import { useHistory } from 'react-router-dom';

import { FactCheck as FactCheckIcon } from '@mui/icons-material';

import { ROUTE } from '~/constants/Route';

import IconButton from '~/components/IconButton';

import Log from '~/utils/Log';

export const OpenDataQualityViewButton = ({ id }) => {
  const history = useHistory();

  const openDataQualityView = () => {
    Log.productAnalyticsEvent(
      'Open delivery note data quality',
      Log.FEATURE.DELIVERY_NOTE,
    );
    history.push(ROUTE.DELIVERY_NOTE_DATA_QUALITY.ROUTE + '/' + id);
  };

  return (
    <IconButton
      onClick={openDataQualityView}
      tooltipTitle="Lieferung in vollständiger Datenansicht öffnen."
      size="large"
    >
      <FactCheckIcon className="text-primary500" />
    </IconButton>
  );
};
