import axios from '~/utils/api-client';
import Config from '~/Config';

const apiUrl = Config.apiUrl;

class AttachmentService {
  getAttachment(chainId, digest) {
    return axios
      .get(apiUrl + '/asset/delivery_note/' + chainId + '/attachment', {
        params: { digest },
        responseType: 'blob',
      })
      .then((response) => {
        return response.data;
      });
  }

  getHandwrittenSignature(chainId) {
    return axios
      .get(
        apiUrl +
          '/asset/delivery_note/chain/' +
          chainId +
          '/handwritten_signature',
        {
          responseType: 'blob',
        },
      )
      .then((response) => {
        return response.data;
      });
  }
}

export default new AttachmentService();
