import InvoiceCheckResult from '~/models/invoices/InvoiceCheckResult';
import ArrayUtils from '~/utils/arrayUtils';
import UnitUtils from '~/utils/unitUtils';

export default class InvoiceParser {
  static parseAssetMain_v1(assetMain) {
    if (!assetMain?.meta?.check_results) {
      return;
    }

    assetMain.meta.check_results = InvoiceParser.filterChecks_v1(
      assetMain.meta.check_results,
    );
    assetMain.meta.check_results =
      InvoiceParser.splitDeliveryNoteExistsErrors_v1(
        assetMain.meta.check_results,
      );
    assetMain.meta.check_results =
      InvoiceParser.addMissingDeliveryNoteAuthorizedNaCheckResults_v1(
        assetMain.meta.check_results,
      );
    assetMain.meta.check_results =
      InvoiceParser.mergeDeliveryNoteQuantityAndUnitTypeMatchesChecks_v1(
        assetMain.meta.check_results,
      );
  }

  static parseAssetMain_v2(assetMain) {
    if (!assetMain?.meta?.check_results) {
      return;
    }

    assetMain.meta.check_results =
      InvoiceParser.mergeDeliveryNoteQuantityAndUnitTypeMatchesChecks_v2(
        assetMain.meta.check_results,
      );
  }

  // This filters the redundant check AcceptedPartialDeliveryQuantityMatches if DeliveryNoteAuthorized has the same status for the same dln.
  // -> This logic should be moved to the backend.
  static filterChecks_v1(check_results) {
    return check_results.filter((check_result) => {
      if (check_result.name === 'AcceptedPartialDeliveryQuantityMatches') {
        const checkDlnAuthorized = check_results.find((check) => {
          return (
            check.name === 'DeliveryNoteAuthorized' &&
            check.delivery_note_asset_id ===
              check_result.delivery_note_asset_id &&
            check.status === check_result.status
          );
        });

        if (checkDlnAuthorized) {
          return false;
        }
      }

      return true;
    });
  }

  // Currently DeliveryNoteExists errors can contain multiple delivery notes. However, it makes more sense to have one error for each delivery note.
  // -> This logic should be moved to the backend.
  static splitDeliveryNoteExistsErrors_v1(check_results) {
    const deliveryNoteExistsErrors = check_results.filter(
      (check_result) =>
        check_result.name === 'DeliveryNoteExists' &&
        check_result.status === InvoiceCheckResult.STATUS.ERROR,
    );

    const deliveryNotes =
      deliveryNoteExistsErrors
        .map((deliveryNoteExistsError) =>
          deliveryNoteExistsError.delivery_note_id?.split(', '),
        )
        ?.flat(1) ?? [];

    check_results = check_results.filter(
      (check_result) =>
        !(
          check_result.name === 'DeliveryNoteExists' &&
          check_result.status === InvoiceCheckResult.STATUS.ERROR
        ),
    );

    for (const deliveryNote of ArrayUtils.removeDuplicates(deliveryNotes)) {
      check_results.push({
        name: 'DeliveryNoteExists',
        delivery_note_id: deliveryNote,
        status: InvoiceCheckResult.STATUS.ERROR,
      });
    }

    return check_results;
  }

  // na check results aren't thrown in DeliveryNoteAuthorized checks for dlns where DeliveryNoteExists has thrown an error
  // This is solved after the invoice check refactoring in the backend but missing for old invoices.
  static addMissingDeliveryNoteAuthorizedNaCheckResults_v1(check_results) {
    const deliveryNoteExistsErrors = check_results.filter(
      (check_result) =>
        check_result.name === 'DeliveryNoteExists' &&
        check_result.status === InvoiceCheckResult.STATUS.ERROR,
    );

    for (const deliveryNoteExistsError of deliveryNoteExistsErrors) {
      check_results.push({
        name: 'DeliveryNoteAuthorized',
        delivery_note_id: deliveryNoteExistsError.delivery_note_id,
        status: InvoiceCheckResult.STATUS.NA,
      });
    }

    return check_results;
  }

  // It is easier for the frontend to treat unit and value together if the amount is different between invoice and delivery note. Hence, the two errors are merged.
  // This logic is supposed to be moved to the backend in the course of the backend invoice check refactoring.
  static mergeDeliveryNoteQuantityAndUnitTypeMatchesChecks_v1(check_results) {
    const deliveryNoteQuantityMatchesCheckResults = check_results.filter(
      (check_result) => check_result.name === 'DeliveryNoteQuantityMatches',
    );
    const deliveryNoteUnitTypeMatchesCheckResults = check_results.filter(
      (check_result) => check_result.name === 'DeliveryNoteUnitTypeMatches',
    );

    // Get all pairs of matching deliveryNoteQuantityMatchesCheckResults and deliveryNoteUnitTypeMatchesCheckResults
    // as well as all check results that have no match
    const arrayMatch = ArrayUtils.matchArrays(
      deliveryNoteQuantityMatchesCheckResults,
      deliveryNoteUnitTypeMatchesCheckResults,
      (
        deliveryNoteQuantityMatchesCheckResult,
        deliveryNoteUnitTypeMatchesCheckResult,
      ) =>
        deliveryNoteQuantityMatchesCheckResult.conflicting_entity ===
          deliveryNoteUnitTypeMatchesCheckResult.conflicting_entity &&
        deliveryNoteQuantityMatchesCheckResult.delivery_note_id ===
          deliveryNoteUnitTypeMatchesCheckResult.delivery_note_id,
    );

    const newDeliveryNoteQuantityMatchesCheckResults = [];

    // All pairs of deliveryNoteQuantityMatchesCheckResults and deliveryNoteUnitTypeMatchesCheckResults should be formatted together
    for (const checkResultPair of arrayMatch.matches) {
      const deliveryNoteQuantityMatchesCheckResult = checkResultPair[0];
      const deliveryNoteUnitTypeMatchesCheckResult = checkResultPair[1];

      newDeliveryNoteQuantityMatchesCheckResults.push({
        ...deliveryNoteQuantityMatchesCheckResult,
        conflicting_values: {
          1: UnitUtils.formatStringUnitPair_safe(
            deliveryNoteQuantityMatchesCheckResult.conflicting_values?.[1],
            deliveryNoteUnitTypeMatchesCheckResult.conflicting_values?.[1],
            UnitUtils.getAbbreviatedUnits,
          ),
          2: UnitUtils.formatStringUnitPair_safe(
            deliveryNoteQuantityMatchesCheckResult.conflicting_values?.[2],
            deliveryNoteUnitTypeMatchesCheckResult.conflicting_values?.[2],
            UnitUtils.getAbbreviatedUnits,
          ),
        },
      });
    }

    // All deliveryNoteQuantityMatchesCheckResults that have no deliveryNoteUnitTypeMatchesCheckResult should only format to DE number
    for (const deliveryNoteQuantityMatchesCheckResult of arrayMatch.unmatched1) {
      newDeliveryNoteQuantityMatchesCheckResults.push({
        ...deliveryNoteQuantityMatchesCheckResult,
        conflicting_values: {
          1: UnitUtils.formatStringDe_safe(
            deliveryNoteQuantityMatchesCheckResult.conflicting_values?.[1],
          ),
          2: UnitUtils.formatStringDe_safe(
            deliveryNoteQuantityMatchesCheckResult.conflicting_values?.[2],
          ),
        },
      });
    }

    // All deliveryNoteUnitTypeMatchesCheckResults that have no deliveryNoteQuantityMatchesCheckResult should only format the unit
    for (const deliveryNoteUnitTypeMatchesCheckResult of arrayMatch.unmatched2) {
      newDeliveryNoteQuantityMatchesCheckResults.push({
        ...deliveryNoteUnitTypeMatchesCheckResult,
        conflicting_values: {
          1: UnitUtils.getAbbreviatedUnits(
            deliveryNoteUnitTypeMatchesCheckResult.conflicting_values?.[1],
          ),
          2: UnitUtils.getAbbreviatedUnits(
            deliveryNoteUnitTypeMatchesCheckResult.conflicting_values?.[2],
          ),
        },
      });
    }

    // Filter out the old check results and replace them with the merged ones
    check_results = check_results.filter(
      (check_result) =>
        check_result.name !== 'DeliveryNoteQuantityMatches' &&
        check_result.name !== 'DeliveryNoteUnitTypeMatches',
    );
    check_results.push(...newDeliveryNoteQuantityMatchesCheckResults);

    return check_results;
  }

  static mergeDeliveryNoteQuantityAndUnitTypeMatchesChecks_v2(check_results) {
    const deliveryNoteQuantityMatchesCheckResults = check_results.filter(
      (check_result) => check_result.name === 'DeliveryNoteQuantityMatches',
    );
    const deliveryNoteUnitTypeMatchesCheckResults = check_results.filter(
      (check_result) => check_result.name === 'DeliveryNoteUnitTypeMatches',
    );

    // Get all pairs of matching deliveryNoteQuantityMatchesCheckResults and deliveryNoteUnitTypeMatchesCheckResults
    // as well as all check results that have no match
    const arrayMatch = ArrayUtils.matchArrays(
      deliveryNoteQuantityMatchesCheckResults,
      deliveryNoteUnitTypeMatchesCheckResults,
      (
        deliveryNoteQuantityMatchesCheckResult,
        deliveryNoteUnitTypeMatchesCheckResult,
      ) =>
        deliveryNoteQuantityMatchesCheckResult.item_name ===
          deliveryNoteUnitTypeMatchesCheckResult.item_name &&
        JSON.stringify(
          deliveryNoteQuantityMatchesCheckResult.delivery_note_asset_ids,
        ) ===
          JSON.stringify(
            deliveryNoteUnitTypeMatchesCheckResult.delivery_note_asset_ids,
          ),
    );

    const newDeliveryNoteQuantityMatchesCheckResults = [];

    // All pairs of deliveryNoteQuantityMatchesCheckResults and deliveryNoteUnitTypeMatchesCheckResults should be formatted together
    for (const checkResultPair of arrayMatch.matches) {
      const deliveryNoteQuantityMatchesCheckResult = checkResultPair[0];
      const deliveryNoteUnitTypeMatchesCheckResult = checkResultPair[1];

      // If one of the check results is an error, the merged check must be an error.
      // If no error and at least one successful, the merged check must be successful.
      let displayStatus = InvoiceCheckResult.STATUS.NA;
      if (
        deliveryNoteQuantityMatchesCheckResult.display_status ===
          InvoiceCheckResult.STATUS.SUCCESS ||
        deliveryNoteUnitTypeMatchesCheckResult.display_status ===
          InvoiceCheckResult.STATUS.SUCCESS
      ) {
        displayStatus = InvoiceCheckResult.STATUS.SUCCESS;
      }

      if (
        deliveryNoteQuantityMatchesCheckResult.display_status ===
          InvoiceCheckResult.STATUS.ERROR ||
        deliveryNoteUnitTypeMatchesCheckResult.display_status ===
          InvoiceCheckResult.STATUS.ERROR
      ) {
        displayStatus = InvoiceCheckResult.STATUS.ERROR;
      }

      let expectedValue = null;
      let invoiceValue = null;

      if (
        deliveryNoteQuantityMatchesCheckResult.expected_value &&
        deliveryNoteUnitTypeMatchesCheckResult.expected_value
      ) {
        // If both quantity and unit are given, format the together in the merged expected value.
        expectedValue = UnitUtils.formatStringUnitPair_safe(
          deliveryNoteQuantityMatchesCheckResult.expected_value,
          deliveryNoteUnitTypeMatchesCheckResult.expected_value,
          UnitUtils.getAbbreviatedUnits,
        );
      } else if (deliveryNoteQuantityMatchesCheckResult.expected_value) {
        // If only quantity is given, don't try to merge it with unit.
        expectedValue = UnitUtils.formatStringDe_safe(
          deliveryNoteQuantityMatchesCheckResult.expected_value,
        );
      } else if (deliveryNoteUnitTypeMatchesCheckResult.expected_value) {
        // If only unit is given, don't try to merge it with quantity.
        expectedValue = UnitUtils.getAbbreviatedUnits(
          deliveryNoteUnitTypeMatchesCheckResult.expected_value,
        );
      }

      // Analogous approach for invoice_value as previous for expected_value
      if (
        deliveryNoteQuantityMatchesCheckResult.invoice_value &&
        deliveryNoteUnitTypeMatchesCheckResult.invoice_value
      ) {
        invoiceValue = UnitUtils.formatStringUnitPair_safe(
          deliveryNoteQuantityMatchesCheckResult.invoice_value,
          deliveryNoteUnitTypeMatchesCheckResult.invoice_value,
          UnitUtils.getAbbreviatedUnits,
        );
      } else if (deliveryNoteQuantityMatchesCheckResult.invoice_value) {
        invoiceValue = UnitUtils.formatStringDe_safe(
          deliveryNoteQuantityMatchesCheckResult.invoice_value,
        );
      } else if (deliveryNoteUnitTypeMatchesCheckResult.invoice_value) {
        invoiceValue = UnitUtils.getAbbreviatedUnits(
          deliveryNoteUnitTypeMatchesCheckResult.invoice_value,
        );
      }

      newDeliveryNoteQuantityMatchesCheckResults.push({
        ...deliveryNoteQuantityMatchesCheckResult,
        display_status: displayStatus,
        expected_value: expectedValue,
        invoice_value: invoiceValue,
      });
    }

    // All deliveryNoteQuantityMatchesCheckResults that have no deliveryNoteUnitTypeMatchesCheckResult should only format to DE number
    for (const deliveryNoteQuantityMatchesCheckResult of arrayMatch.unmatched1) {
      newDeliveryNoteQuantityMatchesCheckResults.push({
        ...deliveryNoteQuantityMatchesCheckResult,
        expected_value: UnitUtils.formatStringDe_safe(
          deliveryNoteQuantityMatchesCheckResult.expected_value,
        ),
        invoice_value: UnitUtils.formatStringDe_safe(
          deliveryNoteQuantityMatchesCheckResult.invoice_value,
        ),
      });
    }

    // All deliveryNoteUnitTypeMatchesCheckResults that have no deliveryNoteQuantityMatchesCheckResult should only format the unit
    for (const deliveryNoteUnitTypeMatchesCheckResult of arrayMatch.unmatched2) {
      newDeliveryNoteQuantityMatchesCheckResults.push({
        ...deliveryNoteUnitTypeMatchesCheckResult,
        expected_value: UnitUtils.getAbbreviatedUnits(
          deliveryNoteUnitTypeMatchesCheckResult.expected_value,
        ),
        invoice_value: UnitUtils.getAbbreviatedUnits(
          deliveryNoteUnitTypeMatchesCheckResult.invoice_value,
        ),
      });
    }

    // Filter out the old check results and replace them with the merged ones
    check_results = check_results.filter(
      (check_result) =>
        check_result.name !== 'DeliveryNoteQuantityMatches' &&
        check_result.name !== 'DeliveryNoteUnitTypeMatches',
    );
    check_results.push(...newDeliveryNoteQuantityMatchesCheckResults);

    return check_results;
  }
}
