import { cn } from '~/utils/tailwind';

export const AccordionBody = ({ children, className, id, open }) => {
  if (!open) {
    return null;
  }

  return (
    <div className={cn('overflow-scroll', className)} id={`accordion-${id}`}>
      {children}
    </div>
  );
};
