import ValueGroup from '~/models/deliveries/ValueGroup';

import Log from '~/utils/Log';

import { ErrorBoundary } from '~/ui/atoms';

import EditingHistory from './EditingHistory';

export const EditingHistorySafe = ({ value, ...rest }) => {
  let fallback;

  try {
    fallback = <div>{ValueGroup.getCurrentValue(value)}</div>;
  } catch (error) {
    fallback = <div>(fehlerhafter Wert)</div>;
    Log.error('Failed to access current value in EditingHistorySafe.', error);
    Log.productAnalyticsEvent(
      'Failed to display editing history',
      Log.FEATURE.OTHER_FEATURE,
      Log.TYPE.ERROR,
    );
  }

  return (
    <ErrorBoundary fallback={() => fallback}>
      <EditingHistory value={value} {...rest} />
    </ErrorBoundary>
  );
};
