export const MASTERDATA = {
  IS_ACTIVE: {
    YES: 'Ja',
    NO: 'Nein',
  },
};

export const COMPANY = {
  ANBS: '98b6312d-70d0-4754-93a3-727add732ebb',
  ANTON_EIREINER_GMBH: '99d9c375-60c7-4c80-a818-112852146f58',
  ARGE_A3: 'edfca2ba-eac3-487f-8d1a-337259b7fea0',
  ARGE_OBERIRDISCHE_WEST: '36be0185-9b75-4829-8b24-5526e4d7d8ac',
  ATH: '143ef55b-4428-4cc0-8dbb-18d0dc06bec7',
  DECHANT: 'a838a898-b6bc-40c6-b3cb-8b4ca166f59c',
  FERTIGBETON_STIEB: 'cc06e3b3-21cf-439d-977b-03ddcb097131',
  GANSER_BETON: '64b8820e-8435-4c10-8697-558524d0917a',
  GROSSMANN: '68587cc0-a792-4c21-a9a5-e8df33da1a73',
  STEINWERKE_ANTON_EIREINER: 'a16fef17-5842-4bcf-bb02-f12bb6c20811',
  STRABAG_AG: 'e8db65d2-79eb-4cb2-9e9a-1f635dd9d9ff',
  STRABAG_BAYERN_NORD: 'c66c66d9-8d6c-493c-9c85-38fa55f0f385',
  STRABAG_NORD_OST: 'bd374d5c-e0a5-457b-af4f-770fc384767b',
  STRABAG_NORD: 'f2af83b2-fa88-46cd-af84-bac57be3e089',
  UNION_BETON: '55fe9894-0189-4b73-9ea7-698097a89003',
  WM: 'd9421c74-1817-4954-91a3-e7ba913a677e',
};

export const COMPANY_ACCOUNT = {
  DEMO_ALLOWED_01: '9b004d37-5da7-4065-a9d6-76fed9716d76',
  DEMO_ALLOWED_02: 'a85be708-d8b3-42d8-8e4b-1ffedb4f5f0e',
  DEMO_ALLOWED_03: '7c045c36-9e31-494d-849d-4eb873fca674', // demo
  DEMO_ALLOWED_04: '2c6b34b9-09df-4ae3-901b-1a4ba7c1dcdb',
  DEMO_ALLOWED_05: '781dc85d-7cbb-4ade-a762-d2274793989d',
  DEMO_ALLOWED_06: '01a60cb0-75fc-4c2e-a465-2cc1701ec279', // muster demo
  DEMO_ALLOWED_07: 'b506cfa1-4e87-42ef-9e38-6a01d8194a1e',
  DEMO_ALLOWED_08: '6360729a-f2b6-4431-8b81-6764f1aa5fff',
  DEMO_ALLOWED_09: 'ecdcb1f5-40bf-4ac3-9cc7-05171ecf40bf', // dev
  DEMO_ALLOWED_10: 'fc9a9155-0058-4ef2-a6fd-3fd9ffca90cb',
  DEMO_ALLOWED_11: 'd695f86a-e20c-447d-b373-390e72248f8c',
  DEMO_ALLOWED_12: 'a767719d-453c-404e-b9cd-ac849b9a113b', // dev in dev db
  DEMO_ALLOWED_13: 'f0110e4a-505e-4d0b-ba8d-c1500df12eaf',
  DEMO_ALLOWED_14: '0141a50c-da1c-46b1-82fa-62454d560a8f',
  DEMO_ALLOWED_15: 'a6ecab6d-c55c-457d-a096-4da3785b6fc0', // dev in prod db
  DEMO_ALLOWED_16: '948ed3a9-b375-4623-bc00-63f6fc09999c',
  DEMO_ALLOWED_17: 'e90991cc-97e4-4b6d-962b-d12ef1a9c335',
  DEMO_ALLOWED_18: 'b04cb975-8287-4c36-af38-9c3e46d82d50', // mustermann prod
  SCHICKER: 'a25f3d41-8a54-4b6e-acc3-36670ddf7e26',
  STRABAG: 'b2169efc-c7a0-41e2-bffd-9d71a2509a53',
  JOSEF_RAEDLINGER: 'ebe2ab36-45ad-4256-9f63-a6901845632c',
  STAATLICHES_BAUAMT_BAYREUTH: '1937032a-79c9-4fb3-a2dd-952fb3e2964e',
};

export const USER_ACCOUNT = {
  LIEFERANT_DEV: 'lieferant-dev',
  SPEDITEUR_DEV: 'spediteur-dev',
  POLIER_DEV: 'polier-dev',
  DEMO_LIEFERANT: 'demo-lieferant',
  DEMO_SPEDITEUR: 'demo-spediteur',
  DEMO_POLIER: 'demo-polier',
  MUSTER_POLIER: 'muster-polier',
  LIEFERANT_IDP: 'lieferant-idp@vestigas.com',
};
