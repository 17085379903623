import React from 'react';
import { connect } from 'react-redux';
import { withErrorBoundary } from '~/ui/atoms';
import MapperService from '~/services/mapper.service';
import { SettingsTable } from '../../SettingsTable';
import CustomFieldService from '~/services/customField.service';
import LocalStorageService from '~/services/localStorage.service';
import CustomField from '~/models/customData/CustomField';

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'displayName',
    headerName: 'Anzeige-Name',
    width: 300,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'type',
    headerName: 'Typ',
    width: 200,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'hasOptions',
    headerName: 'Dropdown',
    width: 150,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'level',
    headerName: 'Level',
    width: 150,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'visibility',
    headerName: 'Sichtbarkeit',
    width: 150,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'public',
    headerName: 'Öffentlich/Privat',
    width: 150,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'key',
    headerName: 'Key',
    width: 400,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 400,
    sortable: true,
    resizableText: true,
  },
];

const mapStateToProps = (state) => ({
  customFields: state.customFields,
});

class CustomFieldTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadData();
    }
  }

  loadData() {
    this.setState({
      rows: this.props.customFields.customFields
        .filter((customField) => !customField.hardcoded)
        .map((customField) => {
          const row = {
            id: customField.id,
            name: customField.name,
            key: customField.key,
            displayName: customField.displayName,
            hasOptions: customField.hasOptions ? 'Ja' : 'Nein',
            public: customField.public ? 'Öffentlich' : 'Privat',
            type: CustomField.getTypeString(customField.type),
            visibility: CustomField.getVisibilityString(customField.visibility),
            level: CustomField.getLevelString(customField.level),
          };

          return MapperService.addSearchString(row, [
            'id',
            'name',
            'key',
            'displayName',
            'hasOptions',
            'public',
            'type',
            'visibility',
            'level',
          ]);
        }),
    });
  }

  render() {
    return (
      <SettingsTable
        entity="customField"
        title="Flexible Felder"
        rows={this.state.rows}
        columns={columns}
        items={this.props.customFields.customFields}
        loading={this.props.customFields.customFieldsLoading}
        refreshData={CustomFieldService.refreshCustomFields}
        localStorageKey={LocalStorageService.CUSTOM_FIELD_TABLE}
      />
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(CustomFieldTable),
  'Flexible Felder konnten nicht geladen werden.',
);
