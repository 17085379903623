import { useState } from 'react';

import { PictureAsPdf as PictureAsPdfIcon } from '@mui/icons-material';

import IconButton from '~/components/IconButton';

import Log from '~/utils/Log';

import ValueGroup from '~/models/deliveries/ValueGroup';

import ExportService from '~/services/export.service';
import ToastService from '~/services/toast.service';

export const DownloadPDFButton = ({ id }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const exportPdf = () => {
    Log.info(
      'Download delivery note as PDF',
      null,
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent(
      'Download delivery note PDF',
      Log.FEATURE.PDF_DOWNLOAD,
    );

    setIsProcessing(true);

    ExportService.createSpecificDeliveryNoteAsPDF(
      ValueGroup.getCurrentValue(id),
    )
      .then(() => {
        getSpecificDeliveryNoteAsPDF(ValueGroup.getCurrentValue(id));
      })
      .catch((error) => {
        if (error.response?.status === 409) {
          getSpecificDeliveryNoteAsPDF(ValueGroup.getCurrentValue(id));
          return;
        }

        ToastService.error([
          ToastService.MESSAGE.PDF_DOWNLOAD_FAILED,
          ToastService.MESSAGE.CONTACT_SUPPORT,
        ]);
        Log.error(
          `Failed to request PDF creation of delivery note. id: ${ValueGroup.getCurrentValue(id)}`,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to download delivery note PDF',
          Log.FEATURE.PDF_DOWNLOAD,
          Log.TYPE.ERROR,
        );

        setIsProcessing(false);
      });
  };

  const getSpecificDeliveryNoteAsPDF = async (deliveryNoteId) => {
    ExportService.getSpecificDeliveryNoteAsPDF(deliveryNoteId)
      .then((response) => {
        if (response.status === 202) {
          return new Promise((r) => setTimeout(r, 1000)).then(() => {
            getSpecificDeliveryNoteAsPDF(deliveryNoteId);
          });
        }

        const file = new Blob([response.data], { type: 'application/pdf' });
        ExportService.downloadFileWithCustomName(
          file,
          ExportService.getDeliveryNoteFileName(deliveryNoteId),
        );

        setIsProcessing(false);
      })
      .catch((error) => {
        ToastService.error([
          ToastService.MESSAGE.PDF_DOWNLOAD_FAILED,
          ToastService.MESSAGE.CONTACT_SUPPORT,
        ]);
        Log.error(
          `Failed to download PDF of delivery note. id: ${deliveryNoteId}`,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to download delivery note PDF',
          Log.FEATURE.PDF_DOWNLOAD,
          Log.TYPE.ERROR,
        );

        setIsProcessing(false);
      });
  };

  return (
    <IconButton
      onClick={exportPdf}
      isLoading={isProcessing}
      tooltipTitle="PDF Download"
      size="large"
    >
      <PictureAsPdfIcon className="text-primary500" />
    </IconButton>
  );
};
