import { Check as CheckIcon } from '@mui/icons-material';
import { Chip as MuiChip } from '@mui/material';

const Chip = ({
  clickable,
  deletable,
  id,
  isPaginationChip,
  name,
  new: isNew,
  onClick,
  onDelete,
  onPaginationClick,
  selected,
}) => {
  const isClickable = () => {
    if (isPaginationChip) {
      return true;
    }

    return clickable;
  };

  const isDeletable = () => {
    if (isPaginationChip) {
      return false;
    }

    return deletable;
  };

  const handleClick = () => {
    if (!isClickable()) {
      return;
    }

    if (isPaginationChip) {
      onPaginationClick();
    } else {
      onClick(id);
    }
  };

  const handleDelete = () => {
    if (!isDeletable()) {
      return;
    }

    onDelete(id);
  };

  return (
    <div onClick={handleClick} className="relative">
      <MuiChip
        key={id}
        label={name}
        onDelete={isDeletable() ? handleDelete : null}
        icon={selected ? <CheckIcon className="text-white" /> : null}
        className={
          selected ? 'bg-primary500 text-white' : 'text-grey800 bg-white'
        }
        sx={{
          cursor: isClickable() ? 'pointer' : 'default',
          backgroundColor: 'white',
          color: isPaginationChip ? '#173C88' : null,
          border: isPaginationChip ? '#173C88 solid 1px' : '#8D94A8 solid 1px', // primary500, grey400
          '&:hover': {
            backgroundColor:
              isClickable() && !selected ? '#E4E6ED !important' : 'white',
          },
        }}
      />
      {isNew ? (
        <div className="absolute -right-2 -top-2.5 flex h-5 w-10 items-center justify-center rounded-full bg-gray-800 text-xs text-white">
          Neu
        </div>
      ) : null}
    </div>
  );
};

export default Chip;
