import React from 'react';

import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
} from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import Log from '~/utils/Log';
import cloneDeep from 'lodash/cloneDeep';
import SignatureRoles from '~/models/masterdata/SignatureRoles';

class CreateUserWizardSignatures extends React.Component {
  handleRadioChange = (event) => {
    Log.info(
      'Change form value of enable signature radio button',
      { from: this.props.enableSignature, to: event.target.value },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change enable signature radio button in create user wizard',
      Log.FEATURE.WIZARD,
    );
    this.props.setEnableSignature(
      event.target.value === 'enable_signature_yes',
    );
  };
  handleCheckboxChange = (event) => {
    const newUser = cloneDeep(this.props.user);

    switch (event.target.name) {
      case 'signature_role_supplier': {
        newUser.signatureRoles.supplier = event.target.checked;
        Log.info(
          'Change form value of supplier signature role',
          {
            from: this.props.user.signatureRoles.supplier,
            to: newUser.signatureRoles.supplier,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change supplier signature role checkbox in create user wizard',
          Log.FEATURE.WIZARD,
        );
        break;
      }

      case 'signature_role_carrier': {
        newUser.signatureRoles.carrier = event.target.checked;
        Log.info(
          'Change form value of carrier signature role',
          {
            from: this.props.user.signatureRoles.carrier,
            to: newUser.signatureRoles.carrier,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change carrier signature role checkbox in create user wizard',
          Log.FEATURE.WIZARD,
        );
        break;
      }

      case 'signature_role_recipient': {
        newUser.signatureRoles.recipient = event.target.checked;
        Log.info(
          'Change form value of recipient signature role',
          {
            from: this.props.user.signatureRoles.recipient,
            to: newUser.signatureRoles.recipient,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change recipient signature role checkbox in create user wizard',
          Log.FEATURE.WIZARD,
        );
        break;
      }
    }

    this.props.setUser(newUser);
  };

  render() {
    return (
      <Grid container direction="row" spacing={3} space={4}>
        <Grid item xs={12} lg={12}>
          <h3 className="main-text mt-0">
            Soll der Benutzer berechtigt sein, Lieferungen in der VESTIGAS App
            zu bearbeiten oder zu signieren? (kann jederzeit geändert werden)
          </h3>
          <RadioGroup
            onChange={this.handleRadioChange}
            value={
              this.props.enableSignature
                ? 'enable_signature_yes'
                : 'enable_signature_no'
            }
            row
          >
            <FormControlLabel
              value="enable_signature_yes"
              control={<Radio />}
              label="Ja (empfohlen)"
              className="mr-50px"
            />
            <FormControlLabel
              value="enable_signature_no"
              control={<Radio />}
              label="Nein"
            />
          </RadioGroup>
        </Grid>
        {/* We could provide the on behalf signature roles here as well. But as they are currently a more edge case, it doesn't make a lot of sense to include this configuration in the wizard. */}
        {this.props.enableSignature ? (
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">
              In welcher Funktion soll der Benutzer Lieferungen bearbeiten oder
              signieren können?
            </h3>
            <FormControlLabel
              className="mr-50px"
              control={
                <Checkbox
                  checked={this.props.user.signatureRoles.supplier}
                  onChange={this.handleCheckboxChange}
                  name="signature_role_supplier"
                />
              }
              label={'Als ' + SignatureRoles.SIGNATURE_ROLE.SUPPLIER.STRING}
            />
            <FormControlLabel
              className="mr-50px"
              control={
                <Checkbox
                  checked={this.props.user.signatureRoles.carrier}
                  onChange={this.handleCheckboxChange}
                  name="signature_role_carrier"
                />
              }
              label={'Als ' + SignatureRoles.SIGNATURE_ROLE.CARRIER.STRING}
            />
            <FormControlLabel
              className="mr-50px"
              control={
                <Checkbox
                  checked={this.props.user.signatureRoles.recipient}
                  onChange={this.handleCheckboxChange}
                  name="signature_role_recipient"
                />
              }
              label={
                'Als ' +
                SignatureRoles.SIGNATURE_ROLE.RECIPIENT.STRING +
                ' (empfohlen)'
              }
            />
          </Grid>
        ) : null}
      </Grid>
    );
  }
}

export default withErrorBoundary(
  CreateUserWizardSignatures,
  'Daten konnten nicht geladen werden.',
);
