import React, { useState, useEffect } from 'react';

import { Button, Grid, TextField } from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import BasicForm from '../../BasicForm';
import Log from '~/utils/Log';
import { useSelector, useDispatch } from 'react-redux';
import User from '~/models/masterdata/User';
import { promiseHandler } from '~/utils/promiseHandler';
import UserService from '~/services/user.service';
import ToastService from '~/services/toast.service';
import { Spinner } from '../../Spinner';
import { updateUserInfos } from '~/redux/userinfoSlice';
import { LOADING_STATE } from '~/constants/LoadingState';
import FunctionUtils from '~/utils/functionUtils';

function ProfileDetails(props) {
  const { userinfo, userinfoLoading } = useSelector((state) => state.userinfo);
  const { companyAccount } = useSelector((state) => state.companyAccount);
  const [open, setOpen] = useState(false);
  const [submittingForm, setSubmittingForm] = React.useState(false);
  const [firstName, setFirstName] = useState(userinfo.firstname);
  const [lastName, setLastName] = useState(userinfo.lastname);
  const [position, setPosition] = useState(userinfo.position);
  const [phone, setPhone] = useState(userinfo.phone);
  const dispatch = useDispatch();

  useEffect(() => {
    setFirstName(userinfo.firstname);
    setLastName(userinfo.lastname);
    setPosition(userinfo.position);
    setPhone(userinfo.phone);
  }, [
    userinfo.firstname,
    userinfo.lastname,
    userinfo.position,
    userinfo.phone,
  ]);

  const detailsPersonalData = [
    {
      key: 'Name',
      value: User.formatName(userinfo.firstname, userinfo.lastname),
    },
    {
      key: 'Position',
      value: userinfo.position ?? '-',
    },
    {
      key: 'Telefon-Nr.',
      value: userinfo.phone ?? '-',
    },
    {
      key: 'Firma',
      value: userinfo.company?.name ?? '-',
    },
    {
      key: 'Unternehmens-Account',
      value: companyAccount.name ?? '-',
    },
  ];

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case 'firstname': {
        setFirstName(event.target.value);
        Log.info(
          'Change form value of first name',
          { from: firstName, to: event.target.value },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'profile_change_first_name',
          Log.productAnalyticsEvent,
          ['Change first name', Log.FEATURE.PROFILE],
        );
        break;
      }

      case 'lastname': {
        setLastName(event.target.value);
        Log.info(
          'Change form value of last name',
          { from: lastName, to: event.target.value },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'profile_change_last_name',
          Log.productAnalyticsEvent,
          ['Change last name', Log.FEATURE.PROFILE],
        );
        break;
      }

      case 'position': {
        setPosition(event.target.value);
        Log.info(
          'Change form value of position',
          { from: position, to: event.target.value },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'profile_change_position',
          Log.productAnalyticsEvent,
          ['Change position', Log.FEATURE.PROFILE],
        );
        break;
      }

      case 'phone': {
        setPhone(event.target.value);
        Log.info(
          'Change form value of phone',
          { from: phone, to: event.target.value },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'profile_change_phone',
          Log.productAnalyticsEvent,
          ['Change phone', Log.FEATURE.PROFILE],
        );
        break;
      }
    }
  };

  const handleClick = () => {
    Log.info('Open user data form', null, Log.BREADCRUMB.FORM_OPEN.KEY);
    Log.productAnalyticsEvent('Open form', Log.FEATURE.PROFILE);
    setOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmittingForm(true);

    Log.productAnalyticsEvent('Submit form', Log.FEATURE.PROFILE);

    const [response, error] = await promiseHandler(
      UserService.updateUserName(firstName, lastName),
    );

    if (error) {
      Log.error('Failed to update user name', error);
      ToastService.error([
        'Benutzername konnte nicht aktualisiert werden.',
        ToastService.MESSAGE.CONTACT_SUPPORT,
      ]);
      Log.productAnalyticsEvent(
        'Failed to update user name',
        Log.FEATURE.PROFILE,
        Log.TYPE.ERROR,
      );
      setSubmittingForm(false);
      return;
    }

    const [response2, error2] = await promiseHandler(
      UserService.updateUserPosition(position),
    );

    if (error2) {
      Log.error('Failed to update position', error2);
      ToastService.error([
        'Position konnte nicht aktualisiert werden.',
        ToastService.MESSAGE.CONTACT_SUPPORT,
      ]);
      Log.productAnalyticsEvent(
        'Failed to update position',
        Log.FEATURE.PROFILE,
        Log.TYPE.ERROR,
      );
      setSubmittingForm(false);
      return;
    }

    const [response3, error3] = await promiseHandler(
      UserService.updateUserPhone(phone),
    );

    if (error3) {
      Log.error('Failed to update phone', error3);
      ToastService.error([
        'Telefon-Nr. konnte nicht aktualisiert werden.',
        ToastService.MESSAGE.CONTACT_SUPPORT,
      ]);
      Log.productAnalyticsEvent(
        'Failed to update phone',
        Log.FEATURE.PROFILE,
        Log.TYPE.ERROR,
      );
      setSubmittingForm(false);
      return;
    }

    setSubmittingForm(false);

    dispatch(
      updateUserInfos({
        firstname: firstName,
        lastname: lastName,
        position,
        phone,
      }),
    );
    setOpen(false);
  };

  const handleAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.PROFILE);
    setOpen(false);
    setFirstName(userinfo.firstname);
    setLastName(userinfo.lastname);
    setPosition(userinfo.position);
    setPhone(userinfo.phone);
  };

  const getUnsavedChanges = () => {
    const differentValues = [];

    if (userinfo.firstname !== firstName) {
      differentValues.push('Vorname');
    }

    if (userinfo.lastname !== lastName) {
      differentValues.push('Nachname');
    }

    if (userinfo.position !== position) {
      differentValues.push('Position');
    }

    if (userinfo.phone !== phone) {
      differentValues.push('Telefon-Nr.');
    }

    return differentValues;
  };

  if (userinfoLoading === LOADING_STATE.LOADING) {
    return (
      <div className="p-1rem">
        <Spinner title="Persönliche Daten werden geladen..." />
      </div>
    );
  }

  if (userinfoLoading === LOADING_STATE.FAILED) {
    return (
      <div className="flex-sb-s h-full w-full">
        <div>
          <div className="bold mb-20px">Persönliche Daten</div>
          Daten konnten nicht geladen werden.
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex-sb-s h-full w-full">
        <div>
          <div className="bold mb-20px">Persönliche Daten</div>
          <div className="flexdir-column gap-20px flex">
            {detailsPersonalData.map((detail, index) => (
              <div key={index}>
                <div>{detail.key}</div>
                <div className="bold">{detail.value}</div>
              </div>
            ))}
          </div>
        </div>
        <Button
          variant="outlined"
          className="t-0 relative"
          onClick={handleClick}
        >
          Bearbeiten
        </Button>
      </div>
      <BasicForm
        title="Persönliche Daten bearbeiten"
        open={open}
        formAbort={handleAbort}
        formSuccess={handleSubmit}
        fullWidth
        submittingForm={submittingForm}
        unsavedChanges={getUnsavedChanges()}
      >
        <Grid container direction="row" spacing={3} space={4}>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="firstname-input"
                  name="firstname"
                  label="Vorname"
                  type="text"
                  fullWidth
                  value={firstName}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="lastname-input"
                  name="lastname"
                  label="Nachname"
                  type="text"
                  fullWidth
                  value={lastName}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="position-input"
                  name="position"
                  label="Position"
                  type="text"
                  fullWidth
                  value={position}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="phone-input"
                  name="phone"
                  label="Telefon-Nr."
                  type="text"
                  fullWidth
                  value={phone}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BasicForm>
    </>
  );
}

export default withErrorBoundary(
  ProfileDetails,
  'Persönliche Daten konnten nicht geladen werden',
);
