import React, { useEffect, useState } from 'react';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Backdrop, CircularProgress, CssBaseline } from '@mui/material';

import LocalStorageService from '~/services/localStorage.service';
import FeatureService from '~/services/feature.service';

import RouteUtils from '~/utils/routeUtils';
import Log from '~/utils/Log';
import { usePrevious } from '~/utils/customHooks';

import { JsonModal } from '../JsonModal';

import { Drawer } from './Drawer';
import { TopBar } from './TopBar';

export const MainMenu = () => {
  const location = useLocation();

  const previousLocation = usePrevious(location);

  const backdrop = useSelector((state) => state.backdrop);

  const [hideDrawer, setHideDrawer] = useState(
    LocalStorageService.getBooleanFromLocalStorage(
      LocalStorageService.HIDE_DRAWER,
    ),
  );

  const drawerWidthOpen = FeatureService.serviceNotes() ? 280 : 240;
  const drawerWidthClosed = 110;
  const drawerWidth = hideDrawer ? drawerWidthClosed : drawerWidthOpen;

  if (location.pathname !== previousLocation?.pathname) {
    try {
      Log.productAnalyticsEvent(
        RouteUtils.getBaseUrl(location.pathname),
        Log.FEATURE.NAVIGATION,
      );
    } catch (error) {
      Log.error('Failed to send page view to product analytics tool', error);
    }
  }

  // Enforce limit of toasts.
  const { toasts } = useToasterStore();
  useEffect(() => {
    for (const t of toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, index) => index >= 5))
      toast.dismiss(t.id); // Dismiss – Use toast.remove(t.id) removal without animation
  }, [toasts]);

  return (
    <div className="flex h-screen w-screen">
      <Backdrop
        style={{
          zIndex: 10_000,
          backgroundColor: '#000',
          opacity: 0.8,
          color: '#fff',
          fontSize: '25px',
        }}
        open={backdrop.open}
      >
        <CircularProgress color="inherit" className="mr-4" />
        {backdrop.message}
      </Backdrop>
      <Toaster
        toastOptions={{
          className: 'rounded-md max-w-60vw py-1 pl-1',
        }}
      />
      <CssBaseline />
      <TopBar drawerWidth={drawerWidth} />
      <Drawer
        hideDrawer={hideDrawer}
        setHideDrawer={setHideDrawer}
        drawerWidth={drawerWidth}
      />
      <JsonModal />
    </div>
  );
};
