import React from 'react';
import { connect } from 'react-redux';
import { withErrorBoundary } from '~/ui/atoms';
import MapperService from '~/services/mapper.service';
import { SettingsTable } from '../../SettingsTable';
import LocalStorageService from '~/services/localStorage.service';
import SignatureFieldService from '~/services/signatureField.service';
import SignatureField from '~/models/signatureField/SignatureField';

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'displayName',
    headerName: 'Anzeige-Name',
    width: 300,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'required',
    headerName: 'Verpflichtend',
    width: 150,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'role',
    headerName: 'Rolle',
    width: 200,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'pathString',
    headerName: 'Zugriff auf',
    width: 200,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'customField',
    headerName: 'Flexibles Feld',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'company',
    headerName: 'Firma',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'site',
    headerName: 'Standort',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'costCenter',
    headerName: 'Kostenstelle',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 400,
    sortable: true,
    resizableText: true,
  },
];

const mapStateToProps = (state) => ({
  signatureFields: state.signatureFields,
  customFields: state.customFields,
  companies: state.companies,
  sites: state.sites,
  costCenters: state.costCenters,
});

class SignatureFieldTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadData();
    }
  }

  loadData() {
    this.setState({
      rows: this.props.signatureFields.signatureFields.map((signatureField) => {
        const row = {
          id: signatureField.id,
          name: signatureField.name,
          displayName: signatureField.displayName,
          required: signatureField.required ? 'Ja' : 'Nein',
          role: SignatureField.getRoleString(signatureField.role),
          pathString: SignatureField.getPathStringFromKey(
            signatureField.pathKey,
          ),
          customField: this.props.customFields.customFields.find(
            (customField) => customField.id === signatureField.customFieldId,
          )?.name,
          company: this.props.companies.companies.find(
            (company) => company.id === signatureField.companyId,
          )?.name,
          site: this.props.sites.sites.find(
            (site) => site.id === signatureField.siteId,
          )?.name,
          costCenter: this.props.costCenters.costCenters.find(
            (costCenter) => costCenter.id === signatureField.costCenterId,
          )?.name,
        };

        return MapperService.addSearchString(row, [
          'id',
          'name',
          'displayName',
          'required',
          'role',
          'pathString',
          'customField',
          'company',
          'site',
          'costCenter',
        ]);
      }),
    });
  }

  render() {
    return (
      <SettingsTable
        entity="signatureField"
        title="Signaturfelder"
        rows={this.state.rows}
        columns={columns}
        items={this.props.signatureFields.signatureFields}
        loading={this.props.signatureFields.signatureFieldsLoading}
        refreshData={SignatureFieldService.refreshSignatureFields}
        localStorageKey={LocalStorageService.SIGNATURE_FIELD_TABLE}
      />
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(SignatureFieldTable),
  'Signaturfelder konnten nicht geladen werden.',
);
