import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { LOADING_STATE } from '~/constants/LoadingState';
import { GridToolbar } from '~/components/interaction/GridToolbar';
import Log from '~/utils/Log';
import { withErrorBoundary } from '~/ui/atoms';
import {
  LightTooltip,
  MissingPermissionsTooltip,
} from '~/utils/componentUtils';
import { Spinner } from '~/components/Spinner';
import PermissionGrantService from '~/services/permissionGrant.service';
import Permissions from '~/models/masterdata/Permissions';
import DatagridUtils from '~/utils/datagridUtils';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import { promiseHandler } from '~/utils/promiseHandler';
import ToastService from '~/services/toast.service';
import UserService from '~/services/user.service';
import SiteService from '~/services/site.service';
import CostCenterService from '~/services/costCenter.service';
import VehicleService from '~/services/vehicle.service';
import CompanyService from '~/services/company.service';
import OrganisationalGroupService from '~/services/organisationalGroup.service';
import UserGroupService from '~/services/userGroup.service';
import { PROMISE_STATUS } from '~/constants/AsyncOperationConsts';
import PermissionForm from './PermissionForm';
import PermissionGrantDialog from './PermissionGrantDialog';
import UserUtils from '~/utils/userUtils';
import PromiseUtils from '~/utils/promiseUtils';
import { ENTITY_TYPES } from 'models/masterdata/PermissionGrant';

const PermissionGrantSubjectTable = ({
  defaultEntities,
  defaultEntityType,
  defaultSubjects,
  defaultSubjectType,
  fixedPicker,
  loading,
  permissionGrantsOn,
  refreshData,
  title,
}) => {
  const users = useSelector((state) => state.users.users);
  const sites = useSelector((state) => state.sites.sites);
  const costCenters = useSelector((state) => state.costCenters.costCenters);
  const vehicles = useSelector((state) => state.vehicles.vehicles);
  const companies = useSelector((state) => state.companies.companies);
  const organisationalGroups = useSelector(
    (state) => state.organisationalGroups.organisationalGroups,
  );
  const userGroups = useSelector((state) => state.userGroups.userGroups);

  const [open, setOpen] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [permissionFormOpen, setPermissionFormOpen] = useState(false);
  const [permissionGrantId, setPermissionGrantId] = useState(null);
  const [permissions, setPermissions] = useState(new Permissions());
  const [rows, setRows] = useState([]);
  const [deletingPermissionGrants, setDeletingPermissionGrants] =
    useState(false);
  const [submittingPermissionForm, setSubmittingPermissionForm] =
    useState(false);

  useEffect(() => {
    initRows();
  }, [permissionGrantsOn]);

  const initRows = async () => {
    const promises = permissionGrantsOn.map((permissionGrant) =>
      getEntityName(permissionGrant.entityType, permissionGrant.entityId),
    );

    const [results, error] = await promiseHandler(Promise.allSettled(promises));

    if (error) {
      Log.error('Failed to load subjects.', error);
      Log.productAnalyticsEvent(
        'Failed to load subjects',
        Log.FEATURE.PERMISSIONS,
        Log.TYPE.ERROR,
      );
      return;
    }

    const newRows = [];
    for (const [index, result] of results.entries()) {
      const { entityId, entityType, id, permissions } =
        permissionGrantsOn[index];

      newRows.push({
        defaultRole: permissions.getDefaultRoleName(),
        entityId,
        entityName:
          result.status === PROMISE_STATUS.FULFILLED ? result.value : '...',
        entityType: ENTITY_TYPES[entityType],
        id,
        permissions: {
          permissions,
          permissionGrantId: id,
        },
      });
    }

    setRows(newRows);
  };

  const onRowSelectionModelChange = (event) => {
    Log.info(
      'Change selection value of permission grants',
      { from: rowSelectionModel, to: event },
      Log.BREADCRUMB.SELECTION_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      '(De)select granted permissions',
      Log.FEATURE.PERMISSIONS,
    );

    setRowSelectionModel(event);
  };

  const addPermissionGrant = () => {
    Log.productAnalyticsEvent('Open form', Log.FEATURE.PERMISSION_GRANT_DIALOG);
    setOpen(true);
  };

  const deletePermissionGrant = async () => {
    if (rowSelectionModel.length === 0) {
      ToastService.warning([
        'Bitte wähle mindestens eine Berechtigung aus, die gelöscht werden soll.',
      ]);
      Log.productAnalyticsEvent(
        'No permission selected to be deleted',
        Log.FEATURE.PERMISSIONS,
      );
      return;
    }

    setDeletingPermissionGrants(true);

    Log.info(
      'Delete permission grants',
      { rowSelectionModel },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent('Delete', Log.FEATURE.PERMISSIONS);

    const promises = rowSelectionModel.map((id) =>
      PermissionGrantService.deletePermissionGrant(id),
    );

    PromiseUtils.allResolved(promises)
      .then((results) => {
        ToastService.success(['Berechtigungen wurden gelöscht.']);
        setDeletingPermissionGrants(false);
        refreshData();
      })
      .catch((error) => {
        ToastService.error([
          'Berechtigungen konnten nicht vollständig gelöscht werden.',
        ]);
        Log.productAnalyticsEvent(
          'Failed to delete',
          Log.FEATURE.PERMISSIONS,
          Log.TYPE.ERROR,
        );
        setDeletingPermissionGrants(false);
        Log.error('Failed to delete permissions.', error);
      });
  };

  const openPermissionForm = (params) => {
    Log.productAnalyticsEvent('Open form', Log.FEATURE.PERMISSIONS);
    setPermissionFormOpen(true);
    setPermissions(params.value.permissions);
    setPermissionGrantId(params.value.permissionGrantId);
  };

  const permissionFormSuccess = async (newPermissions) => {
    if (!permissionGrantId) {
      return;
    }

    setSubmittingPermissionForm(true);

    const permissionGrant = permissionGrantsOn.find(
      (pg) => pg.id === permissionGrantId,
    );

    if (permissionGrant) {
      const [isDuplicate, error] = await promiseHandler(
        PermissionGrantService.isDuplicatePermissionGrant(
          PermissionGrant.TYPE.SUBJECT,
          permissionGrant.subjectType,
          permissionGrant.subjectId,
          permissionGrant.entityType,
          permissionGrant.entityId,
          newPermissions,
        ),
      );

      if (error) {
        Log.error('Failed to detect duplicate permission grant.', error);
        Log.productAnalyticsEvent(
          'Failed to detect duplicate permission grant',
          Log.FEATURE.PERMISSIONS,
          Log.TYPE.ERROR,
        );
      }

      if (isDuplicate) {
        ToastService.warning([
          'Die Berechtigung wurde nicht aktualisiert, da diese Berechtigung bereits vorhanden ist.',
        ]);
        setPermissionFormOpen(false);
        setSubmittingPermissionForm(false);
        return;
      }
    } else {
      Log.error('Failed to find permission grant. id: ' + permissionGrantId);
      Log.productAnalyticsEvent(
        'Failed to find permission grant',
        Log.FEATURE.PERMISSIONS,
        Log.TYPE.ERROR,
      );
    }

    const [, error2] = await promiseHandler(
      PermissionGrantService.updatePermissionGrant(permissionGrantId, {
        permissions: newPermissions.getBackendPermissions(),
      }),
    );

    if (error2) {
      ToastService.httpError(
        ['Berechtigung konnte nicht aktualisiert werden.'],
        error2.response,
      );
      Log.error(
        'Failed to updated permission grant. id: ' + permissionGrantId,
        error2,
      );
      Log.productAnalyticsEvent(
        'Failed to update',
        Log.FEATURE.PERMISSIONS,
        Log.TYPE.ERROR,
      );
    }

    ToastService.success(['Berechtigung wurden aktualisiert.']);

    setPermissionFormOpen(false);
    setSubmittingPermissionForm(false);

    refreshData();
  };

  const permissionFormAbort = () => {
    setPermissionFormOpen(false);
  };

  const closeForm = () => {
    setOpen(false);
  };

  const getColumns = () => {
    return [
      {
        field: 'permissions',
        headerName: '',
        width: 100,
        sortable: true,
        filterable: false,
        renderCell(params) {
          return (
            <>
              <LightTooltip title="Vergebene Berechtigungen einsehen">
                <Button
                  variant="outlined"
                  color="primary"
                  className="h-30px"
                  onClick={() => openPermissionForm(params)}
                >
                  Details
                </Button>
              </LightTooltip>
              <PermissionForm
                title="Vergebene Berechtigungen einsehen"
                open={permissionFormOpen}
                permissions={permissions}
                formSuccess={permissionFormSuccess}
                formAbort={permissionFormAbort}
                submittingForm={submittingPermissionForm}
                // Set opacity of modal manually because otherwise the backdrop of the form inside the table is too dark.
                opacity={0.1}
              />
            </>
          );
        },
      },
      {
        field: 'defaultRole',
        headerName: 'Berechtigung als',
        width: 200,
        sortable: true,
      },
      {
        field: 'entityType',
        headerName: 'Berechtigung auf',
        width: 200,
        sortable: true,
      },
      {
        field: 'entityName',
        headerName: 'Name',
        width: 250,
        sortable: true,
        renderCell: DatagridUtils.displayCellTooltip,
      },
      {
        field: 'entityId',
        headerName: 'ID',
        width: 350,
        sortable: true,
      },
    ];
  };

  const getEntityName = async (entityType, entityId) => {
    // In some cases, entities are loaded directly from the backend and cached in plain JS (such as with UserService.getUser).
    // In other cases, entities are loaded from the redux store and if not given a bulk load is performed (such as with SiteService.getSiteById).
    // Both have advantages. In general, when scaling master data it will not work anymore to do a bulk load as in the byId functions.
    // As soon as it is clear whether to go with the first or second option of loading entities, the following functions should be aligned accordingly.
    switch (entityType) {
      case PermissionGrant.ENTITY_TYPE.USER.KEY: {
        const [user, error] = await promiseHandler(
          UserService.getUserById(entityId),
        );

        if (error) {
          throw error;
        }

        return user.email;
      }

      case PermissionGrant.ENTITY_TYPE.SITE.KEY: {
        const [site, error2] = await promiseHandler(
          SiteService.getSiteById(entityId),
        );

        if (error2) {
          throw error2;
        }

        return site.name;
      }

      case PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY: {
        const [costCenter, error3] = await promiseHandler(
          CostCenterService.getCostCenterById(entityId),
        );

        if (error3) {
          throw error3;
        }

        return costCenter.name;
      }

      case PermissionGrant.ENTITY_TYPE.VEHICLE.KEY: {
        const [vehicle, error4] = await promiseHandler(
          VehicleService.getVehicleById(entityId),
        );

        if (error4) {
          throw error4;
        }

        return vehicle.licensePlate.name;
      }

      case PermissionGrant.ENTITY_TYPE.COMPANY.KEY: {
        const [company, error5] = await promiseHandler(
          CompanyService.getCompanyById(entityId),
        );

        if (error5) {
          throw error5;
        }

        return company.name;
      }

      case PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY: {
        const [organisationalGroup, error6] = await promiseHandler(
          OrganisationalGroupService.getOrganisationalGroupById(entityId),
        );

        if (error6) {
          throw error6;
        }

        return organisationalGroup.name;
      }

      case PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY: {
        const [userGroup, error7] = await promiseHandler(
          UserGroupService.getUserGroupById(entityId),
        );

        if (error7) {
          throw error7;
        }

        return userGroup.name;
      }

      default: {
        Log.error(
          null,
          new EnumValueNotFoundException('Invalid entity type: ' + entityType),
        );
        return '...';
      }
    }
  };

  const getPermissionGrantButton = () => {
    if (!UserUtils.isPermissionGrantAllowedUser()) {
      return (
        <MissingPermissionsTooltip>
          <Button
            variant="outlined"
            color="primary"
            className="mt-10px"
            onClick={addPermissionGrant}
            disabled
          >
            Berechtigungen vergeben
          </Button>
        </MissingPermissionsTooltip>
      );
    }

    return (
      <LightTooltip title="Neue Benutzer oder Benutzer-Gruppen berechtigen.">
        <Button
          variant="outlined"
          color="primary"
          className="mt-10px"
          onClick={addPermissionGrant}
        >
          Berechtigungen vergeben
        </Button>
      </LightTooltip>
    );
  };

  const getPermissionDeleteButton = () => {
    if (!UserUtils.isPermissionGrantAllowedUser()) {
      return (
        <MissingPermissionsTooltip>
          <Button
            variant="outlined"
            color="secondary"
            className="mt-10px"
            onClick={deletePermissionGrant}
            disabled
          >
            {deletingPermissionGrants ? (
              <Spinner title="Berechtigungen löschen..." />
            ) : (
              'Berechtigungen löschen'
            )}
          </Button>
        </MissingPermissionsTooltip>
      );
    }

    return (
      <LightTooltip title="Berechtigungen von ausgewählten Benutzern oder Benutzer-Gruppen löschen.">
        <Button
          variant="outlined"
          color="secondary"
          className="mt-10px"
          onClick={deletePermissionGrant}
          disabled={deletingPermissionGrants}
        >
          {deletingPermissionGrants ? (
            <Spinner title="Berechtigungen löschen..." />
          ) : (
            'Berechtigungen löschen'
          )}
        </Button>
      </LightTooltip>
    );
  };

  return (
    <>
      <h3 className="main-text">{title}</h3>
      <div className="border">
        <DataGrid
          rows={rows}
          columns={getColumns()}
          pageSize={5}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={onRowSelectionModelChange}
          rowSelectionModel={rowSelectionModel}
          autoHeight
          rowHeight={DatagridUtils.ROW_HEIGHT.THIN}
          loading={
            deletingPermissionGrants || loading === LOADING_STATE.LOADING
          }
          columnVisibilityModel={{
            entityId: UserUtils.isVestigasAccount(),
          }}
          slots={{
            toolbar: () => <GridToolbar noColumnsButton noExportButton />,
            noRowsOverlay: () => (
              <div className="flex-c-c h-full w-full">
                {loading === LOADING_STATE.FAILED
                  ? 'Berechtigungen konnten nicht geladen werden.'
                  : 'Keine Einträge'}
              </div>
            ),
          }}
        />
      </div>
      <div className="flex-sb-c">
        {getPermissionDeleteButton()}
        {getPermissionGrantButton()}
      </div>
      <PermissionGrantDialog
        open={open}
        loadData={refreshData}
        closeForm={closeForm}
        defaultSubjects={defaultSubjects}
        defaultSubjectType={defaultSubjectType}
        defaultEntities={defaultEntities}
        defaultEntityType={defaultEntityType}
        fixedPicker={fixedPicker}
      />
    </>
  );
};

export default withErrorBoundary(
  PermissionGrantSubjectTable,
  'Berechtigungen konnten nicht geladen werden.',
);
