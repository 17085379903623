import LicensePlate from './LicensePlate';
import PermissionGrant from './PermissionGrant';
import ArrayUtils from '~/utils/arrayUtils';

export default class Vehicle {
  constructor(vehicle, additionalDataInitiated) {
    this.id = vehicle?.id ?? null;
    this.licensePlate = new LicensePlate(vehicle?.license_plate);
    this.company = vehicle?.company_id ?? null;
    this.active = vehicle?.is_active === undefined ? true : vehicle?.is_active;

    this.organisationalGroups = vehicle?.org_units ?? [];
    this.organisationalGroupPaths = vehicle?.org_unit_paths ?? [];

    this.permissionGrantsFrom =
      vehicle?.permissions_from?.map(
        (permissionGrant) =>
          new PermissionGrant({
            ...permissionGrant,
            target_type: PermissionGrant.ENTITY_TYPE.VEHICLE.KEY,
            target_id: this.id,
          }),
      ) ?? [];

    // This variable is used to track whether additional data such as permissions and paths are already initiated.
    // In the future, this data will not be provided anymore by the bulk endpoint and must be loaded separately when the user opens the respective master data form.
    this.additionalDataInitiated = additionalDataInitiated;

    // Workaround: Prevent the paths to be loaded when the GET /all endpoint is called as the paths are currently still provided and cause visual bugs.
    if (!this.additionalDataInitiated) {
      this.organisationalGroupPaths = [];
    }
  }

  static getDifferentValues(vehicleA, vehicleB) {
    const differentValues = [];

    if (vehicleA?.id !== vehicleB?.id) {
      differentValues.push('ID');
    }

    if (vehicleA?.company !== vehicleB?.company) {
      differentValues.push('Firma');
    }

    if (vehicleA?.active !== vehicleB.active) {
      differentValues.push('Aktiv');
    }

    if (
      LicensePlate.getDifferentValues(
        vehicleA?.licensePlate,
        vehicleB?.licensePlate,
      ).length > 0
    ) {
      differentValues.push('Kfz-Kennzeichen');
    }

    const differentOrganisationalGroups = ArrayUtils.getDifference(
      vehicleA?.organisationalGroups,
      vehicleB?.organisationalGroups,
    );
    if (
      differentOrganisationalGroups[0].length > 0 ||
      differentOrganisationalGroups[1].length > 0
    ) {
      differentValues.push('Organisations-Gruppen');
    }

    return differentValues;
  }

  static IS_ACTIVE = {
    YES: 'Ja',
    NO: 'Nein',
  };
}
