import axios from '~/utils/api-client';
import Config from '~/Config';

const SETTINGS_URL = Config.apiUrl + '/user/settings';

class UserTypeService {
  async updateUserType(userType) {
    return axios.post(SETTINGS_URL, { user_type: userType });
  }
}

export default new UserTypeService();
