import React from 'react';

import { DoNotDisturbAlt as DoNotDisturbAltIcon } from '@mui/icons-material';

import { LightTooltip } from '~/utils/componentUtils';

export const InactiveIcon = () => (
  <LightTooltip title="Inaktiv">
    <DoNotDisturbAltIcon className="text-warningBase" fontSize="small" />
  </LightTooltip>
);
