import { useState } from 'react';

import { LocationOff as LocationOffIcon } from '@mui/icons-material';

import IconButton from '~/components/IconButton';

import Log from '~/utils/Log';
import ValueGroup from '~/models/deliveries/ValueGroup';

import { BlacklistSiteForm } from '../../deliveryNoteActions/BlacklistSiteForm';

export const BlacklistSiteButton = ({ deliveryNote, refreshDeliveryNote }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    Log.info('Open blacklist site form');
    Log.productAnalyticsEvent(
      'Open blacklist site form',
      Log.FEATURE.SITE_MAPPING,
    );

    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        tooltipTitle="Standort von Zuordnung ausschließen."
        size="large"
      >
        <LocationOffIcon className="text-primary500 icon-small" />
      </IconButton>
      <BlacklistSiteForm
        closeForm={handleClose}
        defaultSelectedSiteId={ValueGroup.getCurrentValue(
          deliveryNote.permittedToSites[0]?.id,
        )}
        deliveryNote={deliveryNote}
        open={isOpen}
        refreshDeliveryNote={refreshDeliveryNote}
        toSiteSupplierName={ValueGroup.getCurrentValue(
          deliveryNote?.toSiteSupplier?.name,
        )}
      />
    </>
  );
};
