import axios from '~/utils/api-client';
import Config from '~/Config';
import UserGroup from '~/models/masterdata/UserGroup';
import ArrayUtils from '~/utils/arrayUtils';
import { promiseHandler } from '~/utils/promiseHandler';
import CacheService from './cache.service';
import Log from '~/utils/Log';
import store from '~/redux/store';
import { LOADING_STATE } from '~/constants/LoadingState';
import {
  replaceUserGroups,
  setUserGroupsLoading,
  replaceUserGroup,
} from '~/redux/userGroupsSlice';
import { es6ClassFactory as ES6ClassFactory } from '~/utils/ES6ClassFactory';
import PromiseUtils from '~/utils/promiseUtils';

const API_URL = Config.apiUrl + '/access_control/user_group';

class UserGroupService {
  constructor() {
    this.userGroupsLoading = LOADING_STATE.NOT_LOADED;
    this.userGroups = [];
  }

  async getAllUserGroups() {
    return axios.get(API_URL).then((response) => {
      if (response.status !== 200) {
        return [];
      }

      return response.data.user_groups.map((item) => new UserGroup(item));
    });
  }

  getUserGroupById = async (userGroupId) => {
    let userGroup = store
      .getState()
      .userGroups?.userGroups?.find(
        (userGroup) => userGroup.id === userGroupId,
      );
    // For more information about why user groups are cached locally in UserGroupService, check the comments in SiteService.getSiteById.
    if (
      !userGroup &&
      store.getState().userGroups?.userGroupsLoading ===
        LOADING_STATE.NOT_LOADED
    ) {
      userGroup = this.userGroups.find(
        (userGroup) => userGroup.id === userGroupId,
      );
    }

    if (userGroup) {
      return ES6ClassFactory.convertToES6Class([userGroup], new UserGroup())[0];
    }

    if (
      store.getState().userGroups?.userGroupsLoading ===
        LOADING_STATE.SUCCEEDED ||
      store.getState().userGroups?.userGroupsLoading === LOADING_STATE.FAILED
    ) {
      return null;
    }

    if (
      this.userGroupsLoading === LOADING_STATE.SUCCEEDED ||
      this.userGroupsLoading === LOADING_STATE.FAILED
    ) {
      return null;
    }

    store.dispatch(setUserGroupsLoading(LOADING_STATE.LOADING));
    this.userGroupsLoading = LOADING_STATE.LOADING;

    const [userGroups, error] = await promiseHandler(this.getAllUserGroups());

    if (error) {
      store.dispatch(setUserGroupsLoading(LOADING_STATE.FAILED));
      this.userGroupsLoading = LOADING_STATE.FAILED;
      throw error;
    }

    store.dispatch(replaceUserGroups(userGroups));
    this.userGroupsLoading = LOADING_STATE.SUCCEEDED;
    this.userGroups = userGroups;

    return userGroups.find((userGroup) => userGroup.id === userGroupId) ?? null;
  };
  getUserGroup = async (userGroupId, ignoreCache) => {
    const url = API_URL + '/' + userGroupId;

    if (!ignoreCache) {
      const [cachedValue, error] = CacheService.getCached(url);
      if (cachedValue) {
        return cachedValue;
      }

      if (error) {
        throw error;
      }
    }

    return axios
      .get(url)
      .then((response) => {
        if (response.status !== 200) {
          Log.warn('GET /access_control/user_group did not return 200', {
            status: response.status,
          });
        }

        const userGroup = new UserGroup(response.data);
        CacheService.setCached(url, userGroup);
        return userGroup;
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  };

  // Deprecated: Was used to load user groups for the user group path
  getUserGroups(userGroupIds) {
    return axios
      .post(API_URL + '/query/ids', { ids: userGroupIds })
      .then((response) => {
        return (
          response.data.items?.map((userGroup) => new UserGroup(userGroup)) ??
          []
        );
      });
  }

  loadUserGroups = async () => {
    // to not load organisational groups again when they are already loading or have already been loaded
    if (
      store.getState().userGroups?.userGroupsLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshUserGroups();
  };
  refreshUserGroups = async () => {
    store.dispatch(setUserGroupsLoading(LOADING_STATE.LOADING));

    const [userGroups, error] = await promiseHandler(this.getAllUserGroups());

    if (error) {
      store.dispatch(setUserGroupsLoading(LOADING_STATE.FAILED));
      Log.error('Failed to refresh user groups.', error);
      Log.productAnalyticsEvent(
        'Failed to load user groups',
        Log.FEATURE.USER_GROUP,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceUserGroups(userGroups));
  };
  refreshUserGroup = async (userGroupId) => {
    const [userGroup, error] = await promiseHandler(
      this.getUserGroup(userGroupId, true),
    );

    if (error) {
      Log.error('Failed to refresh user group. id: ' + userGroupId, error);
      Log.productAnalyticsEvent(
        'Failed to load user group',
        Log.FEATURE.USER_GROUP,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceUserGroup(userGroup));
  };

  async createUserGroup(body) {
    return axios.post(API_URL, body).then((response) => {
      return response.data?.id;
    });
  }

  async updateUserGroup(id, body) {
    return axios.put(API_URL + '/' + id, body);
  }

  async deleteUserGroup(id) {
    return axios.delete(API_URL + '/' + id);
  }

  // Updating the parent user groups must be a separate function to updateEntities due to the following reason:
  // The order is inverted. The userGroupId is assigned as the child to another user group.
  async updateParentUserGroups(
    subjectId,
    subjectType,
    oldUserGroups = [],
    newUserGroups = [],
  ) {
    const [deletedUserGroups, addedUserGroups] = ArrayUtils.getDifference(
      oldUserGroups,
      newUserGroups,
    );

    const promises = [];

    for (const deletedUserGroup of deletedUserGroups) {
      promises.push(
        this.deleteEntity(deletedUserGroup, subjectType, subjectId),
      );
    }

    for (const addedUserGroup of addedUserGroups) {
      promises.push(this.addEntity(addedUserGroup, subjectType, subjectId));
    }

    return PromiseUtils.allResolved(promises);
  }

  async updateEntities(userGroupId, entityType, oldEntities, newEntities) {
    const [deletedEntities, addedEntities] = ArrayUtils.getDifference(
      oldEntities,
      newEntities,
    );

    const promises = [];

    for (const deletedEntity of deletedEntities) {
      promises.push(this.deleteEntity(userGroupId, entityType, deletedEntity));
    }

    for (const addedEntity of addedEntities) {
      promises.push(this.addEntity(userGroupId, entityType, addedEntity));
    }

    return PromiseUtils.allResolved(promises);
  }

  async addEntity(userGroupId, entityType, entityId) {
    return axios.post(API_URL + '/' + userGroupId + '/' + entityType, {
      member_id: entityId,
    });
  }

  async deleteEntity(userGroupId, entityType, entityId) {
    return axios.delete(
      API_URL + '/' + userGroupId + '/' + entityType + '/' + entityId,
    );
  }
}

export default new UserGroupService();
