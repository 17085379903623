import { useAuth } from 'oidc-react';

import Config from '~/Config';
import { LightTooltip } from '~/utils/componentUtils';
import Log from '~/utils/Log';

import { LogoWithText } from '../../VestigasLogo';

import { BRAND_COLOR } from './constants';

export const PageHeader = () => {
  const auth = useAuth();

  const logout = () => {
    Log.productAnalyticsEvent('Logout', Log.FEATURE.AUTHENTICATION);
    auth.signOutRedirect({
      post_logout_redirect_uri: Config.redirectUrl,
    });
  };

  return (
    <div
      className="flex-0 text-white"
      style={{ background: BRAND_COLOR, padding: '1vw' }}
    >
      <div className="align-center flex w-full flex-1 justify-between">
        <LightTooltip title={'Ausloggen'}>
          <button
            className="cursor-pointer border-0 bg-transparent text-white"
            onClick={logout}
          >
            <LogoWithText style={{ height: '4vw' }} />
          </button>
        </LightTooltip>
        <h1
          className="m-0 grow text-center font-normal"
          style={{
            flex: 4,
            fontSize: '3vw',
          }}
        >
          {'Abholer-Übersicht'}
        </h1>
        <div className="flex-1" />
      </div>
    </div>
  );
};
