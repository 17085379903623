import React from 'react';

import InvoiceCheckResult from '~/models/invoices/InvoiceCheckResult';

import { withErrorBoundary } from '~/ui/atoms';
import InvoiceCheckIcon from '../InvoiceCheckIcon';
import InvoiceCheckSummaryText from './InvoiceCheckSummaryText';

import { EyeIcon } from '~/assets/icons';

import InvoiceCheckButtons from '../InvoiceCheckButtons';

class InvoiceCheckSummary extends React.Component {
  getComponent(category) {
    const active = this.props.activeInvoiceCheckCategories.includes(
      category.name,
    );

    return (
      <div
        className="flex-sb-c gap-20px transition-500ms pl-20px pr-20px cursor-pointer"
        style={{ backgroundColor: active ? '#EBF0F9' : null }}
        onClick={() => this.props.onCategoryClick(category.name)}
      >
        <div className="flex-s-c gap-20px pt-5px pb-5px flex-1">
          <InvoiceCheckIcon
            iconKey={category.key}
            status={category.status}
            className={
              InvoiceCheckResult.getStatusColorClass(category.status) +
              ' icon-medium'
            }
          />
          <div className="flex-s-c gap-20px">
            {/* Wrapping InvoiceCheckSummaryText inside div is needed because otherwise the flexbox gap would be applied to the summary text. */}
            <div>
              <InvoiceCheckSummaryText category={category} withTooltip />
            </div>
            <InvoiceCheckButtons
              category={category}
              referencedDeliveryNotes={this.props.referencedDeliveryNotes}
            />
          </div>
        </div>
        {active ? <EyeIcon className="icon-15px text-black" /> : null}
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="text-18px mb-10px pl-20px pr-20px">
          Formale Rechnungsprüfung (nach §14 UStG)
        </div>
        {this.getComponent(this.props.invoice.checkCategory.formalCheck)}
        <div className="text-18px mb-10px pl-20px pr-20px mt-20px">
          Lieferscheinprüfung
        </div>
        {this.getComponent(this.props.invoice.checkCategory.dlnCheck)}
        {this.getComponent(this.props.invoice.checkCategory.signatureCheck)}
        <div className="text-18px mb-10px pl-20px pr-20px mt-20px">
          Mengenprüfung
        </div>
        {this.getComponent(this.props.invoice.checkCategory.articleExistsCheck)}
        {this.getComponent(this.props.invoice.checkCategory.amountCheck)}
        {this.getComponent(
          this.props.invoice.checkCategory.amountApprovedCheck,
        )}
        <div className="text-18px mb-10px pl-20px pr-20px mt-20px">
          Preisprüfung
        </div>
        {this.getComponent(this.props.invoice.checkCategory.priceCheck)}
      </>
    );
  }
}

export default withErrorBoundary(
  InvoiceCheckSummary,
  'Die Zusammenfassung der Rechnungsprüfung konnte nicht geladen werden.',
);
