import React from 'react';
import { withErrorBoundary } from '~/ui/atoms';
import BilledItem from '~/models/billingState/BilledItem';
import { SettledIcon } from '~/assets/icons';
import Log from '~/utils/Log';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import InvoicesService from '~/services/invoices.service';
import InvoiceModel from '~/models/invoices/Invoice';
import { promiseHandler } from '~/utils/promiseHandler';
import { InvoiceLinks } from '~/utils/componentUtils';

class DeliveryNoteArticleList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      referencedInvoices: [],
    };
  }

  componentDidMount() {
    this.loadReferencedInvoices();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.deliveryNote?.referencedInvoices) !==
      JSON.stringify(prevProps.deliveryNote?.referencedInvoices)
    ) {
      this.loadReferencedInvoices();
    }
  }

  async loadReferencedInvoices() {
    const referencedInvoices = [];

    for (
      let index = 0;
      index < this.props.deliveryNote?.referencedInvoices?.length;
      index++
    ) {
      const [invoice, error] = await promiseHandler(
        InvoicesService.getInvoiceById(
          this.props.deliveryNote.referencedInvoices[index],
          InvoiceModel.DIRECTION.INCOMING,
        ),
      );

      if (error) {
        Log.error(
          'Failed to load invoice by id: ' +
            this.props.deliveryNote.referencedInvoices[index],
        );
        Log.productAnalyticsEvent(
          'Failed to load invoice',
          Log.FEATURE.DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );
        continue;
      }

      referencedInvoices.push(invoice);
    }

    this.setState({
      referencedInvoices,
    });
  }

  render() {
    if (
      this.props.deliveryNote?.settledStatus ===
      BilledItem.SETTLED_STATUS.FULLY_SETTLED.KEY
    ) {
      return (
        <div className="flex-s-c h-40px rounded-5px p-8px border-successBase text-successBase w-full">
          <SettledIcon className="mr-8px" />
          <span className="mr-8px">
            {BilledItem.SETTLED_STATUS.FULLY_SETTLED.DESCRIPTION}:
          </span>
          <span>
            <InvoiceLinks
              invoices={this.state.referencedInvoices}
              className="text-successBase bold"
            />
          </span>
        </div>
      );
    }

    if (
      this.props.deliveryNote?.settledStatus ===
      BilledItem.SETTLED_STATUS.PARTLY_SETTLED.KEY
    ) {
      return (
        <div className="flex-s-c h-40px rounded-5px p-8px border-warningBase text-warningBase w-full">
          <SettledIcon className="mr-8px" />
          <span className="mr-8px">
            {BilledItem.SETTLED_STATUS.PARTLY_SETTLED.DESCRIPTION}:
          </span>
          <span>
            <InvoiceLinks
              invoices={this.state.referencedInvoices}
              className="text-warningBase bold"
            />
          </span>
        </div>
      );
    }

    Log.error(
      null,
      new EnumValueNotFoundException(
        'Invalid settled status: ' + this.props.deliveryNote?.settledStatus,
      ),
    );
    Log.productAnalyticsEvent(
      'Failed to display referenced invoices',
      Log.FEATURE.DELIVERY_NOTE,
      Log.TYPE.ERROR,
    );

    return null;
  }
}

export default withErrorBoundary(
  DeliveryNoteArticleList,
  'Verknüpfte Rechnungen konnten nicht geladen werden.',
);
