import UnitUtils from '~/utils/unitUtils';
import Article from '../Article';

/**
 * Normalizes the units and values for amount, quantity, and weight to ensure valid statistics calculation.
 *
 * If the amount unit is a weight unit, it sets the weight with the amount's value and unit,
 * and normalizes the amount to '1 piece' to act as a neutral value for statistics.
 *
 * @param {Object} amount - The amount object with unit and value.
 * @param {Object} quantity - The quantity object with unit and value.
 * @param {Object} weight - The weight object with unit and value.
 * @return {Object} An object containing the normalized amount, quantity, and weight units and values.
 */
export const normalizeUnitsAndValues = ({
  amount = { unit: '', value: 0 },
  quantity = { unit: '', value: 0 },
  weight = { unit: '', value: 0 },
}) => {
  const isAmountWeightUnit = UnitUtils.isWeightUnit(amount.unit);

  return {
    amountUnit: isAmountWeightUnit ? Article.UNIT.EA.STANDARDISED : amount.unit,
    amountValue: isAmountWeightUnit ? 1 : amount.value,
    quantityUnit: quantity.unit,
    quantityValue: quantity.value,
    weightUnit: isAmountWeightUnit ? amount.unit : weight.unit,
    weightValue: isAmountWeightUnit ? amount.value : weight.value,
  };
};
