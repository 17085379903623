import { useDispatch } from 'react-redux';

import ThirdPartyService from '~/services/thirdParty.service';
import UserService from '~/services/user.service';

import { LOADING_STATE } from '~/constants/LoadingState';
import { FEATURE_FLAG } from '~/constants/FeatureFlag';

import { saveCompanyAccount } from '~/redux/companyAccountSlice';
import {
  setDashboard_filterGroups,
  setDelivery_filterGroups,
  setInvoice_filterGroups,
} from '~/redux/filtersSlice';
import {
  saveArticleColorMapping,
  saveDeliveryTabs,
  saveUserActions,
  saveUserCompany,
  saveUserFeatureFlags,
  saveUserPermissions,
  saveUserType,
  setUserinfoLoading,
  setUserPermissionsLoading,
  updateUserInfos,
} from '~/redux/userinfoSlice';

import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';

import { useLoadDeliveryNotes } from './useLoadDeliveryNotes';
import { useLoadDeliveryNoteTemplates } from './useLoadDeliveryNoteTemplates';
import { useSaveLoadedUserData } from './useSaveLoadedUserData';

/**
 * Custom hook for loading user data.
 *
 * This hook provides functionality to load user data either from the window.redux_state
 * (if available and successfully loaded) or by fetching from the backend using UserService.
 * It also initializes third-party services and loads delivery notes and templates.
 *
 * @returns {Object} An object containing the loadUserData function.
 */
export const useLoadUserData = () => {
  const dispatch = useDispatch();

  const { loadDeliveryNotes } = useLoadDeliveryNotes();
  const { loadDeliveryNoteTemplates } = useLoadDeliveryNoteTemplates();
  const { saveLoadedUserData } = useSaveLoadedUserData();

  /**
   * Loads user data and associated information.
   *
   * This function checks if data is available in window.redux_state first.
   * If so, it updates the Redux store with this data. Otherwise, it fetches
   * the data from the backend using UserService.
   *
   * @returns {Promise<void>}
   */
  async function loadUserData() {
    const { FAILED, LOADING, SUCCEEDED } = LOADING_STATE;

    if (window.redux_state) {
      /**
       * Opening an item in a new browser tab using BrowserUtils.openMenuItemInNewTab or BrowserUtils.openNewTab
       * passes the whole Redux state to the new tab as window.redux_state.
       * If that is the case, we don't need to load the data again from the backend,
       * but push the data from the window object into Redux state.
       */
      const {
        companyAccount: { companyAccount },
        filters: {
          dashboard_filterGroups,
          delivery_filterGroups,
          invoice_filterGroups,
        },
        userinfo: {
          articleColorMapping,
          deliveryTabs,
          userActions,
          userinfo,
          userinfoLoading,
          userPermissions,
          userPermissionsLoading,
        },
      } = window.redux_state;
      const { company, isVestigasSupport, userFeatureFlags, userType } =
        userinfo;

      if (
        userinfoLoading === SUCCEEDED &&
        userPermissionsLoading === SUCCEEDED
      ) {
        dispatch(saveArticleColorMapping({ mapping: articleColorMapping }));
        dispatch(saveCompanyAccount(companyAccount));
        dispatch(saveDeliveryTabs(deliveryTabs));
        dispatch(saveUserActions(userActions));
        dispatch(saveUserCompany(company));
        dispatch(saveUserFeatureFlags(userFeatureFlags));
        dispatch(saveUserPermissions(userPermissions));
        dispatch(saveUserType(userType));
        dispatch(setDashboard_filterGroups(dashboard_filterGroups));
        dispatch(setDelivery_filterGroups(delivery_filterGroups));
        dispatch(setInvoice_filterGroups(invoice_filterGroups));
        dispatch(updateUserInfos(userinfo));

        ThirdPartyService.initSentryCompany(company.name);
        ThirdPartyService.initProductAnalyticsTools(
          companyAccount.featureFlags[FEATURE_FLAG.PRODUCT_ANALYTICS_TOOLS],
          company.name,
          isVestigasSupport,
        );

        Log.info('Userinfo state passed via window.redux_state.');

        loadDeliveryNotes(true);
        loadDeliveryNoteTemplates();

        return;
      }
    }

    dispatch(setUserPermissionsLoading(LOADING));
    dispatch(setUserinfoLoading(LOADING));

    // Load data of current user from the backend.
    const [response, error] = await promiseHandler(UserService.getData());

    if (error) {
      dispatch(setUserPermissionsLoading(FAILED));
      dispatch(setUserinfoLoading(FAILED));

      return;
    }

    // Save loaded data of current user into Redux state.
    saveLoadedUserData(response);
  }

  return {
    loadUserData,
  };
};
