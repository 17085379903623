import { promiseHandler } from '~/utils/promiseHandler';
import IdsCredentials from '~/models/ids/IdsCredentials';
import axios from '~/utils/api-client';
import Config from '~/Config';
import Log from '~/utils/Log';

class IdsService {
  async redirectWithIdsCredentials(companyId, accessToken, action) {
    const [idsCredentials, error] = await promiseHandler(
      this.getIdsCredentialsFromUserSettings(companyId, accessToken),
    );

    if (error) {
      throw error;
    }

    this.redirect(idsCredentials, action);
  }

  async getIdsCredentialsFromUserSettings(companyId, accessToken) {
    const [response, error] = await promiseHandler(
      axios.get(Config.apiUrl + '/user/settings', {
        headers: { Authorization: 'Bearer ' + accessToken },
        withCredentials: true,
      }),
    );

    if (error) {
      Log.error('Failed to load user settings', error);
      throw error;
    }

    return new IdsCredentials(companyId, response.data.ids?.[companyId]);
  }

  redirect(idsCredentials, action) {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = idsCredentials.redirectUrl;
    form.target = '_self';
    form.enctype = 'multipart/form-data';

    const inputAction = document.createElement('input');
    inputAction.type = 'hidden';
    inputAction.name = 'action';
    inputAction.value = action;
    form.append(inputAction);

    for (const queryParameter of idsCredentials.queryParams) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = queryParameter.key;
      input.value = queryParameter.value;
      form.append(input);
    }

    // Append the form to the body and submit
    document.body.append(form);
    form.submit();

    // Clean up after form submission
    form.remove();
  }
}

export default new IdsService();
