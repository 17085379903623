import DataSubscriptionDateFilterClause from './DataSubscriptionDateFilterClause';
import DataSubscriptionFilterClause from './DataSubscriptionFilterClause';

export default class DataSubscription {
  constructor(dataSubscription) {
    this.id = dataSubscription?.id ?? null;
    this.companyAccountId = dataSubscription?.company_account_id ?? null;
    this.name = dataSubscription?.name ?? null;
    this.trigger =
      dataSubscription?.trigger ??
      DataSubscription.TRIGGER.PROCESS_STATE_CHANGE.KEY;
    this.subscriptionEvents = dataSubscription?.subscription_events ?? [];
    this.active = dataSubscription?.is_active ?? true;
    this.filterUnion =
      dataSubscription?.filter_union ?? DataSubscription.FILTER_UNION.AND.KEY;
    this.filterClauses =
      dataSubscription?.filter_clauses?.map(
        (filterClause) => new DataSubscriptionFilterClause(filterClause),
      ) ?? [];
    this.dateFilterClauses =
      dataSubscription?.date_filter_clauses?.map(
        (dateFilterClause) =>
          new DataSubscriptionDateFilterClause(dateFilterClause),
      ) ?? [];
  }

  static getDifferentValues(dataSubscriptionA, dataSubscriptionB) {
    const differentValues = [];

    if (dataSubscriptionA?.id !== dataSubscriptionB?.id) {
      differentValues.push('ID');
    }

    if (
      dataSubscriptionA?.companyAccountId !==
      dataSubscriptionB?.companyAccountId
    ) {
      differentValues.push('Firmenaccount');
    }

    if (dataSubscriptionA?.name !== dataSubscriptionB?.name) {
      differentValues.push('Name');
    }

    if (dataSubscriptionA?.trigger !== dataSubscriptionB?.trigger) {
      differentValues.push('Auslöser');
    }

    if (
      JSON.stringify(dataSubscriptionA?.subscriptionEvents) !==
      JSON.stringify(dataSubscriptionB?.subscriptionEvents)
    ) {
      differentValues.push('Events');
    }

    if (dataSubscriptionA?.active !== dataSubscriptionB?.active) {
      differentValues.push('Aktiv');
    }

    if (dataSubscriptionA?.filterUnion !== dataSubscriptionB?.filterUnion) {
      differentValues.push('und/oder');
    }

    if (
      JSON.stringify(dataSubscriptionA?.filterClauses) !==
      JSON.stringify(dataSubscriptionB?.filterClauses)
    ) {
      differentValues.push('Filterklauseln');
    }

    if (
      JSON.stringify(dataSubscriptionA?.dateFilterClauses) !==
      JSON.stringify(dataSubscriptionB?.dateFilterClauses)
    ) {
      differentValues.push('Filterklauseln (Zeitraum)');
    }

    return differentValues;
  }

  static TRIGGER = {
    PROCESS_STATE_CHANGE: {
      KEY: 'process_state_change',
      STRING: 'Statusänderung',
      DESCRIPTION:
        'Wird ausgelöst, wenn sich der Status (Ausgabe, Geliefert, ...) eines Lieferscheins ändert.',
    },
  };
  static SUBSCRIPTION_EVENT = {
    READY_FOR_OUTPUT: {
      KEY: 'readyForOutput',
      STRING: 'Ausgabe',
      DESCRIPTION: 'Wird ausgelöst, wenn Lieferschein erstellt wird.',
    },
    DELIVERED: {
      KEY: 'delivered',
      STRING: 'Geliefert',
      DESCRIPTION: 'Wird ausgelöst, wenn Lieferschein signiert wird.',
    },
  };
  static FILTER_UNION = {
    AND: {
      KEY: 'and',
      STRING: 'und',
    },
    OR: {
      KEY: 'or',
      STRING: 'oder',
    },
  };
}
