import { parseISO, isAfter, subHours } from 'date-fns';

import {
  DISPLAY_IF_YOUNGER_THAN_HOURS,
  PICKUP_NUMBER_KEY,
  PICKUP_NUMBER_KEY_FALLBACK,
} from './constants';

const getPickupNumberText = (dln) => {
  if (dln?.[PICKUP_NUMBER_KEY]) {
    return dln[PICKUP_NUMBER_KEY];
  }

  if (dln?.[PICKUP_NUMBER_KEY_FALLBACK]) {
    // If there is no pickup number, but we have a fallback key, return last 3 digits of fallback key.
    return `L${dln[PICKUP_NUMBER_KEY_FALLBACK].slice(-3)}`;
  }

  return 'n/a';
};

/**
 * Checks if a given modified date is recent by comparing it against a cutoff time.
 *
 * @param {Object} modifiedDate - The modified date to check.
 * @param {string} modifiedDate.modifiedDate - The modified date in ISO format.
 * @return {boolean} Returns true if the modified date is recent, false otherwise.
 */
const isRecent = ({ modifiedDate }) => {
  const currentDate = new Date();
  const parsedDate = parseISO(modifiedDate);

  return isAfter(
    parsedDate,
    subHours(currentDate, DISPLAY_IF_YOUNGER_THAN_HOURS),
  );
};

/**
 * Filters a list of delivery rows for specified state,
 * picks the pickup number from each row,
 * and sorts the results numerically in descending order.
 *
 * @param {Array} deliveryRows - An array of delivery rows.
 * @param {string} processState - The process state to filter by.
 * @return {Array} An array of pickup numbers sorted in descending order.
 */
export const dlnsForProcessState = (deliveryRows, processState) =>
  Array.isArray(deliveryRows)
    ? deliveryRows
        .filter(({ processState: state }) => state === processState)
        .filter(isRecent)
        .map(getPickupNumberText)
        .sort((a, b) => Number(b) - Number(a))
    : [];
