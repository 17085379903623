import { useAuth } from 'oidc-react';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Log from '~/utils/Log';
import Config from '~/Config';

import DataExchanges from './DataExchanges';

export const ExternalSupplierOverview = (props) => {
  const useStyles = makeStyles((theme) => ({
    appBar: {
      backgroundColor: '#fff',
      color: '#343A4D',
      marginLeft: props.drawerWidth,
      transition: 'width 1s ease-in-out',
      width: '100%',
      // This fixed a bug. Without it, the burger menu would move to the left when the date range select
      // in the home screen is opened (in case of a screen width of around 729px).
      paddingRight: '0 !important',
    },
  }));

  const classes = useStyles();
  const auth = useAuth();

  const logout = () => {
    Log.productAnalyticsEvent('Logout', Log.FEATURE.AUTHENTICATION);
    auth.signOutRedirect({
      post_logout_redirect_uri: Config.redirectUrl,
    });
  };

  return (
    <div className="flex h-full min-h-dvh flex-col pt-16">
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div className="flex w-full items-center justify-between">
            <span className="text-4xl">Lieferantenübersicht</span>
            <IconButton onClick={logout} size="large">
              <LogoutIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <div className="flex-1">
        <DataExchanges externalSupplierOverview />
      </div>
    </div>
  );
};
