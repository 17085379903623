import ToastService from './toast.service';
import Log from '~/utils/Log';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

class MasterDataService {
  propertiesAreMissing(body, properties, productAnalyticsFeature) {
    let propertiesAreMissing = false;

    for (const property of properties) {
      if (body[property]) {
        continue;
      }

      propertiesAreMissing = true;

      switch (property) {
        case 'company_id': {
          ToastService.warning(['Bitte wähle eine Firma aus.']);
          Log.productAnalyticsEvent(
            'Missing company',
            productAnalyticsFeature,
            Log.TYPE.FAILED_VALIDATION,
          );
          break;
        }

        /* case 'org_units':
                    ToastService.warning(['Bitte wähle eine Organisations-Gruppe aus, ...']);
                    break; */
        default: {
          Log.error(
            null,
            new EnumValueNotFoundException('Invalid property: ' + property),
          );
          false;
          continue;
        }
      }
    }

    return propertiesAreMissing;
  }
}

export default new MasterDataService();
