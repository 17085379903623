import React from 'react';
import { withErrorBoundary } from '~/ui/atoms';

class Impressum extends React.Component {
  render() {
    return (
      <div className="ml-2rem mr-2rem">
        <div className="main-header">Impressum</div>
        <div className="box-shadow-blue rounded-5px p-20px pt-10px bg-white">
          <h3 className="main-text">Angaben gemäß § 5 TMG:</h3>
          <div className="bold">VESTIGAS GmbH</div>
          <div>Dorf Zellhub 48</div>
          <div>84307 Eggenfelden</div>
          <div className="mt-20px">
            <span className="bold">Registergericht: </span>Landshut
          </div>
          <div>
            <span className="bold">HRB: </span>12875
          </div>
          <div className="bold mt-20px">Kontakt:</div>
          <div>Telefon: +49 (0)8721 507533</div>
          <div>E-Mail: info@vestigas.com</div>
          <div className="bold mt-20px">
            Vertreten durch die Geschäftsführer:
          </div>
          <div>Julian Blum, Paul Kaiser</div>
          <div className="bold mt-20px">Umsatzsteuer:</div>
          <div>
            Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetzt:
          </div>
          <div>DE346828280</div>
          <div className="bold mt-20px">Büro:</div>
          <div>Lindwurmstraße 44</div>
          <div>80337 München</div>
          <div>Telefon: +49 (0)89 9589 3094</div>
          <h3 className="main-text border-top pt-20px mt-20px">
            Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
          </h3>
          <div className="bold">VESTIGAS GmbH</div>
          <div>Dorf Zellhub 48</div>
          <div>84307 Eggenfelden</div>
          <div className="bold mt-20px">Kontakt:</div>
          <div>Telefon: +49 (0)8721 507533</div>
          <div>E-Mail: info@vestigas.com</div>
        </div>
      </div>
    );
  }
}

export default withErrorBoundary(
  Impressum,
  'Impressum konnte nicht geladen werden.',
);
