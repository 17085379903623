import clsx from 'clsx';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { connect } from 'react-redux';
import {
  getGridStringOperators,
  gridExpandedSortedRowIdsSelector,
} from '@mui/x-data-grid-pro';

import { LOADING_STATE } from '~/constants/LoadingState';
import { ROUTE } from '~/constants/Route';

import { saveDeliveryChanges } from '~/redux/deliveryNotesSlice';
import {
  setDeliveryChanges_sortModel,
  setDeliveryChanges_filterModel,
} from '~/redux/filtersSlice';

import DeliveryNote from '~/models/deliveries/DeliveryNote';
import ValueGroup from '~/models/deliveries/ValueGroup';
import DeliveryNoteAction from '~/models/deliveries/DeliveryNoteAction';

import LocalStorageService from '~/services/localStorage.service';
import CustomFieldService from '~/services/customField.service';
import DeliveriesService from '~/services/deliveries.service';
import FeatureService from '~/services/feature.service';
import ToastService from '~/services/toast.service';

import { dateUtils, parseDate } from '~/utils/dateUtils';
import { promiseHandler } from '~/utils/promiseHandler';
import ArrayUtils from '~/utils/arrayUtils';
import BrowserUtils from '~/utils/browserUtils';
import DatagridUtils from '~/utils/datagridUtils';
import Log from '~/utils/Log';

import { withErrorBoundary } from '~/ui/atoms';
import BasicTable from '~/components/BasicTable';
import ClientPortalMessage from '~/components/salesPackages/clientPortal/clientPortalMessage';
import ContextMenu from '~/components/menu/ContextMenu';
import { Spinner } from '~/components/Spinner';

import DeliveryStatus from '../../DeliveryStatus';

const mapStateToProps = ({
  companyAccount,
  deliveryNotes,
  filters: {
    deliveryChanges_sortModel,
    deliveryChanges_filterModel,
    delivery_dateRange,
    selectedSites,
    selectedCostCenters,
  },
}) => ({
  companyAccount, // subscribe to companyAccount state so that update of clientPortal feature flag force a rerender
  dateRange: delivery_dateRange,
  deliveryNotes,
  filterModel: deliveryChanges_filterModel,
  selectedCostCenters,
  selectedSites,
  sortModel: deliveryChanges_sortModel,
});

const mapDispatchToProps = () => ({
  saveDeliveryChanges,
  setDeliveryChanges_sortModel,
  setDeliveryChanges_filterModel,
});

// This class represents the overview/table of all delivery notes
class DeliveryChangesComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contextMenu: null,
      loading: LOADING_STATE.NOT_LOADED,
      loadingIsComplete: false,
      minRowsToScroll: 0,
      offset: 0,
      rows: [],
      rowSelectionModel: [],
    };

    this.visibleRowCount = 0;

    this.PAGINATION_SIZE = 100;
  }

  componentDidMount() {
    // height of table is needed to calculate how many rows must be loaded until pagination triggered by scrolling is possible
    const tableHeight =
      document.querySelector('#changes-datagrid').clientHeight;
    this.setState({
      minRowsToScroll:
        DatagridUtils.getRowCountByTableHeight(
          tableHeight,
          LocalStorageService.DELIVERY_CHANGES,
        ) + 3, // 3 as buffer
    });

    this.loadStartRows();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.deliveryNotes.deliveryNotesLoading ===
        LOADING_STATE.SUCCEEDED &&
        prevProps.deliveryNotes.deliveryNotesLoading ===
          LOADING_STATE.LOADING) ||
      JSON.stringify(this.props.dateRange) !==
        JSON.stringify(prevProps.dateRange)
    ) {
      this.loadStartRows();
    }

    // automatic loading of next rows if table isn't scrollable yet
    if (
      this.state.loading !== LOADING_STATE.LOADING &&
      prevState.loading === LOADING_STATE.LOADING &&
      this.visibleRowCount < this.state.minRowsToScroll
    ) {
      this.loadNextRows();
    }
  }

  // start to load rows with pagination of 100 DLNs
  loadStartRows = () => {
    this.setState({
      rows: [],
      offset: 0,
    });

    this.loadPaginatedRows([], 0).catch((error) => {
      this.setState({
        loading: LOADING_STATE.FAILED,
      });
      Log.error('Failed to load first rows of delivery changes.', error);
      Log.productAnalyticsEvent(
        'Failed to load delivery changes',
        Log.FEATURE.DELIVERY_CHANGES,
        Log.TYPE.ERROR,
      );
    });
  };
  // load the next set of 100 dlns and transform to rows for changes list, only if pagination isn't finished already
  loadNextRows = () => {
    if (this.state.loading === LOADING_STATE.FAILED) {
      return;
    }

    if (
      this.state.loading === LOADING_STATE.LOADING ||
      this.state.loadingIsComplete
    ) {
      return;
    }

    Log.productAnalyticsEvent(
      'Load delivery changes',
      Log.FEATURE.DELIVERY_CHANGES,
    );

    this.loadPaginatedRows(this.state.rows, this.state.offset).catch(
      (error) => {
        ToastService.error([
          ToastService.MESSAGE.DLN_CHANGES_LOAD_FAILED,
          ToastService.MESSAGE.CONTACT_SUPPORT,
        ]);
        this.setState({
          loading: LOADING_STATE.FAILED,
        });
        Log.error('Failed to load next rows of delivery changes.', error);
        Log.productAnalyticsEvent(
          'Failed to load delivery changes',
          Log.FEATURE.DELIVERY_CHANGES,
          Log.TYPE.ERROR,
        );
      },
    );
  };
  // load 100 dlns and transform to rows for changes list
  loadPaginatedRows = async (rows, offset) => {
    Log.info(
      'Load the next paginated set of delivery note changes.',
      { offset },
      null,
    );

    this.setState({
      loading: LOADING_STATE.LOADING,
    });

    const [deliveryNotes, error] = await promiseHandler(
      DeliveriesService.getFilteredDlnsForDeliveryChanges(
        this.props.selectedSites,
        this.props.selectedCostCenters,
        this.props.dateRange,
        offset,
        this.PAGINATION_SIZE,
      ),
    );

    if (error) {
      throw error;
    }

    const newRows = [];
    const dlnIds = [];

    for (let index = 0; index < this.PAGINATION_SIZE; index++) {
      if (deliveryNotes[index]) {
        dlnIds.push(deliveryNotes[index].id);
      }
    }

    const [chains, error2] = await promiseHandler(
      DeliveriesService.getDeliveryNoteChainsByDlnIds(dlnIds),
    );

    if (error2) {
      throw error2;
    }

    const [_, error3] = await promiseHandler(
      this.loadCustomFieldsBulk(deliveryNotes, chains),
    );

    if (error3) {
      throw error3;
    }

    for (let index = 0; index < this.PAGINATION_SIZE; index++) {
      if (!deliveryNotes[index]) {
        break;
      }

      const dlnChains = chains.filter(
        ({ asset_id }) => asset_id === deliveryNotes[index].id,
      );

      const [row, error3_] = await promiseHandler(
        this.initDln(deliveryNotes[index], dlnChains),
      );

      if (error3_) {
        Log.error(
          'Failed to initialize delivery note in dln changes list.',
          error3_,
        );
        Log.productAnalyticsEvent(
          'Failed to initialize delivery note',
          Log.FEATURE.DELIVERY_CHANGES,
          Log.TYPE.ERROR,
        );
        continue;
      }

      if (row?.hasOwnProperty('id')) {
        newRows.push(row);
      }
    }

    this.props.saveDeliveryChanges(newRows);

    const filteredRows = this.filterRows([...rows, ...newRows]);
    const formattedRows = this.formatRows(filteredRows);

    this.setState({
      rows: formattedRows,
      offset: offset + this.PAGINATION_SIZE,
      loading: LOADING_STATE.SUCCEEDED,
      loadingIsComplete: deliveryNotes.length < this.PAGINATION_SIZE,
    });
  };

  async loadCustomFieldsBulk(deliveryNotes, chains) {
    let customFieldIds = [];

    for (const deliveryNote of deliveryNotes) {
      customFieldIds.push(...deliveryNote.getCustomFieldIds());
    }

    for (const chain of chains) {
      customFieldIds.push(...DeliveryNote.getCustomFieldIdsFromChain(chain));
    }

    customFieldIds = ArrayUtils.removeDuplicates(customFieldIds);

    const [_, error] = await promiseHandler(
      CustomFieldService.loadCustomFieldsBulk(customFieldIds),
    );

    if (error) {
      throw error;
    }
  }

  // transform a dln into a dln change in the changes list
  async initDln(dln, chains) {
    const storedRow = this.props.deliveryNotes.deliveryChanges.find(
      ({ id }) => id === dln.id,
    );
    if (storedRow) {
      return cloneDeep(storedRow);
    }

    const [_, error] = await promiseHandler(this.addChangesToDln(dln, chains));

    if (error) {
      throw error;
    }

    const { getCurrentValue } = ValueGroup;
    const {
      acceptState,
      changes,
      combinedState,
      id,
      modifiedDate,
      number,
      processState,
    } = dln;

    if (changes.length === 0) {
      return;
    }

    const status = [processState, acceptState, combinedState]
      .map(getCurrentValue)
      .join(';');

    return {
      changedBy: '',
      changedDate: parseDate(getCurrentValue(modifiedDate)),
      changedFields: '',
      changes,
      id: getCurrentValue(id),
      number: getCurrentValue(number),
      status,
    };
  }

  async addChangesToDln(deliveryNote, chains) {
    for (const [index, chain] of chains.entries()) {
      const deliveryNoteAction = new DeliveryNoteAction(chain, deliveryNote);
      await deliveryNoteAction.initCompany();

      await deliveryNote.addChainToHistory(
        chain,
        deliveryNoteAction.company?.name,
        index === 0,
      );
    }

    deliveryNote.mergeHistory();
    deliveryNote.setChanges(true);
  }

  filterRows(rows) {
    const timeframe = dateUtils.extractTimeframe(this.props.dateRange);

    const mappedRows = rows.map((row) => {
      let changes = row.changes.map((change) => {
        const filteredHistory = change.history.filter(
          (value) =>
            Date.parse(value.datetime) >= timeframe.from &&
            Date.parse(value.datetime) <= timeframe.to,
        );

        return {
          ...change,
          companies: filteredHistory.map(({ company }) => company),
        };
      });

      changes = changes.filter(({ companies }) => companies.length);

      return {
        ...row,
        changes,
      };
    });

    const filteredRows = mappedRows.filter(({ changes }) => changes.length);
    // Filter out duplicate rows because they can cause nasty issues on the MUI Datagrid.
    // Duplicate shouldn't exist anyway.
    const uniqueRows = ArrayUtils.removeDuplicatesByKey(filteredRows, 'id');

    return uniqueRows;
  }

  formatRows(rows) {
    for (const row of rows) {
      row.changedFields = ArrayUtils.removeDuplicates(
        row.changes.map(({ name }) => name),
      ).join(', ');
      row.changedBy = ArrayUtils.removeDuplicates(
        row.changes.flatMap(({ companies }) => companies),
      ).join(', ');
    }

    return rows;
  }

  onRowSelectionModelChange = (event) => {
    Log.info(
      'Change selection value of selected delivery notes',
      { from: this.state.rowSelectionModel, to: event },
      Log.BREADCRUMB.SELECTION_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      '(De)select delivery note',
      Log.FEATURE.DELIVERY_CHANGES,
    );

    this.setState({
      rowSelectionModel: event,
    });
  };
  onFilterModelChange = (event) => {
    Log.info(
      'Change filter value of filter model',
      { from: this.props.filterModel, to: event },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Filter', Log.FEATURE.DELIVERY_CHANGES);
    this.props.setDeliveryChanges_filterModel(event);
  };
  onOpenDeliveryNote = (id) => {
    Log.productAnalyticsEvent(
      'Open delivery note',
      Log.FEATURE.DELIVERY_CHANGES,
    );
    this.props.history.push(ROUTE.DELIVERY_NOTE.ROUTE + '/' + id);
  };
  onOpenDeliveryNoteInNewTab = () => {
    Log.productAnalyticsEvent(
      'Open delivery note in new tab',
      Log.FEATURE.DELIVERY_CHANGES,
    );
    BrowserUtils.openNewTab(
      ROUTE.DELIVERY_NOTE.ROUTE + '/' + this.state.contextMenu.id,
    );
    this.onCloseContextMenu();
  };
  onOpenContextMenu = (event) => {
    event.preventDefault();

    if (this.state.contextMenu) {
      // Repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu. Other native context menus might behave different.
      // With this behavior we prevent contextmenu from the backdrop to re-locale existing context men
      this.setState({
        contextMenu: null,
      });

      return;
    }

    Log.productAnalyticsEvent('Open context menu', Log.FEATURE.MENU);
    this.setState({
      contextMenu: {
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
        id: event.currentTarget.dataset.id,
      },
    });
  };
  onCloseContextMenu = () => {
    Log.productAnalyticsEvent('Close context menu', Log.FEATURE.MENU);
    this.setState({
      contextMenu: null,
    });
  };
  onSortModelChange = (event) => {
    Log.productAnalyticsEvent('Sort', Log.FEATURE.DELIVERY_CHANGES);
    this.props.setDeliveryChanges_sortModel(event);
  };
  updateVisibleRowCount = (state) => {
    this.visibleRowCount = gridExpandedSortedRowIdsSelector(state).length;
  };

  getColumns() {
    return [
      {
        field: DeliveryNote.PROPERTY.STATUS.KEY,
        headerName: DeliveryNote.PROPERTY.STATUS.STRING,
        width: 200,
        sortable: true,
        renderCell({ value }) {
          if (!value) {
            return '';
          }

          const cookie = LocalStorageService.getObjectFromLocalStorage(
            LocalStorageService.DELIVERY_CHANGES,
          );
          const v = value.split(';');

          const props = {
            centerIcon: cookie?.rowHeight
              ? cookie.rowHeight <= DatagridUtils.ROW_HEIGHT.THIN
              : true,
            combinedState: v?.[2],
            processState: v?.[0],
            settledStatus: v?.[3],
            whiteBackground: true,
          };

          return (
            <div
              className={clsx([
                'w-170px',
                DatagridUtils.getStatusBoxHeight(cookie?.rowHeight),
              ])}
            >
              <DeliveryStatus {...props} />
            </div>
          );
        },
      },
      {
        field: DeliveryNote.PROPERTY.NUMBER.KEY,
        headerName: DeliveryNote.PROPERTY.NUMBER.STRING,
        width: 200,
        sortable: true,
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.CHANGED_FIELDS.KEY,
        headerName: DeliveryNote.PROPERTY.CHANGED_FIELDS.STRING,
        width: 600,
        sortable: true,
        resizableText: true,
        renderCell(params) {
          // needed because of the loading row
          return typeof params.value === 'string'
            ? DatagridUtils.displayCellTooltip(params)
            : params.value;
        },
      },
      {
        field: DeliveryNote.PROPERTY.CHANGED_BY.KEY,
        headerName: DeliveryNote.PROPERTY.CHANGED_BY.STRING,
        width: 300,
        sortable: true,
        resizableText: true,
        filterOperators: getGridStringOperators().map((operator) => {
          if (operator.value !== 'equals') {
            return operator;
          }

          return {
            ...operator,
            label: 'enthält nicht',
            getApplyFilterFn: (filterItem) => (value) =>
              // Show all, if no filter is set
              !filterItem.value ||
              // If filter set, filter according to "not contains" logic
              !value?.toLowerCase().includes(filterItem.value?.toLowerCase()),
          };
        }),
      },
      {
        field: DeliveryNote.PROPERTY.CHANGED_AT.KEY,
        headerName: DeliveryNote.PROPERTY.CHANGED_AT.STRING,
        type: 'date',
        width: 200,
        sortable: true,
        resizableText: true,
        valueGetter: parseDate,
        renderCell: ({ value }) =>
          dateUtils.getFormattedDate_safe(
            value,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
          ),
      },
    ];
  }

  getLoadingState() {
    const loadingState = this.state.loading;

    if (
      loadingState === LOADING_STATE.LOADING &&
      this.state.rows.length === 0
    ) {
      return LOADING_STATE.LOADING;
    }

    if (loadingState === LOADING_STATE.FAILED) {
      return LOADING_STATE.FAILED;
    }

    return this.props.deliveryNotes.deliveryNotesLoading;
  }

  // Display loading animation as footer because when it is a row we had the bug that the row was filtered out
  // when filters were applied.
  getFooterText() {
    if (this.state.loading !== LOADING_STATE.LOADING) {
      return null;
    }

    return (
      <Spinner
        title={
          'Suche nach Änderungen... (Lieferung ' +
          this.state.offset +
          ' bis ' +
          (this.state.offset + this.PAGINATION_SIZE) +
          ')'
        }
      />
    );
  }

  render() {
    if (FeatureService.clientPortal()) {
      return (
        <div
          className="min-h-500px mt-10px rounded-5px box-shadow-blue flex-c-c flex-1 bg-white"
          id="changes-datagrid"
        >
          <ClientPortalMessage />
        </div>
      );
    }

    const { contextMenu, rows, rowSelectionModel } = this.state;

    return (
      <div
        className="min-h-500px mt-10px rounded-5px box-shadow-blue flex-1 bg-white"
        id="changes-datagrid"
      >
        <BasicTable
          columns={this.getColumns()}
          localStorageKey={LocalStorageService.DELIVERY_CHANGES}
          filterModel={this.props.filterModel}
          footerText={this.getFooterText()}
          loading={this.getLoadingState()}
          onFilterModelChange={this.onFilterModelChange}
          onRowClick={(rowData) => this.onOpenDeliveryNote(rowData.row.id)}
          onRowRightClick={this.onOpenContextMenu}
          onRowsScrollEnd={this.loadNextRows}
          onRowSelectionModelChange={this.onRowSelectionModelChange}
          onSortModelChange={this.onSortModelChange}
          onStateChange={this.updateVisibleRowCount}
          productAnalyticsFeature={Log.FEATURE.DELIVERY_CHANGES}
          rows={rows}
          rowSelectionModel={rowSelectionModel}
          sortModel={this.props.sortModel}
          checkboxSelection
          infiniteScrolling
          withFilterModel
        />
        <ContextMenu
          contextMenu={contextMenu}
          onClose={this.onCloseContextMenu}
          onOpen={() => this.onOpenDeliveryNote(contextMenu.id)}
          onOpenInNewTab={this.onOpenDeliveryNoteInNewTab}
        />
      </div>
    );
  }
}

export const DeliveryChanges = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(DeliveryChangesComponent),
  'Lieferungsänderungen konnten nicht geladen werden.',
);
