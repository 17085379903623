import { MenuItem, Select as MuiSelect } from '@mui/material';

export default function ArticleSelect(props) {
  const EMPTY_VALUE = 'empty-value';

  const options = [{ id: EMPTY_VALUE, name: 'leer' }, ...props.options];

  const error = Boolean(props.error);

  return (
    <div className="rounded-4px bg-white">
      <MuiSelect
        value={props.value ? props.value : EMPTY_VALUE}
        onChange={props.onChange}
        fullWidth
        error={error}
        size="small"
        renderValue={(id) => {
          if (id === EMPTY_VALUE) {
            return (
              <span className="text-mui-not-selected-grey">
                {props.placeholder}
              </span>
            );
          }

          if (props.displayId) {
            return id;
          }

          return options.find((option) => option.id === id)?.name;
        }}
        sx={
          error ? null : { '.MuiOutlinedInput-notchedOutline': { border: 0 } }
        }
        SelectDisplayProps={{ style: { padding: '5px 24px 5px 10px' } }}
      >
        {options.map((entity) => {
          return (
            <MenuItem
              value={entity.id}
              key={entity.id}
              style={entity.id === EMPTY_VALUE ? { display: 'none' } : null}
            >
              {entity.name}
            </MenuItem>
          );
        })}
      </MuiSelect>
    </div>
  );
}
