import { Clear as ClearIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export const EndAdornmentClear = ({ onClick }) => (
  <div className="flex items-center">
    <div className="h-5 w-px bg-gray-200" />
    <IconButton onClick={onClick} edge="end" size="large">
      <ClearIcon fontSize="small" />
    </IconButton>
  </div>
);
