import ConfigUtils from '~/utils/configUtils';

import FeatureService from '~/services/feature.service';

import { ManualCreationButton } from './ManualCreationButton';
import { ManualUploadButton } from './ManualUploadButton';

/**
 * Renders the buttons for manually creating delivery notes and service notes.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.handleClickCreateDln - The function to handle the click event for creating a delivery note.
 * @param {Function} props.handleClickCreateServiceNote - The function to handle the click event for creating a service note.
 * @param {Function} props.handleClickServiceNoteTemplate - The function to handle the click event for opening the service note template menu.
 * @param {Function} props.handleClickTemplate - The function to handle the click event for opening the delivery note template menu.
 * @return {JSX.Element} The rendered actions.
 */
export const DeliveryNoteActions = ({
  handleClickCreateDln,
  handleClickCreateServiceNote,
  handleClickServiceNoteTemplate,
  handleClickTemplate,
}) => (
  <div className="flex-e-c gap-20px w-full">
    {ConfigUtils.isDevelopmentStagingEnvironment() ? (
      <ManualUploadButton />
    ) : null}
    {FeatureService.createDeliveryNote() ? (
      <ManualCreationButton
        buttonText={'Lieferung'}
        title={'Neue Lieferung anlegen'}
        onClick={handleClickCreateDln}
        onOpenTemplateMenu={handleClickTemplate}
      />
    ) : null}
    {FeatureService.serviceNotes() ? (
      <ManualCreationButton
        buttonText={'Leistungsnachweis'}
        title={'Neuen Leistungsnachweis anlegen'}
        onClick={handleClickCreateServiceNote}
        onOpenTemplateMenu={handleClickServiceNoteTemplate}
      />
    ) : null}
  </div>
);
