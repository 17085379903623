import Coordinates from '~/models/masterdata/Coordinates';
import PermissionGrant from '~/models/masterdata/PermissionGrant';

import CostCenterService from '~/services/costCenter.service';
import MasterDataService from '~/services/masterData.service';
import OrganisationalGroupService from '~/services/organisationalGroup.service';
import PermissionGrantService from '~/services/permissionGrant.service';
import SiteService from '~/services/site.service';
import ToastService from '~/services/toast.service';

import { promiseHandler } from '~/utils/promiseHandler';
import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';

export const onSubmit = async ({
  closeForm,
  deactivateCoupledCostCenters,
  formSite,
  renderForCreate,
  resetForm,
  setSubmittingForm,
  site,
  syncCoupledPermissionGrants,
}) => {
  setSubmittingForm(true);

  const { latitude, longitude } = Coordinates.getConvertedCoordinates(
    formSite.coords.latitude,
    formSite.coords.longitude,
  );

  const {
    active,
    address: { streetName, buildingNumber, postCode, city, country },
    company,
    end,
    name,
    start,
    type,
  } = formSite;

  const body = {
    name,
    type,
    is_active: active,
    start,
    end,
    address: {
      street_name: streetName,
      building_number: buildingNumber,
      post_code: postCode,
      city,
      country,
    },
    coords: {
      latitude,
      longitude,
    },
    company_id: company,
  };

  if (renderForCreate()) {
    body.org_units = formSite.organisationalGroups;
  }

  if (
    MasterDataService.propertiesAreMissing(
      body,
      ['company_id'],
      Log.FEATURE.SITE,
    )
  ) {
    setSubmittingForm(false);
    return;
  }

  Log.info('Submit site form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
  Log.productAnalyticsEvent('Submit form', Log.FEATURE.SITE);

  if (renderForCreate()) {
    const [siteId, error] = await promiseHandler(SiteService.createSite(body));

    if (error) {
      ToastService.httpError(
        [ToastService.MESSAGE.SITE_CREATION_FAILED],
        error.response,
      );
      Log.error('Failed to create site.', error);
      Log.productAnalyticsEvent(
        'Failed to create',
        Log.FEATURE.SITE,
        Log.TYPE.ERROR,
      );
      setSubmittingForm(false);
      return;
    }

    const [, error2] = await promiseHandler(
      SiteService.updateAccountingReferencesOfSite(
        siteId,
        formSite.costCenters,
      ),
    );

    if (error2) {
      ToastService.httpError(
        [ToastService.MESSAGE.ACCOUNTING_REFERENCE_ASSIGNMENT_FAILED],
        error2.response,
      );
      Log.productAnalyticsEvent(
        'Failed to update assigned cost centers',
        Log.FEATURE.SITE,
        Log.TYPE.ERROR,
      );
    }
  } else {
    const [, error] = await promiseHandler(
      SiteService.updateSite(formSite?.id, body),
    );

    if (error) {
      ToastService.httpError(
        [ToastService.MESSAGE.SITE_UPDATE_FAILED],
        error.response,
      );
      Log.error('Failed to update site. id: ' + formSite?.id, error);
      Log.productAnalyticsEvent(
        'Failed to update',
        Log.FEATURE.SITE,
        Log.TYPE.ERROR,
      );
      setSubmittingForm(false);
      return;
    }

    const [, error2] = await promiseHandler(
      SiteService.updateAccountingReferencesOfSite(
        formSite.id,
        formSite.costCenters,
      ),
    );

    if (error2) {
      ToastService.httpError(
        [ToastService.MESSAGE.ACCOUNTING_REFERENCE_ASSIGNMENT_FAILED],
        error2.response,
      );
      Log.productAnalyticsEvent(
        'Failed to update assigned cost centers',
        Log.FEATURE.SITE,
        Log.TYPE.ERROR,
      );
    }

    if (syncCoupledPermissionGrants) {
      const [, error3] = await promiseHandler(
        PermissionGrantService.assignCoupledPermissionGrants(
          formSite.permissionGrantsFrom,
          site.costCenters,
          formSite.costCenters,
        ),
      );

      if (error3) {
        Log.error('Failed to create new permission grants.', error3);
        ToastService.httpError(
          [
            'Es konnten nicht alle Berechtigungen zu den Kostenstellen hinzugefügt werden.',
          ],
          error3.response,
        );
        Log.productAnalyticsEvent(
          'Failed to sync permissions between site and cost centers',
          Log.FEATURE.PERMISSIONS,
          Log.TYPE.ERROR,
        );
      }

      const [, error4] = await promiseHandler(
        PermissionGrantService.deleteCoupledPermissionGrants(
          formSite.permissionGrantsFrom,
          site.costCenters,
          formSite.costCenters,
        ),
      );

      if (error4) {
        Log.error('Failed to delete permission grants.', error4);
        ToastService.httpError(
          [
            'Es konnten nicht alle Berechtigungen von den Kostenstellen entfernt werden.',
          ],
          error4.response,
        );
        Log.productAnalyticsEvent(
          'Failed to sync permissions between site and cost centers',
          Log.FEATURE.PERMISSIONS,
          Log.TYPE.ERROR,
        );
      }

      const [removedCostCenters, addedCostCenters] = ArrayUtils.getDifference(
        site.costCenters,
        formSite.costCenters,
      );

      for (const costCenterId of removedCostCenters)
        CostCenterService.refreshCostCenter(costCenterId);
      for (const costCenterId of addedCostCenters)
        CostCenterService.refreshCostCenter(costCenterId);
    }

    if (deactivateCoupledCostCenters) {
      for (const costCenter of formSite.costCenters) {
        const [, error5] = await promiseHandler(
          CostCenterService.updateCostCenter(costCenter, {
            is_active: formSite.active,
          }),
        );

        if (error5) {
          Log.error('Failed to update cost center.', error5);
          ToastService.httpError(
            ['Es konnten nicht alle Kostenstellen (de)aktiviert werden.'],
            error5.response,
          );
          Log.productAnalyticsEvent(
            'Failed to update cost center',
            Log.FEATURE.SITE,
            Log.TYPE.ERROR,
          );
        }
      }
    }

    const [, error6] = await promiseHandler(
      OrganisationalGroupService.updateParentOrganisationalGroups(
        site.id,
        PermissionGrant.ENTITY_TYPE.SITE.KEY,
        site.organisationalGroups,
        formSite.organisationalGroups,
      ),
    );

    if (error6) {
      ToastService.httpError(
        ['Organisations-Gruppen konnten nicht geändert werden.'],
        error6.response,
      );
      Log.productAnalyticsEvent(
        'Failed to update organisational groups',
        Log.FEATURE.SITE,
        Log.TYPE.ERROR,
      );
    }
  }

  setSubmittingForm(false);
  closeForm();
  resetForm(true);
  SiteService.refreshSites();
};
