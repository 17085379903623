import React from 'react';
import { connect } from 'react-redux';

import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { Button, Grid, IconButton } from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import { setPageTitle } from '~/redux/menuSlice';
import Log from '~/utils/Log';
import { LOADING_STATE } from '~/constants/LoadingState';
import { promiseHandler } from '~/utils/promiseHandler';
import FunctionUtils from '~/utils/functionUtils';
import DeliveriesService from '~/services/deliveries.service';
import DeliveryNoteDataQualityCategory from './DeliveryNoteDataQualityCategory';

import { LightTooltip } from '~/utils/componentUtils';
import ToastService from '~/services/toast.service';
import { Spinner } from '../../Spinner';
import DeliveryNoteDataQualityModel from '~/models/deliveries/DeliveryNoteDataQuality';

import LocalStorageService from '~/services/localStorage.service';
import DocumentLoadingPage from '../../DocumentLoadingPage';
import CustomFieldService from '~/services/customField.service';

const mapStateToProps = (state) => ({
  deliveryNotes: state.deliveryNotes,
});
const mapDispatchToProps = () => ({
  setPageTitle,
});

class DeliveryNoteDataQuality extends React.Component {
  constructor(props) {
    super(props);

    this.HIDE_DESCRIPTION_COOKIE = 'hide_description';

    this.state = {
      dataQualityCategories: [],
      loading: LOADING_STATE.LOADING,
      isUploading: false,
      hideDescription: LocalStorageService.getBooleanFromLocalStorage(
        this.HIDE_DESCRIPTION_COOKIE,
      ),
    };
  }

  componentDidMount() {
    this.loadDocument_safe();

    this.props.setPageTitle('Lieferung');
    document.title = 'VESTIGAS - Lieferung';
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const dlnFromStore = this.props.deliveryNotes.deliveryNotes.find(
      (dln) => dln.id === this.props.match.params.id,
    );
    const previousDlnFromStore = prevProps.deliveryNotes.deliveryNotes.find(
      (dln) => dln.id === this.props.match.params.id,
    );

    // reload document if other dln has been opened (i.e. url has changed)
    // or dln in store has been updated (i.e. by changes feed)
    if (
      this.props.match.params.id !== prevProps.match.params.id ||
      (JSON.stringify(dlnFromStore?.assetChain) !==
        JSON.stringify(previousDlnFromStore?.assetChain) &&
        previousDlnFromStore !== undefined)
    ) {
      this.loadDocument_safe();
    }
  }

  loadDocument_safe() {
    this.loadDocument().catch((error) => {
      Log.error('Failed to load delivery note', error);
      Log.productAnalyticsEvent(
        'Failed to load delivery note',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );

      this.setState({
        loading: LOADING_STATE.FAILED,
      });
    });
  }

  async loadDocument() {
    // Before throwing an error, try to load the delivery note 5 times
    const [dln, error] = await promiseHandler(
      FunctionUtils.repeatAsyncFunction(
        () => DeliveriesService.getDeliveryNoteById(this.props.match.params.id),
        5,
        1000,
      ),
    );

    if (error) {
      throw error;
    }

    this.props.setPageTitle('Lieferung ' + (dln.number ?? ''));
    document.title = 'VESTIGAS - Lieferung ' + (dln.number ?? '');

    const customFieldIds = dln.getCustomFieldIds();
    const [response2, error2] = await promiseHandler(
      CustomFieldService.loadCustomFieldsBulk(customFieldIds),
    );

    if (error2) {
      throw error2;
    }

    const [response3, error3] = await promiseHandler(dln.asyncInit());

    if (error3) {
      throw error3;
    }

    const [backendDln, error4] = await promiseHandler(
      DeliveriesService.getDeliveryNote(this.props.match.params.id),
    );

    if (error4) {
      Log.error(
        'Failed to load delivery note. id: ' + this.props.match.params.id,
        error4,
      );
      ToastService.httpError(
        [
          'Datenqualität konnte nicht geladen werden wegen eines internen Fehlers.',
        ],
        error4.response,
      );
      Log.productAnalyticsEvent(
        'Failed to load delivery note',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      return;
    }

    const [validationResults, error5] = await promiseHandler(
      DeliveriesService.validateDeliveryNoteVestigasFormat(
        backendDln.asset_state.body,
      ),
    );

    if (error5) {
      Log.error(
        'Failed to validate delivery note. id: ' + this.props.match.params.id,
        error5,
      );
      ToastService.httpError(
        [
          'Lieferung konnte nicht validiert werden wegen eines internen Fehlers.',
        ],
        error5.response,
      );
      Log.productAnalyticsEvent(
        'Failed to validate delivery note',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      return;
    }

    const deliveryNoteDataQuality = new DeliveryNoteDataQualityModel(
      dln,
      validationResults,
    );

    const dataQualityCategories =
      deliveryNoteDataQuality.getDataQualityCategories();

    this.setState({
      dataQualityCategories,
      loading: LOADING_STATE.SUCCEEDED,
    });
  }

  createDeliveryNote = async (body) => {
    this.setState({
      uploading: true,
    });

    const [response, error] = await promiseHandler(
      DeliveriesService.createDeliveryNoteVestigasFormat_enhanced(body),
    );

    this.setState({
      uploading: false,
    });
  };
  closeDescription = () => {
    Log.productAnalyticsEvent(
      'Close description of delivery note data quality',
      Log.FEATURE.DELIVERY_NOTE,
    );
    this.setState({
      hideDescription: true,
    });
    LocalStorageService.setLocalStorage(this.HIDE_DESCRIPTION_COOKIE, true);
  };

  getArticleComponents() {
    const articleComponents = [];

    const dataQualityArticles = this.state.dataQualityCategories.filter(
      (item) =>
        item.NAME === DeliveryNoteDataQualityModel.CATEGORY.ARTICLE.NAME,
    );

    for (const [index, dataQualityArticle] of dataQualityArticles.entries()) {
      articleComponents.push(
        <div
          className={
            'rounded-5px box-shadow-blue p-10px w-full bg-white ' +
            (index > 0 ? 'mt-20px' : '')
          }
        >
          <DeliveryNoteDataQualityCategory
            category={DeliveryNoteDataQualityModel.CATEGORY.ARTICLE.NAME}
            dataQualityPairs={dataQualityArticle.dataQualityPairs}
          />
        </div>,
      );
    }

    return articleComponents;
  }

  render() {
    if (this.state.loading !== LOADING_STATE.SUCCEEDED) {
      return (
        <DocumentLoadingPage
          loading={this.state.loading}
          documentType="Lieferung"
        />
      );
    }

    return (
      <div className="main-padding whitespace-pre">
        <div className="flex-sb-c flexdir-row-reverse gap-20px mb-20px">
          <LightTooltip title="Neue Lieferung im JSON Format hochladen.">
            <label htmlFor="input-json-upload">
              <input
                type="file"
                className="hidden"
                accept=".json"
                id="input-json-upload"
                onChange={(event) =>
                  DeliveriesService.uploadJson(
                    event.target.files[0],
                    this.createDeliveryNote,
                  )
                }
              />
              <Button
                className="primary-button"
                startIcon={this.state.uploading ? null : <AddIcon />}
                component="span"
                disabled={this.state.uploading}
              >
                {this.state.uploading ? (
                  <Spinner title="Hochladen..." />
                ) : (
                  'Hochladen'
                )}
              </Button>
            </label>
          </LightTooltip>
          {this.state.hideDescription ? null : (
            <div className="rounded-5px box-shadow-blue p-10px flex-sb-c gap-30px bg-white">
              <div>
                <span className="bold">Willkommen in der Datenansicht!</span>
                <br />
                In der Datenansicht können alle Informationen einer Lieferung
                sowie deren Datenqualität eingesehen werden.
                <br />
                Dies ist vor allem hilfreich, um nachvollziehen zu können, ob
                Lieferungen die gewünschte Datenqualität aufweisen.
              </div>
              <IconButton onClick={this.closeDescription} size="large">
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </div>
        <Grid container spacing="20px" justifyContent="space-between">
          <Grid item xs={12} lg={4}>
            <div className="rounded-5px box-shadow-blue p-10px w-full bg-white">
              <DeliveryNoteDataQualityCategory
                category={DeliveryNoteDataQualityModel.CATEGORY.META_DATA.NAME}
                dataQualityPairs={
                  this.state.dataQualityCategories.find(
                    (category) =>
                      category.NAME ===
                      DeliveryNoteDataQualityModel.CATEGORY.META_DATA.NAME,
                  ).dataQualityPairs
                }
              />
            </div>
            <div className="rounded-5px box-shadow-blue p-10px mt-20px w-full bg-white">
              <DeliveryNoteDataQualityCategory
                category={DeliveryNoteDataQualityModel.CATEGORY.ISSUER.NAME}
                dataQualityPairs={
                  this.state.dataQualityCategories.find(
                    (category) =>
                      category.NAME ===
                      DeliveryNoteDataQualityModel.CATEGORY.ISSUER.NAME,
                  ).dataQualityPairs
                }
              />
            </div>
            <div className="rounded-5px box-shadow-blue p-10px mt-20px w-full bg-white">
              <DeliveryNoteDataQualityCategory
                category={DeliveryNoteDataQualityModel.CATEGORY.SUPPLIER.NAME}
                dataQualityPairs={
                  this.state.dataQualityCategories.find(
                    (category) =>
                      category.NAME ===
                      DeliveryNoteDataQualityModel.CATEGORY.SUPPLIER.NAME,
                  ).dataQualityPairs
                }
              />
            </div>
            <div className="rounded-5px box-shadow-blue p-10px mt-20px w-full bg-white">
              <DeliveryNoteDataQualityCategory
                category={DeliveryNoteDataQualityModel.CATEGORY.CARRIER.NAME}
                dataQualityPairs={
                  this.state.dataQualityCategories.find(
                    (category) =>
                      category.NAME ===
                      DeliveryNoteDataQualityModel.CATEGORY.CARRIER.NAME,
                  ).dataQualityPairs
                }
              />
            </div>
            <div className="rounded-5px box-shadow-blue p-10px mt-20px w-full bg-white">
              <DeliveryNoteDataQualityCategory
                category={DeliveryNoteDataQualityModel.CATEGORY.RECIPIENT.NAME}
                dataQualityPairs={
                  this.state.dataQualityCategories.find(
                    (category) =>
                      category.NAME ===
                      DeliveryNoteDataQualityModel.CATEGORY.RECIPIENT.NAME,
                  ).dataQualityPairs
                }
              />
            </div>
            <div className="rounded-5px box-shadow-blue p-10px mt-20px w-full bg-white">
              <DeliveryNoteDataQualityCategory
                category={DeliveryNoteDataQualityModel.CATEGORY.SELLER.NAME}
                dataQualityPairs={
                  this.state.dataQualityCategories.find(
                    (category) =>
                      category.NAME ===
                      DeliveryNoteDataQualityModel.CATEGORY.SELLER.NAME,
                  ).dataQualityPairs
                }
              />
            </div>
            <div className="rounded-5px box-shadow-blue p-10px mt-20px w-full bg-white">
              <DeliveryNoteDataQualityCategory
                category={DeliveryNoteDataQualityModel.CATEGORY.BUYER.NAME}
                dataQualityPairs={
                  this.state.dataQualityCategories.find(
                    (category) =>
                      category.NAME ===
                      DeliveryNoteDataQualityModel.CATEGORY.BUYER.NAME,
                  ).dataQualityPairs
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} lg={4}>
            <div className="rounded-5px box-shadow-blue p-10px w-full bg-white">
              <DeliveryNoteDataQualityCategory
                category={DeliveryNoteDataQualityModel.CATEGORY.EXECUTION.NAME}
                dataQualityPairs={
                  this.state.dataQualityCategories.find(
                    (category) =>
                      category.NAME ===
                      DeliveryNoteDataQualityModel.CATEGORY.EXECUTION.NAME,
                  ).dataQualityPairs
                }
              />
            </div>
            <div className="rounded-5px box-shadow-blue p-10px mt-20px w-full bg-white">
              <DeliveryNoteDataQualityCategory
                category={
                  DeliveryNoteDataQualityModel.CATEGORY.FREIGHT_FORWARDER.NAME
                }
                dataQualityPairs={
                  this.state.dataQualityCategories.find(
                    (category) =>
                      category.NAME ===
                      DeliveryNoteDataQualityModel.CATEGORY.FREIGHT_FORWARDER
                        .NAME,
                  ).dataQualityPairs
                }
              />
            </div>
            <div className="rounded-5px box-shadow-blue p-10px mt-20px w-full bg-white">
              <DeliveryNoteDataQualityCategory
                category={DeliveryNoteDataQualityModel.CATEGORY.FROM_SITE.NAME}
                dataQualityPairs={
                  this.state.dataQualityCategories.find(
                    (category) =>
                      category.NAME ===
                      DeliveryNoteDataQualityModel.CATEGORY.FROM_SITE.NAME,
                  ).dataQualityPairs
                }
              />
            </div>
            <div className="rounded-5px box-shadow-blue p-10px mt-20px w-full bg-white">
              <DeliveryNoteDataQualityCategory
                category={
                  DeliveryNoteDataQualityModel.CATEGORY.TO_SITE_SUPPLIER.NAME
                }
                dataQualityPairs={
                  this.state.dataQualityCategories.find(
                    (category) =>
                      category.NAME ===
                      DeliveryNoteDataQualityModel.CATEGORY.TO_SITE_SUPPLIER
                        .NAME,
                  ).dataQualityPairs
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} lg={4}>
            {this.getArticleComponents()}
          </Grid>
        </Grid>
        <div
          className="min-h-2rem"
          /* This is a hacky workaround to get the padding bottom of 2rem. It is applied as child container to all divs with main-padding */
          /* A better solution would be to make the parent container min-h-fit-content so that the padding of main-padding is applied. */
          /* However, min-h-fit-content seems to not work with h-fill or generally with flexbox and flex-1. */
        />
      </div>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(DeliveryNoteDataQuality),
  'Lieferung konnte nicht geladen werden.',
);
