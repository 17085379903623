import React from 'react';

import { Tooltip } from '@mui/material';
import { getGridStringOperators } from '@mui/x-data-grid';

import LocalStorageService from '~/services/localStorage.service';

/**
 * Converts a legacy filter model to the current MUI Datagrid format.
 *
 * @param {{ items: Array<{ id: string, columnField?: string, field: string, operatorValue?: string, operator: string, value: any }>, linkOperator?: string, logicOperator?: string }} oldModel
 * @returns {{ items: Array<{ id: string, field: string, operator: string, value: any }>, logicOperator: string }}
 */
export const fixLegacyFilterModels = ({
  items,
  linkOperator: legacyLinkOperator,
  logicOperator: legacyLogicOperator,
}) => ({
  items: items.map(
    ({ id, columnField, field, operatorValue, operator, value }) => ({
      id,
      field: columnField ?? field,
      operator: operatorValue ?? operator,
      value,
    }),
  ),
  logicOperator: legacyLinkOperator ?? legacyLogicOperator,
});

class DatagridUtils {
  constructor() {
    this.TABLE_HEIGHT_10_ROWS = '668px';

    this.ROW_HEIGHT = {
      VERY_THIN: 25,
      THIN: 35,
      STANDARD: 52,
      WIDE: 65,
    };

    this.EMPTY_FILTER_MODEL = {
      items: [],
      logicOperator: 'and',
    };
  }

  /**
   * Render a tooltip with the cell value on hover.
   * Used to make it easier to read if the cell is too narrow to display the full value.
   */
  displayCellTooltip(params) {
    let value = params.value;
    if (typeof value === 'boolean') {
      value = value ? 'Ja' : 'Nein';
    }

    return (
      <Tooltip title={value ?? ''}>
        <span>{value}</span>
      </Tooltip>
    );
  }

  displayCellTooltipControlled(renderValue, hoverValue) {
    return (
      <Tooltip title={hoverValue ? hoverValue : ''}>
        <span>{renderValue}</span>
      </Tooltip>
    );
  }

  customColumnTypeArray() {
    return {
      renderCell: (params) =>
        this.displayCellTooltipControlled(
          params.value.join(', '),
          params.value.join(', '),
        ),
      sortComparator: (v1, v2) => v1.join(', ').localeCompare(v2.join(', ')),
      filterOperators: getGridStringOperators().map((operator) => {
        let getApplyFilterFunction = operator.getApplyFilterFn;

        switch (operator.value) {
          // TODO mgottelt refactor in the future - circular dependency
          case 'contains': {
            getApplyFilterFunction = (filterItem) => (value) =>
              !filterItem.value ||
              value?.some((item) =>
                item
                  .toString()
                  .toLowerCase()
                  .includes(filterItem.value.toLowerCase()),
              );
            break;
          }

          case 'equals': {
            getApplyFilterFunction = (filterItem) => (value) =>
              !filterItem.value ||
              value?.some(
                (item) =>
                  item.toString().toLowerCase() ===
                  filterItem.value.toLowerCase(),
              );
            break;
          }

          case 'startsWith': {
            getApplyFilterFunction = (filterItem) => (value) =>
              !filterItem.value ||
              value?.some((item) =>
                item
                  .toString()
                  .toLowerCase()
                  .startsWith(filterItem.value.toLowerCase()),
              );
            break;
          }

          case 'endsWith': {
            getApplyFilterFunction = (filterItem) => (value) =>
              !filterItem.value ||
              value?.some((item) =>
                item
                  .toString()
                  .toLowerCase()
                  .endsWith(filterItem.value.toLowerCase()),
              );
            break;
          }

          case 'isEmpty': {
            getApplyFilterFunction = (filterItem) => (value) =>
              value?.length === 0;
            break;
          }

          case 'isNotEmpty': {
            getApplyFilterFunction = (filterItem) => (value) =>
              value?.length > 0;
            break;
          }

          case 'isAnyOf': {
            getApplyFilterFunction = (filterItem) => (value) =>
              !filterItem.value ||
              value?.some((item) =>
                filterItem.value?.find(
                  (searchItem) =>
                    searchItem.toLowerCase() === item.toString().toLowerCase(),
                ),
              );
            break;
          }

          default: {
            break;
          }
        }

        return {
          ...operator,
          getApplyFilterFn: getApplyFilterFunction,
        };
      }),
    };
  }

  getRowCountByTableHeight(totalHeight, cookieName) {
    return (totalHeight - 110) / this.getCookieRowHeight(cookieName);
  }

  getCookieRowHeight(cookieName) {
    const cookie = LocalStorageService.getObjectFromLocalStorage(cookieName);

    if (!cookie?.rowHeight) {
      return this.ROW_HEIGHT.THIN;
    } // default data grid row height

    return Number(cookie?.rowHeight);
  }

  getStatusBoxHeight(rowHeight) {
    switch (rowHeight) {
      case this.ROW_HEIGHT.VERY_THIN: {
        return 'h-20px';
      }

      case this.ROW_HEIGHT.THIN: {
        return 'h-30px';
      }

      case this.ROW_HEIGHT.STANDARD:
      case this.ROW_HEIGHT.WIDE: {
        return 'h-2_5rem';
      }

      default: {
        return 'h-30px';
      }
    }
  }

  resizeText(rowHeight, renderFunction) {
    let fontClass = '';

    if (rowHeight <= this.ROW_HEIGHT.THIN) {
      fontClass = 'text-14px';
    }

    return {
      renderCell: (params) => (
        <span className={fontClass}>
          {renderFunction
            ? renderFunction(params)
            : this.displayCellTooltip(params)}
        </span>
      ),
    };
  }

  getColumnWidthFactor(rowHeight) {
    switch (rowHeight) {
      case this.ROW_HEIGHT.VERY_THIN:
      case this.ROW_HEIGHT.THIN: {
        return 0.8;
      }

      case this.ROW_HEIGHT.STANDARD:
      case this.ROW_HEIGHT.WIDE:
      default: {
        return 1;
      }
    }
  }
}

export default new DatagridUtils();
