import { useState } from 'react';

import { PersonAdd as PersonAddIcon } from '@mui/icons-material';

import Log from '~/utils/Log';

import IconButton from '~/components/IconButton';

import ShareDeliveryNoteForm from '../../deliveryNoteActions/ShareDeliveryNoteForm';

export const ShareDeliveryNoteButton = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    Log.productAnalyticsEvent(
      'Open share delivery note form',
      Log.FEATURE.SHARE_DELIVERY_NOTE,
    );

    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        tooltipTitle="Lieferung mit einem Benutzer teilen."
        size="large"
      >
        <PersonAddIcon className="text-primary500 icon-small" />
      </IconButton>
      <ShareDeliveryNoteForm
        deliveryNoteIds={[id]}
        open={isOpen}
        closeForm={handleClose}
      />
    </>
  );
};
