import Company from './Company';

export default class PdfTemplate {
  constructor(pdfTemplate) {
    this.id = pdfTemplate?.id ?? '';
    this.filename = pdfTemplate?.filename ?? '';
    this.type = pdfTemplate?.type ?? '';
    // this.contentType = pdfTemplate?.content_type ?? ''; // currently not used
    this.company = new Company({ id: pdfTemplate?.company_id });
  }

  static TYPE = {
    DEFAULT: {
      KEY: 'default',
      STRING: 'Standard',
    },
    CONCRETE: {
      KEY: 'concrete',
      STRING: 'Beton',
    },
  };
}
