import { useQuery } from '@tanstack/react-query';

import SiteService from '~/services/site.service';

export const useQuerySiteIdentifierProposals = (options = {}) =>
  useQuery({
    queryKey: ['siteIdentifierProposals'],
    queryFn: SiteService.getSiteIdentifierProposals,
    ...options,
  });
