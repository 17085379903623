import { useMutation } from '@tanstack/react-query';

import SiteService from '~/services/site.service';

export const useMutationBlacklistSiteIdentifier = (options = {}) =>
  useMutation({
    mutationKey: [
      'blacklistSiteIdentifier',
      // {
      //   siteId,
      //   identifierId,
      //   shouldBlacklist,
      // },
    ],
    mutationFn: ({
      address,
      ignoreAddressInfo,
      issuerId,
      shouldBlacklist = true,
      siteId,
      tradeAddressIssuerAssignedId,
      tradeAddressLineOne,
    }) =>
      SiteService.addIdentifierForSupplierSite({
        address,
        ignoreAddressInfo,
        issuerId,
        shouldBlacklist,
        siteId,
        tradeAddressIssuerAssignedId,
        tradeAddressLineOne,
      }),
    ...options,
  });
