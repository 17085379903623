import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentIssues } from '~/redux/dataExchangesSlice';

/**
 * Custom hook to notify the user about data exchange issues.
 *
 * This hook checks for current issues in data exchanges and notifies the user
 * by updating the Redux store. It monitors changes in dataExchanges and
 * dataExchangeCompanies, and updates the currentIssues state accordingly.
 *
 * @returns {void}
 */
export const useNotifyDataExchangeIssues = () => {
  const dispatch = useDispatch();

  const { dataExchangeCompanies, dataExchanges } = useSelector(
    (state) => state.dataExchanges,
  );

  useEffect(() => {
    // Notify the user if a data exchange has a company with current issues.
    const hasCurrentIssues = dataExchanges.some(
      ({ senderId }) =>
        dataExchangeCompanies.find(({ id }) => id === senderId)?.currentIssues,
    );

    dispatch(setCurrentIssues(hasCurrentIssues));
  }, [JSON.stringify(dataExchanges), JSON.stringify(dataExchangeCompanies)]);
};
