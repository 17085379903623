import { createSlice } from '@reduxjs/toolkit';

const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    pageTitle: '',
  },
  reducers: {
    setPageTitle(state, parameter) {
      state.pageTitle = parameter.payload;
    },
  },
});

const { actions, reducer } = menuSlice;
export const { setPageTitle } = actions;
export default reducer;
