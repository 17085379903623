import Article from './Article';
import ArrayUtils from '~/utils/arrayUtils';

export default class Category {
  constructor(category) {
    this.id = category?.id ?? '';
    this.name = category?.name ?? '';
    // this.description = category?.description ?? '';
    this.subCategories = category?.sub_categories ?? [];
    this.parentCategories = category?.parent_categories ?? [];
    this.linkedArticles = category?.linked_articles ?? [];
    // this.otherProperties = category?.other_properties ?? {};
    // this.icon = category?.icon ?? null;
    this.active =
      category?.is_active === undefined ? true : category?.is_active;
    // this.root = category?.is_root !== undefined ? category?.is_root : true;
    // this.rank = category?.rank ?? 0;
    // this.containsArticles = category?.contains_articles !== undefined ? category?.contains_articles : true;
    // this.containsCategories = category?.contains_categories !== undefined ? category?.contains_categories : true;
    this.ownerCompanyAccounts = category?.owner_company_accounts ?? [];
    this.ownerCompanies = category?.owner_companies ?? [];
    this.access = category?.access ?? Article.ACCESS.PRIVATE.KEY;
    this.filterCompanies = category?.filter_companies ?? [];
    this.filterSites = category?.filter_sites ?? [];
    this.filterCostCenters = category?.filter_acc_refs ?? [];
  }

  static getDifferentValues(categoryA, categoryB) {
    const differentValues = [];

    if (categoryA?.id !== categoryB?.id) {
      differentValues.push('ID');
    }

    if (categoryA?.name !== categoryB?.name) {
      differentValues.push('Name');
    }

    if (categoryA?.active !== categoryB?.active) {
      differentValues.push('Aktiv');
    }

    if (categoryA?.rank !== categoryB?.rank) {
      differentValues.push('Reihenfolge');
    }

    if (categoryA?.access !== categoryB?.access) {
      differentValues.push('Berechtigung');
    }

    const differentSubCategories = ArrayUtils.getDifference(
      categoryA?.subCategories,
      categoryB?.subCategories,
    );
    if (
      differentSubCategories[0].length > 0 ||
      differentSubCategories[1].length > 0
    ) {
      differentValues.push('Unterkategorien');
    }

    const differentParentCategories = ArrayUtils.getDifference(
      categoryA?.parentCategories,
      categoryB?.parentCategories,
    );
    if (
      differentParentCategories[0].length > 0 ||
      differentParentCategories[1].length > 0
    ) {
      differentValues.push('Oberkategorien');
    }

    const differentLinkedArticles = ArrayUtils.getDifference(
      categoryA?.linkedArticles,
      categoryB?.linkedArticles,
    );
    if (
      differentLinkedArticles[0].length > 0 ||
      differentLinkedArticles[1].length > 0
    ) {
      differentValues.push('Enthaltene Artikel');
    }

    const differentOwnerCompanies = ArrayUtils.getDifference(
      categoryA?.ownerCompanies,
      categoryB?.ownerCompanies,
    );
    if (
      differentOwnerCompanies[0].length > 0 ||
      differentOwnerCompanies[1].length > 0
    ) {
      differentValues.push('Zuordnung zu Firmen');
    }

    const differentFilterCompanies = ArrayUtils.getDifference(
      categoryA?.filterCompanies,
      categoryB?.filterCompanies,
    );
    if (
      differentFilterCompanies[0].length > 0 ||
      differentFilterCompanies[1].length > 0
    ) {
      differentValues.push('Suchbar in Firmen');
    }

    const differentFilterSites = ArrayUtils.getDifference(
      categoryA?.filterSites,
      categoryB?.filterSites,
    );
    if (
      differentFilterSites[0].length > 0 ||
      differentFilterSites[1].length > 0
    ) {
      differentValues.push('Suchbar in Standorten');
    }

    const differentFilterCostCenters = ArrayUtils.getDifference(
      categoryA?.filterCostCenters,
      categoryB?.filterCostCenters,
    );
    if (
      differentFilterCostCenters[0].length > 0 ||
      differentFilterCostCenters[1].length > 0
    ) {
      differentValues.push('Suchbar in Kostenstellen');
    }

    return differentValues;
  }

  static IS_ACTIVE = {
    YES: 'Ja',
    NO: 'Nein',
  };
}
