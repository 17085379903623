import React from 'react';

import { Add as AddIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import DataSubscriptionFilterClause from './DataSubscriptionFilterClause';
import DataSubscriptionFilterClauseModel from '~/models/dataSubscriptions/DataSubscriptionFilterClause';
import cloneDeep from 'lodash/cloneDeep';

class DataSubscriptionFilterClauses extends React.Component {
  addFilterClause = () => {
    const newFilterClauses = cloneDeep(this.props.filterClauses);

    newFilterClauses.push(new DataSubscriptionFilterClauseModel());

    this.props.onChange(newFilterClauses);
  };
  handleChangeFilterClause = (filterClause, index) => {
    const newFilterClauses = cloneDeep(this.props.filterClauses);

    newFilterClauses[index] = filterClause;

    this.props.onChange(newFilterClauses);
  };

  render() {
    return (
      <div className="flexdir-column gap-20px flex">
        {this.props.filterClauses.map((filterClause, index) => (
          <DataSubscriptionFilterClause
            key={index}
            filterClause={filterClause}
            onChange={(filterClause) =>
              this.handleChangeFilterClause(filterClause, index)
            }
            onChangeFilterUnion={(filterUnion) =>
              this.props.onChangeFilterUnion(filterUnion)
            }
            onDelete={() => this.props.onDelete(index)}
            filterUnion={this.props.filterUnion}
            isLastFilterClause={index === this.props.filterClauses.length - 1}
          />
        ))}
        <Button
          variant="outlined"
          color="primary"
          onClick={this.addFilterClause}
          className="w-30px"
        >
          <AddIcon />
        </Button>
      </div>
    );
  }
}

export default DataSubscriptionFilterClauses;
