import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';

/**
 * Retrieves a deeply nested property from an object.
 *
 * @param {Record<string, unknown>} obj - The object from which to retrieve the property.
 * @param {string} nestedProperty - The path to the nested property, separated by dots.
 * @return {unknown} The value of the nested property.
 */
export const getNestedProperty = (object, nestedProperty) => {
  const propertyPath = nestedProperty.split('.');

  return propertyPath.reduce((current, key) => current?.[key], object);
};

class ObjectUtils {
  isEmptyValue(value) {
    return ['', null, undefined].includes(value);
  }

  // To check whether a variable is a JS Error.
  // Checking for stack and message isn't 100% correct and may produce false positives.
  // However, using value instanceof Error won't work if the error was thrown in a different window/frame/iframe.
  // See: https://stackoverflow.com/questions/30469261/checking-for-typeof-error-in-js
  isError(value) {
    return value?.stack && value.message;
  }

  /**
   * Map the array from Object.entries to a key/value pair so that it is more readable when coding
   *
   * @deprecated
   */
  entries(object) {
    return Object.entries(object).map(([key, value]) => ({
      key,
      value,
    }));
  }

  mergeWithAndExtendArrays(objectA, objectB) {
    return mergeWith(objectA, objectB, (objectValue, sourceValue) => {
      if (isArray(objectValue)) {
        // If both values are arrays, extend objValue with srcValue
        if (isArray(sourceValue)) {
          return objectValue.concat(sourceValue);
        }

        // If srcValue is not an array, extend objValue with it
        return objectValue.concat([sourceValue]);
      }
    });
  }

  accessObjectRecursively(object, pathArray) {
    return pathArray.reduce(
      (accumulator, key) =>
        accumulator && accumulator[key] !== 'undefined'
          ? accumulator[key]
          : undefined,
      object,
    );
  }

  isValidJson(string) {
    try {
      JSON.parse(string);
    } catch {
      return false;
    }

    return true;
  }

  removeProperty(object, propertyToRemove) {
    if (typeof object !== 'object' || object === null) {
      return object;
    }

    if (Array.isArray(object)) {
      return object.map((item) => this.removeProperty(item, propertyToRemove));
    }

    const newObject = {};
    for (const key in object) {
      if (key === propertyToRemove) {
        continue;
      }

      newObject[key] = this.removeProperty(object[key], propertyToRemove);
    }

    return newObject;
  }

  JSONstringifyDiffIgnoringProperty(object1, object2, propertyToIgnore) {
    return (
      JSON.stringify(this.removeProperty(object1, propertyToIgnore)) !==
      JSON.stringify(this.removeProperty(object2, propertyToIgnore))
    );
  }

  removeEmptyValues(object) {
    for (const key in object) {
      const value = object[key];
      if (value == null || value?.length === 0) {
        delete object[key];
      }
    }

    return object;
  }

  removeNulls(object) {
    for (const key in object) {
      if (object[key] == null) {
        delete object[key];
      }
    }

    return object;
  }
}

export default new ObjectUtils();
