import clsx from 'clsx';
import { forwardRef } from 'react';

import { ErrorBoundary } from './ErrorBoundary';

/**
 * Higher-order component that wraps the provided component with an error boundary provided by Sentry.
 * If the wrapped component throws an error, the fallback message is displayed instead and the error is reported to Sentry.
 *
 * @param {React.Component} WrappedComponent - The component to be wrapped with an error boundary.
 * @param {string} fallbackMessage - The message to be displayed when an error occurs.
 * @param {boolean} [limitHeight=false] - Whether to limit the height of the fallback message.
 * @return {React.Component} The wrapped component with an error boundary.
 */
export const withErrorBoundary = (
  WrappedComponent,
  fallbackMessage,
  limitHeight = false,
) =>
  forwardRef((props, ref) => (
    <ErrorBoundary
      fallback={
        fallbackMessage ? (
          <div
            className={clsx(
              'flex h-full min-h-28 items-center justify-center text-center',
              {
                'max-h-28': limitHeight,
              },
            )}
          >
            {fallbackMessage}
          </div>
        ) : null
      }
    >
      <WrappedComponent ref={ref} {...props} />
    </ErrorBoundary>
  ));
