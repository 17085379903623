import { v4 as uuidv4 } from 'uuid';

/**
 * Push the default tabs to the front of the list of filter groups if they are not already present.
 */
export const fixTabsOrder = (props, DEFAULT_TABS, getFilterGroupObject) => {
  let newFilterGroups = [];

  for (const { NAME } of DEFAULT_TABS) {
    newFilterGroups.push(getFilterGroupObject(uuidv4(), NAME, [], true));
  }

  newFilterGroups = newFilterGroups.map((filterGroup) => {
    const existingDefaultFilterGroup = props.filterGroups.find(
      ({ name }) => name === filterGroup.name,
    );

    return existingDefaultFilterGroup ?? filterGroup;
  });

  newFilterGroups = [
    ...newFilterGroups,
    ...props.filterGroups.filter(
      (filterGroup) =>
        !DEFAULT_TABS.find(({ NAME }) => NAME === filterGroup.name),
    ),
  ];

  // Migrate the tabs from the old tab functionality. // TODO: is this still required?
  for (const deliveryTab of props.userinfo.deliveryTabs) {
    if (newFilterGroups.find(({ name }) => name === deliveryTab.name)) {
      continue;
    }

    const migratedFilterGroup = getFilterGroupObject(
      uuidv4(),
      deliveryTab.name,
      [],
      true,
    );
    migratedFilterGroup.filterModel = deliveryTab.filterModel;

    newFilterGroups.push(migratedFilterGroup);
  }

  props.setDelivery_filterGroups(newFilterGroups);
};
