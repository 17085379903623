export const mapStateToProps = ({
  customFields,
  deliveryNotes,
  filters,
  userinfo,
}) => {
  const {
    deliveries,
    // -------------------------------------------------------------------------
    delivery_dateRange,
    delivery_filterGroupOpen,
    delivery_filterGroups,
    delivery_filterRows,
    delivery_selectField,
    delivery_selectedAcceptState,
    delivery_selectedArticle,
    delivery_selectedArticleNumber,
    delivery_selectedCostCenter,
    delivery_selectedCustomFields,
    delivery_selectedFilterGroup,
    delivery_selectedFromSite,
    delivery_selectedNumber,
    delivery_selectedPermittedCostCenters,
    delivery_selectedPermittedToSites,
    delivery_selectedProcessState,
    delivery_selectedRecipient,
    delivery_selectedSettledStatus,
    delivery_selectedSupplier,
    delivery_selectedToSiteRecipient,
    delivery_selectedToSiteSupplier,
    delivery_tab,
    oldestFilteredDlnDate,
    selectedCostCenters,
    selectedSites,
  } = filters;

  return {
    deliveries,
    // -------------------------------------------------------------------------
    customFields: customFields.customFields,
    dateRange: delivery_dateRange,
    deliveryNotes,
    filterGroupOpen: delivery_filterGroupOpen,
    filterGroups: delivery_filterGroups,
    filterRows: delivery_filterRows,
    oldestFilteredDlnDate,
    selectedAcceptState: delivery_selectedAcceptState,
    selectedArticle: delivery_selectedArticle,
    selectedArticleNumber: delivery_selectedArticleNumber,
    selectedCostCenter: delivery_selectedCostCenter,
    selectedCostCenters,
    selectedCustomFields: delivery_selectedCustomFields,
    selectedFilterGroup: delivery_selectedFilterGroup,
    selectedFromSite: delivery_selectedFromSite,
    selectedNumber: delivery_selectedNumber,
    selectedPermittedCostCenters: delivery_selectedPermittedCostCenters,
    selectedPermittedToSites: delivery_selectedPermittedToSites,
    selectedProcessState: delivery_selectedProcessState,
    selectedRecipient: delivery_selectedRecipient,
    selectedSettledStatus: delivery_selectedSettledStatus,
    selectedSites,
    selectedSupplier: delivery_selectedSupplier,
    selectedTab: delivery_tab,
    selectedToSiteRecipient: delivery_selectedToSiteRecipient,
    selectedToSiteSupplier: delivery_selectedToSiteSupplier,
    selectField: delivery_selectField,
    userFeatureFlags: userinfo.userinfo.userFeatureFlags,
    userinfo,
  };
};
