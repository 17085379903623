export default class LicensePlate {
  constructor(licensePlate) {
    this.name = licensePlate ?? null;
    this.city = null;
    this.letters = null;
    this.numbers = null;

    this.initLicensePlate();
  }

  initLicensePlate() {
    if (!this.name) {
      return;
    }

    const [city, letters, numbers] = this.name.split(' ');

    this.city = city ?? null;
    this.letters = letters ?? null;
    this.numbers = numbers ?? null;
  }

  isValid() {
    return this.cityIsValid() && this.lettersIsValid() && this.numbersIsValid();
  }

  static cityIsValid(city) {
    return /^[A-Z]{1,3}$/.test(city);
  }

  cityIsValid() {
    return LicensePlate.cityIsValid(this.city);
  }

  static lettersIsValid(letters) {
    return /^[A-Z]{1,2}$/.test(letters);
  }

  lettersIsValid() {
    return LicensePlate.lettersIsValid(this.letters);
  }

  static numbersIsValid(numbers) {
    return /^(?:[1-9]\d{3}|[1-9]\d{2}|[1-9]\d|[1-9])$/.test(numbers);
  }

  numbersIsValid() {
    return LicensePlate.numbersIsValid(this.numbers);
  }

  getConcatenatedName() {
    return this.city + ' ' + this.letters + ' ' + this.numbers;
  }

  static getDifferentValues(licensePlateA, licensePlateB) {
    const differentValues = [];

    if (licensePlateA?.name !== licensePlateB?.name) {
      differentValues.push('Name');
    }

    if (licensePlateA?.city !== licensePlateB?.city) {
      differentValues.push('Stadt');
    }

    if (licensePlateA?.letters !== licensePlateB?.letters) {
      differentValues.push('Buchstaben');
    }

    if (licensePlateA?.numbers !== licensePlateB?.numbers) {
      differentValues.push('Nummern');
    }

    return differentValues;
  }
}
