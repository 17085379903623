import { createBrowserHistory } from 'history';
import { Route } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license';
import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';

import AuthService from './auth.service';
import LocalStorageService from './localStorage.service';

import ConfigUtils from '~/utils/configUtils';
import Log from '~/utils/Log';
import GlobalStorage from '~/utils/GlobalStorage';
import UserUtils from '~/utils/userUtils';

import Config from '~/Config';

// Create Custom Sentry Route component
export const SentryRoute = Sentry.withSentryRouting(Route);

const history = createBrowserHistory();

class ThirdPartyService {
  initMuiPro() {
    LicenseInfo.setLicenseKey(Config.muiLicenseKey);
  }

  initSentry() {
    if (LocalStorageService.sentryGoogleAnalyticsDisabled()) {
      return;
    }

    Sentry.init({
      dsn: Config.sentryDsn,
      release: `web-customer-portal@${Config.version}`,
      integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV5BrowserTracingIntegration({ history }),
        Sentry.browserProfilingIntegration(),
        Sentry.feedbackIntegration({
          colorScheme: 'system',
          showBranding: false,
          isNameRequired: false,
          isEmailRequired: false,
          // i18n
          triggerLabel: 'Fehler melden',
          triggerAriaLabel: 'Fehler melden',
          formTitle: 'Fehler melden',
          submitButtonLabel: 'Senden',
          cancelButtonLabel: 'Abbrechen',
          confirmButtonLabel: 'Bestätigen',
          addScreenshotButtonLabel: 'Screenshot hinzufügen',
          removeScreenshotButtonLabel: 'Screenshot löschen',
          nameLabel: 'Name',
          namePlaceholder: 'Dein Name',
          emailLabel: 'E-Mail-Adresse',
          emailPlaceholder: 'name@domain.com',
          isRequiredLabel: '(Pflichtfeld)',
          messageLabel: 'Beschreibung',
          messagePlaceholder:
            'Was ist der Fehler? Was hätte eigentlich passieren sollen?',
          successMessageText: 'Danke für deine Fehlermeldung!',
        }),
        Sentry.replayIntegration(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100% of transactions for tracing.
      tracesSampleRate: 1, // Reduce this as we gain users in production!
      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      tracePropagationTargets: [/^\//, /^https:\/\/app\.vestigas\.com\/api/],

      // Profiling
      profilesSampleRate: 1, // Reduce this as we gain users in production!

      // Session Replay
      replaysOnErrorSampleRate: 1,
      replaysSessionSampleRate: 0.1,

      ignoreErrors: [
        'No matching state found in storage',
        'Request failed with status code 401',
      ],

      beforeBreadcrumb(breadcrumb) {
        return breadcrumb.category === 'console' ||
          breadcrumb.category?.includes('ui.')
          ? null
          : breadcrumb;
      },
    });
  }

  initSentryUser() {
    const userId = AuthService.getUserIdFromAccessToken();
    Sentry.setUser({ id: userId });

    const isVestigasUser = UserUtils.isVestigasAccount();
    Sentry.setTag('isVestigasUser', isVestigasUser);
  }

  initSentryCompany(companyName) {
    Sentry.setTag('company', companyName);
  }

  initProductAnalyticsTools(featureFlag, companyName, isVestigasSupport) {
    if (!featureFlag) {
      return;
    }

    if (isVestigasSupport || UserUtils.isVestigasAccount()) {
      return;
    }

    if (!ConfigUtils.isProductionEnvironment()) {
      return;
    }

    const userId = AuthService.getUserIdFromAccessToken();

    if (!userId) {
      Log.error(
        "Failed to initialize product analytics tool because user id couldn't be retrieved from access token.",
      );
      return;
    }

    // Init and disable IP tracking
    amplitude.init('9b5a3c6fe13909baba504ba823f59263', undefined, {
      defaultTracking: {
        fileDownloads: true,
        formInteractions: true,
        pageViews: true,
        sessions: true,
      },
      trackingOptions: {
        ipAddress: false,
      },
      serverZone: 'EU',
    });

    amplitude.setUserId(userId);

    const identifyEvent = new amplitude.Identify();
    identifyEvent.setOnce('company', companyName);
    amplitude.identify(identifyEvent);

    // Tracking Amplitude events before the libraries have been initialized, leads to bugs.
    // Thus, use this variable as a Boolean to track whether the libraries have already been initialized.
    GlobalStorage.productAnalyticsToolsInitialized = true;
  }

  /*
   * @see https://www.docs.developers.amplitude.com/data/sdks/typescript-browser/#opt-users-out-of-tracking
   */
  optOutProductAnalyticsTools() {
    if (!GlobalStorage.productAnalyticsToolsInitialized) {
      return;
    }

    amplitude.setOptOut(true);
    const userId = AuthService.getUserIdFromAccessToken();
    localStorage.setItem(`product_analytics_tools_opted_out_${userId}`, '1');
  }

  optInProductAnalyticsTools() {
    if (!GlobalStorage.productAnalyticsToolsInitialized) {
      return;
    }

    amplitude.setOptOut(false);

    const userId = AuthService.getUserIdFromAccessToken();
    localStorage.setItem(`product_analytics_tools_opted_out_${userId}`, '0');
  }

  productAnalyticsToolsOptedOut() {
    const userId = AuthService.getUserIdFromAccessToken();
    return (
      localStorage.getItem(`product_analytics_tools_opted_out_${userId}`) ===
      '1'
    );
  }
}

export default new ThirdPartyService();
