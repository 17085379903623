import { dateUtils } from '~/utils/dateUtils';

import {
  containsAnyValues,
  customFieldsMatch,
  dateRangeMatches,
  queryMatches,
  selectionMatches,
} from './filterDeliveriesUtils';

// TODO: the function signature should be something like filterDeliveries(data, attributeFilters, dateFilters, searchFilter), which would also allow to just map over the provided list of filters and generate all the checks automatically.
/**
 * Filters an array of delivery rows based on the provided filters.
 *
 * @param {Object} data - The data used for filtering the rows.
 * @param {Object} data.dateRange - The date range to filter the rows by.
 * @param {string} data.query - The query to search for in the rows.
 * @param {Array} data.rows - The array of delivery rows to filter.
 * @param {Array} data.selectedAcceptState - The selected accept states to filter the rows by.
 * @param {Array} data.selectedArticle - The selected articles to filter the rows by.
 * @param {Array} data.selectedArticleNumber - The selected article numbers to filter the rows by.
 * @param {Array} data.selectedCostCenter - The selected cost centers to filter the rows by.
 * @param {Array} data.selectedCustomFields - The selected custom fields to filter the rows by.
 * @param {Object} data.selectedFromSite - The selected from site to filter the rows by.
 * @param {Array} data.selectedPermittedCostCenters - The selected permitted cost centers to filter the rows by.
 * @param {Array} data.selectedPermittedToSites - The selected permitted to sites to filter the rows by.
 * @param {Array} data.selectedProcessState - The selected process states to filter the rows by.
 * @param {Object} data.selectedRecipient - The selected recipient to filter the rows by.
 * @param {string} data.selectedSettledStatus - The selected settled status to filter the rows by.
 * @param {Object} data.selectedSupplier - The selected supplier to filter the rows by.
 * @param {Object} data.selectedToSiteRecipient - The selected to site recipient to filter the rows by.
 * @param {Object} data.selectedToSiteSupplier - The selected to site supplier to filter the rows by.
 * @param {string} data.selectField - The field to select for filtering the rows by.
 * @return {Array} The filtered array of delivery rows.
 */
export const filterDeliveries = (data) => {
  if (!data?.rows?.length) {
    return [];
  }

  const {
    dateRange,
    query,
    rows,
    selectField,
    selectedAcceptState,
    selectedArticle, // TODO: all of the 'selected' values from the filtersSlice should be plural!
    selectedArticleNumber,
    selectedCostCenter,
    selectedCustomFields,
    selectedFromSite,
    selectedPermittedCostCenters,
    selectedPermittedToSites,
    selectedProcessState,
    selectedRecipient,
    selectedSettledStatus,
    selectedSupplier,
    selectedToSiteRecipient,
    selectedToSiteSupplier,
  } = data;

  const timeframe = dateRange ? dateUtils.extractTimeframe(dateRange) : null;
  const lowerCaseQuery = query ? query.toLowerCase() : null;
  const lowerCaseSelectField = selectField ? selectField.toLowerCase() : 'all';

  return rows.filter((row) => {
    const {
      acceptState,
      articleNumbers,
      articles,
      costCenters,
      dlnDate,
      fromSite,
      permittedCostCenterNames,
      permittedToSiteNames,
      processState,
      recipient,
      searchString,
      settledStatus,
      supplier,
      toSiteRecipient,
      toSiteSupplier,
    } = row;

    const attributeFilters = [
      [selectedAcceptState, acceptState], // TODO: should be plural
      [selectedArticle, articles], // TODO: should be plural
      [selectedArticleNumber, articleNumbers], // TODO: should be plural
      [selectedCostCenter, costCenters], // TODO: should be plural
      [selectedFromSite, fromSite], // TODO: should be plural
      [selectedPermittedCostCenters, permittedCostCenterNames], // TODO: this name mismatch is particularly bad
      [selectedPermittedToSites, permittedToSiteNames], // TODO: this name mismatch is particularly bad
      [selectedProcessState, processState], // TODO: should be plural
      [selectedRecipient, recipient], // TODO: should be plural
      [selectedSettledStatus, settledStatus], // TODO: should be plural
      [selectedSupplier, supplier], // TODO: should be plural
      [selectedToSiteRecipient, toSiteRecipient], // TODO: should be plural
      [selectedToSiteSupplier, toSiteSupplier], // TODO: should be plural
    ];

    if (!dateRangeMatches(timeframe, dlnDate)) {
      // The dateRange can only cause rows to be excluded. If so, we can return early.
      return false;
    }

    if (
      !containsAnyValues([
        lowerCaseQuery,
        ...attributeFilters.map(([filterValues]) => filterValues),
        selectedCustomFields,
      ])
    ) {
      // If no filters are set in the first place, there is no need to filter.
      return true;
    }

    if (
      lowerCaseQuery &&
      !queryMatches(lowerCaseQuery, lowerCaseSelectField, row, searchString)
    ) {
      // Check for a query match first to be able to return early.
      return false;
    }

    // Go through all regular attribute filters and return early if we find a non-match.
    for (const [selectedValues, rowValues] of attributeFilters) {
      if (
        selectedValues?.length &&
        !selectionMatches(selectedValues, rowValues)
      ) {
        return false;
      }
    }

    // Lastly, check if a custom filter is active and matches.
    if (customFieldsMatch(selectedCustomFields, row)) {
      return true;
    }

    return true;
  });
};
