import React from 'react';

import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import { LightTooltipWide } from '~/utils/componentUtils';
import Log from '~/utils/Log';
import cloneDeep from 'lodash/cloneDeep';
import Permissions from '~/models/masterdata/Permissions';
import BasicForm from '~/components/BasicForm';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import ToastService from '~/services/toast.service';
import UserUtils from '~/utils/userUtils';

class PermissionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      permissions: new Permissions(),
    };
  }

  componentDidMount() {
    this.resetForm();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.permissions) !==
      JSON.stringify(this.props.permissions)
    ) {
      this.resetForm();
    }
  }

  resetForm() {
    this.setState({
      permissions: this.props.permissions ?? new Permissions(),
    });
  }

  handleChangeCheckbox = (event) => {
    const newPermissions = cloneDeep(this.state.permissions);

    switch (event.target.name) {
      case 'perm_dln_in': {
        newPermissions.deliveryNote.accessIncoming = event.target.checked;
        Log.info(
          'Change form value of perm_dln_in',
          {
            from: this.state.permissions.deliveryNote.accessIncoming,
            to: newPermissions.deliveryNote.accessIncoming,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change deliveryNote.accessIncoming checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'perm_dln_out': {
        newPermissions.deliveryNote.accessOutgoing = event.target.checked;
        Log.info(
          'Change form value of perm_dln_out',
          {
            from: this.state.permissions.deliveryNote.accessOutgoing,
            to: newPermissions.deliveryNote.accessOutgoing,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change deliveryNote.accessOutgoing checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'perm_dln_trans': {
        newPermissions.deliveryNote.accessTransported = event.target.checked;
        Log.info(
          'Change form value of perm_dln_trans',
          {
            from: this.state.permissions.deliveryNote.accessTransported,
            to: newPermissions.deliveryNote.accessTransported,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change deliveryNote.accessTransported checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'perm_inv_in': {
        newPermissions.invoice.accessIncoming = event.target.checked;
        Log.info(
          'Change form value of perm_inv_in',
          {
            from: this.state.permissions.invoice.accessIncoming,
            to: newPermissions.invoice.accessIncoming,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change invoice.accessIncoming checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'perm_inv_out': {
        newPermissions.invoice.accessOutgoing = event.target.checked;
        Log.info(
          'Change form value of perm_inv_out',
          {
            from: this.state.permissions.invoice.accessOutgoing,
            to: newPermissions.invoice.accessOutgoing,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change invoice.accessOutgoing checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'user_read': {
        newPermissions.user.read = event.target.checked;
        Log.info(
          'Change form value of user_read',
          {
            from: this.state.permissions.user.read,
            to: newPermissions.user.read,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change user.read checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'user_write': {
        newPermissions.user.write = event.target.checked;
        Log.info(
          'Change form value of user_write',
          {
            from: this.state.permissions.user.write,
            to: newPermissions.user.write,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change user.write checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'user_create': {
        newPermissions.user.create = event.target.checked;
        Log.info(
          'Change form value of user_create',
          {
            from: this.state.permissions.user.create,
            to: newPermissions.user.create,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change user.create checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'site_read': {
        newPermissions.site.read = event.target.checked;
        Log.info(
          'Change form value of site_read',
          {
            from: this.state.permissions.site.read,
            to: newPermissions.site.read,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change site.read checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'site_write': {
        newPermissions.site.write = event.target.checked;
        Log.info(
          'Change form value of site_write',
          {
            from: this.state.permissions.site.write,
            to: newPermissions.site.write,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change site.write checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'site_create': {
        newPermissions.site.create = event.target.checked;
        Log.info(
          'Change form value of site_create',
          {
            from: this.state.permissions.site.create,
            to: newPermissions.site.create,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change site.create checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'cost_center_read': {
        newPermissions.costCenter.read = event.target.checked;
        Log.info(
          'Change form value of cost_center_read',
          {
            from: this.state.permissions.costCenter.read,
            to: newPermissions.costCenter.read,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change costCenter.read checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'cost_center_write': {
        newPermissions.costCenter.write = event.target.checked;
        Log.info(
          'Change form value of cost_center_write',
          {
            from: this.state.permissions.costCenter.write,
            to: newPermissions.costCenter.write,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change costCenter.write checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'cost_center_create': {
        newPermissions.costCenter.create = event.target.checked;
        Log.info(
          'Change form value of cost_center_create',
          {
            from: this.state.permissions.costCenter.create,
            to: newPermissions.costCenter.create,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change costCenter.create checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'vehicle_read': {
        newPermissions.vehicle.read = event.target.checked;
        Log.info(
          'Change form value of vehicle_read',
          {
            from: this.state.permissions.vehicle.read,
            to: newPermissions.vehicle.read,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change vehicle.read checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'vehicle_write': {
        newPermissions.vehicle.write = event.target.checked;
        Log.info(
          'Change form value of vehicle_write',
          {
            from: this.state.permissions.vehicle.write,
            to: newPermissions.vehicle.write,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change vehicle.write checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'vehicle_create': {
        newPermissions.vehicle.create = event.target.checked;
        Log.info(
          'Change form value of vehicle_create',
          {
            from: this.state.permissions.vehicle.create,
            to: newPermissions.vehicle.create,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change vehicle.create checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'company_read': {
        newPermissions.company.read = event.target.checked;
        Log.info(
          'Change form value of company_read',
          {
            from: this.state.permissions.company.read,
            to: newPermissions.company.read,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change company.read checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'company_write': {
        newPermissions.company.write = event.target.checked;
        Log.info(
          'Change form value of company_write',
          {
            from: this.state.permissions.company.write,
            to: newPermissions.company.write,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change company.write checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'company_create': {
        newPermissions.company.create = event.target.checked;
        Log.info(
          'Change form value of company_create',
          {
            from: this.state.permissions.company.create,
            to: newPermissions.company.create,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change company.create checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'organisational_group_read': {
        newPermissions.organisationalGroup.read = event.target.checked;
        Log.info(
          'Change form value of organisational_group_write',
          {
            from: this.state.permissions.organisationalGroup.read,
            to: newPermissions.organisationalGroup.read,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change organisationalGroup.read checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'organisational_group_member': {
        newPermissions.organisationalGroup.member = event.target.checked;
        Log.info(
          'Change form value of organisational_group_member',
          {
            from: this.state.permissions.organisationalGroup.member,
            to: newPermissions.organisationalGroup.member,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change organisationalGroup.member checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'organisational_group_write': {
        newPermissions.organisationalGroup.write = event.target.checked;
        Log.info(
          'Change form value of organisational_group_write',
          {
            from: this.state.permissions.organisationalGroup.write,
            to: newPermissions.organisationalGroup.write,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change organisationalGroup.write checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'organisational_group_create': {
        newPermissions.organisationalGroup.create = event.target.checked;
        Log.info(
          'Change form value of organisational_group_create',
          {
            from: this.state.permissions.organisationalGroup.create,
            to: newPermissions.organisationalGroup.create,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change organisationalGroup.create checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'organisational_group_delete': {
        newPermissions.organisationalGroup.delete = event.target.checked;
        Log.info(
          'Change form value of organisational_group_delete',
          {
            from: this.state.permissions.organisationalGroup.delete,
            to: newPermissions.organisationalGroup.delete,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change organisationalGroup.delete checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'user_group_read': {
        newPermissions.userGroup.read = event.target.checked;
        Log.info(
          'Change form value of user_group_read',
          {
            from: this.state.permissions.userGroup.read,
            to: newPermissions.userGroup.read,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change userGroup.read checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'user_group_member': {
        newPermissions.userGroup.member = event.target.checked;
        Log.info(
          'Change form value of user_group_member',
          {
            from: this.state.permissions.userGroup.member,
            to: newPermissions.userGroup.member,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change userGroup.member checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'user_group_write': {
        newPermissions.userGroup.write = event.target.checked;
        Log.info(
          'Change form value of user_group_write',
          {
            from: this.state.permissions.userGroup.write,
            to: newPermissions.userGroup.write,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change userGroup.write checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'user_group_create': {
        newPermissions.userGroup.create = event.target.checked;
        Log.info(
          'Change form value of user_group_create',
          {
            from: this.state.permissions.userGroup.create,
            to: newPermissions.userGroup.create,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change userGroup.create checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'user_group_delete': {
        newPermissions.userGroup.delete = event.target.checked;
        Log.info(
          'Change form value of user_group_delete',
          {
            from: this.state.permissions.userGroup.delete,
            to: newPermissions.userGroup.delete,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change userGroup.delete checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      case 'access_control_grant': {
        newPermissions.permission.grant = event.target.checked;
        Log.info(
          'Change form value of access_control_grant',
          {
            from: this.state.permissions.permission.grant,
            to: newPermissions.permission.grant,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change permission.grant checkbox',
          Log.FEATURE.PERMISSIONS,
        );
        break;
      }

      default: {
        Log.error(
          null,
          EnumValueNotFoundException(
            'Invalid checkbox type: ' + event.target.name,
          ),
        );
      }
    }

    this.setState({
      permissions: newPermissions,
    });
  };
  handleChangeRole = (event) => {
    Log.info(
      'Change form value of role',
      {
        from: this.state.permissions.getDefaultRoleName(),
        to: event.target.value,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change role (' + event.target.value + ')',
      Log.FEATURE.PERMISSIONS,
    ); // Same event as in PermissionGrantPickerItem. Would be better if it was renamed properly.

    const newPermissions = new Permissions();

    if (event.target.value === Permissions.INDIVIDUAL_ROLE) {
      ToastService.info([
        'Um individuelle Berechtigungen zu vergeben, kannst du diese in den Kästchen auswählen und mit "Speichern" bestätigen.',
      ]);
      return;
    }

    newPermissions.initWithDefaultRole(event.target.value);

    this.setState({
      permissions: newPermissions,
    });
  };
  formSuccess = (event) => {
    event.preventDefault();
    event.stopPropagation();

    Log.info(
      'Submit permission form',
      this.state.permissions,
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.PERMISSIONS);

    this.props.formSuccess(this.state.permissions);
  };
  formAbort = () => {
    Log.info('Abort permission form', null, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.PERMISSIONS);

    this.resetForm();
    this.props.formAbort();
  };
  checkbox = (name, label, checked, description) => {
    return (
      <Grid item xs={12}>
        <LightTooltipWide
          title={description}
          enterDelay={1000}
          enterNextDelay={1000}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={this.handleChangeCheckbox}
                name={name}
              />
            }
            label={label}
            disabled={this.props.disabled}
          />
        </LightTooltipWide>
      </Grid>
    );
  };

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        title={this.props.title ?? 'Berechtigungen vergeben'}
        fullWidth
        submittingForm={this.props.submittingForm}
        opacity={this.props.opacity}
        missingPermissionsToSubmit={!UserUtils.isPermissionGrantAllowedUser()}
      >
        <Grid container direction="row" spacing={3} space={4}>
          <Grid item xs={6} lg={4}>
            <InputLabel className="text-13px">
              Standardrolle auswählen
            </InputLabel>
            <Select
              value={this.state.permissions.getDefaultRoleName() ?? 'None'} // A random String has to be chosen as fallback value because otherwise renderValue wouldn't catch the case if(!this.state.permissions.permissionGranted())
              key={0}
              onChange={this.handleChangeRole}
              className="w-300px"
              size="small"
              renderValue={(id) => {
                if (!this.state.permissions.permissionGranted()) {
                  return (
                    <span className="text-mui-not-selected-grey">
                      Bitte Berechtigungen vergeben
                    </span>
                  );
                }

                return Permissions.getPickableRoles().find(
                  (option) => option.id === id,
                ).name;
              }}
            >
              {Permissions.getPickableRoles().map((role) => (
                <MenuItem value={role.id} key={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="main-text">Rechte Lieferungen und Rechnungen</h3>
            <Grid container direction="row">
              <Grid item xs={12} md={6}>
                <FormLabel component="legend">Lieferungen</FormLabel>
                {this.checkbox(
                  'perm_dln_in',
                  'Eingehende Lieferungen einsehen',
                  this.state.permissions.deliveryNote.accessIncoming,
                  Permissions.PERMISSION.ACCESS_INCOMING_DELIVERY_NOTE
                    .DESCRIPTION,
                )}
                {this.checkbox(
                  'perm_dln_out',
                  'Ausgehende Lieferungen einsehen',
                  this.state.permissions.deliveryNote.accessOutgoing,
                  Permissions.PERMISSION.ACCESS_OUTGOING_DELIVERY_NOTE
                    .DESCRIPTION,
                )}
                {this.checkbox(
                  'perm_dln_trans',
                  'Transportierte Lieferungen einsehen',
                  this.state.permissions.deliveryNote.accessTransported,
                  Permissions.PERMISSION.ACCESS_TRANSPORTED_DELIVERY_NOTE
                    .DESCRIPTION,
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormLabel component="legend">Rechnungen</FormLabel>
                {this.checkbox(
                  'perm_inv_in',
                  'Eingehende Rechnungen einsehen',
                  this.state.permissions.invoice.accessIncoming,
                  Permissions.PERMISSION.ACCESS_INCOMING_INVOICE.DESCRIPTION,
                )}
                {this.checkbox(
                  'perm_inv_out',
                  'Ausgehende Rechnungen einsehen',
                  this.state.permissions.invoice.accessOutgoing,
                  Permissions.PERMISSION.ACCESS_OUTGOING_INVOICE.DESCRIPTION,
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="main-text">
              Administrationsrechte für Verantwortungsbereiche
            </h3>
            <Grid container direction="row">
              <Grid item xs={12} md={6}>
                <FormLabel component="legend">Benutzer</FormLabel>
                {this.checkbox(
                  'user_read',
                  'Benutzer einsehen',
                  this.state.permissions.user.read,
                  Permissions.PERMISSION.READ_USER.DESCRIPTION,
                )}
                {this.checkbox(
                  'user_write',
                  'Benutzer ändern',
                  this.state.permissions.user.write,
                  Permissions.PERMISSION.WRITE_USER.DESCRIPTION,
                )}
                {this.checkbox(
                  'user_create',
                  'Benutzer anlegen',
                  this.state.permissions.user.create,
                  Permissions.PERMISSION.CREATE_USER.DESCRIPTION,
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel component="legend">Standorte</FormLabel>
                {this.checkbox(
                  'site_read',
                  'Standorte einsehen',
                  this.state.permissions.site.read,
                  Permissions.PERMISSION.READ_SITE.DESCRIPTION,
                )}
                {this.checkbox(
                  'site_write',
                  'Standorte ändern',
                  this.state.permissions.site.write,
                  Permissions.PERMISSION.WRITE_SITE.DESCRIPTION,
                )}
                {this.checkbox(
                  'site_create',
                  'Standorte anlegen',
                  this.state.permissions.site.create,
                  Permissions.PERMISSION.CREATE_SITE.DESCRIPTION,
                )}
              </Grid>
            </Grid>
            <Grid container direction="row" className="mt-30px">
              <Grid item xs={12} md={6}>
                <FormLabel component="legend">Kostenstellen</FormLabel>
                {this.checkbox(
                  'cost_center_read',
                  'Kostenstellen einsehen',
                  this.state.permissions.costCenter.read,
                  Permissions.PERMISSION.READ_COST_CENTER.DESCRIPTION,
                )}
                {this.checkbox(
                  'cost_center_write',
                  'Kostenstellen ändern',
                  this.state.permissions.costCenter.write,
                  Permissions.PERMISSION.WRITE_COST_CENTER.DESCRIPTION,
                )}
                {this.checkbox(
                  'cost_center_create',
                  'Kostenstellen anlegen',
                  this.state.permissions.costCenter.create,
                  Permissions.PERMISSION.CREATE_COST_CENTER.DESCRIPTION,
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel component="legend">Fahrzeuge</FormLabel>
                {this.checkbox(
                  'vehicle_read',
                  'Fahrzeuge einsehen',
                  this.state.permissions.vehicle.read,
                  Permissions.PERMISSION.READ_VEHICLE.DESCRIPTION,
                )}
                {this.checkbox(
                  'vehicle_write',
                  'Fahrzeuge ändern',
                  this.state.permissions.vehicle.write,
                  Permissions.PERMISSION.WRITE_VEHICLE.DESCRIPTION,
                )}
                {this.checkbox(
                  'vehicle_create',
                  'Fahrzeuge anlegen',
                  this.state.permissions.vehicle.create,
                  Permissions.PERMISSION.CREATE_VEHICLE.DESCRIPTION,
                )}
              </Grid>
            </Grid>
            <Grid container direction="row" className="mt-30px">
              <Grid item xs={12} md={6}>
                <FormLabel component="legend">Firmen</FormLabel>
                {this.checkbox(
                  'company_read',
                  'Firmen einsehen',
                  this.state.permissions.company.read,
                  Permissions.PERMISSION.READ_COMPANY.DESCRIPTION,
                )}
                {this.checkbox(
                  'company_write',
                  'Firmen ändern',
                  this.state.permissions.company.write,
                  Permissions.PERMISSION.WRITE_COMPANY.DESCRIPTION,
                )}
                {this.checkbox(
                  'company_create',
                  'Firmen anlegen',
                  this.state.permissions.company.create,
                  Permissions.PERMISSION.CREATE_COMPANY.DESCRIPTION,
                )}
              </Grid>
            </Grid>
            <Grid container direction="row" className="mt-30px">
              <Grid item xs={12} md={6}>
                <FormLabel component="legend">Organisations-Gruppen</FormLabel>
                {this.checkbox(
                  'organisational_group_read',
                  'Organisations-Gruppen einsehen',
                  this.state.permissions.organisationalGroup.read,
                  Permissions.PERMISSION.READ_ORGANISATIONAL_GROUP.DESCRIPTION,
                )}
                {this.checkbox(
                  'organisational_group_write',
                  'Organisations-Gruppen ändern',
                  this.state.permissions.organisationalGroup.write,
                  Permissions.PERMISSION.WRITE_ORGANISATIONAL_GROUP.DESCRIPTION,
                )}
                {this.checkbox(
                  'organisational_group_create',
                  'Organisations-Gruppen anlegen',
                  this.state.permissions.organisationalGroup.create,
                  Permissions.PERMISSION.CREATE_ORGANISATIONAL_GROUP
                    .DESCRIPTION,
                )}
                {this.checkbox(
                  'organisational_group_delete',
                  'Organisations-Gruppen löschen',
                  this.state.permissions.organisationalGroup.delete,
                  Permissions.PERMISSION.DELETE_ORGANISATIONAL_GROUP
                    .DESCRIPTION,
                )}
                {this.checkbox(
                  'organisational_group_member',
                  'Zuordnungen verwalten',
                  this.state.permissions.organisationalGroup.member,
                  Permissions.PERMISSION.MEMBER_ORGANISATIONAL_GROUP
                    .DESCRIPTION,
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel component="legend">Benutzer-Gruppen</FormLabel>
                {this.checkbox(
                  'user_group_read',
                  'Benutzer-Gruppen einsehen',
                  this.state.permissions.userGroup.read,
                  Permissions.PERMISSION.READ_USER_GROUP.DESCRIPTION,
                )}
                {this.checkbox(
                  'user_group_write',
                  'Benutzer-Gruppen ändern',
                  this.state.permissions.userGroup.write,
                  Permissions.PERMISSION.WRITE_USER_GROUP.DESCRIPTION,
                )}
                {this.checkbox(
                  'user_group_create',
                  'Benutzer-Gruppen anlegen',
                  this.state.permissions.userGroup.create,
                  Permissions.PERMISSION.CREATE_USER_GROUP.DESCRIPTION,
                )}
                {this.checkbox(
                  'user_group_delete',
                  'Benutzer-Gruppen löschen',
                  this.state.permissions.userGroup.delete,
                  Permissions.PERMISSION.DELETE_USER_GROUP.DESCRIPTION,
                )}
                {this.checkbox(
                  'user_group_member',
                  'Zuordnungen verwalten',
                  this.state.permissions.userGroup.member,
                  Permissions.PERMISSION.MEMBER_USER_GROUP.DESCRIPTION,
                )}
              </Grid>
            </Grid>
            <Grid container direction="row" className="mt-30px">
              <Grid item xs={12} md={6}>
                <FormLabel component="legend">Berechtigungen</FormLabel>
                {this.checkbox(
                  'access_control_grant',
                  'Berechtigungen vergeben',
                  this.state.permissions.permission.grant,
                  Permissions.PERMISSION.GRANT_PERMISSION.DESCRIPTION,
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BasicForm>
    );
  }
}

export default withErrorBoundary(PermissionForm, null);
