import React from 'react';
import { withErrorBoundary } from '~/ui/atoms';
import InvoiceCheck from '../InvoiceCheck';
import { DeliveryNoteLinks } from '~/utils/componentUtils';
import InvoiceCheckCategory from './InvoiceCheckCategory';

class AmountApprovedCheck extends React.Component {
  render() {
    const errorInvoiceChecks = (
      <>
        {this.props.category.errorChecks.map((checkResult, index) => (
          <InvoiceCheck key={index}>
            <div className="w-full">
              Der Artikel{' '}
              <span className="text-error500">
                "{checkResult.articleName ?? ''}"
              </span>{' '}
              wurde in der{' '}
              <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />{' '}
              reklamiert oder nicht bestätigt.
            </div>
          </InvoiceCheck>
        ))}
      </>
    );

    const successInvoiceChecks = (
      <>
        {this.props.category.successChecks.map((checkResult, index) => (
          <InvoiceCheck key={index}>
            <div className="w-full">
              Der Artikel "{checkResult.articleName ?? ''}" wurde in der{' '}
              <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />{' '}
              bestätigt.
            </div>
          </InvoiceCheck>
        ))}
      </>
    );

    return (
      <InvoiceCheckCategory
        category={this.props.category}
        errorInvoiceChecks={errorInvoiceChecks}
        successInvoiceChecks={successInvoiceChecks}
      />
    );
  }
}

export default withErrorBoundary(
  AmountApprovedCheck,
  'Prüfschritt "Keine Reklamationen" konnte nicht geladen werden.',
);
