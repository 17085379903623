import React from 'react';

import { Add as AddIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import Log from '~/utils/Log';
import { withErrorBoundary } from '~/ui/atoms';
import PermissionGrantPickerItem from './PermissionGrantPickerItem';
import ToastService from '~/services/toast.service';
import PromiseUtils from '~/utils/promiseUtils';
import { promiseHandler } from '~/utils/promiseHandler';

class PermissionGrantPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pickerList: [],
    };

    this.childs = [];
  }

  componentDidMount() {
    this.addNewPicker();
  }

  async submit() {
    const promises = [];

    for (const child of this.childs) {
      const promise = child.current.submit();

      promises.push(promise);
    }

    const [response, error] = await promiseHandler(
      PromiseUtils.allResolved(promises),
    );

    if (error) {
      ToastService.error([
        'Berechtigungen konnten nicht vollständig vergeben werden.',
      ]);
      Log.error('Failed to grant permissions.', error);
      Log.productAnalyticsEvent(
        'Failed to grant permissions.',
        Log.FEATURE.PERMISSIONS,
        Log.TYPE.ERROR,
      );
      return;
    }

    ToastService.success(['Berechtigungen wurden vergeben.']);
  }

  addNewPicker = () => {
    this.childs.push(React.createRef());

    const newPickerList = [...this.state.pickerList];
    newPickerList.push({
      ref: this.childs[this.state.pickerList.length],
      key: this.state.pickerList.length,
    });

    Log.info(
      'Add permissions picker',
      { values: newPickerList },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );

    // Initially, a picker is added automatically. We don't want to incorrectly track this as user behavior.
    if (this.state.pickerList.length > 0) {
      Log.productAnalyticsEvent('Add picker', Log.FEATURE.PERMISSIONS);
    }

    this.setState({
      pickerList: newPickerList,
    });
  };

  render() {
    return (
      <>
        {this.state.pickerList.map((picker) => (
          <PermissionGrantPickerItem
            fixedPicker={this.props.fixedPicker}
            defaultSubjects={this.props.defaultSubjects}
            defaultSubjectType={this.props.defaultSubjectType}
            defaultEntityType={this.props.defaultEntityType}
            defaultEntities={this.props.defaultEntities}
            ref={picker.ref}
            key={picker.key}
          />
        ))}
        <div className="flex-c-c w-full">
          <IconButton
            className="m-auto"
            onClick={this.addNewPicker}
            size="large"
          >
            <AddIcon />
          </IconButton>
        </div>
      </>
    );
  }
}

export default withErrorBoundary(
  PermissionGrantPicker,
  'Daten konnten nicht geladen werden.',
);
