import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATE } from '~/constants/LoadingState';

const pdfTemplatesSlice = createSlice({
  name: 'pdfTemplates',
  initialState: {
    pdfTemplates: [],
    pdfTemplatesLoading: LOADING_STATE.NOT_LOADED,
  },
  reducers: {
    replacePdfTemplates(state, parameter) {
      const { payload } = parameter;

      state.pdfTemplates = payload;
      state.pdfTemplatesLoading = LOADING_STATE.SUCCEEDED;
    },
    setPdfTemplatesLoading(state, parameter) {
      const { payload } = parameter;
      state.pdfTemplatesLoading = payload;
    },
  },
});

const { actions, reducer } = pdfTemplatesSlice;
export const { replacePdfTemplates, setPdfTemplatesLoading } = actions;
export default reducer;
