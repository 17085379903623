import { PROMISE_STATUS } from '~/constants/AsyncOperationConsts';

class PromiseUtils {
  allResolved(promises) {
    if (promises.length === 0) {
      return Promise.resolve([]);
    }

    return Promise.allSettled(promises).then((results) => {
      // If there was an error, return the first one from the list of promises.
      const error = results.find(
        (result) => result.status === PROMISE_STATUS.REJECTED,
      )?.reason;
      if (error) {
        throw error;
      }

      return results;
    });
  }
}

export default new PromiseUtils();
