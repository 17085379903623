import React from 'react';
import { useSelector } from 'react-redux';
import { Error as ErrorIcon } from '@mui/icons-material';

import { LOADING_STATE } from '~/constants/LoadingState';

import { Spinner } from '../Spinner';

export const UnauthorizedPage = () => {
  const userPermissionsLoading = useSelector(
    (state) => state.userinfo.userPermissionsLoading,
  );

  const getMessage = () => {
    if (
      userPermissionsLoading === LOADING_STATE.NOT_LOADED ||
      userPermissionsLoading === LOADING_STATE.LOADING
    ) {
      return <Spinner title="Berechtigungen werden geladen..." />;
    }

    if (userPermissionsLoading === LOADING_STATE.FAILED) {
      return (
        <>
          <ErrorIcon className="mr-2" />
          Berechtigungen konnten nicht geladen werden.
        </>
      );
    }

    return (
      <>
        <ErrorIcon className="mr-2" />
        Du bist nicht berechtigt, auf diesen Bereich zuzugreifen.
      </>
    );
  };

  return (
    <div className="flex h-full w-full items-center justify-center">
      {getMessage()}
    </div>
  );
};
