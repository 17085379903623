import React from 'react';

import { Grid } from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import { EditingHistorySafe as EditingHistory } from './EditingHistorySafe';
import ValueGroup from '~/models/deliveries/ValueGroup';
import Address from '~/models/masterdata/Address';
import DeliveryNoteModel from '~/models/deliveries/DeliveryNote';
import { dateUtils } from '~/utils/dateUtils';

class DeliveryNoteContractParties extends React.Component {
  getEmailSubject() {
    return (
      'Lieferung ' +
      ValueGroup.getCurrentValue(this.props.deliveryNote.number) +
      ' vom ' +
      dateUtils.getFormattedDate_safe(
        ValueGroup.getCurrentValue(this.props.deliveryNote.dlnDate),
        dateUtils.DATE_FORMAT.DD_MM_YYYY,
        dateUtils.DATE_FORMAT.YYYY_MM_DD__HH_mm_ss_SSSSSS,
      )
    );
  }

  render() {
    return (
      <>
        <Grid container spacing="20px" justifyContent="space-between">
          <Grid item xs={12} lg={6}>
            <div>{DeliveryNoteModel.CONTRACT_ROLE.SELLER.STRING}</div>
            <div className="bold">
              <EditingHistory value={this.props.seller?.name} />
            </div>
            <div className="bold">
              <EditingHistory
                value={ValueGroup.applyFunction(
                  this.props.seller?.address,
                  Address.getConcatenatedAddress,
                )}
              />
            </div>
          </Grid>
          <Grid item xs={8} lg={4}>
            <div>{DeliveryNoteModel.CONTRACT_ROLE.BUYER.STRING}</div>
            <div className="bold">
              <EditingHistory value={this.props.buyer?.name} />
            </div>
            <div className="bold">
              <EditingHistory
                value={ValueGroup.applyFunction(
                  this.props.buyer?.address,
                  Address.getConcatenatedAddress,
                )}
              />
            </div>
          </Grid>
          <Grid item xs={4} lg={2}>
            <div>Kunden-Nr.</div>
            <div className="bold">
              <EditingHistory value={this.props.buyerId} />
            </div>
          </Grid>
        </Grid>
        {this.props.seller?.universalCommunication?.hasCommunicationInformation() ||
        this.props.buyer?.universalCommunication?.hasCommunicationInformation() ? (
          <Grid container spacing="20px" className="mt-20px">
            <Grid item xs={12} lg={6}>
              <div>Kontaktdaten</div>
              {this.props.seller?.universalCommunication?.hasPhone() ? (
                <div
                  className="bold cursor-pointer"
                  onClick={() =>
                    (window.location =
                      'tel:' +
                      ValueGroup.getCurrentValue(
                        this.props.seller?.universalCommunication?.phone,
                      ))
                  }
                >
                  <EditingHistory
                    value={this.props.seller?.universalCommunication?.phone}
                  />
                </div>
              ) : null}
              {this.props.seller?.universalCommunication?.hasEmail() ? (
                <div
                  className="bold cursor-pointer"
                  onClick={() =>
                    (window.location =
                      'mailto:' +
                      ValueGroup.getCurrentValue(
                        this.props.seller?.universalCommunication?.email,
                      ) +
                      '?subject=' +
                      this.getEmailSubject())
                  }
                >
                  <EditingHistory
                    value={this.props.seller?.universalCommunication?.email}
                  />
                </div>
              ) : null}
            </Grid>
            <Grid item xs={12} lg={6}>
              <div>Kontaktdaten</div>
              {this.props.buyer?.universalCommunication?.hasPhone() ? (
                <div
                  className="bold cursor-pointer"
                  onClick={() =>
                    (window.location =
                      'tel:' +
                      ValueGroup.getCurrentValue(
                        this.props.buyer?.universalCommunication?.phone,
                      ))
                  }
                >
                  <EditingHistory
                    value={this.props.buyer?.universalCommunication?.phone}
                  />
                </div>
              ) : null}
              {this.props.buyer?.universalCommunication?.hasEmail() ? (
                <div
                  className="bold cursor-pointer"
                  onClick={() =>
                    (window.location =
                      'mailto:' +
                      ValueGroup.getCurrentValue(
                        this.props.buyer?.universalCommunication?.email,
                      ) +
                      '?subject=' +
                      this.getEmailSubject())
                  }
                >
                  <EditingHistory
                    value={this.props.buyer?.universalCommunication?.email}
                  />
                </div>
              ) : null}
            </Grid>
          </Grid>
        ) : null}
      </>
    );
  }
}

export default withErrorBoundary(
  DeliveryNoteContractParties,
  'Daten der Lieferpartei konnten nicht geladen werden.',
);
