import React from 'react';

import DataExchangeCompany from '~/models/dataExchanges/DataExchangeCompany';
import Log from '~/utils/Log';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import {
  DataExchangeConfirmedIcon,
  DataExchangeCurrentIssuesIcon,
  DataExchangeInterestedIcon,
  DataExchangeLiveIcon,
  DataExchangeOnHoldIcon,
  DataExchangeQRCodeIcon,
  DataExchangeQualificationIcon,
  DataExchangeSuccessIcon,
  DataExchangeSupplierIcon,
  DataExchangeTestDataIcon,
  DataExchangeTestPhaseIcon,
  DataExchangeVestigasIcon,
} from '~/assets/icons';

class DataExchangeStatusIcon extends React.Component {
  render() {
    switch (this.props.status) {
      case DataExchangeCompany.STATUS.ACTIVATED.KEY: {
        return <DataExchangeSuccessIcon className="text-successBase" />;
      }

      case DataExchangeCompany.STATUS.LIVE.KEY: {
        return <DataExchangeLiveIcon className="text-successBase" />;
      }

      case DataExchangeCompany.STATUS.QR_CODE.KEY: {
        return <DataExchangeQRCodeIcon className="text-successBase" />;
      }

      case DataExchangeCompany.STATUS.TEST_PHASE.KEY: {
        return <DataExchangeTestPhaseIcon className="text-grey600" />;
      }

      case DataExchangeCompany.STATUS.IMPLEMENTATION_SUPPLIER.KEY: {
        return <DataExchangeSupplierIcon className="text-grey600" />;
      }

      case DataExchangeCompany.STATUS.IMPLEMENTATION_VESTIGAS.KEY: {
        return <DataExchangeVestigasIcon className="text-grey600" />;
      }

      case DataExchangeCompany.STATUS.TEST_DATA_EXCHANGED.KEY: {
        return <DataExchangeTestDataIcon className="text-grey600" />;
      }

      case DataExchangeCompany.STATUS.CONFIRMED.KEY: {
        return <DataExchangeConfirmedIcon className="text-grey600" />;
      }

      case DataExchangeCompany.STATUS.ON_HOLD_SUPPLIER.KEY: {
        return <DataExchangeOnHoldIcon className="text-grey600" />;
      }

      case DataExchangeCompany.STATUS.IN_QUALIFICATION.KEY: {
        return <DataExchangeQualificationIcon className="text-grey600" />;
      }

      case DataExchangeCompany.STATUS.INTERESTED.KEY: {
        return <DataExchangeInterestedIcon className="text-grey600" />;
      }

      case DataExchangeCompany.STATUS.NO_STATUS.KEY: {
        return <DataExchangeInterestedIcon className="text-grey600" />;
      }

      case DataExchangeCompany.STATUS.CURRENT_ISSUES.KEY: {
        return <DataExchangeCurrentIssuesIcon className="text-error700" />;
      }

      default: {
        Log.error(
          null,
          new EnumValueNotFoundException(
            'Invalid data exchange status: ' + this.props.status,
          ),
        );
        return null;
      }
    }
  }
}

export default DataExchangeStatusIcon;
