import React from 'react';

class InvoiceMetaDataItem extends React.Component {
  render() {
    return (
      <>
        <div>{this.props.category}</div>
        <div className="bold">{this.props.value}</div>
      </>
    );
  }
}

export default InvoiceMetaDataItem;
