export const columns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 500,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'type',
    headerName: 'Art',
    width: 200,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'active',
    headerName: 'Aktiv',
    width: 100,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'costCenters',
    headerName: 'Kostenstellen',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'address',
    headerName: 'Adresse',
    width: 400,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'company',
    headerName: 'Firma',
    width: 300,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'coords',
    headerName: 'GPS-Standort',
    width: 200,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 400,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'start',
    headerName: 'Startdatum',
    width: 300,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'end',
    headerName: 'Enddatum',
    width: 300,
    sortable: true,
    resizableText: true,
  },
];
