import { useState } from 'react';

import { PhoneAndroid as PhoneAndroidIcon } from '@mui/icons-material';

import PushService from '~/services/push.service';
import ToastService from '~/services/toast.service';

import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

import IconButton from '~/components/IconButton';

export const OpenDeliveryNoteButton = ({ id }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async () => {
    Log.info('Request push notification to open delivery note');
    Log.productAnalyticsEvent('Open delivery note', Log.FEATURE.NOTIFICATIONS);

    setIsProcessing(true);

    const [, error] = await promiseHandler(PushService.openDeliveryNote(id));

    if (error) {
      Log.error(`Failed to send push to open delivery note. id: ${id}`, error);
      ToastService.httpError(
        [
          'Die Lieferung konnte nicht in der mobilen App geöffnet werden wegen eines internen Fehlers.',
        ],
        error.response,
      );
      Log.productAnalyticsEvent(
        'Failed to open delivery note',
        Log.FEATURE.NOTIFICATIONS,
        Log.TYPE.ERROR,
      );
      return;
    }

    setIsProcessing(false);

    ToastService.success([
      'Die Lieferung kann nun in der mobilen App geöffnet werden.',
      'Bitte überprüfe, ob du eine Benachrichtigung auf deinem Handy erhalten hast.',
    ]);
  };

  return (
    <IconButton
      onClick={handleSubmit}
      isLoading={isProcessing}
      tooltipTitle="Lieferung in mobiler App öffnen."
      size="large"
    >
      <PhoneAndroidIcon className="text-primary500" />
    </IconButton>
  );
};
