import Config from '~/Config';
import { ENVIRONMENT } from '~/constants/Environment';

class ConfigUtils {
  isDevelopmentEnvironment() {
    return Config.environment === ENVIRONMENT.DEVELOPMENT;
  }

  isDevelopmentStagingEnvironment() {
    return (
      Config.environment === ENVIRONMENT.DEVELOPMENT ||
      Config.environment === ENVIRONMENT.STAGING
    );
  }

  isProductionEnvironment() {
    return Config.environment === ENVIRONMENT.PRODUCTION;
  }
}

export default new ConfigUtils();
