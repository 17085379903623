import React from 'react';
import { withErrorBoundary } from '~/ui/atoms';
import DataExchangeCompany from '~/models/dataExchanges/DataExchangeCompany';
import DataExchangeCategory from './DataExchangeCategory';
import ObjectUtils from '~/utils/objectUtils';

class DataExchangeTiles extends React.Component {
  getCategories() {
    // Don't display companies that have no status assigned.
    return ObjectUtils.entries(DataExchangeCompany.STATUS)
      .filter(
        (entry) =>
          entry.value.KEY !== DataExchangeCompany.STATUS.NO_STATUS.KEY &&
          entry.value.KEY !== DataExchangeCompany.STATUS.CURRENT_ISSUES.KEY,
      )
      .map((entry) => {
        return {
          key: entry.value.KEY,
          label: entry.value.LABEL,
          dataExchanges: this.props.filteredDataExchanges.filter(
            (dataExchange) => dataExchange.overallStatus === entry.value.KEY,
          ),
        };
      });
  }

  render() {
    return (
      <div className="flexdir-column gap-16px flex">
        {this.getCategories().map((category) => (
          <DataExchangeCategory
            category={category}
            key={category.key}
            requestInformationSelection={this.props.requestInformationSelection}
            requestInformationSelectionList={
              this.props.requestInformationSelectionList
            }
            onChangeRequestInformationSelection={
              this.props.onChangeRequestInformationSelection
            }
          />
        ))}
      </div>
    );
  }
}

export default withErrorBoundary(
  DataExchangeTiles,
  'Lieferantenübersicht konnte nicht geladen werden.',
);
