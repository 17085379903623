const randomBetween = (min, max) => Math.random() * (max - min) + min;

class ColorUtils {
  constructor() {
    this.HSV = {
      S: 0.4,
      V: 0.85,
    };
  }

  getRandomDashboardColor() {
    const h = randomBetween(0.6, 0.7);
    const s = randomBetween(0.1, 0.5);
    const v = randomBetween(0.7, 1);

    return this.HSVtoRGB(h, s, v);
  }

  // min and max in percentage according to HSV color scale: https://de.wikipedia.org/wiki/HSV-Farbraum
  getRandomColor(min, max) {
    const h = randomBetween(min, max);
    return this.HSVtoRGB(h, this.HSV.S, this.HSV.V);
  }

  HSVtoRGB(h, s, v) {
    let r;
    let g;
    let b;
    let index;
    let f;
    let p;
    let q;
    let t;
    index = Math.floor(h * 6);
    f = h * 6 - index;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);

    switch (index % 6) {
      case 0: {
        r = v;
        g = t;
        b = p;
        break;
      }

      case 1: {
        r = q;
        g = v;
        b = p;
        break;
      }

      case 2: {
        r = p;
        g = v;
        b = t;
        break;
      }

      case 3: {
        r = p;
        g = q;
        b = v;
        break;
      }

      case 4: {
        r = t;
        g = p;
        b = v;
        break;
      }

      case 5: {
        r = v;
        g = p;
        b = q;
        break;
      }
    }

    return this.rgbToHex(
      Math.round(r * 255),
      Math.round(g * 255),
      Math.round(b * 255),
    );
  }

  componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }

  rgbToHex(r, g, b) {
    return (
      '#' +
      this.componentToHex(r) +
      this.componentToHex(g) +
      this.componentToHex(b)
    );
  }
}

export default new ColorUtils();
