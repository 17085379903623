import {
  AddLocation as AddLocationIcon,
  BackupTable as BackupTableIcon,
  LockOpenOutlined as LockOpenOutlinedIcon,
  PersonAdd as PersonAddIcon,
  PictureAsPdf as PictureAsPdfIcon,
  TableRows as TableRowsIcon,
} from '@mui/icons-material';

import {
  PieChartOutlinedIcon,
  SignatureIcon,
  UserSignatureIcon,
} from '~/assets/icons';

export const ICON = {
  excel: <BackupTableIcon fontSize="small" />,
  mapDirectDeliveryNote: <AddLocationIcon fontSize="small" />,
  pdf: <PictureAsPdfIcon fontSize="small" />,
  permissionGrant: <LockOpenOutlinedIcon fontSize="small" />,
  report: <PieChartOutlinedIcon className="icon-extra-small" />,
  requestSignature: <UserSignatureIcon className="icon-extra-small" />,
  rowHeight: <TableRowsIcon fontSize="small" />,
  shareDeliveryNote: <PersonAddIcon fontSize="small" />,
  signature: <SignatureIcon className="icon-extra-small" />,
};
