import React, { useState, useMemo } from 'react';

import { Search as SearchIcon } from '@mui/icons-material';

import {
  Select,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
  FormHelperText,
} from '@mui/material';

import { LOADING_STATE } from '~/constants/LoadingState';
import { Spinner } from '~/components/Spinner';
import { LightTooltipWide } from '~/utils/componentUtils';
import ArrayUtils from '~/utils/arrayUtils';
import cloneDeep from 'lodash/cloneDeep';

const containsText = (text, searchText) => {
  if (!text) {
    // If there is nothing to show, don't try to.
    return false;
  }

  if (!searchText) {
    // Return any result if there is no search text.
    return true;
  }

  return text.toLowerCase().includes(searchText.toLowerCase());
};

export default function SearchableSelect(props) {
  const MenuProps = props?.MenuProps ?? {};

  const options = cloneDeep(props.options ?? {});
  if (props.withEmptyOption) {
    options.unshift({
      id: ArrayUtils.EMPTY_DROPDOWN_OPTION,
      name: ArrayUtils.EMPTY_DROPDOWN_OPTION,
      searchString: ArrayUtils.EMPTY_DROPDOWN_OPTION,
    });
  }

  const [searchText, setSearchText] = useState('');
  const displayedOptions = useMemo(
    () =>
      options.filter(
        (option) =>
          containsText(option.name, searchText) ||
          containsText(option.searchString, searchText),
      ),
    [searchText, JSON.stringify(options)],
  );

  const renderValue = () => {
    if (props.loading === LOADING_STATE.LOADING) {
      return <Spinner title="Laden..." />;
    }

    if (!props.value) {
      return ArrayUtils.EMPTY_DROPDOWN_OPTION;
    }

    return (
      props.options.find((option) => option.id === props.value)?.name ?? ''
    );
  };

  const getValue = () => {
    if (!props.value) {
      return 'None';
    } // A random String has to be chosen as fallback value. Otherwise renderValue() wouldn't be executed.

    return props.value;
  };

  const onChange = (event) => {
    if (event.target.value === ArrayUtils.EMPTY_DROPDOWN_OPTION) {
      event.target.value = null;
    }

    props.onChange(event);
  };

  let select = (
    <Select
      size={props.size}
      options={props.options}
      onChange={onChange}
      value={getValue()}
      MenuProps={{ autoFocus: false, ...MenuProps }}
      fullWidth
      onClose={() => setSearchText('')}
      renderValue={renderValue}
      disabled={
        props.loading === LOADING_STATE.LOADING ||
        props.loading === LOADING_STATE.FAILED ||
        props.disabled
      }
    >
      {/* TextField is put into ListSubheader so that it doesn't
                  act as a selectable item in the menu
                  i.e. we can click the TextField without triggering any selection. */}
      <ListSubheader>
        <TextField
          size="small"
          // Autofocus on textfield
          autoFocus
          placeholder="Suchen..."
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== 'Escape') {
              // Prevents autoselecting item while typing (default Select behaviour)
              e.stopPropagation();
            }
          }}
        />
      </ListSubheader>
      {displayedOptions.map((entity) => (
        <MenuItem key={entity.id} value={entity.id}>
          {entity.nameComponent ?? entity.name}
        </MenuItem>
      ))}
    </Select>
  );

  if (props.loading !== LOADING_STATE.LOADING && props.disabled) {
    select = (
      <LightTooltipWide title={renderValue()}>{select}</LightTooltipWide>
    );
  }

  return (
    <div className="relative w-full">
      {select}
      {props.loading === LOADING_STATE.FAILED ? (
        <FormHelperText className="text-mui-error-red absolute">
          {props.errorText ?? 'Daten konnten nicht geladen werden.'}
        </FormHelperText>
      ) : null}
    </div>
  );
}
