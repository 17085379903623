import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATE } from '~/constants/LoadingState';
import ArrayUtils from '~/utils/arrayUtils';

const customFieldsSlice = createSlice({
  name: 'customFields',
  initialState: {
    customFields: [],
    customFieldsLoading: LOADING_STATE.NOT_LOADED,
    customFieldsBulk: [],
    customFieldsBulkNotLoadableIds: [],
  },
  reducers: {
    replaceCustomFields(state, parameter) {
      const { payload } = parameter;

      state.customFields = payload;
      state.customFieldsLoading = LOADING_STATE.SUCCEEDED;
    },
    setCustomFieldsLoading(state, parameter) {
      const { payload } = parameter;

      state.customFieldsLoading = payload;
    },
    addCustomFieldsBulk(state, parameter) {
      const { payload } = parameter;

      state.customFieldsBulk = ArrayUtils.concatByKey(
        state.customFieldsBulk,
        payload,
        'id',
      );
    },
    addCustomFieldsBulkNotLoadableIds(state, parameter) {
      const { payload } = parameter;

      state.customFieldsBulkNotLoadableIds = ArrayUtils.concat(
        state.customFieldsBulkNotLoadableIds,
        payload,
      );
    },
  },
});

const { actions, reducer } = customFieldsSlice;
export const {
  addCustomFieldsBulk,
  addCustomFieldsBulkNotLoadableIds,
  replaceCustomFields,
  setCustomFieldsLoading,
} = actions;
export default reducer;
