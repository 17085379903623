import React, { useState } from 'react';

import {
  ExpandMore as ExpandMoreIcon,
  ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
} from '@mui/icons-material';

import { styled } from '@mui/material/styles';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
} from '@mui/material';

import Log from '~/utils/Log';

const Accordion = styled((props) => <MuiAccordion elevation={0} {...props} />)(
  ({ theme }) => ({
    '&:before': {
      display: 'none',
    },
  }),
);
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: '8px' }}
        className="main-text"
      />
    }
    {...props}
  />
))(({ theme }) => ({
  paddingRight: '8px',
  paddingTop: '20px',
  paddingBottom: 0,
  height: '10px',
  minHeight: 0,
  '& .MuiAccordionSummary-content': {
    justifyContent: 'flex-end',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '0',
}));

export default function StyledAccordion(props) {
  const [expanded, setExpanded] = useState(false);

  const onChange = () => {
    if (expanded) {
      Log.productAnalyticsEvent(
        'Close ' + props.productAnalyticsFeature,
        Log.FEATURE.ACCORDION,
      );
    } else {
      Log.productAnalyticsEvent(
        'Open ' + props.productAnalyticsFeature,
        Log.FEATURE.ACCORDION,
      );
    }

    setExpanded(!expanded);
  };

  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="text-primary500" />}
      >
        <Typography
          style={{
            color: '#173C88',
            fontSize: 14,
            fontWeight: 'bold',
          }}
          component="span"
        >
          {props.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className="w-full" component={'span'}>
          {props.children}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
