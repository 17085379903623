import { DarkTooltipExtraWide } from '~/utils/componentUtils';

export default function InvoiceCheckTableTooltip(props) {
  const body = (
    <div className="flex-s-c p-5px">
      <table>
        <tr>
          {/* Add padding of 20px between columns. If column name is "Rechnung", highlight the text in red. */}
          {props.header.map((value, index) => (
            <th
              key={index}
              className={
                'normal text-start ' +
                (index > 0 ? 'pl-20px' : '') +
                (value === 'Rechnung' ? 'text-error300' : '')
              }
            >
              {value}
            </th>
          ))}
        </tr>
        {props.rows.map((row, index) => (
          <tr key={index}>
            {/* Add padding of 20px between columns. If column name is "Rechnung", highlight the text in red. */}
            {row.map((value, index) => (
              <th
                key={index}
                className={
                  'text-14px text-start align-top ' +
                  (index > 0 ? 'pl-20px' : '') +
                  (props.header[index] === 'Rechnung' ? 'text-error300' : '')
                }
              >
                {value}
              </th>
            ))}
          </tr>
        ))}
      </table>
    </div>
  );

  const content = (
    <div>
      <div className="bold text-14px flex-c-c p-5px border-bottom-grey400 mb-8px w-full">
        {props.title}
      </div>
      {body}
    </div>
  );

  return (
    <DarkTooltipExtraWide title={content}>
      <span className="cursor-pointer">{props.children}</span>
    </DarkTooltipExtraWide>
  );
}
