class FileReaderUtils {
  async readFileAsTextAsync(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.addEventListener('load', (event) => {
        resolve(event.target.result);
      });

      reader.onerror = (event) => {
        reject(event.target.error);
      };

      reader.readAsText(file);
    });
  }
}

export default new FileReaderUtils();
