import axios from '~/utils/api-client';
import Config from '~/Config';
import store from '~/redux/store';
import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';
import { LOADING_STATE } from '~/constants/LoadingState';
import {
  replaceDataExchangeCompanies,
  replaceDataExchanges,
  setDataExchangeCompaniesLoading,
  setDataExchangesLoading,
  setResponsiblePeople,
  setEligiblePeople,
  setSuppliersLoading,
  setUpdatedSuppliers,
  setColumnCount,
} from '~/redux/dataExchangesSlice';
import DataExchange from '~/models/dataExchanges/DataExchange';

import DataExchangeCompany from '~/models/dataExchanges/DataExchangeCompany';
import CompanyService from './company.service';
import CacheService from './cache.service';
import snakecaseKeys from 'snakecase-keys';

const API_URL = Config.apiUrl + '/data_exchange';
const BASE_URL = Config.apiUrl;

class DataExchangeService {
  async getAllDataExchanges() {
    return axios.get(API_URL + '/all').then((response) => {
      if (response.status !== 200) {
        return [];
      }

      if (response?.data?.nextLink) {
        this.getMoreDataExchanges(response?.data?.nextLink);
      }

      return response.data?.items?.map((item) => new DataExchange(item));
    });
  }

  async getDataExchangeAsExcel(queryParams) {
    return axios.get(API_URL + '/company_information/excel', {
      responseType: 'blob',
      params: snakecaseKeys(queryParams)
    });
  }

  async getMoreDataExchanges(nextLink) {
    const request = axios.get(`${BASE_URL}${nextLink}`).then((response) => {
      if (response.status !== 200) {
        return [];
      }

      return response.data;
    });

    const [response, error] = await promiseHandler(request);

    if (error) {
      Log.info('Unable to fetch more data exchanges', error);
      Log.productAnalyticsEvent(
        'Unable to fetch more data exchanges',
        Log.FEATURE.SUPPLIER_OVERVIEW,
        Log.TYPE.ERROR,
      );
      return;
    }

    

    const exchanges = response.items.map((item) => new DataExchange(item));
    const oldExchanges = store.getState()?.dataExchanges?.dataExchanges;

    const updatedDataExchanges = [...oldExchanges, ...exchanges];

    store.dispatch(replaceDataExchanges(updatedDataExchanges));

    if (response?.nextLink) {
      this.getMoreDataExchanges(response.nextLink);
    }
  }

  loadDataExchanges = async () => {
    // to not load data exchanges again when they are already loading or have already been loaded
    if (
      store.getState().dataExchanges?.dataExchangesLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshDataExchanges();
  };
  refreshDataExchanges = async () => {
    store.dispatch(setDataExchangesLoading(LOADING_STATE.LOADING));

    const [dataExchanges, error] = await promiseHandler(
      this.getAllDataExchanges(),
    );

    if (error) {
      store.dispatch(setDataExchangesLoading(LOADING_STATE.FAILED));
      Log.error('Failed to load data exchanges.', error);
      Log.productAnalyticsEvent(
        'Failed to load data exchanges',
        Log.FEATURE.SUPPLIER_OVERVIEW,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceDataExchanges(dataExchanges));
  };

  async getAllDataExchangeCompanies() {
    // TODO: add pagination
    return axios.get(API_URL + '/company_information/all').then((response) => {
      if (response.status !== 200) {
        return [];
      }

      return response.data.items?.map((item) => new DataExchangeCompany(item));
    });
  }

  loadDataExchangeCompanies = async () => {
    if (
      store.getState().dataExchanges?.dataExchangeCompaniesLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshDataExchangeCompanies();
  };
  refreshDataExchangeCompanies = async () => {
    store.dispatch(setDataExchangeCompaniesLoading(LOADING_STATE.LOADING));

    const [dataExchangeCompanies, error] = await promiseHandler(
      this.getAllDataExchangeCompanies(),
    );

    if (error) {
      store.dispatch(setDataExchangeCompaniesLoading(LOADING_STATE.FAILED));
      Log.error('Failed to load data exchange companies.', error);
      Log.productAnalyticsEvent(
        'Failed to load data exchange companies',
        Log.FEATURE.SUPPLIER_OVERVIEW,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceDataExchangeCompanies(dataExchangeCompanies));
  };
  createEmailWithRegistrationLink = async (id) => {
    return axios
      .post(`${API_URL}/contact_link`, { data_exchange_id: id })
      .then((response) => {
        if (response.status !== 200) {
          return null;
        }

        return response.data;
      });
  };

  async loadEligibleResponsiblePeopleForDataExchange(id, ignoreCache) {
    const url = `${API_URL}/${id}/eligible_responsible_persons`;

    if (!ignoreCache) {
      const [cacheValue] = CacheService.getCached(url);
      if (cacheValue) return cacheValue;
    }

    return axios.get(url).then((response) => {
      if (response?.status !== 200) {
        return [];
      }

      if (response?.data?.nextLink) {
        this.getMoreEligibleResponsiblePeople(response?.data?.nextLink);
      }

      CacheService.setCached(url, response?.data?.items);

      store.dispatch(setEligiblePeople(response?.data?.items));
      return response?.data?.items;
    });
  }

  async getMoreEligibleResponsiblePeople(nextLink) {
    const request = axios.get(`${BASE_URL}${nextLink}`).then((response) => {
      if (response.status !== 200) {
        return [];
      }

      return response.data;
    });

    const [response, error] = await promiseHandler(request);

    if (error) {
      Log.info('Unable to load more eligible people', error);
      Log.productAnalyticsEvent(
        'Unable load more eligible people',
        Log.FEATURE.SUPPLIER_OVERVIEW,
        Log.TYPE.ERROR,
      );
      return;
    }

    const eligiblePeople = store.getState()?.dataExchanges?.eligiblePeople;
    const updatedEligiblePeople = [...eligiblePeople, ...response.items];

    store.dispatch(setEligiblePeople(updatedEligiblePeople));

    if (response?.nextLink) {
      this.getMoreEligibleResponsiblePeople(response?.nextLink);
    }
  }

  setResponsiblePerson = async ({ exchangeId, personId }) => {
    return axios.put(`${API_URL}/responsible_user`, null, {
      params: {
        responsible_user_id: personId,
        data_exchange_id: exchangeId,
      },
    });
  };
  removeResponsiblePerson = async ({ senderId, receiverId }) => {
    return axios.put(`${API_URL}`, {
      sender_id: senderId,
      receiver_id: receiverId,
      responsible_person_id: null,
    });
  };
  loadAllResponsiblePeople = async () => {
    const request = axios
      .get(`${API_URL}/responsible_person/all`)
      .then((response) => {
        if (response?.status !== 200) {
          return [];
        }

        return response?.data;
      });

    const [people, error] = await promiseHandler(request);

    if (error) {
      Log.error('Failed to load responsible people', error);
      Log.productAnalyticsEvent(
        'Failed to load responsible people',
        Log.FEATURE.KANBAN_SUPPLIER_OVERVIEW,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(setResponsiblePeople(people.items));

    if (people.nextLink) {
      this.loadMoreResponsiblePeople(people.nextLink);
    }
  };
  loadSingleDataExchange = async (dataExchangeId) => {
    return axios.get(`${API_URL}/query/${dataExchangeId}`).then((response) => {
      if (response.status !== 200) {
        return null;
      }

      return response?.data;
    });
  };

  async loadMoreResponsiblePeople(nextLink) {
    const request = axios.get(`${BASE_URL}${nextLink}`).then((response) => {
      if (response.status !== 200) {
        return [];
      }

      return response.data;
    });

    const [response, error] = await promiseHandler(request);

    if (error) {
      Log.info('Unable to load more responsible people', error);
      Log.productAnalyticsEvent(
        'Unable load more responsible people',
        Log.FEATURE.SUPPLIER_OVERVIEW,
        Log.TYPE.ERROR,
      );
      return;
    }

    const responsiblePeople =
      store?.getState()?.dataExchanges.responsiblePeople;
    const updatedResponsiblePeople = [...responsiblePeople, ...response.items];

    store.dispatch(setResponsiblePeople(updatedResponsiblePeople));

    if (response?.nextLink) {
      this.loadMoreResponsiblePeople(response?.nextLink);
    }
  }

  createContactAttempt = async (body) => {
    return axios.put(`${API_URL}/contact_attempt`, body).then((response) => {
      return response?.data;
    });
  };
  getContactLinkDetailsById = async (linkUrl) => {
    return axios
      .get(`${API_URL}/contact_link/all?link_url=${linkUrl}`)
      .then((response) => {
        return response.data;
      });
  };
  addNewContactPerson = async (body) => {
    return axios.post(`${API_URL}/contact_person`, body).then((response) => {
      if (response?.status !== 200) {
        return false;
      }

      return response.data;
    });
  };
  createNewDataExchange = async (body) => {
    return axios.put(`${API_URL}`, body).then((response) => {
      if (response.status !== 200) {
        return null;
      }

      return new DataExchange(response.data);
    });
  };

  getFilteredSuppliers = async (queryString = '', ignoreCount = false) => {
    if (!ignoreCount) {
      this.getFilteredSuppliersCount(queryString);
    }

    return axios
      .get(`${API_URL}/company_information/query${queryString}`)
      .then((response) => {
        if (response.status !== 200) return [];

        return response.data.items.map((item) => {
          return {
            ...new DataExchangeCompany(item),
            dataExchange: item?.data_exchange
              ? new DataExchange(item?.data_exchange)
              : null,
          };
        });
      });
  };
  getFilteredSuppliersCount = async (queryString = '') => {
    return axios
      .get(`${API_URL}/company_information/query/count${queryString}`)
      .then((response) => {
        if (response.status !== 200) return {};

        store.dispatch(setColumnCount(response.data.counts));
      });
  };
  refreshSuppliers = async (query) => {
    store.dispatch(setSuppliersLoading(LOADING_STATE.LOADING));

    const [suppliers, error] = await promiseHandler(
      this.getFilteredSuppliers(query),
    );

    if (error) {
      store.dispatch(setDataExchangesLoading(LOADING_STATE.FAILED));
      Log.error('Failed to load supplier data exchanges', error);
      Log.productAnalyticsEvent(
        'Failed to load supplier data exchanges',
        Log.FEATURE.KANBAN_SUPPLIER_OVERVIEW,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(setUpdatedSuppliers(suppliers));
  };
  loadSuppliers = async () => {
    const state = store.getState();

    // to not load data exchanges again when they are already loading or have already been loaded
    if (state.dataExchanges?.suppliersLoading !== LOADING_STATE.NOT_LOADED)
      return;
    CompanyService.loadCompanies();

    const filterStore = state?.filters;

    const queryParams = {
      order_by: filterStore.suppliers_sort_model.sortKey,
      sort_order: filterStore.suppliers_sort_model.sortDirection,
      responsible_person_id: filterStore.suppliers_responsible_people,
      company_id: filterStore.suppliers_selectedCompanies,
    };

    const queryString = this.convertQueryParamsToQueryString(queryParams);

    this.refreshSuppliers(queryString);
  };
  convertQueryParamsToQueryString = (queryParams) => {
    const query = [];
    for (const queryKey in queryParams) {
      const value = queryParams[queryKey];

      if (
        !value ||
        (Array.isArray(value) && value.length === 0) ||
        value.length <= 0
      ) {
        continue;
      }

      if (typeof value === 'string' || typeof value === 'number') {
        query.push(`${queryKey}=${value}`);
      }

      if (Array.isArray(value)) {
        for (const option of value) {
          query.push(`${queryKey}=${option}`);
        }
      }
    }

    const queryString = query.join('&');

    return queryString ? `?${queryString}` : '';
  };
}

export default new DataExchangeService();
