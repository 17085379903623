import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATE } from '~/constants/LoadingState';

const dataExchangesSlice = createSlice({
  name: 'dataExchanges',
  initialState: {
    dataExchanges: [],
    dataExchangesLoading: LOADING_STATE.NOT_LOADED,
    dataExchangeCompanies: [],
    dataExchangeCompaniesLoading: LOADING_STATE.NOT_LOADED,
    currentIssues: false,

    // for new supplier portal view
    responsiblePeople: [],
    eligiblePeople: [],
    suppliers: [],
    suppliersLoading: LOADING_STATE.NOT_LOADED,
    columnCount: {},
  },
  reducers: {
    replaceDataExchanges(state, parameter) {
      const { payload } = parameter;

      state.dataExchanges = payload;
      state.dataExchangesLoading = LOADING_STATE.SUCCEEDED;
    },
    setDataExchangesLoading(state, parameter) {
      const { payload } = parameter;

      state.dataExchangesLoading = payload;
    },
    replaceDataExchangeCompanies(state, parameter) {
      const { payload } = parameter;

      state.dataExchangeCompanies = payload;
      state.dataExchangeCompaniesLoading = LOADING_STATE.SUCCEEDED;
    },
    setDataExchangeCompaniesLoading(state, parameter) {
      const { payload } = parameter;

      state.dataExchangeCompaniesLoading = payload;
    },
    setCurrentIssues(state, parameter) {
      const { payload } = parameter;
      state.currentIssues = payload;
    },

    setResponsiblePeople(state, parameter) {
      const { payload } = parameter;
      state.responsiblePeople = payload;
    },
    setEligiblePeople(state, parameter) {
      const { payload } = parameter;
      state.eligiblePeople = payload;
    },

    setUpdatedSuppliers(state, parameter) {
      const { payload } = parameter;

      state.suppliers = payload;
      state.suppliersLoading = LOADING_STATE.SUCCEEDED;
    },

    setSuppliersLoading(state, parameter) {
      const { payload } = parameter;
      state.suppliersLoading = payload;
    },

    setColumnCount(state, parameter) {
      const { payload } = parameter;
      state.columnCount = payload;
    },
  },
});

const { actions, reducer } = dataExchangesSlice;
export const {
  replaceDataExchangeCompanies,
  replaceDataExchanges,
  setCurrentIssues,
  setDataExchangeCompaniesLoading,
  setDataExchangesLoading,
  setEligiblePeople,
  setResponsiblePeople,
  setUpdatedSuppliers,
  setSuppliersLoading,
  setColumnCount,
} = actions;
export default reducer;
