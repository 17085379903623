import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

import { Delete as DeleteIcon, Info as InfoIcon } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';

import { CostCenterIcon, LocationIcon } from '~/assets/icons';

import { setSelectedSites, setSelectedCostCenters } from '~/redux/filtersSlice';
import {
  replaceFilteredDeliveryNotes,
  setDeliveryNotesLoading,
} from '~/redux/deliveryNotesSlice';

import SiteService from '~/services/site.service';
import CostCenterService from '~/services/costCenter.service';
import DeliveriesService from '~/services/deliveries.service';

import Log from '~/utils/Log';
import { LightTooltip } from '~/utils/componentUtils';
import ArrayUtils from '~/utils/arrayUtils';
import { usePrevious } from '~/utils/customHooks';

import { LOADING_STATE } from '~/constants/LoadingState';
import { withErrorBoundary } from '~/ui/atoms';
import BasicPopover from '~/components/BasicPopover';
import { Spinner } from '~/components/Spinner';
import GenericMultiPicker from '~/components/baseComponents/inputs/select/GenericMultiPicker';

import { initSelectableCostCentersAndSites } from './initSelectableCostCentersAndSites';

const selectActiveSites = createSelector(
  (state) => state.sites?.sites || [],
  (sites) => sites.filter(({ active }) => active),
);

const selectActiveCostCenters = createSelector(
  (state) => state.costCenters?.costCenters || [],
  (costCenters) => costCenters.filter(({ active }) => active),
);

export const SitesCostCentersSelection = withErrorBoundary((props) => {
  const { deliveryNotes, deliveryNotesVersion } = useSelector(
    (state) => state.deliveryNotes,
  );
  const { sites, sitesLoading } = useSelector((state) => state.sites);
  const { costCenters, costCentersLoading } = useSelector(
    (state) => state.costCenters,
  );
  const { userinfoLoading } = useSelector((state) => state.userinfo);
  const selectedSites = useSelector((state) => state.filters.selectedSites);
  const selectedCostCenters = useSelector(
    (state) => state.filters.selectedCostCenters,
  );

  const activeSites = useSelector(selectActiveSites);
  const activeCostCenters = useSelector(selectActiveCostCenters);

  const [sortedSites, setSortedSites] = React.useState([]);
  const [sortedCostCenters, setSortedCostCenters] = React.useState([]);
  const [localSelectedSites, setLocalSelectedSites] = React.useState([]);
  const [localSelectedCostCenters, setLocalSelectedCostCenters] =
    React.useState([]);
  const [anchorElement, setAnchorElement] = React.useState(null);

  const previousDeliveryNotesVersion = usePrevious(deliveryNotesVersion);

  const dispatch = useDispatch();

  React.useEffect(() => {
    const updateByBulkLoad =
      previousDeliveryNotesVersion !== deliveryNotesVersion;

    // If no filter is set, show all delivery notes
    if (selectedSites.length === 0 && selectedCostCenters.length === 0) {
      dispatch(
        replaceFilteredDeliveryNotes({
          deliveryNotes,
          updateByBulkLoad,
        }),
      );
      return;
    }

    // If a filter is set, show only the delivery notes that are permitted to the selected sites and cost centers
    const filteredDeliveryNotes = deliveryNotes.filter((deliveryNote) => {
      const permittedToSiteIds = deliveryNote.permittedToSites.map(
        ({ id }) => id,
      );
      const permittedCostCenterIds = deliveryNote.permittedCostCenters.map(
        ({ id }) => id,
      );

      const correctPermittedToSite =
        ArrayUtils.getOverlappingValues(selectedSites, permittedToSiteIds)
          .length > 0;
      const correctPermittedCostCenter =
        ArrayUtils.getOverlappingValues(
          selectedCostCenters,
          permittedCostCenterIds,
        ).length > 0;

      return correctPermittedToSite || correctPermittedCostCenter;
    });

    dispatch(
      replaceFilteredDeliveryNotes({
        deliveryNotes: filteredDeliveryNotes,
        updateByBulkLoad,
      }),
    );
  }, [
    deliveryNotesVersion,
    JSON.stringify(selectedSites),
    JSON.stringify(selectedCostCenters),
  ]);

  React.useEffect(() => {
    SiteService.loadSites();
    CostCenterService.loadCostCenters();
  });

  React.useEffect(() => {
    resetSelectedSites();
    resetSelectedCostCenters();
  }, [sitesLoading, costCentersLoading]);

  React.useEffect(() => {
    initLocalSelectedSites();
  }, [JSON.stringify(selectedSites), JSON.stringify(sortedSites)]);

  React.useEffect(() => {
    initLocalSelectedCostCenters();
  }, [JSON.stringify(selectedCostCenters), JSON.stringify(sortedCostCenters)]);

  React.useEffect(() => {
    initSelectableSitesCostCenters();
  }, [JSON.stringify(sites), JSON.stringify(costCenters)]);

  // We have to reset the selected sites and cost centers if the user (mainly Vestigas support)
  // is switching between different accounts. Therefore, when the sites and cost centers are loaded,
  // we check if the selected sites and cost centers are still valid. If not, we reset them.
  const resetSelectedSites = () => {
    if (sitesLoading !== LOADING_STATE.SUCCEEDED) {
      return;
    }

    const newSelectedSites = selectedSites.filter((selectedSite) =>
      sites.find((site) => site.id === selectedSite),
    );
    if (newSelectedSites.length !== selectedSites.length) {
      dispatch(setSelectedSites(newSelectedSites));
    }
  };

  const resetSelectedCostCenters = () => {
    if (costCentersLoading !== LOADING_STATE.SUCCEEDED) {
      return;
    }

    const newSelectedCostCenters = selectedCostCenters.filter(
      (selectedCostCenter) =>
        costCenters.find((costCenter) => costCenter.id === selectedCostCenter),
    );
    if (newSelectedCostCenters.length !== selectedCostCenters.length) {
      dispatch(setSelectedCostCenters(newSelectedCostCenters));
    }
  };

  const initLocalSelectedSites = () => {
    const newLocalSelectedSites = [];

    for (const siteId of selectedSites) {
      const site = sortedSites.find(({ id }) => id === siteId);
      if (site) {
        newLocalSelectedSites.push(site);
      }
    }

    setLocalSelectedSites(newLocalSelectedSites);
  };

  const initLocalSelectedCostCenters = () => {
    const newLocalSelectedCostCenters = [];

    for (const costCenterId of selectedCostCenters) {
      const costCenter = sortedCostCenters.find(
        ({ id }) => id === costCenterId,
      );
      if (costCenter) {
        newLocalSelectedCostCenters.push(costCenter);
      }
    }

    setLocalSelectedCostCenters(newLocalSelectedCostCenters);
  };

  const initSelectableSitesCostCenters = () => {
    initSelectableCostCentersAndSites({
      activeCostCenters,
      activeSites,
      setSortedCostCenters,
      setSortedSites,
    });
  };

  const formSuccess = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Log.info(
      'Submit current site and cost center selection',
      null,
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent(
      'Submit',
      Log.FEATURE.CURRENT_SITE_AND_COST_CENTER,
    );

    const siteIds = localSelectedSites.map(({ id }) => id);
    const costCenterIds = localSelectedCostCenters.map(({ id }) => id);

    dispatch(setSelectedSites(siteIds));
    dispatch(setSelectedCostCenters(costCenterIds));

    dispatch(setDeliveryNotesLoading(LOADING_STATE.LOADING));
    DeliveriesService.initStoredDlns(false, siteIds, costCenterIds).catch(
      () => {
        dispatch(setDeliveryNotesLoading(LOADING_STATE.FAILED));
      },
    );

    setAnchorElement(null);
  };

  const formAbort = () => {
    Log.productAnalyticsEvent(
      'Abort',
      Log.FEATURE.CURRENT_SITE_AND_COST_CENTER,
    );

    initLocalSelectedSites();
    initLocalSelectedCostCenters();

    setAnchorElement(null);
  };

  const handleChangeSites = (data) => {
    Log.info(
      'Change form value of sites',
      { from: localSelectedSites, to: data },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change sites',
      Log.FEATURE.CURRENT_SITE_AND_COST_CENTER,
    );

    setLocalSelectedSites(data);

    // Determine the sites that were added
    const newSites = ArrayUtils.subtract(
      data.map(({ id }) => id),
      localSelectedSites.map(({ id }) => id),
    );

    // Determine the cost centers that belong to the new sites. Subtract the already selected cost centers.
    const newCostCenters = ArrayUtils.subtract(
      newSites.flatMap((siteId) =>
        sortedSites
          .find(({ id }) => id === siteId)
          .costCenters.map(({ id }) => id),
      ),
      localSelectedCostCenters.map(({ id }) => id),
    );

    // Add the new cost centers to the selected cost centers.
    setLocalSelectedCostCenters([
      ...localSelectedCostCenters,
      ...sortedCostCenters.filter((costCenter) =>
        newCostCenters.includes(costCenter.id),
      ),
    ]);
  };

  const handleChangeCostCenters = (data) => {
    Log.info(
      'Change form value of cost centers',
      { from: localSelectedCostCenters, to: data },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change cost centers',
      Log.FEATURE.CURRENT_SITE_AND_COST_CENTER,
    );

    setLocalSelectedCostCenters(data);
  };

  const handleDelete = () => {
    Log.info(
      'Delete current site and cost center selection',
      null,
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Delete',
      Log.FEATURE.CURRENT_SITE_AND_COST_CENTER,
    );

    setLocalSelectedSites([]);
    setLocalSelectedCostCenters([]);
  };

  const getSelectedSitesString = () => {
    if (selectedSites.length === 0) {
      return 'Kein Standort ausgewählt';
    }

    if (selectedSites.length === 1) {
      return sites.find((site) => site.id === selectedSites[0])?.name ?? '';
    }

    return selectedSites.length + ' Standorte ausgewählt';
  };

  const getSelectedCostCentersString = () => {
    if (selectedCostCenters.length === 0) {
      return 'Keine Kostenstelle ausgewählt';
    }

    if (selectedCostCenters.length === 1) {
      return (
        costCenters.find(
          (costCenter) => costCenter.id === selectedCostCenters[0],
        )?.name ?? ''
      );
    }

    return selectedCostCenters.length + ' Kostenstellen ausgewählt';
  };

  const getComponent = () => {
    if (userinfoLoading === LOADING_STATE.LOADING) {
      return <Spinner title="Laden..." />;
    }

    if (selectedSites.length === 0 && selectedCostCenters.length === 0) {
      return (
        <LightTooltip title="Aktuellen Standort und Kostenstelle auswählen. Lieferungen, Statistiken und Rechnungen werden nur für diese angezeigt.">
          <Button
            variant="outlined"
            onClick={(event) => setAnchorElement(event.currentTarget)}
          >
            Aktuellen Standort auswählen
          </Button>
        </LightTooltip>
      );
    }

    return (
      <div
        className="bold text-primary500 cursor-pointer underline"
        onClick={(event) => setAnchorElement(event.currentTarget)}
      >
        <div className="flex-s-c gap-8px">
          <LocationIcon className="text-primary500 icon-15px" />
          <div className="max-w-400px text-overflow">
            {getSelectedSitesString()}
          </div>
        </div>
        <div className="flex-s-c gap-8px">
          <CostCenterIcon className="text-primary500 icon-15px" />
          <div className="max-w-400px text-overflow">
            {getSelectedCostCentersString()}
          </div>
        </div>
      </div>
    );
  };

  const getDeleteIconButton = () => {
    return (
      <LightTooltip title="Standorte und Kostenstellen zurücksetzen.">
        <IconButton onClick={handleDelete} size="large">
          <DeleteIcon fontSize="small" />
        </IconButton>
      </LightTooltip>
    );
  };

  return (
    <div className={props.className}>
      {getComponent()}
      <BasicPopover
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        onClose={formAbort}
        formSuccess={formSuccess}
        customDeleteButton={getDeleteIconButton()}
      >
        <div className="w-500px flexdir-column gap-20px flex">
          <GenericMultiPicker
            textfieldLabel="Standorte"
            pickedItems={localSelectedSites}
            allItems={sortedSites}
            callbackPickedItems={handleChangeSites}
            loading={sitesLoading}
          />
          <GenericMultiPicker
            textfieldLabel="Kostenstellen"
            pickedItems={localSelectedCostCenters}
            allItems={sortedCostCenters}
            callbackPickedItems={handleChangeCostCenters}
            loading={costCentersLoading}
          />
          <div className="flex-s-c gap-8px">
            <InfoIcon fontSize="small" className="text-primary500" />
            Es werden nur Daten für diese Auswahl angezeigt.
          </div>
        </div>
      </BasicPopover>
    </div>
  );
}, null);
