import moment from 'moment';
import { dateUtils } from '~/utils/dateUtils';
import UnitUtils from '~/utils/unitUtils';
import ArrayUtils from '~/utils/arrayUtils';
import DeliveryNote from '~/models/deliveries/DeliveryNote';
import Log from '~/utils/Log';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import ObjectUtils from '~/utils/objectUtils';
import LocalStorageService from './localStorage.service';
import BilledItem from '~/models/billingState/BilledItem';
import Article from '~/models/articles/Article';
import { normalizeUnitsAndValues } from '~/models/articles/utils';

// this service is used to filter the data for the charts
class DashboardService {
  constructor(props) {
    this.ARTICLE_NUMBER_IN_CHARTS = 100;

    this.DATA_AGGREGATION = {
      HOUR: {
        UP_TO_DAYS: 4,
        AGGREGATE_BY: 'hour',
        TOOLTIP_TITLE(value) {
          const date = moment(value);
          if (!date.isValid()) {
            return null;
          }

          return 'In der Stunde ab ' + date.format('DD.MM.YYYY, HH') + ' Uhr';
        },
        X_AXIS_FORMATTER(value) {
          let hour = value.length === 3 ? value.slice(0, 1) : value.slice(0, 2);
          hour = Number.parseInt(hour);

          const time = value.substring(value.length - 2, value.length);

          if (time === 'AM' && hour === 12) {
            return '0 Uhr';
          }

          if (time === 'AM' || hour === 12) {
            return hour + ' Uhr';
          }

          hour += 12;
          return hour + ' Uhr';
        },
      },
      DAY: {
        UP_TO_DAYS: 62,
        AGGREGATE_BY: 'day',
        TOOLTIP_TITLE(value) {
          const date = moment(value);
          if (!date.isValid()) {
            return null;
          }

          return 'Am ' + date.format('DD.MM.YYYY');
        },
        X_AXIS_FORMATTER(value) {
          return dateUtils.getFormattedDate_safe(
            value,
            dateUtils.DATE_FORMAT.DD_MM,
          );
        },
      },
      WEEK: {
        UP_TO_DAYS: 366,
        AGGREGATE_BY: 'week',
        TOOLTIP_TITLE(value) {
          const date = moment(value);
          if (!date.isValid()) {
            return null;
          }

          return 'In der Woche ab dem ' + date.format('DD.MM.YYYY');
        },
        X_AXIS_FORMATTER(value) {
          return dateUtils.getFormattedDate_safe(
            value,
            dateUtils.DATE_FORMAT.DD_MM_YYYY,
          );
        },
      },
      MONTH: {
        UP_TO_DAYS: 733,
        AGGREGATE_BY: 'month',
        TOOLTIP_TITLE(value) {
          const date = new Date(value);
          return dateUtils.getMonthStringFromDate(date);
        },
        X_AXIS_FORMATTER(value) {
          return new Date(value).toLocaleDateString('de-DE', {
            month: 'short',
            year: 'numeric',
          });
        },
      },
      QUARTER: {
        UP_TO_DAYS: 36_500,
        AGGREGATE_BY: 'quarter',
        TOOLTIP_TITLE(value) {
          const date = new Date(value);
          return 'Im Quartal ab ' + dateUtils.getMonthStringFromDate(date);
        },
        X_AXIS_FORMATTER(value) {
          return value;
        },
      },
    };
  }

  transformDeliveryNotes(deliveryNotes) {
    const rows = [];

    for (const deliveryNote of deliveryNotes) {
      for (const article of deliveryNote.articles) {
        if (!article.amount?.value || !article.amount?.unit) {
          continue;
        }

        rows.push({
          ...article.customData,
          ...deliveryNote.customData,
          acceptState: article.acceptState,
          article: article.type,
          articleNumber: article.number,
          costCenter: article.costCenter ?? '',
          date: deliveryNote.dlnDate ?? deliveryNote.deliveryDate,
          dlnId: deliveryNote.id,
          fromSite: deliveryNote.fromSite?.name,
          number: deliveryNote.number,
          permittedCostCenterNames: deliveryNote.permittedCostCenters.map(
            ({ name }) => name,
          ),
          permittedToSiteNames: deliveryNote.permittedToSites.map(
            ({ name }) => name,
          ),
          processState: deliveryNote.processState,
          recipient: deliveryNote.recipient?.name,
          settledStatus: BilledItem.getSettledStatusDescription(
            article.billedItem.settledStatus,
          ),
          supplier: deliveryNote.supplier?.name,
          toSiteRecipient: deliveryNote.toSiteRecipient?.name,
          toSiteSupplier: deliveryNote.toSiteSupplier?.name,
          toSiteSupplierTradeContact:
            deliveryNote.toSiteSupplier.tradeContact.getConcatenatedContactInformation(),
          unit: article.amount.unit,
          value: article.amount.value,
          ...normalizeUnitsAndValues(article),
        });
      }
    }

    return rows;
  }

  // this function creates datasets (special chartjs format of the data required for the bar chart) based on an input of documents
  createBarChartDatasets(data, aggregateBy) {
    let biggestArticles = this.getBiggestArticles(data);

    const datasets = [];

    // determine distinct dates from dlns so that for each bar, null values are added for all dates
    const dates = biggestArticles.map((item) =>
      moment(item.date).startOf(aggregateBy).toString(),
    );
    const distinctDates = ArrayUtils.removeDuplicates(dates);

    biggestArticles = biggestArticles.map((item) => {
      return {
        ...item,
        aggregatedDate: moment(item.date).startOf(aggregateBy),
      };
    });

    const groupedData = ArrayUtils.assignByKey(
      biggestArticles,
      DeliveryNote.PROPERTY.ARTICLE.KEY,
    );
    for (const article of Object.keys(groupedData)) {
      const dates = ArrayUtils.assignByKey(
        groupedData[article],
        'aggregatedDate',
      );
      const data = distinctDates.map((date) => {
        return {
          x: moment(date),
          y: null,
        };
      });

      // for each "date" (= dlns aggregated by article and date) define the bar value for the chart
      // by summing up the weights
      for (const date of Object.keys(dates)) {
        const dateIndex = distinctDates.indexOf(date);

        const sum = ArrayUtils.sumByKey(dates[date], 'value');

        if (data[dateIndex] === null) {
          data[dateIndex].y = sum;
        } else {
          data[dateIndex].y += sum;
        }
      }

      datasets.push({
        label: article,
        yAxisID: 'yAxisID',
        backgroundColor: null, // assigned in pie chart component
        maxBarThickness: 15,
        stack: 'arbitrary-stack-key', // needed so that values in bar chart are stacked and not just displayed on top of each other
        data,
      });
    }

    return datasets;
  }

  createBarChartDatasetsFromAnalyticsData(analyticsData, dateRange) {
    const distinctDates = this.getDistinctDatesFromDateRange(dateRange);

    const data = analyticsData.map((item) => {
      return {
        label: item.article,
        yAxisID: 'yAxisID',
        backgroundColor: null, // assigned in pie chart component
        maxBarThickness: 15,
        stack: 'arbitrary-stack-key', // needed so that values in bar chart are stacked and not just displayed on top of each other
        data: distinctDates.map((date, index) => {
          return {
            x: date,
            y: item.values[index] ?? null,
          };
        }),
      };
    });

    const sortedData = ArrayUtils.sortByKey(data, 'sum', true);
    return sortedData.slice(0, this.ARTICLE_NUMBER_IN_CHARTS);
  }

  // this function creates datasets (special format of the data required for the pie chart) based on an input of documents
  createPieChartDatasets(data) {
    const biggestArticles = this.getBiggestArticles(data);

    let rows = [];
    const datasets = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
    };

    const groupedData = ArrayUtils.assignByKey(
      biggestArticles,
      DeliveryNote.PROPERTY.ARTICLE.KEY,
    );

    for (const [index, x] of Object.keys(groupedData).entries()) {
      const sum = ArrayUtils.sumByKey(groupedData[x], 'value');

      rows.push({
        value: sum,
        article: x,
      });
    }

    rows = ArrayUtils.sortByKey(rows, 'value', true);

    for (const row of rows) {
      datasets.labels.push(row.article);
      datasets.datasets[0].data.push(row.value);
      datasets.datasets[0].backgroundColor.push(null); // assigned in pie chart component
    }

    return datasets;
  }

  createPieChartDatasetsFromAnalyticsData(analyticsData) {
    const data = analyticsData.map(({ article, values }) => ({
      article,
      value: values.reduce((a, b) => a + b, 0),
    }));

    return this.createPieChartDatasets(data);
  }

  getBiggestArticles(data) {
    const articles = this.getValuePerArticle(data);
    const sortedArticles = ArrayUtils.sortByKey(articles, 'value', true);

    const biggestArticles = new Set(
      sortedArticles
        .slice(0, this.ARTICLE_NUMBER_IN_CHARTS)
        .map((article) => article.article),
    );

    return data.filter((data) => biggestArticles.has(data.article));
  }

  getAggregationFromDateRange(dateRange) {
    if (!dateRange?.length === 2) {
      Log.error('Invalid date range: ' + dateRange);
      return;
    }

    const from = new Date(dateRange[0]).getTime();
    const to = new Date(dateRange[1]).getTime();

    const numberOfDays = dateUtils.getNumberOfDays(from, to);

    const aggregation = Object.keys(this.DATA_AGGREGATION).find(
      (x) => this.DATA_AGGREGATION[x].UP_TO_DAYS >= numberOfDays,
    );

    if (!aggregation) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid date range: ' + dateRange),
      );
      return;
    }

    return this.DATA_AGGREGATION[aggregation].AGGREGATE_BY;
  }

  getDistinctDatesFromDateRange(dateRange) {
    const aggregateBy = this.getAggregationFromDateRange(dateRange);

    if (!aggregateBy) {
      return [];
    }

    const from = moment(dateRange[0]);
    const to = moment(dateRange[1]).add(1, 'day'); // Add one day because the date range is from 00:00 to 00:00

    const start = from.startOf(aggregateBy);

    const dates = [];
    const current = start.clone();
    while (current.isBefore(to)) {
      dates.push(current.clone());
      current.add(1, aggregateBy);
    }

    return dates;
  }

  getXAxisFormatter(aggregateBy, aggregationMapping) {
    const aggregation = Object.keys(aggregationMapping).find(
      (x) => aggregationMapping[x].AGGREGATE_BY === aggregateBy,
    );

    if (!aggregation) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid aggregate: ' + aggregateBy),
      );
      return;
    }

    return aggregationMapping[aggregation].X_AXIS_FORMATTER;
  }

  filterData(
    data,
    filterSiteRecipient,
    filterSiteSupplier,
    filterCostCenter,
    filterArticleNumber,
    filterArticle,
    filterSupplier,
    filterRecipient,
    filterProcessState,
    filterAcceptState,
    filterSettledStatus,
    filterFromSite,
    filterPermittedToSites,
    filterPermittedCostCenters,
    filterCustomFields,
    filterToSiteSupplierTradeContact,
    unit,
    timeframe,
    selectedArticle,
  ) {
    let filteredData = data;

    if (filterSiteRecipient) {
      filteredData = ArrayUtils.filterByKeyValues(
        filteredData,
        DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.KEY,
        filterSiteRecipient,
        ArrayUtils.EMPTY_DROPDOWN_OPTION_RECIPIENT_SITE,
      );
    }

    if (filterSiteSupplier) {
      filteredData = ArrayUtils.filterByKeyValues(
        filteredData,
        DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.KEY,
        filterSiteSupplier,
      );
    }

    if (filterCostCenter) {
      filteredData = ArrayUtils.filterByKeyValues(
        filteredData,
        DeliveryNote.PROPERTY.COST_CENTER.KEY,
        filterCostCenter,
      );
    }

    if (filterArticleNumber) {
      filteredData = ArrayUtils.filterByKeyValues(
        filteredData,
        DeliveryNote.PROPERTY.ARTICLE_NUMBER.KEY,
        filterArticleNumber,
      );
    }

    if (filterArticle) {
      filteredData = ArrayUtils.filterByKeyValues(
        filteredData,
        DeliveryNote.PROPERTY.ARTICLE.KEY,
        filterArticle,
      );
    }

    if (filterProcessState) {
      filteredData = ArrayUtils.filterByKeyValues(
        filteredData,
        DeliveryNote.PROPERTY.PROCESS_STATE.KEY,
        filterProcessState,
      );
    }

    if (filterAcceptState) {
      filteredData = ArrayUtils.filterByKeyValues(
        filteredData,
        DeliveryNote.PROPERTY.ACCEPT_STATE.KEY,
        filterAcceptState,
      );
    }

    if (filterSettledStatus) {
      filteredData = ArrayUtils.filterByKeyValues(
        filteredData,
        DeliveryNote.PROPERTY.SETTLED_STATUS.KEY,
        filterSettledStatus,
      );
    }

    if (filterFromSite) {
      filteredData = ArrayUtils.filterByKeyValues(
        filteredData,
        DeliveryNote.PROPERTY.FROM_SITE.KEY,
        filterFromSite,
      );
    }

    if (filterPermittedToSites && filterPermittedToSites.length > 0) {
      filteredData = ArrayUtils.filterByKeyValuesFromArray(
        filteredData,
        DeliveryNote.PROPERTY.PERMITTED_TO_SITE_NAMES.KEY,
        filterPermittedToSites,
      );
    }

    if (filterPermittedCostCenters && filterPermittedCostCenters.length > 0) {
      filteredData = ArrayUtils.filterByKeyValuesFromArray(
        filteredData,
        DeliveryNote.PROPERTY.PERMITTED_COST_CENTER_NAMES.KEY,
        filterPermittedCostCenters,
      );
    }

    if (filterSupplier) {
      filteredData = ArrayUtils.filterByKeyValues(
        filteredData,
        DeliveryNote.PROPERTY.SUPPLIER.KEY,
        filterSupplier,
      );
    }

    if (filterRecipient) {
      filteredData = ArrayUtils.filterByKeyValues(
        filteredData,
        DeliveryNote.PROPERTY.RECIPIENT.KEY,
        filterRecipient,
      );
    }

    if (filterToSiteSupplierTradeContact) {
      filteredData = ArrayUtils.filterByKeyValues(
        filteredData,
        DeliveryNote.PROPERTY.TO_SITE_SUPPLIER_TRADE_CONTACT.KEY,
        filterToSiteSupplierTradeContact,
      );
    }

    if (unit) {
      const unitKey = UnitUtils.isWeightUnit(unit)
        ? 'weightUnit'
        : 'amountUnit';
      filteredData = ArrayUtils.filterByKeyValues(filteredData, unitKey, unit);
    }

    if (timeframe) {
      filteredData = filteredData.filter(
        (item) =>
          Date.parse(item.date) >= timeframe.from &&
          Date.parse(item.date) <= timeframe.to,
      );
    }

    if (selectedArticle) {
      filteredData = filteredData.filter((item) =>
        selectedArticle.includes(item.article),
      );
    }

    for (const customField of filterCustomFields) {
      if (customField.filterValue.length === 0) {
        continue;
      }

      filteredData = filteredData.filter(
        (item) =>
          customField.filterValue.includes(item[customField.key]) ||
          // If the custom field key isn't set and the filter is set to an empty value, the data should be displayed in the statistics.
          (!item[customField.key] &&
            customField.filterValue.includes(
              ArrayUtils.EMPTY_DROPDOWN_OPTION,
            )) ||
          // Handle booleans in dashboard filter.
          (item[customField.key] === true &&
            customField.filterValue.includes('Ja')) ||
          (item[customField.key] === false &&
            customField.filterValue.includes('Nein')),
      );
    }

    return filteredData;
  }

  getValuePerArticle(articles, unit) {
    const rows = [];

    const groupedArticles = ArrayUtils.assignByKey(
      articles,
      DeliveryNote.PROPERTY.ARTICLE.KEY,
    );

    const isWeightUnit = UnitUtils.isWeightUnit(unit);

    const summingValueKey = isWeightUnit ? 'weightValue' : 'amountValue';
    const unitKey = isWeightUnit ? 'weightUnit' : 'amountUnit';

    for (const article of Object.keys(groupedArticles)) {
      const sum = ArrayUtils.sumByKey(
        groupedArticles[article],
        summingValueKey,
      );

      if (sum && article) {
        rows.push({
          value: sum,
          unit: UnitUtils.getAbbreviatedUnit(articles?.[0]?.[unitKey]),
          unitKey: articles?.[0]?.[unitKey],
          article,
          id: rows.length,
        });
      }
    }

    return rows;
  }

  getValuePerArticleFromAnalyticsData(analyticsData, unit) {
    return analyticsData.map(({ article, values }, index) => ({
      article,
      id: index,
      unit: UnitUtils.getAbbreviatedUnit(unit),
      unitKey: unit,
      value: values.reduce((a, b) => a + b, 0),
    }));
  }

  aggregateValues(articles, columns, key, sorted) {
    const data = ArrayUtils.groupByKeys(articles, columns, key);

    return data.map((row, index) => {
      // clean unused props so that aggregated data is more human-understandable
      for (const x of Object.keys(row)) {
        if (x !== key && !columns.includes(x)) {
          delete row[x];
        }
      }

      return {
        ...row,
        id: index,
      };
    });
  }

  getUnitFrequencies(dashboardData) {
    const units = {};

    for (const item of dashboardData) {
      if (item.amountUnit && units[item.amountUnit]) {
        units[item.amountUnit]++;
      } else {
        units[item.amountUnit] = 1;
      }

      if (item.weightUnit && units[item.weightUnit]) {
        units[item.weightUnit]++;
      } else {
        units[item.weightUnit] = 1;
      }

      if (!(item.amountUnit || item.weightUnit)) {
        // Fall back to `unit` key, if for some reason amountUnit and weightUnit were not mapped to the item.
        if (units[item.unit]) {
          units[item.unit]++;
        } else {
          units[item.unit] = 1;
        }
      }
    }

    return ObjectUtils.entries(units).map((entry) => {
      return {
        unit: entry.key,
        frequency: entry.value,
      };
    });
  }

  // In the old format, custom fields where stored differently in the local storage.
  // Hence, if the old format is detected, return no stored filters as a quick win to protect the app from crashing.
  getSelectedCustomFields_migration() {
    const selectedCustomFields = LocalStorageService.getObjectFromLocalStorage(
      LocalStorageService.FILTER_DASHBOARD_CUSTOM_FIELDS,
    );

    if (
      !selectedCustomFields ||
      selectedCustomFields.length === 0 ||
      !selectedCustomFields[0]
    ) {
      return [];
    }

    return selectedCustomFields;
  }

  getMajorSelectableUnitsForArchiveMode() {
    return [
      {
        id: Article.UNIT.EA.STANDARDISED,
        name: Article.UNIT.EA.DESCRIPTIVE,
        unit: Article.UNIT.EA.STANDARDISED,
      },
      {
        id: Article.UNIT.TNE.STANDARDISED,
        name: Article.UNIT.TNE.DESCRIPTIVE,
        unit: Article.UNIT.TNE.STANDARDISED,
      },
      {
        id: Article.UNIT.MTQ.STANDARDISED,
        name: Article.UNIT.MTQ.DESCRIPTIVE,
        unit: Article.UNIT.MTQ.STANDARDISED,
      },
      {
        id: Article.UNIT.MTR.STANDARDISED,
        name: Article.UNIT.MTR.DESCRIPTIVE,
        unit: Article.UNIT.MTR.STANDARDISED,
      },
      {
        id: Article.UNIT.MTK.STANDARDISED,
        name: Article.UNIT.MTK.DESCRIPTIVE,
        unit: Article.UNIT.MTK.STANDARDISED,
      },
      {
        id: Article.UNIT.LTR.STANDARDISED,
        name: Article.UNIT.LTR.DESCRIPTIVE,
        unit: Article.UNIT.LTR.STANDARDISED,
      },
      {
        id: Article.UNIT.SA.STANDARDISED,
        name: Article.UNIT.SA.DESCRIPTIVE,
        unit: Article.UNIT.SA.STANDARDISED,
      },
      {
        id: Article.UNIT.RO.STANDARDISED,
        name: Article.UNIT.RO.DESCRIPTIVE,
        unit: Article.UNIT.RO.STANDARDISED,
      },
      {
        id: Article.UNIT.PF.STANDARDISED,
        name: Article.UNIT.PF.DESCRIPTIVE,
        unit: Article.UNIT.PF.STANDARDISED,
      },
      {
        id: Article.UNIT.PA.STANDARDISED,
        name: Article.UNIT.PA.DESCRIPTIVE,
        unit: Article.UNIT.PA.STANDARDISED,
      },
      {
        id: Article.UNIT.BJ.STANDARDISED,
        name: Article.UNIT.BJ.DESCRIPTIVE,
        unit: Article.UNIT.BJ.STANDARDISED,
      },
      {
        id: Article.UNIT.BG.STANDARDISED,
        name: Article.UNIT.BG.DESCRIPTIVE,
        unit: Article.UNIT.BG.STANDARDISED,
      },
      {
        id: Article.UNIT.PK.STANDARDISED,
        name: Article.UNIT.PK.DESCRIPTIVE,
        unit: Article.UNIT.PK.STANDARDISED,
      },
      {
        id: Article.UNIT.TU.STANDARDISED,
        name: Article.UNIT.TU.DESCRIPTIVE,
        unit: Article.UNIT.TU.STANDARDISED,
      },
      {
        id: Article.UNIT.CA.STANDARDISED,
        name: Article.UNIT.CA.DESCRIPTIVE,
        unit: Article.UNIT.CA.STANDARDISED,
      },
      {
        id: Article.UNIT.CI.STANDARDISED,
        name: Article.UNIT.CI.DESCRIPTIVE,
        unit: Article.UNIT.CI.STANDARDISED,
      },
      {
        id: Article.UNIT.PR.STANDARDISED,
        name: Article.UNIT.PR.DESCRIPTIVE,
        unit: Article.UNIT.PR.STANDARDISED,
      },
      {
        id: Article.UNIT.CT.STANDARDISED,
        name: Article.UNIT.CT.DESCRIPTIVE,
        unit: Article.UNIT.CT.STANDARDISED,
      },
      {
        id: Article.UNIT.E48.STANDARDISED,
        name: Article.UNIT.E48.DESCRIPTIVE,
        unit: Article.UNIT.E48.STANDARDISED,
      },
    ];
  }
}

export default new DashboardService();
