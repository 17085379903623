import { Dialog, DialogContent, DialogTitle, Button } from '@mui/material';

import FeatureService from '~/services/feature.service';
import { useSelector } from 'react-redux';
import User from '~/models/masterdata/User';
import AuthService from '~/services/auth.service';
import Log from '~/utils/Log';
import UserUtils from '~/utils/userUtils';

export const DeactivatedAccountModal = () => {
  const { userinfo } = useSelector((state) => state.userinfo);

  const logOut = () => {
    AuthService.logout();
  };

  const contactSupport = () => {
    Log.productAnalyticsEvent(
      'Contact support for account activation',
      Log.FEATURE.OTHER_FEATURE,
    );
    window.location =
      'mailto:support@vestigas.com?subject=VESTIGAS-Vollversion';
  };

  const title = User.formatName(userinfo.firstname, userinfo.lastname)
    ? 'Willkommen zurück, ' +
      User.formatName(userinfo.firstname, userinfo.lastname)
    : 'Willkommen zurück';

  return (
    <Dialog
      open={Boolean(
        FeatureService.deactivatedCompanyAccount() &&
          !UserUtils.isVestigasAccount(),
      )}
      aria-labelledby="deactivated-account-modal-title"
      aria-describedby="deactivated-account-description"
      scroll={'paper'}
      maxWidth="lg"
    >
      <DialogTitle className="dialog-title">{title}</DialogTitle>
      <DialogContent>
        <div>
          Dein Account ist derzeit deaktiviert. Für den Fall, dass du
          <br />
          VESTIGAS weiterhin nutzen möchtest, kontaktiere bitte
          <br />
          den Support unter{' '}
          <span className="bold cursor-pointer" onClick={contactSupport}>
            support@vestigas.com
          </span>{' '}
          oder direkt
          <br />
          deine persönliche Ansprechperson bei VESTIGAS.
        </div>
        <div className="mt-20px">Dein VESTIGAS Team</div>
        <div className="mt-20px">
          <Button variant="text" className="mr-30px" onClick={logOut}>
            Ausloggen
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="primary-button"
            onClick={contactSupport}
          >
            Support kontaktieren
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
