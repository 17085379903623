import React from 'react';
import { connect } from 'react-redux';
import { withErrorBoundary } from '~/ui/atoms';
import LocalStorageService from '~/services/localStorage.service';
import { es6ClassFactory as ES6ClassFactory } from '~/utils/ES6ClassFactory';
import Vehicle from '~/models/masterdata/Vehicle';
import { SettingsTable } from '../../SettingsTable';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import MapperService from '~/services/mapper.service';

const columns = [
  {
    field: 'licensePlate',
    headerName: 'Kennzeichen',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'company',
    headerName: 'Firma',
    width: 400,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 400,
    sortable: true,
    resizableText: true,
  },
];

const mapStateToProps = (state) => ({
  userinfo: state.userinfo,
  companies: state.companies,
  vehicles: state.vehicles,
});
const mapDispatchToProps = () => ({});

class VehicleTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadData();
    }
  }

  loadData = () => {
    const newVehicles = ES6ClassFactory.convertToES6Class(
      this.props.vehicles?.vehicles,
      new Vehicle(),
    );

    this.setState({
      rows: newVehicles?.map((vehicle) => {
        const row = {
          id: vehicle.id,
          licensePlate: vehicle.licensePlate.name,
          company: this.props.companies?.companies?.find(
            (company) => company.id === vehicle.company,
          )?.name,
          active: vehicle?.active
            ? Vehicle.IS_ACTIVE.YES
            : Vehicle.IS_ACTIVE.NO,
        };

        return MapperService.addSearchString(row, [
          'id',
          'licensePlate',
          'company',
        ]);
      }),
    });
  };

  render() {
    return (
      <SettingsTable
        entity="vehicle"
        title="Fahrzeuge"
        rows={this.state.rows}
        columns={columns}
        activeFilter
        items={this.props.vehicles.vehicles}
        sortBy="licensePlate"
        loading={this.props.vehicles.vehiclesLoading}
        multiPermissionGrantEdit
        multiPermissionGrantDefaultEntityType={
          PermissionGrant.ENTITY_TYPE.VEHICLE.KEY
        }
        multiPermissionGrantFixedPicker={PermissionGrant.TYPE.ENTITY}
        localStorageKey={LocalStorageService.VEHICLE_TABLE}
      />
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(VehicleTable),
  'Fahrzeuge konnten nicht geladen werden.',
);
