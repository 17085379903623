import Tile from './Tile';
import { withErrorBoundary } from '~/ui/atoms';
import UniversalCommunication from '~/models/masterdata/UniversalCommunication';
import clsx from 'clsx';

const SalesTile = () => {
  const { email, phone } = UniversalCommunication.getSalesContact();

  const rows = [
    {
      title: 'E-Mail',
      value: email,
      onClick: () =>
        (window.location = `mailto:${email}?subject=VESTIGAS-Vollversion`),
    },
    {
      title: 'Telefon',
      value: phone,
      onClick: () => (window.location = `tel:${phone}`),
    },
  ];

  return (
    <Tile title={'VESTIGAS Vertrieb'}>
      <div className="pt-65px flex-s-s gap-20px flexdir-column">
        {rows.map(({ onClick, title, value }) => (
          <div className="flex-s-c">
            <div className="text-grey400 min-w-80px text-start">{title}</div>
            <div
              className={clsx(['bold', { 'cursor-pointer': Boolean(onClick) }])}
              onClick={onClick}
            >
              {value}
            </div>
          </div>
        ))}
        <div className="bold w-full">
          Bei Interesse an einer Vorstellung der VESTIGAS Vollversion
          kontaktiere jederzeit gerne unseren Vertrieb.
        </div>
      </div>
    </Tile>
  );
};

export default withErrorBoundary(SalesTile, null);
