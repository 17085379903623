import React from 'react';
import { connect } from 'react-redux';
import { withErrorBoundary } from '~/ui/atoms';
import { replaceBrowsableInvoices } from '~/redux/invoicesSlice';
import { replaceBrowsableDeliveryNotes } from '~/redux/deliveryNotesSlice';

const mapStateToProps = (state) => ({
  invoices: state.invoices,
});
const mapDispatchToProps = () => ({
  replaceBrowsableInvoices,
  replaceBrowsableDeliveryNotes,
});

class DocumentBrowser extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    this.props.replaceBrowsableDeliveryNotes([]);
    this.props.replaceBrowsableInvoices([]);
  }

  handleKeyDown = (event) => {
    let newDocumentId = null;

    const index = this.props.browsableDocuments.indexOf(this.props.documentId);

    if (index === -1) {
      // Navigate to first invoice
      newDocumentId = this.props.browsableDocuments[0];
    } else if (index === 0 && event.key === 'ArrowLeft') {
      // Navigate to last invoice
      newDocumentId = this.props.browsableDocuments.at(-1);
    } else if (
      index === this.props.browsableDocuments.length - 1 &&
      event.key === 'ArrowRight'
    ) {
      // Navigate to first invoice
      newDocumentId = this.props.browsableDocuments[0];
    } else if (event.key === 'ArrowLeft') {
      // Navigate to previous invoice
      newDocumentId = this.props.browsableDocuments[index - 1];
    } else if (event.key === 'ArrowRight') {
      // Navigate to next invoice
      newDocumentId = this.props.browsableDocuments[index + 1];
    }

    if (!newDocumentId) {
      // As this is currently also triggered when the user just clicks "right" on the keyboard, it spams Sentry with errors.
      // Thus, disable the log until a UI is built for browsing files.
      // Log.error('Failed to find new document id for browsable documents.');
      return;
    }

    this.props.history.push(this.props.route + '/' + newDocumentId);
  };

  render() {
    return <div />;
  }
}

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(DocumentBrowser),
  null,
);
