import { dateUtils } from '~/utils/dateUtils';
import UserUtils from '~/utils/userUtils';
import store from '~/redux/store';
import Article from '../articles/Article';
import UnitUtils from '~/utils/unitUtils';
import ArrayUtils from '~/utils/arrayUtils';
import DeliveryNote from '../deliveries/DeliveryNote';
import Log from '~/utils/Log';

export default class ConcreteDiaryRecord {
  constructor(deliveryNotes, date, concreteId) {
    this.date = date;
    this.concreteId = concreteId;
    this.deliveryNotes = ArrayUtils.sortByKey(
      deliveryNotes,
      DeliveryNote.PROPERTY.NUMBER.KEY,
    );

    this.numberFrom = this.deliveryNotes[0].number;
    this.numberTo = this.deliveryNotes.at(-1).number;

    this.fromSites = deliveryNotes.map(
      (deliveryNote) => deliveryNote.fromSite.name,
    );
    this.customData = deliveryNotes.map(
      (deliveryNote) => deliveryNote.customData,
    );

    this.totalAmount = this.calculateTotalAmount(deliveryNotes);

    // Just take the first article of the first delivery note because they should all be the same in the same concrete diary record
    const article = deliveryNotes[0].articles.find((article) =>
      article.isConcrete(),
    );

    this.delivery = article.delivery;

    this.strengthClass = article.concrete.strengthClass;
    this.exposureClass = article.concrete.exposureClass;
    this.moistureClass = article.concrete.moistureClass;
    this.consistency = article.concrete.consistency;
    this.cement = article.concrete.components
      .filter(
        (component) => component.type === Article.MATERIAL.CE.STANDARDISED,
      )
      .map((component) => component.name);
    this.largestGrain = article.concrete.largestGrain;
    // Prüfalter missing
    this.waterCementRatio = article.concrete.waterCementRatio;
    this.beginConcreting = this.getBeginConcreting(deliveryNotes);
    this.endConcreting = this.getEndConcreting(deliveryNotes);
  }

  calculateTotalAmount(deliveryNotes) {
    return deliveryNotes.reduce((sum, deliveryNote) => {
      const article = deliveryNote.articles.find((article) =>
        article.isConcrete(),
      );

      if (article.amount.unit !== Article.UNIT.MTQ.STANDARDISED) {
        Log.error(
          'Invalid unit for concrete diary; does not equal MTQ. unit: ' +
            article.amount.unit,
        );
      }

      if (article.amount.value === null) {
        Log.error('Invalid amount value of null');
        return sum;
      }

      return sum + article.amount.value;
    }, 0);
  }

  getBeginConcreting(deliveryNotes) {
    const timestamps = deliveryNotes
      .map((deliveryNote) => deliveryNote.execution.beginUnloading)
      .filter(Boolean)
      .sort();
    return timestamps[0];
  }

  getEndConcreting(deliveryNotes) {
    const timestamps = deliveryNotes
      .map((deliveryNote) => deliveryNote.execution.endUnloading)
      .filter(Boolean)
      .sort();
    return timestamps.at(-1);
  }

  getConcretingTime() {
    const formattedBeginConcreting = dateUtils.getFormattedDate_safe(
      this.beginConcreting,
      dateUtils.DATE_FORMAT.HH_mm_ss,
    );
    const formattedEndConcreting = dateUtils.getFormattedDate_safe(
      this.endConcreting,
      dateUtils.DATE_FORMAT.HH_mm_ss,
    );

    if (!formattedBeginConcreting || !formattedEndConcreting) {
      return '';
    }

    return formattedBeginConcreting + ' / ' + formattedEndConcreting;
  }

  getExposureClass(exposureClass) {
    const index = this.exposureClass.indexOf(exposureClass);

    if (index === -1) {
      return '';
    }

    return this.exposureClass.substring(index + 2, index + 3);
  }

  // Individual mapping for each client / company
  getExcelString(selectedNumber) {
    const company = store?.getState()?.userinfo?.userinfo?.company?.id;

    if (UserUtils.isGrossmannAccount(company)) {
      return (
        (dateUtils.getFormattedDate_safe(
          this.date,
          dateUtils.DATE_FORMAT.DD_MM_YYYY,
        ) ?? '') +
        '\t' +
        '\t' +
        (UnitUtils.formatDe_safe(this.totalAmount) ?? '') +
        '\t' +
        this.strengthClass +
        '\t' +
        this.concreteId +
        '\t' +
        (this.cement[0] ?? '') +
        '\t' +
        '\t' +
        '\t' +
        this.largestGrain +
        '\t' +
        '\t' +
        '\t' +
        '\t' +
        this.consistency +
        '\t' +
        '\t' +
        '\t' +
        '\t' +
        (selectedNumber ?? this.numberFrom) +
        '\n' +
        '\t' +
        '\t' +
        '\t' +
        '\t' +
        '\t' +
        (this.cement[1] ?? '')
      );
    }

    if (UserUtils.isDechantAccount(company)) {
      return (
        (dateUtils.getFormattedDate_safe(
          this.date,
          dateUtils.DATE_FORMAT.DD_MM_YYYY,
        ) ?? '') +
        '\t' +
        '\t' +
        (UnitUtils.formatDe_safe(this.totalAmount) ?? '') +
        '\t' +
        this.getConcretingTime() +
        '\t' +
        (selectedNumber ?? this.numberFrom) +
        '\t'
      );
    }

    if (UserUtils.isWMAccount(company)) {
      return (
        (dateUtils.getFormattedDate_safe(
          this.date,
          dateUtils.DATE_FORMAT.DD_MM_YYYY,
        ) ?? '') +
        '\t' +
        '\t' +
        this.fromSites[0] +
        '\t' +
        this.numberFrom +
        (this.numberTo ? ' - ' + this.numberTo : '') +
        '\t' +
        '\t' +
        this.concreteId +
        '\t' +
        this.strengthClass +
        '\t' +
        this.getExposureClass('XC') +
        '\t' +
        this.getExposureClass('XD') +
        '\t' +
        this.getExposureClass('XF') +
        '\t' +
        this.getExposureClass('XM') +
        '\t' +
        this.getExposureClass('XA') +
        '\t' +
        this.getExposureClass('XS') +
        '\t' +
        this.moistureClass +
        '\t' +
        '\t' +
        '\t' +
        this.consistency +
        '\t' +
        '\t' +
        (UnitUtils.formatDe_safe(this.totalAmount) ?? '')
      );
    }

    if (UserUtils.isATHAccount(company)) {
      return (
        (dateUtils.getFormattedDate_safe(
          this.date,
          dateUtils.DATE_FORMAT.DD_MM_YYYY,
        ) ?? '') +
        '\t' +
        (selectedNumber ?? this.numberFrom) +
        '\t' +
        (UnitUtils.formatDe_safe(this.totalAmount) ?? '') +
        '\t' +
        '\t' +
        '\t' +
        '\t' +
        '\t' +
        '\t' +
        '\t' +
        '\t' +
        '\t' +
        '\t' +
        '\t' +
        (this.customData?.[0]?.[
          'fcd94bbb-8925-4df7-84cd-af7fdafa37ca_bauteil_rs3'
        ] ?? '') +
        this.strengthClass +
        '\t' +
        this.getExposureClass('XC') +
        '\t' +
        this.getExposureClass('XD') +
        '\t' +
        this.getExposureClass('XS') +
        '\t' +
        this.getExposureClass('XF') +
        '\t' +
        this.getExposureClass('XA') +
        '\t' +
        this.getExposureClass('XM') +
        '\t' +
        this.getExposureClass('W') +
        '\t'
      );
    }

    // return any format in case of test account
    if (UserUtils.isVestigasAccount()) {
      return (
        (dateUtils.getFormattedDate_safe(
          this.date,
          dateUtils.DATE_FORMAT.DD_MM_YYYY,
        ) ?? '') +
        '\t' +
        '\t' +
        (UnitUtils.formatDe_safe(this.totalAmount) ?? '') +
        '\t' +
        this.strengthClass +
        '\t' +
        this.concreteId +
        '\t' +
        this.cement +
        '\t' +
        '\t' +
        '\t' +
        this.largestGrain +
        '\t' +
        '\t' +
        '\t' +
        '\t' +
        this.consistency +
        '\t' +
        '\t' +
        '\t' +
        '\t' +
        (selectedNumber ?? this.numberFrom) +
        '\n'
      );
    }

    throw new Error(
      'No concrete diary excel mapping found for company of logged in user. company id: ' +
        store?.getState()?.userinfo?.userinfo?.company?.id,
    );
  }
}
