import React from 'react';
import { Grid } from '@mui/material';
import { withErrorBoundary } from '~/ui/atoms';
import ValueGroup from '~/models/deliveries/ValueGroup';
import Address from '~/models/masterdata/Address';
import { EditingHistorySafe as EditingHistory } from '../deliveries/deliveryNote/EditingHistorySafe';

class ArticleManufacturer extends React.Component {
  render() {
    return (
      <Grid container spacing="20px" justifyContent="space-between">
        <Grid item xs={12} lg={6}>
          <div>Hersteller</div>
          <div className="bold">
            <EditingHistory value={this.props.manufacturer?.name} />
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <div>Adresse</div>
          <div className="bold">
            <EditingHistory
              value={ValueGroup.applyFunction(
                this.props.manufacturer?.address,
                Address.getConcatenatedAddress,
              )}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withErrorBoundary(
  ArticleManufacturer,
  'Daten des Herstellers konnten nicht geladen werden.',
);
