import React from 'react';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';

import DeliveryNote from '~/models/deliveries/DeliveryNote';
import Log from '~/utils/Log';

function SupportFaqs(props) {
  const onChange = () => {
    Log.productAnalyticsEvent(
      'Open/close FAQ entry',
      Log.FEATURE.OTHER_FEATURE,
    );
  };

  return (
    <div className="box-shadow-blue rounded-5px h-full bg-white">
      <div className="bold p-20px">FAQs</div>
      <div>
        <Accordion className="color-inherit" onChange={onChange}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="bold text-center">
              Was bedeutet der Status einer Lieferung?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Eine Lieferung wird innerhalb von VESTIGAS mit einem Status
              versehen, je nachdem in welchem Teil des Lieferungsprozesses sie
              sich befindet. Im Folgenden werden diese kurz beschrieben:
              <br />
              <br />
              <span className="bold">
                {DeliveryNote.PROCESS_STATE.READY_FOR_OUTPUT.STRING}
              </span>
              <br />
              {DeliveryNote.PROCESS_STATE.READY_FOR_OUTPUT.SUPPORT_DESC}
              <br />
              <br />
              <span className="bold">
                {DeliveryNote.PROCESS_STATE.IN_TRANSPORT.STRING}
              </span>
              <br />
              {DeliveryNote.PROCESS_STATE.IN_TRANSPORT.SUPPORT_DESC}
              <br />
              <br />
              <span className="bold">
                {DeliveryNote.PROCESS_STATE.ARRIVED.STRING}
              </span>
              <br />
              {DeliveryNote.PROCESS_STATE.ARRIVED.SUPPORT_DESC}
              <br />
              <br />
              <span className="bold">
                {DeliveryNote.PROCESS_STATE.CANCELLED.STRING}
              </span>
              <br />
              {DeliveryNote.PROCESS_STATE.CANCELLED.SUPPORT_DESC}
              <br />
              <br />
              <span className="bold">
                {DeliveryNote.PROCESS_STATE.DELIVERED.STRING}
              </span>
              <br />
              {DeliveryNote.PROCESS_STATE.DELIVERED.SUPPORT_DESC}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="color-inherit">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="bold text-center">
              Eine Lieferung wurde als abgerechnet markiert. Was bedeutet das?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Sobald die Lieferung in einer Rechnung referenziert und diese über
              VESTIGAS zugestellt wurde, gilt sie als abgerechnet. Wird nochmals
              versucht, diese Lieferung abzurechnen, gibt VESTIGAS eine
              Fehlermeldung in der Prüfung an.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="color-inherit">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="bold text-center">
              Was bedeutet der Status einer Rechnung?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Eine Rechnung, die über VESTIGAS zugestellt wird, wird mit Hilfe
              vorhandener Lieferungen und ihren eigenen Daten auf Richtigkeit
              überprüft. Im Anschluss wird das Ergebnis der Rechnungsprüfung
              gekürzt in ihrem Status angezeigt. "Korrekt" bedeutet Richtigkeit
              aller Daten, "Einfacher Fehler" einen Fehler in einem einzelnen
              Prüfungschritt und "Komplexer Fehler" Fehler in mehreren
              Prüfungsschritten.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="color-inherit">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className="bold text-center">
              Mein Mobilgerät besitzt kein Zertifikat und bei einer Anfrage
              erhalte ich die Meldung, dass ich keine Berechtigung dazu habe.
              Was kann ich tun?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Zertifikate können von einem Administrator deiner Organisation
              erstellt werden. Wenn du ein Zertifikat angefragt hast, haben wir
              deinem Administrator bereits eine Nachricht geschickt. Falls du
              nach mehreren Tagen noch immer keine Berechtigung hast, empfehlen
              wir, direkt auf deine IT-Abteilung zuzugehen.
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* <Accordion className="color-inherit">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography className="header-faqs">Wo kann ich mehr über die Funktionen von VESTIGAS als Ganzes erfahren?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Unter <a href="//www.vestigas.com/app/lernen">www.vestigas.com/app/lernen</a> bieten wir
                            umfassende Einführungen, Trainings,
                            Hilfestellungen und weiterführende Informationen zu unserem System. Zudem kannst du
                            jederzeit auf unseren Kundensupport zugehen, der dir gerne weiterhilft.
                        </Typography>
                    </AccordionDetails>
                </Accordion> */}
        <Accordion className="color-inherit">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className="bold text-center">
              Wie kann ich meine Online-Session wiederherstellen?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component={'span'}>
              Sofern du keine gültige Session hast, können keine Daten
              synchronisiert werden. In diesem Fall empfehlen wir, die folgenden
              Schritte nacheinander durchzuführen:
              <ul>
                <li>Lade die Seite neu.</li>
                <li>Logge dich aus und wieder ein.</li>
                <li>
                  Schließe die Anwendung (also den Browser) und öffne sie
                  erneut.
                </li>
              </ul>
              Sofern weiterhin keine gültige Session aufgebaut werden konnte,
              kontakiere bitte den Support.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default SupportFaqs;
