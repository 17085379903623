import React from 'react';

import { PersonAdd as PersonAddIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import { LightTooltipWide } from '~/utils/componentUtils';

import Log from '~/utils/Log';
import DeliveryNote from '~/models/deliveries/DeliveryNote';
import DeliveryNoteActionMetaData from '../deliveryNoteActions/DeliveryNoteActionMetaData';
import UserAction from '~/models/userActions/UserAction';

import { UserSignatureIcon } from '~/assets/icons';

class DeliveryListActions extends React.Component {
  onRequestSignatures = (e, id) => {
    e.stopPropagation();

    Log.info('Open request signature form');
    Log.productAnalyticsEvent(
      'Open request signature form',
      Log.FEATURE.NOTIFICATIONS,
    );

    this.props.onRequestSignatures(id);
  };
  onShareDeliveryNote = (e, id) => {
    e.stopPropagation();

    Log.info('Open share delivery note form');
    Log.productAnalyticsEvent(
      'Open share delivery note form',
      Log.FEATURE.SHARE_DELIVERY_NOTE,
    );

    this.props.onShareDeliveryNote(id);
  };

  getRequestSignatureForm(processState, userActions) {
    if (processState === DeliveryNote.PROCESS_STATE.DELIVERED.STRING) {
      return null;
    }

    const requestedUsers = UserAction.getRequestedUsers([userActions]);

    return (
      <LightTooltipWide
        title={
          requestedUsers.length > 0 ? (
            <DeliveryNoteActionMetaData
              title="Signatur bereits angefordert von:"
              users={requestedUsers}
            />
          ) : (
            'Signatur von einem Benutzer anfordern.'
          )
        }
      >
        <Button
          variant="outlined"
          onClick={(e) => this.onRequestSignatures(e, this.props.params.id)}
          className="h-30px"
          startIcon={
            <UserSignatureIcon
              className={
                'icon-small ml-8px ' +
                (requestedUsers.length > 0 ? 'text-grey400' : '')
              }
            />
          }
          {...(requestedUsers.length > 0 ? {} : { color: 'primary' })}
        />
      </LightTooltipWide>
    );
  }

  getShareDeliveryNoteForm(
    toSiteRecipient,
    permittedToSites,
    permittedCostCenters,
  ) {
    if (toSiteRecipient || permittedToSites || permittedCostCenters) {
      return null;
    }

    return (
      <LightTooltipWide title="Lieferung mit einem Benutzer teilen.">
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => this.onShareDeliveryNote(e, this.props.params.id)}
          className="h-30px"
          startIcon={<PersonAddIcon className="icon-small ml-8px" />}
        />
      </LightTooltipWide>
    );
  }

  render() {
    const segments = this.props.params.value.split(';');
    const processState = segments[0];
    const toSiteRecipient = segments[1];
    const permittedToSites = segments[2];
    const permittedCostCenters = segments[3];
    const userActions = JSON.parse(
      segments?.[4]?.match(/[{}]/g)?.length >= 2
        ? segments[4]
        : '{"requestSignatures":[],"shareDeliveryNote":[]}', // Handle broken user data that caused errors
    );

    return (
      <div className="flex-s-c gap-5px">
        {this.getRequestSignatureForm(processState, userActions)}
        {this.getShareDeliveryNoteForm(
          toSiteRecipient,
          permittedToSites,
          permittedCostCenters,
        )}
      </div>
    );
  }
}

export default withErrorBoundary(DeliveryListActions, null);
