import React from 'react';

import { Grid, TextField } from '@mui/material';

import { connect } from 'react-redux';
import BasicForm from '~/components/BasicForm';
import Log from '~/utils/Log';
import cloneDeep from 'lodash/cloneDeep';
import OrganisationalGroup from '~/models/masterdata/OrganisationalGroup';
import { promiseHandler } from '~/utils/promiseHandler';
import OrganisationalGroupService from '~/services/organisationalGroup.service';
import ToastService from '~/services/toast.service';
import {
  replaceOrganisationalGroups,
  setOrganisationalGroupsLoading,
} from '~/redux/organisationalGroupsSlice';
import ComplexPaginatedEntityMultiPicker from '~/components/baseComponents/inputs/select/ComplexPaginatedEntityMultiPicker';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import { OrganisationalGroupPaths } from '../paths/OrganisationalGroupPaths';
import { LOADING_STATE } from '~/constants/LoadingState';
import PermissionGrantEntityTable from '../permissionGrant/PermissionGrantEntityTable';
import UserUtils from '~/utils/userUtils';
import FunctionUtils from '~/utils/functionUtils';
import ObjectUtils from '~/utils/objectUtils';

const mapStateToProps = (state) => ({});
const mapDispatchToProps = () => ({
  setOrganisationalGroupsLoading,
  replaceOrganisationalGroups,
});

class OrganisationalGroupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submittingForm: false,
      deletingForm: false,
      organisationalGroup: new OrganisationalGroup(),
      organisationalGroupLoading: LOADING_STATE.NOT_LOADED,
    };
  }

  componentDidMount() {
    this.resetForm(true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.organisationalGroup) !==
      JSON.stringify(this.props.organisationalGroup)
    ) {
      this.resetForm(
        ObjectUtils.JSONstringifyDiffIgnoringProperty(
          this.props.organisationalGroup,
          prevProps.organisationalGroup,
          'permissionGrantsFrom',
        ),
      );
    }
  }

  resetForm(resetGeneralOrganisationalGroupInformation) {
    if (!resetGeneralOrganisationalGroupInformation) {
      // Only reset the granted permissions because this is the only thing that has been changed.
      const newOrganisationalGroup = cloneDeep(this.state.organisationalGroup);
      newOrganisationalGroup.permissionGrantsFrom = this.props
        .organisationalGroup
        ? this.props.organisationalGroup.permissionGrantsFrom
        : new OrganisationalGroup().permissionGrantsFrom;

      this.setState({
        organisationalGroup: newOrganisationalGroup,
      });

      return;
    }

    this.setState({
      organisationalGroup:
        this.props.organisationalGroup ?? new OrganisationalGroup(),
    });

    if (
      this.props.organisationalGroup &&
      !this.props.organisationalGroup.additionalDataInitiated
    ) {
      this.refreshOrganisationalGroup();
    }
  }

  renderForCreate = () => {
    return this.props.type === 'create';
  };
  formSuccess = async (event) => {
    event.preventDefault();

    if (
      !this.state.organisationalGroup.isHighestOrganisationalGroup() &&
      this.state.organisationalGroup.parentOrganisationalGroups.length === 0
    ) {
      ToastService.warning([
        'Bitte wähle mindestens eine "Mutter"-Organisations-Gruppe aus, zu der diese Organisations-Gruppe gehört.',
      ]);
      Log.productAnalyticsEvent(
        'Missing parent organisational group',
        Log.FEATURE.ORGANISATIONAL_GROUP,
        Log.TYPE.FAILED_VALIDATION,
      );
      return;
    }

    this.setState({
      submittingForm: true,
    });

    const body = {
      name: this.state.organisationalGroup.name,
      users: this.state.organisationalGroup.users,
      sites: this.state.organisationalGroup.sites,
      accounting_references: this.state.organisationalGroup.costCenters,
      vehicles: this.state.organisationalGroup.vehicles,
      companies: this.state.organisationalGroup.companies,
      user_groups: this.state.organisationalGroup.userGroups,
      parent_ous: this.state.organisationalGroup.parentOrganisationalGroups,
      child_ous: this.state.organisationalGroup.childOrganisationalGroups,
    };

    Log.info(
      'Submit organisational group form',
      body,
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.ORGANISATIONAL_GROUP);

    if (this.renderForCreate()) {
      const [organisationalGroupId, error] = await promiseHandler(
        OrganisationalGroupService.createOrganisationalGroup(body),
      );

      if (error) {
        ToastService.httpError(
          ['Organisations-Gruppe konnte nicht angelegt werden.'],
          error.response,
        );
        Log.error('Failed to create organisational group.', error);
        Log.productAnalyticsEvent(
          'Failed to create',
          Log.FEATURE.ORGANISATIONAL_GROUP,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }
    } else {
      const [response, error] = await promiseHandler(
        OrganisationalGroupService.updateOrganisationalGroup(
          this.props.organisationalGroup.id,
          { name: body.name },
        ),
      );

      // Should be passed via body to not have those many backend calls but currently the backend doesn't accept it like this.
      // Marc will change this: https://innovent-consulting.atlassian.net/browse/VGSD-2316
      const [response1, error1] = await promiseHandler(
        OrganisationalGroupService.updateEntities(
          this.props.organisationalGroup.id,
          PermissionGrant.ENTITY_TYPE.USER.KEY,
          this.props.organisationalGroup.users,
          this.state.organisationalGroup.users,
        ),
      );
      const [response2, error2] = await promiseHandler(
        OrganisationalGroupService.updateEntities(
          this.props.organisationalGroup.id,
          PermissionGrant.ENTITY_TYPE.SITE.KEY,
          this.props.organisationalGroup.sites,
          this.state.organisationalGroup.sites,
        ),
      );
      const [response3, error3] = await promiseHandler(
        OrganisationalGroupService.updateEntities(
          this.props.organisationalGroup.id,
          PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY,
          this.props.organisationalGroup.costCenters,
          this.state.organisationalGroup.costCenters,
        ),
      );
      const [response4, error4] = await promiseHandler(
        OrganisationalGroupService.updateEntities(
          this.props.organisationalGroup.id,
          PermissionGrant.ENTITY_TYPE.VEHICLE.KEY,
          this.props.organisationalGroup.vehicles,
          this.state.organisationalGroup.vehicles,
        ),
      );
      const [response5, error5] = await promiseHandler(
        OrganisationalGroupService.updateEntities(
          this.props.organisationalGroup.id,
          PermissionGrant.ENTITY_TYPE.COMPANY.KEY,
          this.props.organisationalGroup.companies,
          this.state.organisationalGroup.companies,
        ),
      );
      const [response6, error6] = await promiseHandler(
        OrganisationalGroupService.updateEntities(
          this.props.organisationalGroup.id,
          PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY,
          this.props.organisationalGroup.userGroups,
          this.state.organisationalGroup.userGroups,
        ),
      );
      // update child organisational units
      const [response7, error7] = await promiseHandler(
        OrganisationalGroupService.updateEntities(
          this.props.organisationalGroup.id,
          PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY,
          this.props.organisationalGroup.childOrganisationalGroups,
          this.state.organisationalGroup.childOrganisationalGroups,
        ),
      );
      // update parent organisational units
      const [response8, error8] = await promiseHandler(
        OrganisationalGroupService.updateParentOrganisationalGroups(
          this.props.organisationalGroup.id,
          PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY,
          this.props.organisationalGroup.parentOrganisationalGroups,
          this.state.organisationalGroup.parentOrganisationalGroups,
        ),
      );

      if (
        error ||
        error1 ||
        error2 ||
        error3 ||
        error4 ||
        error5 ||
        error6 ||
        error7 ||
        error8
      ) {
        ToastService.error([
          'Organisations-Gruppe konnte nicht vollständig aktualisiert werden.',
        ]);
        Log.productAnalyticsEvent(
          'Failed to update',
          Log.FEATURE.ORGANISATIONAL_GROUP,
          Log.TYPE.ERROR,
        );
        Log.error(
          'Failed to update organisational group.',
          error ||
            error1 ||
            error2 ||
            error3 ||
            error4 ||
            error5 ||
            error6 ||
            error7 ||
            error8,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }
    }

    this.setState({
      submittingForm: false,
    });

    this.props.closeForm();
    this.resetForm(true);
    await OrganisationalGroupService.refreshOrganisationalGroups();
    this.props.onRefreshEntities();
  };
  formAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.ORGANISATIONAL_GROUP);
    this.props.closeForm();
    this.resetForm(true);
  };
  formDelete = async (event) => {
    event.preventDefault();

    Log.info(
      'Delete organisational group',
      { id: this.props.organisationalGroup.id },
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Delete', Log.FEATURE.ORGANISATIONAL_GROUP);

    this.setState({
      deletingForm: true,
    });

    const [response, error] = await promiseHandler(
      OrganisationalGroupService.deleteOrganisationalGroup(
        this.props.organisationalGroup.id,
      ),
    );

    if (error) {
      ToastService.httpError(
        ['Organisations-Gruppe konnte nicht gelöscht werden.'],
        error.response,
      );
      Log.error('Failed to delete organisational group.', error);
      Log.productAnalyticsEvent(
        'Failed to delete',
        Log.FEATURE.ORGANISATIONAL_GROUP,
        Log.TYPE.ERROR,
      );
      this.setState({
        deletingForm: false,
      });
      return;
    }

    this.setState({
      deletingForm: false,
    });

    this.props.closeForm();
    await OrganisationalGroupService.refreshOrganisationalGroups();
    this.props.onRefreshEntities();
  };
  handleInputChange = (event) => {
    const newOrganisationalGroup = cloneDeep(this.state.organisationalGroup);

    switch (event.target.name) {
      case 'name': {
        newOrganisationalGroup.name = event.target.value;
        Log.info(
          'Change form value of name',
          {
            from: this.state.organisationalGroup.name,
            to: newOrganisationalGroup.name,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'organisational_group_change_name',
          Log.productAnalyticsEvent,
          ['Change name', Log.FEATURE.ORGANISATIONAL_GROUP],
        );
        break;
      }
    }

    this.setState({
      organisationalGroup: newOrganisationalGroup,
    });
  };
  handleChangeUsers = (users) => {
    const newOrganisationalGroup = cloneDeep(this.state.organisationalGroup);

    newOrganisationalGroup.users = users.map((user) => user.id);

    Log.info(
      'Change form value of users',
      {
        from: this.state.organisationalGroup.users,
        to: newOrganisationalGroup.users,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change users', Log.FEATURE.ORGANISATIONAL_GROUP);

    this.setState({
      organisationalGroup: newOrganisationalGroup,
    });
  };
  handleChangeSites = (sites) => {
    const newOrganisationalGroup = cloneDeep(this.state.organisationalGroup);

    newOrganisationalGroup.sites = sites.map((site) => site.id);

    Log.info(
      'Change form value of sites',
      {
        from: this.state.organisationalGroup.sites,
        to: newOrganisationalGroup.sites,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change sites', Log.FEATURE.ORGANISATIONAL_GROUP);

    this.setState({
      organisationalGroup: newOrganisationalGroup,
    });
  };
  handleChangeCostCenters = (costCenters) => {
    const newOrganisationalGroup = cloneDeep(this.state.organisationalGroup);

    newOrganisationalGroup.costCenters = costCenters.map(
      (costCenter) => costCenter.id,
    );

    Log.info(
      'Change form value of costCenters',
      {
        from: this.state.organisationalGroup.costCenters,
        to: newOrganisationalGroup.costCenters,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change cost centers',
      Log.FEATURE.ORGANISATIONAL_GROUP,
    );

    this.setState({
      organisationalGroup: newOrganisationalGroup,
    });
  };
  handleChangeVehicles = (vehicles) => {
    const newOrganisationalGroup = cloneDeep(this.state.organisationalGroup);

    newOrganisationalGroup.vehicles = vehicles.map((vehicle) => vehicle.id);

    Log.info(
      'Change form value of vehicles',
      {
        from: this.state.organisationalGroup.vehicles,
        to: newOrganisationalGroup.vehicles,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change vehicles',
      Log.FEATURE.ORGANISATIONAL_GROUP,
    );

    this.setState({
      organisationalGroup: newOrganisationalGroup,
    });
  };
  handleChangeCompanies = (companies) => {
    const newOrganisationalGroup = cloneDeep(this.state.organisationalGroup);

    newOrganisationalGroup.companies = companies.map((company) => company.id);

    Log.info(
      'Change form value of companies',
      {
        from: this.state.organisationalGroup.companies,
        to: newOrganisationalGroup.companies,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change companies',
      Log.FEATURE.ORGANISATIONAL_GROUP,
    );

    this.setState({
      organisationalGroup: newOrganisationalGroup,
    });
  };
  handleChangeChildOrganisationalGroups = (childOrganisationalGroups) => {
    const newOrganisationalGroup = cloneDeep(this.state.organisationalGroup);

    newOrganisationalGroup.childOrganisationalGroups =
      childOrganisationalGroups.map(
        (childOrganisationalGroup) => childOrganisationalGroup.id,
      );

    Log.info(
      'Change form value of child organisational groups',
      {
        from: this.state.organisationalGroup.childOrganisationalGroups,
        to: newOrganisationalGroup.childOrganisationalGroups,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change child organisational groups',
      Log.FEATURE.ORGANISATIONAL_GROUP,
    );

    this.setState({
      organisationalGroup: newOrganisationalGroup,
    });
  };
  handleChangeUserGroups = (userGroups) => {
    const newOrganisationalGroup = cloneDeep(this.state.organisationalGroup);

    newOrganisationalGroup.userGroups = userGroups.map(
      (userGroup) => userGroup.id,
    );

    Log.info(
      'Change form value of user groups',
      {
        from: this.state.organisationalGroup.userGroups,
        to: newOrganisationalGroup.userGroups,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change user groups',
      Log.FEATURE.ORGANISATIONAL_GROUP,
    );

    this.setState({
      organisationalGroup: newOrganisationalGroup,
    });
  };
  handleChangeParentOrganisationalGroups = (parentOrganisationalGroups) => {
    const newOrganisationalGroup = cloneDeep(this.state.organisationalGroup);

    newOrganisationalGroup.parentOrganisationalGroups =
      parentOrganisationalGroups.map(
        (parentOrganisationalGroup) => parentOrganisationalGroup.id,
      );

    Log.info(
      'Change form value of parent organisational groups',
      {
        from: this.state.organisationalGroup.parentOrganisationalGroups,
        to: newOrganisationalGroup.parentOrganisationalGroups,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change parent organisational groups',
      Log.FEATURE.ORGANISATIONAL_GROUP,
    );

    this.setState({
      organisationalGroup: newOrganisationalGroup,
    });
  };
  refreshOrganisationalGroup = async () => {
    this.setState({
      organisationalGroupLoading: LOADING_STATE.LOADING,
    });

    const [response, error] = await promiseHandler(
      OrganisationalGroupService.refreshOrganisationalGroup(
        this.props.organisationalGroup.id,
      ),
    );

    if (error) {
      this.setState({
        organisationalGroupLoading: LOADING_STATE.FAILED,
      });
      return;
    }

    this.setState({
      organisationalGroupLoading: LOADING_STATE.SUCCEEDED,
    });
  };

  getPaths() {
    if (this.renderForCreate()) {
      return null;
    }

    if (this.props.organisationalGroup?.isHighestOrganisationalGroup()) {
      return 'Diese Organisations-Gruppe ist die höchste Gruppe in der Hierarchie.';
    }

    return (
      <OrganisationalGroupPaths
        // Remove the last organisationalGroup from the path to make it consistent with the entities (such as sites)
        id={this.props.organisationalGroup.id}
        organisationalGroupPaths={this.props.organisationalGroup.organisationalGroupPaths.map(
          (organisationalGroupPath) => organisationalGroupPath.slice(0, -1),
        )}
        onOpenOrganisationalGroup={(organisationalGroup) =>
          this.props.onOpenOrganisationalGroup(
            organisationalGroup,
            this.getUnsavedChanges(),
          )
        }
      />
    );
  }

  getUnsavedChanges() {
    if (this.renderForCreate()) {
      return [];
    }

    return OrganisationalGroup.getDifferentValues(
      this.props.organisationalGroup,
      this.state.organisationalGroup,
    );
  }

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        formDelete={
          !this.renderForCreate() &&
          !this.props.organisationalGroup?.isHighestOrganisationalGroup()
            ? this.formDelete
            : null
        }
        title={
          'Organisations-Gruppe ' +
          (this.renderForCreate()
            ? 'Erstellen'
            : this.state.organisationalGroup.name)
        }
        fullWidth
        submittingForm={this.state.submittingForm}
        deletingForm={this.state.deletingForm}
        id={this.props.organisationalGroup?.id}
        unsavedChanges={this.getUnsavedChanges()}
        missingPermissionsToSubmit={
          this.renderForCreate()
            ? !UserUtils.isOrganisationalGroupCreateAllowedUser()
            : !UserUtils.isOrganisationalGroupWriteAllowedUser()
        }
        missingPermissionsToDelete={
          !UserUtils.isOrganisationalGroupDeleteAllowedUser()
        }
      >
        <Grid container direction="row" spacing={3} space={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                {this.getPaths()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h3 className="main-text mt-0">Organisations-Gruppe</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="name-input"
                  name="name"
                  label="Name"
                  type="text"
                  fullWidth
                  required
                  value={this.state.organisationalGroup.name}
                  onChange={this.handleInputChange}
                  autoFocus
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h3 className="mt-20px main-text">Enthält die Folgenden...</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <ComplexPaginatedEntityMultiPicker
                  entityType={PermissionGrant.ENTITY_TYPE.USER.KEY}
                  pickedIds={this.state.organisationalGroup.users}
                  callbackPickedItems={this.handleChangeUsers}
                  onChipClick={(user) =>
                    this.props.onOpenUser(user, this.getUnsavedChanges())
                  }
                  onUpdatedItemsChange={this.props.onUpdatedUsersChange}
                />
              </Grid>
              <Grid item xs={12} lg={8}>
                <ComplexPaginatedEntityMultiPicker
                  entityType={PermissionGrant.ENTITY_TYPE.SITE.KEY}
                  pickedIds={this.state.organisationalGroup.sites}
                  callbackPickedItems={this.handleChangeSites}
                  onChipClick={(site) =>
                    this.props.onOpenSite(site, this.getUnsavedChanges())
                  }
                  onUpdatedItemsChange={this.props.onUpdatedSitesChange}
                />
              </Grid>
              <Grid item xs={12} lg={8}>
                <ComplexPaginatedEntityMultiPicker
                  entityType={PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY}
                  pickedIds={this.state.organisationalGroup.costCenters}
                  callbackPickedItems={this.handleChangeCostCenters}
                  onChipClick={(costCenter) =>
                    this.props.onOpenCostCenter(
                      costCenter,
                      this.getUnsavedChanges(),
                    )
                  }
                  onUpdatedItemsChange={this.props.onUpdatedCostCentersChange}
                />
              </Grid>
              <Grid item xs={12} lg={8}>
                <ComplexPaginatedEntityMultiPicker
                  entityType={PermissionGrant.ENTITY_TYPE.VEHICLE.KEY}
                  pickedIds={this.state.organisationalGroup.vehicles}
                  callbackPickedItems={this.handleChangeVehicles}
                  onChipClick={(vehicle) =>
                    this.props.onOpenVehicle(vehicle, this.getUnsavedChanges())
                  }
                  onUpdatedItemsChange={this.props.onUpdatedVehiclesChange}
                />
              </Grid>
              <Grid item xs={12} lg={8}>
                <ComplexPaginatedEntityMultiPicker
                  entityType={PermissionGrant.ENTITY_TYPE.COMPANY.KEY}
                  pickedIds={this.state.organisationalGroup.companies}
                  callbackPickedItems={this.handleChangeCompanies}
                  onChipClick={(company) =>
                    this.props.onOpenCompany(company, this.getUnsavedChanges())
                  }
                  onUpdatedItemsChange={this.props.onUpdatedCompaniesChange}
                />
              </Grid>
              <Grid item xs={12} lg={8}>
                <ComplexPaginatedEntityMultiPicker
                  entityType={
                    PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY
                  }
                  pickedIds={
                    this.state.organisationalGroup.childOrganisationalGroups
                  }
                  callbackPickedItems={
                    this.handleChangeChildOrganisationalGroups
                  }
                  onChipClick={(organisationalGroup) =>
                    this.props.onOpenOrganisationalGroup(
                      organisationalGroup,
                      this.getUnsavedChanges(),
                    )
                  }
                  // Not needed to refresh organisational groups because all organisational groups are already refreshed when submitting the form.
                  // onUpdatedItemsChange={this.props.onUpdatedOrganisationalGroupsChange}
                />
              </Grid>
              <Grid item xs={12} lg={8}>
                <ComplexPaginatedEntityMultiPicker
                  entityType={PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY}
                  pickedIds={this.state.organisationalGroup.userGroups}
                  callbackPickedItems={this.handleChangeUserGroups}
                  onChipClick={(userGroup) =>
                    this.props.onOpenUserGroup(
                      userGroup,
                      this.getUnsavedChanges(),
                    )
                  }
                  onUpdatedItemsChange={this.props.onUpdatedUserGroupsChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h3 className="mt-20px main-text">Ist Teil von...</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <ComplexPaginatedEntityMultiPicker
                  entityType={
                    PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY
                  }
                  pickedIds={
                    this.state.organisationalGroup.parentOrganisationalGroups
                  }
                  callbackPickedItems={
                    this.handleChangeParentOrganisationalGroups
                  }
                  onChipClick={(organisationalGroup) =>
                    this.props.onOpenOrganisationalGroup(
                      organisationalGroup,
                      this.getUnsavedChanges(),
                    )
                  }
                  // Not needed to refresh organisational groups because all organisational groups are already refreshed when submitting the form.
                  // onUpdatedItemsChange={this.props.onUpdatedOrganisationalGroupsChange}
                />
              </Grid>
            </Grid>
          </Grid>
          {this.renderForCreate() ? null : (
            <Grid item xs={12}>
              <PermissionGrantEntityTable
                title="Wer ist auf diese Organisations-Gruppe berechtigt?"
                permissionGrantsFrom={
                  this.state.organisationalGroup.permissionGrantsFrom
                }
                defaultEntities={[this.props.organisationalGroup.id]}
                defaultEntityType={
                  PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY
                }
                fixedPicker={PermissionGrant.TYPE.ENTITY}
                refreshData={this.refreshOrganisationalGroup}
                loading={this.state.organisationalGroupLoading}
              />
            </Grid>
          )}
        </Grid>
      </BasicForm>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps(),
)(OrganisationalGroupForm);
