export const mapStateToProps = ({
  companyAccount,
  customFields,
  deliveryNotes,
  filters: {
    delivery_dateRange,
    delivery_query,
    delivery_selectedAcceptState,
    delivery_selectedArticle,
    delivery_selectedArticleNumber,
    delivery_selectedCostCenter,
    delivery_selectedCustomFields,
    delivery_selectedFromSite,
    delivery_selectedNumber,
    delivery_selectedPermittedCostCenters,
    delivery_selectedPermittedToSites,
    delivery_selectedProcessState,
    delivery_selectedRecipient,
    delivery_selectedSettledStatus,
    delivery_selectedSupplier,
    delivery_selectedToSiteRecipient,
    delivery_selectedToSiteSupplier,
    delivery_selectField,
    delivery_tab,
    deliveryList_filterModel,
    deliveryList_page,
    deliveryList_sortModel,
    selectedCostCenters,
    selectedSites,
  },
  roles,
  userinfo,
}) => ({
  companyAccount,
  customFields,
  dateRange: delivery_dateRange,
  deliveryNotes,
  filterModel: deliveryList_filterModel,
  page: deliveryList_page,
  query: delivery_query,
  roles,
  selectedAcceptState: delivery_selectedAcceptState,
  selectedArticle: delivery_selectedArticle,
  selectedArticleNumber: delivery_selectedArticleNumber,
  selectedCostCenter: delivery_selectedCostCenter,
  selectedCostCenters,
  selectedCustomFields: delivery_selectedCustomFields,
  selectedFromSite: delivery_selectedFromSite,
  selectedNumber: delivery_selectedNumber,
  selectedPermittedCostCenters: delivery_selectedPermittedCostCenters,
  selectedPermittedToSites: delivery_selectedPermittedToSites,
  selectedProcessState: delivery_selectedProcessState,
  selectedRecipient: delivery_selectedRecipient,
  selectedSettledStatus: delivery_selectedSettledStatus,
  selectedSites,
  selectedSupplier: delivery_selectedSupplier,
  selectedTab: delivery_tab,
  selectedToSiteRecipient: delivery_selectedToSiteRecipient,
  selectedToSiteSupplier: delivery_selectedToSiteSupplier,
  selectField: delivery_selectField,
  sortModel: deliveryList_sortModel,
  userinfo,
});
