import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATE } from '~/constants/LoadingState';

import { mapDeliveryRow } from '~/components/deliveries/utils';

const deliveryNotesSlice = createSlice({
  name: 'deliveryNotes',
  initialState: {
    archiveAnalyticsData: [],
    archiveAnalyticsDataAppliedFilters: {},
    archiveAnalyticsDataLoading: LOADING_STATE.NOT_LOADED,
    archiveAnalyticsDataVersion: 0,
    browsableDeliveryNotes: [],
    deliveryChanges: [],
    deliveryNotes: [], // The unprocessed DLN data fetched from the API
    deliveryNotesLoading: LOADING_STATE.NOT_LOADED,
    deliveryNotesVersion: 0,
    deliveryNoteTemplates: [],
    deliveryNoteTemplatesLoading: LOADING_STATE.NOT_LOADED,
    deliveryRowCount: 0,
    deliveryRowCountLoading: LOADING_STATE.NOT_LOADED,
    deliveryRowPages: [],
    deliveryRowPagesAppliedFilters: {},
    deliveryRowPagesLoading: LOADING_STATE.NOT_LOADED,
    deliveryRowPagesVersion: 0,
    deliveryRows: [], // The mapped DLN data from the raw API response
    filteredDeliveryNotes: [],
    filteredDeliveryNotesVersion: 0,
    filteredDeliveryNotesVersionUpdateByBulkLoad: false,
    filteredDeliveryRows: [],
  },
  reducers: {
    replaceDeliveryNotes(state, { payload: deliveryNotes }) {
      // Reset both 'deliveryNote' and 'deliveryRow' state.
      state.deliveryNotes = deliveryNotes; // Persist the 'deliveryNote' as fetched from the API.
      state.deliveryRows = deliveryNotes.map(mapDeliveryRow); // Map the raw API response and persist it as 'deliveryRow'.

      state.deliveryNotesVersion++;

      state.deliveryNotesLoading = LOADING_STATE.SUCCEEDED;
    },
    saveDeliveryNotes(state, { payload: deliveryNotes }) {
      // Append new DLNs to state.
      state.deliveryNotes.push(...deliveryNotes);
      state.deliveryRows.push(...deliveryNotes.map(mapDeliveryRow));

      state.deliveryNotesVersion++;

      state.deliveryNotesLoading = LOADING_STATE.SUCCEEDED;
    },
    updateDeliveryNotes(state, { payload: deliveryNotes }) {
      for (const updatedDln of deliveryNotes) {
        const updatedDeliveryRow = mapDeliveryRow(updatedDln);

        const oldDlnIndex = state.deliveryNotes.findIndex(
          ({ id }) => id === updatedDln.id,
        );
        const oldDlnExists = oldDlnIndex !== -1;

        if (oldDlnExists) {
          if (updatedDln.deleted) {
            // Remove old DLN, because it was deleted.
            state.deliveryNotes.splice(oldDlnIndex, 1);
            state.deliveryRows.splice(oldDlnIndex, 1);
          } else {
            // Update data with new DLN.
            state.deliveryNotes[oldDlnIndex] = updatedDln;
            state.deliveryRows[oldDlnIndex] = updatedDeliveryRow;
          }
        } else if (!updatedDln.deleted) {
          // Add new DLN.
          state.deliveryNotes.unshift(updatedDln);
          state.deliveryRows.unshift(updatedDeliveryRow);
        }
      }

      state.deliveryNotesVersion++;
    },
    replaceFilteredDeliveryNotes(state, { payload }) {
      state.filteredDeliveryNotes = payload.deliveryNotes;
      state.filteredDeliveryRows = payload.deliveryNotes.map(mapDeliveryRow);

      state.filteredDeliveryNotesVersionUpdateByBulkLoad =
        payload.updateByBulkLoad;

      state.filteredDeliveryNotesVersion++;
    },
    saveDeliveryChanges(state, { payload: deliveryChanges }) {
      for (const change of deliveryChanges) {
        const index = state.deliveryChanges.findIndex(
          ({ id }) => id === change.id,
        );
        const isNewData = index === -1;

        if (isNewData) {
          // Add new change.
          state.deliveryChanges.push(change);
        } else {
          // Update existing change.
          state.deliveryChanges[index] = change;
        }
      }
    },
    replaceDeliveryNoteTemplates(state, { payload }) {
      state.deliveryNoteTemplates = payload;

      state.deliveryNoteTemplatesLoading = LOADING_STATE.SUCCEEDED;
    },
    setDeliveryNotesLoading(state, { payload }) {
      state.deliveryNotesLoading = payload;
    },
    setDeliveryNoteTemplatesLoading(state, { payload }) {
      state.deliveryNoteTemplatesLoading = payload;
    },
    replaceBrowsableDeliveryNotes(state, { payload }) {
      state.browsableDeliveryNotes = payload;
    },
    replaceDeliveryRowPage(state, { payload }) {
      const newDeliveryRowPages = [...state.deliveryRowPages];
      const deliveryRowPageIndex = state.deliveryRowPages.findIndex(
        ({ page }) => page === payload.page,
      );
      const isNewData = deliveryRowPageIndex === -1;

      if (isNewData) {
        // Add new deliveries row page.
        newDeliveryRowPages.push({
          page: payload.page,
          deliveryRows: payload.deliveryRows,
        });
      } else {
        // Update existing deliveries row page.
        newDeliveryRowPages[deliveryRowPageIndex].deliveryRows =
          payload.deliveryRows;
      }

      state.deliveryRowPages = newDeliveryRowPages;
      state.deliveryRowPagesAppliedFilters = payload.appliedFilters;
      state.deliveryRowPagesVersion += 1;
    },
    resetDeliveryRowPages(state, { payload }) {
      state.deliveryRowPages = [
        {
          page: payload.page,
          deliveryRows: payload.deliveryRows,
        },
      ];
      state.deliveryRowPagesAppliedFilters = payload.appliedFilters;
      state.deliveryRowPagesVersion += 1;

      state.deliveryRowPagesLoading = LOADING_STATE.SUCCEEDED;
    },
    setDeliveryRowPagesLoading(state, { payload }) {
      state.deliveryRowPagesLoading = payload;
    },
    setDeliveryRowCount(state, { payload }) {
      state.deliveryRowCount = payload;

      state.deliveryRowCountLoading = LOADING_STATE.SUCCEEDED;
    },
    setDeliveryRowCountLoading(state, { payload }) {
      state.deliveryRowCountLoading = payload;
    },
    setArchiveAnalyticsData(state, { payload }) {
      state.archiveAnalyticsData = payload.archiveAnalyticsData;
      state.archiveAnalyticsDataVersion += 1;
      state.archiveAnalyticsDataAppliedFilters = payload.appliedFilters;

      state.archiveAnalyticsDataLoading = LOADING_STATE.SUCCEEDED;
    },
    setArchiveAnalyticsDataLoading(state, { payload }) {
      state.archiveAnalyticsDataLoading = payload;
    },
  },
});

const { actions, reducer } = deliveryNotesSlice;
export const {
  replaceBrowsableDeliveryNotes,
  replaceDeliveryNoteTemplates,
  replaceDeliveryNotes,
  replaceDeliveryRowPage,
  replaceFilteredDeliveryNotes,
  resetDeliveryRowPages,
  saveDeliveryChanges,
  saveDeliveryNotes,
  setArchiveAnalyticsData,
  setArchiveAnalyticsDataLoading,
  setDeliveryNoteTemplatesLoading,
  setDeliveryNotesLoading,
  setDeliveryRowCount,
  setDeliveryRowCountLoading,
  setDeliveryRowPagesLoading,
  updateDeliveryNotes,
} = actions;
export default reducer;
