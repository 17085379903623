import React from 'react';

import { Grid, InputLabel, TextField } from '@mui/material';

import CompanyService from '~/services/company.service';
import { connect } from 'react-redux';
import Company from '~/models/masterdata/Company';
import BasicForm from '~/components/BasicForm';
import { removeCompanyLogo } from '~/redux/companiesSlice';
import { promiseHandler } from '~/utils/promiseHandler';
import cloneDeep from 'lodash/cloneDeep';
import ImageUpload from '~/components/ImageUpload';

import Log from '~/utils/Log';
import ToastService from '~/services/toast.service';
import { OrganisationalGroupPaths } from '../paths/OrganisationalGroupPaths';
import ComplexPaginatedEntityMultiPicker from '~/components/baseComponents/inputs/select/ComplexPaginatedEntityMultiPicker';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import OrganisationalGroupService from '~/services/organisationalGroup.service';
import { LOADING_STATE } from '~/constants/LoadingState';
import PermissionGrantEntityTable from '../permissionGrant/PermissionGrantEntityTable';
import UserUtils from '~/utils/userUtils';
import FunctionUtils from '~/utils/functionUtils';
import Address from '~/models/masterdata/Address';
import SearchableSelect from '~/components/baseComponents/inputs/select/SearchableSelect';
import ObjectUtils from '~/utils/objectUtils';

const mapDispatchToProps = () => ({
  removeCompanyLogo,
});

class CompanyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      company: this.getDefaultCompany(),
      companyLoading: LOADING_STATE.NOT_LOADED,
      companyLogo: null,
      companyLogoHasBeenUpdated: false,
      submittingForm: false,
    };
  }

  componentDidMount() {
    this.resetForm(true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.resetForm(
        ObjectUtils.JSONstringifyDiffIgnoringProperty(
          this.props.company,
          prevProps.company,
          'permissionGrantsFrom',
        ),
      );

      if (this.renderForCreate()) {
        // this.resetInvoiceReceivers();
      } else {
        // this.loadInvoiceReceivers();
        this.loadCompanyLogo();
      }
    }
  }

  resetForm(resetGeneralCompanyInformation) {
    if (!resetGeneralCompanyInformation) {
      // Only reset the granted permissions because this is the only thing that has been changed.
      const newCompany = cloneDeep(this.state.company);
      newCompany.permissionGrantsFrom = this.props.company
        ? this.props.company.permissionGrantsFrom
        : this.getDefaultCompany().permissionGrantsFrom;

      this.setState({
        company: newCompany,
      });

      return;
    }

    this.setState({
      company: this.props.company ?? this.getDefaultCompany(),
      companyLogo: null,
      companyLogoHasBeenUpdated: false,
    });

    if (this.props.company && !this.props.company.additionalDataInitiated) {
      this.refreshCompany();
    }
  }

  async loadCompanyLogo() {
    const [companyLogo, error] = await promiseHandler(
      CompanyService.getCompanyLogo(this.props.company.id, true),
    );

    if (error) {
      ToastService.httpError(
        [ToastService.MESSAGE.COMPANY_LOGO_LOAD_FAILED],
        error.response,
        ToastService.TYPE.WARNING,
      );
      Log.error(
        'Failed to load company logo. company id: ' + this.props.company.id,
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to load company logo',
        Log.FEATURE.COMPANY,
        Log.TYPE.ERROR,
      );
      return;
    }

    this.setState({
      companyLogo,
    });
  }

  renderForCreate = () => {
    return this.props.type === 'create';
  };
  formSuccess = async (event) => {
    event.preventDefault();

    this.setState({
      submittingForm: true,
    });

    const body = {
      name: this.state.company.name,
      address: {
        street_name: this.state.company.address.streetName,
        building_number: this.state.company.address.buildingNumber,
        post_code: this.state.company.address.postCode,
        city: this.state.company.address.city,
        country: this.state.company.address.country,
      },
    };

    if (this.renderForCreate()) {
      body.org_units = this.state.company.organisationalGroups;
    }

    Log.info('Submit company form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.COMPANY);

    if (this.renderForCreate()) {
      const [companyId, error] = await promiseHandler(
        CompanyService.createNewCompany(body),
      );

      if (error) {
        ToastService.httpError(
          [ToastService.MESSAGE.COMPANY_CREATION_FAILED],
          error.response,
        );
        Log.error('Failed to create company.', error);
        Log.productAnalyticsEvent(
          'Failed to create',
          Log.FEATURE.COMPANY,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }

      if (this.state.companyLogo?.size > 0) {
        Log.productAnalyticsEvent('Upload company logo', Log.FEATURE.COMPANY);

        const [response, error2] = await promiseHandler(
          CompanyService.uploadCompanyLogo(companyId, this.state.companyLogo),
        );

        if (error2) {
          ToastService.httpError(
            [ToastService.MESSAGE.COMPANY_LOGO_UPLOAD_FAILED],
            error2.response,
          );
          Log.error(
            'Failed to upload company logo. company id: ' + companyId,
            error2,
          );
          Log.productAnalyticsEvent(
            'Failed to upload company logo',
            Log.FEATURE.COMPANY,
            Log.TYPE.ERROR,
          );
          this.setState({
            submittingForm: false,
          });
          return;
        }

        this.props.removeCompanyLogo(companyId);
      }
    } else {
      const [data, error] = await promiseHandler(
        CompanyService.updateCompany(this.state.company?.id, body),
      );

      if (error) {
        ToastService.httpError(
          [ToastService.MESSAGE.COMPANY_UPDATE_FAILED],
          error.response,
        );
        Log.error(
          'Failed to update company. company id: ' + this.state.company?.id,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to update',
          Log.FEATURE.COMPANY,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }

      const [response2, error2] = await promiseHandler(
        OrganisationalGroupService.updateParentOrganisationalGroups(
          this.props.company.id,
          PermissionGrant.ENTITY_TYPE.COMPANY.KEY,
          this.props.company.organisationalGroups,
          this.state.company.organisationalGroups,
        ),
      );

      if (error2) {
        ToastService.httpError(
          ['Organisations-Gruppen konnten nicht geändert werden.'],
          error2.response,
        );
        Log.productAnalyticsEvent(
          'Failed to update organisational groups',
          Log.FEATURE.COMPANY,
          Log.TYPE.ERROR,
        );
      }

      if (this.state.companyLogo?.size > 0) {
        Log.productAnalyticsEvent('Upload company logo', Log.FEATURE.COMPANY);

        const [response, error2] = await promiseHandler(
          CompanyService.uploadCompanyLogo(
            this.state.company?.id,
            this.state.companyLogo,
          ),
        );

        if (error2) {
          ToastService.httpError(
            [ToastService.MESSAGE.COMPANY_LOGO_UPLOAD_FAILED],
            error2.response,
          );
          Log.error(
            'Failed to upload company logo. company id: ' +
              this.state.company?.id,
            error2,
          );
          Log.productAnalyticsEvent(
            'Failed to upload company logo',
            Log.FEATURE.COMPANY,
            Log.TYPE.ERROR,
          );
          this.setState({
            submittingForm: false,
          });
          return;
        }
      } else {
        Log.productAnalyticsEvent('Delete company logo', Log.FEATURE.COMPANY);

        const [response, error3] = await promiseHandler(
          CompanyService.deleteCompanyLogo(this.state.company?.id),
        );

        if (error3) {
          ToastService.httpError(
            [ToastService.MESSAGE.COMPANY_LOGO_DELETION_FAILED],
            error.response,
          );
          Log.error('Failed to delete company logo.', error);
          Log.productAnalyticsEvent(
            'Failed to delete company logo',
            Log.FEATURE.COMPANY,
            Log.TYPE.ERROR,
          );
        }
      }

      this.setState({
        submittingForm: false,
      });

      this.props.removeCompanyLogo(this.state.company?.id);
      // this.writeInvoiceReceiversToBackend();
      this.props.removeCompanyLogo(this.state.company?.id);
    }

    this.setState({
      submittingForm: false,
    });

    this.props.closeForm();
    this.resetForm(true);
    CompanyService.refreshCompanies();
  };
  formAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.COMPANY);
    this.props.closeForm();
    this.resetForm(true);
  };

  getDefaultCompany() {
    const company = new Company();

    company.address.country = Address.DEFAULT_COUNTRY_CODE.DE;

    return company;
  }

  handleInputChange = (event) => {
    const newCompany = cloneDeep(this.state.company);

    switch (event.target.name) {
      case 'name': {
        newCompany.name = event.target.value;
        Log.info(
          'Change form value of name',
          { from: this.state.company.name, to: newCompany.name },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'company_change_name',
          Log.productAnalyticsEvent,
          ['Change name', Log.FEATURE.COMPANY],
        );
        break;
      }

      case 'street_name': {
        newCompany.address.streetName = event.target.value;
        Log.info(
          'Change form value of street name',
          {
            from: this.state.company.address?.streetName,
            to: newCompany.address.streetName,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'company_change_street_name',
          Log.productAnalyticsEvent,
          ['Change street name', Log.FEATURE.COMPANY],
        );
        break;
      }

      case 'building_number': {
        newCompany.address.buildingNumber = event.target.value;
        Log.info(
          'Change form value of building number',
          {
            from: this.state.company.address?.buildingNumber,
            to: newCompany.address.buildingNumber,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'company_change_building_number',
          Log.productAnalyticsEvent,
          ['Change building number', Log.FEATURE.COMPANY],
        );
        break;
      }

      case 'city': {
        newCompany.address.city = event.target.value;
        Log.info(
          'Change form value of city',
          {
            from: this.state.company.address?.city,
            to: newCompany.address.city,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'company_change_city',
          Log.productAnalyticsEvent,
          ['Change city', Log.FEATURE.COMPANY],
        );
        break;
      }

      case 'post_code': {
        newCompany.address.postCode = event.target.value;
        Log.info(
          'Change form value of post code',
          {
            from: this.state.company.address?.postCode,
            to: newCompany.address.postCode,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'company_change_post_code',
          Log.productAnalyticsEvent,
          ['Change post code', Log.FEATURE.COMPANY],
        );
        break;
      }
    }

    this.setState({
      company: newCompany,
    });
  };
  handleChangeCountry = (event) => {
    const newCompany = cloneDeep(this.state.company);

    newCompany.address.country = event.target.value;

    Log.info(
      'Change form value of country',
      {
        from: this.state.company.address.country,
        to: newCompany.address.country,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change country', Log.FEATURE.COMPANY);

    this.setState({
      company: newCompany,
    });
  };
  handleChangeOrganisationalGroups = (organisationalGroups) => {
    const newCompany = cloneDeep(this.state.company);

    newCompany.organisationalGroups = organisationalGroups.map(({ id }) => id);

    Log.info(
      'Change form value of organisational groups',
      {
        from: this.state.company.organisationalGroups,
        to: newCompany.organisationalGroups,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change organisational groups',
      Log.FEATURE.COMPANY,
    );

    this.setState({
      company: newCompany,
    });
  };
  setCompanyLogo = (companyLogo) => {
    this.setState({
      companyLogo,
      companyLogoHasBeenUpdated: true,
    });
  };
  refreshCompany = async () => {
    this.setState({
      companyLoading: LOADING_STATE.LOADING,
    });

    const [response, error] = await promiseHandler(
      CompanyService.refreshCompany(this.props.company.id),
    );

    if (error) {
      this.setState({
        companyLoading: LOADING_STATE.FAILED,
      });
      return;
    }

    this.setState({
      companyLoading: LOADING_STATE.SUCCEEDED,
    });
  };

  getPaths() {
    if (this.renderForCreate()) {
      return null;
    }

    return (
      <OrganisationalGroupPaths
        id={this.props.company.id}
        organisationalGroupPaths={this.props.company.organisationalGroupPaths}
        onOpenOrganisationalGroup={(organisationalGroup) =>
          this.props.onOpenOrganisationalGroup(
            organisationalGroup,
            this.getUnsavedChanges(),
          )
        }
      />
    );
  }

  getUnsavedChanges() {
    if (this.renderForCreate()) {
      return [];
    }

    const differentValues = Company.getDifferentValues(
      this.props.company,
      this.state.company,
    );
    if (this.state.companyLogoHasBeenUpdated) {
      differentValues.push('Firmenlogo');
    }

    return differentValues;
  }

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        title={`Firma ${this.renderForCreate() ? 'Erstellen' : this.props.company.name}`}
        fullWidth
        submittingForm={this.state.submittingForm}
        id={this.props.company?.id}
        unsavedChanges={this.getUnsavedChanges()}
        missingPermissionsToSubmit={
          !UserUtils.isVestigasAccount() ||
          (this.renderForCreate()
            ? !UserUtils.isCompanyCreateAllowedUser()
            : !UserUtils.isCompanyWriteAllowedUser())
        }
      >
        <Grid container direction="row">
          {this.props.company?.organisationalGroupPaths?.length > 0 ? (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <div className="mb-20px">{this.getPaths()}</div>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid item xs={12} lg={8}>
            <Grid container direction="row" spacing={3} space={4}>
              <Grid item xs={12}>
                <h3 className="main-text mt-0">{'Firma'}</h3>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      id="name-input"
                      name="name"
                      label={'Name'}
                      type="text"
                      fullWidth
                      required
                      value={this.state.company?.name}
                      onChange={this.handleInputChange}
                      autoFocus
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <h3 className="mt-20px main-text">Details</h3>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      id="street_name-input"
                      name="street_name"
                      label={'Straße'}
                      type="text"
                      fullWidth
                      required
                      value={this.state.company?.address?.streetName}
                      onChange={this.handleInputChange}
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="building_number-input"
                      name="building_number"
                      label={'Hausnummer'}
                      type="text"
                      fullWidth
                      value={this.state.company?.address?.buildingNumber}
                      onChange={this.handleInputChange}
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      id="post_code-input"
                      name="post_code"
                      label={'PLZ'}
                      type="text"
                      fullWidth
                      required
                      value={this.state.company?.address?.postCode}
                      onChange={this.handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="city-input"
                      name="city"
                      label="Ort"
                      type="text"
                      fullWidth
                      required
                      value={this.state.company?.address?.city}
                      onChange={this.handleInputChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputLabel className="text-13px">{'Land'}</InputLabel>
                    <SearchableSelect
                      value={this.state.company?.address?.country}
                      fullWidth
                      onChange={this.handleChangeCountry}
                      size="small"
                      options={Address.getCountryCodeOptions()}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} className="flex-c-c flexdir-column mt-50px">
            <InputLabel id="demo-multiple-name-label" className="text-13px">
              {'Firmenlogo'}
            </InputLabel>
            <ImageUpload
              image={this.state.companyLogo}
              setImage={this.setCompanyLogo}
              onDelete={() => this.setCompanyLogo(null)}
              uploadText={'Füge hier das Firmenlogo hinzu.'}
              missingPermissionsToWrite={!UserUtils.isCompanyWriteAllowedUser()}
            />
          </Grid>
          <Grid item xs={12}>
            <h3 className="mt-20px main-text">{'Ist Teil von...'}</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <ComplexPaginatedEntityMultiPicker
                  entityType={
                    PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY
                  }
                  pickedIds={this.state.company.organisationalGroups}
                  callbackPickedItems={this.handleChangeOrganisationalGroups}
                  onChipClick={(organisationalGroup) =>
                    this.props.onOpenOrganisationalGroup(
                      organisationalGroup,
                      this.getUnsavedChanges(),
                    )
                  }
                  onUpdatedItemsChange={
                    this.props.onUpdatedOrganisationalGroupsChange
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          {this.renderForCreate() ? null : (
            <Grid item xs={12}>
              <div className="h-20px" /* Hacky workaround to add spacing */ />
              <PermissionGrantEntityTable
                title={'Wer ist auf diese Firma berechtigt?'}
                permissionGrantsFrom={this.state.company.permissionGrantsFrom}
                defaultEntities={[this.props.company.id]}
                defaultEntityType={PermissionGrant.ENTITY_TYPE.COMPANY.KEY}
                fixedPicker={PermissionGrant.TYPE.ENTITY}
                refreshData={this.refreshCompany}
                loading={this.state.companyLoading}
              />
            </Grid>
          )}
        </Grid>
      </BasicForm>
    );
  }
}

export default connect(null, mapDispatchToProps())(CompanyForm);
