import React from 'react';

import { Grid, InputLabel, TextField } from '@mui/material';

import Select from '~/components/baseComponents/inputs/select/Select';
import { connect } from 'react-redux';
import { withErrorBoundary } from '~/ui/atoms';
import Log from '~/utils/Log';
import cloneDeep from 'lodash/cloneDeep';
import FunctionUtils from '~/utils/functionUtils';
import Site from '~/models/masterdata/Site';

const mapStateToProps = (state) => ({
  companies: state.companies,
});

class CreateSiteWizardSiteInformation extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.resetDefaultValues();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.companies.companies) !==
      JSON.stringify(prevProps.companies.companies)
    ) {
      this.resetDefaultValues();
    }
  }

  resetDefaultValues() {
    const newSite = cloneDeep(this.props.site);

    newSite.company ||= this.props.userinfo.userinfo.company?.id;

    this.props.setSite(newSite);
  }

  handleInputChange = (event) => {
    const newSite = cloneDeep(this.props.site);

    switch (event.target.name) {
      case 'name': {
        newSite.name = event.target.value;
        Log.info(
          'Change form value of name',
          { from: this.props.site.name, to: newSite.name },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'site_create_wizard_change_name',
          Log.productAnalyticsEvent,
          ['Change name in create site wizard', Log.FEATURE.WIZARD],
        );
        break;
      }

      case 'street_name': {
        newSite.address.streetName = event.target.value;
        Log.info(
          'Change form value of street name',
          {
            from: this.props.site.address.streetName,
            to: newSite.address.streetName,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'site_create_wizard_change_street_name',
          Log.productAnalyticsEvent,
          ['Change street name in create site wizard', Log.FEATURE.WIZARD],
        );
        break;
      }

      case 'building_number': {
        newSite.address.buildingNumber = event.target.value;
        Log.info(
          'Change form value of building number',
          {
            from: this.props.site.address.buildingNumber,
            to: newSite.address.buildingNumber,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'site_create_wizard_change_building_number',
          Log.productAnalyticsEvent,
          ['Change building number in create site wizard', Log.FEATURE.WIZARD],
        );
        break;
      }

      case 'city': {
        newSite.address.city = event.target.value;
        Log.info(
          'Change form value of city',
          { from: this.props.site.address.city, to: newSite.address.city },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'site_create_wizard_change_city',
          Log.productAnalyticsEvent,
          ['Change city in create site wizard', Log.FEATURE.WIZARD],
        );
        break;
      }

      case 'post_code': {
        newSite.address.postCode = event.target.value;
        Log.info(
          'Change form value of post code',
          {
            from: this.props.site.address.postCode,
            to: newSite.address.postCode,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'site_create_wizard_change_post_code',
          Log.productAnalyticsEvent,
          ['Change post code in create site wizard', Log.FEATURE.WIZARD],
        );
        break;
      }

      case 'latitude': {
        newSite.coords.latitude = event.target.value;
        Log.info(
          'Change form value of latitude',
          {
            from: this.props.site.coords.latitude,
            to: newSite.coords.latitude,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'site_create_wizard_change_latitude',
          Log.productAnalyticsEvent,
          ['Change latitude in create site wizard', Log.FEATURE.WIZARD],
        );
        break;
      }

      case 'longitude': {
        newSite.coords.longitude = event.target.value;
        Log.info(
          'Change form value of longitude',
          {
            from: this.props.site.coords.longitude,
            to: newSite.coords.longitude,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'site_create_wizard_change_longitude',
          Log.productAnalyticsEvent,
          ['Change longitude in create site wizard', Log.FEATURE.WIZARD],
        );
        break;
      }
    }

    this.props.setSite(newSite);
  };
  handleChangeCompany = (event) => {
    const newSite = cloneDeep(this.props.site);

    newSite.company = event.target.value;

    Log.info(
      'Change form value of company',
      { from: this.props.site.company, to: newSite.company },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change company in create site wizard',
      Log.FEATURE.WIZARD,
    );

    this.props.setSite(newSite);
  };
  handleChangeSiteType = (event) => {
    const newSite = cloneDeep(this.props.site);

    newSite.type = event.target.value;

    Log.info(
      'Change form value of type',
      { from: this.props.site.type, to: newSite.type },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change type in create site wizard',
      Log.FEATURE.WIZARD,
    );

    this.props.setSite(newSite);
  };

  render() {
    return (
      <Grid container direction="row" spacing={3} space={4}>
        <Grid item xs={12} lg={12}>
          <h3 className="main-text mt-0">Standort Informationen</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <TextField
                id="name-input"
                name="name"
                label="Name"
                type="text"
                required
                fullWidth
                value={this.props.site.name}
                onChange={this.handleInputChange}
                autoFocus
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <TextField
                id="street_name-input"
                name="street_name"
                label="Straße"
                type="text"
                fullWidth
                value={this.props.site.address.streetName}
                onChange={this.handleInputChange}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <TextField
                id="building_number-input"
                name="building_number"
                label="Hausnummer"
                type="text"
                fullWidth
                value={this.props.site.address.buildingNumber}
                onChange={this.handleInputChange}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <TextField
                id="post_code-input"
                name="post_code"
                label="PLZ"
                type="text"
                fullWidth
                value={this.props.site.address.postCode}
                onChange={this.handleInputChange}
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <TextField
                id="city-input"
                name="city"
                label="Ort"
                type="text"
                fullWidth
                value={this.props.site.address.city}
                onChange={this.handleInputChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <TextField
                id="latitude-input"
                name="latitude"
                label="Breitengrad"
                type="text"
                step="any"
                fullWidth
                value={this.props.site.coords.latitude}
                onChange={this.handleInputChange}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <TextField
                id="longitude-input"
                name="longitude"
                label="Längengrad"
                type="text"
                step="any"
                fullWidth
                value={this.props.site.coords.longitude}
                onChange={this.handleInputChange}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <h3 className="mt-20px main-text">
            Welcher Firma soll der Standort zugeordnet sein?
          </h3>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <InputLabel className="text-13px" id="demo-multiple-name-label">
                Firma
              </InputLabel>
              <Select
                value={this.props.site.company}
                fullWidth
                onChange={this.handleChangeCompany}
                size="small"
                options={this.props.companies.companies}
                loading={this.props.companies.companiesLoading}
                errorText="Firmen konnten nicht geladen werden."
                sortOptions
                sortOptionsByKey="name"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <h3 className="mt-20px main-text">
            Welcher Art entspricht dieser Standort?
          </h3>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <InputLabel className="text-13px">Art</InputLabel>
              <Select
                value={this.props.site.type}
                fullWidth
                onChange={this.handleChangeSiteType}
                size="small"
                options={Site.getSiteTypes()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(CreateSiteWizardSiteInformation),
  'Daten konnten nicht geladen werden.',
);
