import { useState } from 'react';

import { Add as AddIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import DeliveriesService from '~/services/deliveries.service';

import { LightTooltip } from '~/utils/componentUtils';

import { Spinner } from '~/components/Spinner';
import { promiseHandler } from '~/utils/promiseHandler';

/**
 * Renders a component for uploading a new delivery note in JSON format.
 */
export const ManualUploadButton = () => {
  const [isUploading, setIsUploading] = useState(false);

  const createDeliveryNote = async (body) => {
    setIsUploading(true);

    await promiseHandler(
      DeliveriesService.createDeliveryNoteVestigasFormat_enhanced(body),
    );

    setIsUploading(false);
  };

  const handleChange = (event) =>
    DeliveriesService.uploadJson(event.target.files[0], createDeliveryNote);

  return (
    <LightTooltip title={'Neue Lieferung im JSON Format hochladen.'}>
      <label htmlFor="contained-button-file">
        <input
          type="file"
          className="hidden"
          accept=".json"
          id="contained-button-file"
          onChange={handleChange}
        />
        <Button
          variant="outlined"
          startIcon={isUploading ? null : <AddIcon />}
          component="span"
          disabled={isUploading}
        >
          {isUploading ? <Spinner title={'Hochladen...'} /> : 'Hochladen'}
        </Button>
      </label>
    </LightTooltip>
  );
};
