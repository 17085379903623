import React from 'react';
import { Grid } from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';

import SupportFaqs from './SupportFaqs';
import { SupportContact } from './SupportContact';

function SupportSettings() {
  return (
    <Grid container spacing="20px" justifyContent="space-between">
      <Grid item xs={12} lg={8}>
        <SupportFaqs />
      </Grid>
      <Grid item xs={12} lg={4}>
        <SupportContact />
      </Grid>
    </Grid>
  );
}

export default withErrorBoundary(
  SupportSettings,
  'Support Seite konnte nicht geladen werden.',
);
