import React from 'react';

import { withErrorBoundary } from '~/ui/atoms';

import { LightTooltip } from '~/utils/componentUtils';

import DeliveryNoteValidationResult from '~/models/deliveries/DeliveryNoteValidationResult';

import { AttentionIcon, DeclinedIcon } from '~/assets/icons';

class DeliveryNoteDataQualityPath extends React.Component {
  getStatusIcon() {
    const className = 'h-20px w-20px absolute r--7px t--7px';

    switch (this.props.validationResult?.status) {
      case DeliveryNoteValidationResult.STATUS.WARNING: {
        return (
          <LightTooltip title={this.props.validationResult.message}>
            <AttentionIcon className={className} />
          </LightTooltip>
        );
      }

      case DeliveryNoteValidationResult.STATUS.ERROR: {
        return (
          <LightTooltip title={this.props.validationResult.message}>
            <DeclinedIcon className={className} />
          </LightTooltip>
        );
      }

      default: {
        // Log.error(null, new EnumValueNotFoundException('Invalid status: ' + this.props.status));
        return null;
      }
    }
  }

  getBorderClassName() {
    switch (this.props.validationResult?.status) {
      case DeliveryNoteValidationResult.STATUS.WARNING: {
        return 'border-warningBase';
      }

      case DeliveryNoteValidationResult.STATUS.ERROR: {
        return 'border-error500';
      }

      default: {
        // Log.error(null, new EnumValueNotFoundException('Invalid status: ' + this.props.status));
        return 'border-grey';
      }
    }
  }

  render() {
    return (
      <div
        className={'rounded-5px p-10px relative ' + this.getBorderClassName()}
      >
        <div className="flex-sb-c">
          <div className="flex-s-c gap-10px">
            {/* <HelpOutlineOutlinedIcon fontSize="small"/> */}
            <div>{this.props.name}</div>
          </div>
          <div className="flex-s-c gap-10px">
            <div>{this.props.value}</div>
            {this.getStatusIcon()}
          </div>
        </div>
        {this.props.description ? (
          <div className="text-error500 mt-10px">{this.props.description}</div>
        ) : null}
      </div>
    );
  }
}

export default withErrorBoundary(
  DeliveryNoteDataQualityPath,
  'Daten konnten nicht geladen werden.',
);
