import React from 'react';
import { connect } from 'react-redux';

import { MASTERDATA } from '~/constants/Masterdata';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import UserService from '~/services/user.service';
import SiteService from '~/services/site.service';
import CostCenterService from '~/services/costCenter.service';
import VehicleService from '~/services/vehicle.service';
import CompanyService from '~/services/company.service';
import OrganisationalGroupService from '~/services/organisationalGroup.service';
import UserGroupService from '~/services/userGroup.service';

import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';
import UserUtils from '~/utils/userUtils';
import ToastService from '~/services/toast.service';

import PermissionGrantDialog from '../masterData/permissionGrant/PermissionGrantDialog';
import { SettingsTableComponent } from './SettingsTableComponent';

import { getButton } from './getButton';
import { getForm } from './getForm';

const mapStateToProps = (state) => ({
  companies: state.companies,
  costCenters: state.costCenters,
  organisationalGroups: state.organisationalGroups,
  sites: state.sites,
  userGroups: state.userGroups,
  users: state.users,
  vehicles: state.vehicles,
});

class SettingsTableClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonGroupFilter: MASTERDATA.IS_ACTIVE.YES,
      company: null,
      costCenter: null,
      excelData: [],
      filteredRows: [],
      formOpen: false,
      formType: 'create',
      freeTextFilter: '',
      item: null,
      organisationalGroup: null,
      rowSelectionModel: [],
      showMultiPermissionGrantEdit: false,
      site: null,
      sortModel: [
        {
          field: this.props.sortBy ?? 'name',
          sort: 'asc',
        },
      ],
      updatedCompanies: [],
      updatedCostCenters: [],
      updatedOrganisationalGroups: [],
      updatedSites: [],
      updatedUserGroups: [],
      updatedUsers: [],
      updatedVehicles: [],
      user: null,
      userGroup: null,
      vehicle: null,
    };
  }

  componentDidMount() {
    this.filterRows();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // It is necessary to refresh the item in the form because otherwise when updating the granted permissions,
    // they would not automatically refresh. However, this approach doesn't scale and must be changed in the future.
    if (
      JSON.stringify(this.props.items) !== JSON.stringify(prevProps.items) &&
      this.state.item
    ) {
      const newItem = this.props.items.find(
        (item) => item.id === this.state.item.id,
      );

      // Only update item, if it hasn't been deleted.
      // Otherwise, this.props.organisationalGroup.id would throw "Cannot access property of undefined" error in OrganisationalGroupForm.
      // Same for user groups.
      if (newItem) {
        this.setState({
          item: newItem,
        });
      }
    }

    // It is necessary to refresh the entities in the form because otherwise when clicking into entity via path or chip click doesn't recognize refresh of entity.
    // As a result, when browsing through forms, paths and permissions wouldn't be displayed.
    // However, this approach doesn't scale and must be changed in the future.
    /* if(this.state.user) {
            // The logic as described above is currently not needed for users and user groups as there the paths and permissions are taken from the GET /all endpoint.
            // Not sure why this is the case. It could lead to issues when the backend doesn't provide this data anymore in the GET /all endpoint.
            const newUser = this.props.users.users.find(user => user.id === this.state.user.id);

            if(JSON.stringify(this.state.user) !== JSON.stringify(newUser)) {
                this.setState({
                    user: newUser
                });
            }
        } */
    if (this.state.site) {
      const newSite = this.props.sites.sites.find(
        (site) => site.id === this.state.site.id,
      );

      if (JSON.stringify(this.state.site) !== JSON.stringify(newSite)) {
        this.setState({
          site: newSite,
        });
      }
    }

    if (this.state.costCenter) {
      const newCostCenter = this.props.costCenters.costCenters.find(
        (costCenter) => costCenter.id === this.state.costCenter.id,
      );

      if (
        JSON.stringify(this.state.costCenter) !== JSON.stringify(newCostCenter)
      ) {
        this.setState({
          costCenter: newCostCenter,
        });
      }
    }

    if (this.state.vehicle) {
      const newVehicle = this.props.vehicles.vehicles.find(
        (vehicle) => vehicle.id === this.state.vehicle.id,
      );

      if (JSON.stringify(this.state.vehicle) !== JSON.stringify(newVehicle)) {
        this.setState({
          vehicle: newVehicle,
        });
      }
    }

    if (this.state.company) {
      const newCompany = this.props.companies.companies.find(
        (company) => company.id === this.state.company.id,
      );

      if (JSON.stringify(this.state.company) !== JSON.stringify(newCompany)) {
        this.setState({
          company: newCompany,
        });
      }
    }

    if (this.state.organisationalGroup) {
      const newOrganisationalGroup =
        this.props.organisationalGroups.organisationalGroups.find(
          (organisationalGroup) =>
            organisationalGroup.id === this.state.organisationalGroup.id,
        );

      if (
        JSON.stringify(this.state.organisationalGroup) !==
        JSON.stringify(newOrganisationalGroup)
      ) {
        this.setState({
          organisationalGroup: newOrganisationalGroup,
        });
      }
    }

    if (
      JSON.stringify(this.props.rows) !== JSON.stringify(prevProps.rows) ||
      this.state.freeTextFilter !== prevState.freeTextFilter ||
      this.state.buttonGroupFilter !== prevState.buttonGroupFilter
    ) {
      this.filterRows();
    }

    // This should also check for changes of JSON.stringify(this.state.filteredRows)
    // but as JSON.stringify is too expensive for large datasets, it is omitted.
    if (
      this.state.rowSelectionModel.length !== prevState.rowSelectionModel.length
    ) {
      this.setState((prevState) => ({
        excelData: prevState.filteredRows.filter((row) =>
          prevState.rowSelectionModel.includes(row.id),
        ),
      }));
    }
  }

  filterRows() {
    const { activeFilter, rows } = this.props;

    const doFilterRows = (buttonGroupFilter, freeTextFilter) => {
      const filteredRows = activeFilter
        ? ArrayUtils.filterByKey(rows, 'active', buttonGroupFilter)
        : rows;

      return freeTextFilter
        ? filteredRows.filter(({ searchString }) =>
            searchString.includes(freeTextFilter.toLowerCase()),
          )
        : filteredRows;
    };

    this.setState((prevState) => ({
      filteredRows: doFilterRows(
        prevState.buttonGroupFilter,
        prevState.freeTextFilter,
      ),
    }));
  }

  onSortModelChange = (event) => {
    Log.productAnalyticsEvent('Sort', Log.FEATURE.SETTINGS_TABLE);

    this.setState({
      sortModel: event,
    });
  };
  onRowSelectionModelChange = (event) => {
    Log.info(
      'Change selection value of ' + this.props.entity,
      { from: this.state.rowSelectionModel, to: event },
      Log.BREADCRUMB.SELECTION_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      '(De)select ' + this.props.entity,
      Log.FEATURE.SETTINGS_TABLE,
    );

    this.setState({
      rowSelectionModel: event,
    });
  };
  onRowClick = (event) => {
    const item = this.props.items.find((item) => item.id === event.id);

    Log.info(
      'Open ' + this.props.entity + ' edit form',
      item,
      Log.BREADCRUMB.FORM_OPEN.KEY,
    );
    Log.productAnalyticsEvent(
      'Open ' + this.props.entity + ' edit form',
      Log.FEATURE.SETTINGS_TABLE,
    );

    this.setState({
      item,
    });

    this.openEditForm();
  };
  onOpenUser = (user, unsavedChanges) => {
    Log.info('Open user edit form', user, Log.BREADCRUMB.FORM_OPEN.KEY);
    Log.productAnalyticsEvent(
      'Open user edit form via chip click',
      Log.FEATURE.SETTINGS_TABLE,
    );

    if (unsavedChanges.length > 0) {
      ToastService.warning([
        'Bitte speichere zunächst deine Änderungen, bevor du das Formular verlässt.',
      ]);
      Log.productAnalyticsEvent(
        'Failed to open user edit form via chip click due to unsaved changes',
        Log.FEATURE.SETTINGS_TABLE,
        Log.TYPE.FAILED_VALIDATION,
      );
      return;
    }

    ToastService.info(['Benutzer ' + user.email + ' geöffnet.']);

    this.setState({
      company: null,
      costCenter: null,
      organisationalGroup: null,
      site: null,
      user,
      userGroup: null,
      vehicle: null,
    });

    this.openEditForm();
  };
  onOpenSite = (site, unsavedChanges) => {
    Log.info('Open site edit form', site, Log.BREADCRUMB.FORM_OPEN.KEY);
    Log.productAnalyticsEvent(
      'Open site edit form via chip click',
      Log.FEATURE.SETTINGS_TABLE,
    );

    if (unsavedChanges.length > 0) {
      ToastService.warning([
        'Bitte speichere zunächst deine Änderungen, bevor du das Formular verlässt.',
      ]);
      Log.productAnalyticsEvent(
        'Failed to open site edit form via chip click due to unsaved changes',
        Log.FEATURE.SETTINGS_TABLE,
        Log.TYPE.FAILED_VALIDATION,
      );
      return;
    }

    ToastService.info(['Standort ' + site.name + ' geöffnet.']);

    this.setState({
      company: null,
      costCenter: null,
      organisationalGroup: null,
      site,
      user: null,
      userGroup: null,
      vehicle: null,
    });

    this.openEditForm();
  };
  onOpenCostCenter = (costCenter, unsavedChanges) => {
    Log.info(
      'Open cost center edit form',
      costCenter,
      Log.BREADCRUMB.FORM_OPEN.KEY,
    );
    Log.productAnalyticsEvent(
      'Open cost center edit form via chip click',
      Log.FEATURE.SETTINGS_TABLE,
    );

    if (unsavedChanges.length > 0) {
      ToastService.warning([
        'Bitte speichere zunächst deine Änderungen, bevor du das Formular verlässt.',
      ]);
      Log.productAnalyticsEvent(
        'Failed to open cost center edit form via chip click due to unsaved changes',
        Log.FEATURE.SETTINGS_TABLE,
        Log.TYPE.FAILED_VALIDATION,
      );
      return;
    }

    ToastService.info(['Kostenstelle ' + costCenter.name + ' geöffnet.']);

    this.setState({
      company: null,
      costCenter,
      organisationalGroup: null,
      site: null,
      user: null,
      userGroup: null,
      vehicle: null,
    });

    this.openEditForm();
  };
  onOpenVehicle = (vehicle, unsavedChanges) => {
    Log.info('Open vehicle edit form', vehicle, Log.BREADCRUMB.FORM_OPEN.KEY);
    Log.productAnalyticsEvent(
      'Open vehicle edit form via chip click',
      Log.FEATURE.SETTINGS_TABLE,
    );

    if (unsavedChanges.length > 0) {
      ToastService.warning([
        'Bitte speichere zunächst deine Änderungen, bevor du das Formular verlässt.',
      ]);
      Log.productAnalyticsEvent(
        'Failed to open vehicle edit form via chip click due to unsaved changes',
        Log.FEATURE.SETTINGS_TABLE,
        Log.TYPE.FAILED_VALIDATION,
      );
      return;
    }

    ToastService.info(['Fahrzeug ' + vehicle.name + ' geöffnet.']);

    this.setState({
      company: null,
      costCenter: null,
      organisationalGroup: null,
      site: null,
      user: null,
      userGroup: null,
      vehicle,
    });

    this.openEditForm();
  };
  onOpenCompany = (company, unsavedChanges) => {
    Log.info('Open company edit form', company, Log.BREADCRUMB.FORM_OPEN.KEY);
    Log.productAnalyticsEvent(
      'Open company edit form via chip click',
      Log.FEATURE.SETTINGS_TABLE,
    );

    if (unsavedChanges.length > 0) {
      ToastService.warning([
        'Bitte speichere zunächst deine Änderungen, bevor du das Formular verlässt.',
      ]);
      Log.productAnalyticsEvent(
        'Failed to open company edit form via chip click due to unsaved changes',
        Log.FEATURE.SETTINGS_TABLE,
        Log.TYPE.FAILED_VALIDATION,
      );
      return;
    }

    ToastService.info(['Firma ' + company.name + ' geöffnet.']);

    this.setState({
      company,
      costCenter: null,
      organisationalGroup: null,
      site: null,
      user: null,
      userGroup: null,
      vehicle: null,
    });

    this.openEditForm();
  };
  onOpenOrganisationalGroup = (organisationalGroup, unsavedChanges) => {
    Log.info(
      'Open organisationalGroup edit form',
      organisationalGroup,
      Log.BREADCRUMB.FORM_OPEN.KEY,
    );
    // Currently in the events it isn't differentiated whether organisational group is opened via path or by clicking on a ship.
    // This differentiation should be implemented at some point.
    Log.productAnalyticsEvent(
      'Open organisational group edit form via path or chip click',
      Log.FEATURE.SETTINGS_TABLE,
    );

    if (unsavedChanges?.length > 0) {
      ToastService.warning([
        'Bitte speichere zunächst deine Änderungen, bevor du das Formular verlässt.',
      ]);
      Log.productAnalyticsEvent(
        'Failed to open organisational group edit form via chip click due to unsaved changes',
        Log.FEATURE.SETTINGS_TABLE,
        Log.TYPE.FAILED_VALIDATION,
      );
      return;
    }

    ToastService.info([
      'Organisations-Gruppe ' + organisationalGroup.name + ' geöffnet.',
    ]);

    this.setState({
      company: null,
      costCenter: null,
      organisationalGroup,
      site: null,
      user: null,
      userGroup: null,
      vehicle: null,
    });

    this.openEditForm();
  };
  onOpenUserGroup = (userGroup, unsavedChanges) => {
    Log.info(
      'Open userGroup edit form',
      userGroup,
      Log.BREADCRUMB.FORM_OPEN.KEY,
    );
    Log.productAnalyticsEvent(
      'Open user group edit form via path or chip click',
      Log.FEATURE.SETTINGS_TABLE,
    );

    if (unsavedChanges.length > 0) {
      ToastService.warning([
        'Bitte speichere zunächst deine Änderungen, bevor du das Formular verlässt.',
      ]);
      Log.productAnalyticsEvent(
        'Failed to open user group edit form via chip click due to unsaved changes',
        Log.FEATURE.SETTINGS_TABLE,
        Log.TYPE.FAILED_VALIDATION,
      );
      return;
    }

    ToastService.info(['Benutzer-Gruppe ' + userGroup.name + ' geöffnet.']);

    this.setState({
      company: null,
      costCenter: null,
      organisationalGroup: null,
      site: null,
      user: null,
      userGroup,
      vehicle: null,
    });

    this.openEditForm();
  };
  closeForm = () => {
    Log.info(
      'Close ' + this.props.entity + ' form',
      null,
      Log.BREADCRUMB.FORM_CLOSE.KEY,
    );

    this.setState({
      company: null,
      costCenter: null,
      formOpen: false,
      organisationalGroup: null,
      site: null,
      user: null,
      userGroup: null,
      vehicle: null,
    });
  };
  openEditForm = () => {
    this.setState({
      formOpen: true,
      formType: 'edit',
    });
  };
  openCreateForm = () => {
    Log.info(
      'Open ' + this.props.entity + ' create form',
      null,
      Log.BREADCRUMB.FORM_OPEN.KEY,
    );
    Log.productAnalyticsEvent(
      'Open ' + this.props.entity + ' create form',
      Log.FEATURE.SETTINGS_TABLE,
    );

    this.setState({
      formOpen: true,
      formType: 'create',
      item: null,
    });
  };
  openMultiPermissionGrantEdit = () => {
    Log.info(
      'Open multi user edit form',
      { rowSelectionModel: this.state.rowSelectionModel },
      Log.BREADCRUMB.FORM_OPEN.KEY,
    );
    Log.productAnalyticsEvent(
      'Open ' + this.props.entity + ' multi permission grant edit form',
      Log.FEATURE.SETTINGS_TABLE,
    );

    this.setState({
      showMultiPermissionGrantEdit: true,
    });
  };
  closeMultiPermissionGrantEdit = () => {
    Log.info(
      'Close multi permission grant form',
      null,
      Log.BREADCRUMB.FORM_CLOSE.KEY,
    );

    this.setState({
      showMultiPermissionGrantEdit: false,
    });
  };
  getButton = () => {
    return getButton(this.props.entity, this.openCreateForm);
  };

  getForm() {
    return getForm({
      closeForm: this.closeForm,
      entity: this.props.entity,
      onOpenCompany: this.onOpenCompany,
      onOpenCostCenter: this.onOpenCostCenter,
      onOpenOrganisationalGroup: this.onOpenOrganisationalGroup,
      onOpenSite: this.onOpenSite,
      onOpenUser: this.onOpenUser,
      onOpenUserGroup: this.onOpenUserGroup,
      onOpenVehicle: this.onOpenVehicle,
      refreshEntities: this.refreshEntities,
      setState: this.setState.bind(this),
      state: this.state,
    });
  }

  getPermissionGrantDialog() {
    if (!UserUtils.isPermissionGrantAllowedUser()) {
      return null;
    }

    return (
      <PermissionGrantDialog
        open={this.state.showMultiPermissionGrantEdit}
        loadData={this.refreshSubjects}
        closeForm={this.closeMultiPermissionGrantEdit}
        defaultSubjects={
          this.props.multiPermissionGrantFixedPicker ===
          PermissionGrant.TYPE.SUBJECT
            ? this.state.rowSelectionModel
            : []
        }
        defaultSubjectType={this.props.multiPermissionGrantDefaultSubjectType}
        defaultEntities={
          this.props.multiPermissionGrantFixedPicker ===
          PermissionGrant.TYPE.ENTITY
            ? this.state.rowSelectionModel
            : []
        }
        defaultEntityType={this.props.multiPermissionGrantDefaultEntityType}
        fixedPicker={this.props.multiPermissionGrantFixedPicker}
      />
    );
  }

  // This refresh of master data is needed to update the granted permissions that were granted via the multi permission grant dialog.
  // However, it is a bit redundant to have this refresh call both in the master data tables and in the master data forms. This could be consolidated somehow.
  refreshSubjects = () => {
    if (this.props.refreshData) {
      this.props.refreshData();
    }
  };
  refreshEntities = () => {
    for (const updatedUser of this.state.updatedUsers)
      UserService.refreshUser(updatedUser.id);
    for (const updatedSite of this.state.updatedSites)
      SiteService.refreshSite(updatedSite.id);
    for (const updatedCostCenter of this.state.updatedCostCenters)
      CostCenterService.refreshCostCenter(updatedCostCenter.id);
    for (const updatedVehicle of this.state.updatedVehicles)
      VehicleService.refreshVehicle(updatedVehicle.id);
    for (const updatedCompany of this.state.updatedCompanies)
      CompanyService.refreshCompany(updatedCompany.id);
    for (const updatedOrganisationalGroup of this.state
      .updatedOrganisationalGroups)
      OrganisationalGroupService.refreshOrganisationalGroup(
        updatedOrganisationalGroup.id,
      );
    for (const updatedUserGroup of this.state.updatedUserGroups)
      UserGroupService.refreshUserGroup(updatedUserGroup.id);
  };

  render() {
    return (
      <SettingsTableComponent
        title={this.props.title}
        entity={this.props.entity}
        activeFilter={this.props.activeFilter}
        columns={this.props.columns}
        loading={this.props.loading}
        localStorageKey={this.props.localStorageKey}
        items={this.props.items}
        rows={this.props.rows}
        buttonGroupFilter={this.state.buttonGroupFilter}
        setButtonGroupFilter={(value) =>
          this.setState({ buttonGroupFilter: value })
        }
        freeTextFilter={this.state.freeTextFilter}
        setFreeTextFilter={(value) => this.setState({ freeTextFilter: value })}
        filteredRows={this.state.filteredRows}
        setFilteredRows={(value) => this.setState({ filteredRows: value })}
        sortModel={this.state.sortModel}
        setSortModel={(value) => this.setState({ sortModel: value })}
        rowSelectionModel={this.state.rowSelectionModel}
        setRowSelectionModel={(value) =>
          this.setState({ rowSelectionModel: value })
        }
        formOpen={this.state.formOpen}
        setFormOpen={(value) => this.setState({ formOpen: value })}
        formType={this.state.formType}
        setFormType={(value) => this.setState({ formType: value })}
        item={this.state.item}
        setItem={(value) => this.setState({ item: value })}
        showMultiPermissionGrantEdit={this.state.showMultiPermissionGrantEdit}
        setShowMultiPermissionGrantEdit={(value) =>
          this.setState({ showMultiPermissionGrantEdit: value })
        }
        getButton={this.getButton}
        getForm={this.getForm.bind(this)}
        refreshSubjects={this.refreshSubjects.bind(this)}
        refreshEntities={this.refreshEntities.bind(this)}
        onRowClick={this.onRowClick.bind(this)}
        onRowSelectionModelChange={this.onRowSelectionModelChange.bind(this)}
        onSortModelChange={this.onSortModelChange.bind(this)}
        onMultiPermissionGrantEdit={
          this.props.multiPermissionGrantEdit &&
          UserUtils.isPermissionGrantAllowedUser()
            ? this.openMultiPermissionGrantEdit.bind(this)
            : null
        }
        getPermissionGrantDialog={this.getPermissionGrantDialog.bind(this)}
      />
    );
  }
}

export const SettingsTable = connect(mapStateToProps)(SettingsTableClass);
