import { BRAND_COLOR } from './constants';

const fontSize = {
  xl: '3vw',
  lg: '2vw',
};

export const DLNNumber = ({ size = 'xl', text }) => (
  <div
    className="flex-0 inline-block whitespace-nowrap rounded-full"
    style={{
      background: 'rgba(0, 0, 0, 0.08)',
      color: BRAND_COLOR,
      fontSize: fontSize[size] ?? '2vw',
      mixBlendMode: 'multiply',
      padding: '.25vw 1.5vw',
      width: 'calc(4ch + 2 * 1.5vw)',
    }}
  >
    {text}
  </div>
);
