import isEqual from 'lodash/isEqual';

const shouldUpdate = (currentProps, prevProps, keys) =>
  keys.some((key) => {
    const currentValue = key
      .split('.')
      .reduce(
        (accumulator, currentKey) => accumulator[currentKey],
        currentProps,
      );
    const previousValue = key
      .split('.')
      .reduce((accumulator, currentKey) => accumulator[currentKey], prevProps);

    return !isEqual(currentValue, previousValue);
  });

export const handleComponentDidUpdate = (props, prevProps, component) => {
  const {
    filterGroups,
    selectedFilterGroup,
    selectedTab,
    setDelivery_selectedFilterGroup,
  } = props;

  if (filterGroups.length !== prevProps.filterGroups.length) {
    component.initSelectedFilterGroup();
  }

  if (selectedFilterGroup !== prevProps.selectedFilterGroup) {
    component.initSelectedTab();
  } else if (
    selectedTab !== prevProps.selectedTab &&
    // Only update the selected filter group if the selected tab is out of sync.
    selectedTab !==
      filterGroups.findIndex(({ id }) => id === selectedFilterGroup)
  ) {
    setDelivery_selectedFilterGroup(filterGroups[selectedTab].id);
    component.handleChangeFilterGroup(filterGroups[selectedTab]);
  }

  if (
    shouldUpdate(props, prevProps, ['dateRange', 'deliveryNotes.deliveryNotes'])
  ) {
    component.initUnassignedDeliveryNotesCount();
  }

  if (
    shouldUpdate(props, prevProps, [
      'customFields',
      'dataVersion',
      'dateRange',
      'oldestFilteredDlnDate',
      'selectedAcceptState',
      'selectedArticle',
      'selectedArticleNumber',
      'selectedCostCenter',
      'selectedCustomFields',
      'selectedFromSite',
      'selectedPermittedCostCenters',
      'selectedPermittedToSites',
      'selectedProcessState',
      'selectedRecipient',
      'selectedSettledStatus',
      'selectedSupplier',
      'selectedToSiteRecipient',
      'selectedToSiteSupplier',
    ])
  ) {
    component.initSelectableFilters();
  }

  if (
    shouldUpdate(props, prevProps, [
      'dateRange',
      'filterGroups',
      'oldestFilteredDlnDate',
    ])
  ) {
    component.initFilterGroups();
  }

  // Reset cached suggestions if the selected sites or cost centers have changed.
  if (
    shouldUpdate(props, prevProps, ['selectedCostCenters', 'selectedSites'])
  ) {
    component.setState({
      cachedSuggestions: {},
    });
  }
};
