import axios from '~/utils/api-client';
import Config from '~/Config';
import Log from '~/utils/Log';
import CacheService from './cache.service';
import OrganisationalGroup from '~/models/masterdata/OrganisationalGroup';
import store from '~/redux/store';
import { LOADING_STATE } from '~/constants/LoadingState';
import {
  replaceOrganisationalGroup,
  replaceOrganisationalGroups,
  setOrganisationalGroupsLoading,
} from '~/redux/organisationalGroupsSlice';
import { promiseHandler } from '~/utils/promiseHandler';
import ArrayUtils from '~/utils/arrayUtils';
import { es6ClassFactory as ES6ClassFactory } from '~/utils/ES6ClassFactory';
import PromiseUtils from '~/utils/promiseUtils';

const API_URL = Config.apiUrl + '/access_control/org_unit';

class OrganisationalGroupService {
  constructor() {
    this.organisationalGroupsLoading = LOADING_STATE.NOT_LOADED;
    this.organisationalGroups = [];
  }

  async getAllOrganisationalGroups() {
    return axios.get(API_URL).then((response) => {
      if (response.status !== 200) {
        return [];
      }

      return response.data.org_unit_list.map(
        (item) => new OrganisationalGroup(item),
      );
    });
  }

  getOrganisationalGroupById = async (organisationalGroupId) => {
    let organisationalGroup = store
      .getState()
      .organisationalGroups?.organisationalGroups?.find(
        (organisationalGroup) =>
          organisationalGroup.id === organisationalGroupId,
      );
    // For more information about why organisational groups are cached locally in OrganisationalGroupService, check the comments in SiteService.getSiteById.
    if (
      !organisationalGroup &&
      store.getState().organisationalGroups?.organisationalGroupsLoading ===
        LOADING_STATE.NOT_LOADED
    ) {
      organisationalGroup = this.organisationalGroups.find(
        (organisationalGroup) =>
          organisationalGroup.id === organisationalGroupId,
      );
    }

    if (organisationalGroup) {
      return ES6ClassFactory.convertToES6Class(
        [organisationalGroup],
        new OrganisationalGroup(),
      )[0];
    }

    if (
      store.getState().organisationalGroups?.organisationalGroupsLoading ===
        LOADING_STATE.SUCCEEDED ||
      store.getState().organisationalGroups?.organisationalGroupsLoading ===
        LOADING_STATE.FAILED
    ) {
      return null;
    }

    if (
      this.organisationalGroupsLoading === LOADING_STATE.SUCCEEDED ||
      this.organisationalGroupsLoading === LOADING_STATE.FAILED
    ) {
      return null;
    }

    store.dispatch(setOrganisationalGroupsLoading(LOADING_STATE.LOADING));
    this.organisationalGroupsLoading = LOADING_STATE.LOADING;

    const [organisationalGroups, error] = await promiseHandler(
      this.getAllOrganisationalGroups(),
    );

    if (error) {
      store.dispatch(setOrganisationalGroupsLoading(LOADING_STATE.FAILED));
      this.organisationalGroupsLoading = LOADING_STATE.FAILED;
      throw error;
    }

    store.dispatch(replaceOrganisationalGroups(organisationalGroups));
    this.organisationalGroupsLoading = LOADING_STATE.SUCCEEDED;
    this.organisationalGroups = organisationalGroups;

    return (
      organisationalGroups.find(
        (organisationalGroup) =>
          organisationalGroup.id === organisationalGroupId,
      ) ?? null
    );
  };
  getOrganisationalGroup = async (organisationalGroupId, ignoreCache) => {
    const url = API_URL + '/' + organisationalGroupId;

    if (!ignoreCache) {
      const [cachedValue, error] = CacheService.getCached(url);
      if (cachedValue) {
        return cachedValue;
      }

      if (error) {
        throw error;
      }
    }

    return axios
      .get(url)
      .then((response) => {
        if (response.status !== 200) {
          Log.warn('GET /access_control/org_unit did not return 200', {
            status: response.status,
          });
        }

        const organisationalGroup = new OrganisationalGroup(
          response.data,
          true,
        );
        CacheService.setCached(url, organisationalGroup);
        return organisationalGroup;
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  };

  // Deprecated: Was used to load organisational groups for the organisational group path
  getOrganisationalGroups(organisationalGroupIds) {
    return axios
      .post(API_URL + '/query/ids', { ids: organisationalGroupIds })
      .then((response) => {
        return (
          response.data.items?.map(
            (organisationalGroup) =>
              new OrganisationalGroup(organisationalGroup),
          ) ?? []
        );
      });
  }

  loadOrganisationalGroups = async () => {
    // to not load organisational groups again when they are already loading or have already been loaded
    if (
      store.getState().organisationalGroups?.organisationalGroupsLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshOrganisationalGroups();
  };
  refreshOrganisationalGroups = async () => {
    store.dispatch(setOrganisationalGroupsLoading(LOADING_STATE.LOADING));

    const [organisationalGroups, error] = await promiseHandler(
      this.getAllOrganisationalGroups(),
    );

    if (error) {
      store.dispatch(setOrganisationalGroupsLoading(LOADING_STATE.FAILED));
      Log.error('Failed to load organisational groups.', error);
      Log.productAnalyticsEvent(
        'Failed to load organisational groups',
        Log.FEATURE.ORGANISATIONAL_GROUP,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceOrganisationalGroups(organisationalGroups));
  };
  refreshOrganisationalGroup = async (organisationalGroupId) => {
    const [organisationalGroup, error] = await promiseHandler(
      this.getOrganisationalGroup(organisationalGroupId, true),
    );

    if (error) {
      Log.error('Failed to load organisationalGroup.', error);
      Log.productAnalyticsEvent(
        'Failed to load organisational group',
        Log.FEATURE.ORGANISATIONAL_GROUP,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceOrganisationalGroup(organisationalGroup));
  };

  async createOrganisationalGroup(body) {
    return axios.post(API_URL, body).then((response) => {
      return response.data?.id;
    });
  }

  async updateOrganisationalGroup(id, body) {
    return axios.put(API_URL + '/' + id, body);
  }

  async deleteOrganisationalGroup(id) {
    return axios.delete(API_URL + '/' + id);
  }

  // Updating the parent organisational groups must be a separate function to updateEntities due to two reasons:
  //  1. The order is inverted. The organisationalGroupId is assigned as the child to another organisational group
  //  2. It is important to add the new organisational groups before deleting the old ones as there can never be en empty list
  async updateParentOrganisationalGroups(
    entityId,
    entityType,
    oldParentOrganisationalGroups = [],
    newParentOrganisationalGroups = [],
  ) {
    const [deletedParentOrganisationalGroups, addedParentOrganisationalGroups] =
      ArrayUtils.getDifference(
        oldParentOrganisationalGroups,
        newParentOrganisationalGroups,
      );

    // It is important to add the new parent organisational units first because otherwise it could happen that after deleting there are none left which is not allowed.
    for (const addedParentOrganisationalGroup of addedParentOrganisationalGroups) {
      const [response, error] = await promiseHandler(
        this.addEntity(addedParentOrganisationalGroup, entityType, entityId),
      );
    }

    for (const deletedParentOrganisationalGroup of deletedParentOrganisationalGroups) {
      const [response, error] = await promiseHandler(
        this.deleteEntity(
          deletedParentOrganisationalGroup,
          entityType,
          entityId,
        ),
      );
    }
  }

  async updateEntities(
    organisationalGroupId,
    entityType,
    oldEntities,
    newEntities,
  ) {
    const [deletedEntities, addedEntities] = ArrayUtils.getDifference(
      oldEntities,
      newEntities,
    );

    const promises = [];

    for (const deletedEntity of deletedEntities) {
      promises.push(
        this.deleteEntity(organisationalGroupId, entityType, deletedEntity),
      );
    }

    for (const addedEntity of addedEntities) {
      promises.push(
        this.addEntity(organisationalGroupId, entityType, addedEntity),
      );
    }

    return PromiseUtils.allResolved(promises);
  }

  async addEntity(organisationalGroupId, entityType, entityId) {
    return axios.post(
      API_URL + '/' + organisationalGroupId + '/' + entityType,
      { entity_id: entityId },
    );
  }

  async deleteEntity(organisationalGroupId, entityType, entityId) {
    return axios.delete(
      API_URL + '/' + organisationalGroupId + '/' + entityType + '/' + entityId,
    );
  }
}

export default new OrganisationalGroupService();
