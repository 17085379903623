import InvoiceCheckCategory from '~/models/invoices/InvoiceCheckCategory';
import InvoiceCheckResult from '~/models/invoices/InvoiceCheckResult';

import {
  AmountApprovedCheckErrorIcon,
  AmountApprovedCheckSuccessIcon,
  AmountCheckErrorIcon,
  AmountCheckSuccessIcon,
  ArticleErrorIcon,
  ArticleExistsCheckErrorIcon,
  ArticleExistsCheckSuccessIcon,
  ArticleSuccessIcon,
  DlnCheckErrorIcon,
  DlnCheckSuccessIcon,
  FormalCheckIcon,
  ListErrorIcon,
  ListSuccessIcon,
  MoneyIcon,
  NoteErrorIcon,
  NoteSuccessIcon,
  ParagraphIcon,
  PriceCheckIcon,
  ScaleBalancedIcon,
  ScaleUnbalancedIcon,
  SignatureCheckIcon,
  SignatureIcon,
} from '~/assets/icons';

import Log from '~/utils/Log';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

export default function InvoiceCheckIcon(props) {
  // Depending on the following inputs, the correct icon is selected:
  // props.iconKey: key of the icon
  // props.status: some icons look differently in case of an error (e.g. dln check)
  // props.withoutBorder: whether the icon should have the round border

  if (props.withoutBorder) {
    switch (props.iconKey) {
      case InvoiceCheckCategory.CATEGORIES.FORMAL_CHECK.KEY: {
        return <ParagraphIcon className={props.className} />;
      }

      case InvoiceCheckCategory.CATEGORIES.DLN_CHECK.KEY: {
        if (
          props.status === InvoiceCheckResult.STATUS.ERROR ||
          props.status === InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE
        ) {
          return <NoteErrorIcon className={props.className} />;
        }

        return <NoteSuccessIcon className={props.className} />;
      }

      case InvoiceCheckCategory.CATEGORIES.SIGNATURE_CHECK.KEY: {
        return <SignatureIcon className={props.className} />;
      }

      case InvoiceCheckCategory.CATEGORIES.ARTICLE_EXISTS_CHECK.KEY: {
        if (props.status === InvoiceCheckResult.STATUS.ERROR) {
          return <ArticleErrorIcon className={props.className} />;
        }

        return <ArticleSuccessIcon className={props.className} />;
      }

      case InvoiceCheckCategory.CATEGORIES.AMOUNT_CHECK.KEY: {
        if (props.status === InvoiceCheckResult.STATUS.ERROR) {
          return <ScaleUnbalancedIcon className={props.className} />;
        }

        return <ScaleBalancedIcon className={props.className} />;
      }

      case InvoiceCheckCategory.CATEGORIES.AMOUNT_APPROVED_CHECK.KEY: {
        if (props.status === InvoiceCheckResult.STATUS.ERROR) {
          return <ListErrorIcon className={props.className} />;
        }

        return <ListSuccessIcon className={props.className} />;
      }

      case InvoiceCheckCategory.CATEGORIES.PRICE_CHECK.KEY: {
        return <MoneyIcon className={props.className} />;
      }

      default: {
        Log.error(
          null,
          new EnumValueNotFoundException('Invalid icon key: ' + props.key),
        );
        return null;
      }
    }
  } else {
    switch (props.iconKey) {
      case InvoiceCheckCategory.CATEGORIES.FORMAL_CHECK.KEY: {
        return <FormalCheckIcon className={props.className} />;
      }

      case InvoiceCheckCategory.CATEGORIES.DLN_CHECK.KEY: {
        // If the invoice status is "no checking possible", it is currently always the case that there was an error in the dln check.
        // Hence, the error icon for the dln check should be displayed.
        if (
          props.status === InvoiceCheckResult.STATUS.ERROR ||
          props.status === InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE
        ) {
          return <DlnCheckErrorIcon className={props.className} />;
        }

        return <DlnCheckSuccessIcon className={props.className} />;
      }

      case InvoiceCheckCategory.CATEGORIES.SIGNATURE_CHECK.KEY: {
        return <SignatureCheckIcon className={props.className} />;
      }

      case InvoiceCheckCategory.CATEGORIES.ARTICLE_EXISTS_CHECK.KEY: {
        if (props.status === InvoiceCheckResult.STATUS.ERROR) {
          return <ArticleExistsCheckErrorIcon className={props.className} />;
        }

        return <ArticleExistsCheckSuccessIcon className={props.className} />;
      }

      case InvoiceCheckCategory.CATEGORIES.AMOUNT_CHECK.KEY: {
        if (props.status === InvoiceCheckResult.STATUS.ERROR) {
          return <AmountCheckErrorIcon className={props.className} />;
        }

        return <AmountCheckSuccessIcon className={props.className} />;
      }

      case InvoiceCheckCategory.CATEGORIES.AMOUNT_APPROVED_CHECK.KEY: {
        if (props.status === InvoiceCheckResult.STATUS.ERROR) {
          return <AmountApprovedCheckErrorIcon className={props.className} />;
        }

        return <AmountApprovedCheckSuccessIcon className={props.className} />;
      }

      case InvoiceCheckCategory.CATEGORIES.PRICE_CHECK.KEY: {
        return <PriceCheckIcon className={props.className} />;
      }

      default: {
        Log.error(
          null,
          new EnumValueNotFoundException('Invalid icon key: ' + props.iconKey),
        );
        return null;
      }
    }
  }
}
