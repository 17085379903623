import React from 'react';

import { Add as AddIcon } from '@mui/icons-material';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  IconButton,
} from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import Log from '~/utils/Log';
import PromiseUtils from '~/utils/promiseUtils';
import CreateSiteWizardPermissionGrantPicker from './CreateSiteWizardPermissionGrantPicker';

class CreateSiteWizardPermissions extends React.Component {
  constructor() {
    super();

    this.state = {
      permissionGrantPickerList: [],
    };

    this.permissionGrantChilds = [];
  }

  componentDidMount() {
    this.addNewPermissionGrantPicker();
  }

  handleGrantPermissionsRadioChange = (event) => {
    Log.info(
      'Change form value of grant permissions radio button',
      { from: this.props.grantPermissions, to: event.target.value },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change grant permissions radio button in create site wizard',
      Log.FEATURE.WIZARD,
    );
    this.props.setGrantPermissions(
      event.target.value === 'grant_permissions_yes',
    );
  };
  handleGrantPermissionsOnCostCentersRadioChange = (event) => {
    Log.info(
      'Change form value of grant permissions on cost centers radio button',
      {
        from: this.props.grantPermissionsOnCostCenters,
        to: event.target.value,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change grant permissions on cost centers radio button in create site wizard',
      Log.FEATURE.WIZARD,
    );
    this.props.setGrantPermissionsOnCostCenters(
      event.target.value === 'cost_centers_yes',
    );
  };
  addNewPermissionGrantPicker = () => {
    this.permissionGrantChilds.push(React.createRef());

    const newPermissionGrantPickerList = [
      ...this.state.permissionGrantPickerList,
    ];
    newPermissionGrantPickerList.push({
      ref: this.permissionGrantChilds[
        this.state.permissionGrantPickerList.length
      ],
      key: this.state.permissionGrantPickerList.length,
    });
    /* newPermissionGrantPickerList.push(
            <CreateSiteWizardPermissionGrantPicker
                ref={this.permissionGrantChilds[this.state.permissionGrantPickerList.length]}
                key={this.state.permissionGrantPickerList.length}
            />
        ); */

    Log.info(
      'Add permission grant picker',
      { values: newPermissionGrantPickerList },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );

    // Initially, a picker is added automatically. We don't want to incorrectly track this as user behavior.
    if (this.state.permissionGrantPickerList.length > 0) {
      Log.productAnalyticsEvent('Add picker', Log.FEATURE.PERMISSIONS);
    }

    this.setState({
      permissionGrantPickerList: newPermissionGrantPickerList,
    });
  };

  async submit(userId) {
    const promises = [];

    for (const child of this.permissionGrantChilds) {
      const promise = child.current.submit(userId);
      promises.push(promise);
    }

    return PromiseUtils.allResolved(promises);
  }

  async submitCostCenters(costCenterIds) {
    const promises = [];

    for (const child of this.permissionGrantChilds) {
      const promise = child.current.submitCostCenters(costCenterIds);
      promises.push(promise);
    }

    return PromiseUtils.allResolved(promises);
  }

  render() {
    return (
      <Grid container direction="row" spacing={3} space={4}>
        <Grid item xs={12} lg={12}>
          <h3 className="main-text mt-0">
            Sollen Benutzer Berechtigungen auf den Standort erhalten? (kann
            jederzeit geändert werden)
          </h3>
          <RadioGroup
            onChange={this.handleGrantPermissionsRadioChange}
            value={
              this.props.grantPermissions
                ? 'grant_permissions_yes'
                : 'grant_permissions_no'
            }
            row
          >
            <FormControlLabel
              value="grant_permissions_yes"
              control={<Radio />}
              label="Ja (empfohlen)"
              className="mr-50px"
            />
            <FormControlLabel
              value="grant_permissions_no"
              control={<Radio />}
              label="Nein"
            />
          </RadioGroup>
        </Grid>
        {this.props.grantPermissions ? (
          <>
            {this.props.site.costCenters.length > 0 ? (
              <Grid item xs={12} lg={12}>
                <h3 className="mt-20px main-text">
                  Sollen die Berechtigungen auch auf die mit dem Standort
                  verbundenen Kostenstellen vergeben werden?
                </h3>
                <RadioGroup
                  onChange={this.handleGrantPermissionsOnCostCentersRadioChange}
                  value={
                    this.props.grantPermissionsOnCostCenters
                      ? 'cost_centers_yes'
                      : 'cost_centers_no'
                  }
                  row
                >
                  <FormControlLabel
                    value="cost_centers_yes"
                    control={<Radio />}
                    label="Ja (empfohlen)"
                    className="mr-50px"
                  />
                  <FormControlLabel
                    value="cost_centers_no"
                    control={<Radio />}
                    label="Nein"
                  />
                </RadioGroup>
              </Grid>
            ) : null}
            <Grid item xs={12} lg={12}>
              {this.state.permissionGrantPickerList.map(
                (permissionGrantPicker) => (
                  <CreateSiteWizardPermissionGrantPicker
                    grantPermissionsOnCostCenters={
                      this.props.grantPermissionsOnCostCenters
                    }
                    ref={permissionGrantPicker.ref}
                    key={permissionGrantPicker.key}
                  />
                ),
              )}
              <div className="flex-c-c w-full">
                <IconButton
                  className="m-auto"
                  onClick={this.addNewPermissionGrantPicker}
                  size="large"
                >
                  <AddIcon />
                </IconButton>
              </div>
            </Grid>
          </>
        ) : null}
      </Grid>
    );
  }
}

export default withErrorBoundary(
  CreateSiteWizardPermissions,
  'Daten konnten nicht geladen werden.',
);
