import React from 'react';
import InvoiceCheckCategory from '~/models/invoices/InvoiceCheckCategory';
import InvoiceCheckResult from '~/models/invoices/InvoiceCheckResult';
import ArrayUtils from '~/utils/arrayUtils';
import InvoiceCheckListTooltip from '../invoiceCheckTooltips/InvoiceCheckListTooltip';
import InvoiceCheckTableTooltip from '../invoiceCheckTooltips/InvoiceCheckTableTooltip';
import { pluralizeString, pluralizeWord } from '~/utils/pluralize';

export default class InvoiceCheckSummaryText extends React.Component {
  getText() {
    switch (this.props.category.key) {
      case InvoiceCheckCategory.CATEGORIES.FORMAL_CHECK.KEY: {
        if (
          this.props.category.status === InvoiceCheckResult.STATUS.MANUAL ||
          this.props.category.status ===
            InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE
        ) {
          return 'Manuelle formale Prüfung erforderlich.';
        }

        if (this.props.withTooltip) {
          return this.getFormalCheckTextTooltip();
        }

        return this.getFormalCheckText();
      }

      case InvoiceCheckCategory.CATEGORIES.DLN_CHECK.KEY: {
        // In case of the dln check, the descriptive error text should be displayed if the invoice status is "no checking possible"
        // because the dln check includes the explanation why further checking wasn't possible.
        if (this.props.category.status === InvoiceCheckResult.STATUS.MANUAL) {
          return 'Manuelle Prüfung erforderlich, ob Lieferungen vorliegen.';
        }

        if (this.props.withTooltip) {
          return this.getDlnCheckTextTooltip();
        }

        return this.getDlnCheckText();
      }

      case InvoiceCheckCategory.CATEGORIES.SIGNATURE_CHECK.KEY: {
        // In case of the other checks, "Manuelle Prüfung erforderlich" should be displayed in case of the status "no checking possible".
        if (
          this.props.category.status === InvoiceCheckResult.STATUS.MANUAL ||
          this.props.category.status ===
            InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE
        ) {
          return 'Manuelle Prüfung erforderlich, ob Lieferungen signiert wurden.';
        }

        if (this.props.withTooltip) {
          return this.getSignatureCheckTextTooltip();
        }

        return this.getSignatureCheckText();
      }

      case InvoiceCheckCategory.CATEGORIES.ARTICLE_EXISTS_CHECK.KEY: {
        if (
          this.props.category.status === InvoiceCheckResult.STATUS.MANUAL ||
          this.props.category.status ===
            InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE
        ) {
          return 'Manuelle Prüfung erforderlich, ob Artikel auf Lieferungen vorhanden sind.';
        }

        if (this.props.withTooltip) {
          return this.getArticleExistsCheckTextTooltip();
        }

        return this.getArticleExistsCheckText();
      }

      case InvoiceCheckCategory.CATEGORIES.AMOUNT_CHECK.KEY: {
        if (
          this.props.category.status === InvoiceCheckResult.STATUS.MANUAL ||
          this.props.category.status ===
            InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE
        ) {
          return 'Manuelle Prüfung erforderlich, ob Mengen mit Lieferungen übereinstimmen.';
        }

        if (this.props.withTooltip) {
          return this.getAmountCheckTextTooltip();
        }

        return this.getAmountCheckText();
      }

      case InvoiceCheckCategory.CATEGORIES.AMOUNT_APPROVED_CHECK.KEY: {
        if (
          this.props.category.status === InvoiceCheckResult.STATUS.MANUAL ||
          this.props.category.status ===
            InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE
        ) {
          return 'Manuelle Prüfung erforderlich, ob Reklamationen vorliegen.';
        }

        if (this.props.withTooltip) {
          return this.getAmountApprovedCheckTextTooltip();
        }

        return this.getAmountApprovedCheckText();
      }

      case InvoiceCheckCategory.CATEGORIES.PRICE_CHECK.KEY: {
        if (
          this.props.category.status === InvoiceCheckResult.STATUS.MANUAL ||
          this.props.category.status ===
            InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE
        ) {
          return 'Manuelle Preisprüfung erforderlich.';
        }

        return null;
      }
    }
  }

  getFormalCheckText() {
    if (this.props.category.status === InvoiceCheckResult.STATUS.SUCCESS) {
      return 'Die Formale Rechnungsprüfung wurde erfolgreich bestanden.';
    }

    return 'Die formale Rechnungsprüfung ergab Fehler.';
  }

  getFormalCheckTextTooltip() {
    if (this.props.category.status === InvoiceCheckResult.STATUS.SUCCESS) {
      return this.getFormalCheckText();
    }

    return (
      <>
        Die formale Rechnungsprüfung ergab{' '}
        <span className="text-error500 bold">Fehler.</span>
      </>
    );
  }

  getDlnCheckText() {
    const deliveryNoteExistsSuccessCount =
      this.props.category.successChecks.filter(
        (successCheck) => successCheck.name === 'DeliveryNoteExists',
      ).length;
    const deliveryNoteExistsErrorCount = this.props.category.errorChecks.filter(
      (errorCheck) => errorCheck.name === 'DeliveryNoteExists',
    ).length;
    const deliveryNoteNotAlreadySettledErrorCount =
      this.props.category.errorChecks.filter(
        (errorCheck) => errorCheck.name === 'DeliveryNoteNotAlreadySettled',
      ).length;
    const deliveryNoteItemAlreadyBilledErrorCount =
      this.props.category.errorChecks.filter(
        (errorCheck) => errorCheck.name === 'DeliveryNoteItemAlreadyBilled',
      ).length;
    const deliveryNoteReferencedExistsErrorCount =
      this.props.category.errorChecks.filter(
        (errorCheck) => errorCheck.name === 'DeliveryNoteReferenceExists',
      ).length;

    if (this.props.category.status === InvoiceCheckResult.STATUS.SUCCESS) {
      return (
        pluralizeString(
          deliveryNoteExistsSuccessCount,
          'Lieferung liegt',
          true,
        ) +
        ' vor und ' +
        pluralizeWord(deliveryNoteExistsSuccessCount, 'wurde') +
        ' noch nicht abgerechnet.'
      );
    }

    const errorText = [];

    if (deliveryNoteExistsErrorCount > 0) {
      errorText.push(
        pluralizeString(deliveryNoteExistsErrorCount, 'Lieferung liegt', true) +
          ' nicht vor.',
      );
    }

    if (deliveryNoteNotAlreadySettledErrorCount > 0) {
      errorText.push(
        pluralizeString(
          deliveryNoteNotAlreadySettledErrorCount,
          'Lieferung wurde',
          true,
        ) + ' bereits abgerechnet.',
      );
    }

    if (deliveryNoteItemAlreadyBilledErrorCount > 0) {
      errorText.push(
        pluralizeString(
          deliveryNoteItemAlreadyBilledErrorCount,
          'Artikel wurde',
          true,
        ) + ' bereits abgerechnet.',
      );
    }

    if (deliveryNoteReferencedExistsErrorCount > 0) {
      errorText.push(
        pluralizeString(
          deliveryNoteReferencedExistsErrorCount,
          'Artikel enthält',
          true,
        ) + ' keine Referenz auf eine Lieferung.',
      );
    }

    return errorText.join(' ');
  }

  getDlnCheckTextTooltip() {
    // In case of the invoice status being "no checking possible", the text should be highlighted in grey instead of red.
    const textClasses =
      'bold ' +
      InvoiceCheckResult.getStatusColorClass(this.props.category.status);

    const deliveryNoteExistsErrorCount = this.props.category.errorChecks.filter(
      (errorCheck) => errorCheck.name === 'DeliveryNoteExists',
    ).length;
    const deliveryNoteNotAlreadySettledErrorCount =
      this.props.category.errorChecks.filter(
        (errorCheck) => errorCheck.name === 'DeliveryNoteNotAlreadySettled',
      ).length;
    const deliveryNoteItemAlreadyBilledErrorCount =
      this.props.category.errorChecks.filter(
        (errorCheck) => errorCheck.name === 'DeliveryNoteItemAlreadyBilled',
      ).length;
    const deliveryNoteReferencedExistsErrorCount =
      this.props.category.errorChecks.filter(
        (errorCheck) => errorCheck.name === 'DeliveryNoteReferenceExists',
      ).length;

    if (this.props.category.status === InvoiceCheckResult.STATUS.SUCCESS) {
      return this.getDlnCheckText();
    }

    const errorText = [];

    if (deliveryNoteExistsErrorCount > 0) {
      errorText.push(
        <>
          <InvoiceCheckListTooltip
            title="Nicht vorliegende Lieferungen"
            items={this.props.category.errorChecks.map(
              (errorCheck) => errorCheck.deliveryNotes[0]?.number ?? '',
            )}
          >
            <span className={textClasses}>
              {pluralizeWord(deliveryNoteExistsErrorCount, 'Lieferung', true)}
            </span>
          </InvoiceCheckListTooltip>
          {' ' +
            pluralizeWord(deliveryNoteExistsErrorCount, 'liegt') +
            ' nicht vor.'}
        </>,
      );
    }

    if (deliveryNoteNotAlreadySettledErrorCount > 0) {
      errorText.push(
        <>
          <InvoiceCheckListTooltip
            title="Bereits abgerechnete Lieferungen"
            items={this.props.category.errorChecks.map(
              (errorCheck) => errorCheck.deliveryNotes[0]?.number ?? '',
            )}
          >
            <span className={textClasses}>
              {pluralizeWord(
                deliveryNoteNotAlreadySettledErrorCount,
                'Lieferung',
                true,
              )}
            </span>
          </InvoiceCheckListTooltip>
          {' ' +
            pluralizeWord(deliveryNoteNotAlreadySettledErrorCount, 'wurde') +
            ' bereits abgerechnet.'}
        </>,
      );
    }

    if (deliveryNoteItemAlreadyBilledErrorCount > 0) {
      errorText.push(
        <>
          <InvoiceCheckListTooltip
            title="Bereits abgerechnete Artikel"
            items={this.props.category.errorChecks.map(
              (errorCheck) => errorCheck.articleName ?? '',
            )}
          >
            <span className={textClasses}>
              {pluralizeWord(
                deliveryNoteItemAlreadyBilledErrorCount,
                'Artikel',
                true,
              )}
            </span>
          </InvoiceCheckListTooltip>
          {' ' +
            pluralizeWord(deliveryNoteItemAlreadyBilledErrorCount, 'wurde') +
            ' bereits abgerechnet.'}
        </>,
      );
    }

    if (deliveryNoteReferencedExistsErrorCount > 0) {
      errorText.push(
        <>
          <InvoiceCheckListTooltip
            title="Artikel ohne Referenz auf eine Lieferung"
            items={this.props.category.errorChecks.map(
              (errorCheck) => errorCheck.articleName ?? '',
            )}
          >
            <span className={textClasses}>
              {pluralizeWord(
                deliveryNoteReferencedExistsErrorCount,
                'Artikel',
                true,
              )}
            </span>
          </InvoiceCheckListTooltip>
          {' ' +
            pluralizeWord(deliveryNoteReferencedExistsErrorCount, 'enthält') +
            ' keine Referenz auf eine Lieferung.'}
        </>,
      );
    }

    // Special case needed to add space between the two sentences.
    if (errorText.length === 2) {
      return (
        <>
          {errorText[0]} {errorText[1]}
        </>
      );
    }

    return errorText[0];
  }

  // Attention: Little bug will occur here when some dlns have been delayed signed and some haven't been signed at all.
  // In this case, the delayed signed dlns are displayed as not signed at all because the overall status of the category is still InvoiceCheckResult.STATUS.ERROR.
  getSignatureCheckText() {
    if (this.props.category.status === InvoiceCheckResult.STATUS.SUCCESS) {
      return (
        pluralizeString(
          this.props.category.successChecks.length,
          'Lieferung wurde',
          true,
        ) + ' signiert.'
      );
    }

    if (
      this.props.category.status === InvoiceCheckResult.STATUS.DELAYED_SUCCESS
    ) {
      return (
        pluralizeString(
          this.props.category.delayedSuccessChecks.length,
          'Lieferung wurde',
          true,
        ) + ' nachträglich signiert.'
      );
    }

    return (
      pluralizeString(
        this.props.category.errorChecks.length,
        'Lieferung wurde',
        true,
      ) + ' nicht signiert.'
    );
  }

  getSignatureCheckTextTooltip() {
    if (this.props.category.status === InvoiceCheckResult.STATUS.SUCCESS) {
      return this.getSignatureCheckText();
    }

    if (
      this.props.category.status === InvoiceCheckResult.STATUS.DELAYED_SUCCESS
    ) {
      return this.getSignatureCheckText();
    }

    return (
      <>
        <InvoiceCheckListTooltip
          title="Nicht signierte Lieferungen"
          items={this.props.category.errorChecks.map(
            (errorCheck) => errorCheck.deliveryNotes[0]?.number ?? '',
          )}
        >
          <span className="text-warningBase bold">
            {pluralizeWord(
              this.props.category.errorChecks.length,
              'Lieferung',
              true,
            )}
          </span>
        </InvoiceCheckListTooltip>
        {' ' +
          pluralizeWord(this.props.category.errorChecks.length, 'wurde') +
          ' nicht signiert.'}
      </>
    );
  }

  // Only display the distinct amount of articles because in some invoices it can happen that the error is thrown for the same article many times in different delivery notes.
  // By only displaying the distinct amount, a better aggregation of errors and thus a better understanding for the user should be achieved.
  getArticleExistsCheckText() {
    if (this.props.category.status === InvoiceCheckResult.STATUS.SUCCESS) {
      const articles = ArrayUtils.removeDuplicates(
        this.props.category.successChecks.map(
          (errorCheck) => errorCheck.articleName ?? '',
        ),
      );
      return (
        articles.length +
        ' Artikel ' +
        pluralizeWord(articles.length, 'konnte') +
        ' in den Lieferungen gefunden werden.'
      );
    }

    const articles = ArrayUtils.removeDuplicates(
      this.props.category.errorChecks.map(
        (errorCheck) => errorCheck.articleName ?? '',
      ),
    );
    const deliveryNotes = ArrayUtils.removeDuplicates(
      InvoiceCheckResult.getDeliveryNoteNumbers(
        this.props.category.errorChecks,
      ),
    );

    return (
      'Es gibt ' +
      articles.length +
      ' Artikel in der Rechnung, ' +
      pluralizeWord(articles.length, 'der') +
      ' in ' +
      pluralizeWord(deliveryNotes.length, 'Lieferung', true) +
      ' nicht gefunden werden ' +
      pluralizeWord(articles.length, 'konnte') +
      '.'
    );
  }

  getArticleExistsCheckTextTooltip() {
    if (this.props.category.status === InvoiceCheckResult.STATUS.SUCCESS) {
      return this.getArticleExistsCheckText();
    }

    const articles = ArrayUtils.removeDuplicates(
      this.props.category.errorChecks.map(
        (errorCheck) => errorCheck.articleName ?? '',
      ),
    );
    const deliveryNotes = ArrayUtils.removeDuplicates(
      InvoiceCheckResult.getDeliveryNoteNumbers(
        this.props.category.errorChecks,
      ),
    );

    if (articles.length === this.props.category.errorChecks.length) {
      return (
        <>
          {'Es gibt '}
          <InvoiceCheckListTooltip
            title="Nicht gefundene Artikel"
            items={articles}
          >
            <span className="text-error500 bold">
              {articles.length} Artikel
            </span>
          </InvoiceCheckListTooltip>
          {' in der Rechnung, ' +
            pluralizeWord(articles.length, 'der') +
            ' in '}
          <InvoiceCheckListTooltip
            title="In den Lieferungen"
            items={deliveryNotes}
          >
            <span className="text-error500 bold">
              {pluralizeWord(deliveryNotes.length, 'Lieferung', true)}
            </span>
          </InvoiceCheckListTooltip>
          {' nicht gefunden werden ' +
            pluralizeWord(articles.length, 'konnte') +
            '.'}
        </>
      );
    }

    const tooltipHeader = ['Artikel', 'fehlt in'];
    const tooltipRows = ArrayUtils.getDistinctValuesByKey(
      this.props.category.errorChecks,
      'articleName',
      '',
    ).map((articleName) => {
      const errorChecks = this.props.category.errorChecks.filter(
        (checkResult) => checkResult.articleName === articleName,
      );

      return [
        articleName,
        pluralizeWord(
          InvoiceCheckResult.getDeliveryNoteNumbers(errorChecks).length,
          'Lieferung',
          true,
        ),
      ];
    });

    return (
      <>
        {'Es gibt '}
        <InvoiceCheckTableTooltip
          title="Nicht gefundene Artikel"
          header={tooltipHeader}
          rows={tooltipRows}
        >
          <span className="text-error500 bold">{articles.length} Artikel</span>
        </InvoiceCheckTableTooltip>
        {' in der Rechnung, ' + pluralizeWord(articles.length, 'der') + ' in '}
        <InvoiceCheckListTooltip
          title="In den Lieferungen"
          items={deliveryNotes}
        >
          <span className="text-error500 bold">
            {pluralizeWord(deliveryNotes.length, 'Lieferung', true)}
          </span>
        </InvoiceCheckListTooltip>
        {' nicht gefunden werden ' +
          pluralizeWord(articles.length, 'konnte') +
          '.'}
      </>
    );
  }

  // Articles are not listed distinctly because if amounts differ, this must be checked manually for each article in each delivery note.
  // It is important that no wrong article amount isn't checked manually.
  // Additionally, displaying only the distinct amount of wrong articles isn't that significant because this error doesn't occur that often (e.g. compared to DeliveryNoteMaterialMatches).
  getAmountCheckText() {
    if (this.props.category.status === InvoiceCheckResult.STATUS.SUCCESS) {
      const articles = ArrayUtils.removeDuplicates(
        this.props.category.successChecks.map(
          (errorCheck) => errorCheck.articleName ?? '',
        ),
      );
      const deliveryNotes = ArrayUtils.removeDuplicates(
        InvoiceCheckResult.getDeliveryNoteNumbers(
          this.props.category.successChecks,
        ),
      );
      return (
        'Die Menge zwischen Rechnung und Lieferung stimmt für ' +
        articles.length +
        ' Artikel in ' +
        pluralizeWord(deliveryNotes.length, 'Lieferung', true) +
        ' überein.'
      );
    }

    const articles = ArrayUtils.removeDuplicates(
      this.props.category.errorChecks.map(
        (errorCheck) => errorCheck.articleName ?? '',
      ),
    );
    const deliveryNotes = ArrayUtils.removeDuplicates(
      InvoiceCheckResult.getDeliveryNoteNumbers(
        this.props.category.errorChecks,
      ),
    );

    return (
      'Die Menge zwischen Rechnung und Lieferung stimmt für ' +
      articles.length +
      ' Artikel in ' +
      pluralizeWord(deliveryNotes.length, 'Lieferung', true) +
      ' nicht überein.'
    );
  }

  getAmountCheckTextTooltip() {
    if (this.props.category.status === InvoiceCheckResult.STATUS.SUCCESS) {
      return this.getAmountCheckText();
    }

    const tooltipHeader = ['Artikel', 'in Lieferung', 'Lieferung', 'Rechnung'];
    const tooltipRows = this.props.category.errorChecks.map((errorCheck) => [
      errorCheck.articleName ?? '',
      errorCheck.getDeliveryNoteNumbers().join(', '),
      errorCheck.expectedValue ?? '-',
      errorCheck.invoiceValue ?? '-',
    ]);

    const articles = ArrayUtils.removeDuplicates(
      this.props.category.errorChecks.map(
        (errorCheck) => errorCheck.articleName ?? '',
      ),
    );
    const deliveryNotes = ArrayUtils.removeDuplicates(
      InvoiceCheckResult.getDeliveryNoteNumbers(
        this.props.category.errorChecks,
      ),
    );

    return (
      <>
        {'Die Menge zwischen Rechnung und Lieferung stimmt für '}
        <InvoiceCheckTableTooltip
          title="Nicht übereinstimmende Mengen bei den Artikeln"
          header={tooltipHeader}
          rows={tooltipRows}
        >
          <span className="text-error500 bold">{articles.length} Artikel</span>
          {' in '}
          <span className="text-error500 bold">
            {pluralizeWord(deliveryNotes.length, 'Lieferung', true)}
          </span>
        </InvoiceCheckTableTooltip>
        {' nicht überein.'}
      </>
    );
  }

  // Articles are not listed distinctly because if articles are declined, this must be checked manually for each article in each delivery note.
  // It is important that no wrong article amount isn't checked manually.
  // Additionally, displaying only the distinct amount of wrong articles isn't that significant because this error doesn't occur that often (e.g. compared to DeliveryNoteMaterialMatches).
  getAmountApprovedCheckText() {
    if (this.props.category.status === InvoiceCheckResult.STATUS.SUCCESS) {
      return 'Es gab keine Reklamationen.';
    }

    const articles = ArrayUtils.removeDuplicates(
      this.props.category.errorChecks.map(
        (errorCheck) => errorCheck.articleName ?? '',
      ),
    );
    const deliveryNotes = ArrayUtils.removeDuplicates(
      InvoiceCheckResult.getDeliveryNoteNumbers(
        this.props.category.errorChecks,
      ),
    );

    return (
      articles.length +
      ' Artikel ' +
      pluralizeWord(articles.length, 'wurde') +
      ' in ' +
      pluralizeWord(deliveryNotes.length, 'Lieferung', true) +
      ' reklamiert oder die Menge wurde nicht komplett bestätigt.'
    );
  }

  getAmountApprovedCheckTextTooltip() {
    if (this.props.category.status === InvoiceCheckResult.STATUS.SUCCESS) {
      return this.getAmountApprovedCheckText();
    }

    const tooltipHeader = ['Artikel', 'in Lieferung'];
    const tooltipRows = this.props.category.errorChecks.map((errorCheck) => [
      errorCheck.articleName ?? '',
      errorCheck.getDeliveryNoteNumbers().join(', '),
    ]);

    const articles = ArrayUtils.removeDuplicates(
      this.props.category.errorChecks.map(
        (errorCheck) => errorCheck.articleName ?? '',
      ),
    );
    const deliveryNotes = ArrayUtils.removeDuplicates(
      InvoiceCheckResult.getDeliveryNoteNumbers(
        this.props.category.errorChecks,
      ),
    );

    return (
      <>
        <InvoiceCheckTableTooltip
          title="Reklamierte oder nicht bestätigte Artikel"
          header={tooltipHeader}
          rows={tooltipRows}
        >
          <span className="text-error500 bold">{articles.length} Artikel</span>
          {' ' + pluralizeWord(articles.length, 'wurde') + ' in '}
          <span className="text-error500 bold">
            {pluralizeWord(deliveryNotes.length, 'Lieferung', true)}
          </span>
        </InvoiceCheckTableTooltip>
        {' reklamiert oder die Menge wurde nicht komplett bestätigt.'}
      </>
    );
  }

  render() {
    if (!this.props.category) {
      return null;
    }

    return this.getText();
  }
}
