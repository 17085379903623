import clsx from 'clsx';

import { Info as InfoIcon } from '@mui/icons-material';

export const Hint = ({ type, text }) => (
  <div
    className={clsx('flex items-center text-xs', {
      'text-error500': type === 'error',
    })}
  >
    <InfoIcon
      className={clsx('mr-2 text-lg', {
        'text-error500': type === 'error',
      })}
    />
    {text}
  </div>
);
