import { withErrorBoundary } from '~/ui/atoms';
import InvoiceCheckIcon from '../InvoiceCheckIcon';
import InvoiceCheckResult from '~/models/invoices/InvoiceCheckResult';
import InvoiceCheckSummaryText from '../invoiceCheckSummary/InvoiceCheckSummaryText';
import StyledAccordion from '~/components/baseComponents/accordion/StyledAccordion';

function InvoiceCheckCategory(props) {
  return (
    <>
      <div className="flex-s-s gap-20px">
        <InvoiceCheckIcon
          iconKey={props.category.key}
          status={props.category.status}
          className={
            'icon-extra-large ' +
            InvoiceCheckResult.getStatusColorClass(props.category.status)
          }
        />
        <div>
          <div className="text-20px bold">{props.category.name}</div>
          <div className="text-14px">
            <InvoiceCheckSummaryText category={props.category} withTooltip />
          </div>
        </div>
      </div>
      {props.category.status === InvoiceCheckResult.STATUS.ERROR ? (
        <div className="flexdir-column gap-10px mt-20px flex">
          {props.errorInvoiceChecks}
        </div>
      ) : null}
      {props.category.status === InvoiceCheckResult.STATUS.WARNING ? (
        <div className="flexdir-column gap-10px mt-20px flex">
          {props.errorInvoiceChecks}
        </div>
      ) : null}
      {props.category.status === InvoiceCheckResult.STATUS.DELAYED_SUCCESS ? (
        <div className="flexdir-column gap-10px mt-20px flex">
          {props.delayedSuccessInvoiceChecks}
        </div>
      ) : null}
      {props.category.status ===
        InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE &&
      props.category.errorChecks.length > 0 ? (
        <div className="flexdir-column gap-10px mt-20px flex">
          {props.errorInvoiceChecks}
        </div>
      ) : null}
      {props.category.successChecks.length > 0 ? (
        <StyledAccordion
          title="Korrekte Schritte"
          productAnalyticsFeature="invoice_check_correct_checks"
        >
          <div className="flexdir-column gap-10px mt-20px flex">
            {props.successInvoiceChecks}
          </div>
        </StyledAccordion>
      ) : null}
    </>
  );
}

export default withErrorBoundary(
  InvoiceCheckCategory,
  'Prüfschritt konnte nicht geladen werden',
);
