import React from 'react';

import {
  Add as AddIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@mui/icons-material';
import { Button, Grid, TextField } from '@mui/material';

import { connect } from 'react-redux';
import { withErrorBoundary } from '~/ui/atoms';
import Log from '~/utils/Log';
import cloneDeep from 'lodash/cloneDeep';
import GenericMultiPicker from '~/components/baseComponents/inputs/select/GenericMultiPicker';
import {
  LightTooltip,
  MissingPermissionsTooltip,
} from '~/utils/componentUtils';
import CostCenter from '~/models/masterdata/CostCenter';
import BasicForm from '~/components/BasicForm';
import FunctionUtils from '~/utils/functionUtils';
import UserUtils from '~/utils/userUtils';

const mapStateToProps = (state) => ({
  costCenters: state.costCenters,
});

class CreateSiteWizardCostCenters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      costCenterOpen: false,
      costCenter: '',
      newCostCenters: [],
    };
  }

  handleChangeCostCenters = (costCenters) => {
    const newSite = cloneDeep(this.props.site);

    newSite.costCenters = costCenters.map((costCenter) => costCenter.id);

    Log.info(
      'Change form value of cost centers',
      { from: this.props.site.costCenters, to: newSite.costCenters },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change cost centers in create site wizard',
      Log.FEATURE.WIZARD,
    );

    this.props.setSite(newSite);
  };
  handleInputChange = (event) => {
    switch (event.target.name) {
      case 'name': {
        Log.info(
          'Change form value of cost center name',
          { from: this.state.costCenter, to: event.target.value },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'site_create_wizard_change_cost_center_name',
          Log.productAnalyticsEvent,
          ['Change cost center name in create site wizard', Log.FEATURE.WIZARD],
        );
        this.setState({
          costCenter: event.target.value,
        });
        break;
      }
    }
  };
  handleCostCenterOpen = () => {
    Log.info('Open cost center form', null, Log.BREADCRUMB.FORM_OPEN.KEY);
    Log.productAnalyticsEvent(
      'Open cost center form in create site wizard',
      Log.FEATURE.WIZARD,
    );

    this.setState({
      costCenterOpen: true,
    });
  };
  closeCostCenter = () => {
    Log.info('Close cost center form', null, Log.BREADCRUMB.FORM_CLOSE.KEY);
    Log.productAnalyticsEvent(
      'Close cost center form in create site wizard',
      Log.FEATURE.WIZARD,
    );

    this.setState({
      costCenterOpen: false,
    });
  };
  setCostCenter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Log.productAnalyticsEvent(
      'Submit cost center form in create site wizard',
      Log.FEATURE.WIZARD,
    );

    const newSite = cloneDeep(this.props.site);

    const existingCostCenter = this.props.costCenters.costCenters.find(
      (costCenter) => costCenter.name === this.state.costCenter,
    );

    if (existingCostCenter) {
      Log.productAnalyticsEvent(
        'Select existing cost center in create site wizard',
        Log.FEATURE.WIZARD,
      );
      const existingCostCenterIsAlreadySelected =
        this.props.site.costCenters.find(
          (costCenterId) => costCenterId === existingCostCenter.id,
        );

      // If cost center already exists and is not selected, select it from the list.
      if (!existingCostCenterIsAlreadySelected) {
        newSite.costCenters.push(existingCostCenter.id);
      }
    } else {
      // If cost center doesn't exist yet, create a new one.
      Log.productAnalyticsEvent(
        'Create new cost center in create site wizard',
        Log.FEATURE.WIZARD,
      );
      newSite.costCenters.push(this.state.costCenter);
      this.setState({
        newCostCenters: [
          ...this.state.newCostCenters,
          new CostCenter({
            id: this.state.costCenter,
            name: this.state.costCenter,
          }),
        ],
      });
    }

    this.props.setSite(newSite);

    this.setState({
      costCenter: '',
      costCenterOpen: false,
    });
  };

  getActiveCostCenters() {
    const activeCostCenters = [...this.props.costCenters.costCenters].filter(
      (costCenter) =>
        costCenter.active ||
        this.props.site.costCenters.includes(costCenter.id),
    );
    return [...activeCostCenters, ...this.state.newCostCenters];
  }

  getPickedCostCenters() {
    return this.props.site.costCenters.map(
      (id) =>
        this.props.costCenters.costCenters.find(
          (costCenter) => costCenter.id === id,
        ) ??
        this.state.newCostCenters.find((costCenter) => costCenter.id === id) ??
        new CostCenter({ id }),
    );
  }

  getCostCenterCreateButton() {
    if (!UserUtils.isCostCenterCreateAllowedUser()) {
      return (
        <MissingPermissionsTooltip>
          <Button
            variant="outlined"
            className="ml-10px"
            startIcon={<AddIcon />}
            onClick={this.handleCostCenterOpen}
            disabled
          >
            Erstellen
          </Button>
        </MissingPermissionsTooltip>
      );
    }

    return (
      <LightTooltip title="Neue Kostenstelle erstellen">
        <Button
          variant="outlined"
          color="primary"
          className="ml-10px"
          onClick={this.handleCostCenterOpen}
          startIcon={<AddIcon />}
        >
          Erstellen
        </Button>
      </LightTooltip>
    );
  }

  render() {
    return (
      <Grid container direction="row" spacing={3} space={4}>
        <Grid item xs={12} lg={12}>
          <h3 className="main-text mt-0">
            Mit welchen Kostenstellen soll der Standort verbunden werden?
          </h3>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8} className="flex-sb-e">
              <GenericMultiPicker
                textfieldLabel="Kostenstellen"
                pickedItems={this.getPickedCostCenters()}
                allItems={this.getActiveCostCenters()}
                callbackPickedItems={this.handleChangeCostCenters}
                fieldName="name"
                loading={this.props.costCenters.costCentersLoading}
                errorText="Kostenstellen konnten nicht geladen werden."
                sortItems
                sortItemsByKey="name"
              />
              {this.getCostCenterCreateButton()}
              <BasicForm
                title="Kostenstelle erstellen"
                open={this.state.costCenterOpen}
                formAbort={this.closeCostCenter}
                formSuccess={this.setCostCenter}
              >
                <div className="w-400px">
                  <TextField
                    id="name-input"
                    name="name"
                    label="Name"
                    type="text"
                    required
                    fullWidth
                    value={this.state.costCenter}
                    onChange={this.handleInputChange}
                    autoFocus
                    autoComplete="off"
                  />
                </div>
              </BasicForm>
            </Grid>
            {this.props.site.costCenters.length === 0 ? (
              <div className="mt-10px text-13px text-warningBase flex-s-c gap-5px">
                <ErrorOutlineIcon fontSize="small" />
                Es wird empfohlen, die zugehörigen Kostenstellen mit dem neuen
                Standort zu verbinden, damit Lieferungen richtig zugeordnet
                werden können.
              </div>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(CreateSiteWizardCostCenters),
  'Daten konnten nicht geladen werden.',
);
