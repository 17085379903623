import UserUtils from '~/utils/userUtils';

import ValueGroup from '../deliveries/ValueGroup';

export default class UniversalCommunication {
  constructor(universalCommunication) {
    this.phone = universalCommunication?.complete_number ?? '';
    this.email = universalCommunication?.email ?? '';
  }

  hasPhone() {
    return ValueGroup.getCurrentValue(this.phone);
  }

  hasEmail() {
    return ValueGroup.getCurrentValue(this.email);
  }

  hasCommunicationInformation() {
    return this.hasPhone() || this.hasEmail();
  }

  static getConcatenatedCommunicationInformation(universalCommunication) {
    return [
      ValueGroup.getCurrentValue(universalCommunication.phone),
      ValueGroup.getCurrentValue(universalCommunication.email),
    ]
      .filter(Boolean)
      .join(' | ');
  }

  static getSupportContact(companyAccountId) {
    if (UserUtils.isStrabagAccount(companyAccountId)) {
      return new UniversalCommunication({
        complete_number: '0800 44 66 77 00',
        email: 'itservice.de@strabag.com',
      });
    }

    return new UniversalCommunication({
      complete_number: '+49 89 904215125',
      email: 'support@vestigas.com',
    });
  }

  static getSalesContact() {
    return new UniversalCommunication({
      complete_number: '+49 89 904215121',
      email: 'kontakt@vestigas.com',
    });
  }
}
