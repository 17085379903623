import { createSlice } from '@reduxjs/toolkit';
import UserService from '~/services/user.service';
import { LOADING_STATE } from '~/constants/LoadingState';
import UserFeatureFlags from '~/models/masterdata/UserFeatureFlags';

const userinfoSlice = createSlice({
  name: 'userinfo',
  initialState: {
    userinfo: {
      company: null,
      firstname: null,
      id: null,
      isVestigasSupport: false,
      lastname: null,
      phone: null,
      position: null,
      profilePicture: null,
      userFeatureFlags: new UserFeatureFlags(),
      userHasLoggedInViaSSO: false,
      userType: null,
    },
    userinfoLoading: LOADING_STATE.NOT_LOADED,
    userPermissions: [],
    userPermissionsLoading: LOADING_STATE.NOT_LOADED,
    articleColorMapping: [],
    articleColorMappingVersion: 0,
    deliveryTabs: [], // Deprecated and not used anymore
    userActions: {},
  },
  reducers: {
    updateUserInfos(state, { payload }) {
      state.userinfo = {
        ...state.userinfo,
        firstname: payload.firstname,
        isVestigasSupport: payload.isVestigasSupport,
        lastname: payload.lastname,
        phone: payload.phone,
        position: payload.position,
      };
      state.userinfoLoading = LOADING_STATE.SUCCEEDED;
    },
    saveUserCompany(state, { payload }) {
      state.userinfo.company = payload;
    },
    saveUserPermissions(state, { payload }) {
      state.userPermissions = payload;
      state.userPermissionsLoading = LOADING_STATE.SUCCEEDED;
    },
    saveUserType(state, { payload }) {
      state.userinfo.userType = payload;
    },
    saveUserFeatureFlags(state, { payload }) {
      state.userinfo.userFeatureFlags = payload;
    },
    saveUserId(state, { payload }) {
      state.userinfo.id = payload;
    },
    saveProfilePicture(state, { payload }) {
      state.userinfo.profilePicture = payload;
    },
    saveArticleColorMapping(state, { payload }) {
      if (
        payload.saveToBackend &&
        payload.mapping.length > state.articleColorMapping.length
      ) {
        UserService.updateColorMapping(payload.mapping);
      }

      state.articleColorMapping = payload.mapping;
      state.articleColorMappingVersion += 1;
    },
    saveDeliveryTabs(state, { payload }) {
      state.deliveryTabs = payload;
    },
    saveUserActions(state, { payload }) {
      state.userActions = payload;
    },
    setUserinfoLoading(state, { payload }) {
      state.userinfoLoading = payload;
    },
    setUserPermissionsLoading(state, { payload }) {
      state.userPermissionsLoading = payload;
    },
    setUserHasLoggedInViaSSO(state, { payload }) {
      state.userinfo.userHasLoggedInViaSSO = payload;
    },
  },
});

const { actions, reducer } = userinfoSlice;
export const {
  saveArticleColorMapping,
  saveDeliveryTabs,
  saveProfilePicture,
  saveUserActions,
  saveUserCompany,
  saveUserFeatureFlags,
  saveUserId,
  saveUserPermissions,
  saveUserType,
  setUserHasLoggedInViaSSO,
  setUserPermissionsLoading,
  setUserinfoLoading,
  updateUserInfos,
} = actions;
export default reducer;
