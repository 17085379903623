import cloneDeep from 'lodash/cloneDeep';

import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';
import BilledItem from '~/models/billingState/BilledItem';
import DeliveryNote from '~/models/deliveries/DeliveryNote';

import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';
import { getCustomFieldOptions } from './getCustomFieldOptions';

import { filterDeliveries } from '~/components/deliveries/utils';

/**
 * Retrieves the _remaining selectable_ options from all options for a delivery notes filter (after filtering).
 */
export const getFilteredOptions = (
  data,
  selectedProcessState,
  filterName,
  customField,
  selectedCustomFields,
  props,
) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }

  if (props.data.length === 0) {
    return [];
  }

  const propertyString = (state) => DeliveryNote.PROPERTY[state].STRING;
  const propertyKey = (state) => DeliveryNote.PROPERTY[state].KEY;

  const dataZ = {
    rows: props.data, // = deliveryRows from deliveryNotesSlice
    dateRange: props.dateRange,
    query: props.query ?? '', // FIXME: query is not being passed in
    selectField: props.selectField,
    selectedAcceptState:
      filterName === propertyString('ACCEPT_STATE')
        ? []
        : props.selectedAcceptState,
    selectedArticle:
      filterName === propertyString('ARTICLE') ? [] : props.selectedArticle,
    selectedArticleNumber:
      filterName === propertyString('MAIN_ARTICLE_NUMBER')
        ? []
        : props.selectedArticleNumber,
    selectedCostCenter:
      filterName === propertyString('COST_CENTER')
        ? []
        : props.selectedCostCenter,
    selectedCustomFields:
      filterName === customField?.name ? [] : props.selectedCustomFields,
    selectedFromSite:
      filterName === propertyString('FROM_SITE') ? [] : props.selectedFromSite,
    selectedNumber:
      filterName === propertyString('NUMBER') ? [] : props.selectedNumber,
    selectedPermittedCostCenters:
      filterName === propertyString('PERMITTED_COST_CENTER_NAMES')
        ? []
        : props.selectedPermittedCostCenters,
    selectedPermittedToSites:
      filterName === propertyString('PERMITTED_TO_SITE_NAMES')
        ? []
        : props.selectedPermittedToSites,
    selectedProcessState:
      filterName === propertyString('PROCESS_STATE')
        ? []
        : props.selectedProcessState,
    selectedRecipient:
      filterName === propertyString('RECIPIENT') ? [] : props.selectedRecipient,
    selectedSettledStatus:
      filterName === propertyString('SETTLED_STATUS')
        ? []
        : props.selectedSettledStatus,
    selectedSupplier:
      filterName === propertyString('SUPPLIER') ? [] : props.selectedSupplier,
    selectedToSiteRecipient:
      filterName === propertyString('TO_SITE_RECIPIENT')
        ? []
        : props.selectedToSiteRecipient,
    selectedToSiteSupplier:
      filterName === propertyString('TO_SITE_SUPPLIER')
        ? []
        : props.selectedToSiteSupplier,
  };

  if (customField) {
    /**
     * In order to make the custom field filters influence each other,
     * props.data must be filtered by the other custom fields as well.
     * Thus, pass props.selectedCustomFields to the filter logic but
     * remove the own custom field filter to not restrict the own options
     * by the own selection.
     */
    const newSelectedCustomFields = cloneDeep(selectedCustomFields);
    const selectedCustomFieldIndex = selectedCustomFields.findIndex(
      ({ key }) => key === customField.key,
    );
    if (selectedCustomFieldIndex !== -1) {
      newSelectedCustomFields.splice(selectedCustomFieldIndex, 1);
    }

    dataZ.selectedCustomFields = newSelectedCustomFields;
  }

  const filteredData = filterDeliveries(dataZ);

  let filterOptions = null;

  switch (filterName) {
    case propertyString('ACCEPT_STATE'): {
      filterOptions = ArrayUtils.getDistinctValuesByKey(
        filteredData,
        propertyKey('ACCEPT_STATE'),
        ArrayUtils.EMPTY_DROPDOWN_OPTION,
        AcceptStateCalculator.getAcceptStateOptionsBasedOnProcessStates(
          selectedProcessState,
        ),
      );
      break;
    }

    case propertyString('ARTICLE'): {
      filterOptions = ArrayUtils.getDistinctValuesFromArrayByKey(
        filteredData,
        propertyKey('ARTICLES'),
      );
      break;
    }

    case propertyString('ARTICLE_NUMBERS'): {
      filterOptions = ArrayUtils.getDistinctValuesFromArrayByKey(
        filteredData,
        propertyKey('ARTICLE_NUMBERS'),
      );
      break;
    }

    case propertyString('COST_CENTER'): {
      filterOptions = ArrayUtils.getDistinctValuesFromArrayByKey(
        filteredData,
        propertyKey('COST_CENTERS'),
      );
      break;
    }

    case propertyString('FROM_SITE'): {
      filterOptions = ArrayUtils.getDistinctValuesByKey(
        filteredData,
        propertyKey('FROM_SITE'),
      );
      break;
    }

    case propertyString('NUMBER'): {
      filterOptions = ArrayUtils.getDistinctValuesByKey(
        filteredData,
        propertyKey('NUMBER'),
      );
      break;
    }

    case propertyString('PROCESS_STATE'): {
      filterOptions = ArrayUtils.getDistinctValuesByKey(
        filteredData,
        propertyKey('PROCESS_STATE'),
        ArrayUtils.EMPTY_DROPDOWN_OPTION,
        DeliveryNote.getProcessStateOptions(),
      );
      break;
    }

    case propertyString('RECIPIENT'): {
      filterOptions = ArrayUtils.getDistinctValuesByKey(
        filteredData,
        propertyKey('RECIPIENT'),
      );
      break;
    }

    case propertyString('PERMITTED_COST_CENTER_NAMES'): {
      filterOptions = ArrayUtils.getDistinctValuesFromArrayByKey(
        filteredData,
        propertyKey('PERMITTED_COST_CENTER_NAMES'),
      );
      break;
    }

    case propertyString('PERMITTED_TO_SITE_NAMES'): {
      filterOptions = ArrayUtils.getDistinctValuesFromArrayByKey(
        filteredData,
        propertyKey('PERMITTED_TO_SITE_NAMES'),
      );
      break;
    }

    case propertyString('SETTLED_STATUS'): {
      filterOptions = ArrayUtils.getDistinctValuesByKey(
        filteredData,
        propertyKey('SETTLED_STATUS'),
        ArrayUtils.EMPTY_DROPDOWN_OPTION,
        BilledItem.getSettledStatusOptions(),
      );
      break;
    }

    case propertyString('SUPPLIER'): {
      filterOptions = ArrayUtils.getDistinctValuesByKey(
        filteredData,
        propertyKey('SUPPLIER'),
      );
      break;
    }

    case propertyString('TO_SITE_RECIPIENT'): {
      filterOptions = ArrayUtils.getDistinctValuesByKey(
        filteredData,
        propertyKey('TO_SITE_RECIPIENT'),
      );
      break;
    }

    case propertyString('TO_SITE_SUPPLIER'): {
      filterOptions = ArrayUtils.getDistinctValuesByKey(
        filteredData,
        propertyKey('TO_SITE_SUPPLIER'),
      );
      break;
    }

    default: {
      break;
    }
  }

  const filterNameHasMatched = Array.isArray(filterOptions);
  if (filterNameHasMatched) {
    // Make sure to return strings. Broken data like the number 0 will break the rendering of the Select otherwise.
    return filterOptions.filter((v) => v != null).map(String);
  }

  if (customField) {
    // Make sure to return strings. Broken data like the number 0 will break the rendering of the Select otherwise.
    return getCustomFieldOptions(filteredData, customField)
      .filter((v) => v != null)
      .map(String);
  }

  Log.error(
    'Failed to find autocomplete option for selectable filter (getFilteredOptions). name: ' +
      filterName +
      ' | custom field key: ' +
      customField?.key,
  );

  // Just return an array to make sure the Select works.
  return [];
};
