import mergeWith from 'lodash/mergeWith';

/**
 * Converts an asset chain object to a main asset object by extracting the `body` property and merging it with the rest of the object.
 *
 * @param {Object} assetChain - The asset chain object to be converted.
 * @param {Object} assetChain.body - The body property of the asset chain object.
 * @param {...Object} rest - The rest of the properties of the asset chain object.
 * @return {Object} The main asset object with the `asset_state` property containing the `body` property and the rest of the properties.
 */
export const convertAssetChainToMain = ({ body, ...rest }) => ({
  asset_state: {
    body,
  },
  ...rest,
});

export const updateAssetMain = (assetMain, assetChain) =>
  mergeWith({}, assetMain, assetChain, (mainValue, chainValue) => {
    // if asset chain contains null, it has NOT been updated
    if (chainValue == null) {
      return mainValue;
    }
  });
