import axios from '~/utils/api-client';
import Config from '~/Config';
import Log from '~/utils/Log';
import store from '~/redux/store';
import { LOADING_STATE } from '~/constants/LoadingState';
import { promiseHandler } from '~/utils/promiseHandler';
import {
  replaceContactPoints,
  replaceDataSubscriptions,
  replaceNotificationPolicies,
  setContactPointsLoading,
  setDataSubscriptionsLoading,
  setNotificationPoliciesLoading,
} from '~/redux/dataSubscriptionsSlice';
import DataSubscription from '~/models/dataSubscriptions/DataSubscription';
import ContactPoint from '~/models/dataSubscriptions/ContactPoint';
import NotificationPolicy from '~/models/dataSubscriptions/NotificationPolicy';

const API_URL = Config.apiUrl + '/data_subscription';

class DataSubscriptionService {
  async getAllDataSubscriptions() {
    return axios.get(API_URL).then((response) => {
      if (response.status !== 200) {
        return [];
      }

      return response.data.items.map((item) => new DataSubscription(item));
    });
  }

  async createDataSubscription(body) {
    return axios.post(API_URL, body).then((response) => {
      return response.data?.id;
    });
  }

  async updateDataSubscription(id, body) {
    return axios.put(API_URL + '/' + id, body);
  }

  async deleteDataSubscription(id) {
    return axios.delete(API_URL + '/' + id);
  }

  loadDataSubscriptions = async () => {
    // to not load items again when they are already loading or have already been loaded
    if (
      store.getState().dataSubscriptions?.dataSubscriptionsLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshDataSubscriptions();
  };
  refreshDataSubscriptions = async () => {
    store.dispatch(setDataSubscriptionsLoading(LOADING_STATE.LOADING));

    const [dataSubscriptions, error] = await promiseHandler(
      this.getAllDataSubscriptions(),
    );

    if (error) {
      store.dispatch(setDataSubscriptionsLoading(LOADING_STATE.FAILED));
      Log.error('Failed to load data subscriptions.', error);
      Log.productAnalyticsEvent(
        'Failed to load data subscriptions',
        Log.FEATURE.DATA_SUBSCRIPTION,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceDataSubscriptions(dataSubscriptions));
  };

  async getAllContactPoints() {
    return axios.get(API_URL + '/contact_point').then((response) => {
      if (response.status !== 200) {
        return [];
      }

      return response.data.contact_points.map((item) => new ContactPoint(item));
    });
  }

  async createContactPoint(body) {
    return axios.post(API_URL + '/contact_point', body).then((response) => {
      return response.data?.id;
    });
  }

  async updateContactPoint(id, body) {
    return axios.put(API_URL + '/contact_point/' + id, body);
  }

  async deleteContactPoint(id) {
    return axios.delete(API_URL + '/contact_point/' + id);
  }

  loadContactPoints = async () => {
    // to not load items again when they are already loading or have already been loaded
    if (
      store.getState().dataSubscriptions?.contactPointsLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshContactPoints();
  };
  refreshContactPoints = async () => {
    store.dispatch(setContactPointsLoading(LOADING_STATE.LOADING));

    const [contactPoints, error] = await promiseHandler(
      this.getAllContactPoints(),
    );

    if (error) {
      store.dispatch(setContactPointsLoading(LOADING_STATE.FAILED));
      Log.error('Failed to load contact points.', error);
      Log.productAnalyticsEvent(
        'Failed to load contact points',
        Log.FEATURE.DATA_SUBSCRIPTION,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceContactPoints(contactPoints));
  };

  async getAllNotificationPolicies() {
    return axios.get(API_URL + '/notification_policy').then((response) => {
      if (response.status !== 200) {
        return [];
      }

      return response.data.items.map((item) => new NotificationPolicy(item));
    });
  }

  async loadNotificationPolicies() {
    // to not load items again when they are already loading or have already been loaded
    if (
      store.getState().dataSubscriptions?.notificationPoliciesLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshNotificationPolicies();
  }

  refreshNotificationPolicies = async () => {
    store.dispatch(setNotificationPoliciesLoading(LOADING_STATE.LOADING));

    const [notificationPolicies, error] = await promiseHandler(
      this.getAllNotificationPolicies(),
    );

    if (error) {
      store.dispatch(setNotificationPoliciesLoading(LOADING_STATE.FAILED));
      Log.error('Failed to load notification policies.', error);
      Log.productAnalyticsEvent(
        'Failed to load notification policies',
        Log.FEATURE.DATA_SUBSCRIPTION,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceNotificationPolicies(notificationPolicies));
  };

  async createNotificationPolicy(body) {
    return axios.post(API_URL + '/notification_policy', body);
  }

  async deleteNotificationPolicy(contactPointId, dataSubscriptionId) {
    return axios.post(API_URL + '/notification_policy/delete', {
      contact_point_id: contactPointId,
      data_subscription_id: dataSubscriptionId,
    });
  }
}

export default new DataSubscriptionService();
