import { DarkTooltipExtraWide } from '~/utils/componentUtils';

export default function InvoiceCheckListTooltip(props) {
  const body = (
    <ul className="pr-10px">
      {props.items.map((item) => (
        <li key={item}>
          <span className="bold text-14px">{item}</span>
        </li>
      ))}
    </ul>
  );

  const content = (
    <div>
      <div className="bold text-14px flex-c-c p-5px border-bottom-grey400 mb-8px w-full">
        {props.title}
      </div>
      {body}
    </div>
  );

  return (
    <DarkTooltipExtraWide title={content}>
      <span className="cursor-pointer">{props.children}</span>
    </DarkTooltipExtraWide>
  );
}
