import React, { useState, useMemo, useCallback } from 'react';

import { Delete as DeleteIcon } from '@mui/icons-material';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';

import { Spinner } from './Spinner';

import { LightTooltip } from '~/utils/componentUtils';
import { LOADING_STATE } from '~/constants/LoadingState';

export const BurgerMenu = ({
  bottomButtonId,
  bottomButtonName,
  className,
  color,
  deleteText = 'Eintrag löschen',
  disabled,
  endIcon,
  loading,
  menuId,
  onClick,
  onDelete,
  options,
  startIcon,
  title,
  variant,
}) => {
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleClick = useCallback(
    (id) => {
      setMenuAnchor(null);
      onClick(id);
    },
    [onClick],
  );

  const handleDelete = useCallback(
    (e, id) => {
      e.stopPropagation();
      onDelete(id);
    },
    [onDelete],
  );

  const getMenuItems = useMemo(() => {
    let menuItems = [];

    if (loading === LOADING_STATE.LOADING) {
      menuItems = [
        <MenuItem key="default-loading" disabled>
          <Spinner title="Laden..." />
        </MenuItem>,
      ];
    } else if (loading === LOADING_STATE.SUCCEEDED && options.length === 0) {
      menuItems = [
        <MenuItem key="default-no-entries" disabled>
          Keine Einträge
        </MenuItem>,
      ];
    } else if (loading === LOADING_STATE.FAILED) {
      menuItems = [
        <MenuItem key="default-no-entries" disabled>
          Vorlagen konnten nicht geladen werden.
        </MenuItem>,
      ];
    } else {
      menuItems = options.map(({ deletable, id, name }) => (
        <MenuItem key={id} onClick={() => handleClick(id)}>
          <div className="flex-sb-c gap-20px w-full">
            {name}
            {deletable && onDelete ? (
              <LightTooltip title={deleteText}>
                <IconButton
                  size="small"
                  component="span"
                  onClick={(e) => handleDelete(e, id)}
                >
                  <DeleteIcon />
                </IconButton>
              </LightTooltip>
            ) : null}
          </div>
        </MenuItem>
      ));
    }

    if (bottomButtonId && bottomButtonName) {
      menuItems.push(
        <MenuItem
          key={bottomButtonId}
          onClick={() => handleClick(bottomButtonId)}
          style={{
            borderTop: '1px solid #e0e0e0',
          }}
        >
          {bottomButtonName}
        </MenuItem>,
      );
    }

    return menuItems;
  }, [
    loading,
    options,
    onDelete,
    deleteText,
    bottomButtonId,
    bottomButtonName,
    handleClick,
    handleDelete,
  ]);

  const renderMenu = useMemo(
    () => (
      <Menu
        open={Boolean(menuAnchor)}
        anchorEl={menuAnchor}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        id={menuId}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        onClose={() => setMenuAnchor(null)}
        keepMounted
      >
        {getMenuItems}
      </Menu>
    ),
    [menuAnchor, menuId, getMenuItems],
  );

  return (
    <>
      <Button
        aria-controls={menuId}
        onClick={(event) => setMenuAnchor(event.currentTarget)}
        className={className}
        variant={variant}
        color={color}
        startIcon={startIcon}
        endIcon={endIcon}
        disabled={disabled}
      >
        {title}
      </Button>
      {renderMenu}
    </>
  );
};
