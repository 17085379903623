import Config from '~/Config';
import axios from '~/utils/api-client';
import CustomerSubscription from '~/models/masterdata/CustomerSubscription';
import store from '~/redux/store';
import { LOADING_STATE } from '~/constants/LoadingState';
import {
  replaceCustomerSubscriptions,
  setCustomerSubscriptionsLoading,
} from '~/redux/customerSubscriptionsSlice';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

const API_URL = Config.apiUrl + '/customer_subscription';

class CustomerSubscriptionService {
  async getAllCustomerSubscriptions() {
    return axios.get(API_URL).then((response) => {
      if (response.status !== 200) {
        return [];
      }

      return response.data.customer_subscriptions.map(
        (customerSubscription) =>
          new CustomerSubscription(customerSubscription),
      );
    });
  }

  async createCustomerSubscription(body) {
    return axios.post(API_URL, body).then((response) => {
      return response.data?.id;
    });
  }

  async updateCustomerSubscription(id, body) {
    return axios.put(API_URL + '/' + id, body);
  }

  async testCustomerSubscription(body) {
    return axios.post(API_URL + '/test', body);
  }

  loadCustomerSubscriptions = async () => {
    // to not load customer subscriptions again when they are already loading or have already been loaded
    if (
      store.getState().customerSubscriptions?.customerSubscriptionsLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshCustomerSubscriptions();
  };
  refreshCustomerSubscriptions = async () => {
    store.dispatch(setCustomerSubscriptionsLoading(LOADING_STATE.LOADING));

    const [customerSubscriptions, error] = await promiseHandler(
      this.getAllCustomerSubscriptions(),
    );

    if (error) {
      store.dispatch(setCustomerSubscriptionsLoading(LOADING_STATE.FAILED));
      Log.error('Failed to load customer subscriptions.', error);
      Log.productAnalyticsEvent(
        'Failed to load subscriptions',
        Log.FEATURE.SUBSCRIPTION,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceCustomerSubscriptions(customerSubscriptions));
  };
}

export default new CustomerSubscriptionService();
