import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FolderOutlinedIcon } from '~/assets/icons';

import ValueGroup from '~/models/deliveries/ValueGroup';

import { ROUTE } from '~/constants/Route';
import { setConcreteDiary_concreteIds } from '~/redux/filtersSlice';
import Log from '~/utils/Log';

import IconButton from '~/components/IconButton';

export const ImportIntoConcreteDiaryButton = ({ deliveryNote }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const concreteIds = useSelector(
    ({ filters }) => filters.concreteDiary_concreteIds,
  );

  const handleAddConcreteId = () => {
    Log.productAnalyticsEvent(
      'Import delivery note',
      Log.FEATURE.CONCRETE_DIARY,
    );

    const newConcreteId = ValueGroup.getCurrentValue(
      deliveryNote.articles.find((article) => article.isConcrete())?.concrete
        ?.concreteId,
    );

    if (!newConcreteId) {
      Log.error('Failed to find concrete article in concrete delivery note.');
      Log.productAnalyticsEvent(
        'Failed to import delivery note',
        Log.FEATURE.CONCRETE_DIARY,
        Log.TYPE.ERROR,
      );
      return;
    }

    if (!concreteIds.includes(newConcreteId)) {
      dispatch(setConcreteDiary_concreteIds([...concreteIds, newConcreteId]));
    }

    history.push({ pathname: ROUTE.CONCRETE_DIARY.ROUTE });
  };

  return (
    <IconButton
      onClick={handleAddConcreteId}
      tooltipTitle="Sorte in Betontagebuch importieren."
      size="large"
    >
      <FolderOutlinedIcon className="text-primary500 icon-small" />
    </IconButton>
  );
};
