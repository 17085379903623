import React from 'react';
import ProfileDetails from './ProfileDetails';
import ProfileBanner from './ProfileBanner';
import { Grid } from '@mui/material';
import ProfileUserSettings from './ProfileUserSettings';
import { withErrorBoundary } from '~/ui/atoms';

function ProfileSettings() {
  return (
    <>
      <ProfileBanner uploadablePicture />
      <div className="mt-20px">
        <Grid container justifyContent="space-between" spacing="20px">
          <Grid item xs={12} sm={6}>
            <div className="box-shadow-blue rounded-5px p-20px bg-white">
              <ProfileDetails />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="box-shadow-blue rounded-5px p-20px bg-white">
              <ProfileUserSettings />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default withErrorBoundary(
  ProfileSettings,
  'Profil konnte nicht geladen werden.',
);
