import { Button, Grid } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import { LightTooltip } from '~/utils/componentUtils';

import GenericMultiPicker from '~/components/baseComponents/inputs/select/GenericMultiPicker';

import CostCenterForm from '../../../costCenter/CostCenterForm';

export const CostCentersSection = ({
  closeCostCenter,
  costCenterOpen,
  costCentersLoading,
  getActiveCostCenters,
  getPickedCostCenters,
  handleChangeCostCenters,
  handleCostCenterOpen,
  setCostCenter,
}) => (
  <Grid item xs={12} lg={12}>
    <Grid container spacing={2}>
      <Grid item xs={12} lg={8} className="flex-sb-e">
        <GenericMultiPicker
          textfieldLabel="Kostenstellen"
          pickedItems={getPickedCostCenters}
          allItems={getActiveCostCenters}
          callbackPickedItems={handleChangeCostCenters}
          fieldName="name"
          loading={costCentersLoading}
          errorText="Kostenstellen konnten nicht geladen werden."
          sortItems
          sortItemsByKey="name"
        />
        <LightTooltip title="Neue Kostenstelle erstellen">
          <Button
            variant="outlined"
            color="primary"
            className="ml-10px"
            onClick={handleCostCenterOpen}
            startIcon={<AddIcon />}
          >
            Erstellen
          </Button>
        </LightTooltip>
        <CostCenterForm
          open={costCenterOpen}
          closeForm={closeCostCenter}
          setCostCenter={setCostCenter}
          type={'create'}
        />
      </Grid>
    </Grid>
  </Grid>
);
