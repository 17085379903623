import React from 'react';

import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

import DataExchangeTile from './DataExchangeTile';
import DataExchangeStatusIcon from './DataExchangeStatusIcon';
import DataExchangeStatusTooltip from './DataExchangeStatusTooltip';
import { AccordionBody } from '../baseComponents/accordion/AccordionBody';
import Log from '~/utils/Log';
import DataExchangeCompany from '~/models/dataExchanges/DataExchangeCompany';
import { useSelector } from 'react-redux';

const DataExchangeCategory = (props) => {
  const [open, toggleOpen] = React.useState(true);

  const companies = useSelector((state) => state.companies);
  const selectedCompany = useSelector(
    (state) => state.filters.dataExchanges_selectedCompany,
  );

  if (props.category.dataExchanges.length === 0) {
    return;
  }

  const getLabel = () => {
    if (props.category.key === DataExchangeCompany.STATUS.ACTIVATED.KEY) {
      const company = companies.companies.find(
        (company) => company.id === selectedCompany,
      );

      if (company) {
        return (
          <span className="ml-10px mr-10px bold">
            Für {company.name} {props.category.label}
          </span>
        );
      }

      return (
        <span className="ml-10px mr-10px bold">{props.category.label}</span>
      );
    }

    return <span className="ml-10px mr-10px">{props.category.label}</span>;
  };

  const handleClick = () => {
    if (open) {
      Log.productAnalyticsEvent(
        'Collapse category',
        Log.FEATURE.SUPPLIER_OVERVIEW,
      );
      toggleOpen(false);
    } else {
      Log.productAnalyticsEvent(
        'Expand category',
        Log.FEATURE.SUPPLIER_OVERVIEW,
      );
      toggleOpen(true);
    }
  };

  return (
    <div id={props.category.key}>
      <DataExchangeStatusTooltip status={props.category.key}>
        <div
          className="rounded-5px border-grey400 flex-sb-c mb-16px p-5px w-300px t-10px z-index-1 sticky cursor-pointer bg-white"
          onClick={handleClick}
        >
          <div className="flex-s-c">
            <DataExchangeStatusIcon status={props.category.key} />
            {getLabel()}
          </div>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>
      </DataExchangeStatusTooltip>
      <AccordionBody id={props.category.key} open={open}>
        <div className="flex-s-s gap-16px mb-16px flex-wrap">
          {props.category.dataExchanges.map((dataExchange, index) => (
            <div
              className="rounded-15px p-16px w-300px min-h-240px bg-white"
              key={dataExchange.id}
            >
              <DataExchangeTile
                key={index}
                dataExchange={dataExchange}
                requestInformationSelection={props.requestInformationSelection}
                requestInformationSelectionList={
                  props.requestInformationSelectionList
                }
                onChangeRequestInformationSelection={
                  props.onChangeRequestInformationSelection
                }
              />
            </div>
          ))}
        </div>
      </AccordionBody>
    </div>
  );
};

export default DataExchangeCategory;
