import React from 'react';

import {
  AddBoxOutlined as AddBoxOutlinedIcon,
  BrokenImageOutlined as BrokenImageOutlinedIcon,
  Check as CheckIcon,
  CloseOutlined as CloseOutlinedIcon,
  ContentPasteOffOutlined as ContentPasteOffOutlinedIcon,
  IndeterminateCheckBoxOutlined as IndeterminateCheckBoxOutlinedIcon,
} from '@mui/icons-material';

import { Grid } from '@mui/material';

import { LightTooltip } from '~/utils/componentUtils';
import UnitUtils from '~/utils/unitUtils';
import { withErrorBoundary } from '~/ui/atoms';
import AcceptArticle from '~/models/acceptState/AcceptArticle';
import SignatureRoles from '~/models/masterdata/SignatureRoles';
import Attachment from '../attachments/Attachment';

class ArticleStatus extends React.Component {
  getAcceptStatusText(amount, description, icon) {
    if (!amount || amount <= 0) {
      return null;
    }

    return (
      <div className="flex-s-c bold">
        <div className="flex-s-c w-120px">
          <LightTooltip title={description}>{icon}</LightTooltip>
          {UnitUtils.formatValueUnitPair_safe(
            amount,
            this.props.acceptArticle?.unit,
            UnitUtils.getAbbreviatedUnit,
          ) ?? ''}
        </div>
        ({description})
      </div>
    );
  }

  render() {
    // Check if the chainId has already been loaded from the asset_chains. This is not the case until the dln history has been loaded.
    const attachments =
      this.props.acceptArticle?.attachments?.filter(
        (attachment) => attachment.chainId,
      ) ?? [];

    // Display this.props.acceptArticle?.acceptState as a variable might lead to cases where capital letters are wrong (e.g. Artikel Teilweise angenommen)
    const articleStatus = (
      <>
        <div className="bold">
          Artikel {this.props.acceptArticle?.acceptState} (
          {SignatureRoles.getSignatureRole(this.props.party)})
        </div>
        {this.props.acceptArticle?.acceptStatus ? (
          <div>
            Artikel {this.props.acceptArticle?.getDescriptiveAcceptStatus()}
          </div>
        ) : (
          <div>
            {this.getAcceptStatusText(
              this.props.acceptArticle?.partialAcceptStatus?.valid,
              AcceptArticle.ACCEPT_STATUS.VALID.DESCRIPTION,
              <CheckIcon className="mr-5px" fontSize="small" />,
            )}
            {this.getAcceptStatusText(
              this.props.acceptArticle?.partialAcceptStatus?.damaged,
              AcceptArticle.ACCEPT_STATUS.DAMAGED.DESCRIPTION,
              <BrokenImageOutlinedIcon className="mr-5px" fontSize="small" />,
            )}
            {this.getAcceptStatusText(
              this.props.acceptArticle?.partialAcceptStatus?.wrong,
              AcceptArticle.ACCEPT_STATUS.WRONG.DESCRIPTION,
              <ContentPasteOffOutlinedIcon
                className="mr-5px"
                fontSize="small"
              />,
            )}
            {this.getAcceptStatusText(
              this.props.acceptArticle?.partialAcceptStatus?.declined,
              AcceptArticle.ACCEPT_STATUS.DECLINED.DESCRIPTION,
              <CloseOutlinedIcon className="mr-5px" fontSize="small" />,
            )}
            {this.getAcceptStatusText(
              this.props.acceptArticle?.partialAcceptStatus?.delta,
              'fehlt',
              <IndeterminateCheckBoxOutlinedIcon
                className="mr-5px"
                fontSize="small"
              />,
            )}
            {this.getAcceptStatusText(
              -this.props.acceptArticle?.partialAcceptStatus?.delta,
              'überschüssig',
              <AddBoxOutlinedIcon className="mr-5px" fontSize="small" />,
            )}
          </div>
        )}
      </>
    );

    const reason = (
      <>
        <div>Kommentar</div>
        <div className="bold">{this.props.acceptArticle?.reason ?? '-'}</div>
      </>
    );

    const attachmentDiv = (
      <div className="flex-s-s gap-10px mt-20px flex-wrap">
        {attachments.map((attachment) => (
          <Attachment attachment={attachment} />
        ))}
      </div>
    );

    return (
      <div>
        <Grid container spacing="20px" justifyContent="space-between">
          <Grid item xs={12} lg={6}>
            {articleStatus}
          </Grid>
          <Grid item xs={12} lg={6}>
            {reason}
          </Grid>
        </Grid>
        {attachments.length > 0 ? attachmentDiv : null}
      </div>
    );
  }
}

export default withErrorBoundary(
  ArticleStatus,
  'Artikelstatus konnte nicht geladen werden.',
);
