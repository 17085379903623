import UnitUtils from '~/utils/unitUtils';
import ToastService from '~/services/toast.service';
import Log from '~/utils/Log';

export default class Coordinates {
  constructor(coords) {
    this.latitude = coords?.latitude ?? null;
    this.longitude = coords?.longitude ?? null;
  }

  // This should not be a static function but as there are still issues with reading models from the redux store, making it a static function is the quick fix.
  static getConvertedCoordinates(latitude, longitude) {
    if (!latitude && !longitude) {
      return {
        latitude: null,
        longitude: null,
      };
    }

    if (
      !UnitUtils.isValidCoordinate(latitude) ||
      !UnitUtils.isValidCoordinate(longitude)
    ) {
      ToastService.warning([
        'Ungültige Eingabe: Koordinaten konnten nicht übernommen werden.',
      ]);
      Log.productAnalyticsEvent(
        'Invalid coordinates',
        Log.FEATURE.SITE,
        Log.TYPE.FAILED_VALIDATION,
      );

      return {
        latitude: null,
        longitude: null,
      };
    }

    return {
      latitude: UnitUtils.getConvertedCoordinate(latitude),
      longitude: UnitUtils.getConvertedCoordinate(longitude),
    };
  }

  static getDifferentValues(coordinatesA, coordinatesB) {
    const differentValues = [];

    if (coordinatesA?.latitude !== coordinatesB?.latitude) {
      differentValues.push('Breitengrad');
    }

    if (coordinatesA?.longitude !== coordinatesB?.longitude) {
      differentValues.push('Längengrad');
    }

    return differentValues;
  }
}
