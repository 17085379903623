import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { promiseHandler } from '~/utils/promiseHandler';
import PushService from '~/services/push.service';
import Log from '~/utils/Log';
import ToastService from '~/services/toast.service';
import UserAction from '~/models/userActions/UserAction';
import { saveUserActions } from '~/redux/userinfoSlice';
import { updateDeliveryNotes } from '~/redux/deliveryNotesSlice';
import UserService from '~/services/user.service';
import { LOADING_STATE } from '~/constants/LoadingState';
import { Spinner } from '~/components/Spinner';
import DeliveryNote from '~/models/deliveries/DeliveryNote';
import { LightTooltip } from '~/utils/componentUtils';
import { UserSignatureIcon } from '~/assets/icons';
import { Chip } from '@mui/material';
import ArrayUtils from '~/utils/arrayUtils';
import DeliveryNoteActionMetaData from '../../deliveryNoteActions/DeliveryNoteActionMetaData';

export default function DeliveryNoteMetaDataUsers(props) {
  const dispatch = useDispatch();
  const { userActions } = useSelector((state) => state.userinfo);
  const [requestingUserId, setRequestingUserId] = useState(null);

  const requestSignature = async (user) => {
    setRequestingUserId(user.id);

    const [, error] = await promiseHandler(
      PushService.requestSignature([props.deliveryNote.id], [user.id]),
    );

    if (error) {
      Log.error(
        'Failed to send push to request delivery note signature.',
        error,
      );
      ToastService.httpError(
        [
          'Signatur konnte nicht angefordert werden wegen eines internen Fehlers.',
        ],
        error.response,
      );
      Log.productAnalyticsEvent(
        'Failed to request signatures',
        Log.FEATURE.NOTIFICATIONS,
        Log.TYPE.ERROR,
      );
      setRequestingUserId(null);
      return;
    }

    ToastService.success([
      'Die Signatur wurde erfolgreich angefordert.',
      'Es wurde eine Benachrichtigung an den Benutzer geschickt.',
    ]);

    const [newUserActions, updatedDeliveryNotes] =
      UserAction.getEnhanceUserActionsAndDeliveryNotes(
        userActions,
        [user],
        [props.deliveryNote],
        [props.deliveryNote.id],
        UserAction.TYPE.REQUEST_SIGNATURES,
      );

    dispatch(saveUserActions(newUserActions));
    dispatch(updateDeliveryNotes(updatedDeliveryNotes));

    const [, error2] = await promiseHandler(
      UserService.updateUserActions(newUserActions),
    );

    if (error2) {
      Log.error('Failed to update user actions.', error2);
    }

    setRequestingUserId(null);
  };

  const getName = (user) => {
    return user.name ? user.name : user.email;
  };

  if (props.usersLoading === LOADING_STATE.FAILED) {
    return 'Daten konnten nicht geladen werden.';
  }

  if (props.usersLoading === LOADING_STATE.LOADING) {
    return <Spinner alignLeft />;
  }

  if (!props.deliveryNote || props.users.length === 0) {
    return '-';
  }

  let enhancedUsers = [];

  const userActionsList = [];
  if (userActions[props.deliveryNote?.id]) {
    userActionsList.push(userActions[props.deliveryNote.id]);
  }

  const requestedUsers = UserAction.getRequestedUsers(userActionsList);

  for (const user of props.users) {
    if (enhancedUsers.find((enhancedUser) => enhancedUser.id === user.id)) {
      continue;
    }

    user.requestedUser =
      requestedUsers.find((requestedUser) => requestedUser.id === user.id) ??
      null;

    enhancedUsers.push(user);
  }

  enhancedUsers = ArrayUtils.sortByKey(
    ArrayUtils.removeDuplicatesByKey(enhancedUsers, 'id'),
    'name',
  );

  const deliveryNoteCanBeSigned =
    props.deliveryNote.processState !==
    DeliveryNote.PROCESS_STATE.DELIVERED.STRING;

  if (!deliveryNoteCanBeSigned) {
    return (
      <div className="flex-s-c gap-5px flex-wrap">
        {enhancedUsers.map((user) => {
          const name = getName(user);
          return <Chip key={name} label={name} />;
        })}
      </div>
    );
  }

  return (
    <div className="flex-s-c gap-5px flex-wrap">
      {enhancedUsers.map((user) => {
        const tooltipTitle = user.requestedUser ? (
          <DeliveryNoteActionMetaData
            key={user.id}
            title="Signatur bereits angefordert:"
            users={[user.requestedUser]}
            withoutNameAndEmail
          />
        ) : (
          'Signatur von ' + getName(user) + ' anfordern.'
        );
        const icon =
          user.id === requestingUserId ? (
            <Spinner />
          ) : (
            <UserSignatureIcon
              className={
                'icon-small ' + (user.requestedUser ? 'text-grey400' : '')
              }
            />
          );
        const name = getName(user);

        return (
          <LightTooltip key={name} title={tooltipTitle}>
            <Chip
              label={name}
              onClick={() => requestSignature(user)}
              icon={icon}
              sx={{ color: user.requestedUser ? '#8D94A8' : null }} // grey400
            />
          </LightTooltip>
        );
      })}
    </div>
  );
}
