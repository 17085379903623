import {
  EmailOutlined as EmailOutlinedIcon,
  ForumOutlined as ForumOutlinedIcon,
  PhoneOutlined as PhoneOutlinedIcon,
} from '@mui/icons-material';

import {
  PersonCheckedIcon,
  PersonErrorIcon,
  PersonStaleIcon,
} from '~/assets/icons';

export const SUPPLIERS_STATUS_MAP = {
  created: {
    message:
      'Die Ansprechperson hat noch nicht reagiert. Du kannst den Link erneuern, indem du eine neue E-Mail erstellst.',
    icon: <PersonStaleIcon />,
  },
  expired: {
    message:
      'Der Link für die Ansprechperson ist abgelaufen. Du kannst den Link erneuern, indem du eine neue E-Mail erstellst.',
    icon: <PersonErrorIcon />,
  },

  filled: {
    message: 'Die Ansprechperson hat sich erfolgreich registriert',
    icon: <PersonCheckedIcon />,
  },
};

export const CHANNEL_MAP = {
  personal: <ForumOutlinedIcon fontSize="small" />,
  email: <EmailOutlinedIcon fontSize="small" />,
  phone: <PhoneOutlinedIcon fontSize="small" />,
};
