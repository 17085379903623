import cloneDeep from 'lodash/cloneDeep';

import FilterContext from '~/models/filters/FilterContext';
import FilterNew from '~/models/filters/FilterNew';

/**
 * Get all filter groups with their disabled state based on the context.
 *
 * @param {Array} filterGroups - The array of filter groups to process.
 * @param {boolean} isArchiveMode - A flag indicating if the page is in archive mode.
 * @param {number} unassignedDeliveryNotesTabIndex - The index of the unassigned delivery notes tab.
 * @return {Array} The updated filter groups based on the context.
 */
export const getFilterGroups = (
  filterGroups,
  isArchiveMode,
  unassignedDeliveryNotesTabIndex,
) => {
  if (!isArchiveMode) {
    return filterGroups;
  }

  const newFilterGroups = cloneDeep(filterGroups).map((filterGroup, index) => {
    const activeFilters = FilterNew.getActiveFiltersForFilterGroup(
      filterGroup,
      FilterContext.PAGE.DELIVERY,
    );
    const nonApplicableBackendFilters =
      FilterNew.getNonApplicableFilters(activeFilters);

    // TODO: The "Nicht zugeordnet" feature is currently disabled because of some unspecified bugs.
    const isUnassignedDeliveryNotesFilterGroup =
      index === unassignedDeliveryNotesTabIndex;

    return {
      ...filterGroup,
      disabled:
        nonApplicableBackendFilters.length ||
        isUnassignedDeliveryNotesFilterGroup,
    };
  });

  return newFilterGroups;
};
