import React from 'react';

import { PictureAsPdf as PictureAsPdfIcon } from '@mui/icons-material';

import Log from '~/utils/Log';
import ExportService from '~/services/export.service';
import { dateUtils } from '~/utils/dateUtils';

class AttachmentPdf extends React.Component {
  handleClick = () => {
    Log.productAnalyticsEvent(
      'Download pdf attachment',
      Log.FEATURE.OTHER_FEATURE,
    );
    ExportService.downloadUrlWithCustomName(
      this.props.url,
      'VESTIGAS_' +
        dateUtils.getFormattedDate_safe(
          new Date(),
          dateUtils.DATE_FORMAT.YYYYMMDD_HHMMSS,
        ) +
        '.pdf',
    );
  };

  render() {
    return (
      <div
        className="flex-c-c h-full w-full cursor-pointer"
        onClick={this.handleClick}
      >
        <PictureAsPdfIcon className="text-white" fontSize="large" />
      </div>
    );
  }
}

export default AttachmentPdf;
