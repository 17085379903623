import { createSlice } from '@reduxjs/toolkit';
import { FEATURE_FLAG } from '~/constants/FeatureFlag';

const companyAccountSlice = createSlice({
  name: 'companyAccount',
  initialState: {
    companyAccount: {},
  },
  reducers: {
    saveCompanyAccount(state, parameter) {
      const { payload } = parameter;

      state.companyAccount = {
        ...payload,
        featureFlags: {
          ...payload.featureFlags,
          // The feature flag sentry_google_analytics_disabled must be set explicitly to false, because undefined is handled differently:
          // In case of undefined, no Cookie Banner is displayed to the user to prevent that users see the Cookie Banner although sentry_google_analytics_disabled is true after being loaded from backend.
          [FEATURE_FLAG.SENTRY_GOOGLE_ANALYTICS_DISABLED]:
            payload.featureFlags?.[
              FEATURE_FLAG.SENTRY_GOOGLE_ANALYTICS_DISABLED
            ] ?? false,
        },
      };
    },
  },
});

const { actions, reducer } = companyAccountSlice;
export const { saveCompanyAccount } = actions;
export default reducer;
