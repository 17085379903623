import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DataGrid } from '@mui/x-data-grid';

import { promiseHandler } from '~/utils/promiseHandler';
import DeliveriesService from '~/services/deliveries.service';
import DeliveryNoteAction from '~/models/deliveries/DeliveryNoteAction';
import DatagridUtils from '~/utils/datagridUtils';
import { LOADING_STATE } from '~/constants/LoadingState';
import Log from '~/utils/Log';
import ArrayUtils from '~/utils/arrayUtils';
import DeliveryNote from '~/models/deliveries/DeliveryNote';
import BrowserUtils from '~/utils/browserUtils';
import LocalStorageService from '~/services/localStorage.service';
import DeliveryStatus from '../deliveries/DeliveryStatus';
import { dateUtils, parseDate } from '~/utils/dateUtils';
import BasicTable from '../BasicTable';
import { ROUTE } from '~/constants/Route';
import ObjectUtils from '~/utils/objectUtils';
import UserService from '~/services/user.service';
import ContextMenu from '../menu/ContextMenu';
import SiteService from '~/services/site.service';
import { InactiveIcon } from '~/components/InactiveIcon';

export default function UserMetricsDetailPanel(props) {
  const columnProps = {
    headerClassName: 'text-14px',
    renderCell: (params) => (
      <span className="text-14px">
        {DatagridUtils.displayCellTooltip(params)}
      </span>
    ),
  };

  const permissionGrantColumns = [
    { field: 'defaultRole', headerName: 'Rolle', width: 150, ...columnProps },
    { field: 'name', headerName: 'Benutzer', width: 200, ...columnProps },
    { field: 'email', headerName: 'E-Mail', width: 300, ...columnProps },
  ];

  const signatureColumns = [
    { field: 'userName', headerName: 'Benutzer', width: 300, ...columnProps },
    {
      field: 'signatureCount',
      headerName: 'Signaturen',
      width: 120,
      ...columnProps,
    },
    { field: 'userId', headerName: 'Benutzer ID', width: 300, ...columnProps },
  ];

  const deliveryColumns = [
    {
      field: DeliveryNote.PROPERTY.STATUS.KEY,
      headerName: DeliveryNote.PROPERTY.STATUS.STRING,
      width: BrowserUtils.isWideScreen() ? 200 : 120,
      sortable: true,
      renderCell(params) {
        const processState = params.value.split(';')?.[0];
        const combinedState = params.value.split(';')?.[2];
        const settledStatus = params.value.split(';')?.[3];

        const cookie = LocalStorageService.getObjectFromLocalStorage(
          LocalStorageService.DELIVERY_LIST,
        );

        const className =
          'w-170px ' + DatagridUtils.getStatusBoxHeight(cookie?.rowHeight);
        let centerIcon = cookie?.rowHeight <= DatagridUtils.ROW_HEIGHT.THIN;
        if (!cookie?.rowHeight) {
          centerIcon = true;
        } // Center icon as default because default row height is thin

        return (
          <div className={className}>
            <DeliveryStatus
              processState={processState}
              combinedState={combinedState}
              settledStatus={settledStatus}
              whiteBackground
              centerIcon={centerIcon}
            />
          </div>
        );
      },
    },
    {
      field: DeliveryNote.PROPERTY.NUMBER.KEY,
      headerName: DeliveryNote.PROPERTY.NUMBER.STRING,
      width: 200,
      sortable: true,
      resizableText: true,
    },
    {
      field: DeliveryNote.PROPERTY.DLN_DATE.KEY,
      headerName: DeliveryNote.PROPERTY.DLN_DATE.STRING,
      type: 'date',
      width: 170,
      sortable: true,
      resizableText: true,
      valueGetter: parseDate,
      renderCell: (params) =>
        dateUtils.getFormattedDateWithoutMidnight_safe(
          params.value,
          dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
        ),
    },
    {
      field: 'invoiceParsedDate',
      headerName: 'Rechnungsprüfung am',
      type: 'date',
      width: 170,
      sortable: true,
      resizableText: true,
      valueGetter: parseDate,
      renderCell: (params) =>
        dateUtils.getFormattedDateWithoutMidnight_safe(
          params.value,
          dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
        ),
    },
    {
      field: 'deliveryNoteSignatureDate',
      headerName: 'Signatur am',
      type: 'date',
      width: 170,
      sortable: true,
      resizableText: true,
      valueGetter: parseDate,
      renderCell: (params) =>
        dateUtils.getFormattedDateWithoutMidnight_safe(
          params.value,
          dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
        ),
    },
    {
      field: 'diffDlnDate',
      headerName: 'Unterschied LFS-Datum',
      width: 150,
      sortable: true,
      resizableText: true,
    },
    {
      field: 'diffInvoiceParsedDate',
      headerName: 'Unterschied Rechnungsprüfung',
      width: 150,
      sortable: true,
      resizableText: true,
    },
    {
      field: DeliveryNote.PROCESS_ROLE.SUPPLIER.KEY,
      headerName: DeliveryNote.PROCESS_ROLE.SUPPLIER.STRING,
      width: 250,
      sortable: true,
      resizableText: true,
    },
    {
      field: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.KEY,
      headerName: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.STRING,
      width: 400,
      sortable: true,
      resizableText: true,
    },
    {
      field: DeliveryNote.PROPERTY.PERMITTED_TO_SITES.KEY,
      headerName: DeliveryNote.PROPERTY.PERMITTED_TO_SITES.STRING,
      width: 400,
      sortable: true,
      resizableText: true,
      renderCell(params) {
        if (!params.value) {
          return '';
        }

        const permittedToSites = params.value
          .split(';;;')
          .map((string) => JSON.parse(string));
        if (permittedToSites.every((site) => site.active)) {
          return DatagridUtils.displayCellTooltipControlled(
            permittedToSites.map((site) => site.name).join(', '),
            permittedToSites.map((site) => site.name).join(', '),
          );
        }

        return (
          <div className="flex-s-c gap-5px">
            {permittedToSites.map((site) => {
              if (site.active) {
                return site.name;
              }

              return (
                <span className="flex-s-c gap-5px">
                  {site.name}
                  <InactiveIcon />
                </span>
              );
            })}
          </div>
        );
      },
    },
    {
      field: DeliveryNote.PROPERTY.PERMITTED_COST_CENTERS.KEY,
      headerName: DeliveryNote.PROPERTY.PERMITTED_COST_CENTERS.STRING,
      width: 400,
      sortable: true,
      resizableText: true,
      renderCell(params) {
        if (!params.value) {
          return '';
        }

        const permittedToCostCenters = params.value
          .split(';;;')
          .map((string) => JSON.parse(string));
        if (permittedToCostCenters.every((costCenter) => costCenter.active)) {
          return DatagridUtils.displayCellTooltipControlled(
            permittedToCostCenters
              .map((costCenter) => costCenter.name)
              .join(', '),
            permittedToCostCenters
              .map((costCenter) => costCenter.name)
              .join(', '),
          );
        }

        return (
          <div className="flex-s-c gap-5px">
            {permittedToCostCenters.map((costCenter) => {
              if (costCenter.active) {
                return costCenter.name;
              }

              return (
                <span className="flex-s-c gap-5px">
                  {costCenter.name}
                  <InactiveIcon />
                </span>
              );
            })}
          </div>
        );
      },
    },
    {
      field: DeliveryNote.PROPERTY.COST_CENTERS.KEY,
      headerName: DeliveryNote.PROPERTY.COST_CENTERS.STRING,
      width: 150,
      sortable: true,
      resizableText: true,
      ...DatagridUtils.customColumnTypeArray(),
    },
    {
      field: DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.KEY,
      headerName: DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.STRING,
      width: 400,
      sortable: true,
      resizableText: true,
    },
    {
      field: DeliveryNote.PROPERTY.MAIN_ARTICLE_TYPE.KEY,
      headerName: DeliveryNote.PROPERTY.MAIN_ARTICLE_TYPE.STRING,
      width: 250,
      sortable: true,
      resizableText: true,
    },
    {
      field: DeliveryNote.PROPERTY.CREATION_DATE.KEY,
      headerName: DeliveryNote.PROPERTY.CREATION_DATE.STRING,
      type: 'date',
      width: 150,
      sortable: true,
      resizableText: true,
      valueGetter: parseDate,
      renderCell: (params) =>
        dateUtils.getFormattedDate_safe(
          params.value,
          dateUtils.DATE_FORMAT.DD_MM_YYYY,
        ),
    },
    {
      field: 'id',
      headerName: 'ID',
      width: 200,
      sortable: true,
      resizableText: true,
    },
  ];

  const history = useHistory();
  const users = useSelector((state) => state.users);
  const deliveryNotes = useSelector((state) => state.deliveryNotes);
  const companies = useSelector((state) => state.companies);
  const [delayedSignatureRows, setDelayedSignatureRows] = React.useState([]);
  const [permissionGrants, setPermissionGrants] = React.useState([]);
  const [signatureRows, setSignatureRows] = React.useState({});
  const [permissionGrantsAreLoading, setPermissionGrantsAreLoading] =
    React.useState(true);
  const [signatureRowsAreLoading, setSignatureRowsAreLoading] =
    React.useState(true);
  const [signatureCount, setSignatureCount] = React.useState(0);
  const [delayedSignatureDates, setDelayedSignatureDates] = React.useState({});
  const [delayedSignatureDatesLoading, setDelayedSignatureDatesLoading] =
    React.useState(LOADING_STATE.NOT_LOADED);
  const [sortModel, setSortModel] = React.useState([
    { field: 'signatureCount', sort: 'desc' },
  ]);
  const [contextMenu, setContextMenu] = React.useState(null);

  const initPermissionGrants = async () => {
    setPermissionGrantsAreLoading(true);

    const [grantedUsers, error] = await promiseHandler(
      UserService.getPermittedUsersOfEntity(
        props.site.id,
        SiteService.getSite,
        SiteService.getSiteById,
      ),
    );

    if (error) {
      throw error;
    }

    setPermissionGrants(
      grantedUsers.map((grantedUser) => {
        return {
          ...grantedUser,
          name: grantedUser.getName(),
          defaultRole: grantedUser.getDefaultRoleNames(),
        };
      }),
    );
    setPermissionGrantsAreLoading(false);
  };

  // Because dlns are sorted by modified date per default when they are loaded from the backend,
  // the latest 50 signed dlns are taken based on the newest modifiedDate.
  const initSignatures = async () => {
    setSignatureRowsAreLoading(true);

    const newRows = {};

    const [chains, error] = await promiseHandler(
      DeliveriesService.getDeliveryNoteChainsByDlnIds(
        props.site.signedDlns.slice(0, 50),
      ),
    );

    if (error) {
      throw error;
    }

    // Duplicates from chains must be removed so that signatures are only tracked once.
    // DeliveriesService.getDeliveryNoteChainsByDlnIds() can return duplicates because init() is called twice in a short amount of time
    // because users.users changes in React.useEffect while users are loaded from backend.
    // As this happens faster than the backend call is closed in getDeliveryNoteChainsByDlnIds(), the duplicate chains are pushed twice to cachedChainsByDlns.
    // Removing the duplicates in init() is only a workaround for this. The bug should actually be solved directly in DeliveriesService.getDeliveryNoteChainsByDlnIds().
    const deliveryNoteActions = ArrayUtils.removeDuplicatesByKey(
      chains,
      '_id',
    ).map((chain) => new DeliveryNoteAction(chain));

    for (const deliveryNoteAction of deliveryNoteActions) {
      if (
        deliveryNoteAction.action ===
          DeliveryNoteAction.ACTION.CONFIRMED_RECIPIENT.STRING ||
        deliveryNoteAction.action ===
          DeliveryNoteAction.ACTION.DECLINED_RECIPIENT.STRING
      ) {
        if (newRows[deliveryNoteAction.user.id] === undefined) {
          newRows[deliveryNoteAction.user.id] = 0;
        }

        newRows[deliveryNoteAction.user.id]++;
      }
    }

    const signatureRows = [];
    let signatureCount = 0;

    for (const entry of ObjectUtils.entries(newRows)) {
      const user = users.users.find((user) => user.id === entry.key);

      signatureCount += entry.value;

      signatureRows.push({
        userName: user?.getName() ?? '-',
        userId: entry.key,
        signatureCount: entry.value,
        id: entry.key,
      });
    }

    setSignatureRows(signatureRows);
    setSignatureCount(signatureCount);
    setSignatureRowsAreLoading(false);
  };

  const initDelayedSignatureDates = async () => {
    setDelayedSignatureDatesLoading(LOADING_STATE.LOADING);

    const delayedSignatureDates = {};

    const [chains, error] = await promiseHandler(
      DeliveriesService.getDeliveryNoteChainsByDlnIds(
        props.site.delayedSignedDlns,
      ),
    );

    if (error) {
      throw error;
    }

    const deliveryNoteActions = chains.map(
      (chain) => new DeliveryNoteAction(chain),
    );

    for (const deliveryNoteAction of deliveryNoteActions) {
      if (
        deliveryNoteAction.action ===
          DeliveryNoteAction.ACTION.CONFIRMED_RECIPIENT.STRING ||
        deliveryNoteAction.action ===
          DeliveryNoteAction.ACTION.DECLINED_RECIPIENT.STRING
      ) {
        if (delayedSignatureDates[deliveryNoteAction.deliveryNoteId]) {
          continue;
        } // Only take first signature

        delayedSignatureDates[deliveryNoteAction.deliveryNoteId] =
          deliveryNoteAction.datetime;
      }
    }

    setDelayedSignatureDates(delayedSignatureDates);
    setDelayedSignatureDatesLoading(LOADING_STATE.SUCCEEDED);
  };

  const initDelayedSignatureRows = () => {
    if (props.site.delayedSignedDlns.length === 0) {
      return [];
    }

    const delayedSignatureRows = props.filteredDeliveryRows.map((row) => {
      const diffInvoiceParsedDate =
        delayedSignatureDates[row.id] && props.invoiceParsedDates[row.number]
          ? dateUtils.formatMillisecondsAsString(
              new Date(delayedSignatureDates[row.id]) -
                new Date(props.invoiceParsedDates[row.number]),
            )
          : null;

      const diffDlnDate =
        delayedSignatureDates[row.id] && row.dlnDate
          ? dateUtils.formatMillisecondsAsString(
              new Date(delayedSignatureDates[row.id]) - new Date(row.dlnDate),
            )
          : null;

      return {
        ...row,
        invoiceParsedDate: props.invoiceParsedDates[row.number],
        deliveryNoteSignatureDate: delayedSignatureDates[row.id],
        diffInvoiceParsedDate,
        diffDlnDate,
      };
    });

    setDelayedSignatureRows(delayedSignatureRows);
  };

  React.useEffect(() => {
    UserService.loadUsers();
  }, [companies.companies]);

  React.useEffect(() => {
    initPermissionGrants().catch((error) => {
      Log.error(
        'Failed to initialize user permissions for user metrics. site id: ' +
          props.site.id,
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to initialize user permissions',
        Log.FEATURE.USER_METRICS,
        Log.TYPE.ERROR,
      );
      setPermissionGrantsAreLoading(false);
    });

    initSignatures().catch((error) => {
      Log.error(
        'Failed to initialize signed delivery notes for user metrics. site id: ' +
          props.site.id,
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to initialize signed delivery notes',
        Log.FEATURE.USER_METRICS,
        Log.TYPE.ERROR,
      );
      setSignatureRowsAreLoading(false);
    });
  }, [users.users]);

  React.useEffect(() => {
    initDelayedSignatureDates().catch((error) => {
      Log.error(
        'Failed to initialize signatures for delayed delivery notes for user metrics. site id: ' +
          props.site.id,
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to initialize signatures for delayed delivery notes',
        Log.FEATURE.USER_METRICS,
        Log.TYPE.ERROR,
      );
      setDelayedSignatureDatesLoading(LOADING_STATE.FAILED);
    });
  }, [JSON.stringify(props.site.delayedSignedDlns)]);

  React.useEffect(
    () => {
      initDelayedSignatureRows();
    },
    [
      JSON.stringify(props.site.delayedSignedDlns),
      JSON.stringify(props.invoiceParsedDates),
      JSON.stringify(delayedSignatureDates),
    ],
    props.filteredDeliveryRows.length,
  );

  const onOpenDeliveryNote = (id) => {
    Log.productAnalyticsEvent('Open delivery note', Log.FEATURE.INVOICE);
    history.push(ROUTE.DELIVERY_NOTE.ROUTE + '/' + id);
  };

  const onOpenDeliveryNoteInNewTab = () => {
    Log.productAnalyticsEvent(
      'Open delivery note in new tab',
      Log.FEATURE.INVOICE,
    );
    BrowserUtils.openNewTab(ROUTE.DELIVERY_NOTE.ROUTE + '/' + contextMenu.id);
    onCloseContextMenu();
  };

  const onOpenContextMenu = (event) => {
    event.preventDefault();

    if (contextMenu) {
      // Repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu. Other native context menus might behave different.
      // With this behavior we prevent contextmenu from the backdrop to re-locale existing context men
      setContextMenu(null);
      return;
    }

    Log.productAnalyticsEvent('Open context menu', Log.FEATURE.MENU);
    setContextMenu({
      mouseX: event.clientX + 2,
      mouseY: event.clientY - 6,
      id: event.currentTarget.dataset.id,
    });
  };

  const onCloseContextMenu = () => {
    Log.productAnalyticsEvent('Close context menu', Log.FEATURE.MENU);
    setContextMenu(null);
  };

  const getDelaySignedDeliveryNotesLoadingState = () => {
    if (deliveryNotes.deliveryNotesLoading === LOADING_STATE.FAILED) {
      return LOADING_STATE.FAILED;
    }

    if (delayedSignatureDatesLoading === LOADING_STATE.LOADING) {
      return LOADING_STATE.LOADING;
    }

    return deliveryNotes.deliveryNotesLoading;
  };

  return (
    <div>
      <div className="flex-s-s gap-30px">
        <div className="w-full">
          <div className="bold mb-10px">Zugeordnete Benutzer</div>

          <DataGrid
            columns={permissionGrantColumns}
            rows={permissionGrants}
            rowHeight={DatagridUtils.ROW_HEIGHT.THIN}
            pageSize={5}
            pageSizeOptions={[5]}
            autoHeight
            loading={permissionGrantsAreLoading}
          />
        </div>
        <div className="w-full">
          <div className="bold mb-10px">Signaturen (letzte 50 Lieferungen)</div>

          <DataGrid
            columns={signatureColumns}
            rows={signatureRows}
            rowHeight={DatagridUtils.ROW_HEIGHT.THIN}
            pageSize={5}
            pageSizeOptions={[5]}
            autoHeight
            loading={
              companies.companiesLoading === LOADING_STATE.LOADING ||
              users.usersLoading === LOADING_STATE.LOADING ||
              signatureRowsAreLoading
            }
            sortModel={sortModel}
            onSortModelChange={setSortModel}
          />

          {signatureCount > 50 ? (
            <div className="flex-s-s text-grey500 gap-5px">
              <div>*</div>
              <div>
                HINWEIS: Die letzten 50 Lieferungen enthalten mehr als 50
                Signaturen. Dies ist der Fall, da Lieferungen mehr als einmal
                signiert werden können.
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="bold mb-10px mt-30px">Nicht signierte Lieferungen</div>

      <BasicTable
        columns={deliveryColumns}
        rows={props.filteredDeliveryRows}
        autoHeight
        disableRowSelectionOnClick
        pageSize={10}
        pageSizeOptions={[10]}
        onRowClick={(rowData) => onOpenDeliveryNote(rowData.row.id)}
        onRowRightClick={onOpenContextMenu}
        loading={deliveryNotes.deliveryNotesLoading}
        localStorageKey={LocalStorageService.USER_METRICS_DELIVERIES}
        sortModel={[{ field: 'creationDate', sort: 'desc' }]}
        withFilterModel
        filterModel={{
          items: [
            {
              id: 1,
              field: DeliveryNote.PROPERTY.STATUS.KEY,
              operator: 'contains',
              value: DeliveryNote.PROCESS_STATE.READY_FOR_OUTPUT.STRING,
            },
            {
              id: 2,
              field: DeliveryNote.PROPERTY.PERMITTED_TO_SITES.KEY,
              operator: 'contains',
              value: props.site.name,
            },
          ],
        }}
        defaultHiddenColumns={[
          'invoiceParsedDate',
          'deliveryNoteSignatureDate',
          'diffDlnDate',
          'diffInvoiceParsedDate',
        ]}
      />
      <ContextMenu
        contextMenu={contextMenu}
        onClose={onCloseContextMenu}
        onOpen={() => onOpenDeliveryNote(contextMenu.id)}
        onOpenInNewTab={onOpenDeliveryNoteInNewTab}
      />

      <div className="bold mb-10px mt-30px">
        Nachträglich signierte Lieferungen
      </div>

      <BasicTable
        columns={deliveryColumns}
        rows={delayedSignatureRows}
        autoHeight
        disableRowSelectionOnClick
        pageSize={10}
        pageSizeOptions={[10]}
        onRowClick={(rowData) => onOpenDeliveryNote(rowData.row.id)}
        onRowRightClick={onOpenContextMenu}
        loading={getDelaySignedDeliveryNotesLoadingState()}
        localStorageKey={LocalStorageService.USER_METRICS_DELIVERIES}
        sortModel={[{ field: 'creationDate', sort: 'desc' }]}
        withFilterModel
        filterModel={{
          items: [
            {
              id: 1,
              field: 'id',
              operator: 'isAnyOf',
              value: props.site.delayedSignedDlns,
            },
          ],
        }}
      />
      <ContextMenu
        contextMenu={contextMenu}
        onClose={onCloseContextMenu}
        onOpen={() => onOpenDeliveryNote(contextMenu.id)}
        onOpenInNewTab={onOpenDeliveryNoteInNewTab}
      />

      <div className="bold mb-10px mt-30px">Signierte Lieferungen</div>

      <BasicTable
        columns={deliveryColumns}
        rows={props.filteredDeliveryRows}
        autoHeight
        disableRowSelectionOnClick
        pageSize={10}
        pageSizeOptions={[10]}
        onRowClick={(rowData) => onOpenDeliveryNote(rowData.row.id)}
        onRowRightClick={onOpenContextMenu}
        loading={deliveryNotes.deliveryNotesLoading}
        localStorageKey={LocalStorageService.USER_METRICS_DELIVERIES}
        sortModel={[{ field: 'creationDate', sort: 'desc' }]}
        withFilterModel
        filterModel={{
          items: [
            {
              id: 1,
              field: DeliveryNote.PROPERTY.STATUS.KEY,
              operator: 'contains',
              value: DeliveryNote.PROCESS_STATE.DELIVERED.STRING,
            },
            {
              id: 2,
              field: DeliveryNote.PROPERTY.PERMITTED_TO_SITES.KEY,
              operator: 'contains',
              value: props.site.name,
            },
          ],
        }}
        defaultHiddenColumns={[
          'invoiceParsedDate',
          'deliveryNoteSignatureDate',
          'diffDlnDate',
          'diffInvoiceParsedDate',
        ]}
      />
      <ContextMenu
        contextMenu={contextMenu}
        onClose={onCloseContextMenu}
        onOpen={() => onOpenDeliveryNote(contextMenu.id)}
        onOpenInNewTab={onOpenDeliveryNoteInNewTab}
      />
    </div>
  );
}
