import { createSlice } from '@reduxjs/toolkit';
import ArrayUtils from '~/utils/arrayUtils';
import { LOADING_STATE } from '~/constants/LoadingState';

const companiesSlice = createSlice({
  name: 'companies',
  initialState: {
    companies: [],
    companiesLoading: LOADING_STATE.NOT_LOADED,
    companyLogos: [],
  },
  reducers: {
    replaceCompanies(state, parameter) {
      const { payload } = parameter;

      state.companies = ArrayUtils.sortByKey(payload, 'name');
      state.companiesLoading = LOADING_STATE.SUCCEEDED;
    },
    replaceCompany(state, parameter) {
      const { payload } = parameter;

      const newCompanies = ArrayUtils.removeByKey(
        state.companies,
        'id',
        payload.id,
      );
      newCompanies.push(payload);

      state.companies = newCompanies;
    },
    saveCompanyLogo(state, parameter) {
      const { payload } = parameter;

      state.companyLogos = [...state.companyLogos, payload];
    },
    removeCompanyLogo(state, parameter) {
      const { payload } = parameter;

      state.companyLogos = ArrayUtils.removeByKey(
        state.companyLogos,
        'companyId',
        payload,
      );
    },
    setCompaniesLoading(state, parameter) {
      const { payload } = parameter;

      state.companiesLoading = payload;
    },
  },
});

const { actions, reducer } = companiesSlice;
export const {
  removeCompanyLogo,
  replaceCompanies,
  replaceCompany,
  saveCompanyLogo,
  setCompaniesLoading,
} = actions;
export default reducer;
