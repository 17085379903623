import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

/**
 * ErrorBoundary component that wraps its children with a Sentry.ErrorBoundary.
 *
 * @param {Object} props - The props for the ErrorBoundary component.
 * @param {ReactNode} props.children - The children to be wrapped.
 * @param {ReactNode} props.fallback - The fallback component to be rendered when an error occurs.
 * @return {ReactElement} The ErrorBoundary component.
 */
export const ErrorBoundary = ({ children, fallback }) => (
  <SentryErrorBoundary fallback={fallback} showDialog={false}>
    {children}
  </SentryErrorBoundary>
);
