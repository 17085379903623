import React from 'react';

import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { LOADING_STATE } from '~/constants/LoadingState';
import { GridToolbar } from '~/components/interaction/GridToolbar';
import Log from '~/utils/Log';
import { withErrorBoundary } from '~/ui/atoms';
import {
  LightTooltip,
  MissingPermissionsTooltip,
} from '~/utils/componentUtils';
import { Spinner } from '~/components/Spinner';
import PermissionGrantService from '~/services/permissionGrant.service';
import Permissions from '~/models/masterdata/Permissions';
import { connect } from 'react-redux';
import DatagridUtils from '~/utils/datagridUtils';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import { promiseHandler } from '~/utils/promiseHandler';
import ToastService from '~/services/toast.service';
import UserService from '~/services/user.service';
import UserGroupService from '~/services/userGroup.service';
import { PROMISE_STATUS } from '~/constants/AsyncOperationConsts';
import PermissionForm from './PermissionForm';
import PermissionGrantDialog from './PermissionGrantDialog';
import UserUtils from '~/utils/userUtils';
import PromiseUtils from '~/utils/promiseUtils';

const mapStateToProps = (state) => ({
  users: state.users.users,
  sites: state.sites.sites,
  costCenters: state.costCenters.costCenters,
  vehicles: state.vehicles.vehicles,
  companies: state.companies.companies,
  organisationalGroups: state.organisationalGroups.organisationalGroups,
  userGroups: state.userGroups.userGroups,
});

class PermissionGrantEntityTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rowSelectionModel: [],
      open: false,
      deletingPermissionGrants: false,
      permissionFormOpen: false,
      permissionGrantId: null,
      permissions: new Permissions(),
      rows: [],
      submittingPermissionForm: false,
    };
  }

  componentDidMount() {
    this.initRows();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.permissionGrantsFrom) !==
      JSON.stringify(prevProps.permissionGrantsFrom)
    ) {
      this.initRows();
    }
  }

  async initRows() {
    const promises = this.props.permissionGrantsFrom.map((permissionGrant) =>
      this.getSubjectName(
        permissionGrant.subjectType,
        permissionGrant.subjectId,
      ),
    );

    const [results, error] = await promiseHandler(Promise.allSettled(promises));

    if (error) {
      Log.error('Failed to load subjects.', error);
      Log.productAnalyticsEvent(
        'Failed to load subjects',
        Log.FEATURE.PERMISSIONS,
        Log.TYPE.ERROR,
      );
      return;
    }

    const rows = [];
    for (const [index, result] of results.entries()) {
      const permissionGrantFrom = this.props.permissionGrantsFrom[index];

      rows.push({
        id: permissionGrantFrom.id,
        permissions: {
          permissions: permissionGrantFrom.permissions,
          permissionGrantId: permissionGrantFrom.id,
        },
        defaultRole: permissionGrantFrom.permissions.getDefaultRoleName(),
        subjectType: permissionGrantFrom.getSubjectTypeString(),
        subjectName:
          result.status === PROMISE_STATUS.FULFILLED ? result.value : '...',
        subjectId: permissionGrantFrom.subjectId,
      });
    }

    this.setState({
      rows,
    });
  }

  onRowSelectionModelChange = (event) => {
    Log.info(
      'Change selection value of permission grants',
      { from: this.state.rowSelectionModel, to: event },
      Log.BREADCRUMB.SELECTION_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      '(De)select granted permissions',
      Log.FEATURE.PERMISSIONS,
    );

    this.setState({
      rowSelectionModel: event,
    });
  };
  addPermissionGrant = () => {
    Log.productAnalyticsEvent('Open form', Log.FEATURE.PERMISSION_GRANT_DIALOG);
    this.setState({
      open: true,
    });
  };
  deletePermissionGrant = async () => {
    if (this.state.rowSelectionModel.length === 0) {
      ToastService.warning([
        'Bitte wähle mindestens eine Berechtigung aus, die gelöscht werden soll.',
      ]);
      Log.productAnalyticsEvent(
        'No permission selected to be deleted',
        Log.FEATURE.PERMISSIONS,
      );
      return;
    }

    this.setState({
      deletingPermissionGrants: true,
    });

    Log.info(
      'Delete permission grants',
      { rowSelectionModel: this.state.rowSelectionModel },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent('Delete', Log.FEATURE.PERMISSIONS);

    const promises = [];

    for (const id of this.state.rowSelectionModel) {
      const promise = PermissionGrantService.deletePermissionGrant(id);
      promises.push(promise);
    }

    PromiseUtils.allResolved(promises)
      .then((results) => {
        ToastService.success(['Berechtigungen wurden gelöscht.']);

        this.setState({
          deletingPermissionGrants: false,
        });

        this.props.refreshData();
      })
      .catch((error) => {
        ToastService.error([
          'Berechtigungen konnten nicht vollständig gelöscht werden.',
        ]);
        Log.productAnalyticsEvent(
          'Failed to delete',
          Log.FEATURE.PERMISSIONS,
          Log.TYPE.ERROR,
        );
        this.setState({
          deletingPermissionGrants: false,
        });
        Log.error('Failed to delete permissions.', error);
      });
  };
  openPermissionForm = (params) => {
    Log.productAnalyticsEvent('Open form', Log.FEATURE.PERMISSIONS);
    this.setState({
      permissionFormOpen: true,
      permissions: params.value.permissions,
      permissionGrantId: params.value.permissionGrantId,
    });
  };
  permissionFormSuccess = async (permissions) => {
    if (!this.state.permissionGrantId) {
      return;
    }

    this.setState({
      submittingPermissionForm: true,
    });

    const permissionGrant = this.props.permissionGrantsFrom.find(
      (permissionGrant) => permissionGrant.id === this.state.permissionGrantId,
    );

    if (permissionGrant) {
      const [isDuplicate, error] = await promiseHandler(
        PermissionGrantService.isDuplicatePermissionGrant(
          PermissionGrant.TYPE.ENTITY,
          permissionGrant.subjectType,
          permissionGrant.subjectId,
          permissionGrant.entityType,
          permissionGrant.entityId,
          permissions,
        ),
      );

      if (error) {
        Log.error('Failed to detect duplicate permission grant.', error);
        Log.productAnalyticsEvent(
          'Failed to detect duplicate permission grant',
          Log.FEATURE.PERMISSIONS,
          Log.TYPE.ERROR,
        );
      }

      if (isDuplicate) {
        ToastService.warning([
          'Die Berechtigung wurde nicht aktualisiert, da diese Berechtigung bereits vorhanden ist.',
        ]);
        this.setState({
          permissionFormOpen: false,
          submittingPermissionForm: false,
        });
        return;
      }
    } else {
      Log.error(
        'Failed to find permission grant. id: ' + this.state.permissionGrantId,
      );
      Log.productAnalyticsEvent(
        'Failed to find permission grant',
        Log.FEATURE.PERMISSIONS,
        Log.TYPE.ERROR,
      );
    }

    const [response, error2] = await promiseHandler(
      PermissionGrantService.updatePermissionGrant(
        this.state.permissionGrantId,
        {
          permissions: permissions.getBackendPermissions(),
        },
      ),
    );

    if (error2) {
      ToastService.httpError(
        ['Berechtigung konnte nicht aktualisiert werden.'],
        error2.response,
      );
      Log.error(
        'Failed to updated permission grant. id: ' +
          this.state.permissionGrantId,
        error2,
      );
      Log.productAnalyticsEvent(
        'Failed to update',
        Log.FEATURE.PERMISSIONS,
        Log.TYPE.ERROR,
      );
    }

    ToastService.success(['Berechtigung wurden aktualisiert.']);

    this.setState({
      permissionFormOpen: false,
      submittingPermissionForm: false,
    });

    this.props.refreshData();
  };
  permissionFormAbort = () => {
    this.setState({
      permissionFormOpen: false,
    });
  };
  closeForm = () => {
    this.setState({
      open: false,
    });
  };

  getColumns() {
    return [
      {
        field: 'permissions',
        headerName: '',
        width: 100,
        sortable: true,
        filterable: false,
        renderCell: (params) => {
          return (
            <>
              <LightTooltip title="Vergebene Berechtigungen einsehen">
                <Button
                  variant="outlined"
                  color="primary"
                  className="h-30px"
                  onClick={() => this.openPermissionForm(params)}
                >
                  Details
                </Button>
              </LightTooltip>
              <PermissionForm
                title="Vergebene Berechtigungen einsehen"
                open={this.state.permissionFormOpen}
                permissions={this.state.permissions}
                formSuccess={this.permissionFormSuccess}
                formAbort={this.permissionFormAbort}
                submittingForm={this.state.submittingPermissionForm}
                // Set opacity of modal manually because otherwise the backdrop of the form inside the table is too dark.
                opacity={0.1}
              />
            </>
          );
        },
      },
      {
        field: 'defaultRole',
        headerName: 'Berechtigung als',
        width: 200,
        sortable: true,
      },
      {
        field: 'subjectType',
        headerName: 'Berechtigung für',
        width: 200,
        sortable: true,
      },
      {
        field: 'subjectName',
        headerName: 'Name',
        width: 250,
        sortable: true,
        renderCell: DatagridUtils.displayCellTooltip,
      },
      { field: 'subjectId', headerName: 'ID', width: 350, sortable: true },
    ];
  }

  async getSubjectName(subjectType, subjectId) {
    switch (subjectType) {
      case PermissionGrant.SUBJECT_TYPE.USER.KEY: {
        const [user, error] = await promiseHandler(
          UserService.getUserById(subjectId),
        );
        if (error) {
          throw error;
        }

        return user.email;
      }

      case PermissionGrant.SUBJECT_TYPE.USER_GROUP.KEY: {
        const [userGroup, error7] = await promiseHandler(
          UserGroupService.getUserGroupById(subjectId),
        );
        if (error7) {
          throw error7;
        }

        return userGroup.name;
      }

      default: {
        Log.error(
          null,
          new EnumValueNotFoundException('Invalid subject type: ' + subjectId),
        );
        return '...';
      }
    }
  }

  getPermissionGrantButton() {
    if (!UserUtils.isPermissionGrantAllowedUser()) {
      return (
        <MissingPermissionsTooltip>
          <Button
            variant="outlined"
            color="primary"
            className="mt-10px"
            onClick={this.addPermissionGrant}
            disabled
          >
            Berechtigungen vergeben
          </Button>
        </MissingPermissionsTooltip>
      );
    }

    return (
      <LightTooltip title="Neue Benutzer oder Benutzer-Gruppen berechtigen.">
        <Button
          variant="outlined"
          color="primary"
          className="mt-10px"
          onClick={this.addPermissionGrant}
        >
          Berechtigungen vergeben
        </Button>
      </LightTooltip>
    );
  }

  getPermissionDeleteButton() {
    if (!UserUtils.isPermissionGrantAllowedUser()) {
      return (
        <MissingPermissionsTooltip>
          <Button
            variant="outlined"
            color="secondary"
            className="mt-10px"
            onClick={this.deletePermissionGrant}
            disabled
          >
            {this.state.deletingPermissionGrants ? (
              <Spinner title="Berechtigungen löschen..." />
            ) : (
              'Berechtigungen löschen'
            )}
          </Button>
        </MissingPermissionsTooltip>
      );
    }

    return (
      <LightTooltip title="Berechtigungen von ausgewählten Benutzern oder Benutzer-Gruppen löschen.">
        <Button
          variant="outlined"
          color="secondary"
          className="mt-10px"
          onClick={this.deletePermissionGrant}
          disabled={this.state.deletingPermissionGrants}
        >
          {this.state.deletingPermissionGrants ? (
            <Spinner title="Berechtigungen löschen..." />
          ) : (
            'Berechtigungen löschen'
          )}
        </Button>
      </LightTooltip>
    );
  }

  render() {
    return (
      <>
        <h3 className="main-text">{this.props.title}</h3>
        <div className="border">
          <DataGrid
            rows={this.state.rows}
            columns={this.getColumns()}
            pageSize={5}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={this.onRowSelectionModelChange}
            rowSelectionModel={this.state.rowSelectionModel}
            autoHeight
            rowHeight={DatagridUtils.ROW_HEIGHT.THIN}
            loading={
              this.state.deletingPermissionGrants ||
              this.props.loading === LOADING_STATE.LOADING
            }
            columnVisibilityModel={{
              subjectId: UserUtils.isVestigasAccount(),
            }}
            slots={{
              toolbar: () => <GridToolbar noColumnsButton noExportButton />,
              noRowsOverlay: () => (
                <div className="flex-c-c h-full w-full">
                  {this.props.loading === LOADING_STATE.FAILED
                    ? 'Berechtigungen konnten nicht geladen werden.'
                    : 'Keine Einträge'}
                </div>
              ),
            }}
          />
        </div>
        <div className="flex-sb-c">
          {this.getPermissionDeleteButton()}
          {this.getPermissionGrantButton()}
        </div>
        <PermissionGrantDialog
          open={this.state.open}
          loadData={this.props.refreshData}
          closeForm={this.closeForm}
          defaultSubjects={this.props.defaultSubjects}
          defaultSubjectType={this.props.defaultSubjectType}
          defaultEntities={this.props.defaultEntities}
          defaultEntityType={this.props.defaultEntityType}
          fixedPicker={this.props.fixedPicker}
        />
      </>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(PermissionGrantEntityTable),
  'Berechtigungen konnten nicht geladen werden.',
);
