import ArrayUtils from '~/utils/arrayUtils';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

export default class Article {
  constructor(article) {
    this.id = article?.id ?? '';
    this.articleId = article?.article_id ?? '';
    this.name = article?.name ?? '';
    this.description = article?.description ?? '';
    this.ean = article?.ean ?? '';
    // this.defaultUnits = article?.default_units ?? [];
    this.product = article?.product ?? {};
    this.handling = article?.handling ?? {};
    this.additionalPartyData = article?.additional_data ?? {};
    // this.linkedCategories = article?.linked_categories ?? [];
    this.equivalentArticles = article?.equivalent_articles ?? [];
    this.active = article?.is_active === undefined ? true : article?.is_active;
    this.virtual =
      article?.is_virtual === undefined ? false : article?.is_virtual;
    this.ownerCompanyAccounts = article?.owner_company_accounts ?? [];
    this.ownerCompanies = article?.owner_companies ?? [];
    this.access = article?.access ?? Article.ACCESS.PRIVATE.KEY;
    // this.accessCompanies = article?.access_companies ?? [];
    // this.accessUsers = article?.access_users ?? [];
    this.filterCompanies = article?.filter_companies ?? [];
    this.filterSites = article?.filter_sites ?? [];
    this.filterCostCenters = article?.filter_acc_refs ?? [];
    // this.filterOrganisationalUnits = article?.filter_ous ?? [];
  }

  static getDifferentValues(articleA, articleB) {
    const differentValues = [];

    if (articleA?.id !== articleB?.id) {
      differentValues.push('ID');
    }

    if (articleA?.articleId !== articleB?.articleId) {
      differentValues.push('Artikel-ID');
    }

    if (articleA?.name !== articleB?.name) {
      differentValues.push('Name');
    }

    if (articleA?.active !== articleB?.active) {
      differentValues.push('Aktiv');
    }

    if (articleA?.description !== articleB?.description) {
      differentValues.push('Beschreibung');
    }

    if (articleA?.ean !== articleB?.ean) {
      differentValues.push('EAN');
    }

    if (articleA?.virtual !== articleB?.virtual) {
      differentValues.push('Generischer Artikel');
    }

    if (articleA?.access !== articleB?.access) {
      differentValues.push('Berechtigung');
    }

    const differentEquivalentArticles = ArrayUtils.getDifference(
      articleA?.equivalentArticles,
      articleB?.equivalentArticles,
    );
    if (
      differentEquivalentArticles[0].length > 0 ||
      differentEquivalentArticles[1].length > 0
    ) {
      differentValues.push('Ersetzbare Artikel');
    }

    const differentOwnerCompanies = ArrayUtils.getDifference(
      articleA?.ownerCompanies,
      articleB?.ownerCompanies,
    );
    if (
      differentOwnerCompanies[0].length > 0 ||
      differentOwnerCompanies[1].length > 0
    ) {
      differentValues.push('Zuordnung zu Firmen');
    }

    const differentFilterCompanies = ArrayUtils.getDifference(
      articleA?.filterCompanies,
      articleB?.filterCompanies,
    );
    if (
      differentFilterCompanies[0].length > 0 ||
      differentFilterCompanies[1].length > 0
    ) {
      differentValues.push('Suchbar in Firmen');
    }

    const differentFilterSites = ArrayUtils.getDifference(
      articleA?.filterSites,
      articleB?.filterSites,
    );
    if (
      differentFilterSites[0].length > 0 ||
      differentFilterSites[1].length > 0
    ) {
      differentValues.push('Suchbar in Standorten');
    }

    const differentFilterCostCenters = ArrayUtils.getDifference(
      articleA?.filterCostCenters,
      articleB?.filterCostCenters,
    );
    if (
      differentFilterCostCenters[0].length > 0 ||
      differentFilterCostCenters[1].length > 0
    ) {
      differentValues.push('Suchbar in Kostenstellen');
    }

    return differentValues;
  }

  static getAccessString(accessKey) {
    const access = Object.keys(Article.ACCESS).find(
      (x) => Article.ACCESS[x].KEY === accessKey,
    );

    if (!access) {
      throw new EnumValueNotFoundException('invalid access: ' + accessKey);
    }

    return Article.ACCESS[access].STRING;
  }

  static getAccessEnum() {
    return Object.keys(Article.ACCESS).map((x) => {
      return {
        id: Article.ACCESS[x].KEY,
        name: Article.ACCESS[x].STRING,
        description: Article.ACCESS[x].DESCRIPTION,
      };
    });
  }

  static ACCESS = {
    PRIVATE: {
      KEY: 'private',
      STRING: 'Privat',
      DESCRIPTION:
        'Das Object kann nur von den Ownern gesehen werden (definiert über companies bzw. company_accounts) oder allen Unternehmen/Nutzern in access_extern_companies und access_extern_users. Generell ist private auch der Standardwert, wenn ein Zustand auf Grund mangelnder Definition undefiniert sein sollte.',
    },
    PUBLIC: {
      KEY: 'public',
      STRING: 'Öffentlich',
      DESCRIPTION:
        'Das Objekt ist für alle VESTIGAS Unternehmen/Nutzern sichtbar; access_extern_companies und access_extern_users sind somit wirkungslos.',
    },
    INHERITED_ALL: {
      KEY: 'inherited_all',
      STRING: 'Vererbung (alle)',
      DESCRIPTION:
        'Das Objekt ist sichtbar, falls alle übergeordneten Objekte sichtbar/public sind. Der Standardwert ist private.',
    },
    INHERITED_ANY: {
      KEY: 'inherited_any',
      STRING: 'Vererbung (mind. eins)',
      DESCRIPTION:
        'Das Objekt ist sichtbar, falls eines der übergeordneten Objekte sichtbar/public ist. Der Standardwert ist private.',
    },
  };
  static IS_ACTIVE = {
    YES: 'Ja',
    NO: 'Nein',
  };
}
