import { useEffect, useReducer } from 'react';

import DeliveriesService from '~/services/deliveries.service';
import SiteService from '~/services/site.service';
import ToastService from '~/services/toast.service';

import ArrayUtils from '~/utils/arrayUtils';
import { promiseHandler } from '~/utils/promiseHandler';

import { initSelectableCostCentersAndSites } from '~/components/menu/SitesCostCentersSelection';

const initialState = {
  form: {
    ignoreAddressInfo: false,
    replaceCostCenters: true,
    replaceSites: true,
    selectedCostCenter: null,
    selectedSite: null,
    shouldAddIdentifierToSite: false,
  },
  isDeleting: false,
  isSubmitting: false,
  sortedCostCenters: [],
  sortedSites: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SORTED_SITES': {
      return {
        ...state,
        sortedSites: action.payload,
      };
    }

    case 'SET_SORTED_COST_CENTERS': {
      return {
        ...state,
        sortedCostCenters: action.payload,
      };
    }

    case 'SET_VALUES': {
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload,
        },
      };
    }

    case 'SET_VALUE': {
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.name]: action.payload.value,
        },
      };
    }

    case 'TOGGLE_SUBMITTING_FORM': {
      return {
        ...state,
        isSubmitting: action.payload,
      };
    }

    case 'TOGGLE_DELETING_FORM': {
      return {
        ...state,
        isDeleting: action.payload,
      };
    }

    case 'RESET_FORM': {
      return {
        ...state,
        form: initialState.form,
      };
    }

    default: {
      return state;
    }
  }
};

export const useMapDirectDeliveryNoteForm = ({
  activeCostCenters,
  activeSites,
  closeForm,
  costCentersLoading,
  defaultSelectedCostCenterId,
  defaultSelectedSiteId,
  deliveryNoteIds,
  formSuccess,
  issuerIds,
  refreshDeliveryNote,
  sitesLoading,
  toSiteSupplierNames,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    init();
  }, [
    costCentersLoading,
    defaultSelectedCostCenterId,
    defaultSelectedSiteId,
    sitesLoading,
  ]);

  const init = async () => {
    const setSortedCostCenters = (payload) =>
      dispatch({
        type: 'SET_SORTED_COST_CENTERS',
        payload,
      });
    const setSortedSites = (payload) =>
      dispatch({
        type: 'SET_SORTED_SITES',
        payload,
      });

    initSelectableCostCentersAndSites({
      activeCostCenters,
      activeSites,
      setSortedCostCenters,
      setSortedSites,
    });

    dispatch({
      type: 'SET_VALUES',
      payload: {
        selectedSite: defaultSelectedSiteId ?? '',
        selectedCostCenter: defaultSelectedCostCenterId ?? '',
      },
    });
  };

  const assignDeliveryNotesToSite = async (
    deliveryNoteIds,
    siteId,
    costCenterId,
    replaceSites = false,
    replaceCostCenters = false,
  ) => {
    const promises = deliveryNoteIds.map((deliveryNoteId) =>
      promiseHandler(
        DeliveriesService.mapDirectDeliveryNotes(
          deliveryNoteId,
          siteId ? [siteId] : [],
          costCenterId ? [costCenterId] : [],
          replaceSites,
          replaceCostCenters,
        ),
      ),
    );

    const results = await Promise.allSettled(promises);

    // promiseHandler returns a possible error as second element of its return value [data, error]
    const hasError = results.some(({ value }) => value[1]);

    if (hasError) {
      ToastService.error([
        'Die Lieferung(en) konnte(n) nicht zugewiesen werden.',
      ]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch({
      type: 'TOGGLE_SUBMITTING_FORM',
      payload: true,
    });

    const {
      ignoreAddressInfo,
      replaceCostCenters,
      replaceSites,
      selectedCostCenter,
      selectedSite,
      shouldAddIdentifierToSite,
    } = state.form;

    const siteId =
      selectedSite === ArrayUtils.EMPTY_DROPDOWN_OPTION ? null : selectedSite;
    const costCenterId =
      selectedCostCenter === ArrayUtils.EMPTY_DROPDOWN_OPTION
        ? null
        : selectedCostCenter;

    await assignDeliveryNotesToSite(
      deliveryNoteIds,
      siteId,
      costCenterId,
      replaceSites,
      replaceCostCenters,
    );

    if (shouldAddIdentifierToSite && siteId) {
      const [, error2] = await promiseHandler(
        SiteService.postSiteIdentifier(
          issuerIds[0],
          toSiteSupplierNames[0],
          siteId,
          ignoreAddressInfo,
        ),
      );

      if (error2) {
        ToastService.error([
          'Die Lieferortbezeichnung konnte nicht für den Standort hinterlegt werden.',
        ]);
      }
    }

    closeFormHandler();
    formSuccess?.();
    refreshDeliveryNote?.();
  };

  const handleCancel = () => {
    closeFormHandler();
  };

  const handleDelete = async (event) => {
    event.preventDefault();
    dispatch({ type: 'TOGGLE_DELETING_FORM', payload: true });

    for (const deliveryNoteId of deliveryNoteIds) {
      const [, error] = await promiseHandler(
        DeliveriesService.removeDeliveryNoteMapping(deliveryNoteId),
      );

      if (error) {
        ToastService.error([
          'Die Zuordnung der Lieferung(en) konnte(n) nicht entfernt werden.',
        ]);
      }
    }

    closeFormHandler();
    formSuccess?.();
    refreshDeliveryNote?.();
  };

  const closeFormHandler = () => {
    dispatch({ type: 'RESET_FORM' });
    closeForm();
  };

  const setValue = (name, value) => {
    dispatch({
      type: 'SET_VALUE',
      payload: {
        name,
        value,
      },
    });

    if (name === 'selectedSite') {
      const selectedSiteCostCenters = state.sortedSites.find(
        ({ id }) => id === value,
      )?.costCenters;
      dispatch({
        type: 'SET_VALUE',
        payload: {
          name: 'selectedCostCenter',
          value: selectedSiteCostCenters?.[0]?.id,
        },
      });
    }

    if (name === 'shouldAddIdentifierToSite' && !value) {
      dispatch({
        type: 'SET_VALUE',
        payload: {
          name: 'ignoreAddressInfo',
          value: false,
        },
      });
    }
  };

  const hasMultipleIssuersOrIdentifiers = () => {
    if (issuerIds.length === 0 || toSiteSupplierNames.length === 0) {
      return true;
    }

    const hasMultipleIssuers = issuerIds.some(
      (issuerId) => issuerId !== issuerIds[0],
    );
    const hasMultipleIdentifiers = toSiteSupplierNames.some(
      (name) => name !== toSiteSupplierNames[0],
    );

    return hasMultipleIssuers || hasMultipleIdentifiers;
  };

  return {
    handleCancel,
    handleDelete,
    handleSubmit,
    hasMultipleIssuersOrIdentifiers,
    setValue,
    state,
  };
};
