import { bindActionCreators } from 'redux';

import {
  setDeliveryList_filterModel,
  setDeliveryList_page,
  setDeliveryList_sortModel,
} from '~/redux/filtersSlice';
import {
  replaceBrowsableDeliveryNotes,
  replaceDeliveryRowPage,
  resetDeliveryRowPages,
  setDeliveryRowCount,
  setDeliveryRowCountLoading,
  setDeliveryRowPagesLoading,
} from '~/redux/deliveryNotesSlice';

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      replaceBrowsableDeliveryNotes,
      replaceDeliveryRowPage,
      resetDeliveryRowPages,
      setDeliveryList_filterModel,
      setDeliveryList_page,
      setDeliveryList_sortModel,
      setDeliveryRowCount,
      setDeliveryRowCountLoading,
      setDeliveryRowPagesLoading,
    },
    dispatch,
  );
