import BasicModal from '../../BasicModal';

import { SiteForm } from '../masterData/site/SiteForm';
import ArticleForm from '../admin/articleMaster/ArticleForm';
import CategoryForm from '../admin/articleMaster/CategoryForm';
import CompanyForm from '../masterData/company/CompanyForm';
import CostCenterForm from '../masterData/costCenter/CostCenterForm';
import CustomerSubscriptionForm from '../admin/customerSubscription/CustomerSubscriptionForm';
import CustomFieldForm from '../admin/customField/CustomFieldForm';
import InvoiceCheckIgnoredArticleForm from '../admin/invoiceCheckIgnoredArticle/InvoiceCheckIgnoredArticleForm';
import OrganisationalGroupForm from '../masterData/organisationalGroup/OrganisationalGroupForm';
import SignatureFieldForm from '../admin/signatureField/SignatureFieldForm';
import UserForm from '../masterData/users/UserForm';
import UserGroupForm from '../masterData/userGroup/UserGroupForm';
import VehicleForm from '../masterData/vehicle/VehicleForm';

import { ErrorBoundary } from '~/ui/atoms';

export const getForm = ({
  closeForm,
  entity,
  onOpenCompany,
  onOpenCostCenter,
  onOpenOrganisationalGroup,
  onOpenSite,
  onOpenUser,
  onOpenUserGroup,
  onOpenVehicle,
  refreshEntities,
  setState,
  state: {
    company,
    costCenter,
    formOpen,
    formType,
    item,
    organisationalGroup,
    site,
    user,
    userGroup,
    vehicle,
  },
}) => {
  let form = null;

  switch (entity) {
    case 'user': {
      form = (
        <UserForm
          open={formOpen}
          closeForm={closeForm}
          user={item}
          type={formType}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onOpenUserGroup={onOpenUserGroup}
          onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
            setState({
              updatedOrganisationalGroups: organisationalGroups,
            })
          }
        />
      );
      break;
    }

    case 'site': {
      form = (
        <SiteForm
          open={formOpen}
          closeForm={closeForm}
          site={item}
          type={formType}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
            setState({
              updatedOrganisationalGroups: organisationalGroups,
            })
          }
        />
      );
      break;
    }

    case 'costCenter': {
      form = (
        <CostCenterForm
          open={formOpen}
          closeForm={closeForm}
          costCenter={item}
          type={formType}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
            setState({
              updatedOrganisationalGroups: organisationalGroups,
            })
          }
        />
      );
      break;
    }

    case 'vehicle': {
      form = (
        <VehicleForm
          open={formOpen}
          closeForm={closeForm}
          vehicle={item}
          type={formType}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
            setState({
              updatedOrganisationalGroups: organisationalGroups,
            })
          }
        />
      );
      break;
    }

    case 'company': {
      form = (
        <CompanyForm
          open={formOpen}
          closeForm={closeForm}
          company={item}
          type={formType}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
            setState({
              updatedOrganisationalGroups: organisationalGroups,
            })
          }
        />
      );
      break;
    }

    case 'organisationalGroup': {
      form = (
        <OrganisationalGroupForm
          open={formOpen}
          closeForm={closeForm}
          organisationalGroup={item}
          type={formType}
          onOpenUser={onOpenUser}
          onOpenSite={onOpenSite}
          onOpenCostCenter={onOpenCostCenter}
          onOpenVehicle={onOpenVehicle}
          onOpenCompany={onOpenCompany}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onOpenUserGroup={onOpenUserGroup}
          onUpdatedUsersChange={(users) => setState({ updatedUsers: users })}
          onUpdatedSitesChange={(sites) => setState({ updatedSites: sites })}
          onUpdatedCostCentersChange={(costCenters) =>
            setState({ updatedCostCenters: costCenters })
          }
          onUpdatedVehiclesChange={(vehicles) =>
            setState({ updatedVehicles: vehicles })
          }
          onUpdatedCompaniesChange={(companies) =>
            setState({ updatedCompanies: companies })
          }
          // Not needed to refresh organisational groups because all organisational groups are already refreshed when submitting the form.
          // onUpdatedOrganisationalGroupsChange={(organisationalGroups) => setState({updatedOrganisationalGroups: organisationalGroups})}
          onUpdatedUserGroupsChange={(userGroups) =>
            setState({ updatedUserGroups: userGroups })
          }
          onRefreshEntities={refreshEntities}
        />
      );
      break;
    }

    case 'userGroup': {
      form = (
        <UserGroupForm
          open={formOpen}
          closeForm={closeForm}
          userGroup={item}
          type={formType}
          onOpenUser={onOpenUser}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onOpenUserGroup={onOpenUserGroup}
          onUpdatedUsersChange={(users) => setState({ updatedUsers: users })}
          onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
            setState({
              updatedOrganisationalGroups: organisationalGroups,
            })
          }
          // Not needed to refresh user groups because all user groups are already refreshed when submitting the form.
          // onUpdatedUserGroupsChange={(userGroups) => setState({updatedUserGroups: userGroups})}
          onRefreshEntities={refreshEntities}
        />
      );
      break;
    }

    case 'customField': {
      form = (
        <CustomFieldForm
          open={formOpen}
          closeForm={closeForm}
          customField={item}
          type={formType}
        />
      );
      break;
    }

    case 'signatureField': {
      form = (
        <SignatureFieldForm
          open={formOpen}
          closeForm={closeForm}
          signatureField={item}
          type={formType}
        />
      );
      break;
    }

    case 'article': {
      form = (
        <ArticleForm
          open={formOpen}
          closeForm={closeForm}
          article={item}
          type={formType}
        />
      );
      break;
    }

    case 'category': {
      form = (
        <CategoryForm
          open={formOpen}
          closeForm={closeForm}
          category={item}
          type={formType}
        />
      );
      break;
    }

    case 'customerSubscription': {
      form = (
        <CustomerSubscriptionForm
          open={formOpen}
          closeForm={closeForm}
          customerSubscription={item}
          type={formType}
        />
      );
      break;
    }

    case 'invoiceCheckIgnoredArticle': {
      form = (
        <InvoiceCheckIgnoredArticleForm
          open={formOpen}
          closeForm={closeForm}
          invoiceCheckIgnoredArticle={item}
          type={formType}
        />
      );
      break;
    }

    default: {
      return null;
    }
  }

  if (user) {
    form = (
      <UserForm
        open={formOpen}
        closeForm={closeForm}
        user={user}
        type={formType}
        onOpenOrganisationalGroup={onOpenOrganisationalGroup}
        onOpenUserGroup={onOpenUserGroup}
        onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
          setState({ updatedOrganisationalGroups: organisationalGroups })
        }
      />
    );
  }

  if (site) {
    form = (
      <SiteForm
        open={formOpen}
        closeForm={closeForm}
        site={site}
        type={formType}
        onOpenOrganisationalGroup={onOpenOrganisationalGroup}
        onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
          setState({ updatedOrganisationalGroups: organisationalGroups })
        }
      />
    );
  }

  if (costCenter) {
    form = (
      <CostCenterForm
        open={formOpen}
        closeForm={closeForm}
        costCenter={costCenter}
        type={formType}
        onOpenOrganisationalGroup={onOpenOrganisationalGroup}
        onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
          setState({ updatedOrganisationalGroups: organisationalGroups })
        }
      />
    );
  }

  if (vehicle) {
    form = (
      <VehicleForm
        open={formOpen}
        closeForm={closeForm}
        vehicle={vehicle}
        type={formType}
        onOpenOrganisationalGroup={onOpenOrganisationalGroup}
        onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
          setState({ updatedOrganisationalGroups: organisationalGroups })
        }
      />
    );
  }

  if (company) {
    form = (
      <CompanyForm
        open={formOpen}
        closeForm={closeForm}
        company={company}
        type={formType}
        onOpenOrganisationalGroup={onOpenOrganisationalGroup}
        onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
          setState({ updatedOrganisationalGroups: organisationalGroups })
        }
      />
    );
  }

  if (organisationalGroup) {
    form = (
      <OrganisationalGroupForm
        open={formOpen}
        closeForm={closeForm}
        organisationalGroup={organisationalGroup}
        type={formType}
        onOpenUser={onOpenUser}
        onOpenSite={onOpenSite}
        onOpenCostCenter={onOpenCostCenter}
        onOpenVehicle={onOpenVehicle}
        onOpenCompany={onOpenCompany}
        onOpenOrganisationalGroup={onOpenOrganisationalGroup}
        onOpenUserGroup={onOpenUserGroup}
        onUpdatedUsersChange={(users) => setState({ updatedUsers: users })}
        onUpdatedSitesChange={(sites) => setState({ updatedSites: sites })}
        onUpdatedCostCentersChange={(costCenters) =>
          setState({ updatedCostCenters: costCenters })
        }
        onUpdatedVehiclesChange={(vehicles) =>
          setState({ updatedVehicles: vehicles })
        }
        onUpdatedCompaniesChange={(companies) =>
          setState({ updatedCompanies: companies })
        }
        // Not needed to refresh organisational groups because all organisational groups are already refreshed when submitting the form.
        // onUpdatedOrganisationalGroupsChange={(organisationalGroups) => setState({updatedOrganisationalGroups: organisationalGroups})}
        onUpdatedUserGroupsChange={(userGroups) =>
          setState({ updatedUserGroups: userGroups })
        }
        onRefreshEntities={refreshEntities}
      />
    );
  }

  if (userGroup) {
    form = (
      <UserGroupForm
        open={formOpen}
        closeForm={closeForm}
        userGroup={userGroup}
        type={formType}
        onOpenUser={onOpenUser}
        onOpenOrganisationalGroup={onOpenOrganisationalGroup}
        onOpenUserGroup={onOpenUserGroup}
        onUpdatedUsersChange={(users) => setState({ updatedUsers: users })}
        onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
          setState({ updatedOrganisationalGroups: organisationalGroups })
        }
        // Not needed to refresh user groups because all user groups are already refreshed when submitting the form.
        // onUpdatedUserGroupsChange={(userGroups) => setState({updatedUserGroups: userGroups})}
        onRefreshEntities={refreshEntities}
      />
    );
  }

  const fallback = (
    <BasicModal title="Fehler" open={formOpen} closeModal={closeForm} fullWidth>
      <div className="h-24 w-full text-center">
        Daten konnten nicht geladen werden.
      </div>
    </BasicModal>
  );

  return <ErrorBoundary fallback={() => fallback}>{form}</ErrorBoundary>;
};
