import { KeyboardArrowRight as KeyboardArrowRightIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import { useHistory } from 'react-router-dom';

export default function LinkButton(props) {
  const history = useHistory();

  const redirect = () => {
    if (props.callback) {
      props.callback();
    }

    history.push(props.link);
  };

  return (
    <Button
      className={'primary-button ' + props.className}
      onClick={redirect}
      endIcon={<KeyboardArrowRightIcon />}
    >
      {props.title}
    </Button>
  );
}
