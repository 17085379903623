import * as React from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@mui/material';

import ArticleMetaData from './ArticleMetaData';
import ArticleManufacturer from './ArticleManufacturer';
import ArticleWeighing from './ArticleWeighing';
import ArticleConcrete from './ArticleConcrete';
import ArticleMaterial from './ArticleMaterial';
import ArticleStatus from './ArticleStatus';
import BasicModal from '../BasicModal';

import ValueGroup from '~/models/deliveries/ValueGroup';

import { DeliveryNoteMetaDataItem } from '../deliveries/deliveryNote/deliveryNoteMetaData/DeliveryNoteMetaDataItem';
import { EditingHistorySafe as EditingHistory } from '../deliveries/deliveryNote/EditingHistorySafe';

import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';
import SignatureRoles from '~/models/masterdata/SignatureRoles';
import BilledItem from '~/models/billingState/BilledItem';
import CustomField from '~/models/customData/CustomField';

import { DeclinedIconLight } from '~/assets/icons';

import ArticleBilledItem from './ArticleBilledItem';

export default function ArticleModal(props) {
  const { customFieldsBulk } = useSelector((state) => state.customFields);

  const grossWeightSet = () => {
    return (
      ValueGroup.getCurrentValue(
        props.article?.weighingInformation?.gross?.weight?.value,
      ) &&
      ValueGroup.getCurrentValue(
        props.article?.weighingInformation?.gross?.weight?.unit,
      )
    );
  };

  const tareWeightSet = () => {
    return (
      ValueGroup.getCurrentValue(
        props.article?.weighingInformation?.tare?.weight?.value,
      ) &&
      ValueGroup.getCurrentValue(
        props.article?.weighingInformation?.tare?.weight?.unit,
      )
    );
  };

  const acceptArticles = [
    {
      acceptArticle: props.article?.acceptArticleSupplier,
      party: SignatureRoles.SIGNATURE_ROLE.SUPPLIER.KEY,
    },
    {
      acceptArticle: props.article?.acceptArticleCarrier,
      party: SignatureRoles.SIGNATURE_ROLE.CARRIER.KEY,
    },
    {
      acceptArticle: props.article?.acceptArticleRecipient,
      party: SignatureRoles.SIGNATURE_ROLE.RECIPIENT.KEY,
    },
    {
      acceptArticle: props.article?.acceptArticleOnBehalfSupplier,
      party: SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_SUPPLIER.KEY,
    },
    {
      acceptArticle: props.article?.acceptArticleOnBehalfCarrier,
      party: SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_CARRIER.KEY,
    },
    {
      acceptArticle: props.article?.acceptArticleOnBehalfRecipient,
      party: SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_RECIPIENT.KEY,
    },
  ];

  return (
    <BasicModal
      title={ValueGroup.getCurrentValue(props.article?.type)}
      open={props.open}
      closeModal={props.handleClose}
      fullWidth
    >
      <Grid
        container
        spacing="20px"
        justifyContent="space-between"
        className="main-text"
      >
        <Grid item xs={12} lg={3}>
          <div className="border-grey400 rounded-5px main-background p-20px h-full">
            <ArticleMetaData
              article={props.article}
              deliveryNote={props.deliveryNote}
              deliveryDirection={props.deliveryDirection}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={9}>
          <div className="flexdir-column gap-20px flex w-full">
            {props.article?.billedItem?.settledStatus ===
            BilledItem.SETTLED_STATUS.FULLY_SETTLED.KEY ? (
              <div className="border-successBase rounded-5px p-20px">
                <ArticleBilledItem
                  article={props.article}
                  billedItem={props.article?.billedItem}
                />
              </div>
            ) : null}
            {props.article?.billedItem?.settledStatus ===
            BilledItem.SETTLED_STATUS.PARTLY_SETTLED.KEY ? (
              <div className="border-warningBase rounded-5px p-20px">
                <ArticleBilledItem
                  article={props.article}
                  billedItem={props.article?.billedItem}
                />
              </div>
            ) : null}
            {acceptArticles.map((acceptArticle) => {
              // Display the declined articles.
              return acceptArticle.acceptArticle?.acceptState ===
                AcceptStateCalculator.ACCEPT_STATE.DECLINED ? (
                <div
                  className="border-grey400 rounded-5px p-20px bg-declined relative"
                  key={acceptArticle.party}
                >
                  <DeclinedIconLight className="t--8px l--8px circle box-shadow h-20px w-20px absolute" />
                  <ArticleStatus
                    party={acceptArticle.party}
                    acceptArticle={acceptArticle.acceptArticle}
                  />
                </div>
              ) : null;
            })}
            {acceptArticles.map((acceptArticle) => {
              // Display the articles that are not declined but have attachments or a comment though.
              // In this case don't display a red background and no declined icon.
              return acceptArticle.acceptArticle?.acceptState !==
                AcceptStateCalculator.ACCEPT_STATE.DECLINED &&
                (acceptArticle.acceptArticle?.attachments?.length > 0 ||
                  acceptArticle.acceptArticle?.reason) ? (
                <div
                  className="border-grey400 rounded-5px p-20px relative"
                  key={acceptArticle.party}
                >
                  <ArticleStatus
                    party={acceptArticle.party}
                    acceptArticle={acceptArticle.acceptArticle}
                  />
                </div>
              ) : null;
            })}
            {ValueGroup.getCurrentValue(props.article?.description) ? (
              <div className="border-grey400 rounded-5px p-20px">
                <DeliveryNoteMetaDataItem
                  category="Beschreibung"
                  value={<EditingHistory value={props.article?.description} />}
                />
              </div>
            ) : null}
            {ValueGroup.getCurrentValue(props.article?.comment) ? (
              <div className="border-grey400 rounded-5px p-20px">
                <DeliveryNoteMetaDataItem
                  category="Kommentar"
                  value={<EditingHistory value={props.article?.comment} />}
                />
              </div>
            ) : null}
            {props.article?.customData?.getLength() > 0 ? (
              <div className="border-grey400 rounded-5px p-20px">
                <DeliveryNoteMetaDataItem
                  category="Weitere Informationen"
                  value={
                    <>
                      {props.article?.customData?.getEntries()?.map((entry) => {
                        const customFieldId = CustomField.getIdFromKey(
                          entry[0],
                        );
                        const customField = customFieldsBulk.find(
                          (customField) => customField.id === customFieldId,
                        );

                        const value =
                          typeof entry[1] === 'object' &&
                          !ValueGroup.isValueGroup(entry[1])
                            ? JSON.stringify(entry[1])
                            : entry[1];
                        return (
                          <div className="flex-s-s wrap">
                            <span className="word-break">
                              {customField?.displayName ?? entry[0]}:{' '}
                            </span>
                            <EditingHistory value={value} />
                          </div>
                        );
                      })}
                    </>
                  }
                />
              </div>
            ) : null}
            {ValueGroup.getCurrentValue(props.article?.manufacturer?.name) ? (
              <div className="border-grey400 rounded-5px p-20px">
                <ArticleManufacturer
                  manufacturer={props.article?.manufacturer}
                />
              </div>
            ) : null}
            {grossWeightSet() || tareWeightSet() ? (
              <div className="border-grey400 rounded-5px p-20px">
                <ArticleWeighing
                  weighing={props.article?.weighingInformation}
                />
              </div>
            ) : null}
            {props.article?.isConcrete() ? (
              <div className="border-left-accent main-background p-20px">
                <ArticleConcrete
                  concrete={props.article?.concrete}
                  delivery={props.article?.delivery}
                />
              </div>
            ) : null}
            {props.article?.concrete?.components
              ? props.article?.concrete?.components.map((material) => {
                  return (
                    <div
                      className="border-grey400 rounded-5px p-20px"
                      key={ValueGroup.getCurrentValue(material.name)}
                    >
                      <ArticleMaterial material={material} initialComponent />
                    </div>
                  );
                })
              : null}
            {props.article?.concrete?.posteriorAdded
              ? props.article?.concrete?.posteriorAdded.map((material) => {
                  return (
                    <div
                      className="border-grey400 rounded-5px p-20px"
                      key={ValueGroup.getCurrentValue(material.name)}
                    >
                      <ArticleMaterial material={material} />
                    </div>
                  );
                })
              : null}
          </div>
        </Grid>
      </Grid>
    </BasicModal>
  );
}
