import { Add as AddIcon } from '@mui/icons-material';
import { Button, ButtonGroup } from '@mui/material';

import TemplateMenu from '~/components/deliveries/deliveryNoteForm/TemplateMenu';
import { LightTooltip } from '~/utils/componentUtils';

/**
 * Renders a button group with a button and a template menu for actions related to the deliveries overview page.
 *
 * @param {string} buttonText - The text to be displayed on the button.
 * @param {function} onClick - The function to be called when the button is clicked.
 * @param {function} onOpenTemplateMenu - The function to be called when the template menu is opened.
 * @param {string} title - The title to be displayed in the tooltip.
 * @return {JSX.Element} The rendered button group.
 */
export const ManualCreationButton = ({
  buttonText,
  onClick,
  onOpenTemplateMenu,
  title,
}) => (
  <LightTooltip title={title}>
    <ButtonGroup color="primary" aria-label="outlined primary button group">
      <Button
        className="primary-button"
        onClick={onClick}
        startIcon={<AddIcon />}
      >
        {buttonText}
      </Button>
      <TemplateMenu
        menuId="dln-overview-template-menu"
        onClick={onOpenTemplateMenu}
      />
    </ButtonGroup>
  </LightTooltip>
);
