import cloneDeep from 'lodash/cloneDeep';

export default class UserAction {
  constructor(userAction) {
    this.userId = userAction.userId ?? null;
    this.userName = userAction.userName ?? null;
    this.userEmail = userAction.userEmail ?? null;
    this.deliveryNoteId = userAction.deliveryNoteId ?? null;
    this.createdAt = userAction.createdAt ?? new Date().toISOString();
  }

  static getEnhanceUserActionsAndDeliveryNotes(
    userActions,
    users,
    deliveryNotes,
    deliveryNoteIds,
    type,
  ) {
    const newUserActions = cloneDeep(userActions);
    const updatedDeliveryNotes = [];

    for (const deliveryNoteId of deliveryNoteIds) {
      const newDeliveryNote = cloneDeep(
        deliveryNotes.find(
          (deliveryNote) => deliveryNote.id === deliveryNoteId,
        ),
      );

      for (const user of users) {
        const userAction = new UserAction({
          userId: user.id,
          userName: user.name,
          userEmail: user.email,
          deliveryNoteId,
        });

        newUserActions[deliveryNoteId] ||= {
          requestSignatures: [],
          shareDeliveryNote: [],
        };
        newUserActions[deliveryNoteId][type].push(userAction);

        if (newDeliveryNote) {
          newDeliveryNote.userActions[type].push(userAction);
        }

        if (newDeliveryNote) {
          updatedDeliveryNotes.push(newDeliveryNote);
        }
      }
    }

    return [newUserActions, updatedDeliveryNotes];
  }

  // Check for which users and when the signatures for the delivery notes have already been requested.
  static getRequestedUsers(userActionsList) {
    const requestedUsers = [];

    for (const userActions of userActionsList) {
      for (const userAction of userActions.requestSignatures) {
        // Check if a signature request has already been found for this user.
        const requestedUserIndex = requestedUsers.findIndex(
          (requestedUser) => requestedUser.id === userAction.userId,
        );

        if (requestedUserIndex === -1) {
          // If no signature request has already been found for this user, add it to the list with the latest date of a signature request.
          requestedUsers.push({
            id: userAction.userId,
            name: userAction.userName,
            email: userAction.userEmail,
            date: userAction.createdAt,
            count: 1,
          });
        } else {
          // If a signature request has already been found for this user and the date of the user action is newer,
          // update the date with the latest date of a signature request.
          if (requestedUsers[requestedUserIndex].date < userAction.createdAt) {
            requestedUsers[requestedUserIndex].date = userAction.createdAt;
            requestedUsers[requestedUserIndex].count++;
          }
        }
      }
    }

    return requestedUsers;
  }

  static TYPE = {
    REQUEST_SIGNATURES: 'requestSignatures',
    SHARE_DELIVERY_NOTE: 'shareDeliveryNote',
  };
}
