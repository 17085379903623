import { DeliveryOutlinedIcon } from '~/assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';
import { ROUTE } from '~/constants/Route';
import NumberTile from './NumberTile';
import { withErrorBoundary } from '~/ui/atoms';
import {
  setDelivery_dateRange,
  setDelivery_predefinedDateRange,
  setDelivery_individualDateRange,
} from '~/redux/filtersSlice';
import FilterNew from '~/models/filters/FilterNew';

const DeliveryTile = (props) => {
  const dispatch = useDispatch();

  const { deliveryNotesLoading } = useSelector(
    ({ deliveryNotes }) => deliveryNotes,
  );
  const selectedPredefinedDateRange = useSelector(
    ({ filters }) => filters.home_selectedPredefinedDateRange,
  );
  const selectedDateRange = useSelector(
    ({ filters }) => filters.home_selectedDateRange,
  );
  const individualDateRangeIsSelected = useSelector(
    ({ filters }) => filters.home_individualDateRange,
  );
  const {
    archiveDeclinedDeliveryNotesCount,
    archiveDeliveryNotesCount,
    archiveDeliveryNotesCountLoading,
    filteredDeliveryNotes,
  } = useSelector(({ home }) => home);

  const openDeliveries = () => {
    dispatch(setDelivery_predefinedDateRange(selectedPredefinedDateRange));
    dispatch(
      setDelivery_dateRange({
        dateRange: selectedDateRange,
        updateCookie: true,
      }),
    );
    dispatch(setDelivery_individualDateRange(individualDateRangeIsSelected));

    if (props.isArchiveMode) {
      FilterNew.removeNonApplicableBackendFiltersForDeliveryPage();
    }
  };

  const loading = props.isArchiveMode
    ? archiveDeliveryNotesCountLoading
    : deliveryNotesLoading;

  const firstNumber = props.isArchiveMode
    ? archiveDeliveryNotesCount
    : filteredDeliveryNotes.length;
  const secondNumber = props.isArchiveMode
    ? archiveDeclinedDeliveryNotesCount
    : filteredDeliveryNotes.filter(
        ({ acceptState }) =>
          acceptState === AcceptStateCalculator.ACCEPT_STATE.DECLINED,
      ).length;

  return (
    <NumberTile
      title={'Lieferungen'}
      icon={<DeliveryOutlinedIcon className="icon-medium" />}
      loading={loading}
      buttonTitle={'Zu den Lieferungen'}
      buttonLink={ROUTE.DELIVERIES.ROUTE}
      buttonCallback={openDeliveries}
      firstNumberTitle={'Gesamt'}
      firstNumber={firstNumber}
      secondNumberTitle={'Abgelehnt'}
      secondNumber={secondNumber}
    />
  );
};

export default withErrorBoundary(DeliveryTile, null);
