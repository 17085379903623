import deLocale from 'date-fns/locale/de';
import moment from 'moment';
import React, { useCallback } from 'react';

import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateRangePickerDay from '@mui/lab/DateRangePickerDay';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MuiDateRangePicker from '@mui/lab/DateRangePicker';

const StyledDateRangePickerDay = styled(DateRangePickerDay)(() => ({
  '.MuiDateRangePickerDay-rangeIntervalPreview': {
    '.MuiDateRangePickerDay-day': {
      '&:hover, &:focus': {
        border: '2px solid #80a9fd',
      },
      color: '#80a9fd',
    },
  },
}));

const DateRangePicker = ({
  archiveMode,
  dateRange,
  disabled,
  maxDate,
  oldestFilteredDlnDate,
  onDateRangeChange,
}) => {
  const setDate = useCallback(
    (params, index) => {
      if (!dateRange[index]) {
        return params;
      }

      const date = moment(dateRange[index]);
      const day = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'][date.day()];

      params.inputProps.value = `${day} ${date.format('DD.MM.YYYY')}`;

      return params;
    },
    [dateRange],
  );

  const renderDay = useCallback(
    (date, dateRangePickerDayProps) => {
      if (archiveMode && moment(date).isBefore(oldestFilteredDlnDate)) {
        return <StyledDateRangePickerDay {...dateRangePickerDayProps} />;
      }

      return <DateRangePickerDay {...dateRangePickerDayProps} />;
    },
    [archiveMode, oldestFilteredDlnDate],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
      <MuiDateRangePicker
        startText="Von"
        endText="Bis"
        value={dateRange}
        onChange={onDateRangeChange}
        disabled={disabled}
        maxDate={maxDate}
        inputFormat="__/__/____"
        calendars={3}
        defaultCalendarMonth={new Date(moment().subtract(2, 'months').format())}
        renderDay={renderDay}
        renderInput={(startProps, endProps) => (
          <>
            <TextField
              InputProps={{ className: 'bg-white w-140px mr-10px' }}
              size="small"
              onKeyDown={(e) => {
                e.preventDefault(); // Disable manual changes
              }}
              {...setDate(startProps, 0)}
            />
            <TextField
              InputProps={{ className: 'bg-white w-140px' }}
              size="small"
              onKeyDown={(e) => {
                e.preventDefault(); // Disable manual changes
              }}
              {...setDate(endProps, 1)}
            />
          </>
        )}
      />
    </LocalizationProvider>
  );
};

export default DateRangePicker;
