export default class UserFeatureFlags {
  constructor(featureFlags) {
    this.accessPermittedSites = featureFlags?.access_permitted_sites ?? false;
    this.concreteDiary = featureFlags?.concrete_diary ?? false;
    this.concreteEditingEnabled =
      featureFlags?.concrete_editing_enabled ?? false;
    this.ocrEnabled = featureFlags?.ocr_enabled ?? false;
    this.lineItemDetailsEditingDisabled =
      featureFlags?.line_item_details_editing_disabled ?? false;
    this.recipientSetArrived = featureFlags?.recipient_set_arrived ?? false;
    this.showOrderAtPreview = featureFlags?.show_order_at_preview ?? false;
    this.userCannotSetAccepted =
      featureFlags?.user_cannot_set_accepted ?? false;
    this.userCannotSetDeclined =
      featureFlags?.user_cannot_set_declined ?? false;
    this.userCannotSetDelivered =
      featureFlags?.user_cannot_set_delivered ?? false;
    this.userMetrics = featureFlags?.user_metrics ?? false;
  }

  static getDifferentValues(userFeatureFlagsA, userFeatureFlagsB) {
    const featureFlagMap = {
      accessPermittedSites:
        'Nutzer kann berechtigte Lieferorte und Kostenstellen einsehen',
      concreteDiary: 'Nutzer hat Zugriff auf Betontagebuch',
      concreteEditingEnabled: 'Nutzer kann Betondaten bearbeiten',
      lineItemDetailsEditingDisabled:
        'Nutzer kann Artikeldetails nicht bearbeiten',
      ocrEnabled: 'Nutzer kann Lieferscheine mittels OCR einlesen',
      recipientSetArrived: 'Empfänger kann das "Angekommen" Event setzen',
      showOrderAtPreview:
        'Nutzer kann Bestellreferenz in Vorschau anzeigen (mobile App)',
      userCannotSetAccepted: 'Nutzer kann das "Angenommen" Event nicht setzen',
      userCannotSetDeclined: 'Nutzer kann das "Abgelehnt" Event nicht setzen',
      userCannotSetDelivered: 'Nutzer kann das "Geliefert" Event nicht setzen',
      userMetrics: 'Nutzer kann Benutzerstatistiken einsehen',
    };

    const compareFeatureFlags = (flagA, flagB, key) => {
      if (flagA?.[key] !== flagB?.[key]) {
        return featureFlagMap[key];
      }

      return null;
    };

    return Object.keys(featureFlagMap)
      .map((key) =>
        compareFeatureFlags(userFeatureFlagsA, userFeatureFlagsB, key),
      )
      .filter(Boolean);
  }
}
