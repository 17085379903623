import React from 'react';

import { AddLocationAlt as AddLocationAltIcon } from '@mui/icons-material';

import { connect } from 'react-redux';
import { withErrorBoundary } from '~/ui/atoms';
import { Button } from '@mui/material';
import Wizard from '~/components/Wizard';
import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';
import ToastService from '~/services/toast.service';
import MasterDataService from '~/services/masterData.service';
import { MissingPermissionsTooltip } from '~/utils/componentUtils';
import UserUtils from '~/utils/userUtils';
import Site from '~/models/masterdata/Site';
import SiteService from '~/services/site.service';
import CreateSiteWizardSiteInformation from './CreateSiteWizardSiteInformation';
import CreateSiteWizardCostCenters from './CreateSiteWizardCostCenters';
import CreateSiteWizardPermissions from './CreateSiteWizardPermissions';
import { validate as uuidValidate } from 'uuid';
import CostCenterService from '~/services/costCenter.service';
import Coordinates from '~/models/masterdata/Coordinates';
import Address from '~/models/masterdata/Address';

const mapStateToProps = (state) => ({
  companies: state.companies,
  costCenters: state.costCenters,
  userinfo: state.userinfo,
});

class CreateSiteWizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      site: this.getDefaultSite(),
      grantPermissions: true,
      grantPermissionsOnCostCenters: true,
      submittingWizard: false,
    };

    this.permissionGrantRef = React.createRef();
  }

  resetWizard() {
    this.setState({
      site: this.getDefaultSite(),
      grantPermissions: true,
      grantPermissionsOnCostCenters: true,
    });
  }

  getDefaultSite() {
    const site = new Site();

    site.company = this.props.userinfo.userinfo.company?.id;
    site.type = Site.getSiteTypes()[0].id;
    site.address.country = Address.DEFAULT_COUNTRY_CODE.DE;

    return site;
  }

  onOpenWizard = () => {
    Log.productAnalyticsEvent('Open create site wizard', Log.FEATURE.WIZARD);

    this.setState({
      open: true,
    });

    this.resetWizard();
  };
  onCloseWizard = () => {
    Log.productAnalyticsEvent('Close create site wizard', Log.FEATURE.WIZARD);

    this.setState({
      open: false,
    });
  };
  setSite = (site) => {
    this.setState({
      site,
    });
  };
  setGrantPermissions = (grantPermissions) => {
    this.setState({
      grantPermissions,
    });
  };
  setGrantPermissionsOnCostCenters = (grantPermissionsOnCostCenters) => {
    this.setState({
      grantPermissionsOnCostCenters,
    });
  };

  getWizardSteps() {
    const wizardSteps = [
      {
        title: 'Standort Informationen',
        preventNextStep: () => {
          if (!this.state.site.company) {
            ToastService.warning(['Bitte wähle eine Firma aus.']);
            Log.productAnalyticsEvent(
              'Missing company',
              Log.FEATURE.WIZARD,
              Log.TYPE.FAILED_VALIDATION,
            );
            return true;
          }
        },
        component: (
          <CreateSiteWizardSiteInformation
            site={this.state.site}
            setSite={this.setSite}
          />
        ),
      },
    ];

    // If the user isn't allowed to update the site, the endpoint to assign the cost centers would return 403.
    if (
      UserUtils.isSiteWriteAllowedUser() &&
      UserUtils.isCostCenterReadAllowedUser()
    ) {
      wizardSteps.push({
        title: 'Kostenstellen',
        component: (
          <CreateSiteWizardCostCenters
            site={this.state.site}
            setSite={this.setSite}
          />
        ),
      });
    }

    if (UserUtils.isPermissionGrantAllowedUser()) {
      wizardSteps.push({
        title: 'Berechtigungen',
        component: (
          <CreateSiteWizardPermissions
            site={this.state.site}
            grantPermissions={this.state.grantPermissions}
            setGrantPermissions={this.setGrantPermissions}
            grantPermissionsOnCostCenters={
              this.state.grantPermissionsOnCostCenters
            }
            setGrantPermissionsOnCostCenters={
              this.setGrantPermissionsOnCostCenters
            }
            ref={this.permissionGrantRef}
          />
        ),
      });
    }

    return wizardSteps.map((step) => {
      return {
        ...step,
        component: <div className="mt-40px mb-30px">{step.component}</div>,
      };
    });
  }

  submitSite = async () => {
    this.setState({
      submittingWizard: true,
    });

    const coordinates = Coordinates.getConvertedCoordinates(
      this.state.site.coords.latitude,
      this.state.site.coords.longitude,
    );

    const body = {
      name: this.state.site.name,
      type: this.state.site.type,
      is_active: this.state.site.active,
      address: {
        street_name: this.state.site.address.streetName,
        building_number: this.state.site.address.buildingNumber,
        post_code: this.state.site.address.postCode,
        city: this.state.site.address.city,
        country: this.state.site.address.country,
      },
      coords: {
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
      },
      company_id: this.state.site.company,
    };

    if (
      MasterDataService.propertiesAreMissing(
        body,
        ['company_id'],
        Log.FEATURE.WIZARD,
      )
    ) {
      this.setState({
        submittingWizard: false,
      });
      return false;
    }

    Log.info('Submit create site wizard', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit create site wizard', Log.FEATURE.WIZARD);

    const [siteId, error] = await promiseHandler(SiteService.createSite(body));

    if (error) {
      Log.error('Failed to create site.', error);
      Log.productAnalyticsEvent(
        'Failed to create site',
        Log.FEATURE.WIZARD,
        Log.TYPE.ERROR,
      );

      this.setState({
        submittingWizard: false,
      });

      return false;
    }

    // Set the cost centers active that have been selected via "Erstellen" but are currently inactive.
    const inactiveCostCenters = this.state.site.costCenters.filter(
      (costCenterId) => {
        const costCenter = this.props.costCenters.costCenters.find(
          (costCenter) => costCenter.id === costCenterId,
        );
        return costCenter && !costCenter.active;
      },
    );

    for (const inactiveCostCenter of inactiveCostCenters) {
      const [costCenterId, error2] = await promiseHandler(
        CostCenterService.updateCostCenter(inactiveCostCenter, {
          is_active: true,
        }),
      );

      if (error2) {
        ToastService.httpError(
          [ToastService.MESSAGE.COST_CENTER_UPDATE_FAILED],
          error2.response,
        );
        Log.error('Failed to update cost center.', error2);
        Log.productAnalyticsEvent(
          'Failed to update cost center',
          Log.FEATURE.WIZARD,
          Log.TYPE.ERROR,
        );
      }
    }

    const newCostCenters = this.state.site.costCenters.filter(
      (costCenterId) => !uuidValidate(costCenterId),
    ); // Determine the new cost centers that should be created.
    const existingCostCenters = this.state.site.costCenters.filter(
      (costCenterId) => uuidValidate(costCenterId),
    );

    for (let index = 0; index < newCostCenters.length; index++) {
      const [costCenterId, error3] = await promiseHandler(
        CostCenterService.createNewCostCenter({
          name: newCostCenters[index],
          company_id: this.state.site.company,
        }),
      );

      if (error3) {
        if (error.response.status === 409) {
          ToastService.httpError(
            [ToastService.MESSAGE.COST_CENTER_CREATION_FAILED_DUPLICATE],
            error3.response,
          );
          Log.productAnalyticsEvent(
            'Failed to create duplicate',
            Log.FEATURE.WIZARD,
            Log.TYPE.ERROR,
          );
        } else {
          ToastService.httpError(
            [ToastService.MESSAGE.COST_CENTER_CREATION_FAILED],
            error3.response,
          );
          Log.productAnalyticsEvent(
            'Failed to create',
            Log.FEATURE.WIZARD,
            Log.TYPE.ERROR,
          );
          Log.error('Failed to create cost center.', error3);
        }
      } else {
        newCostCenters[index] = costCenterId; // Replace the cost center list with the actual IDs to enable the assignment of the new cost centers to the site.
      }
    }

    const assignedCostCenters = [...newCostCenters, ...existingCostCenters];

    if (assignedCostCenters.length > 0) {
      const [response4, error4] = await promiseHandler(
        SiteService.updateAccountingReferencesOfSite(
          siteId,
          assignedCostCenters,
        ),
      );

      if (error4) {
        ToastService.httpError(
          [ToastService.MESSAGE.ACCOUNTING_REFERENCE_ASSIGNMENT_FAILED],
          error4.response,
        );
        Log.productAnalyticsEvent(
          'Failed to update assigned cost centers',
          Log.FEATURE.SITE,
          Log.TYPE.ERROR,
        );
      }
    }

    if (
      this.state.grantPermissions &&
      UserUtils.isPermissionGrantAllowedUser()
    ) {
      const [response5, error5] = await promiseHandler(
        this.permissionGrantRef.current.submit(siteId),
      );

      if (error5) {
        Log.error('Failed to grant permissions.', error5);
        ToastService.error([
          'Berechtigungen konnten nicht vollständig vergeben werden.',
        ]);
        Log.productAnalyticsEvent(
          'Failed to grant permissions',
          Log.FEATURE.WIZARD,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingWizard: false,
        });
        return false;
      }

      if (this.state.grantPermissionsOnCostCenters) {
        const [response6, error6] = await promiseHandler(
          this.permissionGrantRef.current.submitCostCenters(
            assignedCostCenters,
          ),
        );

        if (error6) {
          Log.error('Failed to grant permissions.', error6);
          ToastService.error([
            'Berechtigungen konnten nicht vollständig vergeben werden.',
          ]);
          Log.productAnalyticsEvent(
            'Failed to grant permissions',
            Log.FEATURE.WIZARD,
            Log.TYPE.ERROR,
          );
          this.setState({
            submittingWizard: false,
          });
          return false;
        }
      }

      if (response5[0]?.value?.length > 0) {
        ToastService.success(['Berechtigungen wurden vergeben.']);
      }
    }

    ToastService.success(['Standort wurde angelegt.']);

    this.setState({
      open: false,
      site: this.getDefaultSite(),
      submittingWizard: false,
    });

    this.resetWizard();
    SiteService.refreshSites();
    if (
      JSON.stringify(this.state.site.costCenters) !==
      JSON.stringify(newCostCenters)
    ) {
      CostCenterService.refreshCostCenters();
    } // If a new cost center has been created, refresh them.

    return true;
  };

  getButton() {
    if (!UserUtils.isSiteCreateAllowedUser()) {
      return (
        <MissingPermissionsTooltip>
          <Button
            className="disabled-primary-button"
            startIcon={<AddLocationAltIcon />}
            onClick={this.onOpenWizard}
            disabled
          >
            Standort erstellen
          </Button>
        </MissingPermissionsTooltip>
      );
    }

    return (
      <Button
        className="primary-button"
        startIcon={<AddLocationAltIcon />}
        onClick={this.onOpenWizard}
      >
        Standort erstellen
      </Button>
    );
  }

  render() {
    return (
      <>
        {this.getButton()}
        <Wizard
          open={this.state.open}
          closeWizard={this.onCloseWizard}
          title="Standort erstellen"
          fullWidth
          steps={this.getWizardSteps()}
          wizardSuccess={this.submitSite}
          submittingWizard={this.state.submittingWizard}
          unsavedChanges={Site.getDifferentValues(
            this.state.site,
            this.getDefaultSite(),
          )}
        />
      </>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(CreateSiteWizard),
  'Daten konnten nicht geladen werden.',
);
