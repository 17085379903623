import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { Dialog, DialogContent, Button } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { BurgerMenu } from '../../BurgerMenu';
import { withErrorBoundary } from '~/ui/atoms';
import DeliveriesService from '~/services/deliveries.service';
import ToastService from '~/services/toast.service';
import BasicModal from '../../BasicModal';
import DeliveryNote from '~/models/deliveries/DeliveryNote';
import { dateUtils, parseDate } from '~/utils/dateUtils';

import DatagridUtils from '~/utils/datagridUtils';
import DeliveryStatus from '../DeliveryStatus';
import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';
import { ROUTE } from '~/constants/Route';
import { LOADING_STATE } from '~/constants/LoadingState';
import { Spinner } from '../../Spinner';

const mapStateToProps = (state) => ({
  deliveryNoteTemplates: state.deliveryNotes.deliveryNoteTemplates,
  deliveryNoteTemplatesLoading:
    state.deliveryNotes.deliveryNoteTemplatesLoading,
  deliveryRows: state.deliveryNotes.deliveryRows,
  deliveryNotesLoading: state.deliveryNotes.deliveryNotesLoading,
});
const mapDispatchToProps = () => ({});

class TemplateMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toBeDeletedTemplate: null,
      deleteConfirmationModalOpen: false,
      deliveryListModalOpen: false,
      rows: [],
      deletingTemplate: false,
    };
  }

  openModal = (id) => {
    this.setState({
      toBeDeletedTemplate: id,
      deleteConfirmationModalOpen: true,
    });
  };
  closeModal = () => {
    this.setState({
      toBeDeletedTemplate: null,
      deleteConfirmationModalOpen: false,
    });
  };
  handleClick = (id) => {
    if (id === 'import-dln') {
      Log.productAnalyticsEvent(
        'Import delivery note',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );

      this.setState({
        deliveryListModalOpen: true,
      });

      return;
    }

    Log.productAnalyticsEvent(
      'Import template',
      Log.FEATURE.CREATE_DELIVERY_NOTE,
    );

    this.props.onClick(id);
  };
  deleteTemplate = () => {
    this.setState({
      deletingTemplate: true,
    });

    Log.productAnalyticsEvent(
      'Delete template',
      Log.FEATURE.CREATE_DELIVERY_NOTE,
    );

    const id = this.state.toBeDeletedTemplate;

    DeliveriesService.deleteTemplate(id)
      .then((response) => {
        this.setState({
          deletingTemplate: false,
        });

        DeliveriesService.refreshTemplates();
        this.closeModal();

        if (this.props.onDeletedTemplate) this.props.onDeletedTemplate(id);
      })
      .catch((error) => {
        ToastService.error([
          'Vorlage konnte nicht gelöscht werden wegen eines internen Fehlers.',
          ToastService.MESSAGE.CONTACT_SUPPORT,
        ]);
        Log.productAnalyticsEvent(
          'Failed to delete template',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );
        Log.error('Failed to delete delivery note template. id: ' + id, error);
        this.setState({
          deletingTemplate: false,
        });
      });
  };

  getToBeDeletedTemplateName() {
    const toBeDeletedTemplate = this.props.deliveryNoteTemplates.find(
      (template) => template.id === this.state.toBeDeletedTemplate,
    )?.name;
    return toBeDeletedTemplate ? '"' + toBeDeletedTemplate + '" ' : '';
  }

  createDeliveryNote = async (deliveryNote) => {
    Log.productAnalyticsEvent(
      'Import delivery note',
      Log.FEATURE.CREATE_DELIVERY_NOTE,
    );

    const [dln, error] = await promiseHandler(
      DeliveriesService.getDeliveryNoteById(deliveryNote.id),
    );

    if (error) {
      Log.error('Failed to load delivery note. id: ', deliveryNote.id);
      ToastService.warning([
        'Lieferung konnte nicht als Vorlage verwendet werden.',
      ]);
      Log.productAnalyticsEvent(
        'Failed to import delivery note',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
    }

    this.setState({
      deliveryListModalOpen: false,
    });

    if (this.props.onDeliveryNoteSelect) {
      this.props.onDeliveryNoteSelect(dln);
      return;
    }

    DeliveriesService.setDeliveryNoteForCreationForm(dln);
    this.props.history.push({
      pathname: ROUTE.CREATE_DELIVERY_NOTE.ROUTE,
      state: { passDeliveryNote: true },
    });
  };

  getColumns() {
    return [
      {
        field: DeliveryNote.PROPERTY.STATUS.KEY,
        headerName: DeliveryNote.PROPERTY.STATUS.STRING,
        width: 200,
        sortable: true,
        renderCell(params) {
          const processState = params.value.split(';')?.[0];
          const combinedState = params.value.split(';')?.[2];
          const settledStatus = params.value.split(';')?.[3];

          return (
            <div
              className={
                'w-170px ' +
                DatagridUtils.getStatusBoxHeight(
                  DatagridUtils.ROW_HEIGHT.STANDARD,
                )
              }
            >
              <DeliveryStatus
                processState={processState}
                combinedState={combinedState}
                settledStatus={settledStatus}
                whiteBackground
              />
            </div>
          );
        },
      },
      {
        field: DeliveryNote.PROPERTY.NUMBER.KEY,
        headerName: DeliveryNote.PROPERTY.NUMBER.STRING,
        width: 200,
        sortable: true,
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.DLN_DATE.KEY,
        headerName: DeliveryNote.PROPERTY.DLN_DATE.STRING,
        type: 'date',
        width: 170,
        sortable: true,
        resizableText: true,
        valueGetter: parseDate,
        renderCell: (params) =>
          dateUtils.getFormattedDateWithoutMidnight_safe(
            params.value,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
      },
      {
        field: DeliveryNote.PROPERTY.MAIN_ARTICLE_TYPE.KEY,
        headerName: DeliveryNote.PROPERTY.MAIN_ARTICLE_TYPE.STRING,
        width: 250,
        sortable: true,
        resizableText: true,
      },
      {
        field: DeliveryNote.PROCESS_ROLE.RECIPIENT.KEY,
        headerName: DeliveryNote.PROCESS_ROLE.RECIPIENT.STRING,
        width: 250,
        sortable: true,
        resizableText: true,
      },
      {
        field: DeliveryNote.PROCESS_ROLE.SUPPLIER.KEY,
        headerName: DeliveryNote.PROCESS_ROLE.SUPPLIER.STRING,
        width: 250,
        sortable: true,
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.KEY,
        headerName: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.STRING,
        width: 400,
        sortable: true,
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.COST_CENTERS.KEY,
        headerName: DeliveryNote.PROPERTY.COST_CENTERS.STRING,
        width: 150,
        sortable: true,
        resizableText: true,
        ...DatagridUtils.customColumnTypeArray(),
      },
      {
        field: DeliveryNote.PROPERTY.FROM_SITE.KEY,
        headerName: DeliveryNote.PROPERTY.FROM_SITE.STRING,
        width: 400,
        sortable: true,
        resizableText: true,
      },
      {
        field: DeliveryNote.PROCESS_ROLE.CARRIER.KEY,
        headerName: DeliveryNote.PROCESS_ROLE.CARRIER.STRING,
        width: 250,
        sortable: true,
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.LICENSE_PLATE.KEY,
        headerName: DeliveryNote.PROPERTY.LICENSE_PLATE.STRING,
        width: 150,
        sortable: true,
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.MOVEMENT_MEANS.KEY,
        headerName: DeliveryNote.PROPERTY.MOVEMENT_MEANS.STRING,
        width: 150,
        sortable: true,
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.DELIVERY_TYPE.KEY,
        headerName: DeliveryNote.PROPERTY.DELIVERY_TYPE.STRING,
        width: 150,
        sortable: true,
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.CREATION_DATE.KEY,
        headerName: DeliveryNote.PROPERTY.CREATION_DATE.STRING,
        type: 'date',
        width: 150,
        sortable: true,
        resizableText: true,
        valueGetter: parseDate,
        renderCell: (params) =>
          dateUtils.getFormattedDate_safe(
            params.value,
            dateUtils.DATE_FORMAT.DD_MM_YYYY,
          ),
      },
    ];
  }

  render() {
    return (
      <>
        <BurgerMenu
          className="primary-button"
          endIcon={
            this.props.menuId === 'dln-create-template-menu' ? (
              <KeyboardArrowDownIcon />
            ) : null
          }
          title={
            this.props.menuId === 'dln-create-template-menu' ? (
              'Vorlage verwenden'
            ) : (
              <KeyboardArrowDownIcon />
            )
          }
          deleteText="Vorlage löschen"
          options={this.props.deliveryNoteTemplates.map((template) => {
            return { ...template, deletable: !template.default_template };
          })}
          loading={this.props.deliveryNoteTemplatesLoading}
          menuId={this.props.menuId}
          onClick={this.handleClick}
          onDelete={this.openModal}
          bottomButtonId="import-dln"
          bottomButtonName="Lieferung als Vorlage verwenden"
        />
        <Dialog
          open={this.state.deleteConfirmationModalOpen}
          onClose={() => {
            this.setState({ deleteConfirmationModalOpen: false });
          }}
          disableEnforceFocus
        >
          <DialogContent>
            <div className="bold mb-20px text-20px mt-10px">
              Vorlage löschen
            </div>
            <div className="w-400px mt-20px">
              {'Möchtest du die Vorlage ' +
                this.getToBeDeletedTemplateName() +
                'wirklich löschen?'}
            </div>
            <div className="flex-e-c mt-30px mb-10px w-full">
              <Button
                variant="text"
                className="mr-30px"
                onClick={this.closeModal}
              >
                Abbrechen
              </Button>
              <Button
                className="primary-button"
                onClick={this.deleteTemplate}
                disabled={this.state.deletingTemplate}
              >
                {this.state.deletingTemplate ? (
                  <Spinner white title="Löschen..." />
                ) : (
                  'Löschen'
                )}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        <BasicModal
          title="Lieferung auswählen"
          open={this.state.deliveryListModalOpen}
          closeModal={() => this.setState({ deliveryListModalOpen: false })}
          fullWidth
        >
          <div className="main-table border">
            <DataGridPro
              rows={this.props.deliveryRows}
              columns={this.getColumns()}
              onRowClick={(rowData) => this.createDeliveryNote(rowData.row)}
              disableRowSelectionOnClick
              rowHeight={DatagridUtils.ROW_HEIGHT.THIN}
              loading={
                this.props.deliveryNotesLoading === LOADING_STATE.LOADING
              }
              sortModel={[
                {
                  field: DeliveryNote.PROPERTY.CREATION_DATE.KEY,
                  sort: 'desc',
                },
              ]}
              slotProps={{ row: { style: { cursor: 'pointer' } } }}
              slots={{
                NoRowsOverlay: () => (
                  <div className="flex-c-c h-full w-full">
                    {this.props.deliveryNotesLoading === LOADING_STATE.FAILED
                      ? 'Daten konnten nicht geladen werden.'
                      : 'Keine Einträge'}
                  </div>
                ),
              }}
            />
          </div>
        </BasicModal>
      </>
    );
  }
}

export default withRouter(
  withErrorBoundary(
    connect(mapStateToProps, mapDispatchToProps())(TemplateMenu),
    null,
  ),
);
