// When extending the ROUTES, more specific routes must be defined below as when comparing the current URL with those routes,
// the Route object is iterated from top down and current URL <-> route are compared with .includes
import { FEATURE_FLAG } from './FeatureFlag';

export const ROUTE = {
  HOME: {
    ROUTE: '/',
  },
  DASHBOARD: {
    ROUTE: '/dashboard',
    PERMISSIONS: ['dln_in.', 'dln_out.', 'dln_trans.'],
  },
  USER_METRICS: {
    ROUTE: '/user-metrics',
  },
  DELIVERIES: {
    ROUTE: '/deliveries',
    PERMISSIONS: ['dln_in.', 'dln_out.', 'dln_trans.'],
  },
  CONCRETE_DIARY: {
    ROUTE: '/concrete-diary',
    PERMISSIONS: ['dln_in.'],
    FEATURE_FLAG: FEATURE_FLAG.CONCRETE_DIARY,
  },
  OUTGOING_INVOICES: {
    ROUTE: '/outgoing-invoices',
    PERMISSIONS: ['invoice_out.'],
    DISABLING_FEATURE_FLAG: FEATURE_FLAG.DISABLE_INVOICES,
  },
  INCOMING_INVOICES: {
    ROUTE: '/incoming-invoices',
    PERMISSIONS: ['invoice_in.'],
    DISABLING_FEATURE_FLAG: FEATURE_FLAG.DISABLE_INVOICES,
  },
  SETTINGS: {
    ROUTE: '/settings',
  },
  SETTINGS_PROFILE: {
    ROUTE: '/settings/profile',
    MAIN_ROUTE: '/settings',
  },
  SETTINGS_SUPPORT: {
    ROUTE: '/settings/support',
    MAIN_ROUTE: '/settings',
  },
  SETTINGS_MASTERDATA: {
    ROUTE: '/settings/masterdata',
    MAIN_ROUTE: '/settings',
    PERMISSIONS: [
      'user.',
      'site.',
      'accounting_reference.',
      'vehicle.',
      'company.',
      'org_unit.',
      'user_group.',
    ],
  },
  SETTINGS_USER: {
    ROUTE: '/settings/masterdata/employees',
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['user.'],
  },
  SETTINGS_SITE: {
    ROUTE: '/settings/masterdata/site',
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['site.'],
  },
  SETTINGS_COST_CENTER: {
    ROUTE: '/settings/masterdata/cost-center',
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['accounting_reference.'],
  },
  SETTINGS_VEHICLE: {
    ROUTE: '/settings/masterdata/vehicle',
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['vehicle.'],
  },
  SETTINGS_COMPANY: {
    ROUTE: '/settings/masterdata/company',
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['company.'],
  },
  SETTINGS_ORGANISATIONAL_GROUP: {
    ROUTE: '/settings/masterdata/organisational-group',
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['org_unit.'],
  },
  SETTINGS_USER_GROUP: {
    ROUTE: '/settings/masterdata/user-group',
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['user_group.'],
  },
  SETTINGS_SITE_REFERENCES: {
    ROUTE: '/settings/admin/site-references',
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['org_unit.'],
    ONLY_ACCESS_BY_VESTIGAS_SUPPORT: true,
  },
  SETTINGS_DATA_SUBSCRIPTIONS: {
    ROUTE: '/settings/admin/data-subscriptions',
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['org_unit.'],
    ONLY_ACCESS_BY_VESTIGAS_SUPPORT: true,
  },
  SETTINGS_CUSTOM_FIELDS: {
    ROUTE: '/settings/admin/custom-fields',
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['org_unit.'],
    ONLY_ACCESS_BY_VESTIGAS_SUPPORT: true,
  },
  SETTINGS_SIGNATURE_FIELDS: {
    ROUTE: '/settings/admin/signature-fields',
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['org_unit.'],
    ONLY_ACCESS_BY_VESTIGAS_SUPPORT: true,
  },
  SETTINGS_ARTICLES: {
    ROUTE: '/settings/admin/article-master/articles',
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['org_unit.'],
    ONLY_ACCESS_BY_VESTIGAS_SUPPORT: true,
  },
  SETTINGS_CATEGORIES: {
    ROUTE: '/settings/admin/article-master/categories',
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['org_unit.'],
    ONLY_ACCESS_BY_VESTIGAS_SUPPORT: true,
  },
  SETTINGS_CUSTOMER_SUBSCRIPTIONS: {
    ROUTE: '/settings/admin/customer-subscriptions',
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['org_unit.'],
    FEATURE_FLAG: FEATURE_FLAG.CUSTOMER_SUBSCRIPTION,
  },
  SETTINGS_PDF: {
    ROUTE: '/settings/admin/pdf',
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['org_unit.'],
    ONLY_ACCESS_BY_VESTIGAS_SUPPORT: true,
  },
  SETTINGS_INVOICE_CHECK_IGNORED_ARTICLES: {
    ROUTE: '/settings/admin/invoice-check-ignored-articles',
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['org_unit.'],
    ONLY_ACCESS_BY_VESTIGAS_SUPPORT: true,
  },
  SETTINGS_DEMO: {
    ROUTE: '/settings/demo',
    MAIN_ROUTE: '/settings',
  },
  SETTINGS_DATA_PROTECTION: {
    ROUTE: '/settings/data-protection',
    MAIN_ROUTE: '/settings',
  },
  SETTINGS_IMPRESSUM: {
    ROUTE: '/settings/impressum',
    MAIN_ROUTE: '/settings',
  },
  DATA_EXCHANGE: {
    ROUTE: '/data-exchange',
  },
  DELIVERY_NOTE: {
    ROUTE: '/delivery-note',
    MAIN_ROUTE: '/deliveries',
    PERMISSIONS: ['dln_in.', 'dln_out.', 'dln_trans.'],
  },
  DELIVERY_NOTE_DATA_QUALITY: {
    ROUTE: '/delivery-note-data-quality',
    MAIN_ROUTE: '/deliveries',
    PERMISSIONS: ['dln_in.', 'dln_out.', 'dln_trans.'],
  },
  CREATE_DELIVERY_NOTE: {
    ROUTE: '/create-delivery-note',
    PERMISSIONS: ['dln_in.', 'dln_out.', 'dln_trans.'],
    FEATURE_FLAG: FEATURE_FLAG.CREATE_DELIVERY_NOTE,
  },
  CREATE_SERVICE_NOTE: {
    ROUTE: '/create-service-note',
    PERMISSIONS: ['dln_in.', 'dln_out.', 'dln_trans.'],
    FEATURE_FLAG: FEATURE_FLAG.SERVICE_NOTES,
  },
  OUTGOING_INVOICE: {
    ROUTE: '/outgoing-invoices/invoice',
    MAIN_ROUTE: '/outgoing-invoices',
    PERMISSIONS: ['invoice_out.'],
    DISABLING_FEATURE_FLAG: FEATURE_FLAG.DISABLE_INVOICES,
  },
  INCOMING_INVOICE: {
    ROUTE: '/incoming-invoices/invoice',
    MAIN_ROUTE: '/incoming-invoices',
    PERMISSIONS: ['invoice_in.'],
    DISABLING_FEATURE_FLAG: FEATURE_FLAG.DISABLE_INVOICES,
  },
  INVOICE: {
    ROUTE: '/invoice-link',
    PERMISSIONS: ['invoice_in.', 'invoice_out.'],
    DISABLING_FEATURE_FLAG: FEATURE_FLAG.DISABLE_INVOICES,
  },
  IDS_REDIRECT: {
    ROUTE: '/ids-redirect',
  },
};
