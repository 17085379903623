import React from 'react';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import PaginatedEntityMultiPicker from '~/components/baseComponents/inputs/select/PaginatedEntityMultiPicker';
import Log from '~/utils/Log';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

class PermissionGrantMultiPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textfieldLabel: '',
    };
  }

  componentDidMount() {
    this.initTextfieldLabel();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.type !== prevProps.type ||
      this.props.entityType !== prevProps.entityType ||
      this.props.subjectType !== prevProps.subjectType ||
      this.props.subjects?.length !== prevProps.subjects?.length
    ) {
      this.initTextfieldLabel();
    }
  }

  initTextfieldLabel() {
    let textfieldLabel = '';

    if (this.props.type === 'permission_for') {
      switch (this.props.subjectType) {
        case PermissionGrant.SUBJECT_TYPE.USER.KEY: {
          textfieldLabel = 'Welche Benutzer sollen berechtigt werden?';
          break;
        }

        case PermissionGrant.SUBJECT_TYPE.USER_GROUP.KEY: {
          textfieldLabel = 'Welche Benutzer-Gruppen sollen berechtigt werden?';
          break;
        }

        default: {
          Log.error(
            null,
            new EnumValueNotFoundException(
              'Invalid subject type: ' + this.props.subjectType,
            ),
          );
        }
      }
    } else if (this.props.type === 'permission_to') {
      let subjectLabel = '';

      if (this.props.subjectType === PermissionGrant.SUBJECT_TYPE.USER.KEY) {
        if (this.props.subjects.length <= 1) {
          subjectLabel = 'soll dieser Benutzer';
        }

        if (this.props.subjects.length > 1) {
          subjectLabel =
            'sollen diese ' + this.props.subjects.length + ' Benutzer';
        }
      } else if (
        this.props.subjectType === PermissionGrant.SUBJECT_TYPE.USER_GROUP.KEY
      ) {
        if (this.props.subjects.length <= 1) {
          subjectLabel = 'soll diese Benutzer-Gruppe';
        }

        if (this.props.subjects.length > 1) {
          subjectLabel =
            'sollen diese ' + this.props.subjects.length + ' Benutzer-Gruppen';
        }
      } else {
        Log.error(
          null,
          new EnumValueNotFoundException(
            'Invalid subject type: ' + this.props.entityType,
          ),
        );
      }

      switch (this.props.entityType) {
        case PermissionGrant.ENTITY_TYPE.USER.KEY: {
          textfieldLabel =
            'Auf welche Benutzer ' + subjectLabel + ' berechtigt werden?';
          break;
        }

        case PermissionGrant.ENTITY_TYPE.SITE.KEY: {
          textfieldLabel =
            'Auf welche Standorte ' + subjectLabel + ' berechtigt werden?';
          break;
        }

        case PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY: {
          textfieldLabel =
            'Auf welche Kostenstellen ' + subjectLabel + ' berechtigt werden?';
          break;
        }

        case PermissionGrant.ENTITY_TYPE.VEHICLE.KEY: {
          textfieldLabel =
            'Auf welche Fahrzeuge ' + subjectLabel + ' berechtigt werden?';
          break;
        }

        case PermissionGrant.ENTITY_TYPE.COMPANY.KEY: {
          textfieldLabel =
            'Auf welche Firmen ' + subjectLabel + ' berechtigt werden?';
          break;
        }

        case PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY: {
          textfieldLabel =
            'Auf welche Organisations-Gruppen ' +
            subjectLabel +
            ' berechtigt werden?';
          break;
        }

        case PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY: {
          textfieldLabel =
            'Auf welche Benutzer-Gruppen ' +
            subjectLabel +
            ' berechtigt werden?';
          break;
        }

        default: {
          Log.error(
            null,
            new EnumValueNotFoundException(
              'Invalid entity type: ' + this.props.entityType,
            ),
          );
        }
      }
    } else {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid type: ' + this.props.type),
      );
    }

    this.setState({
      textfieldLabel,
    });
  }

  render() {
    return (
      <PaginatedEntityMultiPicker
        textfieldLabel={this.state.textfieldLabel}
        // Depending on the type of PermissionGrantMultiPicker, it can be to grant permissions for users or user groups.
        // In this case the corresponding entity type must be passed to PaginatedEntityMultiPicker to make the users or user groups selectable.
        entityType={this.props.entityType ?? this.props.subjectType}
        pickedIds={this.props.pickedIds}
        callbackPickedItems={this.props.callbackPickedItems}
        disabled={this.props.disabled}
        fullWidth
        displayPermissionGrantOfCostCentersWithSites={
          this.props.displayPermissionGrantOfCostCentersWithSites
        }
      />
    );
  }
}

export default PermissionGrantMultiPicker;
