import { Typography } from '@mui/material';

import DataExchangeCompany from '~/models/dataExchanges/DataExchangeCompany';

import { LightTooltipWide } from '~/utils/componentUtils';

import { SUPPLIERS_STATUS_MAP } from './constants';

export const SupplierStatusFlag = ({ overallStatus, status }) => {
  const { ACTIVATED, LIVE } = DataExchangeCompany.STATUS;

  const isActive = [ACTIVATED.KEY, LIVE.KEY].includes(overallStatus);

  if (!status || (isActive && status === 'expired')) {
    return null;
  }

  return (
    <LightTooltipWide
      title={
        <Typography variant="caption">
          {SUPPLIERS_STATUS_MAP[status].message}
        </Typography>
      }
      enterDelay={300}
      enterNextDelay={500}
    >
      <div className={`status-flag flex-c-c ${status}`}>
        {SUPPLIERS_STATUS_MAP[status].icon}
      </div>
    </LightTooltipWide>
  );
};
