import React from 'react';

import { Add as AddIcon } from '@mui/icons-material';

import { connect } from 'react-redux';
import DatagridUtils from '~/utils/datagridUtils';
import { withErrorBoundary } from '~/ui/atoms';
import BasicTable from '~/components/BasicTable';
import LocalStorageService from '~/services/localStorage.service';
import { Button } from '@mui/material';
import DataSubscriptionForm from './DataSubscriptionForm';
import DataSubscription from '~/models/dataSubscriptions/DataSubscription';
import ContactPointForm from './ContactPointForm';
import ContactPoint from '~/models/dataSubscriptions/ContactPoint';
import NotificationPolicy from '~/models/dataSubscriptions/NotificationPolicy';
import NotificationPolicyForm from './NotificationPolicyForm';
import DeliveryNote from '~/models/deliveries/DeliveryNote';
import Log from '~/utils/Log';

const mapStateToProps = (state) => ({
  dataSubscriptions: state.dataSubscriptions,
});
const mapDispatchToProps = () => ({});

const notificationPolicyColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 400,
    sortable: true,
    resizableText: true,
    renderCell: DatagridUtils.displayCellTooltip,
  },
  {
    field: 'dataSubscriptionName',
    headerName: 'Regel',
    width: 400,
    sortable: true,
    resizableText: true,
    renderCell: DatagridUtils.displayCellTooltip,
  },
  {
    field: 'contactPointName',
    headerName: 'Empfänger',
    width: 400,
    sortable: true,
    resizableText: true,
    renderCell: DatagridUtils.displayCellTooltip,
  },
];

const dataSubscriptionColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 400,
    sortable: true,
    resizableText: true,
    renderCell: DatagridUtils.displayCellTooltip,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 400,
    sortable: true,
    resizableText: true,
    renderCell: DatagridUtils.displayCellTooltip,
  },
  {
    field: 'subscriptionEvents',
    headerName: 'Events',
    width: 400,
    sortable: true,
    resizableText: true,
    renderCell: DatagridUtils.displayCellTooltip,
  },
];

const contactPointColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 400,
    sortable: true,
    resizableText: true,
    renderCell: DatagridUtils.displayCellTooltip,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 400,
    sortable: true,
    resizableText: true,
    renderCell: DatagridUtils.displayCellTooltip,
  },
  {
    field: 'emailReceivers',
    headerName: 'E-Mail Empfänger',
    width: 400,
    sortable: true,
    resizableText: true,
    renderCell: DatagridUtils.displayCellTooltip,
  },
  {
    field: 'dlnFilenamePlaceholder',
    headerName: 'Dateiname',
    width: 200,
    sortable: true,
    resizableText: true,
    renderCell: DatagridUtils.displayCellTooltip,
  },
  {
    field: 'emailSubject',
    headerName: 'Betreffzeile',
    width: 200,
    sortable: true,
    resizableText: true,
    renderCell: DatagridUtils.displayCellTooltip,
  },
  {
    field: 'dataFormat',
    headerName: 'Dateityp',
    width: 200,
    sortable: true,
    resizableText: true,
    renderCell: DatagridUtils.displayCellTooltip,
  },
  {
    field: 'dataGrouping',
    headerName: 'Gruppierung',
    width: 200,
    sortable: true,
    resizableText: true,
    renderCell: DatagridUtils.displayCellTooltip,
  },
];

class DataSubscriptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notificationPolicyRows: [],
      notificationPolicySortModel: [
        { field: 'dataSubscriptionName', sort: 'asc' },
        { field: 'contactPointName', sort: 'asc' },
      ],
      dataSubscriptionRows: [],
      dataSubscriptionSortModel: [{ field: 'name', sort: 'asc' }],
      contactPointRows: [],
      contactPointSortModel: [{ field: 'name', sort: 'asc' }],
      notificationPolicyFormOpen: false,
      notificationPolicyFormType: null,
      notificationPolicy: new NotificationPolicy(),
      dataSubscriptionFormOpen: false,
      dataSubscriptionFormType: null,
      dataSubscription: new DataSubscription(),
      contactPointFormOpen: false,
      contactPointFormType: null,
      contactPoint: new ContactPoint(),
    };
  }

  componentDidMount() {
    this.initRows();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.dataSubscriptions.notificationPolicies) !==
        JSON.stringify(this.props.dataSubscriptions.notificationPolicies) ||
      JSON.stringify(prevProps.dataSubscriptions.dataSubscriptions) !==
        JSON.stringify(this.props.dataSubscriptions.dataSubscriptions) ||
      JSON.stringify(prevProps.dataSubscriptions.contactPoints) !==
        JSON.stringify(this.props.dataSubscriptions.contactPoints)
    ) {
      this.initRows();
    }
  }

  initRows() {
    const notificationPolicyRows =
      this.props.dataSubscriptions.notificationPolicies.map(
        (notificationPolicy) => {
          const dataSubscription =
            this.props.dataSubscriptions.dataSubscriptions.find(
              (dataSubscription) =>
                dataSubscription.id === notificationPolicy.dataSubscriptionId,
            );
          const contactPoint = this.props.dataSubscriptions.contactPoints.find(
            (contactPoint) =>
              contactPoint.id === notificationPolicy.contactPointId,
          );

          return {
            id:
              notificationPolicy.dataSubscriptionId +
              notificationPolicy.contactPointId,
            dataSubscriptionName: dataSubscription?.name,
            contactPointName: contactPoint?.name,
            dataSubscriptionId: notificationPolicy.dataSubscriptionId,
            contactPointId: notificationPolicy.contactPointId,
          };
        },
      );

    const dataSubscriptionRows =
      this.props.dataSubscriptions.dataSubscriptions.map((dataSubscription) => {
        return {
          id: dataSubscription.id,
          name: dataSubscription.name,
          subscriptionEvents: dataSubscription.subscriptionEvents
            .map((subscriptionEvent) =>
              DeliveryNote.getProcessStateString(subscriptionEvent),
            )
            .join(', '),
        };
      });

    const contactPointRows = this.props.dataSubscriptions.contactPoints.map(
      (contactPoint) => {
        return {
          id: contactPoint.id,
          name: contactPoint.name,
          emailReceivers: contactPoint.emailReceivers.join(', '),
          dlnFilenamePlaceholder: contactPoint.dlnFilenamePlaceholder,
          emailSubject: contactPoint.emailSubject,
          dataFormat: ContactPoint.getDataFormatString(contactPoint.dataFormat),
          dataGrouping: ContactPoint.getDataGroupingString(
            contactPoint.dataGrouping,
          ),
        };
      },
    );

    this.setState({
      notificationPolicyRows,
      dataSubscriptionRows,
      contactPointRows,
    });
  }

  onNotificationPolicySortModelChange = (sortModel) => {
    Log.productAnalyticsEvent('Sort', Log.FEATURE.DATA_SUBSCRIPTION);

    this.setState({
      notificationPolicySortModel: sortModel,
    });
  };
  onDataSubscriptionSortModelChange = (sortModel) => {
    Log.productAnalyticsEvent('Sort', Log.FEATURE.DATA_SUBSCRIPTION);

    this.setState({
      dataSubscriptionSortModel: sortModel,
    });
  };
  onContactPointSortModelChange = (sortModel) => {
    Log.productAnalyticsEvent('Sort', Log.FEATURE.DATA_SUBSCRIPTION);

    this.setState({
      contactPointSortModel: sortModel,
    });
  };
  openNotificationsPoliciesCreateForm = () => {
    this.setState({
      notificationPolicyFormOpen: true,
      notificationPolicyFormType: 'create',
      notificationPolicy: new NotificationPolicy(),
    });
  };
  openDataSubscriptionsCreateForm = () => {
    this.setState({
      dataSubscriptionFormOpen: true,
      dataSubscriptionFormType: 'create',
      dataSubscription: new DataSubscription(),
    });
  };
  openContactPointsCreateForm = () => {
    this.setState({
      contactPointFormOpen: true,
      contactPointFormType: 'create',
      contactPoint: new ContactPoint(),
    });
  };
  openNotificationPolicyEditForm = (event) => {
    this.setState({
      notificationPolicyFormOpen: true,
      notificationPolicyFormType: 'edit',
      notificationPolicy:
        this.props.dataSubscriptions.notificationPolicies.find(
          (notificationPolicy) =>
            notificationPolicy.dataSubscriptionId ===
              event.row.dataSubscriptionId &&
            notificationPolicy.contactPointId === event.row.contactPointId,
        ) ?? new NotificationPolicy(),
    });
  };
  openDataSubscriptionEditForm = (event) => {
    this.setState({
      dataSubscriptionFormOpen: true,
      dataSubscriptionFormType: 'edit',
      dataSubscription:
        this.props.dataSubscriptions.dataSubscriptions.find(
          (dataSubscription) => dataSubscription.id === event.id,
        ) ?? new DataSubscription(),
    });
  };
  openContactPointEditForm = (event) => {
    this.setState({
      contactPointFormOpen: true,
      contactPointFormType: 'edit',
      contactPoint:
        this.props.dataSubscriptions.contactPoints.find(
          (contactPoint) => contactPoint.id === event.id,
        ) ?? new ContactPoint(),
    });
  };
  closeNotificationPolicyForm = () => {
    this.setState({
      notificationPolicyFormOpen: false,
    });
  };
  closeDataSubscriptionForm = () => {
    this.setState({
      dataSubscriptionFormOpen: false,
    });
  };
  closeContactPointForm = () => {
    this.setState({
      contactPointFormOpen: false,
    });
  };

  render() {
    return (
      <div className="ml-2rem mr-2rem">
        <div className="main-header">Daten ausleiten</div>
        <div className="mt-20px box-shadow-blue rounded-5px p-10px bg-white">
          <div className="flex-sb-c mb-20px">
            <div className="bold text-16px">Verknüpfungen</div>
            <Button
              className="primary-button"
              startIcon={<AddIcon />}
              onClick={this.openNotificationsPoliciesCreateForm}
            >
              Erstellen
            </Button>
          </div>
          <div className="h-350px">
            <BasicTable
              rows={this.state.notificationPolicyRows}
              columns={notificationPolicyColumns}
              loading={this.props.dataSubscriptions.notificationPoliciesLoading}
              onRowClick={this.openNotificationPolicyEditForm}
              sortModel={this.state.notificationPolicySortModel}
              onSortModelChange={this.onNotificationPolicySortModelChange}
              localStorageKey={LocalStorageService.NOTIFICATION_POLICIES_TABLE}
              defaultHiddenColumns={['id']}
            />
          </div>
          <NotificationPolicyForm
            open={this.state.notificationPolicyFormOpen}
            notificationPolicy={this.state.notificationPolicy}
            closeForm={this.closeNotificationPolicyForm}
            type={this.state.notificationPolicyFormType}
          />
        </div>
        <div className="mt-20px box-shadow-blue rounded-5px p-10px bg-white">
          <div className="flex-sb-c mb-20px">
            <div className="bold text-16px">Regeln</div>
            <Button
              className="primary-button"
              startIcon={<AddIcon />}
              onClick={this.openDataSubscriptionsCreateForm}
            >
              Erstellen
            </Button>
          </div>
          <div className="h-350px">
            <BasicTable
              rows={this.state.dataSubscriptionRows}
              columns={dataSubscriptionColumns}
              loading={this.props.dataSubscriptions.dataSubscriptionsLoading}
              onRowClick={this.openDataSubscriptionEditForm}
              sortModel={this.state.dataSubscriptionSortModel}
              onSortModelChange={this.onDataSubscriptionSortModelChange}
              localStorageKey={LocalStorageService.DATA_SUBSCRIPTIONS_TABLE}
              defaultHiddenColumns={['id']}
            />
          </div>
          <DataSubscriptionForm
            open={this.state.dataSubscriptionFormOpen}
            dataSubscription={this.state.dataSubscription}
            closeForm={this.closeDataSubscriptionForm}
            type={this.state.dataSubscriptionFormType}
          />
        </div>
        <div className="mt-20px box-shadow-blue rounded-5px p-10px bg-white">
          <div className="flex-sb-c mb-20px">
            <div className="bold text-16px">Empfänger</div>
            <Button
              className="primary-button"
              startIcon={<AddIcon />}
              onClick={this.openContactPointsCreateForm}
            >
              Erstellen
            </Button>
          </div>
          <div className="h-350px">
            <BasicTable
              rows={this.state.contactPointRows}
              columns={contactPointColumns}
              loading={this.props.dataSubscriptions.contactPointsLoading}
              onRowClick={this.openContactPointEditForm}
              sortModel={this.state.contactPointSortModel}
              onSortModelChange={this.onContactPointSortModelChange}
              localStorageKey={LocalStorageService.CONTACT_POINTS_TABLE}
              defaultHiddenColumns={['id']}
            />
          </div>
          <ContactPointForm
            open={this.state.contactPointFormOpen}
            contactPoint={this.state.contactPoint}
            closeForm={this.closeContactPointForm}
            type={this.state.contactPointFormType}
          />
        </div>
      </div>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(DataSubscriptions),
  'Daten ausleiten konnten nicht geladen werden.',
);
