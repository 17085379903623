import React from 'react';

import { Chip as MuiChip, InputLabel, Grid, TextField } from '@mui/material';

import { connect } from 'react-redux';
import ContactPoint from '~/models/dataSubscriptions/ContactPoint';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';
import ToastService from '~/services/toast.service';
import DataSubscriptionService from '~/services/dataSubscription.service';
import BasicForm from '~/components/BasicForm';
import cloneDeep from 'lodash/cloneDeep';
import Select from '~/components/deliveries/deliveryNoteForm/Select';
import ChipList from '~/components/baseComponents/chips/ChipList';

const mapStateToProps = (state) => ({
  dataSubscriptions: state.dataSubscriptions,
});
const mapDispatchToProps = () => ({});

class ContactPointForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contactPoint: new ContactPoint(),
      submittingForm: false,
      deletingForm: false,
      emailReceiverFormOpen: false,
      newEmailReceiver: '',
    };
  }

  componentDidMount() {
    this.resetForm();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.contactPoint) !==
      JSON.stringify(this.props.contactPoint)
    ) {
      this.resetForm();
    }
  }

  resetForm() {
    this.setState({
      contactPoint: this.props.contactPoint ?? new ContactPoint(),
    });
  }

  formSuccess = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const [isValid, errorMessage] = ContactPoint.isValid(
      this.state.contactPoint,
    );

    if (!isValid) {
      ToastService.error([errorMessage]);
      return;
    }

    this.setState({
      submittingForm: true,
    });

    const body = {
      name: this.state.contactPoint.name,
      integration_type: this.state.contactPoint.integrationType,
      email_receivers: this.state.contactPoint.emailReceivers,
      dln_filename_placeholder: this.state.contactPoint.dlnFilenamePlaceholder,
      email_subject: this.state.contactPoint.emailSubject,
      data_format: this.state.contactPoint.dataFormat,
      data_grouping: this.state.contactPoint.dataGrouping,
    };

    Log.info('Submit contact point form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.DATA_SUBSCRIPTION);

    if (this.renderForCreate()) {
      const [contactPointId, error] = await promiseHandler(
        DataSubscriptionService.createContactPoint(body),
      );

      if (error) {
        Log.error('Failed to create contact point.', error);
        ToastService.httpError(
          ['Empfänger konnte nicht angelegt werden.'],
          error.response,
        );
        Log.productAnalyticsEvent(
          'Failed to create',
          Log.FEATURE.DATA_SUBSCRIPTION,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }
    } else {
      const [response, error] = await promiseHandler(
        DataSubscriptionService.updateContactPoint(
          this.props.contactPoint.id,
          body,
        ),
      );

      if (error) {
        Log.error(
          'Failed to update contact point. id: ' + this.props.contactPoint.id,
          error,
        );
        ToastService.httpError(
          ['Empfänger konnte nicht aktualisiert werden.'],
          error.response,
        );
        Log.productAnalyticsEvent(
          'Failed to update',
          Log.FEATURE.DATA_SUBSCRIPTION,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }
    }

    this.setState({
      submittingForm: false,
    });

    this.props.closeForm();
    this.resetForm();
    DataSubscriptionService.refreshContactPoints();
  };
  formAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.DATA_SUBSCRIPTION);
    this.props.closeForm();
    this.resetForm();
  };
  formDelete = async (event) => {
    event.preventDefault();

    Log.info(
      'Delete contact point',
      { id: this.props.contactPoint.id },
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Delete', Log.FEATURE.DATA_SUBSCRIPTION);

    this.setState({
      deletingForm: true,
    });

    const [response, error] = await promiseHandler(
      DataSubscriptionService.deleteContactPoint(this.props.contactPoint.id),
    );

    if (error) {
      ToastService.httpError(
        ['Empfänger konnte nicht gelöscht werden.'],
        error.response,
      );
      Log.error('Failed to delete contact point.', error);
      Log.productAnalyticsEvent(
        'Failed to delete',
        Log.FEATURE.DATA_SUBSCRIPTION,
        Log.TYPE.ERROR,
      );
      this.setState({
        deletingForm: false,
      });
      return;
    }

    this.setState({
      deletingForm: false,
    });

    this.props.closeForm();
    this.resetForm();
    await DataSubscriptionService.refreshContactPoints();
  };
  renderForCreate = () => {
    return this.props.type === 'create';
  };
  handleInputChange = (event) => {
    const newContactPoint = cloneDeep(this.state.contactPoint);

    switch (event.target.name) {
      case 'name': {
        newContactPoint.name = event.target.value;
        Log.info(
          'Change form value of name',
          { from: this.state.contactPoint.name, to: newContactPoint.name },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        break;
      }

      case 'dln_filename_placeholder': {
        newContactPoint.dlnFilenamePlaceholder = event.target.value;
        Log.info(
          'Change form value of dln filename placeholder',
          {
            from: this.state.contactPoint.dlnFilenamePlaceholder,
            to: newContactPoint.dlnFilenamePlaceholder,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        break;
      }

      case 'email_subject': {
        newContactPoint.emailSubject = event.target.value;
        Log.info(
          'Change form value of email subject',
          {
            from: this.state.contactPoint.emailSubject,
            to: newContactPoint.emailSubject,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        break;
      }
    }

    this.setState({
      contactPoint: newContactPoint,
    });
  };

  /* handleChangeIntegrationType = (event) => {
        let newContactPoint = cloneDeep(this.state.contactPoint);

        newContactPoint.integrationType = event.target.value;

        this.setState({
            contactPoint: newContactPoint
        });
    } */

  handleChangeDataFormat = (event) => {
    const newContactPoint = cloneDeep(this.state.contactPoint);

    newContactPoint.dataFormat = event.target.value;

    this.setState({
      contactPoint: newContactPoint,
    });
  };
  handleChangeDataGrouping = (event) => {
    const newContactPoint = cloneDeep(this.state.contactPoint);

    newContactPoint.dataGrouping = event.target.value;

    this.setState({
      contactPoint: newContactPoint,
    });
  };

  getUnsavedChanges() {
    if (this.renderForCreate()) {
      return [];
    }

    return ContactPoint.getDifferentValues(
      this.props.contactPoint,
      this.state.contactPoint,
    );
  }

  /* getIntegrationTypes() {
        return Object.keys(ContactPoint.INTEGRATION_TYPE).map(key => {
            return {
                id: ContactPoint.INTEGRATION_TYPE[key].KEY,
                name: ContactPoint.INTEGRATION_TYPE[key].STRING,
            };
        });
    } */

  getDataFormats() {
    return Object.keys(ContactPoint.DATA_FORMAT).map((key) => {
      return {
        id: ContactPoint.DATA_FORMAT[key].KEY,
        name: ContactPoint.DATA_FORMAT[key].STRING,
      };
    });
  }

  getDataGroupings() {
    return Object.keys(ContactPoint.DATA_GROUPING).map((key) => {
      return {
        id: ContactPoint.DATA_GROUPING[key].KEY,
        name: ContactPoint.DATA_GROUPING[key].STRING,
      };
    });
  }

  getEmailReceiversChipList() {
    return (
      <div>
        <InputLabel className="text-13px">E-Mail Empfänger</InputLabel>
        <div className="flex-s-c gap-8px w-full">
          <MuiChip
            onClick={this.onAddNewEmailReceiver}
            key="new-option"
            label="+ E-Mail Empfänger"
            sx={{
              cursor: 'pointer',
              backgroundColor: 'white',
              color: '#173C88',
              border: '#173C88 solid 1px', // primary500
              '&:hover': {
                backgroundColor: '#E4E6ED',
              },
            }}
          />
          <BasicForm
            open={this.state.emailReceiverFormOpen}
            formSuccess={this.emailReceiverFormSuccess}
            formAbort={this.emailReceiverFormAbort}
            title="E-Mail Empfänger Hinzufügen"
          >
            <div className="w-350px">
              <TextField
                title="E-Mail Empfänger"
                value={this.state.newEmailReceiver}
                onChange={this.onChangeNewEmailReceiver}
                placeholder="Name"
                autoComplete="off"
                required
                autoFocus
                fullWidth
              />
            </div>
          </BasicForm>
          <ChipList
            items={this.state.contactPoint.emailReceivers.map(
              (emailReceiver) => {
                return { id: emailReceiver, name: emailReceiver };
              },
            )}
            onDelete={this.onDeleteEmailReceiver}
            deletable
          />
        </div>
      </div>
    );
  }

  onAddNewEmailReceiver = (event) => {
    event.currentTarget.blur();

    this.setState({
      emailReceiverFormOpen: true,
    });
  };
  onChangeNewEmailReceiver = (event) => {
    this.setState({
      newEmailReceiver: event.target.value,
    });
  };
  emailReceiverFormSuccess = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const newContactPoint = cloneDeep(this.state.contactPoint);

    newContactPoint.emailReceivers.push(this.state.newEmailReceiver);

    this.setState({
      contactPoint: newContactPoint,
      emailReceiverFormOpen: false,
      newEmailReceiver: '',
    });
  };
  emailReceiverFormAbort = () => {
    this.setState({
      emailReceiverFormOpen: false,
      newEmailReceiver: '',
    });
  };
  onDeleteEmailReceiver = (emailReceiver) => {
    const newContactPoint = cloneDeep(this.state.contactPoint);

    newContactPoint.emailReceivers = newContactPoint.emailReceivers.filter(
      (item) => item !== emailReceiver,
    );

    this.setState({
      contactPoint: newContactPoint,
    });
  };

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        formDelete={this.renderForCreate() ? null : this.formDelete}
        title={
          'Empfänger ' +
          (this.renderForCreate() ? 'Erstellen' : this.props.contactPoint.name)
        }
        fullWidth
        submittingForm={this.state.submittingForm}
        deletingForm={this.state.deletingForm}
        unsavedChanges={this.getUnsavedChanges()}
        disableSubmitButton={
          !(this.state.contactPoint?.emailReceivers?.length > 0)
        }
        id={this.props.contactPoint?.id}
      >
        <Grid container direction="row" spacing={3} space={4}>
          <Grid item xs={12} lg={12}>
            <h3 className="main-text mt-0">Empfänger</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="name-input"
                  name="name"
                  label="Name"
                  type="text"
                  disabled={!this.renderForCreate()}
                  required
                  fullWidth
                  value={this.state.contactPoint?.name}
                  onChange={this.handleInputChange}
                  autoFocus={this.renderForCreate()}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                {this.getEmailReceiversChipList()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="dln_filename_placeholder-input"
                  name="dln_filename_placeholder"
                  label="Dateiname"
                  type="text"
                  fullWidth
                  value={this.state.contactPoint?.dlnFilenamePlaceholder}
                  onChange={this.handleInputChange}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="email_subject-input"
                  name="email_subject"
                  label="Betreffzeile"
                  type="text"
                  fullWidth
                  value={this.state.contactPoint?.emailSubject}
                  onChange={this.handleInputChange}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              {/* <Grid item xs={6} lg={4}>
                                <Select
                                    title="Übertragungsweg"
                                    value={this.state.contactPoint.integrationType}
                                    onChange={this.handleChangeIntegrationType}
                                    options={this.getIntegrationTypes()}
                                />
                            </Grid> */}
              <Grid item xs={6} lg={4}>
                <Select
                  title="Dateityp"
                  value={this.state.contactPoint.dataFormat}
                  onChange={this.handleChangeDataFormat}
                  options={this.getDataFormats()}
                  required
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <Select
                  title="Gruppierung"
                  value={this.state.contactPoint.dataGrouping}
                  onChange={this.handleChangeDataGrouping}
                  options={this.getDataGroupings()}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BasicForm>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(ContactPointForm);
