import { configureStore } from '@reduxjs/toolkit';

// FIXME: this shit has some fucked up circular dependencies, so the order of the imports matters!!!!
import userinfo from './userinfoSlice';
import users from './usersSlice';
import companyAccount from './companyAccountSlice';
import companies from './companiesSlice';
import sites from './sitesSlice';
import costCenters from './costCentersSlice';
import vehicles from './vehiclesSlice';
import organisationalGroups from './organisationalGroupsSlice';
import userGroups from './userGroupsSlice';
import deliveryNotes from './deliveryNotesSlice';
import invoices from './invoicesSlice';
import backdrop from './backdropSlice';
import siteReferences from './siteReferencesSlice';
import customerSubscriptions from './customerSubscriptionsSlice';
import pdfTemplates from './pdfTemplatesSlice';
import filters from './filtersSlice';
import devTools from './devToolsSlice';
import customFields from './customFieldsSlice';
import signatureFields from './signatureFieldsSlice';
import articleMaster from './articleMasterSlice';
import dataExchanges from './dataExchangesSlice';
import dataSubscriptions from './dataSubscriptionsSlice';
import home from './homeSlice';
import menu from './menuSlice';

const store = configureStore({
  reducer: {
    articleMaster,
    backdrop,
    companies,
    companyAccount,
    costCenters,
    customerSubscriptions,
    customFields,
    dataExchanges,
    dataSubscriptions,
    deliveryNotes,
    devTools,
    filters,
    home,
    invoices,
    menu,
    organisationalGroups,
    pdfTemplates,
    signatureFields,
    siteReferences,
    sites,
    userGroups,
    userinfo,
    users,
    vehicles,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  window.store = store; // Assuming `store` is your Redux store instance
}

export default store;
