import axios from '~/utils/api-client';
import Config from '~/Config';
import CostCenter from '~/models/masterdata/CostCenter';
import store from '~/redux/store';
import { es6ClassFactory as ES6ClassFactory } from '~/utils/ES6ClassFactory';
import CacheService from './cache.service';
import Log from '~/utils/Log';
import { LOADING_STATE } from '~/constants/LoadingState';
import { promiseHandler } from '~/utils/promiseHandler';
import {
  replaceCostCenter,
  replaceCostCenters,
  setCostCentersLoading,
} from '~/redux/costCentersSlice';

const API_URL = Config.apiUrl + '/accounting_reference';

class CostCenterService {
  constructor() {
    this.costCentersLoading = LOADING_STATE.NOT_LOADED;
    this.costCenters = [];
    this.costCentersBulk = [];
  }

  async getAllCostCenters() {
    return axios.get(API_URL + '/all').then((response) => {
      if (response.status !== 200) {
        return [];
      }

      return response.data.items
        .filter((item) => item.type === 'cost_center')
        .map((item) => new CostCenter(item));
    });
  }

  // search for cost center in store. if not found, load all cost centers from backend
  // return empty cost center if no cost center could be found
  getCostCenterById = async (costCenterId) => {
    let costCenter = store
      .getState()
      .costCenters?.costCenters?.find(
        (costCenter) => costCenter.id === costCenterId,
      );
    // For more information about why cost centers are cached locally in CostCenterService, check the comments in SiteService.getSiteById.
    if (
      !costCenter &&
      store.getState().costCenters?.costCentersLoading ===
        LOADING_STATE.NOT_LOADED
    ) {
      costCenter = this.costCenters.find(
        (costCenter) => costCenter.id === costCenterId,
      );
    }

    if (costCenter) {
      return ES6ClassFactory.convertToES6Class(
        [costCenter],
        new CostCenter(),
      )[0];
    }

    if (
      store.getState().costCenters?.costCentersLoading ===
        LOADING_STATE.SUCCEEDED ||
      store.getState().costCenters?.costCentersLoading === LOADING_STATE.FAILED
    ) {
      return null;
    }

    if (
      this.costCentersLoading === LOADING_STATE.SUCCEEDED ||
      this.costCentersLoading === LOADING_STATE.FAILED
    ) {
      return null;
    }

    store.dispatch(setCostCentersLoading(LOADING_STATE.LOADING));
    this.costCentersLoading = LOADING_STATE.LOADING;

    const [costCenters, error] = await promiseHandler(this.getAllCostCenters());

    if (error) {
      store.dispatch(setCostCentersLoading(LOADING_STATE.FAILED));
      this.costCentersLoading = LOADING_STATE.FAILED;
      throw error;
    }

    store.dispatch(replaceCostCenters(costCenters));
    this.costCentersLoading = LOADING_STATE.SUCCEEDED;
    this.costCenters = costCenters;

    return (
      costCenters.find((costCenter) => costCenter.id === costCenterId) ?? null
    );
  };
  getCostCenter = async (costCenterId, ignoreCache) => {
    const url = API_URL + '/' + costCenterId;

    if (!ignoreCache) {
      const [cachedValue, error] = CacheService.getCached(url);
      if (cachedValue) {
        return cachedValue;
      }

      if (error) {
        throw error;
      }
    }

    return axios
      .get(url)
      .then((response) => {
        if (response?.status !== 200) {
          Log.warn('GET /accounting-reference did not return 200', {
            status: response?.status,
          });
        }

        const costCenter = new CostCenter(response?.data, true);
        CacheService.setCached(url, costCenter);
        return costCenter;
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  };

  async createNewCostCenter(body) {
    body = {
      ...body,
      type: 'cost_center',
    };

    return axios.post(API_URL, body).then((response) => {
      return response.data?.id;
    });
  }

  async updateCostCenter(id, body) {
    body = {
      ...body,
      type: 'cost_center',
    };

    return axios.put(API_URL + '/' + id, body);
  }

  loadCostCenters = async () => {
    // to not load cost centers again when they are already loading or have already been loaded
    if (
      store.getState().costCenters?.costCentersLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshCostCenters();
  };
  refreshCostCenters = async () => {
    store.dispatch(setCostCentersLoading(LOADING_STATE.LOADING));

    const [costCenters, error] = await promiseHandler(this.getAllCostCenters());

    if (error) {
      store.dispatch(setCostCentersLoading(LOADING_STATE.FAILED));
      Log.error('Failed to load cost centers.', error);
      Log.productAnalyticsEvent(
        'Failed to load cost centers',
        Log.FEATURE.COST_CENTER,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceCostCenters(costCenters));
  };
  refreshCostCenter = async (costCenterId) => {
    const [costCenter, error] = await promiseHandler(
      this.getCostCenter(costCenterId, true),
    );

    if (error) {
      Log.error('Failed to load cost center.', error);
      Log.productAnalyticsEvent(
        'Failed to load cost center',
        Log.FEATURE.COST_CENTER,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceCostCenter(costCenter));
  };
  loadCostCentersBulk = async (ids) => {
    return axios.post(API_URL + '/query/bulk', { ids }).then((response) => {
      this.costCentersBulk = response.data.items.map(
        (item) => new CostCenter(item),
      );
    });
  };
  getCostCenterFromCostCentersBulk = (costCenterId) => {
    return (
      this.costCentersBulk.find(
        (costCenter) => costCenter.id === costCenterId,
      ) ?? null
    );
  };
}

export default new CostCenterService();
