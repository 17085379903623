import React from 'react';
import { withErrorBoundary } from '~/ui/atoms';
import InvoiceCheck from '../InvoiceCheck';
import InvoiceCheckCategory from './InvoiceCheckCategory';
import { DeliveryNoteLinks } from '~/utils/componentUtils';

class AmountCheck extends React.Component {
  render() {
    const errorInvoiceChecks = (
      <>
        {this.props.category.errorChecks.map((checkResult, index) => (
          <InvoiceCheck key={index}>
            <div className="w-full">
              <div>
                Der Artikel{' '}
                <span className="text-error500">
                  "{checkResult.articleName ?? ''}"
                </span>{' '}
                enthält eine andere Menge oder Einheit als in der{' '}
                <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />{' '}
                ausgewiesen.
              </div>
              <div className="flex-s-e w-full">
                <div className="w-half text-error500">
                  Rechnung: {checkResult.invoiceValue ?? '-'}
                </div>
                <div className="w-half">
                  Lieferung: {checkResult.expectedValue ?? '-'}
                </div>
              </div>
            </div>
          </InvoiceCheck>
        ))}
      </>
    );

    const successInvoiceChecks = (
      <>
        {this.props.category.successChecks.map((checkResult, index) => (
          <InvoiceCheck key={index}>
            <div className="w-full">
              Der Artikel "{checkResult.articleName ?? ''}" enthält die gleiche
              Menge und Einheit wie in der{' '}
              <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />{' '}
              ausgewiesen.
            </div>
          </InvoiceCheck>
        ))}
      </>
    );

    return (
      <InvoiceCheckCategory
        category={this.props.category}
        errorInvoiceChecks={errorInvoiceChecks}
        successInvoiceChecks={successInvoiceChecks}
      />
    );
  }
}

export default withErrorBoundary(
  AmountCheck,
  'Prüfschritt "Mengenabgleich" konnte nicht geladen werden.',
);
