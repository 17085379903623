import axios from '~/utils/api-client';
import Config from '~/Config';
import Log from '~/utils/Log';
import store from '~/redux/store';
import { LOADING_STATE } from '~/constants/LoadingState';
import { promiseHandler } from '~/utils/promiseHandler';
import {
  replaceSignatureFields,
  setSignatureFieldsLoading,
} from '~/redux/signatureFieldsSlice';
import SignatureField from '~/models/signatureField/SignatureField';

const API_URL = Config.apiUrl + '/signature_field';

class SignatureFieldService {
  async getAllSignatureFields() {
    return axios.get(API_URL + '/all').then((response) => {
      if (response.status !== 200) {
        return [];
      }

      return response.data.items.map((item) => new SignatureField(item));
    });
  }

  async createSignatureField(body) {
    return axios.post(API_URL, body).then((response) => {
      return response.data?.id;
    });
  }

  async updateSignatureField(id, body) {
    return axios.put(API_URL + '/' + id, body);
  }

  async deleteSignatureField(id) {
    return axios.delete(API_URL + '/' + id);
  }

  loadSignatureFields = async () => {
    // to not load sites again when they are already loading or have already been loaded
    if (
      store.getState().signatureFields?.signatureFieldsLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshSignatureFields();
  };
  refreshSignatureFields = async () => {
    store.dispatch(setSignatureFieldsLoading(LOADING_STATE.LOADING));

    const [signatureFields, error] = await promiseHandler(
      this.getAllSignatureFields(),
    );

    if (error) {
      store.dispatch(setSignatureFieldsLoading(LOADING_STATE.FAILED));
      Log.error('Failed to load signature fields.', error);
      Log.productAnalyticsEvent(
        'Failed to load signature fields',
        Log.FEATURE.SIGNATURE_FIELD,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceSignatureFields(signatureFields));
  };
}

export default new SignatureFieldService();
