import {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
  Fragment,
} from 'react';

import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowUpward as ArrowUpwardIcon,
  Delete as DeleteIcon,
  Domain as DomainIcon,
  PersonOutline as PersonOutlineIcon,
  Search as SearchIcon,
} from '@mui/icons-material';

import { useDispatch, useSelector } from 'react-redux';
import {
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  TextField,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import { LOADING_STATE } from '~/constants/LoadingState';
import Log from '~/utils/Log';
import {
  setSuppliers_selectCompany,
  setSuppliers_sortModel,
  setSuppliers_responsiblePeople,
} from '~/redux/filtersSlice';
import DataExchangeService from '~/services/dataExchange.service';

const useStyles = makeStyles((theme) => ({
  popover: {
    '& .MuiPaper-root': {
      borderRadius: '0.5rem',
      boxShadow: '0px 4px 8px 0px rgba(23, 60, 136, 0.20)',
      marginTop: '0.25rem',
    },
  },
  filter_container: {
    '& .MuiPaper-root': {
      height: '18.875rem',
    },
    '& .content-container': {
      overflow: 'hidden',
    },

    '& .MuiOutlinedInput-input': {
      paddingTop: '0.90625rem',
      paddingBottom: '0.90625rem',
    },

    '& .search-input': {
      padding: '1rem',
    },

    '& .select-list': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'flex-start',
      height: '100%',

      '& .empty-state': {
        textAlign: 'center',
        fontSize: '1.125rem',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
      },
    },

    '& .MuiFormControlLabel-root': {
      margin: `0 !important`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: `0.6875rem 1rem`,
    },
  },

  sort_filter_container: {
    '& .sort-container': {
      padding: '1rem',
    },

    '& .MuiInputBase-root, & .MuiSelect-root': {
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: '0.5rem',
      height: '3rem',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
}));

export const CompanyFilter = () => {
  const defaultCompanies = useSelector((state) => state.companies);
  const selectedCompanies = useSelector(
    (state) => state.filters.suppliers_selectedCompanies,
  );

  const classes = useStyles();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [element, setElement] = useState(null);
  const [companies, setCompanies] = useState([]);

  const open = Boolean(element);
  const id = open ? 'company-filter-popover' : undefined;

  const ALL_COMPANIES = {
    id: '',
    name: 'Alle Firmen',
  };

  useEffect(() => {
    if (defaultCompanies.companiesLoading === LOADING_STATE.SUCCEEDED) {
      const options = [];

      if (defaultCompanies.companies.length === 0) {
        setCompanies([]);
        return;
      }

      if (defaultCompanies.companies.length === 0) {
        setCompanies([]);
        return;
      }

      options.push(ALL_COMPANIES);

      for (const company of defaultCompanies.companies) {
        options.push({
          id: company.id,
          name: company.name,
        });
      }

      setCompanies(options);
    }
  }, [defaultCompanies]);

  const handleInputChange = (event) => {
    const value = event.target.value;

    Log.info(
      'Change value of company search input',
      { from: searchValue, to: value },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );

    setSearchValue(value);
    filterCompanies(value);
    Log.productAnalyticsEvent(
      'Update company search input',
      Log.FEATURE.KANBAN_SUPPLIER_OVERVIEW,
    );
  };

  const filterCompanies = useCallback(
    (searchQuery) => {
      const filteredCompanies = defaultCompanies.companies.filter((company) => {
        if (
          searchQuery !== '' &&
          !company.name.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return false;
        }

        return true;
      });

      if (searchQuery === '') {
        filteredCompanies.unshift(ALL_COMPANIES);
      }

      setCompanies(filteredCompanies);
    },
    [defaultCompanies],
  );

  const handleOnCompanySelect = (event, id) => {
    const checked = event.target.checked;
    let updatedCompanyIds = selectedCompanies;

    // handle all companies
    if (id === '') {
      if (checked) {
        updatedCompanyIds = companies.map((company) => company.id);
        dispatch(setSuppliers_selectCompany(updatedCompanyIds));
      } else {
        dispatch(setSuppliers_selectCompany([]));
      }

      return;
    }

    if (checked) {
      updatedCompanyIds = [...updatedCompanyIds, id];
    } else {
      updatedCompanyIds = updatedCompanyIds?.filter(
        (companyId) => companyId !== id,
      );
    }

    dispatch(setSuppliers_selectCompany([...updatedCompanyIds]));
  };

  const getChipTitle = useMemo(() => {
    const selectedCompaniesWithoutAll = selectedCompanies.filter(Boolean);

    if (
      selectedCompaniesWithoutAll.length <= 0 ||
      defaultCompanies.companiesLoading !== LOADING_STATE.SUCCEEDED
    ) {
      return 'Firma';
    }

    const firstCompany = defaultCompanies.companies.find(
      (company) => company.id === selectedCompaniesWithoutAll[0],
    );

    if (selectedCompaniesWithoutAll.length > 1) {
      return `${firstCompany?.name} + ${selectedCompanies.length - 1} Weitere`;
    }

    return `${firstCompany?.name}`;
  }, [selectedCompanies, defaultCompanies]);

  return (
    <div className="filter-item">
      <Chip
        variant="outlined"
        onDelete={(event) => {
          setElement(event.currentTarget);
        }}
        deleteIcon={<ArrowDropDownIcon />}
        onClick={(event) => {
          setElement(event.currentTarget);
        }}
        data-active={selectedCompanies?.length > 0 ? 'true' : 'false'}
        avatar={<DomainIcon />}
        label={getChipTitle}
      />

      <Popover
        id={id}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        anchorEl={element}
        onClose={() => setElement(null)}
        className={`${classes.popover} ${classes.filter_container}`}
      >
        <div className="w-360px content-container">
          <div className="search-input">
            <TextField
              value={searchValue}
              onChange={handleInputChange}
              placeholder="Suche nach einer Firma"
              variant="outlined"
              disabled={defaultCompanies.companies.length === 0}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Divider />

          <div className="select-list">
            {defaultCompanies.companies.length === 0 ? (
              <p className="empty-state">No companies available</p>
            ) : (
              <>
                {companies.length > 0 ? (
                  <Fragment>
                    {companies.map((company, index) => {
                      const checked = selectedCompanies.includes(company.id);
                      const isLastItem = companies.length - 1 === index;

                      return (
                        <Fragment key={index}>
                          <FormControlLabel
                            checked={checked}
                            control={
                              <Checkbox checked={checked} color="primary" />
                            }
                            labelPlacement="start"
                            label={company.name}
                            onChange={(event) =>
                              handleOnCompanySelect(event, company.id)
                            }
                          />
                          {!isLastItem && <Divider />}
                        </Fragment>
                      );
                    })}
                  </Fragment>
                ) : (
                  <p className="empty-state">Keine passenden Firmen gefunden</p>
                )}
              </>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export const SortFilter = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sortData = useSelector((state) => state.filters.suppliers_sort_model);
  const [element, setElement] = useState(null);

  const open = Boolean(element);
  const id = open ? 'sort-popover' : undefined;

  const handleOnChange = (event) => {
    const { name, value } = event.target;

    dispatch(
      setSuppliers_sortModel({
        ...sortData,
        [name]: value,
      }),
    );
  };

  const removeActiveSort = () => {
    dispatch(
      setSuppliers_sortModel({
        sortKey: '',
        sortDirection: '',
      }),
    );
  };

  const labelKeyMap = {
    alphabetically: 'Alphabetisch',
    invoice_status: 'Rechnungsstatus',
    last_contact: 'Letzter Kontaktversuch',
  };

  const currentSortLabel = labelKeyMap[sortData?.sortKey];
  const directionIcon =
    sortData?.sortDirection === 'ASC' ? (
      <ArrowUpwardIcon fontSize="large" />
    ) : (
      <ArrowDownwardIcon fontSize="large" />
    );

  return (
    <div className="filter-item">
      <Chip
        onClick={(event) => setElement(event.currentTarget)}
        avatar={directionIcon}
        deleteIcon={<ArrowDropDownIcon />}
        onDelete={(event) => {
          setElement(event.currentTarget);
        }}
        data-active={currentSortLabel ? 'true' : 'false'}
        variant="outlined"
        label={currentSortLabel ?? 'Keine Sortierung'}
      />
      <Popover
        id={id}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        anchorEl={element}
        onClose={() => setElement(null)}
        className={`${classes.popover} ${classes.sort_filter_container}`}
      >
        <div className="w-480px sort-container flex-c-c gap-16px">
          <Select
            name="sortKey"
            variant="outlined"
            fullWidth
            onChange={handleOnChange}
            value={sortData.sortKey}
          >
            <MenuItem value="alphabetically">Alphabetisch</MenuItem>
            <MenuItem value="invoice_status">Rechnungsstatus</MenuItem>
            {/* <MenuItem value="last_contact">Letzter Kontaktversuch</MenuItem> */}
          </Select>

          <Select
            name="sortDirection"
            variant="outlined"
            fullWidth
            onChange={handleOnChange}
            value={sortData.sortDirection}
          >
            <MenuItem value="ASC">Aufsteigend</MenuItem>
            <MenuItem value="DESC">Absteigend</MenuItem>
          </Select>

          <IconButton onClick={removeActiveSort} size="large">
            <DeleteIcon htmlColor="#343A4D" />
          </IconButton>
        </div>
      </Popover>
    </div>
  );
};

export const ResponsiblePersonFilter = () => {
  const classes = useStyles();
  const defaultPeople = useSelector(
    (state) => state?.dataExchanges?.responsiblePeople,
  );
  const selectedResponsiblePeople = useSelector(
    (state) => state.filters?.suppliers_responsible_people,
  );
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [element, setElement] = useState(null);
  const [people, setPeople] = useState([]);
  const unfilteredPeople = useRef([]);

  const open = Boolean(element);
  const id = open ? 'responsible-filter' : undefined;

  const ALL_RESPONSIBLE_PEOPLE = {
    id: '',
    name: 'Alle Verantwortlichen',
  };

  useEffect(() => {
    DataExchangeService.loadAllResponsiblePeople();
  }, []);

  useEffect(() => {
    const options = [];

    if (defaultPeople.length === 0) {
      setPeople([]);
      unfilteredPeople.current = [];
      return;
    }

    if (defaultPeople.length === 0) {
      setPeople([]);
      unfilteredPeople.current = [];
      return;
    }

    options.push(ALL_RESPONSIBLE_PEOPLE);

    for (const person of defaultPeople) {
      options.push({
        id: person.id,
        name: `${person?.first_name} ${person?.last_name}`,
      });
    }

    setPeople(options);
    unfilteredPeople.current = options;
  }, [defaultPeople]);

  const handlePersonSelect = (event, id) => {
    const checked = event.target.checked;
    let updatedpeopleIds = selectedResponsiblePeople;

    // handle all people
    if (id === '') {
      if (checked) {
        updatedpeopleIds = people.map((people) => people.id);
        dispatch(setSuppliers_responsiblePeople(updatedpeopleIds));
      } else {
        dispatch(setSuppliers_responsiblePeople([]));
      }

      return;
    }

    if (checked) {
      updatedpeopleIds = [...updatedpeopleIds, id];
    } else {
      updatedpeopleIds = updatedpeopleIds?.filter(
        (peopleId) => peopleId !== id,
      );
    }

    dispatch(setSuppliers_responsiblePeople([...updatedpeopleIds]));
  };

  const handleInputChange = (event) => {
    const value = event.target.value;

    Log.info(
      'Change value of company search input',
      { from: searchValue, to: value },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );

    setSearchValue(value);

    filterPeople(value);
    Log.productAnalyticsEvent(
      'Update company search input',
      Log.FEATURE.KANBAN_SUPPLIER_OVERVIEW,
    );
  };

  const filterPeople = useCallback(
    (searchQuery) => {
      const filteredPeople = unfilteredPeople?.current?.filter((person) => {
        if (
          searchQuery !== '' &&
          !person.name.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return false;
        }

        return true;
      });

      setPeople(filteredPeople);
    },
    [defaultPeople],
  );

  const getChipLabel = useMemo(() => {
    const selectedPeopleWithoutAll = selectedResponsiblePeople.filter(Boolean);
    const firstPerson = defaultPeople.find(
      (person) => person.id === selectedPeopleWithoutAll[0],
    );

    if (selectedPeopleWithoutAll.length <= 0 || !firstPerson) {
      return 'Verantwortlicher';
    }

    if (selectedPeopleWithoutAll.length > 1) {
      return `${firstPerson?.first_name} ${firstPerson?.last_name} + ${selectedResponsiblePeople.length - 1} Weitere`;
    }

    return `${firstPerson?.first_name} ${firstPerson?.last_name}`;
  }, [selectedResponsiblePeople, defaultPeople]);

  return (
    <div className="filter-item">
      <Chip
        variant="outlined"
        deleteIcon={<ArrowDropDownIcon />}
        onDelete={(event) => {
          setElement(event.currentTarget);
        }}
        onClick={(event) => {
          setElement(event.currentTarget);
        }}
        data-active={selectedResponsiblePeople?.length > 0 ? 'true' : 'false'}
        avatar={<PersonOutlineIcon />}
        label={getChipLabel}
      />

      <Popover
        id={id}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        anchorEl={element}
        onClose={() => setElement(null)}
        className={`${classes.popover} ${classes.filter_container}`}
      >
        <div className="w-360px content-container">
          <div className="search-input">
            <TextField
              value={searchValue}
              onChange={handleInputChange}
              disabled={!unfilteredPeople?.current?.length}
              placeholder="Suche nach einem Verantwortlichen"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Divider />

          <div className="select-list">
            {unfilteredPeople?.current?.length ? (
              <>
                {people.length > 0 ? (
                  <Fragment>
                    {people.map((person, index) => {
                      const checked = selectedResponsiblePeople.includes(
                        person.id,
                      );
                      const isLastItem = people.length - 1 === index;

                      return (
                        <Fragment key={index}>
                          <FormControlLabel
                            checked={checked}
                            control={
                              <Checkbox checked={checked} color="primary" />
                            }
                            labelPlacement="start"
                            label={person.name}
                            onChange={(event) =>
                              handlePersonSelect(event, person.id)
                            }
                          />
                          {!isLastItem && <Divider />}
                        </Fragment>
                      );
                    })}
                  </Fragment>
                ) : (
                  <p className="empty-state">
                    Keine passende verantwortliche Person gefunden
                  </p>
                )}
              </>
            ) : (
              <p className="empty-state">
                Es gibt noch keine verantwortlichen Personen
              </p>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};
