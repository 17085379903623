import Config from '~/Config';
import axios from '~/utils/api-client';
import PushNotificationSettings from '~/models/masterdata/PushNotificationSettings';

const API_URL = Config.apiUrl + '/push';

class PushService {
  openDeliveryNote(id) {
    return axios.post(API_URL + '/open_dln', { asset_id: id });
  }

  requestSignature(deliveryNoteIds, userIds) {
    return axios.post(API_URL + '/request_signature', {
      asset_ids: deliveryNoteIds,
      recipients: userIds,
    });
  }

  async getSettings(user_id) {
    return axios
      .get(Config.apiUrl + '/user/notification/settings', {
        params: { user_id },
      })
      .then((response) => {
        return new PushNotificationSettings(response.data);
      });
  }

  async updateSettings(user_id, body) {
    return axios
      .put(Config.apiUrl + '/user/notification/settings', body, {
        params: { user_id },
      })
      .then((response) => {
        return new PushNotificationSettings(response.data);
      });
  }
}

export default new PushService();
