const PLURALIZED_DICT = [
  ['Lieferung', 'Lieferungen'],
  ['Rechnung', 'Rechnungen'],
  ['Artikel', 'Artikel'],
  ['liegt', 'liegen'],
  ['wurde', 'wurden'],
  ['konnte', 'konnten'],
  ['der', 'die'],
  ['Der', 'Die'],
  ['folgende', 'folgenden'],
  ['enthält', 'enthalten'],
  ['wird', 'werden'],
];

export function pluralizeString(count, string, withCount) {
  const isPlural = count !== 1;

  const words = string.split(' ');

  const pluralizedString = words
    .map((word) => pluralizeWord(count, word, false))
    .join(' ');

  if (withCount) {
    return isPlural
      ? count + ' ' + pluralizedString
      : count + ' ' + pluralizedString;
  }

  return isPlural ? pluralizedString : pluralizedString;
}

export function pluralizeWord(count, word, withCount) {
  const isPlural = count !== 1;

  const dictEntry = PLURALIZED_DICT.find((dictEntry) => dictEntry[0] === word);

  if (withCount) {
    return isPlural ? count + ' ' + dictEntry[1] : count + ' ' + dictEntry[0];
  }

  return isPlural ? dictEntry[1] : dictEntry[0];
}
