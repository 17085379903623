import { createSlice } from '@reduxjs/toolkit';

const backdropSlice = createSlice({
  name: 'backdrop',
  initialState: {
    id: '',
    open: false,
    message: '',
  },
  reducers: {
    openBackdrop(state, parameter) {
      const { payload } = parameter;

      state.id = payload.id;
      state.open = true;
      state.message = payload.message ?? '';
    },
    closeBackdrop(state, parameter) {
      const { payload } = parameter;

      if (payload.id === state.id) {
        state.open = false;
        state.message = '';
      }
    },
  },
});

const { actions, reducer } = backdropSlice;
export const { closeBackdrop, openBackdrop } = actions;
export default reducer;
