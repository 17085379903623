import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Person as PersonFilledIcon,
  PersonOutline as PersonOutlineIcon,
  Phone as PhoneIcon,
  PhoneOutlined as PhoneOutlinedIcon,
} from '@mui/icons-material';

import { FilterPinIcon, FilterPinFilledIcon } from '~/assets/icons';

import Log from '~/utils/Log';
import DataExchangeService from '~/services/dataExchange.service';
import { promiseHandler } from '~/utils/promiseHandler';

import companyService from '~/services/company.service';
import { LOADING_STATE } from '~/constants/LoadingState';

import { ContactAttempt, ContactPerson, CustomerNumber } from './components';
import { useStyles } from './styles';

export const useSupplierDetailModal = ({
  buyerCompany,
  onClose,
  onSupplierChange,
  supplier,
}) => {
  const classes = useStyles();

  const eligiblePeople = useSelector(
    (state) => state.dataExchanges.eligiblePeople,
  );

  const exchange = supplier.dataExchange;

  const [contactLinkState, setContactStateLink] = useState({
    data: exchange?.contactingLink,
    step: exchange?.contactingLink?.status || 'initial-step',
    loadingState: LOADING_STATE.NOT_LOADED,
  });

  const [selectedPerson, setSelectedPerson] = useState(
    exchange?.responsiblePerson?.id,
  );
  const [contactAttempts, setContactAttempts] = useState(
    exchange?.contactAttempts,
  );

  const [logo, setLogo] = useState(null);

  const loadEligibleResponsiblePeople = useCallback(async () => {
    const [, error] = await promiseHandler(
      DataExchangeService.loadEligibleResponsiblePeopleForDataExchange(
        exchange.id,
      ),
    );

    if (!error) {
      return;
    }

    Log.error('Failed to load eligible people', error);
    Log.productAnalyticsEvent(
      'Failed to load eligible people',
      Log.FEATURE.KANBAN_SUPPLIER_OVERVIEW,
      Log.TYPE.ERROR,
    );
  }, [exchange.id]);

  const loadCompanyLogo = async () => {
    const [logoData, error] = await promiseHandler(
      companyService.getCompanyLogo(supplier.id),
    );

    if (error) {
      Log.error('Failed to load logo. company id: ' + supplier.id, error);
      Log.productAnalyticsEvent(
        'Failed to load logo',
        Log.FEATURE.SUPPLIER_OVERVIEW,
        Log.TYPE.ERROR,
      );
      this.setState({
        logoLoading: LOADING_STATE.FAILED,
      });
      return;
    }

    if (logoData.size <= 0) {
      return;
    }

    setLogo(URL.createObjectURL(logoData));
  };

  const loadDataExchange = async () => {
    const [dataExchange, error] = await promiseHandler(
      DataExchangeService.loadSingleDataExchange(exchange.id),
    );

    if (error) {
      Log.error('Failed to load data exchange');
      Log.productAnalyticsEvent(
        'Failed to load data exchange',
        Log.FEATURE.KANBAN_SUPPLIER_OVERVIEW,
        Log.TYPE.ERROR,
      );

      return;
    }

    setContactAttempts(dataExchange.contact_attempts);
  };

  useEffect(() => {
    loadEligibleResponsiblePeople();
    loadCompanyLogo();
    loadDataExchange();
  }, []);

  const routes = {
    person: {
      title: 'Ansprechpersonen',
      icon: <PersonOutlineIcon />,
      filledIcon: <PersonFilledIcon />,
      content: (
        <ContactPerson
          supplier={supplier}
          contactLinkState={contactLinkState}
          updateContactSaveLink={setContactStateLink}
          eligiblePeople={eligiblePeople}
          buyerCompany={buyerCompany}
          selectedPerson={selectedPerson}
          setSelectedPerson={setSelectedPerson}
        />
      ),
    },
    number: {
      title: 'Kundennummern',
      icon: <FilterPinIcon />,
      filledIcon: <FilterPinFilledIcon />,
      content: <CustomerNumber contacts={exchange?.activeCustomerIds} />,
    },
    contact_attempts: {
      title: 'Kontaktversuche',
      icon: <PhoneOutlinedIcon />,
      filledIcon: <PhoneIcon />,
      content: (
        <ContactAttempt
          contactAttempts={contactAttempts}
          setContactAttempts={setContactAttempts}
          supplier={supplier}
        />
      ),
    },
  };

  const [activeRoute, setActiveRoute] = useState(Object.keys(routes)?.[0]);

  const handleClose = () => {
    onSupplierChange({
      ...supplier,
      dataExchange: {
        ...exchange,
        contactingLink: contactLinkState?.data,
        responsiblePerson: eligiblePeople.find(
          ({ id }) => id === selectedPerson,
        ),
        contactAttempts,
      },
    });
    onClose();
  };

  return {
    activeRoute,
    classes,
    handleClose,
    logo,
    routes,
    setActiveRoute,
  };
};
