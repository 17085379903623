import { Grid, InputLabel } from '@mui/material';

import { PERMISSION } from '~/constants/PermissionConsts';

import PermissionGrant from '~/models/masterdata/PermissionGrant';
import Site from '~/models/masterdata/Site';

import BasicForm from '~/components/BasicForm';
import ComplexPaginatedEntityMultiPicker from '~/components/baseComponents/inputs/select/ComplexPaginatedEntityMultiPicker';
import DatePicker from '~/components/baseComponents/inputs/date/DatePicker';
import Select from '~/components/baseComponents/inputs/select/Select';

import { SiteIdentifiersTable } from '../SiteIdentifiersTable';
import { OrganisationalGroupPaths } from '../../paths/OrganisationalGroupPaths';
import PermissionGrantEntityTable from '../../permissionGrant/PermissionGrantEntityTable';

import { CostCentersSection, SiteSection } from './components';
import { useSiteForm } from './useSiteForm'; // Assuming you place the hook in the same folder

export const SiteForm = ({
  open,
  type,
  site,
  closeForm,
  onOpenOrganisationalGroup,
  onUpdatedOrganisationalGroupsChange,
}) => {
  const {
    closeCostCenter,
    companies,
    costCenterOpen,
    costCenters,
    deactivateCoupledCostCentersFormOpen,
    formSite,
    getActiveCostCenters,
    getPaths,
    getPickedCostCenters,
    getUnsavedChanges,
    handleCancel,
    handleChangeCompany,
    handleChangeCostCenters,
    handleChangeDate,
    handleChangeOrganisationalGroups,
    handleChangeSiteType,
    handleCheckboxChange,
    handleCostCenterOpen,
    handleInputChange,
    handleSubmit,
    refreshSite,
    renderForCreate,
    setCostCenter,
    setDeactivateCoupledCostCenters,
    setDeactivateCoupledCostCentersFormOpen,
    setSyncCoupledPermissionGrants,
    setSyncCoupledPermissionGrantsFormOpen,
    siteLoading,
    submittingForm,
    syncCoupledPermissionGrantsFormOpen,
    userinfo,
  } = useSiteForm({
    closeForm,
    site,
    type,
  });

  return (
    <BasicForm
      open={open}
      formSuccess={(e) => {
        e.preventDefault();
        handleSubmit(e);
      }}
      formAbort={handleCancel}
      title={'Standort ' + (renderForCreate() ? 'Erstellen' : site?.name)}
      fullWidth
      submittingForm={submittingForm}
      id={site?.id}
      unsavedChanges={getUnsavedChanges()}
    >
      <Grid container direction="row" spacing={3}>
        {site?.organisationalGroupPaths?.length > 0 || siteLoading ? (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <OrganisationalGroupPaths
                  {...getPaths()}
                  onOpenOrganisationalGroup={onOpenOrganisationalGroup}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        <SiteSection
          formSite={formSite}
          handleCheckboxChange={handleCheckboxChange}
          handleInputChange={handleInputChange}
          renderForCreate={renderForCreate}
        />
        <Grid item xs={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <InputLabel>Startdatum</InputLabel>
              <DatePicker
                textFieldProps={{
                  fullWidth: true,
                }}
                value={formSite?.start}
                maxDate={formSite?.end}
                onChange={(value) => handleChangeDate(value, 'start')}
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <InputLabel>Enddatum</InputLabel>
              <DatePicker
                textFieldProps={{
                  fullWidth: true,
                }}
                value={formSite?.end}
                minDate={formSite?.start}
                onChange={(value) => handleChangeDate(value, 'end')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <InputLabel className="text-13px">Art</InputLabel>
              <Select
                value={formSite?.type}
                fullWidth
                onChange={handleChangeSiteType}
                size="small"
                options={Site?.getSiteTypes()}
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <InputLabel className="text-13px">Firma</InputLabel>
              <Select
                value={formSite?.company}
                fullWidth
                onChange={handleChangeCompany}
                size="small"
                options={companies.companies}
                loading={companies.companiesLoading}
                errorText="Firmen konnten nicht geladen werden."
                sortOptions
                sortOptionsByKey="name"
              />
            </Grid>
          </Grid>
        </Grid>
        <CostCentersSection
          closeCostCenter={closeCostCenter}
          costCenterOpen={costCenterOpen}
          costCentersLoading={costCenters.costCentersLoading}
          getActiveCostCenters={getActiveCostCenters()}
          getPickedCostCenters={getPickedCostCenters()}
          handleChangeCostCenters={handleChangeCostCenters}
          handleCostCenterOpen={handleCostCenterOpen}
          setCostCenter={setCostCenter}
        />
        <Grid item xs={12}>
          <h3 className="mt-20px main-text">Ist Teil von...</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <ComplexPaginatedEntityMultiPicker
                entityType={
                  PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY
                }
                pickedIds={formSite?.organisationalGroups}
                callbackPickedItems={handleChangeOrganisationalGroups}
                onChipClick={(organisationalGroup) =>
                  onOpenOrganisationalGroup(
                    organisationalGroup,
                    getUnsavedChanges(),
                  )
                }
                onUpdatedItemsChange={onUpdatedOrganisationalGroupsChange}
              />
            </Grid>
          </Grid>
        </Grid>
        {!renderForCreate() &&
        userinfo.userPermissions.includes(PERMISSION.MODIFY_SITE_REFERENCES) ? (
          <SiteIdentifiersTable siteId={site?.id} />
        ) : null}
        {renderForCreate() ? null : (
          <Grid item xs={12}>
            <PermissionGrantEntityTable
              title="Wer ist auf diesen Standort berechtigt?"
              permissionGrantsFrom={formSite?.permissionGrantsFrom}
              defaultEntities={[site?.id]}
              defaultEntityType={PermissionGrant.ENTITY_TYPE.SITE?.KEY}
              fixedPicker={PermissionGrant.TYPE.ENTITY}
              refreshData={refreshSite}
              loading={siteLoading}
            />
          </Grid>
        )}
      </Grid>
      {syncCoupledPermissionGrantsFormOpen && (
        <BasicForm
          open={syncCoupledPermissionGrantsFormOpen}
          formSuccess={() => setSyncCoupledPermissionGrants(true)}
          formAbort={() => setSyncCoupledPermissionGrantsFormOpen(false)}
          title="Berechtigungen in Kostenstellen übertragen"
          submitButtonTitle="Berechtigungen übertragen"
        >
          {/* Add your form body here based on the current state */}
        </BasicForm>
      )}
      {deactivateCoupledCostCentersFormOpen && (
        <BasicForm
          open={deactivateCoupledCostCentersFormOpen}
          formSuccess={() => setDeactivateCoupledCostCenters(true)}
          formAbort={() => setDeactivateCoupledCostCentersFormOpen(false)}
          title={
            'Zugewiesene Kostenstellen ' +
            (formSite?.active ? 'aktivieren' : 'deaktivieren')
          }
          submitButtonTitle={
            'Kostenstellen ' +
            (formSite?.active ? 'aktivieren' : 'deaktivieren')
          }
        >
          {/* Add your form body here based on the current state */}
        </BasicForm>
      )}
    </BasicForm>
  );
};
