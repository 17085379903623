import React from 'react';
import { Grid } from '@mui/material';
import { withErrorBoundary } from '~/ui/atoms';
import { EditingHistorySafe as EditingHistory } from './EditingHistorySafe';
import ValueGroup from '~/models/deliveries/ValueGroup';
import Address from '~/models/masterdata/Address';
import { dateUtils } from '~/utils/dateUtils';

class DeliveryNoteTrader extends React.Component {
  getEmailSubject() {
    return (
      'Lieferung ' +
      ValueGroup.getCurrentValue(this.props.deliveryNote.number) +
      ' vom ' +
      dateUtils.getFormattedDate_safe(
        ValueGroup.getCurrentValue(this.props.deliveryNote.dlnDate),
        dateUtils.DATE_FORMAT.DD_MM_YYYY,
        dateUtils.DATE_FORMAT.YYYY_MM_DD__HH_mm_ss_SSSSSS,
      )
    );
  }

  render() {
    return (
      <>
        <Grid container spacing="20px">
          <Grid item xs={12} lg={3}>
            <div>Händler</div>
            <div className="bold">
              <EditingHistory value={this.props.trader?.name} />
            </div>
          </Grid>
          <Grid item xs={12} lg={3}>
            <div>Adresse</div>
            <div className="bold">
              <EditingHistory
                value={ValueGroup.applyFunction(
                  this.props.trader?.address,
                  Address.getConcatenatedAddress,
                )}
              />
            </div>
          </Grid>
        </Grid>
        {this.props.trader?.universalCommunication?.hasCommunicationInformation() ? (
          <Grid container className="mt-20px">
            <Grid item xs={12} lg={3}>
              <div>Kontaktdaten</div>
              {this.props.trader?.universalCommunication?.hasPhone() ? (
                <div
                  className="bold cursor-pointer"
                  onClick={() =>
                    (window.location =
                      'tel:' +
                      ValueGroup.getCurrentValue(
                        this.props.trader?.universalCommunication?.phone,
                      ))
                  }
                >
                  <EditingHistory
                    value={this.props.trader?.universalCommunication?.phone}
                  />
                </div>
              ) : null}
              {this.props.trader?.universalCommunication?.hasEmail() ? (
                <div
                  className="bold cursor-pointer"
                  onClick={() =>
                    (window.location =
                      'mailto:' +
                      ValueGroup.getCurrentValue(
                        this.props.trader?.universalCommunication?.email,
                      ) +
                      '?subject=' +
                      this.getEmailSubject())
                  }
                >
                  <EditingHistory
                    value={this.props.trader?.universalCommunication?.email}
                  />
                </div>
              ) : null}
            </Grid>
          </Grid>
        ) : null}
      </>
    );
  }
}

export default withErrorBoundary(
  DeliveryNoteTrader,
  'Daten des Händlers konnten nicht geladen werden.',
);
