import { useSelector } from 'react-redux';

import UniversalCommunication from '~/models/masterdata/UniversalCommunication';

export const SupportContact = () => {
  const userinfo = useSelector(({ userinfo }) => userinfo);
  const companyAccountId = userinfo?.userinfo?.company?.companyAccount;

  const { email, phone } =
    UniversalCommunication.getSupportContact(companyAccountId);

  return (
    <div className="box-shadow-blue h-full rounded-md bg-white p-4">
      <div className="mb-4 font-bold">Support kontaktieren</div>
      <div className="flex flex-col gap-4">
        <div className="flex w-full flex-wrap">
          <span className="w-20 font-bold">Hotline:</span>
          <span className="ml-auto break-words">{phone}</span>
        </div>
        <div className="flex flex-wrap">
          <span className="w-20 font-bold">E-Mail:</span>
          <span className="ml-auto break-words">{email}</span>
        </div>
      </div>
    </div>
  );
};
