import React from 'react';
import UniversalCommunication from '~/models/masterdata/UniversalCommunication';

class PackageBasicRestrictionMessage extends React.Component {
  render() {
    const { email, phone } = UniversalCommunication.getSalesContact();

    return (
      <span>
        Diese Funktion ist nicht im Paket Basic enthalten. Bei Interesse an
        einer Vorstellung der höheren Pakete, kontaktiere uns über{' '}
        <span
          className="bold cursor-pointer"
          onClick={() =>
            (window.location =
              'mailto:' + email + '?subject=VESTIGAS-Vollversion')
          }
        >
          {email}
        </span>{' '}
        oder{' '}
        <span
          className="bold cursor-pointer"
          onClick={() => (window.location = 'tel:' + phone)}
        >
          {phone}
        </span>
        .
      </span>
    );
  }
}

export default PackageBasicRestrictionMessage;
