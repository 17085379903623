export const mapStateToProps = ({
  companyAccount,
  deliveryNotes,
  filters,
  userinfo,
}) => {
  const {
    deliveries,
    delivery_selectedAcceptState,
    delivery_selectedArticle,
    delivery_selectedArticleNumber,
    delivery_selectedCostCenter,
    delivery_selectedCustomFields,
    delivery_selectedFromSite,
    delivery_selectedNumber,
    delivery_selectedPermittedCostCenters,
    delivery_selectedPermittedToSites,
    delivery_selectedProcessState,
    delivery_selectedRecipient,
    delivery_selectedSettledStatus,
    delivery_selectedSupplier,
    delivery_selectedToSiteRecipient,
    delivery_selectedToSiteSupplier,
    delivery_tab,
    deliveryList_filterModel,
  } = filters;

  return {
    companyAccount, // subscribe to companyAccount state so that update of clientPortal feature flag leads to rerender
    deliveries,
    deliveryNotes,
    filterModel: deliveryList_filterModel,
    selectedAcceptState: delivery_selectedAcceptState,
    selectedArticle: delivery_selectedArticle,
    selectedArticleNumber: delivery_selectedArticleNumber,
    selectedCostCenter: delivery_selectedCostCenter,
    selectedCustomFields: delivery_selectedCustomFields,
    selectedFromSite: delivery_selectedFromSite,
    selectedNumber: delivery_selectedNumber,
    selectedPermittedCostCenters: delivery_selectedPermittedCostCenters,
    selectedPermittedToSites: delivery_selectedPermittedToSites,
    selectedProcessState: delivery_selectedProcessState,
    selectedRecipient: delivery_selectedRecipient,
    selectedSettledStatus: delivery_selectedSettledStatus,
    selectedSupplier: delivery_selectedSupplier,
    selectedTab: delivery_tab,
    selectedToSiteRecipient: delivery_selectedToSiteRecipient,
    selectedToSiteSupplier: delivery_selectedToSiteSupplier,
    userFeatureFlags: userinfo.userinfo.userFeatureFlags,
    userinfo,
  };
};
