import React from 'react';

import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';

import { connect } from 'react-redux';
import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';

import BasicForm from '~/components/BasicForm';
import cloneDeep from 'lodash/cloneDeep';
import ToastService from '~/services/toast.service';
import Select from '~/components/baseComponents/inputs/select/Select';
import ArticleMasterService from '~/services/articleMaster.service';
import Article from '~/models/articleMaster/Article';
import FunctionUtils from '~/utils/functionUtils';
import GenericMultiPicker from '~/components/baseComponents/inputs/select/GenericMultiPicker';
import { LightTooltip } from '~/utils/componentUtils';
import JsonInput from '~/components/JsonInput';
import { validate as uuidvalidate } from 'uuid';
import Company from '~/models/masterdata/Company';
import Site from '~/models/masterdata/Site';
import CostCenter from '~/models/masterdata/CostCenter';

const mapStateToProps = (state) => ({
  articleMaster: state.articleMaster,
  companies: state.companies,
  sites: state.sites,
  costCenters: state.costCenters,
  userinfo: state.userinfo,
});
const mapDispatchToProps = () => ({});

const NEW_OPTION_TYPE = {
  COMPANY: 'company',
  SITE: 'site',
  COST_CENTER: 'cost_center',
};

class ArticleForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      article: new Article(),
      submittingForm: false,
      deletingForm: false,
    };
  }

  componentDidMount() {
    this.resetForm();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.resetForm();
    }
  }

  async resetForm() {
    this.setState({
      article: this.props.article ?? new Article(),
    });
  }

  formSuccess = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      submittingForm: true,
    });

    const body = {
      article_id: this.state.article.articleId,
      name: this.state.article.name,
      description: this.state.article.description,
      ean: this.state.article.ean,
      product: this.state.article.product ?? {},
      handling: this.state.article.handling ?? {},
      additional_data: this.state.article.additionalPartyData ?? {},
      is_active: this.state.article.active,
      is_virtual: this.state.article.virtual,
      equivalent_articles: this.state.article.equivalentArticles,
      // Pass company account of user as default
      owner_company_accounts: this.state.article.ownerCompanyAccounts,
      owner_companies: this.state.article.ownerCompanies,
      access: this.state.article.access,
      filter_companies: this.state.article.filterCompanies,
      filter_sites: this.state.article.filterSites,
      filter_acc_refs: this.state.article.filterCostCenters,
    };

    Log.info('Submit article form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.ARTICLE_MASTER);

    if (this.renderForCreate()) {
      const [response, error] = await promiseHandler(
        ArticleMasterService.createArticle(body),
      );

      if (error) {
        Log.error('Failed to create article.', error);
        ToastService.httpError(
          ['Artikel konnte nicht angelegt werden.'],
          error.response,
        );
        Log.productAnalyticsEvent(
          'Failed to create',
          Log.FEATURE.ARTICLE_MASTER,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }
    } else {
      const [response, error] = await promiseHandler(
        ArticleMasterService.updateArticle(this.props.article.id, body),
      );

      if (error) {
        Log.error(
          'Failed to update article. id: ' + this.props.article.id,
          error,
        );
        ToastService.httpError(
          ['Artikel konnte nicht aktualisiert werden.'],
          error.response,
        );
        Log.productAnalyticsEvent(
          'Failed to update',
          Log.FEATURE.ARTICLE_MASTER,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }
    }

    this.setState({
      submittingForm: false,
    });

    this.props.closeForm();
    this.resetForm();
    ArticleMasterService.refreshArticles(
      this.props.userinfo.userinfo.company.companyAccount,
      this.props.userinfo.userinfo.company.id,
    );
  };
  formAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.ARTICLE_MASTER);
    this.props.closeForm();
    this.resetForm();
  };
  formDelete = async (event) => {
    event.preventDefault();

    Log.info(
      'Delete article',
      { id: this.props.article.id },
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Delete', Log.FEATURE.ARTICLE_MASTER);

    this.setState({
      deletingForm: true,
    });

    const [response, error] = await promiseHandler(
      ArticleMasterService.deleteArticle(this.props.article.id),
    );

    if (error) {
      ToastService.httpError(
        ['Artikel konnte nicht gelöscht werden.'],
        error.response,
      );
      Log.error('Failed to delete article.', error);
      Log.productAnalyticsEvent(
        'Failed to delete',
        Log.FEATURE.ARTICLE_MASTER,
        Log.TYPE.ERROR,
      );
      this.setState({
        deletingForm: false,
      });
      return;
    }

    this.setState({
      deletingForm: false,
    });

    this.props.closeForm();
    this.resetForm();
    await ArticleMasterService.refreshArticles(
      this.props.userinfo.userinfo.company.companyAccount,
      this.props.userinfo.userinfo.company.id,
    );
  };
  renderForCreate = () => {
    return this.props.type === 'create';
  };
  handleInputChange = (event) => {
    const newArticle = cloneDeep(this.state.article);

    switch (event.target.name) {
      case 'articleId': {
        newArticle.articleId = event.target.value;
        newArticle.product.seller_assigned_id = event.target.value;
        Log.info(
          'Change form value of article id',
          { from: this.state.article.articleId, to: newArticle.articleId },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'article_change_articleId',
          Log.productAnalyticsEvent,
          ['Change article id', Log.FEATURE.ARTICLE_MASTER],
        );
        break;
      }

      case 'name': {
        newArticle.name = event.target.value;
        newArticle.product.name = event.target.value;
        Log.info(
          'Change form value of name',
          { from: this.state.article.name, to: newArticle.name },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'article_change_name',
          Log.productAnalyticsEvent,
          ['Change name', Log.FEATURE.ARTICLE_MASTER],
        );
        break;
      }

      case 'description': {
        newArticle.description = event.target.value;
        newArticle.product.description = event.target.value;
        Log.info(
          'Change form value of description',
          { from: this.state.article.description, to: newArticle.description },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'article_change_description',
          Log.productAnalyticsEvent,
          ['Change description', Log.FEATURE.ARTICLE_MASTER],
        );
        break;
      }

      case 'ean': {
        newArticle.ean = event.target.value;
        newArticle.product.ean = event.target.value;
        Log.info(
          'Change form value of ean',
          { from: this.state.article.ean, to: newArticle.ean },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'article_change_ean',
          Log.productAnalyticsEvent,
          ['Change ean', Log.FEATURE.ARTICLE_MASTER],
        );
        break;
      }
    }

    this.setState({
      article: newArticle,
    });
  };
  handleChangeProduct = (product) => {
    const newArticle = cloneDeep(this.state.article);

    newArticle.product = product;
    newArticle.articleId = product.seller_assigned_id ?? '';
    newArticle.name = product.name ?? '';
    newArticle.description = product.description ?? '';
    newArticle.ean = product.ean ?? '';

    Log.info(
      'Change form value of product',
      { from: this.state.article.product, to: newArticle.product },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change product', Log.FEATURE.ARTICLE_MASTER);

    this.setState({
      article: newArticle,
    });
  };
  handleChangeHandling = (handling) => {
    const newArticle = cloneDeep(this.state.article);

    newArticle.handling = handling;

    Log.info(
      'Change form value of handling',
      { from: this.state.article.handling, to: newArticle.handling },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change handling', Log.FEATURE.ARTICLE_MASTER);

    this.setState({
      article: newArticle,
    });
  };
  handleChangeAdditionalPartyData = (additionalPartyData) => {
    const newArticle = cloneDeep(this.state.article);

    newArticle.additionalPartyData = additionalPartyData;

    Log.info(
      'Change form value of additional party data',
      {
        from: this.state.article.additionalPartyData,
        to: newArticle.additionalPartyData,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change additional party data',
      Log.FEATURE.ARTICLE_MASTER,
    );

    this.setState({
      article: newArticle,
    });
  };
  handleCheckboxChange = (event) => {
    const newArticle = cloneDeep(this.state.article);

    switch (event.target.name) {
      case 'is_active': {
        newArticle.active = event.target.checked;
        Log.info(
          'Change form value of active',
          { from: this.state.article.active, to: newArticle.active },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change active checkbox',
          Log.FEATURE.ARTICLE_MASTER,
        );
        break;
      }

      case 'is_virtual': {
        newArticle.virtual = event.target.checked;
        Log.info(
          'Change form value of virtual',
          { from: this.state.article.virtual, to: newArticle.virtual },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change virtual checkbox',
          Log.FEATURE.ARTICLE_MASTER,
        );
        break;
      }

      case 'owner_company_accounts': {
        newArticle.ownerCompanyAccounts = event.target.checked
          ? [this.props.userinfo.userinfo.company.companyAccount]
          : [];
        Log.info(
          'Change form value of owner company account',
          {
            from: this.state.article.ownerCompanyAccounts,
            to: newArticle.ownerCompanyAccounts,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change owner company account checkbox',
          Log.FEATURE.ARTICLE_MASTER,
        );
        break;
      }
    }

    this.setState({
      article: newArticle,
    });
  };
  handleChangeEquivalentArticles = (equivalentArticles) => {
    const newArticle = cloneDeep(this.state.article);

    newArticle.equivalentArticles = equivalentArticles.map(
      (article) => article.id,
    );

    Log.info(
      'Change form value of equivalent articles',
      {
        from: this.state.article.equivalentArticles,
        to: newArticle.equivalentArticles,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change equivalent articles',
      Log.FEATURE.ARTICLE_MASTER,
    );

    this.setState({
      article: newArticle,
    });
  };
  handleChangeOwnerCompanies = (ownerCompanies) => {
    const newArticle = cloneDeep(this.state.article);

    newArticle.ownerCompanies = ownerCompanies.map((company) => company.id);

    Log.info(
      'Change form value of owner companies',
      {
        from: this.state.article.ownerCompanies,
        to: newArticle.ownerCompanies,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change owner companies',
      Log.FEATURE.ARTICLE_MASTER,
    );

    this.setState({
      article: newArticle,
    });
  };
  handleChangeAccess = (event) => {
    const newArticle = cloneDeep(this.state.article);

    newArticle.access = event.target.value;

    Log.info(
      'Change form value of access',
      { from: this.state.article.access, to: newArticle.access },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change access', Log.FEATURE.ARTICLE_MASTER);

    this.setState({
      article: newArticle,
    });
  };
  handleChangeFilterCompanies = (filterCompanies) => {
    const newArticle = cloneDeep(this.state.article);

    newArticle.filterCompanies = filterCompanies.map((company) => company.id);

    Log.info(
      'Change form value of filter companies',
      {
        from: this.state.article.filterCompanies,
        to: newArticle.filterCompanies,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change filter companies',
      Log.FEATURE.ARTICLE_MASTER,
    );

    this.setState({
      article: newArticle,
    });
  };
  handleChangeFilterSites = (filterSites) => {
    const newArticle = cloneDeep(this.state.article);

    newArticle.filterSites = filterSites.map((site) => site.id);

    Log.info(
      'Change form value of filter sites',
      { from: this.state.article.filterSites, to: newArticle.filterSites },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change filter sites',
      Log.FEATURE.ARTICLE_MASTER,
    );

    this.setState({
      article: newArticle,
    });
  };
  handleChangeFilterCostCenters = (filterCostCenters) => {
    const newArticle = cloneDeep(this.state.article);

    newArticle.filterCostCenters = filterCostCenters.map(
      (costCenter) => costCenter.id,
    );

    Log.info(
      'Change form value of filter cost centers',
      {
        from: this.state.article.filterCostCenters,
        to: newArticle.filterCostCenters,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change filter cost centers',
      Log.FEATURE.ARTICLE_MASTER,
    );

    this.setState({
      article: newArticle,
    });
  };

  getUnsavedChanges() {
    if (this.renderForCreate()) {
      return [];
    }

    return Article.getDifferentValues(this.props.article, this.state.article);
  }

  handleNewOptionCreate(option, type) {
    if (uuidvalidate(option.inputValue)) {
      switch (type) {
        case NEW_OPTION_TYPE.COMPANY: {
          return new Company({
            id: option.inputValue,
            name: option.inputValue,
          });
        }

        case NEW_OPTION_TYPE.SITE: {
          return new Site({ id: option.inputValue, name: option.inputValue });
        }

        case NEW_OPTION_TYPE.COST_CENTER: {
          return new CostCenter({
            id: option.inputValue,
            name: option.inputValue,
          });
        }

        default: {
          break;
        }
      }
    } else {
      ToastService.error(['Ungültige ID: Bitte gib eine gültige UUID ein.']);
      Log.productAnalyticsEvent(
        'Invalid UUID',
        Log.FEATURE.ARTICLE_MASTER,
        Log.TYPE.ERROR,
      );
      return null;
    }
  }

  getFilterHint() {
    if (
      this.state.article?.filterCompanies?.length > 0 ||
      this.state.article?.filterSites?.length > 0 ||
      this.state.article?.filterCostCenters?.length > 0
    ) {
      return (
        <div className="text-12px text-grey500 mb-10px">Exklusiv für...</div>
      );
    }

    if (this.state.article?.access === Article.ACCESS.PRIVATE.KEY) {
      return (
        <div className="text-12px text-grey500 mb-10px">
          Dieser Artikel hat aktuell keine Beschränkungen und wird jedem Nutzer
          der oben ausgewählten Firma / Firmenaccount angezeigt.
        </div>
      );
    }

    return (
      <div className="text-12px text-grey500 mb-10px">
        Dieser Artikel hat aktuell keine Beschränkungen und wird jedem Nutzer in
        VESTIGAS angezeigt.
      </div>
    );
  }

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        formDelete={this.renderForCreate() ? null : this.formDelete}
        title={
          'Artikel ' +
          (this.renderForCreate() ? 'Erstellen' : this.props.article.name)
        }
        fullWidth
        submittingForm={this.state.submittingForm}
        deletingForm={this.state.deletingForm}
        unsavedChanges={this.getUnsavedChanges()}
        id={this.props.article?.id}
      >
        <Grid container direction="row" spacing={3} space={4}>
          <Grid item xs={12} lg={12}>
            <h3 className="main-text mt-0">Artikel</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="articleId-input"
                  name="articleId"
                  label="Artikel-ID"
                  type="text"
                  required
                  fullWidth
                  value={this.state.article?.articleId}
                  onChange={this.handleInputChange}
                  autoFocus
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="name-input"
                  name="name"
                  label="Name"
                  type="text"
                  required
                  fullWidth
                  value={this.state.article?.name}
                  onChange={this.handleInputChange}
                  autoComplete="off"
                />
              </Grid>
              {this.renderForCreate() ? null : (
                <Grid item xs={6} lg={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.article?.active}
                        onChange={this.handleCheckboxChange}
                        name="is_active"
                      />
                    }
                    label="Aktiv"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="description-input"
                  name="description"
                  label="Beschreibung"
                  type="text"
                  fullWidth
                  value={this.state.article?.description}
                  onChange={this.handleInputChange}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="ean-input"
                  name="ean"
                  label="EAN"
                  type="number"
                  fullWidth
                  value={this.state.article?.ean}
                  onChange={this.handleInputChange}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">JSON</h3>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <JsonInput
                  title="product"
                  value={this.state.article?.product}
                  onChange={this.handleChangeProduct}
                  minRows={15}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <JsonInput
                  title="handling"
                  value={this.state.article?.handling}
                  onChange={this.handleChangeHandling}
                  minRows={15}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <JsonInput
                  title="additional_party_data"
                  value={this.state.article?.additionalPartyData}
                  onChange={this.handleChangeAdditionalPartyData}
                  minRows={15}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">Generischer Artikel</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8} className="flex-s-c gap-20px">
                <div className="w-300px">
                  <LightTooltip title="Generische Artikel (z.B. Palette) können statt spezifischen Artikeln (z.B. Europalette 100x100) ausgewählt werden und sind in der Rechnungsprüfung ein gleichwertiger Ersatz.">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.article?.virtual}
                          onChange={this.handleCheckboxChange}
                          name="is_virtual"
                        />
                      }
                      label="Generischer Artikel"
                    />
                  </LightTooltip>
                </div>
                <GenericMultiPicker
                  textfieldLabel="Welche Artikel sollen ersetzt werden?"
                  pickedItemIds={
                    this.state.article?.virtual &&
                    this.state.article?.equivalentArticles
                      ? this.state.article?.equivalentArticles
                      : []
                  }
                  allItems={this.props.articleMaster.articles
                    .filter((article) => article.id !== this.props.article?.id)
                    .map((article) => {
                      return {
                        ...article,
                        nameComponent: article.articleId + ' ' + article.name,
                      };
                    })}
                  callbackPickedItems={this.handleChangeEquivalentArticles}
                  loading={this.props.articleMaster.articlesLoading}
                  disabled={!this.state.article?.virtual}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">Zugeordnet zu</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8} className="flex-s-c gap-20px">
                <div className="w-300px">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          this.state.article?.ownerCompanyAccounts?.length > 0
                        }
                        onChange={this.handleCheckboxChange}
                        name="owner_company_accounts"
                      />
                    }
                    label="Firmen-Account"
                  />
                </div>
                <GenericMultiPicker
                  textfieldLabel="Zu welchen Firmen soll der Artikel zugeordnet sein?"
                  pickedItemIds={this.state.article?.ownerCompanies ?? []}
                  allItems={this.props.companies.companies}
                  callbackPickedItems={this.handleChangeOwnerCompanies}
                  loading={this.props.companies.companiesLoading}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">Berechtigung</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <Select
                  value={this.state.article?.access}
                  fullWidth
                  onChange={this.handleChangeAccess}
                  size="small"
                  options={Article.getAccessEnum()}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">
              Dieser Artikel soll exklusiv sein?
            </h3>
            {this.getFilterHint()}
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <GenericMultiPicker
                  textfieldLabel="Firmen"
                  pickedItemIds={this.state.article?.filterCompanies ?? []}
                  allItems={this.props.companies.companies}
                  callbackPickedItems={this.handleChangeFilterCompanies}
                  loading={this.props.companies.companiesLoading}
                  onNewOptionCreate={(option) =>
                    this.handleNewOptionCreate(option, NEW_OPTION_TYPE.COMPANY)
                  }
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <GenericMultiPicker
                  textfieldLabel="Standorte"
                  pickedItemIds={this.state.article?.filterSites ?? []}
                  allItems={this.props.sites.sites}
                  callbackPickedItems={this.handleChangeFilterSites}
                  loading={this.props.sites.sitesLoading}
                  onNewOptionCreate={(option) =>
                    this.handleNewOptionCreate(option, NEW_OPTION_TYPE.SITE)
                  }
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <GenericMultiPicker
                  textfieldLabel="Kostenstellen"
                  pickedItemIds={this.state.article?.filterCostCenters ?? []}
                  allItems={this.props.costCenters.costCenters}
                  callbackPickedItems={this.handleChangeFilterCostCenters}
                  loading={this.props.costCenters.costCentersLoading}
                  onNewOptionCreate={(option) =>
                    this.handleNewOptionCreate(
                      option,
                      NEW_OPTION_TYPE.COST_CENTER,
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BasicForm>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(ArticleForm);
