import { Add as AddIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import { MissingPermissionsTooltip } from '~/utils/componentUtils';
import UserUtils from '~/utils/userUtils';

import SettingsCSVUpload from '../SettingsCSVUpload';

export const getButton = (entity, openCreateForm) => {
  let missingPermissions = false;

  if (entity === 'user' && !UserUtils.isUserCreateAllowedUser()) {
    missingPermissions = true;
  }

  if (entity === 'site' && !UserUtils.isSiteCreateAllowedUser()) {
    missingPermissions = true;
  }

  if (entity === 'costCenter' && !UserUtils.isCostCenterCreateAllowedUser()) {
    missingPermissions = true;
  }

  if (entity === 'vehicle' && !UserUtils.isVehicleCreateAllowedUser()) {
    missingPermissions = true;
  }

  if (entity === 'company' && !UserUtils.isCompanyCreateAllowedUser()) {
    missingPermissions = true;
  }

  if (
    entity === 'organisationalGroup' &&
    !UserUtils.isOrganisationalGroupCreateAllowedUser()
  ) {
    missingPermissions = true;
  }

  if (entity === 'userGroup' && !UserUtils.isUserGroupCreateAllowedUser()) {
    missingPermissions = true;
  }

  if (missingPermissions) {
    return (
      <MissingPermissionsTooltip>
        <Button
          className="disabled-primary-button"
          startIcon={<AddIcon />}
          onClick={openCreateForm}
          disabled
        >
          Erstellen
        </Button>
      </MissingPermissionsTooltip>
    );
  }

  if (['site', 'user'].includes(entity) && UserUtils.isVestigasAccount()) {
    return (
      <SettingsCSVUpload entity={entity} openCreateForm={openCreateForm} />
    );
  }

  return (
    <Button
      className="primary-button"
      startIcon={<AddIcon />}
      onClick={openCreateForm}
    >
      Erstellen
    </Button>
  );
};
