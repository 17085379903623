import { useSelector } from 'react-redux';
import { InputLabel, Checkbox, FormControlLabel } from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import BasicForm from '~/components/BasicForm';
import SearchableSelect from '~/components/baseComponents/inputs/select/SearchableSelect';
import { LightTooltipWide } from '~/utils/componentUtils';

import { useBlacklistSiteForm } from './useBlacklistSiteForm';

export const BlacklistSiteForm = withErrorBoundary(
  ({
    closeForm,
    defaultSelectedSiteId,
    deliveryNote,
    open,
    refreshDeliveryNote,
  }) => {
    const sites = useSelector((state) => state.sites);

    const { handleCancel, handleSubmit, setValue, state } =
      useBlacklistSiteForm({
        closeForm,
        defaultSelectedSiteId,
        deliveryNote,
        refreshDeliveryNote,
        sites,
      });

    return (
      <BasicForm
        open={open}
        formSuccess={handleSubmit}
        formAbort={handleCancel}
        title="Standort von Zuordnung ausschließen"
        submitButtonTitle="Standort ausschließen"
        submittingForm={state.submittingForm}
        noDeleteForm
      >
        <div className="text-13px text-grey600">
          Wähle aus, welcher Standort von der Zuordnung zum Lieferort der
          Lieferung ausgeschlossen und niemals zu diesem zugeordnet werden soll.
        </div>
        <InputLabel className="text-13px mt-20px">Standort</InputLabel>
        <div className="w-600px">
          <SearchableSelect
            options={state.sortedSites}
            value={state.form.selectedSite}
            onChange={(e) => setValue('selectedSite', e.target.value)}
            size="small"
            loading={sites.sitesLoading}
            name="selectedSite"
            withEmptyOption
          />
        </div>
        <div>
          <LightTooltipWide title="Eine Zuordnung basiert normalerweise auf der vollständigen Adresse des Orts. Wenn die Adresse ignoriert wird, wird nur die Bezeichnung des Orts in der Zuordnung verwendet.">
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.form.ignoreAddressInfo}
                  onChange={(e) =>
                    setValue('ignoreAddressInfo', e.target.checked)
                  }
                  name="ignoreAddressInfo"
                />
              }
              label="Nur Lieferort-Bezeichnung verwenden und -Adresse ignorieren für Zuordnung"
            />
          </LightTooltipWide>
        </div>
      </BasicForm>
    );
  },
  null,
);
