import React from 'react';
import { connect } from 'react-redux';
import { SettingsTable } from '../../SettingsTable';
import { withErrorBoundary } from '~/ui/atoms';
import LocalStorageService from '~/services/localStorage.service';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import MapperService from '~/services/mapper.service';

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 400,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'address',
    headerName: 'Adresse',
    width: 400,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 400,
    sortable: true,
    resizableText: true,
  },
];

const mapStateToProps = (state) => ({
  companyAccount: state.companyAccount,
  companies: state.companies,
});

class CompanyTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadData();
    }
  }

  loadData() {
    this.setState({
      rows: this.props.companies?.companies?.map((company) => {
        const row = {
          id: company.id,
          name: company.name,
          address: company.address.getConcatenatedAddress(),
        };

        return MapperService.addSearchString(row, ['id', 'name', 'address']);
      }),
    });
  }

  render() {
    return (
      <SettingsTable
        entity="company"
        title="Firmen"
        rows={this.state.rows}
        columns={columns}
        items={this.props.companies.companies}
        loading={this.props.companies.companiesLoading}
        multiPermissionGrantEdit
        multiPermissionGrantDefaultEntityType={
          PermissionGrant.ENTITY_TYPE.COMPANY.KEY
        }
        multiPermissionGrantFixedPicker={PermissionGrant.TYPE.ENTITY}
        localStorageKey={LocalStorageService.COMPANY_TABLE}
      />
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(CompanyTable),
  'Firmen konnten nicht geladen werden.',
);
