import React from 'react';
import { withErrorBoundary } from '~/ui/atoms';
import InvoiceCheckCategory from './InvoiceCheckCategory';

class PriceCheck extends React.Component {
  render() {
    return <InvoiceCheckCategory category={this.props.category} />;
  }
}

export default withErrorBoundary(
  PriceCheck,
  'Prüfschritt "Preisprüfung" konnte nicht geladen werden.',
);
