import Address from '../masterdata/Address';
import ObjectUtils from '~/utils/objectUtils';

export default class DataExchangeCompany {
  constructor(dataExchangeCompany) {
    this.id = dataExchangeCompany?.company_id ?? '';
    this.companyAccountId = dataExchangeCompany?.company_account_id ?? '';
    this.name = dataExchangeCompany?.name ?? '';
    this.address = new Address(dataExchangeCompany?.address);
    this.isSender = dataExchangeCompany?.is_sender ?? '';
    this.isReceiver = dataExchangeCompany?.is_receiver ?? '';
    this.statusDeliveryNote = dataExchangeCompany?.status_dln ?? '';
    this.statusInvoice = dataExchangeCompany?.status_invoice ?? '';
    this.internalStatusDeliveryNote =
      dataExchangeCompany?.internal_status_dln ?? '';
    this.internalStatusInvoice =
      dataExchangeCompany?.internal_status_invoice ?? '';
    this.comment = dataExchangeCompany?.status_comment ?? '';
    this.internalComment = dataExchangeCompany?.internal_status_comment ?? '';
    this.knownIssues = dataExchangeCompany?.known_issues ?? '';
    this.furtherInformation = dataExchangeCompany?.further_information ?? '';
    this.rank = dataExchangeCompany?.rank ?? '';
    this.blacklisted = dataExchangeCompany?.blacklisted ?? false;
    this.currentIssues = dataExchangeCompany?.current_issues ?? '';
  }

  static getStatusLabel(statusKey) {
    const status = Object.keys(DataExchangeCompany.STATUS).find(
      (x) => DataExchangeCompany.STATUS[x].KEY === statusKey,
    );

    if (!status) {
      return null;
    }

    return DataExchangeCompany.STATUS[status].LABEL;
  }

  static getStatusAbbreviatedLabel(statusKey) {
    const status = Object.keys(DataExchangeCompany.STATUS).find(
      (x) => DataExchangeCompany.STATUS[x].KEY === statusKey,
    );

    if (!status) {
      return null;
    }

    return DataExchangeCompany.STATUS[status].ABBREVIATED_LABEL;
  }

  static getStatusDescription(statusKey) {
    const status = Object.keys(DataExchangeCompany.STATUS).find(
      (x) => DataExchangeCompany.STATUS[x].KEY === statusKey,
    );

    if (!status) {
      return null;
    }

    return DataExchangeCompany.STATUS[status].DESCRIPTION;
  }

  static getHighestStatus(statusKeys) {
    let highestStatusKey = null;

    for (const entry of ObjectUtils.entries(DataExchangeCompany.STATUS)) {
      if (highestStatusKey) {
        continue;
      }

      if (statusKeys.includes(entry.value.KEY)) {
        highestStatusKey = entry.value.KEY;
      }
    }

    return highestStatusKey;
  }

  static TYPE = {
    DATA_EXCHANGE: 'dataExchange',
    DATA_EXCHANGE_COMPANY: 'dataExchangeCompany',
  };
  static STATUS = {
    ACTIVATED: {
      KEY: 'active',
      LABEL: 'Freigeschaltet',
      ABBREVIATED_LABEL: 'Freigeschaltet',
      DESCRIPTION:
        'Die Schnittstelle wurde freigeschaltet und es können Daten ausgetauscht werden',
      HIGHLIGHTED: true,
    },
    LIVE: {
      KEY: 'live',
      LABEL: 'Freischaltung möglich',
      ABBREVIATED_LABEL: 'Freischaltung möglich',
      DESCRIPTION: 'Produktive Anbindung',
      HIGHLIGHTED: true,
    },
    QR_CODE: {
      KEY: 'qr_code',
      LABEL: 'QR-Code (Freischaltung möglich)',
      ABBREVIATED_LABEL: 'QR-Code',
      DESCRIPTION: 'Lieferungen können per QR-Code Scan eingelesen werden.',
      HIGHLIGHTED: true,
    },
    TEST_PHASE: {
      KEY: 'test_phase',
      LABEL: 'Testphase',
      ABBREVIATED_LABEL: 'Testphase',
      DESCRIPTION:
        'Die Schnittstelle wurde hergestellt, befindet sich aber noch in einer Testphase (bevor ein Dauerbetrieb möglich ist)',
    },
    IMPLEMENTATION_SUPPLIER: {
      KEY: 'implementation_supplier',
      LABEL: 'Implementierung Lieferant',
      ABBREVIATED_LABEL: 'Impl. Lieferant',
      DESCRIPTION:
        'Ggf. notwendige Anpassungen an der Schnittstelle werden vom Lieferanten implementiert',
    },
    IMPLEMENTATION_VESTIGAS: {
      KEY: 'implementation_vestigas',
      LABEL: 'Implementierung VESTIGAS',
      ABBREVIATED_LABEL: 'Impl. VESTIGAS',
      DESCRIPTION:
        'Ein notwendiger Datenparser wird von VESTIGAS implementiert',
    },
    TEST_DATA_EXCHANGED: {
      KEY: 'test_data_exchanged',
      LABEL: 'Testdaten wurden ausgetauscht',
      ABBREVIATED_LABEL: 'Testdaten',
      DESCRIPTION:
        'Erste Testdaten wurden zwischen VESTIGAS und dem Lieferanten ausgetauscht',
    },
    CONFIRMED: {
      KEY: 'confirmed',
      LABEL: 'Zusage erteilt',
      ABBREVIATED_LABEL: 'Zusage erteilt',
      DESCRIPTION:
        'Der Lieferant will mit der Umsetzung beginnen und VESTIGAS wartet auf erste Testdatensätze',
    },
    ON_HOLD_SUPPLIER: {
      KEY: 'on_hold_supplier',
      LABEL: 'Interne Absprachen beim Lieferant',
      ABBREVIATED_LABEL: 'Absprachen Lieferant',
      DESCRIPTION:
        'Lieferant benötigt Zeit, technische Möglichkeiten intern abzuklären oder die benötigten Ressourcen bereitzustellen',
    },
    IN_QUALIFICATION: {
      KEY: 'in_qualification',
      LABEL: 'Gespräch mit Lieferant',
      ABBREVIATED_LABEL: 'Gespräch mit Lieferant',
      DESCRIPTION:
        'Der Lieferant hat sich zurückgemeldet und fixiert ein Gespräch mit VESTIGAS',
    },
    INTERESTED: {
      KEY: 'interested',
      LABEL: 'Lieferant aufgenommen',
      ABBREVIATED_LABEL: 'Lieferant aufgenommen',
      DESCRIPTION:
        'Der Lieferant wurde von einem Abnehmer gemeldet und in VESTIGAS aufgenommen. Bei Interesse bitte den Lieferanten kontaktieren.',
    },
    NO_STATUS: {
      KEY: 'non_existant',
      LABEL: 'Kein Status',
      ABBREVIATED_LABEL: 'Kein Status',
      DESCRIPTION: 'Kein Status vergeben',
    },
    CURRENT_ISSUES: {
      KEY: 'current_issues',
      LABEL: 'Aktuelle Probleme',
      ABBREVIATED_LABEL: 'Aktuelle Probleme',
      DESCRIPTION:
        'Derzeit gibt es Übertragungsschwierigkeiten bei diesem Lieferanten',
    },
  };
}
