import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Check as CheckIcon } from '@mui/icons-material';
import { Chip, Stack } from '@mui/material';

class DashboardReportChips extends React.Component {
  columnIsSelected(column) {
    return this.props.selectedColumns.includes(column);
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    this.props.onReorderColumns(result.source.index, result.destination.index);
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="columns" direction="horizontal">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="flex overflow-x-auto"
            >
              <Stack direction="row" spacing={1}>
                {this.props.selectableColumns.map((column, index) => (
                  <Draggable
                    key={column.headerName}
                    draggableId={column.headerName}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Chip
                          label={column.headerName}
                          onClick={() => this.props.onClick(column.headerName)}
                          icon={
                            this.columnIsSelected(column.headerName) ? (
                              <CheckIcon className="text-white" />
                            ) : null
                          }
                          className={
                            this.columnIsSelected(column.headerName)
                              ? 'bg-primary500 text-white'
                              : null
                          }
                          style={
                            this.columnIsSelected(column.headerName)
                              ? { cursor: 'grab' }
                              : null
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </Stack>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default DashboardReportChips;
