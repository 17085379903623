import React from 'react';

import { PersonAdd as PersonAddIcon } from '@mui/icons-material';

import { connect } from 'react-redux';
import { withErrorBoundary } from '~/ui/atoms';

import { Button } from '@mui/material';
import Wizard from '~/components/Wizard';
import User from '~/models/masterdata/User';
import CreateUserWizardAccountInformation from './CreateUserWizardAccountInformation';
import CreateUserWizardSignatures from './CreateUserWizardSignatures';
import CreateUserWizardPermissions from './CreateUserWizardPermissions';
import Company from '~/models/masterdata/Company';
import ObjectUtils from '~/utils/objectUtils';
import { promiseHandler } from '~/utils/promiseHandler';
import UserService from '~/services/user.service';
import Log from '~/utils/Log';
import ToastService from '~/services/toast.service';
import MasterDataService from '~/services/masterData.service';
import { MissingPermissionsTooltip } from '~/utils/componentUtils';
import UserUtils from '~/utils/userUtils';

const mapStateToProps = (state) => ({
  companies: state.companies,
  userinfo: state.userinfo,
});

class CreateUserWizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      user: this.getDefaultUser(),
      enableSignature: true,
      grantPermissions: true,
      submittingWizard: false,
    };

    this.permissionGrantRef = React.createRef();
  }

  resetWizard() {
    this.setState({
      user: this.getDefaultUser(),
      enableSignature: true,
      grantPermissions: true,
    });
  }

  getDefaultUser() {
    const user = new User();

    user.company =
      this.props.companies.companies.find(
        (company) => company.id === this.props.userinfo.userinfo.company?.id,
      ) ?? new Company({ id: this.props.userinfo.userinfo.company?.id });
    user.signatureRoles.recipient = true;

    return user;
  }

  onOpenWizard = () => {
    Log.productAnalyticsEvent('Open create user wizard', Log.FEATURE.WIZARD);

    this.setState({
      open: true,
    });
  };
  onCloseWizard = () => {
    Log.productAnalyticsEvent('Close create user wizard', Log.FEATURE.WIZARD);

    this.setState({
      open: false,
    });

    this.resetWizard();
  };
  setUser = (user) => {
    this.setState({
      user,
    });
  };
  setEnableSignature = (enableSignature) => {
    this.setState({
      enableSignature,
    });
  };
  setGrantPermissions = (grantPermissions) => {
    this.setState({
      grantPermissions,
    });
  };

  getWizardSteps() {
    const wizardSteps = [
      {
        title: 'Benutzer-Account Informationen',
        preventNextStep: () => {
          if (!this.state.user.company.id) {
            ToastService.warning(['Bitte wähle eine Firma aus.']);
            Log.productAnalyticsEvent(
              'Missing company',
              Log.FEATURE.WIZARD,
              Log.TYPE.FAILED_VALIDATION,
            );
            return true;
          }
        },
        component: (
          <CreateUserWizardAccountInformation
            user={this.state.user}
            setUser={this.setUser}
          />
        ),
      },
      {
        title: 'Signaturen',
        component: (
          <CreateUserWizardSignatures
            user={this.state.user}
            setUser={this.setUser}
            enableSignature={this.state.enableSignature}
            setEnableSignature={this.setEnableSignature}
          />
        ),
      },
    ];

    if (UserUtils.isPermissionGrantAllowedUser()) {
      wizardSteps.push({
        title: 'Berechtigungen',
        component: (
          <CreateUserWizardPermissions
            user={this.state.user}
            grantPermissions={this.state.grantPermissions}
            setGrantPermissions={this.setGrantPermissions}
            ref={this.permissionGrantRef}
          />
        ),
      });
    }

    return wizardSteps.map((step) => {
      return {
        ...step,
        component: <div className="mt-40px mb-30px">{step.component}</div>,
      };
    });
  }

  submitUser = async () => {
    this.setState({
      submittingWizard: true,
    });

    const body = {
      password: this.state.user.password,
      first_name: this.state.user.firstName,
      last_name: this.state.user.lastName,
      position: this.state.user.position,
      company_id: this.state.user.company.id,
      is_active: true,
      user_type: this.state.user.signatureRoles?.getUserType() ?? '',
      username: this.state.user.email,
      email: this.state.user.email,
      permitted_signature_types: this.state.enableSignature
        ? ObjectUtils.entries(this.state.user.signatureRoles)
            .filter((entry) => entry.value)
            .map((entry) => entry.key)
        : [],
      org_units: this.state.user.organisationalGroups,
    };

    if (
      MasterDataService.propertiesAreMissing(
        body,
        ['company_id'],
        Log.FEATURE.WIZARD,
      )
    ) {
      this.setState({
        submittingWizard: false,
      });
      return false;
    }

    Log.info('Submit create user wizard', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit create user wizard', Log.FEATURE.WIZARD);

    const [userId, error] = await promiseHandler(UserService.createUser(body));

    if (error) {
      Log.error('Failed to create user.', error);

      if (error.response.status === 409) {
        ToastService.httpError(
          [ToastService.MESSAGE.USER_CREATION_FAILED_DUPLICATE],
          error.response,
        );
        Log.productAnalyticsEvent(
          'Failed to create due to duplicate user',
          Log.FEATURE.WIZARD,
          Log.TYPE.ERROR,
        );
      } else {
        ToastService.httpError(
          [ToastService.MESSAGE.USER_CREATION_FAILED],
          error.response,
        );
        Log.productAnalyticsEvent(
          'Failed to create user',
          Log.FEATURE.WIZARD,
          Log.TYPE.ERROR,
        );
      }

      this.setState({
        submittingWizard: false,
      });

      return false;
    }

    if (
      this.state.grantPermissions &&
      UserUtils.isPermissionGrantAllowedUser()
    ) {
      const [response2, error2] = await promiseHandler(
        this.permissionGrantRef.current.submit(userId),
      );

      if (error2) {
        Log.error('Failed to grant permissions.', error);
        ToastService.error([
          'Berechtigungen konnten nicht vollständig vergeben werden.',
        ]);
        Log.productAnalyticsEvent(
          'Failed to grant permissions',
          Log.FEATURE.WIZARD,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingWizard: false,
        });
        return false;
      }

      if (response2[0]?.value?.length > 0) {
        ToastService.success(['Berechtigungen wurden vergeben.']);
      }
    }

    ToastService.success(['Benutzer wurde angelegt.']);

    this.setState({
      open: false,
      submittingWizard: false,
    });

    this.resetWizard();
    UserService.refreshUsers();

    return true;
  };

  getButton() {
    if (!UserUtils.isUserCreateAllowedUser()) {
      return (
        <MissingPermissionsTooltip>
          <Button
            className="disabled-primary-button"
            startIcon={<PersonAddIcon />}
            onClick={this.onOpenWizard}
            disabled
          >
            Benutzer erstellen
          </Button>
        </MissingPermissionsTooltip>
      );
    }

    return (
      <Button
        className="primary-button"
        startIcon={<PersonAddIcon />}
        onClick={this.onOpenWizard}
      >
        Benutzer erstellen
      </Button>
    );
  }

  render() {
    return (
      <>
        {this.getButton()}
        <Wizard
          open={this.state.open}
          closeWizard={this.onCloseWizard}
          title="Benutzer erstellen"
          fullWidth
          steps={this.getWizardSteps()}
          wizardSuccess={this.submitUser}
          submittingWizard={this.state.submittingWizard}
          unsavedChanges={User.getDifferentValues(
            this.state.user,
            this.getDefaultUser(),
          )}
        />
      </>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(CreateUserWizard),
  'Daten konnten nicht geladen werden.',
);
