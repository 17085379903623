import { withErrorBoundary } from '~/ui/atoms';
import DeliveryNoteDataQualityPath from './DeliveryNoteDataQualityPath';

const DeliveryNoteDataQualityCategory = ({ category, dataQualityPairs }) => (
  <>
    <h3>{category}</h3>
    <div className="flexdir-column gap-10px flex">
      {dataQualityPairs.map((item) => (
        <DeliveryNoteDataQualityPath key={item.name} {...item} />
      ))}
    </div>
  </>
);

export default withErrorBoundary(
  DeliveryNoteDataQualityCategory,
  'Daten konnten nicht geladen werden.',
);
