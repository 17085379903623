import Address from './Address';

export default class SiteReference {
  constructor(siteReference, assignedSiteId) {
    // Line breaks cause the mui data grid to crash. That's why the identifier must be "cleaned".
    siteReference.identifier = siteReference.identifier.replaceAll('\n', ' ');

    this.id = `${siteReference?.supplier_id}-${siteReference.identifier}`;
    this.address = new Address(siteReference?.address);
    this.addressString = Address.getConcatenatedAddress(this.address);
    this.assignedSiteId = assignedSiteId;
    this.assignedSiteName = null;
    this.identifier = siteReference?.identifier;
    this.identifierId = siteReference?.identifier_uuid;
    this.ignoreAddressInfo = siteReference?.ignore_address_info ?? true;
    this.issuerId = siteReference?.supplier_id;
    this.isBlacklisted = siteReference?.blacklisted ?? false;
    this.issuerName = siteReference?.supplier_name;
    this.proposalId = siteReference?.proposal_uuid;
  }
}
