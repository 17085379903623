import UnitUtils from '~/utils/unitUtils';
import InvoiceCheckResult from '~/models/invoices/InvoiceCheckResult';
import InvoiceCheckSummaryText from '~/components/invoices/InvoiceCheck/invoiceCheckSummary/InvoiceCheckSummaryText';
import ComponentUtils from '~/utils/componentUtils';
import InvoiceCheckCategory from '~/models/invoices/InvoiceCheckCategory';

// The mapDeliveryRow and mapInvoiceRow functions have been moved from the DeliveriesService and InvoicesService to an own class.
// This is because they are needed in the slices but importing DeliveriesService or InvoicesService would cause a cyclic dependency in Jest.
class MapperService {
  mapInvoiceRow(invoice) {
    let row = {
      id: invoice.id,
      number: invoice.number,
      seller: invoice.seller.name,
      buyer: invoice.buyer.name,
      totalPriceGross: invoice.totalPriceGross,
      currency: UnitUtils.getAbbreviatedUnit(invoice.currency),
      date: invoice.date,
      parsedDate: invoice.parsedDate,
      toSite: invoice.toSite,
      delayedSigned:
        invoice.checkCategory.signatureCheck.status ===
        InvoiceCheckResult.STATUS.DELAYED_SUCCESS,
      unsignedDeliveryNoteIds:
        invoice.checkCategory.signatureCheck.status ===
        InvoiceCheckResult.STATUS.DELAYED_SUCCESS
          ? ''
          : InvoiceCheckCategory.getUnsignedDeliveryNoteIds(
              invoice.checkCategory.signatureCheck.errorChecks,
            ).join(';'),
      status: invoice.status,
      // Add the erroneous categories so that the status filter can easily filter by this.
      errorAndWarningCategories: Object.keys(invoice.checkCategory)
        .filter(
          (key) =>
            invoice.checkCategory[key].status ===
              InvoiceCheckResult.STATUS.ERROR ||
            invoice.checkCategory[key].status ===
              InvoiceCheckResult.STATUS.WARNING,
        )
        .map((key) => invoice.checkCategory[key].key)
        .join(';'),
      // Add the icons of the erroneous categories so that the status column in the invoice overview can be instantiated.
      combinedStatus:
        invoice.status +
        ';' +
        invoice.subStatus +
        ';' +
        invoice.checkCategory.signatureCheck.status +
        ';' +
        ComponentUtils.getStringFromComponent(
          <InvoiceCheckSummaryText
            category={invoice.checkCategory.signatureCheck}
          />,
        ) +
        ';' +
        Object.keys(invoice.checkCategory)
          .filter(
            (key) =>
              invoice.checkCategory[key].status ===
              InvoiceCheckResult.STATUS.ERROR,
          )
          .map(
            (key) =>
              invoice.checkCategory[key].key +
              '|' +
              ComponentUtils.getStringFromComponent(
                <InvoiceCheckSummaryText
                  category={invoice.checkCategory[key]}
                />,
              ),
          )
          .join(';'),
    };

    row = {
      ...row,
      searchString: [
        row.id,
        row.number,
        row.seller,
        row.buyer,
        UnitUtils.formatDeMoneyAmount_safe(row.amount),
        // Add the invoice check summary text so that the free text filter can search properly.
        Object.keys(invoice.checkCategory)
          .filter(
            (key) =>
              invoice.checkCategory[key].status ===
                InvoiceCheckResult.STATUS.ERROR ||
              invoice.checkCategory[key].status ===
                InvoiceCheckResult.STATUS.WARNING,
          )
          .map((key) =>
            ComponentUtils.getStringFromComponent(
              <InvoiceCheckSummaryText category={invoice.checkCategory[key]} />,
            ),
          )
          .join(';'),
      ]
        .join(';')
        .toLowerCase(),
    };

    return row;
  }

  addSearchString(row, properties) {
    row.searchString = properties
      .map((property) =>
        row[property] == null || row[property] === '' ? '' : row[property],
      )
      .join(';')
      .toLowerCase();

    return row;
  }

  addSearchStringWithValues(row, values) {
    row.searchString = values.join(';').toLowerCase();

    return row;
  }
}

export default new MapperService();
