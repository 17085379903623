import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

export default class CustomerSubscription {
  constructor(customerSubscription) {
    this.id = customerSubscription?.id ?? '';
    this.name = customerSubscription?.name ?? '';
    this.active =
      customerSubscription?.is_active === undefined
        ? true
        : customerSubscription?.is_active;
    this.type =
      customerSubscription?.subscription_type ?? 'process_state_change';
    this.events = customerSubscription?.subscription_events ?? [];
    this.includeDln = customerSubscription?.include_dln_data;
    this.authType = customerSubscription?.auth_type ?? 'key_auth';
    this.keyAuthHeader = customerSubscription?.key_auth_header ?? '';
    this.keyAuthValue = customerSubscription?.key_auth_value ?? '';
    this.callbackUrl = customerSubscription?.callback_url ?? '';
    this.callbackPort = customerSubscription?.callback_port ?? null;
  }

  getEventStrings() {
    return this.events.map((key) => CustomerSubscription.getEventString(key));
  }

  static getEventString(eventKey) {
    const event = Object.keys(CustomerSubscription.EVENT).find(
      (x) => CustomerSubscription.EVENT[x].KEY === eventKey,
    );

    if (!event) {
      throw new EnumValueNotFoundException('Invalid event: ' + eventKey);
    }

    return CustomerSubscription.EVENT[event].STRING;
  }

  static EVENT = {
    READY_FOR_OUTPUT: {
      KEY: 'readyForOutput',
      STRING: 'Ausgabe',
    },
    CANCELLED: {
      KEY: 'cancelled',
      STRING: 'Storniert',
    },
    IN_TRANSPORT: {
      KEY: 'inTransport',
      STRING: 'In Transport',
    },
    ARRIVED: {
      KEY: 'arrived',
      STRING: 'Übergabe',
    },
    DELIVERED: {
      KEY: 'delivered',
      STRING: 'Geliefert',
    },
  };
  static IS_ACTIVE = {
    YES: 'Ja',
    NO: 'Nein',
  };
}
