import React from 'react';
import { withErrorBoundary } from '~/ui/atoms';
import { promiseHandler } from '~/utils/promiseHandler';
import InvoicesService from '~/services/invoices.service';
import InvoiceModel from '~/models/invoices/Invoice';
import Log from '~/utils/Log';
import { PartlySettledIcon, SettledIcon } from '~/assets/icons';
import BilledItem from '~/models/billingState/BilledItem';
import UnitUtils from '~/utils/unitUtils';
import { InvoiceLink } from '~/utils/componentUtils';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import ValueGroup from '~/models/deliveries/ValueGroup';

class ArticleBilledItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      referencedInvoices: [],
    };
  }

  componentDidMount() {
    this.loadReferencedInvoices();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.billedItem?.billingDetails) !==
      JSON.stringify(prevProps.billedItem?.billingDetails)
    ) {
      this.loadReferencedInvoices();
    }
  }

  async loadReferencedInvoices() {
    const referencedInvoices = [];

    for (
      let index = 0;
      index < this.props.billedItem?.billingDetails?.length;
      index++
    ) {
      const [invoice, error] = await promiseHandler(
        InvoicesService.getInvoiceById(
          this.props.billedItem.billingDetails[index].invoiceId,
          InvoiceModel.DIRECTION.INCOMING,
        ),
      );

      if (error) {
        Log.error(
          'Failed to load invoice by id: ' +
            this.props.billedItem.billingDetails[index].invoiceId,
        );
        Log.productAnalyticsEvent(
          'Failed to load invoice',
          Log.FEATURE.DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );
        continue;
      }

      referencedInvoices.push(invoice);
    }

    this.setState({
      referencedInvoices,
    });
  }

  getIcon() {
    if (
      this.props.billedItem?.settledStatus ===
      BilledItem.SETTLED_STATUS.FULLY_SETTLED.KEY
    ) {
      return <SettledIcon className="text-successBase mr-20px" />;
    }

    if (
      this.props.billedItem?.settledStatus ===
      BilledItem.SETTLED_STATUS.PARTLY_SETTLED.KEY
    ) {
      return <PartlySettledIcon className="text-warningBase mr-20px" />;
    }

    Log.error(
      null,
      new EnumValueNotFoundException(
        'Invalid settled status: ' + this.props.billedItem?.settledStatus,
      ),
    );
    Log.productAnalyticsEvent(
      'Failed to display settled amount',
      Log.FEATURE.DELIVERY_NOTE,
      Log.TYPE.ERROR,
    );

    return null;
  }

  getDescription() {
    if (
      this.props.billedItem?.settledStatus ===
      BilledItem.SETTLED_STATUS.FULLY_SETTLED.KEY
    ) {
      return BilledItem.SETTLED_STATUS.FULLY_SETTLED.DESCRIPTION;
    }

    if (
      this.props.billedItem?.settledStatus ===
      BilledItem.SETTLED_STATUS.PARTLY_SETTLED.KEY
    ) {
      return BilledItem.SETTLED_STATUS.PARTLY_SETTLED.DESCRIPTION;
    }

    Log.error(
      null,
      new EnumValueNotFoundException(
        'Invalid settled status: ' + this.props.billedItem?.settledStatus,
      ),
    );
    Log.productAnalyticsEvent(
      'Failed to display settled amount',
      Log.FEATURE.DELIVERY_NOTE,
      Log.TYPE.ERROR,
    );

    return null;
  }

  getTextClassName() {
    if (
      this.props.billedItem?.settledStatus ===
      BilledItem.SETTLED_STATUS.FULLY_SETTLED.KEY
    ) {
      return 'text-successBase';
    }

    if (
      this.props.billedItem?.settledStatus ===
      BilledItem.SETTLED_STATUS.PARTLY_SETTLED.KEY
    ) {
      return 'text-warningBase';
    }

    Log.error(
      null,
      new EnumValueNotFoundException(
        'Invalid settled status: ' + this.props.billedItem?.settledStatus,
      ),
    );
    Log.productAnalyticsEvent(
      'Failed to display settled amount',
      Log.FEATURE.DELIVERY_NOTE,
      Log.TYPE.ERROR,
    );

    return null;
  }

  render() {
    return (
      <>
        <div className="flex-s-c">
          {this.getIcon()}
          <div className={this.getTextClassName()}>
            <div>{this.getDescription()}</div>
            <div>
              <span className="bold">
                {ValueGroup.getCurrentValue(this.props.article?.amount?.value) *
                  this.props.billedItem?.totalBilledQuotient}
              </span>{' '}
              / {ValueGroup.getCurrentValue(this.props.article?.amount?.value)}{' '}
              {UnitUtils.getAbbreviatedUnits(
                ValueGroup.getCurrentValue(this.props.article?.amount?.unit),
              )}
            </div>
          </div>
        </div>
        <div className="mt-20px">
          In den folgenden Rechnungen abgerechnet:
          <div className="flex-s-c gap-8px mt-10px flex-wrap">
            {this.props.billedItem?.billingDetails?.map((billingDetail) => {
              const referencedInvoice = this.state.referencedInvoices.find(
                (referencedInvoice) =>
                  referencedInvoice.id === billingDetail.invoiceId,
              );

              return (
                <div className="bg-grey100 p-8px rounded-5px">
                  <InvoiceLink
                    id={referencedInvoice?.id}
                    number={referencedInvoice?.number}
                    className="text-grey800 bold mr-20px"
                  />{' '}
                  {UnitUtils.formatValueUnitPair_safe(
                    billingDetail.amount.value,
                    billingDetail.amount.unit,
                    UnitUtils.getAbbreviatedUnit,
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default withErrorBoundary(
  ArticleBilledItem,
  'Abgerechnete Menge konnte nicht geladen werden.',
);
