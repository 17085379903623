import React from 'react';
import { withErrorBoundary } from '~/ui/atoms';
import BasicForm from '~/components/BasicForm';
import PermissionGrantPicker from './PermissionGrantPicker';
import Log from '~/utils/Log';

class PermissionGrantDialog extends React.Component {
  constructor(props) {
    super(props);

    this.permissionGrantPickerChild = React.createRef();
  }

  formSuccess = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    Log.info(
      'Submit permission grant dialog',
      null,
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent(
      'Submit form',
      Log.FEATURE.PERMISSION_GRANT_DIALOG,
    );

    this.props.closeForm();
    await this.permissionGrantPickerChild.current.submit();
    if (typeof this.props.loadData === 'function') {
      this.props.loadData();
    }
  };
  formAbort = () => {
    Log.productAnalyticsEvent(
      'Abort form',
      Log.FEATURE.PERMISSION_GRANT_DIALOG,
    );
    this.props.closeForm();
  };

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        title="Berechtigungen vergeben"
        fullWidth
      >
        <PermissionGrantPicker
          defaultSubjects={this.props.defaultSubjects}
          defaultSubjectType={this.props.defaultSubjectType}
          defaultEntityType={this.props.defaultEntityType}
          defaultEntities={this.props.defaultEntities}
          fixedPicker={this.props.fixedPicker}
          ref={this.permissionGrantPickerChild}
        />
      </BasicForm>
    );
  }
}

export default withErrorBoundary(
  PermissionGrantDialog,
  'Dialog konnte nicht geladen werden.',
);
