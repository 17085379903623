import { Error as ErrorIcon } from '@mui/icons-material';

import { LOADING_STATE } from '~/constants/LoadingState';
import { Spinner } from './Spinner';
import { FunctionalLink } from '~/utils/componentUtils';
import { ROUTE } from '~/constants/Route';

export default function DocumentLoadingPage(props) {
  if (props.loading === LOADING_STATE.LOADING) {
    return <Spinner title={props.documentType + ' wird geladen...'} />;
  }

  if (props.loading === LOADING_STATE.FAILED) {
    return (
      <div className="flex-c-c h-full w-full">
        <ErrorIcon className="mr-10px" />
        <div>
          Die {props.documentType} konnte nicht geladen werden. Dies kann daran
          liegen, dass du keine Berechtigung
          <br />
          hast, um diese einzusehen. Um den Zugriff zu erhalten, kontaktiere den{' '}
          <FunctionalLink
            to={ROUTE.SETTINGS_SUPPORT.ROUTE}
            message="Support"
            className="main-text bold"
          />
          .
        </div>
      </div>
    );
  }
}
