import Config from '~/Config';
import axios from '~/utils/api-client';
import Article from '~/models/articleMaster/Article';
import Category from '~/models/articleMaster/Category';
import store from '~/redux/store';
import { LOADING_STATE } from '~/constants/LoadingState';
import {
  replaceArticles,
  replaceCategories,
  setArticlesLoading,
  setCategoriesLoading,
} from '~/redux/articleMasterSlice';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';
import qs from 'qs';
import CacheService from './cache.service';

const ARTICLE_API_URL = Config.apiUrl + '/article_master/article';
const CATEGORY_API_URL = Config.apiUrl + '/article_master/category';

class ArticleMasterService {
  async getAllArticles(companyAccountId, companyId) {
    return axios
      .get(ARTICLE_API_URL, {
        // Load all articles that are owned either by the company account or company of the user.
        params: {
          filter_owner_comp_accs: [companyAccountId],
          filter_owner_comps: [companyId],
          owner_filter_union: true,
        },
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: 'repeat' }),
      })
      .then((response) => {
        if (response.status !== 200) {
          return [];
        }

        return response.data.items.map((item) => new Article(item));
      });
  }

  async createArticle(body) {
    return axios.post(ARTICLE_API_URL, body).then((response) => {
      return response.data?.id;
    });
  }

  async updateArticle(id, body) {
    return axios.put(ARTICLE_API_URL + '/' + id, body);
  }

  async deleteArticle(id) {
    return axios.delete(ARTICLE_API_URL + '/' + id);
  }

  loadArticles = async (companyAccountId, companyId) => {
    // to not load articles again when they are already loading or have already been loaded
    if (
      store.getState().articleMaster?.articlesLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshArticles(companyAccountId, companyId);
  };
  refreshArticles = async (companyAccountId, companyId) => {
    store.dispatch(setArticlesLoading(LOADING_STATE.LOADING));

    const [articles, error] = await promiseHandler(
      this.getAllArticles(companyAccountId, companyId),
    );

    if (error) {
      store.dispatch(setArticlesLoading(LOADING_STATE.FAILED));
      Log.error('Failed to load articles.', error);
      Log.productAnalyticsEvent(
        'Failed to load articles',
        Log.FEATURE.ARTICLE_MASTER,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceArticles(articles));
  };

  async getAllCategories(companyAccountId, companyId) {
    return axios
      .get(CATEGORY_API_URL, {
        // Load all categories that are owned either by the company account or company of the user.
        params: {
          filter_owner_comp_accs: [companyAccountId],
          filter_owner_comps: [companyId],
          owner_filter_union: true,
        },
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: 'repeat' }),
      })
      .then((response) => {
        if (response.status !== 200) {
          return [];
        }

        return response.data.items.map((item) => new Category(item));
      });
  }

  async createCategory(body) {
    return axios.post(CATEGORY_API_URL, body).then((response) => {
      return response.data?.id;
    });
  }

  async updateCategory(id, body) {
    return axios.put(CATEGORY_API_URL + '/' + id, body);
  }

  async deleteCategory(id) {
    return axios.delete(CATEGORY_API_URL + '/' + id);
  }

  loadCategories = async (companyAccountId, companyId) => {
    // to not load categories again when they are already loading or have already been loaded
    if (
      store.getState().articleMaster?.categoriesLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshCategories(companyAccountId, companyId);
  };
  refreshCategories = async (companyAccountId, companyId) => {
    store.dispatch(setCategoriesLoading(LOADING_STATE.LOADING));

    const [categories, error] = await promiseHandler(
      this.getAllCategories(companyAccountId, companyId),
    );

    if (error) {
      store.dispatch(setCategoriesLoading(LOADING_STATE.FAILED));
      Log.error('Failed to load categories.', error);
      Log.productAnalyticsEvent(
        'Failed to load categories',
        Log.FEATURE.ARTICLE_MASTER,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceCategories(categories));
  };

  // CRUD for Category Icon
  async getCategoryIcon(categoryId, ignoreCache) {
    if (!categoryId) {
      throw new Error(`Failed to load category icon. id: ${categoryId}`);
    }

    const url = `${CATEGORY_API_URL}/${categoryId}/icon`;

    if (!ignoreCache) {
      const [cachedValue] = CacheService.getCached(url);
      if (cachedValue?.type === 'image/png') {
        return cachedValue;
      }
    }

    return axios
      .get(url, { responseType: 'blob' })
      .then((response) => {
        if (response.status !== 200) {
          Log.warn(
            'Put category icon did not return 200',
            { status_code: response?.status },
            Log.BREADCRUMB.HTTP_NOT_200.KEY,
          );
        }

        CacheService.setCached(url, response.data);

        return response.data;
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  }

  async updateCategoryIcon({ categoryId, picture }) {
    return axios
      .put(`${CATEGORY_API_URL}/${categoryId}/icon`, picture, {
        headers: {
          'Content-Type': 'image/png',
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          Log.warn(
            'Put category icon did not return 200',
            { status_code: response?.status },
            Log.BREADCRUMB.HTTP_NOT_200.KEY,
          );
        }
      });
  }

  async deleteCategoryIcon(categoryId) {
    const url = `${CATEGORY_API_URL}/${categoryId}/icon`;
    return axios.delete(url).then((response) => {
      CacheService.removeCached(url, null);
      return response.data;
    });
  }
}

export default new ArticleMasterService();
