import React from 'react';
import { connect } from 'react-redux';
import { setInvoice_query } from '~/redux/filtersSlice';
import Log from '~/utils/Log';
import FreeTextSearch from '../../baseComponents/inputs/FreeTextSearch';

const mapStateToProps = (state) => ({
  query: state.filters.invoice_query,
});
const mapDispatchToProps = () => ({
  setInvoice_query,
});

class InvoiceFreeTextFilter extends React.Component {
  render() {
    return (
      <div className="w-300px">
        <FreeTextSearch
          id="InvoiceFreeTextFilter_delay_function"
          onChange={(params) => this.props.setInvoice_query(params)}
          value={this.props.query}
          withDelay
          productAnalyticsFeature={Log.FEATURE.INVOICE_LIST}
          autoFocus
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps(),
)(InvoiceFreeTextFilter);
