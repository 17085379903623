import { array, func, number, object, shape, string } from 'prop-types';

import React from 'react';
import BasicTable from '~/components/BasicTable';
import { connect } from 'react-redux';
import DatagridUtils from '~/utils/datagridUtils';
import { ROUTE } from '~/constants/Route';
import DeliveryNote from '~/models/deliveries/DeliveryNote';
import UnitUtils from '~/utils/unitUtils';

import Log from '~/utils/Log';

import { dateUtils, fromUTC, parseDate } from '~/utils/dateUtils';

import { withErrorBoundary } from '~/ui/atoms';
import LocalStorageService from '~/services/localStorage.service';
import UserUtils from '~/utils/userUtils';
import User from '~/models/masterdata/User';
import ExportService from '~/services/export.service';
import BrowserUtils from '~/utils/browserUtils';
import { LOADING_STATE } from '~/constants/LoadingState';
import FeatureService from '~/services/feature.service';
import RequestSignatureForm from '../../deliveryNoteActions/RequestSignatureForm';
import ContextMenu from '~/components/menu/ContextMenu';

import DeliveriesService from '~/services/deliveries.service';
import ShareDeliveryNoteForm from '../../deliveryNoteActions/ShareDeliveryNoteForm';
import DeliveryListActions from '../../deliveryColumns/DeliveryListActions';
import ToastService from '~/services/toast.service';
import { MapDirectDeliveryNoteForm } from '../../deliveryNoteActions/MapDirectDeliveryNoteForm';
import { promiseHandler } from '~/utils/promiseHandler';
import BasicModal from '~/components/BasicModal';
import DashboardReport from '~/components/dashboard/DashboardReport';
import DashboardService from '~/services/dashboard.service';
import { InactiveIcon } from '~/components/InactiveIcon';
import ArrayUtils from '~/utils/arrayUtils';
import MuiDataGridFilter from '~/models/filters/MuiDataGridFilter';
import FilterNew from '~/models/filters/FilterNew';
import FilterContext from '~/models/filters/FilterContext';

import DeliveryStatus from '../../DeliveryStatus';
import { DeliveryCategoryIcon } from '../../DeliveryCategoryIcon';

import { mapStateToProps } from './mapStateToProps';
import { mapDispatchToProps } from './mapDispatchToProps';

import { filterRows } from './utils';

// This class represents the overview/table of all delivery notes
class DeliveryListComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contextMenu: null,
      excelData: [],
      filteredRows: [],
      isLoading: false,
      mapDirectDeliveryNoteDefaultSelectedCostCenterId: null,
      mapDirectDeliveryNoteDefaultSelectedSiteId: null,
      mapDirectDeliveryNoteFormOpen: false,
      mapDirectDeliveryNoteIssuerIds: [],
      mapDirectDeliveryNoteToSiteSupplierNames: [],
      paginatedRows: [],
      reportData: [],
      reportOpen: false,
      requestDeliveryNoteSignatureFormOpen: false,
      requestDeliveryNoteSignatureFormOpenDeliveryNoteId: null,
      rowSelectionModel: [],
      shareDeliveryNoteFormOpen: false,
      shareDeliveryNoteFormOpenDeliveryNoteId: null,
      /* deliveryNoteSignaturePushIsLoading: false, */
    };

    this.BROWSE_MAX_PAGES = 5;

    // To track the pages that are already loading to prevent that pages are loaded multiple times in parallel.
    this.pagesLoading = [];

    // To track whether the open HTTP requests are loading data for the latest filter.
    // If the requests are already stale, the response is thrown away.
    this.filterId = 0;
  }

  componentDidMount() {
    const shouldResetDeliveryRowPages =
      this.props.deliveryNotes.deliveryNotesLoading ===
      LOADING_STATE.NOT_LOADED;
    this.loadRows(shouldResetDeliveryRowPages);
    this.loadDeliveryRowCount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.page !== prevProps.page ||
      this.props.deliveryNotes.deliveryRowPagesVersion !==
        prevProps.deliveryNotes.deliveryRowPagesVersion
    ) {
      this.loadRows(false);
    }

    if (
      JSON.stringify(this.props.selectedSites) !==
        JSON.stringify(prevProps.selectedSites) ||
      JSON.stringify(this.props.selectedCostCenters) !==
        JSON.stringify(prevProps.selectedCostCenters) ||
      JSON.stringify(this.props.selectedToSiteRecipient) !==
        JSON.stringify(prevProps.selectedToSiteRecipient) ||
      JSON.stringify(this.props.selectedToSiteSupplier) !==
        JSON.stringify(prevProps.selectedToSiteSupplier) ||
      JSON.stringify(this.props.selectedCostCenter) !==
        JSON.stringify(prevProps.selectedCostCenter) ||
      JSON.stringify(this.props.selectedArticleNumber) !==
        JSON.stringify(prevProps.selectedArticleNumber) ||
      JSON.stringify(this.props.selectedArticle) !==
        JSON.stringify(prevProps.selectedArticle) ||
      JSON.stringify(this.props.selectedSupplier) !==
        JSON.stringify(prevProps.selectedSupplier) ||
      JSON.stringify(this.props.selectedRecipient) !==
        JSON.stringify(prevProps.selectedRecipient) ||
      JSON.stringify(this.props.selectedProcessState) !==
        JSON.stringify(prevProps.selectedProcessState) ||
      JSON.stringify(this.props.selectedAcceptState) !==
        JSON.stringify(prevProps.selectedAcceptState) ||
      JSON.stringify(this.props.selectedSettledStatus) !==
        JSON.stringify(prevProps.selectedSettledStatus) ||
      JSON.stringify(this.props.selectedFromSite) !==
        JSON.stringify(prevProps.selectedFromSite) ||
      JSON.stringify(this.props.selectedPermittedToSites) !==
        JSON.stringify(prevProps.selectedPermittedToSites) ||
      JSON.stringify(this.props.selectedPermittedCostCenters) !==
        JSON.stringify(prevProps.selectedPermittedCostCenters) ||
      JSON.stringify(this.props.selectedCustomFields) !==
        JSON.stringify(prevProps.selectedCustomFields) ||
      this.props.selectField !== prevProps.selectField ||
      this.props.query !== prevProps.query ||
      JSON.stringify(this.props.dateRange) !==
        JSON.stringify(prevProps.dateRange) ||
      (!this.isArchiveMode() &&
        this.props.deliveryNotes.filteredDeliveryNotesVersion !==
          prevProps.deliveryNotes.filteredDeliveryNotesVersion) ||
      (this.isArchiveMode() &&
        JSON.stringify(this.props.calculatedFilterModel) !==
          JSON.stringify(prevProps.calculatedFilterModel))
    ) {
      // When one of the filters has changed, the filterId is updated so that outdated requests can be detected.
      this.filterId += 1;

      this.loadRows(true);
    }

    const formatDateInLocalTime = (
      date,
      format = dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
    ) => dateUtils.getFormattedDate(fromUTC(date), format);

    // This should also check for changes of JSON.stringify(this.state.filteredRows)
    // but as JSON.stringify is too expensive for large datasets, it is omitted.
    if (
      this.state.rowSelectionModel.length !== prevState.rowSelectionModel.length
    ) {
      const isSchickerAccount = UserUtils.isSchickerAccount(
        this.props.userinfo.userinfo.company.companyAccount,
      );

      this.setState({
        excelData: this.state.filteredRows
          .filter(({ id }) => this.state.rowSelectionModel.includes(id))
          .map((row) => {
            return {
              ...row,
              creationDate: formatDateInLocalTime(row.creationDate),
              dlnDate: formatDateInLocalTime(
                row.dlnDate,
                isSchickerAccount
                  ? dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss
                  : dateUtils.DATE_FORMAT.DD_MM_YYYY,
              ),
              modifiedDate: formatDateInLocalTime(row.modifiedDate),
              permittedToSites: row.permittedToSiteNames.join(', '),
              permittedCostCenters: row.permittedCostCenterNames.join(', '),
            };
          }),
      });
    }
  }

  loadRows(shouldResetDeliveryRowPages) {
    if (!this.isArchiveMode()) {
      const selected = {
        acceptState: this.props.selectedAcceptState,
        article: this.props.selectedArticle,
        articleNumber: this.props.selectedArticleNumber,
        costCenter: this.props.selectedCostCenter,
        customFields: this.props.selectedCustomFields,
        fromSite: this.props.selectedFromSite,
        permittedCostCenters: this.props.selectedPermittedCostCenters,
        permittedToSites: this.props.selectedPermittedToSites,
        processState: this.props.selectedProcessState,
        recipient: this.props.selectedRecipient,
        settledStatus: this.props.selectedSettledStatus,
        supplier: this.props.selectedSupplier,
        toSiteRecipient: this.props.selectedToSiteRecipient,
        toSiteSupplier: this.props.selectedToSiteSupplier,
      };

      filterRows(
        this.props.deliveryNotes.filteredDeliveryRows,
        selected,
        this.props.query,
        this.props.dateRange,
        this.props.selectField,
        (filteredRows) => this.setState({ filteredRows }),
        (isLoading) => this.setState({ isLoading }),
      );

      return;
    }

    if (shouldResetDeliveryRowPages) {
      this.resetDeliveryRowPages(this.filterId);
    } else {
      this.loadDeliveryRowPage(this.filterId);
      this.loadNextDeliveryRowPage(this.filterId);
    }
  }

  async resetDeliveryRowPages(filterId) {
    this.loadDeliveryRowCount(filterId);

    this.pagesLoading = [];

    this.props.setDeliveryRowPagesLoading(LOADING_STATE.LOADING);

    const {
      calculatedFilterModel,
      dateRange,
      selectedAcceptState,
      selectedArticle,
      selectedCostCenters,
      selectedFromSite,
      selectedPermittedCostCenters,
      selectedPermittedToSites,
      selectedProcessState,
      selectedRecipient,
      selectedSettledStatus,
      selectedSites,
      selectedSupplier,
      selectedToSiteRecipient,
      selectedToSiteSupplier,
    } = this.props;

    const [response, error] = await promiseHandler(
      DeliveriesService.loadFirstDlnPage({
        calculatedFilterModel,
        dateRange,
        selectedAcceptState,
        selectedArticle,
        selectedCostCenters,
        selectedFromSite,
        selectedPermittedCostCenters,
        selectedPermittedToSites,
        selectedProcessState,
        selectedRecipient,
        selectedSettledStatus,
        selectedSites,
        selectedSupplier,
        selectedToSiteRecipient,
        selectedToSiteSupplier,
      }),
    );

    // If the filterId has been updated in the meantime, the request is outdated and the response is thrown away.
    if (this.filterId !== filterId) {
      return;
    }

    if (error) {
      ToastService.httpError(
        [
          'Lieferungen konnten nicht geladen werden wegen eines internen Fehlers.',
        ],
        error.response,
      );
      this.props.setDeliveryRowPagesLoading(LOADING_STATE.FAILED);
      return;
    }

    this.props.setDeliveryList_page(0);
    this.props.resetDeliveryRowPages({
      page: response.page,
      deliveryRows: response.deliveryRows,
      appliedFilters: this.getAppliedFilters(),
    });
  }

  async loadDeliveryRowPage(filterId) {
    const {
      deliveryNotes,
      calculatedFilterModel,
      dateRange,
      page,
      replaceDeliveryRowPage,
      selectedAcceptState,
      selectedArticle,
      selectedCostCenters,
      selectedFromSite,
      selectedPermittedCostCenters,
      selectedPermittedToSites,
      selectedProcessState,
      selectedRecipient,
      selectedSettledStatus,
      selectedSites,
      selectedSupplier,
      selectedToSiteRecipient,
      selectedToSiteSupplier,
      setDeliveryRowPagesLoading,
    } = this.props;

    const deliveryRowPage = deliveryNotes.deliveryRowPages.find(
      ({ page }) => page === this.props.page,
    );

    if (
      deliveryRowPage &&
      JSON.stringify(this.getAppliedFilters()) ===
        JSON.stringify(deliveryNotes.deliveryRowPagesAppliedFilters)
    ) {
      this.setState({
        paginatedRows: deliveryRowPage.deliveryRows,
      });
      setDeliveryRowPagesLoading(LOADING_STATE.SUCCEEDED);
      return;
    }

    setDeliveryRowPagesLoading(LOADING_STATE.LOADING);

    if (this.pagesLoading.includes(page)) {
      return;
    }

    this.pagesLoading.push(page);

    const [response, error] = await promiseHandler(
      DeliveriesService.loadDlnPage(
        page,
        selectedProcessState,
        selectedAcceptState,
        selectedSettledStatus,
        selectedToSiteRecipient,
        selectedToSiteSupplier,
        selectedArticle,
        selectedSupplier,
        selectedRecipient,
        selectedPermittedToSites,
        selectedPermittedCostCenters,
        selectedSites,
        selectedCostCenters,
        dateRange,
        calculatedFilterModel,
        selectedFromSite,
      ),
    );

    // If the filterId has been updated in the meantime, the request is outdated and the response is thrown away.
    if (this.filterId !== filterId) {
      return;
    }

    if (error) {
      ToastService.error([
        'Lieferungen konnten nicht geladen werden wegen eines internen Fehlers.',
      ]);
      setDeliveryRowPagesLoading(LOADING_STATE.FAILED);
    }

    setDeliveryRowPagesLoading(LOADING_STATE.SUCCEEDED);

    if (response)
      replaceDeliveryRowPage({
        page: response.page,
        deliveryRows: response.deliveryRows,
        appliedFilters: this.getAppliedFilters(),
      });
  }

  async loadNextDeliveryRowPage(filterId) {
    const nextDeliveryRowPage = this.props.deliveryNotes.deliveryRowPages.find(
      ({ page }) => page === this.props.page + 1,
    );

    if (nextDeliveryRowPage) {
      return;
    }

    if (this.pagesLoading.includes(this.props.page + 1)) {
      return;
    }

    this.pagesLoading.push(this.props.page + 1);

    const [response] = await promiseHandler(
      DeliveriesService.loadDlnPage(
        this.props.page + 1,
        this.props.selectedProcessState,
        this.props.selectedAcceptState,
        this.props.selectedSettledStatus,
        this.props.selectedToSiteRecipient,
        this.props.selectedToSiteSupplier,
        this.props.selectedArticle,
        this.props.selectedSupplier,
        this.props.selectedRecipient,
        this.props.selectedPermittedToSites,
        this.props.selectedPermittedCostCenters,
        this.props.selectedSites,
        this.props.selectedCostCenters,
        this.props.dateRange,
        this.props.calculatedFilterModel,
        this.props.selectedFromSite,
      ),
    );

    // If the filterId has been updated in the meantime, the request is outdated and the response is thrown away.
    if (this.filterId !== filterId) {
      return;
    }

    if (response)
      this.props.replaceDeliveryRowPage({
        page: response.page,
        deliveryRows: response.deliveryRows,
        appliedFilters: this.getAppliedFilters(),
      });
  }

  async loadDeliveryRowCount() {
    if (!this.isArchiveMode()) {
      return;
    }

    this.props.setDeliveryRowCountLoading(LOADING_STATE.LOADING);

    const [count, error] = await promiseHandler(
      DeliveriesService.countDlns(
        this.props.selectedProcessState,
        this.props.selectedAcceptState,
        this.props.selectedSettledStatus,
        this.props.selectedToSiteRecipient,
        this.props.selectedToSiteSupplier,
        this.props.selectedArticle,
        this.props.selectedSupplier,
        this.props.selectedRecipient,
        this.props.selectedPermittedToSites,
        this.props.selectedPermittedCostCenters,
        this.props.selectedSites,
        this.props.selectedCostCenters,
        this.props.dateRange,
        this.props.calculatedFilterModel,
        this.props.selectedFromSite,
      ),
    );

    if (error) {
      Log.error('Failed to load delivery note count.', error);
      Log.productAnalyticsEvent(
        'Failed to load delivery note count',
        Log.FEATURE.DELIVERY_LIST,
        Log.TYPE.ERROR,
      );
      this.props.setDeliveryRowCountLoading(LOADING_STATE.FAILED);
      return;
    }

    this.props.setDeliveryRowCount(count);
  }

  isArchiveMode() {
    return DeliveriesService.isArchiveMode(this.props.dateRange);
  }

  getAppliedFilters() {
    const {
      calculatedFilterModel,
      dateRange,
      query,
      selectedAcceptState,
      selectedArticle,
      selectedArticleNumber,
      selectedCostCenter,
      selectedCostCenters,
      selectedCustomFields,
      selectedFromSite,
      selectedPermittedCostCenters,
      selectedPermittedToSites,
      selectedProcessState,
      selectedRecipient,
      selectedSettledStatus,
      selectedSites,
      selectedSupplier,
      selectedToSiteRecipient,
      selectedToSiteSupplier,
    } = this.props;

    return {
      calculatedFilterModel,
      dateRange,
      query,
      selectedAcceptState,
      selectedArticle,
      selectedArticleNumber,
      selectedCostCenter,
      selectedCostCenters,
      selectedCustomFields,
      selectedFromSite,
      selectedPermittedCostCenters,
      selectedPermittedToSites,
      selectedProcessState,
      selectedRecipient,
      selectedSettledStatus,
      selectedSites,
      selectedSupplier,
      selectedToSiteRecipient,
      selectedToSiteSupplier,
    };
  }

  onPdfExport = (downloadOption) => {
    ExportService.exportDeliveryNotes(
      this.state.rowSelectionModel,
      downloadOption,
    );
  };
  onRowSelectionModelChange = (event) => {
    Log.info(
      'Change selection value of selected delivery notes',
      { from: this.state.rowSelectionModel, to: event },
      Log.BREADCRUMB.SELECTION_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      '(De)select delivery note',
      Log.FEATURE.DELIVERY_LIST,
    );

    this.setState({
      rowSelectionModel: event,
    });
  };
  onFilterModelChange = (event) => {
    Log.info(
      'Change filter value of filter model',
      { from: this.props.calculatedFilterModel, to: event },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Filter', Log.FEATURE.DELIVERY_LIST);

    this.props.setDeliveryList_filterModel(event);
  };
  onOpenDeliveryNote = (id) => {
    Log.productAnalyticsEvent('Open delivery note', Log.FEATURE.DELIVERY_LIST);
    this.props.replaceBrowsableDeliveryNotes(this.state.rowSelectionModel);
    this.props.history.push(ROUTE.DELIVERY_NOTE.ROUTE + '/' + id);
  };
  onOpenDeliveryNoteInNewTab = () => {
    Log.productAnalyticsEvent(
      'Open delivery note in new tab',
      Log.FEATURE.DELIVERY_LIST,
    );
    this.props.replaceBrowsableDeliveryNotes(this.state.rowSelectionModel);
    BrowserUtils.openNewTab(
      ROUTE.DELIVERY_NOTE.ROUTE + '/' + this.state.contextMenu.id,
    );
    this.onCloseContextMenu();
  };
  onOpenDeliveryDataQuality = (id) => {
    Log.productAnalyticsEvent(
      'Open delivery note data quality',
      Log.FEATURE.SUPPLIER_OVERVIEW,
    );
    this.props.history.push(ROUTE.DELIVERY_NOTE_DATA_QUALITY.ROUTE + '/' + id);
  };
  onOpenContextMenu = (event) => {
    event.preventDefault();

    if (this.state.contextMenu) {
      // Repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu. Other native context menus might behave different.
      // With this behavior we prevent contextmenu from the backdrop to re-locale existing context men
      this.setState({
        contextMenu: null,
      });
      return;
    }

    Log.productAnalyticsEvent('Open context menu', Log.FEATURE.MENU);
    this.setState({
      contextMenu: {
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
        id: event.currentTarget.dataset.id,
      },
    });
  };
  onCloseContextMenu = () => {
    Log.productAnalyticsEvent('Close context menu', Log.FEATURE.MENU);
    this.setState({
      contextMenu: null,
    });
  };
  onSortModelChange = (event) => {
    if (this.isArchiveMode()) {
      ToastService.info([
        'Sortierung ist im Archiv derzeit noch nicht verfügbar.',
      ]);
      return;
    }

    Log.productAnalyticsEvent('Sort', Log.FEATURE.DELIVERY_LIST, undefined, {
      sortModel: event,
    });

    this.props.setDeliveryList_sortModel(event);
  };

  /* onDeliveryNoteSignature = async() => {
        if(this.state.rowSelectionModel.length === 0) {
            ToastService.warning(['Bitte wähle mindestens eine Lieferung aus, die du signieren willst.']);
            return;
        }

        this.setState({
            deliveryNoteSignaturePushIsLoading: true
        });

        const [response, err] = await promiseHandler(PushService.requestSignature(this.state.rowSelectionModel, []));

        if(err) {
            Log.error('Failed to send push to request delivery note signature.', err);
            ToastService.httpError(['Die Lieferung konnte nicht in der mobilen App signiert werden wegen eines internen Fehlers.'], err.response);
            this.setState({
                deliveryNoteSignaturePushIsLoading: false
            });
            return;
        }

        ToastService.info(['Die Lieferungen können nun in der mobilen App signiert werden.']);

        this.setState({
            deliveryNoteSignaturePushIsLoading: false
        });
    } */

  onRequestDeliveryNoteSignature = () => {
    if (this.state.rowSelectionModel.length === 0) {
      ToastService.warning([
        'Bitte wähle mindestens eine Lieferung aus, die von einem anderen Benutzer signiert werden soll.',
      ]);

      Log.productAnalyticsEvent(
        'No delivery note selected to request signature',
        Log.FEATURE.NOTIFICATIONS,
        Log.TYPE.FAILED_VALIDATION,
      );

      return;
    }

    Log.productAnalyticsEvent(
      'Open request signature form',
      Log.FEATURE.NOTIFICATIONS,
    );

    this.setState({
      requestDeliveryNoteSignatureFormOpen: true,
    });
  };
  onShareDeliveryNote = () => {
    if (this.state.rowSelectionModel.length === 0) {
      ToastService.warning([
        'Bitte wähle mindestens eine Lieferung aus, die mit einem anderen Benutzer geteilt werden soll.',
      ]);

      Log.productAnalyticsEvent(
        'No delivery note selected to share',
        Log.FEATURE.SHARE_DELIVERY_NOTE,
        Log.TYPE.FAILED_VALIDATION,
      );

      return;
    }

    // Because otherwise too many requests would be sent to the backend to get the users that the delivery note is already shared with.
    if (this.state.rowSelectionModel.length > 5) {
      ToastService.warning([
        'Bitte wähle maximal 5 Lieferungen aus, die mit einem anderen Benutzer geteilt werden sollen.',
      ]);

      Log.productAnalyticsEvent(
        'More than 5 delivery notes selected to share',
        Log.FEATURE.SHARE_DELIVERY_NOTE,
        Log.TYPE.FAILED_VALIDATION,
      );

      return;
    }

    Log.productAnalyticsEvent(
      'Open share delivery note form',
      Log.FEATURE.SHARE_DELIVERY_NOTE,
    );

    this.setState({
      shareDeliveryNoteFormOpen: true,
    });
  };
  onMapDirectDeliveryNote = async () => {
    if (this.state.rowSelectionModel.length === 0) {
      ToastService.warning([
        'Bitte wähle mindestens eine Lieferung aus, die einem Standort und einer Kostenstelle zugewiesen werden soll.',
      ]);

      Log.productAnalyticsEvent(
        'No delivery note selected to map',
        Log.FEATURE.SITE_MAPPING,
        Log.TYPE.FAILED_VALIDATION,
      );

      return;
    }

    // Because otherwise too many requests would be sent to the backend to update the direct mapping.
    if (this.state.rowSelectionModel.length > 10) {
      ToastService.warning([
        'Bitte wähle maximal 10 Lieferungen aus, die einem Standort und einer Kostenstelle zugewiesen werden sollen.',
      ]);

      Log.productAnalyticsEvent(
        'More than 10 delivery notes selected to map',
        Log.FEATURE.SITE_MAPPING,
        Log.TYPE.FAILED_VALIDATION,
      );

      return;
    }

    Log.productAnalyticsEvent(
      'Open map direct delivery note form',
      Log.FEATURE.SITE_MAPPING,
    );

    const mapDirectDeliveryNoteIssuerIds = [];
    const mapDirectDeliveryNoteToSiteSupplierNames = [];
    const mapDirectDeliveryNotePermittedToSiteIds = [];
    const mapDirectDeliveryNotePermittedCostCenterIds = [];

    for (let index = 0; index < this.state.rowSelectionModel.length; index++) {
      const [deliveryNote, error] = await promiseHandler(
        DeliveriesService.getDeliveryNoteById(
          this.state.rowSelectionModel[index],
        ),
      );

      if (error) {
        Log.error('Failed to load delivery note', error);
        Log.productAnalyticsEvent(
          'Failed to load delivery note',
          Log.FEATURE.DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );
        continue;
      }

      mapDirectDeliveryNoteIssuerIds.push(deliveryNote.issuer.id);
      mapDirectDeliveryNoteToSiteSupplierNames.push(
        deliveryNote.toSiteSupplier.name,
      );
      mapDirectDeliveryNotePermittedToSiteIds.push(
        ...deliveryNote.permittedToSites.map((site) => site.id),
      );
      mapDirectDeliveryNotePermittedCostCenterIds.push(
        ...deliveryNote.permittedCostCenters.map((costCenter) => costCenter.id),
      );
    }

    this.setState({
      mapDirectDeliveryNoteFormOpen: true,
      mapDirectDeliveryNoteIssuerIds,
      mapDirectDeliveryNoteToSiteSupplierNames,
      mapDirectDeliveryNoteDefaultSelectedSiteId:
        ArrayUtils.getMostFrequentValue(
          mapDirectDeliveryNotePermittedToSiteIds,
        ),
      mapDirectDeliveryNoteDefaultSelectedCostCenterId:
        ArrayUtils.getMostFrequentValue(
          mapDirectDeliveryNotePermittedCostCenterIds,
        ),
    });
  };
  onOpenReport = () => {
    if (this.state.rowSelectionModel.length === 0) {
      ToastService.info([
        'Bitte wähle mindestens eine Lieferung aus, für die die Statistiken erstellt werden sollen.',
      ]);
      Log.productAnalyticsEvent(
        'No delivery note selected for reports modal',
        Log.FEATURE.DELIVERY_LIST,
        Log.TYPE.FAILED_VALIDATION,
      );

      return;
    }

    Log.productAnalyticsEvent('Open reports modal', Log.FEATURE.DELIVERY_LIST);

    this.setState({
      reportOpen: true,
      reportData: DashboardService.transformDeliveryNotes(
        this.props.deliveryNotes.filteredDeliveryNotes.filter(({ id }) =>
          this.state.rowSelectionModel.includes(id),
        ),
      ),
    });
  };
  onCloseReport = () => {
    this.setState({
      reportOpen: false,
      reportData: [],
    });
  };
  onRequestSingleSignatures = (id) => {
    this.setState({
      requestDeliveryNoteSignatureFormOpenDeliveryNoteId: id,
    });
  };
  onShareSingleDeliveryNote = (id) => {
    this.setState({
      shareDeliveryNoteFormOpenDeliveryNoteId: id,
    });
  };
  onPageChange = (newPage) => {
    Log.info(
      'Open page',
      {
        from: this.props.page,
        to: newPage,
      },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent('Open page', Log.FEATURE.DELIVERY_LIST);

    // If the user is going back a page or not yet to the 5th page,
    // we can just change the page and load the data from the backend.
    if (newPage < this.props.page || newPage < this.BROWSE_MAX_PAGES) {
      this.props.setDeliveryList_page(newPage);
      return;
    }

    // Otherwise, we need to check if the user hasn't opened too many pages at once.
    // If the user is switching to the next page, don't open the next page if the delivery rows of old pages haven't been loaded yet.
    // This should prevent that the user spams the backend with request by switching pages too fast.
    let notLoadedPagesCount = 0;

    // Iterate through all the earlier pages and check if data is already loaded. There should be at most 5 pages that are loading at once.
    for (let index = 0; index < this.props.page; index++) {
      const isPageLoaded = this.props.deliveryNotes.deliveryRowPages.find(
        ({ page }) => page === newPage - index,
      );

      if (!isPageLoaded) {
        notLoadedPagesCount++;
      }
    }

    if (notLoadedPagesCount >= this.BROWSE_MAX_PAGES) {
      ToastService.info(
        ['Bitte warten Sie, bis die vorherigen Daten geladen wurden.'],
        ToastService.ID.DLN_PAGE_CHANGE,
      );

      return;
    }

    this.props.setDeliveryList_page(newPage);
  };

  getArchiveModeColumProps(
    propertyKey,
    muiDataGridDataType = MuiDataGridFilter.DATA_TYPE.STRING,
  ) {
    if (!this.isArchiveMode())
      return {
        filterable: true,
        filterOperators:
          MuiDataGridFilter.getMuiDataGridDefaultOperators(muiDataGridDataType),
      };

    return {
      filterable: FilterNew.fieldIsApplicableBackendFilter(
        propertyKey,
        FilterContext.PAGE.DELIVERY,
      ),
      filterOperators:
        MuiDataGridFilter.getApplicableMuiDataGridOperatorsForMuiDataGridDataType(
          muiDataGridDataType,
        ),
    };
  }

  getColumns() {
    const isSchickerAccount = UserUtils.isSchickerAccount(
      this.props.userinfo.userinfo.company.companyAccount,
    );

    const showFullDlnDateDateTime = UserUtils.showFullDlnDateDateTime(
      this.props.userinfo.userinfo.company.companyAccount,
    );

    const columns = [
      {
        field: DeliveryNote.PROPERTY.CATEGORY.KEY,
        headerName: DeliveryNote.PROPERTY.CATEGORY.STRING,
        width: 100,
        sortable: false,
        ...this.getArchiveModeColumProps(DeliveryNote.PROPERTY.CATEGORY.KEY),
        renderCell: (params) => (
          <div className="flex h-full w-full items-center justify-center">
            <DeliveryCategoryIcon
              category={DeliveryNote.getProcessCategoryKeyFromString(
                params.value,
              )}
              className="size-8"
            />
          </div>
        ),
      },
      {
        field: DeliveryNote.PROPERTY.STATUS.KEY,
        headerName: DeliveryNote.PROPERTY.STATUS.STRING,
        width: BrowserUtils.isWideScreen() ? 200 : 120,
        sortable: false,
        ...this.getArchiveModeColumProps(DeliveryNote.PROPERTY.STATUS.KEY),
        valueFormatter(params) {
          const processState = params.split(';')?.[0];

          return processState;
        },
        renderCell(params) {
          const processState = params.value.split(';')?.[0];
          const combinedState = params.value.split(';')?.[2];
          const settledStatus = params.value.split(';')?.[3];

          const localData = LocalStorageService.getObjectFromLocalStorage(
            LocalStorageService.DELIVERY_LIST,
          );

          const className =
            'w-170px ' + DatagridUtils.getStatusBoxHeight(localData?.rowHeight);
          let centerIcon =
            localData?.rowHeight <= DatagridUtils.ROW_HEIGHT.THIN;
          if (!localData?.rowHeight) {
            centerIcon = true; // Center icon as default because default row height is thin
          }

          return (
            <div className={className}>
              <DeliveryStatus
                processState={processState}
                combinedState={combinedState}
                settledStatus={settledStatus}
                centerIcon={centerIcon}
                whiteBackground
              />
            </div>
          );
        },
      },
      {
        field: 'deliveryListActions',
        headerName: 'Aktionen',
        width: 150,
        sortable: false,
        filterable: false,
        valueFormatter: () => '',
        renderCell: (params) => (
          <DeliveryListActions
            params={params}
            onRequestSignatures={this.onRequestSingleSignatures}
            onShareDeliveryNote={this.onShareSingleDeliveryNote}
          />
        ),
      },
      {
        field: DeliveryNote.PROPERTY.NUMBER.KEY,
        headerName: DeliveryNote.PROPERTY.NUMBER.STRING,
        width: 200,
        sortable: true,
        ...this.getArchiveModeColumProps(DeliveryNote.PROPERTY.NUMBER.KEY),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.DLN_DATE.KEY,
        headerName: DeliveryNote.PROPERTY.DLN_DATE.STRING,
        type: MuiDataGridFilter.DATA_TYPE.DATE,
        width: 170,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.DLN_DATE.KEY,
          MuiDataGridFilter.DATA_TYPE.DATE,
        ),
        resizableText: true,
        valueFormatter: (params) =>
          dateUtils.getFormattedDateWithoutMidnight_safe(
            params,
            showFullDlnDateDateTime
              ? dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss
              : dateUtils.DATE_FORMAT.DD_MM_YYYY,
          ),
        renderCell: (params) =>
          dateUtils.getFormattedDateWithoutMidnight_safe(
            params.value,
            showFullDlnDateDateTime
              ? dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss
              : dateUtils.DATE_FORMAT.DD_MM_YYYY,
          ),
      },
      {
        field: DeliveryNote.PROCESS_ROLE.SUPPLIER.KEY,
        headerName: DeliveryNote.PROCESS_ROLE.SUPPLIER.STRING,
        width: 250,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROCESS_ROLE.SUPPLIER.KEY,
        ),
        resizableText: true,
      },
      {
        field: DeliveryNote.CONTRACT_ROLE.BUYER.KEY,
        headerName: DeliveryNote.CONTRACT_ROLE.BUYER.STRING,
        width: 250,
        sortable: true,
        ...this.getArchiveModeColumProps(DeliveryNote.CONTRACT_ROLE.BUYER.KEY),
        resizableText: true,
      },
      {
        field: DeliveryNote.CONTRACT_ROLE.SELLER.KEY,
        headerName: DeliveryNote.CONTRACT_ROLE.SELLER.STRING,
        width: 250,
        sortable: true,
        ...this.getArchiveModeColumProps(DeliveryNote.CONTRACT_ROLE.SELLER.KEY),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROCESS_ROLE.RECIPIENT.KEY,
        headerName: DeliveryNote.PROCESS_ROLE.RECIPIENT.STRING,
        width: 250,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROCESS_ROLE.RECIPIENT.KEY,
        ),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.TRADER.KEY,
        headerName: DeliveryNote.PROPERTY.TRADER.STRING,
        width: 250,
        sortable: true,
        ...this.getArchiveModeColumProps(DeliveryNote.PROPERTY.TRADER.KEY),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.KEY,
        headerName: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.STRING,
        width: 400,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.KEY,
        ),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER_ADDRESS.KEY,
        headerName: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER_ADDRESS.STRING,
        width: 400,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.TO_SITE_SUPPLIER_ADDRESS.KEY,
        ),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.COST_CENTERS.KEY,
        headerName: DeliveryNote.PROPERTY.COST_CENTERS.STRING,
        width: 150,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.COST_CENTERS.KEY,
        ),
        resizableText: true,
        ...DatagridUtils.customColumnTypeArray(),
      },
      {
        field: DeliveryNote.PROPERTY.MAIN_ARTICLE_NUMBER.KEY,
        headerName: DeliveryNote.PROPERTY.MAIN_ARTICLE_NUMBER.STRING,
        width: 200,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.MAIN_ARTICLE_NUMBER.KEY,
        ),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.MAIN_ARTICLE_TYPE.KEY,
        headerName: DeliveryNote.PROPERTY.MAIN_ARTICLE_TYPE.STRING,
        width: 250,
        sortable: true,
        ...this.getArchiveModeColumProps(DeliveryNote.PROPERTY.ARTICLE.KEY),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.MAIN_ARTICLE_AMOUNT_VALUE.KEY,
        headerName: DeliveryNote.PROPERTY.MAIN_ARTICLE_AMOUNT_VALUE.STRING,
        type: MuiDataGridFilter.DATA_TYPE.NUMBER,
        width: 150,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.MAIN_ARTICLE_AMOUNT_VALUE.KEY,
          MuiDataGridFilter.DATA_TYPE.NUMBER,
        ),
        resizableText: true,
        renderCell: (params) => UnitUtils.formatDe_safe(params.value),
      },
      {
        field: DeliveryNote.PROPERTY.MAIN_ARTICLE_AMOUNT_UNIT.KEY,
        headerName: DeliveryNote.PROPERTY.MAIN_ARTICLE_AMOUNT_UNIT.STRING,
        width: 100,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.MAIN_ARTICLE_AMOUNT_UNIT.KEY,
        ),
        resizableText: true,
        renderCell: (params) => UnitUtils.getAbbreviatedUnit(params.value),
      },
      {
        field: DeliveryNote.PROPERTY.TOTAL_WEIGHT_GROSS.KEY,
        headerName: DeliveryNote.PROPERTY.TOTAL_WEIGHT_GROSS.STRING,
        width: 200,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.TOTAL_WEIGHT_GROSS.KEY,
        ),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.TOTAL_WEIGHT_NET.KEY,
        headerName: DeliveryNote.PROPERTY.TOTAL_WEIGHT_NET.STRING,
        width: 200,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.TOTAL_WEIGHT_NET.KEY,
        ),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.TOTAL_WEIGHT_TARE.KEY,
        headerName: DeliveryNote.PROPERTY.TOTAL_WEIGHT_TARE.STRING,
        width: 200,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.TOTAL_WEIGHT_TARE.KEY,
        ),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.DESCRIPTION.KEY,
        headerName: DeliveryNote.PROPERTY.DESCRIPTION.STRING,
        width: 250,
        sortable: true,
        ...this.getArchiveModeColumProps(DeliveryNote.PROPERTY.DESCRIPTION.KEY),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.COMMENTS.KEY,
        headerName: DeliveryNote.PROPERTY.COMMENTS.STRING,
        width: 250,
        sortable: true,
        ...this.getArchiveModeColumProps(DeliveryNote.PROPERTY.COMMENTS.KEY),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.CUSTOM_DATA.KEY,
        headerName: DeliveryNote.PROPERTY.CUSTOM_DATA.STRING,
        width: 400,
        sortable: true,
        ...this.getArchiveModeColumProps(DeliveryNote.PROPERTY.CUSTOM_DATA.KEY),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.KEY,
        headerName: DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.STRING,
        width: 400,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.KEY,
        ),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.PROCESS_STATE_CHANGE_USER.KEY,
        headerName: DeliveryNote.PROPERTY.PROCESS_STATE_CHANGE_USER.STRING,
        width: 200,
        sortable: false,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.PROCESS_STATE_CHANGE_USER.KEY,
        ),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.FROM_SITE.KEY,
        headerName: DeliveryNote.PROPERTY.FROM_SITE.STRING,
        width: 400,
        sortable: true,
        ...this.getArchiveModeColumProps(DeliveryNote.PROPERTY.FROM_SITE.KEY),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.FROM_SITE_ISSUER_ASSIGNED_ID.KEY,
        headerName: DeliveryNote.PROPERTY.FROM_SITE_ISSUER_ASSIGNED_ID.STRING,
        width: 200,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.FROM_SITE_ISSUER_ASSIGNED_ID.KEY,
        ),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROCESS_ROLE.CARRIER.KEY,
        headerName: DeliveryNote.PROCESS_ROLE.CARRIER.STRING,
        width: 250,
        sortable: true,
        ...this.getArchiveModeColumProps(DeliveryNote.PROCESS_ROLE.CARRIER.KEY),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.LICENSE_PLATE.KEY,
        headerName: DeliveryNote.PROPERTY.LICENSE_PLATE.STRING,
        width: 150,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.LICENSE_PLATE.KEY,
        ),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.SELLER_ORDER_REFERENCES.KEY,
        headerName: DeliveryNote.PROPERTY.SELLER_ORDER_REFERENCES.STRING,
        width: 150,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.SELLER_ORDER_REFERENCES.KEY,
        ),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.BUYER_ORDER_REFERENCES.KEY,
        headerName: DeliveryNote.PROPERTY.BUYER_ORDER_REFERENCES.STRING,
        width: 150,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.BUYER_ORDER_REFERENCES.KEY,
        ),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.CONSTRUCTION_PLANS.KEY,
        headerName: DeliveryNote.PROPERTY.CONSTRUCTION_PLANS.STRING,
        width: 150,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.CONSTRUCTION_PLANS.KEY,
        ),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.CONSTRUCTION_COMPONENTS.KEY,
        headerName: DeliveryNote.PROPERTY.CONSTRUCTION_COMPONENTS.STRING,
        width: 150,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.CONSTRUCTION_COMPONENTS.KEY,
        ),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.MOVEMENT_MEANS.KEY,
        headerName: DeliveryNote.PROPERTY.MOVEMENT_MEANS.STRING,
        width: 150,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.MOVEMENT_MEANS.KEY,
        ),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.PROJECT.KEY,
        headerName: DeliveryNote.PROPERTY.PROJECT.STRING,
        width: 150,
        sortable: true,
        ...this.getArchiveModeColumProps(DeliveryNote.PROPERTY.PROJECT.KEY),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.BUYER_ID.KEY,
        headerName: DeliveryNote.PROPERTY.BUYER_ID.STRING,
        width: 150,
        sortable: true,
        ...this.getArchiveModeColumProps(DeliveryNote.PROPERTY.BUYER_ID.KEY),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.DELIVERY_TYPE.KEY,
        headerName: DeliveryNote.PROPERTY.DELIVERY_TYPE.STRING,
        width: 150,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.DELIVERY_TYPE.KEY,
        ),
        resizableText: true,
      },
      {
        field: DeliveryNote.PROPERTY.CREATION_DATE.KEY,
        headerName: DeliveryNote.PROPERTY.CREATION_DATE.STRING,
        type: MuiDataGridFilter.DATA_TYPE.DATE,
        width: 180,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.CREATION_DATE.KEY,
          MuiDataGridFilter.DATA_TYPE.DATE,
        ),
        resizableText: true,
        valueGetter: parseDate,
        valueFormatter: (params) =>
          dateUtils.getFormattedDateWithoutMidnight_safe(
            params,
            isSchickerAccount
              ? dateUtils.DATE_FORMAT.DD_MM_YYYY
              : dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
        renderCell: (params) =>
          dateUtils.getFormattedDate_safe(
            params.value,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
          ),
      },
      {
        field: DeliveryNote.PROPERTY.MODIFIED_DATE.KEY,
        headerName: DeliveryNote.PROPERTY.MODIFIED_DATE.STRING,
        type: MuiDataGridFilter.DATA_TYPE.DATE,
        width: 180,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.MODIFIED_DATE.KEY,
          MuiDataGridFilter.DATA_TYPE.DATE,
        ),
        resizableText: true,
        valueGetter: parseDate,
        valueFormatter: (params) =>
          dateUtils.getFormattedDateWithoutMidnight_safe(
            params,
            isSchickerAccount
              ? dateUtils.DATE_FORMAT.DD_MM_YYYY
              : dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
        renderCell: (params) =>
          dateUtils.getFormattedDate_safe(
            params.value,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
          ),
      },
      {
        field: DeliveryNote.PROPERTY.CARRIER_ID.KEY,
        headerName: DeliveryNote.PROPERTY.CARRIER_ID.STRING,
        width: 150,
        sortable: true,
        ...this.getArchiveModeColumProps(DeliveryNote.PROPERTY.CARRIER_ID.KEY),
        resizableText: true,
      },
    ];

    if (
      this.props.userinfo.userinfo.userFeatureFlags.accessPermittedSites ||
      UserUtils.isPermittedSiteAllowedUser()
    ) {
      columns.push({
        field: DeliveryNote.PROPERTY.PERMITTED_TO_SITES.KEY,
        headerName: DeliveryNote.PROPERTY.PERMITTED_TO_SITES.STRING,
        width: 400,
        sortable: true,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.PERMITTED_TO_SITES.KEY,
        ),
        resizableText: true,
        valueFormatter(params) {
          const permittedToSites = params
            .split(';;;')
            .filter(Boolean)
            .map(JSON.parse);

          return permittedToSites
            .filter(({ active }) => active)
            .map(({ name }) => name)
            .join(', ');
        },
        renderCell(params) {
          if (!params.value) {
            return '';
          }

          const permittedToSites = params.value.split(';;;').map(JSON.parse);

          if (permittedToSites.every(({ active }) => active))
            return DatagridUtils.displayCellTooltipControlled(
              permittedToSites.map(({ name }) => name).join(', '),
              permittedToSites.map(({ name }) => name).join(', '),
            );

          return (
            <div className="flex-s-c gap-5px">
              {permittedToSites.map((site) => {
                if (site.active) {
                  return site.name;
                }

                return (
                  <span className="flex-s-c gap-5px">
                    {site.name}
                    <InactiveIcon />
                  </span>
                );
              })}
            </div>
          );
        },
      });
      columns.push({
        field: DeliveryNote.PROPERTY.PERMITTED_COST_CENTERS.KEY,
        headerName: DeliveryNote.PROPERTY.PERMITTED_COST_CENTERS.STRING,
        width: 400,
        sortable: false,
        ...this.getArchiveModeColumProps(
          DeliveryNote.PROPERTY.PERMITTED_COST_CENTERS.KEY,
        ),
        resizableText: true,
        valueFormatter(params) {
          const permittedToCostCenters = params
            .split(';;;')
            .filter(Boolean)
            .map(JSON.parse);

          return permittedToCostCenters
            .filter(({ active }) => active)
            .map(({ name }) => name)
            .join(', ');
        },
        renderCell(params) {
          if (!params.value) {
            return '';
          }

          const permittedToCostCenters = params.value
            .split(';;;')
            .map(JSON.parse);

          if (permittedToCostCenters.every(({ active }) => active)) {
            return DatagridUtils.displayCellTooltipControlled(
              permittedToCostCenters.map(({ name }) => name).join(', '),
              permittedToCostCenters.map(({ name }) => name).join(', '),
            );
          }

          return (
            <div className="flex-s-c gap-5px">
              {permittedToCostCenters.map((costCenter) => {
                if (costCenter.active) {
                  return costCenter.name;
                }

                return (
                  <span key={costCenter.name} className="flex-s-c gap-5px">
                    {costCenter.name}
                    <InactiveIcon />
                  </span>
                );
              })}
            </div>
          );
        },
      });
    }

    for (const customField of this.props.customFields.customFields) {
      columns.push({
        field: customField.key,
        headerName: customField.displayName,
        width: 400,
        sortable: true,
        ...this.getArchiveModeColumProps(customField.key),
        resizableText: true,
        // It might happen that a custom field has been used both on article and delivery note level.
        // In this case, if we check for customField.level === CustomField.LEVEL.ARTICLE.KEY, it might still happen
        // that params.value is an array. Thus, it is safer to check for Array.isArray(params.value) and join the array.
        renderCell: (params) =>
          params.value && Array.isArray(params.value)
            ? params.value.join(', ')
            : params.value,
      });
    }

    return columns;
  }

  getDefaultHiddenColumns() {
    const userType = this.props.userinfo.userinfo.userType;

    const hiddenColumns = [
      DeliveryNote.PROPERTY.TOTAL_WEIGHT_GROSS.KEY,
      DeliveryNote.PROPERTY.TOTAL_WEIGHT_NET.KEY,
      DeliveryNote.PROPERTY.TOTAL_WEIGHT_TARE.KEY,
      DeliveryNote.PROPERTY.CATEGORY.KEY,
      DeliveryNote.PROPERTY.TRADER.KEY,
      DeliveryNote.CONTRACT_ROLE.BUYER.KEY,
      DeliveryNote.CONTRACT_ROLE.SELLER.KEY,
      DeliveryNote.PROPERTY.COMMENTS.KEY,
      DeliveryNote.PROPERTY.FROM_SITE.KEY,
      DeliveryNote.PROPERTY.FROM_SITE_ISSUER_ASSIGNED_ID.KEY,
      DeliveryNote.PROPERTY.CONSTRUCTION_PLANS.KEY,
      DeliveryNote.PROPERTY.CONSTRUCTION_COMPONENTS.KEY,
      DeliveryNote.PROPERTY.BUYER_ID.KEY,
      DeliveryNote.PROPERTY.TO_SITE_SUPPLIER_ADDRESS.KEY,
      DeliveryNote.PROPERTY.SIGNATURES_REQUESTED_FROM.KEY,
      DeliveryNote.PROPERTY.DELIVERY_SHARED_WITH.KEY,
      DeliveryNote.PROPERTY.MODIFIED_DATE.KEY,
      DeliveryNote.PROPERTY.CARRIER_ID.KEY,
    ];

    switch (userType) {
      case User.TYPE.SUPPLIER.KEY: {
        hiddenColumns.push(DeliveryNote.PROCESS_ROLE.SUPPLIER.KEY);
        break;
      }

      case User.TYPE.CARRIER.KEY: {
        hiddenColumns.push(DeliveryNote.PROCESS_ROLE.CARRIER.KEY);
        break;
      }

      case User.TYPE.RECIPIENT.KEY: {
        hiddenColumns.push(DeliveryNote.PROCESS_ROLE.RECIPIENT.KEY);
        break;
      }

      default: {
        break;
      }
    }

    return hiddenColumns;
  }

  getPaginationObject() {
    if (!this.isArchiveMode()) {
      return null;
    }

    return {
      paginationMode: 'server',
      onPageChange: this.onPageChange,
      page: this.props.page,
      pageSize: DeliveriesService.DELIVERY_NOTES_PAGE_SIZE,
      rowCount: this.props.deliveryNotes.deliveryRowCount,
      paginationLoading: this.props.deliveryNotes.deliveryRowCountLoading,
      filterMode: 'server',
    };
  }

  getRows() {
    if (!this.isArchiveMode()) {
      return this.state.filteredRows;
    }

    return this.state.paginatedRows;
  }

  getLoading() {
    if (!this.isArchiveMode()) {
      return this.state.isLoading
        ? LOADING_STATE.LOADING
        : this.props.deliveryNotes.deliveryNotesLoading;
    }

    return this.props.deliveryNotes.deliveryRowPagesLoading;
  }

  render() {
    const csvOptions = {};

    if (
      UserUtils.isSchickerAccount(
        this.props.userinfo.userinfo.company.companyAccount,
      )
    ) {
      csvOptions.delimiter = ';';
      csvOptions.includeHeaders = false;
    }

    return (
      <div className="mt-20px rounded-5px box-shadow-blue h-[600px] flex-1 bg-white">
        <BasicTable
          checkboxSelection
          columns={this.getColumns()}
          csvOptions={csvOptions}
          defaultHiddenColumns={this.getDefaultHiddenColumns()}
          disableColumnFilter={FeatureService.clientPortal()}
          disableSelectAllRowsButton={this.isArchiveMode()}
          disableSelectAllRowsButtonMessage="Im Archiv können nicht alle Lieferungen ausgewählt werden."
          excelColumns={this.getColumns()}
          excelData={this.state.excelData}
          filterModel={this.props.calculatedFilterModel}
          loading={this.getLoading()}
          localStorageKey={LocalStorageService.DELIVERY_LIST}
          logicOperators={
            this.isArchiveMode()
              ? [FilterNew.BOOLEAN_OPERATOR.AND]
              : [FilterNew.BOOLEAN_OPERATOR.AND, FilterNew.BOOLEAN_OPERATOR.OR]
          }
          multiplePdfDownload={this.state.rowSelectionModel.length > 1}
          onFilterModelChange={this.onFilterModelChange}
          onMapDirectDeliveryNote={this.onMapDirectDeliveryNote}
          onOpenReport={this.onOpenReport}
          onPdfExport={this.onPdfExport}
          onRequestDeliveryNoteSignature={this.onRequestDeliveryNoteSignature}
          onRowClick={(rowData) => this.onOpenDeliveryNote(rowData.row.id)}
          onRowRightClick={this.onOpenContextMenu}
          onRowSelectionModelChange={this.onRowSelectionModelChange}
          onShareDeliveryNote={this.onShareDeliveryNote}
          onSortModelChange={this.onSortModelChange}
          pinnedColumnsCookieId={
            LocalStorageService.DELIVERY_LIST_PINNED_COLUMNS
          }
          productAnalyticsFeature={Log.FEATURE.DELIVERY_LIST}
          rows={this.getRows()}
          rowSelectionModel={this.state.rowSelectionModel}
          selectAllRowsCount={
            this.isArchiveMode()
              ? this.props.deliveryNotes.deliveryRowCount
              : this.getRows().length
          }
          sortModel={
            this.isArchiveMode()
              ? [
                  {
                    field: DeliveryNote.PROPERTY.CREATION_DATE.KEY,
                    sort: 'desc',
                  },
                ]
              : this.props.sortModel
          }
          withFilterModel
        />
        <ContextMenu
          open={false} // TODO: how is this thing actually opened?!
          contextMenu={this.state.contextMenu}
          onClose={this.onCloseContextMenu}
          onOpen={() => this.onOpenDeliveryNote(this.state.contextMenu.id)}
          onOpenInNewTab={this.onOpenDeliveryNoteInNewTab}
          customMenuItems={[
            {
              key: 'openDeliveryDataQuality',
              name: 'In Datenansicht öffnen',
              callback: () =>
                this.onOpenDeliveryDataQuality(this.state.contextMenu.id),
            },
          ]}
        />
        {/* When requesting signatures for / sharing multiple delivery notes via BasicTable GridToolbar. */}
        <RequestSignatureForm
          permittedUserIds={[]}
          deliveryNoteIds={this.state.rowSelectionModel}
          open={this.state.requestDeliveryNoteSignatureFormOpen}
          closeForm={() =>
            this.setState({ requestDeliveryNoteSignatureFormOpen: false })
          }
        />
        <ShareDeliveryNoteForm
          deliveryNoteIds={this.state.rowSelectionModel}
          open={this.state.shareDeliveryNoteFormOpen}
          closeForm={() => this.setState({ shareDeliveryNoteFormOpen: false })}
        />
        <MapDirectDeliveryNoteForm
          issuerIds={this.state.mapDirectDeliveryNoteIssuerIds}
          toSiteSupplierNames={
            this.state.mapDirectDeliveryNoteToSiteSupplierNames
          }
          deliveryNoteIds={this.state.rowSelectionModel}
          defaultSelectedSiteId={
            this.state.mapDirectDeliveryNoteDefaultSelectedSiteId
          }
          defaultSelectedCostCenterId={
            this.state.mapDirectDeliveryNoteDefaultSelectedCostCenterId
          }
          open={this.state.mapDirectDeliveryNoteFormOpen}
          closeForm={() =>
            this.setState({ mapDirectDeliveryNoteFormOpen: false })
          }
          formSuccess={() => this.setState({ rowSelectionModel: [] })}
        />
        {/* When requesting signatures for / sharing a single delivery note via the button in the single rows. */}
        {/* The forms are not stored in the DeliveryListActions component because otherwise there would be a form for each data grid row */}
        {/* which leads to performance bottlenecks and a slow webapp when scrolling the table. */}
        <RequestSignatureForm
          permittedUserIds={[]}
          deliveryNoteIds={
            this.state.requestDeliveryNoteSignatureFormOpenDeliveryNoteId
              ? [this.state.requestDeliveryNoteSignatureFormOpenDeliveryNoteId]
              : []
          }
          open={this.state.requestDeliveryNoteSignatureFormOpenDeliveryNoteId}
          closeForm={() =>
            this.setState({
              requestDeliveryNoteSignatureFormOpenDeliveryNoteId: null,
            })
          }
        />
        <ShareDeliveryNoteForm
          deliveryNoteIds={[this.state.shareDeliveryNoteFormOpenDeliveryNoteId]}
          open={this.state.shareDeliveryNoteFormOpenDeliveryNoteId}
          closeForm={() =>
            this.setState({
              shareDeliveryNoteFormOpenDeliveryNoteId: null,
            })
          }
        />
        {/* When requesting signatures for / sharing a single delivery note via the button in the single rows. */}
        <BasicModal
          title="Statistiken"
          fullWidth
          open={this.state.reportOpen}
          closeModal={this.onCloseReport}
        >
          <div className="h-70vh flexdir-column flex">
            <DashboardReport data={this.state.reportData} />
          </div>
        </BasicModal>
      </div>
    );
  }
}

DeliveryListComponent.propTypes = {
  calculatedFilterModel: object.isRequired,
  customFields: shape({
    customFields: array.isRequired,
  }).isRequired,
  dateRange: array.isRequired,
  deliveryNotes: shape({
    deliveryNotesLoading: string.isRequired,
    deliveryRowPages: array.isRequired,
    filteredDeliveryRows: array.isRequired,
    filteredDeliveryNotesVersion: number.isRequired,
    deliveryRowPagesAppliedFilters: object,
    deliveryRowPagesLoading: string,
    deliveryRowCount: number,
    deliveryRowCountLoading: string,
  }).isRequired,
  page: number.isRequired,
  query: string.isRequired,
  selectedAcceptState: array.isRequired,
  selectedArticle: array.isRequired,
  selectedArticleNumber: array.isRequired,
  selectedCostCenter: array.isRequired,
  selectedCostCenters: array.isRequired,
  selectedCustomFields: array.isRequired,
  selectedFromSite: array.isRequired,
  selectedPermittedCostCenters: array.isRequired,
  selectedPermittedToSites: array.isRequired,
  selectedProcessState: array.isRequired,
  selectedRecipient: array.isRequired,
  selectedSettledStatus: array.isRequired,
  selectedSites: array.isRequired,
  selectedSupplier: array.isRequired,
  selectedToSiteRecipient: array.isRequired,
  selectedToSiteSupplier: array.isRequired,
  selectField: string,
  setDeliveryList_filterModel: func.isRequired,
  setDeliveryList_page: func.isRequired,
  setDeliveryList_sortModel: func.isRequired,
  setDeliveryRowCount: func.isRequired,
  setDeliveryRowCountLoading: func.isRequired,
  setDeliveryRowPagesLoading: func.isRequired,
  replaceDeliveryRowPage: func.isRequired,
  resetDeliveryRowPages: func.isRequired,
  replaceBrowsableDeliveryNotes: func.isRequired,
  sortModel: array.isRequired,
  userinfo: shape({
    userinfo: shape({
      userType: string.isRequired,
      userFeatureFlags: object.isRequired,
    }).isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export const DeliveryList = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(DeliveryListComponent),
  'Lieferungen konnten nicht geladen werden.',
);
