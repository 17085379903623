import React from 'react';
import { withErrorBoundary } from '~/ui/atoms';
import InvoiceCheck from '../InvoiceCheck';
import InvoiceCheckCategory from './InvoiceCheckCategory';
import { DeliveryNoteLinks } from '~/utils/componentUtils';
import { pluralizeString, pluralizeWord } from '~/utils/pluralize';
import ArrayUtils from '~/utils/arrayUtils';

class DlnCheck extends React.Component {
  getErrorInvoiceChecks() {
    const deliveryNoteExistsCheckResults =
      this.props.category.errorChecks.filter(
        (checkResult) => checkResult.name === 'DeliveryNoteExists',
      );
    const deliveryNoteNotAlreadySettledCheckResults =
      this.props.category.errorChecks.filter(
        (checkResult) => checkResult.name === 'DeliveryNoteNotAlreadySettled',
      );
    const deliveryNoteItemAlreadyBilledCheckResults =
      this.props.category.errorChecks.filter(
        (checkResult) => checkResult.name === 'DeliveryNoteItemAlreadyBilled',
      );
    const deliveryNoteReferenceExistsCheckResults =
      this.props.category.errorChecks.filter(
        (checkResult) => checkResult.name === 'DeliveryNoteReferenceExists',
      );

    const deliveryNoteExistsInvoiceCheck = (
      <InvoiceCheck
        expandedDefault
        checkResults={deliveryNoteExistsCheckResults}
        checkResultFormatter={(checkResult) =>
          'Lieferung ' + checkResult.getDeliveryNoteNumbers().join(', ')
        }
      >
        <div className="w-full">
          Die {pluralizeWord(deliveryNoteExistsCheckResults.length, 'folgende')}{' '}
          {pluralizeString(
            deliveryNoteExistsCheckResults.length,
            'Lieferung liegt',
            deliveryNoteExistsCheckResults.length > 1,
          )}{' '}
          nicht von diesem Lieferanten vor.
        </div>
      </InvoiceCheck>
    );

    const deliveryNoteNotAlreadySettledInvoiceCheck = (
      <InvoiceCheck
        expandedDefault
        checkResults={deliveryNoteNotAlreadySettledCheckResults}
        checkResultFormatter={(checkResult) => (
          <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />
        )}
      >
        <div className="w-full">
          Die{' '}
          {pluralizeWord(
            deliveryNoteNotAlreadySettledCheckResults.length,
            'folgende',
          )}{' '}
          {pluralizeString(
            deliveryNoteNotAlreadySettledCheckResults.length,
            'Lieferung wurde',
            deliveryNoteNotAlreadySettledCheckResults.length > 1,
          )}{' '}
          bereits mit einer anderen Rechnung dieses Lieferanten abgerechnet.
        </div>
      </InvoiceCheck>
    );

    const deliveryNoteItemAlreadyBilledInvoiceCheck = (
      <InvoiceCheck>
        <div className="w-full">
          {pluralizeWord(
            deliveryNoteItemAlreadyBilledCheckResults.length,
            'Der',
          )}{' '}
          Artikel{' '}
          {ArrayUtils.joinComponents(
            deliveryNoteItemAlreadyBilledCheckResults.map(
              (checkResult, index) => (
                <span key={index} className="text-error500">
                  "{checkResult.articleName ?? ''}"
                </span>
              ),
            ),
          )}{' '}
          {pluralizeWord(
            deliveryNoteItemAlreadyBilledCheckResults.length,
            'wurde',
          )}{' '}
          bereits mit einer anderen Rechnung dieses Lieferanten abgerechnet.
        </div>
      </InvoiceCheck>
    );

    const deliveryNoteReferenceExistsInvoiceCheck = (
      <InvoiceCheck>
        <div className="w-full">
          {pluralizeWord(deliveryNoteReferenceExistsCheckResults.length, 'Der')}{' '}
          Artikel{' '}
          {ArrayUtils.joinComponents(
            deliveryNoteReferenceExistsCheckResults.map(
              (checkResult, index) => (
                <span key={index} className="text-error500">
                  "{checkResult.articleName ?? ''}"
                </span>
              ),
            ),
          )}{' '}
          {pluralizeWord(
            deliveryNoteReferenceExistsCheckResults.length,
            'enthält',
          )}{' '}
          keine Referenz auf eine Lieferung.
        </div>
      </InvoiceCheck>
    );

    return (
      <>
        {deliveryNoteExistsCheckResults.length > 0
          ? deliveryNoteExistsInvoiceCheck
          : null}
        {deliveryNoteNotAlreadySettledCheckResults.length > 0
          ? deliveryNoteNotAlreadySettledInvoiceCheck
          : null}
        {deliveryNoteItemAlreadyBilledCheckResults.length > 0
          ? deliveryNoteItemAlreadyBilledInvoiceCheck
          : null}
        {deliveryNoteReferenceExistsCheckResults.length > 0
          ? deliveryNoteReferenceExistsInvoiceCheck
          : null}
      </>
    );
  }

  getSuccessInvoiceChecks() {
    const deliveryNoteExistsCheckResults =
      this.props.category.successChecks.filter(
        (checkResult) => checkResult.name === 'DeliveryNoteExists',
      );
    const deliveryNoteNotAlreadySettledCheckResults =
      this.props.category.successChecks.filter(
        (checkResult) => checkResult.name === 'DeliveryNoteNotAlreadySettled',
      );
    const deliveryNoteItemAlreadyBilledCheckResults =
      this.props.category.successChecks.filter(
        (checkResult) => checkResult.name === 'DeliveryNoteItemAlreadyBilled',
      );
    const deliveryNoteReferenceExistsCheckResults =
      this.props.category.successChecks.filter(
        (checkResult) => checkResult.name === 'DeliveryNoteReferenceExists',
      );

    const deliveryNoteExistsInvoiceCheck = (
      <InvoiceCheck
        expandedDefault
        checkResults={deliveryNoteExistsCheckResults}
        checkResultFormatter={(checkResult) => (
          <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />
        )}
      >
        <div className="w-full">
          Die {pluralizeWord(deliveryNoteExistsCheckResults.length, 'folgende')}{' '}
          {pluralizeString(
            deliveryNoteExistsCheckResults.length,
            'Lieferung liegt',
            deliveryNoteExistsCheckResults.length > 1,
          )}{' '}
          von diesem Lieferanten vor.
        </div>
      </InvoiceCheck>
    );

    const deliveryNoteNotAlreadySettledInvoiceCheck = (
      <InvoiceCheck
        expandedDefault
        checkResults={deliveryNoteNotAlreadySettledCheckResults}
        checkResultFormatter={(checkResult) => (
          <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />
        )}
      >
        <div className="w-full">
          Die{' '}
          {pluralizeWord(
            deliveryNoteNotAlreadySettledCheckResults.length,
            'folgende',
          )}{' '}
          {pluralizeString(
            deliveryNoteNotAlreadySettledCheckResults.length,
            'Lieferung wurde',
            deliveryNoteNotAlreadySettledCheckResults.length > 1,
          )}{' '}
          noch nicht mit einer anderen Rechnung dieses Lieferanten abgerechnet.
        </div>
      </InvoiceCheck>
    );

    const deliveryNoteItemAlreadyBilledInvoiceCheck = (
      <InvoiceCheck>
        <div className="w-full">
          {pluralizeWord(
            deliveryNoteItemAlreadyBilledCheckResults.length,
            'Der',
          )}{' '}
          Artikel{' '}
          {ArrayUtils.joinComponents(
            deliveryNoteItemAlreadyBilledCheckResults.map(
              (checkResult) => '"' + (checkResult.articleName ?? '') + '"',
            ),
          )}{' '}
          {pluralizeWord(
            deliveryNoteItemAlreadyBilledCheckResults.length,
            'wurde',
          )}{' '}
          noch nicht mit einer anderen Rechnung dieses Lieferanten abgerechnet.
        </div>
      </InvoiceCheck>
    );

    const deliveryNoteReferenceExistsInvoiceCheck = (
      <InvoiceCheck
        expandedDefault
        checkResults={deliveryNoteReferenceExistsCheckResults}
      >
        <div className="w-full">
          {pluralizeWord(deliveryNoteReferenceExistsCheckResults.length, 'Der')}{' '}
          Artikel{' '}
          {ArrayUtils.joinComponents(
            deliveryNoteReferenceExistsCheckResults.map(
              (checkResult) => '"' + (checkResult.articleName ?? '') + '"',
            ),
          )}{' '}
          {pluralizeWord(
            deliveryNoteReferenceExistsCheckResults.length,
            'enthält',
          )}{' '}
          eine Referenz auf eine Lieferung.
        </div>
      </InvoiceCheck>
    );

    return (
      <>
        {deliveryNoteExistsCheckResults.length > 0
          ? deliveryNoteExistsInvoiceCheck
          : null}
        {deliveryNoteNotAlreadySettledCheckResults.length > 0
          ? deliveryNoteNotAlreadySettledInvoiceCheck
          : null}
        {deliveryNoteItemAlreadyBilledCheckResults.length > 0
          ? deliveryNoteItemAlreadyBilledInvoiceCheck
          : null}
        {deliveryNoteReferenceExistsCheckResults.length > 0
          ? deliveryNoteReferenceExistsInvoiceCheck
          : null}
      </>
    );
  }

  render() {
    return (
      <InvoiceCheckCategory
        category={this.props.category}
        errorInvoiceChecks={this.getErrorInvoiceChecks()}
        successInvoiceChecks={this.getSuccessInvoiceChecks()}
      />
    );
  }
}

export default withErrorBoundary(
  DlnCheck,
  'Prüfschritt "Lieferscheinprüfung" konnte nicht geladen werden.',
);
