import ArrayUtils from '~/utils/arrayUtils';
import ObjectUtils from '~/utils/objectUtils';

export default class Permissions {
  constructor(permissions) {
    this.deliveryNote = {
      accessIncoming: false,
      accessOutgoing: false,
      accessTransported: false,
    };

    this.invoice = {
      accessIncoming: false,
      accessOutgoing: false,
    };

    this.user = {
      read: false,
      write: false,
      create: false,
    };

    this.site = {
      read: false,
      write: false,
      create: false,
    };

    this.costCenter = {
      read: false,
      write: false,
      create: false,
    };

    this.vehicle = {
      read: false,
      write: false,
      create: false,
    };

    this.company = {
      read: false,
      write: false,
      create: false,
    };

    this.organisationalGroup = {
      read: false,
      write: false,
      create: false,
      delete: false,
      member: false,
    };

    this.userGroup = {
      read: false,
      write: false,
      create: false,
      delete: false,
      member: false,
    };

    this.permission = {
      grant: false,
    };

    this.initPermissions(permissions);
  }

  getGrantedPermissions() {
    const grantedPermissions = [];

    for (const x of Object.keys(this.deliveryNote)) {
      if (this.deliveryNote[x]) {
        grantedPermissions.push('deliveryNote.' + x);
      }
    }

    for (const x of Object.keys(this.invoice)) {
      if (this.invoice[x]) {
        grantedPermissions.push('invoice.' + x);
      }
    }

    for (const x of Object.keys(this.user)) {
      if (this.user[x]) {
        grantedPermissions.push('user.' + x);
      }
    }

    for (const x of Object.keys(this.site)) {
      if (this.site[x]) {
        grantedPermissions.push('site.' + x);
      }
    }

    for (const x of Object.keys(this.costCenter)) {
      if (this.costCenter[x]) {
        grantedPermissions.push('costCenter.' + x);
      }
    }

    for (const x of Object.keys(this.vehicle)) {
      if (this.vehicle[x]) {
        grantedPermissions.push('vehicle.' + x);
      }
    }

    for (const x of Object.keys(this.company)) {
      if (this.company[x]) {
        grantedPermissions.push('company.' + x);
      }
    }

    for (const x of Object.keys(this.organisationalGroup)) {
      if (this.organisationalGroup[x]) {
        grantedPermissions.push('organisationalGroup.' + x);
      }
    }

    for (const x of Object.keys(this.userGroup)) {
      if (this.userGroup[x]) {
        grantedPermissions.push('userGroup.' + x);
      }
    }

    for (const x of Object.keys(this.permission)) {
      if (this.permission[x]) {
        grantedPermissions.push('permission.' + x);
      }
    }

    return grantedPermissions;
  }

  permissionGranted() {
    return this.getGrantedPermissions().length > 0;
  }

  initPermissions(backendPermissions) {
    if (!backendPermissions) {
      return;
    }

    for (const x of Object.keys(Permissions.PERMISSION)) {
      if (
        ArrayUtils.getOverlappingValues(
          Permissions.PERMISSION[x].BACKEND,
          backendPermissions,
        ).length === 0
      ) {
        continue;
      }

      const frontendPermission = Permissions.PERMISSION[x].WEBAPP.split('.');
      this[frontendPermission[0]][frontendPermission[1]] = true;
    }
  }

  getBackendPermissions() {
    const backendPermissions = [];

    for (const x of Object.keys(Permissions.PERMISSION)) {
      const frontendPermission = Permissions.PERMISSION[x].WEBAPP.split('.');

      if (this[frontendPermission[0]][frontendPermission[1]]) {
        backendPermissions.push(...Permissions.PERMISSION[x].BACKEND);
      }
    }

    return backendPermissions;
  }

  initWithDefaultRole(defaultRoleName) {
    if (!defaultRoleName) {
      return;
    }

    const defaultPermissions = Object.values(Permissions.DEFAULT_ROLE).find(
      (defaultRole) => defaultRole.NAME === defaultRoleName,
    ).PERMISSIONS;

    for (const defaultPermission of defaultPermissions) {
      const frontendPermission = defaultPermission.split('.');
      this[frontendPermission[0]][frontendPermission[1]] = true;
    }
  }

  getDefaultRoleName() {
    const grantedPermissions = this.getGrantedPermissions();

    if (grantedPermissions.length === 0) {
      return null;
    }

    const defaultRole = ObjectUtils.entries(Permissions.DEFAULT_ROLE).find(
      (entry) =>
        JSON.stringify(grantedPermissions) ===
        JSON.stringify(entry.value.PERMISSIONS),
    )?.value;

    if (defaultRole) {
      return defaultRole.NAME;
    }

    return Permissions.INDIVIDUAL_ROLE;
  }

  static getPickableRoles = () => {
    const pickableRoles = [
      { id: Permissions.INDIVIDUAL_ROLE, name: Permissions.INDIVIDUAL_ROLE },
    ];

    for (const defaultRole of Object.values(Permissions.DEFAULT_ROLE)) {
      pickableRoles.push({ id: defaultRole.NAME, name: defaultRole.NAME });
    }

    return pickableRoles;
  };

  canReadDeliveryNotes() {
    return (
      this.deliveryNote.accessIncoming ||
      this.deliveryNote.accessOutgoing ||
      this.deliveryNote.accessTransported
    );
  }

  // mapping of frontend permissions to backend permissions. backend permissions are connected by AND operator:
  // - when deliveryNote.accessIncoming is granted in frontend, then both dln_in.read and dln_in.write are written to backend
  // - deliveryNote.accessIncoming is displayed to be granted in frontend only if both dln_in.read and dln_in.write are granted
  static PERMISSION = {
    ACCESS_INCOMING_DELIVERY_NOTE: {
      WEBAPP: 'deliveryNote.accessIncoming',
      BACKEND: ['dln_in.read'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um eingehende Lieferungen einsehen und signieren zu können.',
    },
    ACCESS_OUTGOING_DELIVERY_NOTE: {
      WEBAPP: 'deliveryNote.accessOutgoing',
      BACKEND: ['dln_out.read'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um ausgehende Lieferungen einsehen und signieren zu können.',
    },
    ACCESS_TRANSPORTED_DELIVERY_NOTE: {
      WEBAPP: 'deliveryNote.accessTransported',
      BACKEND: ['dln_trans.read'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um transportierte Lieferungen einsehen und signieren zu können.',
    },
    ACCESS_INCOMING_INVOICE: {
      WEBAPP: 'invoice.accessIncoming',
      BACKEND: ['invoice_in.read'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um eingehende Rechnungen und die Ergebnisse der automatisierten Rechnungsprüfung einsehen zu können.',
    },
    ACCESS_OUTGOING_INVOICE: {
      WEBAPP: 'invoice.accessOutgoing',
      BACKEND: ['invoice_out.read'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um ausgehende Rechnungen und die Ergebnisse der automatisierten Rechnungsprüfung einsehen zu können.',
    },
    READ_USER: {
      WEBAPP: 'user.read',
      BACKEND: ['user.read'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Benutzer einsehen zu können.',
    },
    WRITE_USER: {
      WEBAPP: 'user.write',
      BACKEND: ['user.write'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Benutzer ändern zu können.',
    },
    CREATE_USER: {
      WEBAPP: 'user.create',
      BACKEND: ['user.create'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Benutzer anlegen zu können.',
    },
    READ_SITE: {
      WEBAPP: 'site.read',
      BACKEND: ['site.read'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Standorte einsehen zu können (u.a. wenn einer Lieferung ein Standort zugewiesen wird, sobald diese signiert wird).',
    },
    WRITE_SITE: {
      WEBAPP: 'site.write',
      BACKEND: ['site.write'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Standorte ändern zu können.',
    },
    CREATE_SITE: {
      WEBAPP: 'site.create',
      BACKEND: ['site.create'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Standorte anlegen zu können.',
    },
    READ_COST_CENTER: {
      WEBAPP: 'costCenter.read',
      BACKEND: ['accounting_reference.read'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Kostenstellen einsehen zu können (u.a. wenn einer Lieferung eine Kostenstelle zugewiesen wird, sobald diese signiert wird).',
    },
    WRITE_COST_CENTER: {
      WEBAPP: 'costCenter.write',
      BACKEND: ['accounting_reference.write'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Kostenstellen ändern zu können.',
    },
    CREATE_COST_CENTER: {
      WEBAPP: 'costCenter.create',
      BACKEND: ['accounting_reference.create'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Kostenstellen anlegen zu können.',
    },
    READ_VEHICLE: {
      WEBAPP: 'vehicle.read',
      BACKEND: ['vehicle.read'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Fahrzeuge einsehen zu können.',
    },
    WRITE_VEHICLE: {
      WEBAPP: 'vehicle.write',
      BACKEND: ['vehicle.write'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Fahrzeuge ändern zu können.',
    },
    CREATE_VEHICLE: {
      WEBAPP: 'vehicle.create',
      BACKEND: ['vehicle.create'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Fahrzeuge anlegen zu können.',
    },
    READ_COMPANY: {
      WEBAPP: 'company.read',
      BACKEND: ['company.read'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Firmen einsehen zu können.',
    },
    WRITE_COMPANY: {
      WEBAPP: 'company.write',
      BACKEND: ['company.write'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Firmen ändern zu können.',
    },
    CREATE_COMPANY: {
      WEBAPP: 'company.create',
      BACKEND: ['company.create'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Firmen anlegen zu können.',
    },
    READ_ORGANISATIONAL_GROUP: {
      WEBAPP: 'organisationalGroup.read',
      BACKEND: ['org_unit.read'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Organisations-Gruppen einsehen zu können.',
    },
    WRITE_ORGANISATIONAL_GROUP: {
      WEBAPP: 'organisationalGroup.write',
      BACKEND: ['org_unit.write'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Organisations-Gruppen ändern zu können.',
    },
    CREATE_ORGANISATIONAL_GROUP: {
      WEBAPP: 'organisationalGroup.create',
      BACKEND: ['org_unit.create'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Organisations-Gruppen anlegen zu können.',
    },
    DELETE_ORGANISATIONAL_GROUP: {
      WEBAPP: 'organisationalGroup.delete',
      BACKEND: ['org_unit.delete'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Organisations-Gruppen löschen zu können.',
    },
    MEMBER_ORGANISATIONAL_GROUP: {
      WEBAPP: 'organisationalGroup.member',
      BACKEND: ['org_unit.member'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Stammdaten zu Organisations-Gruppen hinzufügen zu können.',
    },
    READ_USER_GROUP: {
      WEBAPP: 'userGroup.read',
      BACKEND: ['user_group.read'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Benutzer-Gruppen einsehen zu können.',
    },
    WRITE_USER_GROUP: {
      WEBAPP: 'userGroup.write',
      BACKEND: ['user_group.write'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Benutzer-Gruppen ändern zu können.',
    },
    CREATE_USER_GROUP: {
      WEBAPP: 'userGroup.create',
      BACKEND: ['user_group.create'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Benutzer-Gruppen anlegen zu können.',
    },
    DELETE_USER_GROUP: {
      WEBAPP: 'userGroup.delete',
      BACKEND: ['user_group.delete'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Benutzer-Gruppen löschen zu können.',
    },
    MEMBER_USER_GROUP: {
      WEBAPP: 'userGroup.member',
      BACKEND: ['user_group.member'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Benutzer zu Benutzer-Gruppen hinzufügen zu können.',
    },
    GRANT_PERMISSION: {
      WEBAPP: 'permission.grant',
      BACKEND: ['access_control.grant'],
      DESCRIPTION:
        'Diese Berechtigung wird benötigt, um Berechtigungen vergeben zu können.',
    },
  };
  static DEFAULT_ROLE = {
    FOREMAN: {
      NAME: 'Polier/Lagerist',
      DESCRIPTION:
        'Die Polier/Lagerist Rolle wird üblicherweise einem Standort zugewiesen. Diese Rolle ermöglicht es einem' +
        'Benutzer, Lieferungen einzusehen, entgegenzunehmen und die angenommenen Lieferungen dem entsprechenden' +
        'Standort und einer Kostenstelle zuzuweisen.',
      PERMISSIONS: [
        'deliveryNote.accessIncoming',
        'deliveryNote.accessOutgoing',
        'deliveryNote.accessTransported',
        'site.read',
        'site.create',
        'costCenter.read',
        'costCenter.create',
      ],
    },
    CONSTRUCTION_MANAGER: {
      NAME: 'Bauleitung',
      DESCRIPTION:
        'Die Rolle der Bauleitung wird üblicherweise einer Firma oder einem Standort zugewiesen. Ein' +
        'Benutzer mit dieser Rolle kann Lieferungen und eingehende Rechnungen einsehen. Zudem können Standorte' +
        'und Kostenstellen eingesehen und angelegt werden. Diese Rolle sollte an Benutzer vergeben werden, welche' +
        'auf Abnehmer Seite vollen Zugriff auf Standorte sowie deren Materialflüsse und Kostenstellen benötigen.',
      PERMISSIONS: [
        'deliveryNote.accessIncoming',
        'deliveryNote.accessOutgoing',
        'deliveryNote.accessTransported',
        'invoice.accessIncoming',
        'site.read',
        'site.create',
        'costCenter.read',
        'costCenter.create',
      ],
    },
    INVOICE_CHECKING: {
      NAME: 'Rechnungsprüfung',
      DESCRIPTION:
        'Die Rolle der Rechnungsprüfung wird üblicherweise einer Firma zugewiesen. Ein Benutzer mit dieser' +
        'Rolle kann eingehende Lieferungen und Rechnungen einsehen. Zudem können Standorte und Kostenstellen dieser' +
        'Firma eingesehen und angelegt werden. Diese Rolle sollte an Benutzer vergeben werden, welche auf Abnehmer' +
        'Seite einen Überblick über eingehende Lieferungen benötigen und im Prozess der Rechnungsprüfung beteiligt sind.',
      PERMISSIONS: [
        'deliveryNote.accessIncoming',
        'invoice.accessIncoming',
        'site.read',
        'site.create',
        'costCenter.read',
        'costCenter.create',
      ],
    },
    ACCOUNT_ADMIN: {
      NAME: 'Account-Administrator',
      DESCRIPTION:
        'Die Account-Administrator Rolle wird üblicherweise einem Unternehmens-Account zugewiesen und' +
        'enthält alle verfügbaren Berechtigungen. Ein Account-Administrator kann somit alle Lieferungen und' +
        'Rechnungen einsehen sowie alle Stammdaten administrieren. Diese Rolle sollte nur an Benutzer vergeben' +
        'werden, welche die umfassenden Berechtigungen zur Administration der VESTIGAS Anwendung benötigen (z.B. IT-Admin).',
      PERMISSIONS: [
        'deliveryNote.accessIncoming',
        'deliveryNote.accessOutgoing',
        'deliveryNote.accessTransported',
        'invoice.accessIncoming',
        'invoice.accessOutgoing',
        'user.read',
        'user.write',
        'user.create',
        'site.read',
        'site.write',
        'site.create',
        'costCenter.read',
        'costCenter.write',
        'costCenter.create',
        'vehicle.read',
        'vehicle.write',
        'vehicle.create',
        'company.read',
        'company.write',
        'company.create',
        'organisationalGroup.read',
        'organisationalGroup.write',
        'organisationalGroup.create',
        'organisationalGroup.delete',
        'organisationalGroup.member',
        'userGroup.read',
        'userGroup.write',
        'userGroup.create',
        'userGroup.delete',
        'userGroup.member',
        'permission.grant',
      ],
    },
    DRIVER: {
      NAME: 'Fahrer',
      DESCRIPTION:
        'Ein Fahrer kann transportierte Lieferungen einsehen. Diese Rolle sollte an Benutzer vergeben' +
        'werden, welche Lieferungen transportieren und übergeben.',
      PERMISSIONS: ['deliveryNote.accessTransported', 'vehicle.read'],
    },
    GOODS_ISSUE: {
      NAME: 'Warenausgabe',
      DESCRIPTION:
        'Die Rolle der Warenausgabe wird üblicherweise einem Standort zugewiesen. Diese Rolle ermöglicht' +
        'es einem Benutzer, ausgehende Lieferungen einzusehen, auszugeben und die angenommenen Lieferungen dem' +
        'entsprechenden Standort und einer Kostenstelle zuzuweisen.',
      PERMISSIONS: [
        'deliveryNote.accessOutgoing',
        'deliveryNote.accessTransported',
      ],
    },
    EMPLOYEE: {
      NAME: 'Mitarbeiter',
      DESCRIPTION:
        'Die Mitarbeiter Rolle wird üblicherweise einem Unternehmens-Account zugewiesen. Ein Benutzer mit' +
        'dieser Rolle kann Standorte und Kostenstelle einsehen und anlegen.',
      PERMISSIONS: [
        'user.read',
        'site.read',
        'site.create',
        'costCenter.read',
        'costCenter.create',
      ],
    },
  };
  static INDIVIDUAL_ROLE = 'Individuell';
}
