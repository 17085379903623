import { useEffect, useState, useRef, useCallback } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@mui/icons-material';

import DataExchangeStatusIcon from '~/components/dataExchanges/DataExchangeStatusIcon';

import { Spinner } from '~/components/Spinner';
import DataExchangeService from '~/services/dataExchange.service';
import { setUpdatedSuppliers } from '~/redux/dataExchangesSlice';

import { LightTooltip } from '~/utils/componentUtils';

import { SupplierCard } from './SupplierCard';

function debounce(function_, wait) {
  let timeout;
  return function executedFunction(...arguments_) {
    const later = () => {
      clearTimeout(timeout);
      function_(...arguments_);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const SCROLL_THRESHOLD = 1;

const useStyles = makeStyles((theme) => ({
  container: {
    color: '#343A4D',
    flexShrink: 0,
    width: '28.5rem',
    transition: 'all 200ms cubic-bezier(0.4, 0, 1, 1)',

    '&[data-column-active="true"]': {
      color: '#158C47',
    },

    '&[data-column-open="false"]': {
      width: '7.875rem',

      '& .kanban-header': {
        width: '5.875rem',
      },

      '& .kanban-content': {
        display: 'none',
      },
    },

    '& .kanban-content': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '1rem',
      height: '100%',
      paddingBottom: '10.625rem',
      overflowY: 'scroll',
    },

    '& .collapse-icon': {
      color: '#2453B2',
    },

    '& .kanban-header': {
      height: '3.25rem',
      width: '26.5rem',
      margin: '0 auto',
      cursor: 'pointer',

      '& .content': {
        padding: '0 0.75rem',
      },

      '& h6': {
        fontWeight: 700,
        fontSize: '1rem',
      },

      '& .count': {
        marginLeft: 'auto',
      },
    },
  },
}));

export const KanbanColumn = ({ section, onSupplierCardClick }) => {
  const columnRef = useRef(null);
  const columnCounts = useSelector((state) => state.dataExchanges.columnCount);
  const selectedCompanies = useSelector(
    (state) => state.filters.suppliers_selectedCompanies,
  );
  const selectedResponsiblePeople = useSelector(
    (state) => state.filters.suppliers_responsible_people,
  );
  const sortData = useSelector((state) => state.filters.suppliers_sort_model);
  const dataExchangeSuppliers = useSelector(
    (state) => state.dataExchanges.suppliers,
  );

  const dispatch = useDispatch();

  const { active, description, key, label, suppliers } = section;

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const totalCount = columnCounts?.[key];

  const [opened, setOpened] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (suppliers.length > 0) {
      setOpened(true);
    } else {
      setOpened(false);
    }
  }, [suppliers.length]);

  const fetchMoreExchanges = useCallback(async () => {
    if (isLoadingMore || suppliers.length >= totalCount) return;

    setIsLoadingMore(true);

    const queryParams = {
      order_by: sortData.sortKey,
      sort_order: sortData.sortDirection,
      responsible_person_id: selectedResponsiblePeople,
      company_id: selectedCompanies,
      status: key,
      offset: suppliers.length,

      // TODO: @cas move search text to redux
      // search_text: searchQuery
    };

    const queryString =
      DataExchangeService.convertQueryParamsToQueryString(queryParams);

    try {
      const response = await DataExchangeService.getFilteredSuppliers(
        queryString,
        true,
      );
      const updatedSuppliers = [...dataExchangeSuppliers, ...response];
      dispatch(setUpdatedSuppliers(updatedSuppliers));
    } catch (error) {
      console.error('Error fetching more exchanges:', error);
    } finally {
      setIsLoadingMore(false);
    }
  }, [
    isLoadingMore,
    suppliers.length,
    totalCount,
    sortData.sortKey,
    sortData.sortDirection,
    selectedResponsiblePeople,
    selectedCompanies,
    key,
    dataExchangeSuppliers,
    dispatch,
  ]);

  const handleScroll = useCallback(() => {
    const kanbanElement = columnRef.current;
    if (!kanbanElement) {
      return;
    }

    const isNearBottom =
      kanbanElement.scrollTop + kanbanElement.clientHeight >=
      kanbanElement.scrollHeight - SCROLL_THRESHOLD;

    if (isNearBottom) {
      fetchMoreExchanges();
    }
  }, [fetchMoreExchanges]);

  useEffect(() => {
    const kanbanElement = columnRef.current;
    if (kanbanElement) {
      const debouncedHandleScroll = debounce(handleScroll, 200);
      kanbanElement.addEventListener('scroll', debouncedHandleScroll);
      return () => {
        kanbanElement.removeEventListener('scroll', debouncedHandleScroll);
      };
    }
  }, [handleScroll, columnRef]);

  return (
    <Box
      data-column-active={active ? 'true' : 'false'}
      data-column-open={opened ? 'true' : 'false'}
      className={classes.container}
    >
      <div className="kanban-header flex-c-c sticky">
        <div
          className="content flex-c-c gap-8px w-full"
          onClick={() => setOpened(!opened)}
        >
          <DataExchangeStatusIcon status={key} />
          {opened ? (
            <LightTooltip
              title={
                <div>
                  <div className="font-bold">{label}</div>
                  <div>{description}</div>
                </div>
              }
              enterDelay={1000}
              enterNextDelay={1000}
            >
              <Typography variant="h6" className="text-overflow">
                {label}
              </Typography>
            </LightTooltip>
          ) : null}
          <Typography variant="h6" className="count">
            {totalCount}
          </Typography>
        </div>
        <IconButton
          size="small"
          className="flex-c-c"
          onClick={() => setOpened(!opened)}
        >
          {opened ? (
            <ArrowBackIosIcon fontSize="small" className="collapse-icon" />
          ) : (
            <ArrowForwardIosIcon fontSize="small" className="collapse-icon" />
          )}
        </IconButton>
      </div>

      <div className="kanban-content" ref={columnRef}>
        {suppliers.map((supplier, index) => {
          return (
            <SupplierCard
              onClick={() => onSupplierCardClick(supplier)}
              supplier={supplier}
              key={supplier.id}
            />
          );
        })}

        {isLoadingMore && (
          <div className="loading-more" style={{ padding: '40px 0' }}>
            <Spinner />
          </div>
        )}
      </div>
    </Box>
  );
};
