import React from 'react';
import { LightTooltip } from '~/utils/componentUtils';
import DataExchangeCompany from '~/models/dataExchanges/DataExchangeCompany';

class DataExchangeStatusTooltip extends React.Component {
  render() {
    const tooltipTitle = (
      <div>
        <div className="bold">
          {DataExchangeCompany.getStatusLabel(this.props.status)}
        </div>
        <div>{DataExchangeCompany.getStatusDescription(this.props.status)}</div>
      </div>
    );

    return (
      <LightTooltip
        title={tooltipTitle}
        enterDelay={1000}
        enterNextDelay={1000}
      >
        {this.props.children}
      </LightTooltip>
    );
  }
}

export default DataExchangeStatusTooltip;
