import { CssBaseline } from '@mui/material';
import { useSelector } from 'react-redux';

import { LOADING_STATE } from '~/constants/LoadingState';
import DeliveryNote from '~/models/deliveries/DeliveryNote';

import { AdOverlay } from './AdOverlay';
import { DLNList } from './DLNList';
import { PageHeader } from './PageHeader';
import { dlnsForProcessState } from './utils';
import { OrderReadyIcon } from './OrderReadyIcon';
import { OrderInProcessIcon } from './OrderInProcessIcon';

export const BayWaScreen = () => {
  const { deliveryNotesLoading, deliveryRows } = useSelector(
    ({ deliveryNotes }) => deliveryNotes,
  );

  const inProcess = dlnsForProcessState(
    deliveryRows,
    DeliveryNote.PROCESS_STATE.IMPORTED.STRING,
  );
  const readyForPickup = dlnsForProcessState(
    deliveryRows,
    DeliveryNote.PROCESS_STATE.READY_FOR_OUTPUT.STRING,
  );

  const isLoading = deliveryNotesLoading !== LOADING_STATE.SUCCEEDED;

  return (
    <div className="flex h-screen w-full flex-col overflow-hidden">
      <CssBaseline />
      <AdOverlay />
      <PageHeader />
      <div className="flex flex-1">
        <DLNList
          dlns={inProcess}
          status={'In Bearbeitung'}
          prompt={'— Bitte im Wartebereich warten —'}
          icon={
            <OrderInProcessIcon
              className="inline-block align-top"
              style={{
                height: '6vw',
                marginRight: '1vw',
                marginTop: '-0.9vw',
              }}
            />
          }
          style={{
            color: 'var(--grey500)',
          }}
          isLoading={isLoading}
        />
        <DLNList
          dlns={readyForPickup}
          status={'Zur Abholung'}
          prompt={'Bitte einfahren!'}
          icon={
            <OrderReadyIcon
              className="inline-block align-top"
              style={{
                height: '6vw',
                marginRight: '1vw',
                marginTop: '-0.9vw',
              }}
            />
          }
          style={{
            backgroundColor: 'var(--successLight)',
            color: 'var(--successBase)',
          }}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
