import ObjectUtils from '~/utils/objectUtils';
import cloneDeep from 'lodash/cloneDeep';
import DeliveryNote from './DeliveryNote';
import unitUtils from '~/utils/unitUtils';
import { dateUtils } from '~/utils/dateUtils';

export default class DeliveryNoteDataQuality {
  constructor(deliveryNote, validationResults) {
    this.deliveryNote = deliveryNote ?? new DeliveryNote();
    this.validationResults = validationResults ?? [];
  }

  getDataQualityCategories() {
    const categories = Object.values(DeliveryNoteDataQuality.CATEGORY).filter(
      (category) => !category.MULTIPLE,
    );
    const multiCategories = Object.values(
      DeliveryNoteDataQuality.CATEGORY,
    ).filter((category) => category.MULTIPLE);

    // Flatten the categories that can occur multiple times.
    for (const multiCategory of multiCategories) {
      const amount = this.deliveryNote[multiCategory.PATH[0]].length;

      for (let index = 0; index < amount; index++) {
        categories.push({
          ...multiCategory,
          PATH: [...multiCategory.PATH, index],
        });
      }
    }

    return ObjectUtils.entries(categories).map((entry) => {
      const category = entry.value;
      const dataQualityPairs = [];

      if (category.ITEMS)
        for (const item of category.ITEMS) {
          dataQualityPairs.push(
            ...this.getDataQualityPairsFromItem(
              item,
              this.deliveryNote,
              category.NAME,
              item.BACKEND_PATH,
            ),
          );
        }

      if (category.MODEL) {
        dataQualityPairs.push(
          ...this.getDataQualityPairsFromModel(
            category.MODEL,
            category.PATH.slice(1),
            this.deliveryNote[category.PATH[0]],
            category.NAME,
            category.BACKEND_PATH,
          ),
        );
      }

      return {
        ...category,
        dataQualityPairs,
      };
    });
  }

  getValueFromPath(path, value, formatter /* isCustomData */) {
    if (path.length === 0 && formatter) {
      return formatter(value);
    }

    if (path.length === 0) {
      return value;
    }

    // if(path[0] === 'customData' && isCustomData) return value[path[0]];

    if (path[0] === '*') {
      return value.map((item) =>
        this.getValueFromPath(
          path.slice(1),
          item,
          formatter /* isCustomData */,
        ),
      );
    }

    return this.getValueFromPath(
      path.slice(1),
      value[path[0]],
      formatter /* isCustomData */,
    );
  }

  getDataQualityPairsFromItem(
    item,
    value,
    concatenatedName,
    concatenatedBackendPath = [],
  ) {
    const dataQualityPairs = [];

    let newConcatenatedName = concatenatedName;
    if (item.NAME) {
      newConcatenatedName = concatenatedName + ' | ' + item.NAME;
    }

    if (item.BACKEND_PATH) {
      concatenatedBackendPath = [
        ...concatenatedBackendPath,
        ...item.BACKEND_PATH,
      ];
    }

    if (item.MODEL) {
      dataQualityPairs.push(
        ...this.getDataQualityPairsFromModel(
          item.MODEL,
          item.PATH.slice(1),
          value[item.PATH[0]],
          newConcatenatedName,
          concatenatedBackendPath,
        ),
      );
    } else {
      // DISCLAIMER: this.getUpdateConcatenatedBackendPathForLogisticsPackage is a workaround and should be refactored.
      concatenatedBackendPath =
        this.getUpdatedConcatenatedBackendPathForLogisticsPackage(
          item,
          concatenatedBackendPath,
        );

      const validationResult = this.validationResults.find(
        (validationResult) =>
          JSON.stringify(validationResult.backendPath) ===
          JSON.stringify(concatenatedBackendPath),
      );
      dataQualityPairs.push({
        name: newConcatenatedName,
        value: this.getValueFromPath(item.PATH, value, item.FORMATTER),
        validationResult,
      });
    }

    return dataQualityPairs;
  }

  getDataQualityPairsFromModel(
    model,
    path,
    value,
    concatenatedName,
    concatenatedBackendPath,
  ) {
    const dataQualityPairs = [];
    // Clone deep concatenatedBackendPath to not modify original concatenatedBackendPath because this would lead to incorrectly setting the log package and line item always to 0.
    const newConcatenatedBackendPath = cloneDeep(concatenatedBackendPath);

    // DISCLAIMER: This code is a workaround and should be refactored.
    // If the model has the IS_ARTICLE flag and the n-th article has been accessed, we know that value is now an article.
    // Thus, we can execute the following code for the workarounds.
    if (model.IS_ARTICLE && path.length === 0) {
      // DISCLAIMER: This code is a workaround and should be refactored.
      // Update path with corresponding indexes.
      const documentLogPackagePositionIndex =
        newConcatenatedBackendPath.indexOf('*');
      newConcatenatedBackendPath[documentLogPackagePositionIndex] =
        value.documentLogPackagePosition.toString();
      const documentLineItemPositionIndex =
        newConcatenatedBackendPath.indexOf('*');
      newConcatenatedBackendPath[documentLineItemPositionIndex] =
        value.documentLineItemPosition.toString();
    }

    if (path.length > 0) {
      return this.getDataQualityPairsFromModel(
        model,
        path.slice(1),
        value[path[0]],
        concatenatedName,
        newConcatenatedBackendPath,
      );
    }

    for (const item of model.ITEMS) {
      dataQualityPairs.push(
        ...this.getDataQualityPairsFromItem(
          item,
          value,
          concatenatedName,
          newConcatenatedBackendPath,
        ),
      );
    }

    return dataQualityPairs;
  }

  // DISCLAIMER: This code is a workaround and should be refactored.
  // If we know that we have accessed the logisticsPackage prop of an article, we set the concatenatedBackendPath manually
  // so that we can access the validation result of the corresponding path ['transaction', 'logistics_package', '*', 'id'].
  // This workaround code is necessary because the backend path is not part of the article path ['transaction', 'logistics_package', '*', 'line_item', '*].
  getUpdatedConcatenatedBackendPathForLogisticsPackage(
    item,
    concatenatedBackendPath,
  ) {
    let newConcatenatedBackendPath = cloneDeep(concatenatedBackendPath);

    if (
      JSON.stringify(item.PATH) !== JSON.stringify(['logisticsPackage']) ||
      concatenatedBackendPath[0] !== 'transaction' ||
      concatenatedBackendPath[1] !== 'logistics_package' ||
      concatenatedBackendPath[3] !== 'line_item'
    ) {
      return newConcatenatedBackendPath;
    }

    const lineItemIndex = newConcatenatedBackendPath.indexOf('line_item');
    newConcatenatedBackendPath = newConcatenatedBackendPath.slice(
      0,
      lineItemIndex,
    );
    newConcatenatedBackendPath.push('id');

    return newConcatenatedBackendPath;
  }

  // The models are split into separate variables as they call each other.
  static UNIVERSAL_COMMUNICATION = {
    ITEMS: [
      { NAME: 'Telefon', PATH: ['phone'], BACKEND_PATH: ['complete_number'] },
      { NAME: 'E-Mail', PATH: ['email'], BACKEND_PATH: ['email'] },
    ],
  };
  static TRADE_CONTACT = {
    ITEMS: [
      { NAME: 'Name', PATH: ['personName'], BACKEND_PATH: ['person_name'] },
      { NAME: 'Job', PATH: ['jobTitle'], BACKEND_PATH: ['job_title'] },
      {
        NAME: 'Bereich',
        PATH: ['departmentName'],
        BACKEND_PATH: ['department_name'],
      },
      {
        NAME: null,
        PATH: ['universalCommunication'],
        BACKEND_PATH: ['universal_communication'],
        MODEL: DeliveryNoteDataQuality.UNIVERSAL_COMMUNICATION,
      },
    ],
  };
  static ADDRESS = {
    ITEMS: [
      { NAME: 'Straße', PATH: ['streetName'], BACKEND_PATH: ['street_name'] },
      {
        NAME: 'Hausnummer',
        PATH: ['buildingNumber'],
        BACKEND_PATH: ['building_number'],
      },
      { NAME: 'PLZ', PATH: ['postCode'], BACKEND_PATH: ['post_code'] },
      { NAME: 'Ort', PATH: ['city'], BACKEND_PATH: ['city'] },
      { NAME: 'Land', PATH: ['country'], BACKEND_PATH: ['country'] },
    ],
  };
  static SITE = {
    ITEMS: [
      {
        NAME: 'Lieferantenbezeichnung',
        PATH: ['name'],
        BACKEND_PATH: ['trade_address', 'line_one'],
      },
      {
        NAME: 'Adresse',
        PATH: ['address'],
        BACKEND_PATH: ['trade_address'],
        MODEL: DeliveryNoteDataQuality.ADDRESS,
      },
      {
        NAME: 'Ansprechperson',
        PATH: ['tradeContact'],
        BACKEND_PATH: ['trade_contact'],
        MODEL: DeliveryNoteDataQuality.TRADE_CONTACT,
      },
    ],
  };
  static COMPANY = {
    ITEMS: [
      {
        NAME: 'Name',
        PATH: ['name'],
        BACKEND_PATH: ['legal_organization', 'name'],
      },
      {
        NAME: 'Adresse',
        PATH: ['address'],
        BACKEND_PATH: ['legal_organization', 'address'],
        MODEL: DeliveryNoteDataQuality.ADDRESS,
      },
      // NAME is null as there is additional depth in the displayed path for universal communication
      {
        NAME: null,
        PATH: ['universalCommunication'],
        BACKEND_PATH: ['legal_organization', 'universal_communication'],
        MODEL: DeliveryNoteDataQuality.UNIVERSAL_COMMUNICATION,
      },
      {
        NAME: 'Ansprechperson',
        PATH: ['tradeContact'],
        BACKEND_PATH: ['trade_contact'],
        MODEL: DeliveryNoteDataQuality.TRADE_CONTACT,
      },
    ],
  };
  static ARTICLE = {
    // DISCLAIMER: This code is a workaround and should be refactored.
    // The IS_ARTICLE flag is used to determine articles because there we have to handle multiple ones
    // by accessing via documentLogPackagePosition and documentLineItemPosition.
    IS_ARTICLE: true,
    ITEMS: [
      { NAME: 'Paket', PATH: ['logisticsPackage'] }, // The backend path for logisticsPackage is set in getUpdatedConcatenatedBackendPathForLogisticsPackage()
      { NAME: 'Position', PATH: ['position'], BACKEND_PATH: ['id'] },
      {
        NAME: 'Nummer',
        PATH: ['number'],
        BACKEND_PATH: ['seller_assigned_id'],
      },
      { NAME: 'Name', PATH: ['type'], BACKEND_PATH: ['name'] },
      { NAME: 'EAN', PATH: ['ean'], BACKEND_PATH: ['ean'] },
      {
        NAME: 'Beschreibung',
        PATH: ['description'],
        BACKEND_PATH: ['product', 'description'],
      },
      {
        NAME: 'Kommentar',
        PATH: ['comment'],
        BACKEND_PATH: ['product', 'comment'],
      },
      {
        NAME: 'Plan',
        PATH: ['constructionPlan'],
        BACKEND_PATH: ['product', 'construction_plan'],
      },
      {
        NAME: 'Bauteil',
        PATH: ['constructionComponent'],
        BACKEND_PATH: ['product', 'construction_component'],
      },
      {
        NAME: 'Kostenstelle',
        PATH: ['costCenter'],
        BACKEND_PATH: ['settlement', 'payable_account', 'name'],
      },
      {
        NAME: 'Menge',
        PATH: ['quantity', 'value'],
        BACKEND_PATH: ['delivery', 'product_unit', 'quantity'],
      },
      {
        NAME: 'Menge-Einheit',
        PATH: ['quantity', 'unit'],
        BACKEND_PATH: ['delivery', 'product_unit', 'quantity_type'],
        FORMATTER: unitUtils.getAbbreviatedUnits,
      },
      {
        NAME: 'Gewicht',
        PATH: ['weight', 'value'],
        BACKEND_PATH: ['delivery', 'net_weight', 'weight'],
      },
      {
        NAME: 'Gewicht-Einheit',
        PATH: ['weight', 'unit'],
        BACKEND_PATH: ['delivery', 'net_weight', 'measure'],
        FORMATTER: unitUtils.getAbbreviatedUnits,
      },
      {
        NAME: 'Bruttogewicht',
        PATH: ['weighingInformation', 'gross', 'weight', 'value'],
        BACKEND_PATH: [
          'document_line',
          'note',
          'content',
          'gross_weight',
          'weight',
        ],
      },
      {
        NAME: 'Bruttogewicht-Einheit',
        PATH: ['weighingInformation', 'gross', 'weight', 'unit'],
        BACKEND_PATH: [
          'document_line',
          'note',
          'content',
          'gross_weight',
          'measure',
        ],
        FORMATTER: unitUtils.getAbbreviatedUnits,
      },
      {
        NAME: 'Bruttogewicht Waage',
        PATH: ['weighingInformation', 'gross', 'scaleId'],
        BACKEND_PATH: [
          'document_line',
          'note',
          'content',
          'gross_weight',
          'id',
        ],
      },
      {
        NAME: 'Taragewicht',
        PATH: ['weighingInformation', 'tare', 'weight', 'value'],
        BACKEND_PATH: [
          'document_line',
          'note',
          'content',
          'tare_weight',
          'weight',
        ],
      },
      {
        NAME: 'Taragewicht-Einheit',
        PATH: ['weighingInformation', 'tare', 'weight', 'unit'],
        BACKEND_PATH: [
          'document_line',
          'note',
          'content',
          'tare_weight',
          'measure',
        ],
        FORMATTER: unitUtils.getAbbreviatedUnits,
      },
      {
        NAME: 'Taragewicht Waage',
        PATH: ['weighingInformation', 'tare', 'scaleId'],
        BACKEND_PATH: ['document_line', 'note', 'content', 'tare_weight', 'id'],
      },
      {
        NAME: 'Hersteller',
        PATH: ['manufacturer'],
        BACKEND_PATH: ['product', 'manufacturer'],
        MODEL: DeliveryNoteDataQuality.COMPANY,
      },
    ],
  };
  static CATEGORY = {
    META_DATA: {
      NAME: 'Metadaten',
      ITEMS: [
        { NAME: 'LFS-Nr.', PATH: ['number'], BACKEND_PATH: ['header', 'id'] },
        {
          NAME: 'LFS-Datum.',
          PATH: ['dlnDate'],
          BACKEND_PATH: ['header', 'date'],
          FORMATTER: (date) =>
            dateUtils.getFormattedDate_safe(
              date,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
            ),
        },
        // DISCLAIMER: This code is a workaround and should be refactored.
        // Being pragmatic, we only access the first order reference so that we don't have to map multiple validation results to one data quality pair.
        {
          NAME: 'Bestellreferenz Auftragnehmer',
          PATH: ['sellerOrderReferences'],
          BACKEND_PATH: [
            'transaction',
            'logistics_package',
            '0',
            'line_item',
            '0',
            'agreement',
            'seller_order',
          ],
        },
        {
          NAME: 'Bestellreferenz Auftraggeber',
          PATH: ['buyerOrderReferences'],
          BACKEND_PATH: [
            'transaction',
            'logistics_package',
            '0',
            'line_item',
            '0',
            'agreement',
            'buyer_order',
          ],
        },
        // DISCLAIMER: This code is a workaround and should be refactored.
        // Being pragmatic, we only access the construction plans and components on article level
        // so that we don't have to map multiple validation results to one data quality pair.
        // { NAME: 'Plan', PATH: ['constructionPlans'] },
        // { NAME: 'Bauteil', PATH: ['constructionComponents'] },
        {
          NAME: 'Projekt',
          PATH: ['project'],
          BACKEND_PATH: [
            'transaction',
            'delivery',
            'ship_to',
            'trade_address',
            'issuer_assigned_id',
          ],
        },
        {
          NAME: 'Lieferungsart',
          PATH: ['deliveryType'],
          BACKEND_PATH: ['transaction', 'agreement', 'delivery_terms', 'code'],
        },
        {
          NAME: 'Zufuhrart',
          PATH: ['movementMeans'],
          BACKEND_PATH: [
            'transaction',
            'delivery',
            'ship_from',
            'consignment',
            'movement',
            'means',
          ],
        },
        {
          NAME: 'Kostenstelle',
          PATH: ['costCenter'],
          BACKEND_PATH: [
            'transaction',
            'logistics_package',
            '0',
            'line_item',
            '0',
            'settlement',
            'payable_account',
            'name',
          ],
        },
        {
          NAME: 'Kunden-Nr.',
          PATH: ['buyerId'],
          BACKEND_PATH: ['transaction', 'settlement', 'sales_account', 'name'],
        },
      ],
    },
    ISSUER: {
      NAME: 'Ausgeber',
      PATH: ['issuer'],
      BACKEND_PATH: ['header', 'issuer'],
      MODEL: DeliveryNoteDataQuality.COMPANY,
    },
    SUPPLIER: {
      NAME: 'Lieferant',
      PATH: ['supplier'],
      BACKEND_PATH: ['transaction', 'delivery', 'ship_from'],
      MODEL: DeliveryNoteDataQuality.COMPANY,
    },
    CARRIER: {
      NAME: 'Spediteur',
      PATH: ['carrier'],
      BACKEND_PATH: [
        'transaction',
        'delivery',
        'ship_from',
        'consignment',
        'carrier',
      ],
      MODEL: DeliveryNoteDataQuality.COMPANY,
    },
    RECIPIENT: {
      NAME: 'Abnehmer',
      PATH: ['recipient'],
      BACKEND_PATH: ['transaction', 'delivery', 'ship_to'],
      MODEL: DeliveryNoteDataQuality.COMPANY,
    },
    SELLER: {
      NAME: 'Auftragnehmer',
      PATH: ['seller'],
      BACKEND_PATH: ['transaction', 'agreement', 'seller'],
      MODEL: DeliveryNoteDataQuality.COMPANY,
    },
    BUYER: {
      NAME: 'Auftraggeber',
      PATH: ['buyer'],
      BACKEND_PATH: ['transaction', 'agreement', 'buyer'],
      MODEL: DeliveryNoteDataQuality.COMPANY,
    },
    EXECUTION: {
      NAME: 'Auslieferung',
      ITEMS: [
        // DISCLAIMER: This code is a workaround and should be refactored.
        // Being pragmatic, we only access the first license plate so that we don't have to map multiple validation results to one data quality pair.
        {
          NAME: 'Kfz-Kennzeichen',
          PATH: ['carrierLicensePlate'],
          BACKEND_PATH: [
            'transaction',
            'delivery',
            'ship_from',
            'consignment',
            'movement',
            'registration_id',
            '0',
            'id',
          ],
        },
        {
          NAME: 'Fahrzeug-Nr.',
          PATH: ['carrierVehicleNumber'],
          BACKEND_PATH: [
            'transaction',
            'delivery',
            'ship_from',
            'consignment',
            'movement',
            'registration_id',
            '0',
            'issuer_assigned_id',
          ],
        },
        {
          NAME: 'Fahrer',
          PATH: ['driver'],
          MODEL: DeliveryNoteDataQuality.TRADE_CONTACT,
          BACKEND_PATH: [
            'transaction',
            'delivery',
            'ship_from',
            'consignment',
            'movement',
            'driver',
          ],
        },
        {
          NAME: 'Zeitpunkt | Beginn Beladung (Geplant)',
          PATH: ['execution', 'beginLoadingPlanned'],
          BACKEND_PATH: [
            'transaction',
            'delivery',
            'execution',
            'begin_loading_planned',
          ],
          FORMATTER: (date) =>
            dateUtils.getFormattedDate_safe(
              date,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
            ),
        },
        {
          NAME: 'Zeitpunkt | Ende Beladung (Geplant)',
          PATH: ['execution', 'endLoadingPlanned'],
          BACKEND_PATH: [
            'transaction',
            'delivery',
            'execution',
            'end_loading_planned',
          ],
          FORMATTER: (date) =>
            dateUtils.getFormattedDate_safe(
              date,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
            ),
        },
        {
          NAME: 'Zeitpunkt | Abfahrt (Geplant)',
          PATH: ['execution', 'departurePlanned'],
          BACKEND_PATH: [
            'transaction',
            'delivery',
            'execution',
            'departure_planned',
          ],
          FORMATTER: (date) =>
            dateUtils.getFormattedDate_safe(
              date,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
            ),
        },
        {
          NAME: 'Zeitpunkt | Ankunft (Geplant)',
          PATH: ['execution', 'arrivedPlanned'],
          BACKEND_PATH: [
            'transaction',
            'delivery',
            'execution',
            'arrived_planned',
          ],
          FORMATTER: (date) =>
            dateUtils.getFormattedDate_safe(
              date,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
            ),
        },
        {
          NAME: 'Zeitpunkt | Beginn Entladung (Geplant)',
          PATH: ['execution', 'beginUnloadingPlanned'],
          BACKEND_PATH: [
            'transaction',
            'delivery',
            'execution',
            'begin_unloading_planned',
          ],
          FORMATTER: (date) =>
            dateUtils.getFormattedDate_safe(
              date,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
            ),
        },
        {
          NAME: 'Zeitpunkt | Ende Entladung (Geplant)',
          PATH: ['execution', 'endUnloadingPlanned'],
          BACKEND_PATH: [
            'transaction',
            'delivery',
            'execution',
            'end_unloading_planned',
          ],
          FORMATTER: (date) =>
            dateUtils.getFormattedDate_safe(
              date,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
            ),
        },
        {
          NAME: 'Zeitpunkt | Beginn Lieferung (Geplant)',
          PATH: ['execution', 'beginExecutionPlanned'],
          BACKEND_PATH: [
            'transaction',
            'delivery',
            'execution',
            'begin_execution_planned',
          ],
          FORMATTER: (date) =>
            dateUtils.getFormattedDate_safe(
              date,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
            ),
        },
        {
          NAME: 'Zeitpunkt | Ende Lieferung (Geplant)',
          PATH: ['execution', 'endExecutionPlanned'],
          BACKEND_PATH: [
            'transaction',
            'delivery',
            'execution',
            'end_execution_planned',
          ],
          FORMATTER: (date) =>
            dateUtils.getFormattedDate_safe(
              date,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
            ),
        },
        {
          NAME: 'Zeitpunkt | Beginn Beladung',
          PATH: ['execution', 'beginLoading'],
          BACKEND_PATH: [
            'transaction',
            'delivery',
            'execution',
            'begin_loading',
          ],
          FORMATTER: (date) =>
            dateUtils.getFormattedDate_safe(
              date,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
            ),
        },
        {
          NAME: 'Zeitpunkt | Ende Beladung',
          PATH: ['execution', 'endLoading'],
          BACKEND_PATH: ['transaction', 'delivery', 'execution', 'end_loading'],
          FORMATTER: (date) =>
            dateUtils.getFormattedDate_safe(
              date,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
            ),
        },
        {
          NAME: 'Zeitpunkt | Abfahrt',
          PATH: ['execution', 'departure'],
          BACKEND_PATH: ['transaction', 'delivery', 'execution', 'departure'],
          FORMATTER: (date) =>
            dateUtils.getFormattedDate_safe(
              date,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
            ),
        },
        {
          NAME: 'Zeitpunkt | Ankunft',
          PATH: ['execution', 'arrived'],
          BACKEND_PATH: ['transaction', 'delivery', 'execution', 'arrived'],
          FORMATTER: (date) =>
            dateUtils.getFormattedDate_safe(
              date,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
            ),
        },
        {
          NAME: 'Zeitpunkt | Beginn Entladung',
          PATH: ['execution', 'beginUnloading'],
          BACKEND_PATH: [
            'transaction',
            'delivery',
            'execution',
            'begin_unloading',
          ],
          FORMATTER: (date) =>
            dateUtils.getFormattedDate_safe(
              date,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
            ),
        },
        {
          NAME: 'Zeitpunkt | Ende Entladung',
          PATH: ['execution', 'endUnloading'],
          BACKEND_PATH: [
            'transaction',
            'delivery',
            'execution',
            'end_unloading',
          ],
          FORMATTER: (date) =>
            dateUtils.getFormattedDate_safe(
              date,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
            ),
        },
        {
          NAME: 'Zeitpunkt | Beginn Lieferung',
          PATH: ['execution', 'beginExecution'],
          BACKEND_PATH: [
            'transaction',
            'delivery',
            'execution',
            'begin_execution',
          ],
          FORMATTER: (date) =>
            dateUtils.getFormattedDate_safe(
              date,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
            ),
        },
        {
          NAME: 'Zeitpunkt | Ende Lieferung',
          PATH: ['execution', 'endExecution'],
          BACKEND_PATH: [
            'transaction',
            'delivery',
            'execution',
            'end_execution',
          ],
          FORMATTER: (date) =>
            dateUtils.getFormattedDate_safe(
              date,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
            ),
        },
      ],
    },
    FREIGHT_FORWARDER: {
      NAME: 'Frachtführer',
      PATH: ['freightForwarder'],
      BACKEND_PATH: [
        'transaction',
        'delivery',
        'ship_from',
        'consignment',
        'movement',
        'freight_forwarder',
      ],
      MODEL: DeliveryNoteDataQuality.COMPANY,
    },
    FROM_SITE: {
      NAME: 'Beladeort',
      PATH: ['fromSite'],
      BACKEND_PATH: ['transaction', 'delivery', 'ship_from'],
      MODEL: DeliveryNoteDataQuality.SITE,
    },
    TO_SITE_SUPPLIER: {
      NAME: 'Lieferort',
      PATH: ['toSiteSupplier'],
      BACKEND_PATH: ['transaction', 'delivery', 'ship_to'],
      MODEL: DeliveryNoteDataQuality.SITE,
    },
    ARTICLE: {
      NAME: 'Artikel',
      MULTIPLE: true,
      PATH: ['articles'],
      BACKEND_PATH: ['transaction', 'logistics_package', '*', 'line_item', '*'],
      MODEL: DeliveryNoteDataQuality.ARTICLE,
    },
  };
}
