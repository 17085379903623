import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Button, Dialog, IconButton, Typography } from '@mui/material';

import { useStyles } from './styles';

export const EmailConfirmationModal = ({
  isLoading,
  onAccept,
  onClose,
  open,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} className={classes.emailDialog}>
      <IconButton className="close-button" onClick={onClose} size="large">
        <CloseIcon />
      </IconButton>
      <Box>
        <Typography variant="h5">Neue Mail erstellen</Typography>
      </Box>
      <Box marginTop={2}>
        <Typography>
          Soll eine neue Mail mit einem neuen Link erstellt werden? Der aktuelle
          Link wird dadurch ungültig.
        </Typography>
      </Box>
      <Box
        fullWidth
        marginTop={4}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button onClick={onClose} color="primary" variant="text">
          Abbrechen
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onAccept}
          disabled={isLoading}
          loading={isLoading}
        >
          Bestätigen
        </Button>
      </Box>
    </Dialog>
  );
};
