import React from 'react';

import { Grid, InputLabel, TextField } from '@mui/material';

import { connect } from 'react-redux';
import { withErrorBoundary } from '~/ui/atoms';
import Select from '~/components/baseComponents/inputs/select/Select';
import Log from '~/utils/Log';
import cloneDeep from 'lodash/cloneDeep';
import Company from '~/models/masterdata/Company';
import FunctionUtils from '~/utils/functionUtils';
import PasswordTextField from '~/components/baseComponents/inputs/PasswordTextField';

const mapStateToProps = (state) => ({
  companies: state.companies,
  userinfo: state.userinfo,
});

class CreateUserWizardAccountInformation extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.resetDefaultValues();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.companies.companies) !==
      JSON.stringify(prevProps.companies.companies)
    ) {
      this.resetDefaultValues();
    }
  }

  resetDefaultValues() {
    const newUser = cloneDeep(this.props.user);

    if (!newUser.company.id) {
      newUser.company =
        this.props.companies.companies.find(
          (company) => company.id === this.props.userinfo.userinfo.company?.id,
        ) ?? new Company({ id: this.props.userinfo.userinfo.company?.id });
    }

    this.props.setUser(newUser);
  }

  handleInputChange = (event) => {
    const newUser = cloneDeep(this.props.user);

    switch (event.target.name) {
      case 'email': {
        newUser.email = event.target.value;
        Log.info(
          'Change form value of email',
          { from: this.props.user.email, to: newUser.email },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'user_create_wizard_change_email',
          Log.productAnalyticsEvent,
          ['Change email in create user wizard', Log.FEATURE.WIZARD],
        );
        break;
      }

      case 'password': {
        newUser.password = event.target.value;
        Log.info(
          'Change form value of password',
          { from: this.props.user.password, to: newUser.password },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'user_create_wizard_change_password',
          Log.productAnalyticsEvent,
          ['Change password in create user wizard', Log.FEATURE.WIZARD],
        );
        break;
      }

      case 'first_name': {
        newUser.firstName = event.target.value;
        Log.info(
          'Change form value of first name',
          { from: this.props.user.firstName, to: newUser.firstName },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'user_create_wizard_change_first_name',
          Log.productAnalyticsEvent,
          ['Change first name in create user wizard', Log.FEATURE.WIZARD],
        );
        break;
      }

      case 'last_name': {
        newUser.lastName = event.target.value;
        Log.info(
          'Change form value of last name',
          { from: this.props.user.lastName, to: newUser.lastName },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'user_create_wizard_change_last_name',
          Log.productAnalyticsEvent,
          ['Change last name in create user wizard', Log.FEATURE.WIZARD],
        );
        break;
      }

      case 'position': {
        newUser.position = event.target.value;
        Log.info(
          'Change form value of position',
          { from: this.props.user.position, to: newUser.position },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'user_create_wizard_change_position',
          Log.productAnalyticsEvent,
          ['Change position in create user wizard', Log.FEATURE.WIZARD],
        );
        break;
      }
    }

    this.props.setUser(newUser);
  };
  handleChangeCompany = (event) => {
    const newUser = cloneDeep(this.props.user);

    newUser.company = new Company({ id: event.target.value });

    Log.info(
      'Change form value of company',
      { from: this.props.user.company, to: newUser.company },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change company in create user wizard',
      Log.FEATURE.WIZARD,
    );

    this.props.setUser(newUser);
  };

  render() {
    return (
      <Grid container direction="row" spacing={3} space={4}>
        <Grid item xs={12} lg={12}>
          <h3 className="main-text mt-0">Benutzer-Account Informationen</h3>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <TextField
                id="email-input"
                name="email"
                label="E-Mail"
                type="text"
                fullWidth
                required
                value={this.props.user.email}
                onChange={this.handleInputChange}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <PasswordTextField
                fullWidth
                required
                value={this.props.user.password}
                onChange={this.handleInputChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <TextField
                id="first_name-input"
                name="first_name"
                label="Vorname"
                type="text"
                fullWidth
                required
                value={this.props.user.firstName}
                onChange={this.handleInputChange}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <TextField
                id="last_name-input"
                name="last_name"
                label="Nachname"
                type="text"
                fullWidth
                required
                value={this.props.user.lastName}
                onChange={this.handleInputChange}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <h3 className="mt-20px main-text">
            Welcher Firma soll der Benutzer zugeordnet sein?
          </h3>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <InputLabel className="text-13px" id="demo-multiple-name-label">
                Firma
              </InputLabel>
              <Select
                value={this.props.user.company.id}
                fullWidth
                onChange={this.handleChangeCompany}
                size="small"
                options={this.props.companies.companies}
                loading={this.props.companies.companiesLoading}
                errorText="Firmen konnten nicht geladen werden."
                sortOptions
                sortOptionsByKey="name"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <h3 className="mt-20px main-text">
            Welche Position hat der Benutzer in der Firma?
          </h3>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <TextField
                id="position-input"
                name="position"
                label="Position"
                type="text"
                fullWidth
                value={this.props.user.position}
                onChange={this.handleInputChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(CreateUserWizardAccountInformation),
  'Daten konnten nicht geladen werden.',
);
