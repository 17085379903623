import React from 'react';

import { AddBoxOutlined as AddBoxOutlinedIcon } from '@mui/icons-material';

import { Grid } from '@mui/material';
import Article from '~/models/articles/Article';
import { LightTooltipWide } from '~/utils/componentUtils';
import { withErrorBoundary } from '~/ui/atoms';
import ValueGroup from '~/models/deliveries/ValueGroup';
import { EditingHistorySafe as EditingHistory } from '../deliveries/deliveryNote/EditingHistorySafe';
import UnitUtils from '~/utils/unitUtils';

class ArticleMaterial extends React.Component {
  formatValueUnitPair = (pair) => {
    return (
      UnitUtils.formatValueUnitPair_safe(
        pair.value,
        pair.unit,
        UnitUtils.getAbbreviatedUnit,
      ) ?? '-'
    );
  };

  render() {
    return (
      <Grid container spacing="20px" justifyContent="space-between">
        <Grid item xs={12} lg={6}>
          <div className="flex-s-s">
            {ValueGroup.getCurrentValue(this.props.initialComponent) ? null : (
              <LightTooltipWide title="Nachträglich zum Betongemisch hinzugefügter Inhaltsstoff">
                <AddBoxOutlinedIcon fontSize="small" className="mr-5px" />
              </LightTooltipWide>
            )}
            <div>
              {Article.getMaterialName(
                ValueGroup.getCurrentValue(this.props.material?.type),
              )}
            </div>
          </div>
          <div className="bold">
            <EditingHistory value={this.props.material?.name} />
          </div>
        </Grid>
        <Grid item xs={12} lg={3}>
          <div>Menge</div>
          <div className="bold">
            <EditingHistory
              value={ValueGroup.applyFunction(
                {
                  value: this.props.material?.quantity?.value,
                  unit: this.props.material?.quantity?.unit,
                },
                this.formatValueUnitPair,
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={3}>
          <div>
            {ValueGroup.getCurrentValue(this.props.initialComponent) ? (
              <>
                <div>Angefragte Menge</div>
                <div className="bold">
                  <EditingHistory
                    value={ValueGroup.applyFunction(
                      {
                        value: this.props.material?.volumeRequested?.value,
                        unit: this.props.material?.volumeRequested?.unit,
                      },
                      this.formatValueUnitPair,
                    )}
                  />
                </div>
              </>
            ) : (
              <>
                <div>Verbleibende Menge</div>
                <div className="bold">
                  <EditingHistory
                    value={ValueGroup.applyFunction(
                      {
                        value: this.props.material?.remainingConcrete?.value,
                        unit: this.props.material?.remainingConcrete?.unit,
                      },
                      this.formatValueUnitPair,
                    )}
                  />
                </div>
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <div>Hersteller</div>
          <div className="bold">
            <EditingHistory value={this.props.material?.manufacturer?.name} />
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <div>Kommentar</div>
          <div className="bold">
            <EditingHistory value={this.props.material?.comment} />
          </div>
        </Grid>
        {ValueGroup.getCurrentValue(this.props.initialComponent) ? null : (
          <>
            <Grid item xs={12} lg={6}>
              <div>Grund</div>
              <div className="bold">
                <EditingHistory value={this.props.material?.reason} />
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div>Ergebnis</div>
              <div className="bold">
                <EditingHistory value={this.props.material?.result} />
              </div>
            </Grid>
          </>
        )}
      </Grid>
    );
  }
}

export default withErrorBoundary(
  ArticleMaterial,
  'Inhaltsstoff konnte nicht geladen werden.',
);
