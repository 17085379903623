import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';

export default function Select(props) {
  const EMPTY_VALUE = 'empty-value';
  let EMPTY_VALUE_NAME = 'Nichts ausgewählt';
  let emptyValueHidden = true;

  if (props.defaultValue) {
    // default value should be selectable in select
    EMPTY_VALUE_NAME = props.defaultValue;
    emptyValueHidden = false;
  } else if (!props.required) {
    // if no default value and the select is not required, the Nichts ausgewählt should be selectable as fallback for user
    emptyValueHidden = false;
  }

  const options = [
    { id: EMPTY_VALUE, name: EMPTY_VALUE_NAME },
    ...props.options,
  ];

  const onChange = (event) => {
    if (event.target.value === EMPTY_VALUE) {
      event.target.value = null;
    }

    props.onChange(event);
  };

  return (
    <div className={'relative ' + props.className}>
      <InputLabel
        className={'text-13px ' + (props.error ? 'text-mui-error-red' : '')}
      >
        {props.title}
      </InputLabel>
      <MuiSelect
        value={props.value ? props.value : EMPTY_VALUE}
        onChange={onChange}
        fullWidth
        error={Boolean(props.error)}
        size="small"
        renderValue={(id) => {
          if (id === EMPTY_VALUE) {
            return (
              <span
                className={
                  props.defaultValue ? '' : 'text-mui-not-selected-grey'
                }
              >
                {EMPTY_VALUE_NAME}
              </span>
            );
          }

          return options.find((option) => option.id === id)?.name;
        }}
      >
        {options.map((entity) => {
          if (entity.id === EMPTY_VALUE) {
            return (
              <MenuItem
                value={entity.id}
                key={entity.id}
                style={emptyValueHidden ? { display: 'none' } : null}
              >
                {entity.name}
              </MenuItem>
            );
          }

          if (props.renderOptions) {
            return props.renderOptions(entity);
          }

          return (
            <MenuItem value={entity.id} key={entity.id}>
              {entity.name}
            </MenuItem>
          );
        })}
      </MuiSelect>
      {props.error ? (
        <div className="flex-e-s w-full">
          <FormHelperText className="text-mui-error-red absolute">
            {props.error}
          </FormHelperText>
        </div>
      ) : null}
    </div>
  );
}
