import clsx from 'clsx';
import Invoice from '~/models/invoices/Invoice';
import InvoiceCheckResult from '~/models/invoices/InvoiceCheckResult';

import LocalStorageService from '~/services/localStorage.service';

import { LightTooltip } from '~/utils/componentUtils';
import DatagridUtils from '~/utils/datagridUtils';

import InvoiceCheckCategory from '~/models/invoices/InvoiceCheckCategory';
import InvoiceCheckIcon from '../InvoiceCheck/InvoiceCheckIcon';

import { withErrorBoundary } from '~/ui/atoms';
import { CheckMarkIcon } from '~/assets/icons';

const MAX_INVOICE_CHECK_CATEGORIES = 3;

export const InvoiceColumnCombinedStatus = withErrorBoundary((props) => {
  const cookie = LocalStorageService.getObjectFromLocalStorage(
    LocalStorageService.INVOICE_OVERVIEW,
  );

  const { value: statusValue } = props.params;

  const [
    status,
    subStatus,
    signatureCheckStatus,
    signatureCheckText,
    ...rawIcons
  ] = statusValue.split(';');

  if (status === InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE) {
    const descriptiveSubStatus = Invoice.getDescriptiveSubStatus(subStatus);

    return (
      <LightTooltip
        title={
          descriptiveSubStatus
            ? 'Keine Prüfung möglich - ' + descriptiveSubStatus
            : 'Keine Prüfung möglich.'
        }
      >
        <div
          className={
            'w-34px status-box flex-s-c gap-20px border-grey500 bg-white ' +
            DatagridUtils.getStatusBoxHeight(cookie?.rowHeight)
          }
        >
          <div className="h-3px w-15px bg-grey500 rounded-5px" />
        </div>
      </LightTooltip>
    );
  }

  if (status === InvoiceCheckResult.STATUS.DELAYED_SUCCESS) {
    return (
      <LightTooltip title="Die Lieferungen wurden nachträglich signiert.">
        <div
          className={
            'w-34px status-box flex-s-c gap-20px bg-success100 ' +
            DatagridUtils.getStatusBoxHeight(cookie?.rowHeight)
          }
        >
          <InvoiceCheckIcon
            iconKey={InvoiceCheckCategory.CATEGORIES.SIGNATURE_CHECK.KEY}
            status={InvoiceCheckResult.STATUS.SUCCESS}
            withoutBorder
            className="icon-small text-white"
          />
        </div>
      </LightTooltip>
    );
  }

  if (status === InvoiceCheckResult.STATUS.WARNING) {
    return (
      <LightTooltip title={signatureCheckText}>
        <div
          className={
            'w-34px status-box flex-s-c gap-20px bg-warningBase ' +
            DatagridUtils.getStatusBoxHeight(cookie?.rowHeight)
          }
        >
          <InvoiceCheckIcon
            iconKey={InvoiceCheckCategory.CATEGORIES.SIGNATURE_CHECK.KEY}
            status={InvoiceCheckResult.STATUS.WARNING}
            withoutBorder
            className="icon-small text-white"
          />
        </div>
      </LightTooltip>
    );
  }

  if (status === InvoiceCheckResult.STATUS.SUCCESS) {
    return (
      <div
        className={
          'w-34px status-box flex-s-c gap-20px bg-success500 ' +
          DatagridUtils.getStatusBoxHeight(cookie?.rowHeight)
        }
      >
        <LightTooltip title="Die Rechnungsprüfung wurde erfolgreich bestanden.">
          <CheckMarkIcon className="h-16px w-16px text-white" />
        </LightTooltip>
      </div>
    );
  }

  let icons = rawIcons
    .filter((iconSummaryPair) => iconSummaryPair !== '')
    .map((iconSummaryPair, index) => {
      const [iconKey, summary] = iconSummaryPair.split('|');
      const icon = (
        <InvoiceCheckIcon
          iconKey={iconKey}
          status={InvoiceCheckResult.STATUS.ERROR}
          withoutBorder
          className="icon-small text-white"
        />
      );

      return (
        <LightTooltip key={index} title={summary}>
          <div className="flex-c-c h-full">{icon}</div>
        </LightTooltip>
      );
    });

  let maxInvoiceCheckCategories = MAX_INVOICE_CHECK_CATEGORIES;
  if (signatureCheckStatus === InvoiceCheckResult.STATUS.DELAYED_SUCCESS) {
    maxInvoiceCheckCategories -= 1;
  }

  if (icons.length > maxInvoiceCheckCategories) {
    const additionalErrorCategoriesCount =
      icons.length - maxInvoiceCheckCategories + 1;

    icons = icons.slice(0, maxInvoiceCheckCategories - 1);
    icons[maxInvoiceCheckCategories - 1] = (
      <LightTooltip
        title={
          'Die Rechnungsprüfung enthält ' +
          additionalErrorCategoriesCount +
          ' weitere fehlerhafte Prüfschritte.'
        }
      >
        <div className="w-24px flex-c-c h-full">
          +{additionalErrorCategoriesCount}
        </div>
      </LightTooltip>
    );
  }

  const width = 10 + icons.length * 24 + (icons.length - 1) * 15;

  if (signatureCheckStatus === InvoiceCheckResult.STATUS.DELAYED_SUCCESS) {
    return (
      <div className="gap-5px flex h-full items-center justify-start">
        <LightTooltip title="Die Lieferungen wurden nachträglich signiert.">
          <div
            className={
              'w-34px status-box flex-s-c gap-20px bg-success100 ' +
              DatagridUtils.getStatusBoxHeight(cookie?.rowHeight)
            }
          >
            <InvoiceCheckIcon
              iconKey={InvoiceCheckCategory.CATEGORIES.SIGNATURE_CHECK.KEY}
              status={InvoiceCheckResult.STATUS.SUCCESS}
              withoutBorder
              className="icon-small text-white"
            />
          </div>
        </LightTooltip>
        <div
          className={
            'status-box flex-c-c gap-15px ' +
            DatagridUtils.getStatusBoxHeight(cookie?.rowHeight) +
            (props.params.value === 0
              ? ' bg-success100 text-black'
              : ' bg-error300 text-white')
          }
          style={{ width }}
        >
          {[...icons]}
        </div>
      </div>
    );
  }

  if (signatureCheckStatus === InvoiceCheckResult.STATUS.WARNING) {
    return (
      <div className="gap-5px flex h-full items-center justify-start">
        <LightTooltip title={signatureCheckText}>
          <div
            className={
              'w-34px status-box flex-s-c gap-20px bg-warningBase ' +
              DatagridUtils.getStatusBoxHeight(cookie?.rowHeight)
            }
          >
            <InvoiceCheckIcon
              iconKey={InvoiceCheckCategory.CATEGORIES.SIGNATURE_CHECK.KEY}
              status={InvoiceCheckResult.STATUS.WARNING}
              withoutBorder
              className="icon-small text-white"
            />
          </div>
        </LightTooltip>
        <div
          className={clsx(
            'status-box flex h-full items-center justify-center',
            {
              'bg-success100 text-black': props.params.value === 0,
              'bg-error300 text-white': props.params.value !== 0,
            },
          )}
          style={{ width }}
        >
          {[...icons]}
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx('status-box flex-c-c gap-15px h-full', {
        'bg-success100 text-black': props.params.value === 0,
        'bg-error300 text-white': props.params.value !== 0,
      })}
      style={{ width }}
    >
      {[...icons]}
    </div>
  );
}, null);
