import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Grid } from '@mui/material';

import DeliveryNoteMetaData from './deliveryNoteMetaData/DeliveryNoteMetaData';
import DeliveryNoteArticleList from './DeliveryNoteArticleList';
import DeliveryNoteParty from './DeliveryNoteParty';
import DeliveryNoteHistory from './DeliveryNoteHistory';
import DeliveriesService from '~/services/deliveries.service';
import DeliveryNoteModel from '~/models/deliveries/DeliveryNote';
import DeliveryNoteAction from '~/models/deliveries/DeliveryNoteAction';
import { promiseHandler } from '~/utils/promiseHandler';

import DeliveryNoteLogos from './DeliveryNoteLogos';
import Log from '~/utils/Log';
import DeliveryStatus from '../DeliveryStatus';

import DeliveryNoteContractParties from './DeliveryNoteContractParties';
import FunctionUtils from '~/utils/functionUtils';
import BrowserUtils from '~/utils/browserUtils';
import { setConcreteDiary_concreteIds } from '~/redux/filtersSlice';
import { openJsonModal } from '~/redux/devToolsSlice';
import { LOADING_STATE } from '~/constants/LoadingState';
import { setPageTitle } from '~/redux/menuSlice';

import DocumentLoadingPage from '../../DocumentLoadingPage';
import DocumentBrowser from '../../DocumentBrowser';
import { ROUTE } from '~/constants/Route';
import DeliveryNoteTrader from './DeliveryNoteTrader';
import ValueGroup from '~/models/deliveries/ValueGroup';
import Article from '~/models/articles/Article';
import BilledItem from '~/models/billingState/BilledItem';
import DeliveryNoteReferencedInvoices from './DeliveryNoteReferencedInvoices';
import CustomFieldService from '~/services/customField.service';

import { ErrorBoundary, withErrorBoundary } from '~/ui/atoms';

import { DeliveryCategoryIcon } from '../DeliveryCategoryIcon';

import { DeliveryNoteButtons } from './DeliveryNoteButtons';

const mapStateToProps = (state) => ({
  deliveryNotes: state.deliveryNotes,
  concreteIds: state.filters.concreteDiary_concreteIds,
  userinfo: state.userinfo,
});
const mapDispatchToProps = () => ({
  setConcreteDiary_concreteIds,
  openJsonModal,
  setPageTitle,
});

class DeliveryNote extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveryNote: null,
      loading: LOADING_STATE.LOADING,
      historyLoading: LOADING_STATE.LOADING,
      permittedUsersLoading: LOADING_STATE.LOADING,
      requestedUsersLoading: LOADING_STATE.LOADING,
      sharedUsersLoading: LOADING_STATE.LOADING,
      deliveryNoteActions: [],
      downloadingPdf: false,
      isGridXsScreen: false,
    };
  }

  componentDidMount() {
    this.loadDocument_safe();

    this.updateIsGridXsScreen();
    window.addEventListener('resize', this.updateIsGridXsScreen);

    this.props.setPageTitle('Lieferung');
    document.title = 'VESTIGAS - Lieferung';
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const dlnFromStore = this.props.deliveryNotes.deliveryNotes.find(
      (dln) => dln.id === this.props.match.params.id,
    );
    const previousDlnFromStore = prevProps.deliveryNotes.deliveryNotes.find(
      (dln) => dln.id === this.props.match.params.id,
    );

    // reload document if other dln has been opened (i.e. url has changed)
    // or dln in store has been updated (i.e. by changes feed)
    if (
      this.props.match.params.id !== prevProps.match.params.id ||
      (JSON.stringify(dlnFromStore?.assetChain) !==
        JSON.stringify(previousDlnFromStore?.assetChain) &&
        previousDlnFromStore !== undefined) ||
      // If a signature has been requested, we need to update the dln so that the button is greyed out and the metadata is displayed.
      JSON.stringify(dlnFromStore?.userActions) !==
        JSON.stringify(previousDlnFromStore?.userActions)
    ) {
      this.loadDocument_safe();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateIsGridXsScreen);
  }

  // isGridXsScreen is implemented as state so that Grid alignment is dynamically changed when screen resizes
  updateIsGridXsScreen = () => {
    this.setState({
      isGridXsScreen: BrowserUtils.isGridXsScreen(),
    });
  };

  loadDocument_safe(ignoreCache) {
    this.loadDocument(ignoreCache).catch((error) => {
      Log.error('Failed to load delivery note', error);
      Log.productAnalyticsEvent(
        'Failed to load delivery note',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );

      this.setState({
        loading: LOADING_STATE.FAILED,
      });
    });
  }

  async loadDocument(ignoreCache) {
    // Before throwing an error, try to load the delivery note 5 times
    const [dln, error] = await promiseHandler(
      FunctionUtils.repeatAsyncFunction(
        () =>
          DeliveriesService.getDeliveryNoteById(
            this.props.match.params.id,
            ignoreCache,
          ),
        5,
        1000,
      ),
    );

    if (error) {
      throw error;
    }

    this.setState({
      deliveryNote: dln,
      loading: LOADING_STATE.SUCCEEDED,
    });

    this.props.setPageTitle('Lieferung ' + (dln.number ?? ''));
    document.title = 'VESTIGAS - Lieferung ' + (dln.number ?? '');

    const customFieldIds = dln.getCustomFieldIds();
    const [response2, error2] = await promiseHandler(
      CustomFieldService.loadCustomFieldsBulk(customFieldIds),
    );

    if (error2) {
      throw error2;
    }

    const [response3, error3] = await promiseHandler(this.asyncDlnInit(dln));

    if (error3) {
      throw error3;
    }

    const [response4, error4] = await promiseHandler(
      this.initPermittedUsers(dln),
    );

    if (error4) {
      this.setState({
        permittedUsersLoading: LOADING_STATE.FAILED,
      });
    }

    // If the delivery note is loaded from the backend and not from the store, the user actions must be initialized.
    dln.initUserActions();

    this.setState({
      requestedUsersLoading: LOADING_STATE.SUCCEEDED,
      sharedUsersLoading: LOADING_STATE.SUCCEEDED,
    });

    // It is important to load the delivery note as the last step, as the attachments are written here and overwritten otherwise.
    const [response5, error5] = await promiseHandler(
      this.loadDeliveryNoteHistory(dln),
    );

    if (error5) {
      this.setState({
        historyLoading: LOADING_STATE.FAILED,
      });

      Log.error('Failed to load delivery note history.', error5);
      Log.productAnalyticsEvent(
        'Failed to load delivery note history',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
    }
  }

  async loadDeliveryNoteHistory(deliveryNote) {
    const newDln = cloneDeep(deliveryNote);

    const deliveryNoteActions = [];

    const [chains, error1] = await promiseHandler(
      DeliveriesService.getDeliveryNoteChainsByDlnIds([newDln.id]),
    );

    if (error1) {
      throw error1;
    }

    for (let index = 0; index < newDln.assetChain?.length; index++) {
      const chain = chains.find(
        (chain) => chain._id === newDln.assetChain[index],
      );

      if (!chain) {
        Log.error(
          'Failed to load chain in delivery note history. id: ' +
            newDln.assetChain[index],
        );
        Log.productAnalyticsEvent(
          'Failed to load chain in delivery note history',
          Log.FEATURE.DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );
        continue;
      }

      const deliveryNoteAction = new DeliveryNoteAction(chain, newDln);

      const [response2, error2] = await promiseHandler(
        deliveryNoteAction.initCompany(),
      );

      if (error2) {
        Log.error(
          'Failed to initialize company in delivery note history.',
          error2,
        );
        Log.productAnalyticsEvent(
          'Failed to initialize company in delivery note history',
          Log.FEATURE.DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );
      } else {
        // Only load user if company has been loaded successfully as the user is loaded via the employees of the company.
        const [response3, error3] = await promiseHandler(
          deliveryNoteAction.initUser(),
        );
        if (error3) {
          Log.error(
            'Failed to initialize user in delivery note history.',
            error3,
          );
        }
      }

      await newDln.addChainToHistory(
        chain,
        deliveryNoteAction.company?.name,
        index === 0,
      );

      newDln.changes = [];
      deliveryNoteAction.setChanges(newDln);

      newDln.attachmentHandler.addAttachmentsFromChain(chain);

      deliveryNoteActions.push(deliveryNoteAction);
    }

    // Write the chain ids of the attachments from the attachment handler into the corresponding attachments of the accept articles of the articles of the dln.
    newDln.attachmentHandler.populateAcceptArticleChainIds(newDln);
    // Write the attachments from the attachment handler into the corresponding delivery note actions.
    newDln.attachmentHandler.populateDeliveryNoteActions(deliveryNoteActions);

    newDln.mergeHistory();

    this.setState({
      deliveryNote: newDln,
      deliveryNoteActions,
      historyLoading: LOADING_STATE.SUCCEEDED,
    });
  }

  async asyncDlnInit(deliveryNote) {
    const [response, error] = await promiseHandler(deliveryNote.asyncInit());

    if (error) {
      throw error;
    }

    // deliveryNote must be clonedDeep only after asyncInit. If it was cloned before, the asyncInit changes (e.g. permittedToSites),
    // would not be included in loadDeliveryNoteHistory. This could lead to the permittedToSites to be overwritten.
    // Still cloneDeep must be used at all, because otherwise React wouldn't recognise a state change.
    const newDln = cloneDeep(deliveryNote);

    this.setState({
      deliveryNote: newDln,
    });
  }

  async initPermittedUsers(deliveryNote) {
    const [response, error] = await promiseHandler(
      deliveryNote.initPermittedUsers(),
    );

    if (error) {
      throw error;
    }

    const newDln = cloneDeep(deliveryNote);

    this.setState({
      deliveryNote: newDln,
      permittedUsersLoading: LOADING_STATE.SUCCEEDED,
    });
  }

  refreshDeliveryNote = () => {
    this.setState({
      loading: LOADING_STATE.LOADING,
    });

    this.loadDocument_safe(true);
  };

  displayContractParties() {
    return (
      JSON.stringify(this.state.deliveryNote?.seller) !==
        JSON.stringify(this.state.deliveryNote?.supplier) ||
      JSON.stringify(this.state.deliveryNote?.buyer) !==
        JSON.stringify(this.state.deliveryNote?.recipient)
    );
  }

  shouldDisplayArticleList(deliveryDirection) {
    if (
      deliveryDirection === Article.DELIVERY_DIRECTION.RETURNED_ARTICLES.KEY &&
      this.state.deliveryNote?.returnedArticles?.length > 0
    ) {
      return true;
    }

    if (
      deliveryDirection === Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY &&
      this.state.deliveryNote?.deliveredArticles?.length > 0
    ) {
      return true;
    }

    // If no articles are delivered or returned, the article list should be displayed anyway.
    if (
      deliveryDirection === Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY &&
      this.state.deliveryNote?.deliveredArticles?.length === 0 &&
      this.state.deliveryNote?.returnedArticles?.length === 0
    ) {
      return true;
    }

    return false;
  }

  getContentOnSmallScreen() {
    return (
      <Grid container spacing="20px" justifyContent="space-between">
        <Grid item xs={6} lg={3}>
          <div className="rounded-5px box-shadow-blue p-20px h-full bg-white">
            <DeliveryNoteMetaData
              deliveryNote={this.state.deliveryNote}
              permittedUsersLoading={this.state.permittedUsersLoading}
              requestedUsersLoading={this.state.requestedUsersLoading}
              sharedUsersLoading={this.state.sharedUsersLoading}
            />
          </div>
        </Grid>
        <Grid item xs={6} lg={3}>
          <div className="flexdir-column gap-10px flex w-full">
            <div className="rounded-5px box-shadow-blue p-20px bg-white">
              <DeliveryNoteParty
                type={DeliveryNoteModel.PROCESS_ROLE.SUPPLIER.KEY}
                company={this.state.deliveryNote?.supplier}
                deliveryNote={this.state.deliveryNote}
              />
            </div>
            <div className="rounded-5px box-shadow-blue p-20px bg-white">
              <DeliveryNoteParty
                type={DeliveryNoteModel.PROCESS_ROLE.CARRIER.KEY}
                company={this.state.deliveryNote?.carrier}
                deliveryNote={this.state.deliveryNote}
              />
            </div>
            <div className="rounded-5px box-shadow-blue p-20px bg-white">
              <DeliveryNoteParty
                type={DeliveryNoteModel.PROCESS_ROLE.RECIPIENT.KEY}
                company={this.state.deliveryNote?.recipient}
                deliveryNote={this.state.deliveryNote}
                displayContractParties={this.displayContractParties()}
              />
            </div>
            {ValueGroup.getCurrentValue(
              this.state.deliveryNote?.trader?.name,
            ) ? (
              <div className="rounded-5px box-shadow-blue p-20px bg-white">
                <DeliveryNoteTrader
                  trader={this.state.deliveryNote?.trader}
                  deliveryNote={this.state.deliveryNote}
                />
              </div>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12} lg={9}>
          <div className="flexdir-column gap-20px flex w-full">
            {this.displayContractParties() ? (
              <div className="rounded-5px box-shadow-blue p-20px bg-white">
                <DeliveryNoteContractParties
                  seller={this.state.deliveryNote?.seller}
                  buyer={this.state.deliveryNote?.buyer}
                  buyerId={this.state.deliveryNote?.buyerId}
                  deliveryNote={this.state.deliveryNote}
                />
              </div>
            ) : null}
            {this.state.deliveryNote?.settledStatus &&
            this.state.deliveryNote?.settledStatus !==
              BilledItem.SETTLED_STATUS.NOT_SETTLED.KEY ? (
              <div className="rounded-5px box-shadow-blue bg-white">
                <DeliveryNoteReferencedInvoices
                  deliveryNote={this.state.deliveryNote}
                />
              </div>
            ) : null}
            {this.shouldDisplayArticleList(
              Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY,
            ) && (
              <div className="rounded-5px box-shadow-blue bg-white">
                <DeliveryNoteArticleList
                  deliveryNote={this.state.deliveryNote}
                  deliveryDirection={
                    Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY
                  }
                />
              </div>
            )}
            {this.shouldDisplayArticleList(
              Article.DELIVERY_DIRECTION.RETURNED_ARTICLES.KEY,
            ) && (
              <div className="rounded-5px box-shadow-blue bg-white">
                <DeliveryNoteArticleList
                  deliveryNote={this.state.deliveryNote}
                  deliveryDirection={
                    Article.DELIVERY_DIRECTION.RETURNED_ARTICLES.KEY
                  }
                />
              </div>
            )}
            <div className="rounded-5px box-shadow-blue bg-white">
              <DeliveryNoteHistory
                deliveryNoteActions={this.state.deliveryNoteActions}
                currentAcceptState={this.state.deliveryNote?.acceptState}
                loading={this.state.historyLoading}
                showChanges={this.props.history.location.state?.showChanges}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }

  render() {
    if (this.state.loading !== LOADING_STATE.SUCCEEDED) {
      return (
        <DocumentLoadingPage
          loading={this.state.loading}
          documentType="Lieferung"
        />
      );
    }

    return (
      <div className="main-padding">
        <Grid
          container
          spacing="20px"
          justifyContent="space-between"
          className="mb-20px"
        >
          <Grid item xs={12} lg={3} className="flex-s-e">
            <div className="h-50px w-full">
              <DeliveryStatus
                processState={this.state.deliveryNote?.processState}
                combinedState={this.state.deliveryNote?.combinedState}
                settledStatus={this.state.deliveryNote?.settledStatus}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            lg={9}
            className="flex items-center justify-between gap-8"
          >
            <ErrorBoundary>
              <div className="flex h-12 min-w-24 items-center justify-center rounded-lg bg-white">
                <DeliveryCategoryIcon
                  category={this.state.deliveryNote?.processCategory}
                />
              </div>
            </ErrorBoundary>
            <DeliveryNoteLogos
              supplier={this.state.deliveryNote?.supplier}
              carrier={this.state.deliveryNote?.carrier}
              recipient={this.state.deliveryNote?.recipient}
              acceptStateSupplier={this.state.deliveryNote?.acceptStateSupplier}
              acceptStateCarrier={this.state.deliveryNote?.acceptStateCarrier}
              acceptStateRecipient={
                this.state.deliveryNote?.acceptStateRecipient
              }
              acceptStateOnBehalfSupplier={
                this.state.deliveryNote?.acceptStateOnBehalfSupplier
              }
              acceptStateOnBehalfCarrier={
                this.state.deliveryNote?.acceptStateOnBehalfCarrier
              }
              acceptStateOnBehalfRecipient={
                this.state.deliveryNote?.acceptStateOnBehalfRecipient
              }
              processState={this.state.deliveryNote?.processState}
            />
            <DeliveryNoteButtons
              deliveryNote={this.state.deliveryNote}
              refreshDeliveryNote={this.refreshDeliveryNote}
            />
          </Grid>
        </Grid>
        {this.state.isGridXsScreen ? (
          this.getContentOnSmallScreen()
        ) : (
          <Grid container spacing="20px" justifyContent="space-between">
            <Grid item xs={12} lg={3}>
              <div className="rounded-5px box-shadow-blue p-20px h-full bg-white">
                <DeliveryNoteMetaData
                  deliveryNote={this.state.deliveryNote}
                  permittedUsersLoading={this.state.permittedUsersLoading}
                  requestedUsersLoading={this.state.requestedUsersLoading}
                  sharedUsersLoading={this.state.sharedUsersLoading}
                />
              </div>
            </Grid>
            <Grid item xs={12} lg={9}>
              <div className="flexdir-column gap-10px flex w-full">
                {this.state.deliveryNote?.settledStatus &&
                this.state.deliveryNote?.settledStatus !==
                  BilledItem.SETTLED_STATUS.NOT_SETTLED.KEY ? (
                  <div className="rounded-5px box-shadow-blue bg-white">
                    <DeliveryNoteReferencedInvoices
                      deliveryNote={this.state.deliveryNote}
                    />
                  </div>
                ) : null}
                {this.shouldDisplayArticleList(
                  Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY,
                ) && (
                  <div className="rounded-5px box-shadow-blue bg-white">
                    <DeliveryNoteArticleList
                      deliveryNote={this.state.deliveryNote}
                      deliveryDirection={
                        Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY
                      }
                    />
                  </div>
                )}
                {this.shouldDisplayArticleList(
                  Article.DELIVERY_DIRECTION.RETURNED_ARTICLES.KEY,
                ) && (
                  <div className="rounded-5px box-shadow-blue bg-white">
                    <DeliveryNoteArticleList
                      deliveryNote={this.state.deliveryNote}
                      deliveryDirection={
                        Article.DELIVERY_DIRECTION.RETURNED_ARTICLES.KEY
                      }
                    />
                  </div>
                )}
                <div className="rounded-5px box-shadow-blue p-20px mt-10px bg-white">
                  <DeliveryNoteParty
                    type={DeliveryNoteModel.PROCESS_ROLE.SUPPLIER.KEY}
                    company={this.state.deliveryNote?.supplier}
                    deliveryNote={this.state.deliveryNote}
                  />
                </div>
                <div className="rounded-5px box-shadow-blue p-20px bg-white">
                  <DeliveryNoteParty
                    type={DeliveryNoteModel.PROCESS_ROLE.CARRIER.KEY}
                    company={this.state.deliveryNote?.carrier}
                    deliveryNote={this.state.deliveryNote}
                  />
                </div>
                <div className="rounded-5px box-shadow-blue p-20px bg-white">
                  <DeliveryNoteParty
                    type={DeliveryNoteModel.PROCESS_ROLE.RECIPIENT.KEY}
                    company={this.state.deliveryNote?.recipient}
                    deliveryNote={this.state.deliveryNote}
                    displayContractParties={this.displayContractParties()}
                  />
                </div>
                {ValueGroup.getCurrentValue(
                  this.state.deliveryNote?.trader?.name,
                ) ? (
                  <div className="rounded-5px box-shadow-blue p-20px bg-white">
                    <DeliveryNoteTrader
                      trader={this.state.deliveryNote?.trader}
                      deliveryNote={this.state.deliveryNote}
                    />
                  </div>
                ) : null}
                {this.displayContractParties() ? (
                  <div className="rounded-5px box-shadow-blue p-20px bg-white">
                    <DeliveryNoteContractParties
                      seller={this.state.deliveryNote?.seller}
                      buyer={this.state.deliveryNote?.buyer}
                      buyerId={this.state.deliveryNote?.buyerId}
                    />
                  </div>
                ) : null}
                <div className="rounded-5px box-shadow-blue mt-10px bg-white">
                  <DeliveryNoteHistory
                    deliveryNoteActions={this.state.deliveryNoteActions}
                    currentAcceptStateSupplier={
                      this.state.deliveryNote?.acceptStateSupplier
                    }
                    currentAcceptStateCarrier={
                      this.state.deliveryNote?.acceptStateCarrier
                    }
                    currentAcceptStateRecipient={
                      this.state.deliveryNote?.acceptStateRecipient
                    }
                    currentAcceptStateOnBehalfSupplier={
                      this.state.deliveryNote?.acceptStateOnBehalfSupplier
                    }
                    currentAcceptStateOnBehalfCarrier={
                      this.state.deliveryNote?.acceptStateOnBehalfCarrier
                    }
                    currentAcceptStateOnBehalfRecipient={
                      this.state.deliveryNote?.acceptStateOnBehalfRecipient
                    }
                    loading={this.state.historyLoading}
                    showChanges={this.props.history.location.state?.showChanges}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        )}
        <div
          className="min-h-2rem"
          /* This is a hacky workaround to get the padding bottom of 2rem. It is applied as child container to all divs with main-padding */
          /* A better solution would be to make the parent container min-h-fit-content so that the padding of main-padding is applied. */
          /* However, min-h-fit-content seems to not work with h-fill or generally with flexbox and flex-1. */
        />
        <DocumentBrowser
          browsableDocuments={this.props.deliveryNotes.browsableDeliveryNotes}
          documentId={this.props.match.params.id}
          route={ROUTE.DELIVERY_NOTE.ROUTE}
          history={this.props.history}
        />
      </div>
    );
  }
}

export default withRouter(
  withErrorBoundary(
    connect(mapStateToProps, mapDispatchToProps())(DeliveryNote),
    'Lieferung konnte nicht geladen werden.',
  ),
);
