import { useDispatch } from 'react-redux';

import { JsonIcon } from '~/assets/icons';

import { openJsonModal } from '~/redux/devToolsSlice';

import IconButton from '~/components/IconButton';

import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

import DeliveriesService from '~/services/deliveries.service';
import ToastService from '~/services/toast.service';

export const ViewJSONDataButton = ({ id }) => {
  const dispatch = useDispatch();

  const viewJSON = async () => {
    Log.info('Open JSON');
    Log.productAnalyticsEvent('Open JSON', Log.FEATURE.DELIVERY_NOTE);

    const [response, error] = await promiseHandler(
      DeliveriesService.getDeliveryNote(id),
    );

    if (error) {
      Log.error(`Failed to load delivery note json. id: ${id}`, error);
      ToastService.httpError(
        ['JSON konnte nicht geladen werden wegen eines internen Fehlers.'],
        error.response,
      );
      Log.productAnalyticsEvent(
        'Failed to open JSON',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      return;
    }

    dispatch(openJsonModal(response));
  };

  return (
    <IconButton onClick={viewJSON} tooltipTitle="JSON anzeigen." size="large">
      <JsonIcon className="text-primary500 icon-small" />
    </IconButton>
  );
};
