import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import Log from '~/utils/Log';

export default class DataSubscriptionFilterClause {
  constructor(filterClause) {
    this.filterKeyword =
      filterClause?.filter_keyword ??
      DataSubscriptionFilterClause.FILTER_KEYWORD.DLN_NR.KEY;
    this.valueType = DataSubscriptionFilterClause.VALUE_TYPE;
    this.value = filterClause?.value;
  }

  static getFilterKeywordString(filterKey) {
    const filter = Object.keys(
      DataSubscriptionFilterClause.FILTER_KEYWORD,
    ).find(
      (x) => DataSubscriptionFilterClause.FILTER_KEYWORD[x].KEY === filterKey,
    );

    if (!filter) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid filter: ' + filterKey),
      );
      return null;
    }

    return DataSubscriptionFilterClause.FILTER_KEYWORD[filter].STRING;
  }

  static getBackendFormat(filterClause) {
    return {
      filter_keyword: filterClause.filterKeyword,
      value_type: filterClause.valueType,
      value: filterClause.value,
    };
  }

  static FILTER_KEYWORD = {
    DLN_NR: {
      KEY: 'dln_nr',
      STRING: 'LFS-Nr.',
    },
    SUPPLIER_ASSIGNED_SITE: {
      KEY: 'supplier_assigned_site',
      STRING: 'Lieferort (Lieferantenbez.) (Name)',
    },
    SUPPLIER_NAME: {
      KEY: 'supplier_name',
      STRING: 'Lieferant (Name)',
    },
    SUPPLIER_ID: {
      KEY: 'supplier_id',
      STRING: 'Lieferant (ID)',
    },
    SHIP_TO_NAME: {
      KEY: 'ship_to_name',
      STRING: 'Abnehmer (Name)',
    },
    SHIP_TO_ID: {
      KEY: 'ship_to_id',
      STRING: 'Abnehmer (ID)',
    },
    SELLER_NAME: {
      KEY: 'seller_name',
      STRING: 'Auftragnehmer (Name)',
    },
    SELLER_ID: {
      KEY: 'seller_id',
      STRING: 'Auftragnehmer (ID)',
    },
    BUYER_NAME: {
      KEY: 'buyer_name',
      STRING: 'Auftraggeber (Name)',
    },
    BUYER_ID: {
      KEY: 'buyer_id',
      STRING: 'Auftraggeber (ID)',
    },
  };
  static VALUE_TYPE = 'json';
}
