import React, { useCallback } from 'react';

import { Grid } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import {
  setDelivery_dateRange,
  setDelivery_individualDateRange,
  setDelivery_predefinedDateRange,
  setFilterValue,
} from '~/redux/filtersSlice';
import ClientPortalTooltip from '~/components/salesPackages/clientPortal/ClientPortalTooltip';
import FeatureService from '~/services/feature.service';
import { DeliveryFreeTextFilter } from '../DeliveryFreeTextFilter';
import DateRangeSelect from '~/components/baseComponents/inputs/date/DateRangeSelect';
import Log from '~/utils/Log';
import { dateUtils } from '~/utils/dateUtils';
import FilterContext from '~/models/filters/FilterContext';
import { withErrorBoundary } from '~/ui/atoms';

const DeliveryFilterBarComponent = () => {
  const dispatch = useDispatch();

  const dateRange = useSelector((state) => state.filters.delivery_dateRange);
  const predefinedDateRange = useSelector(
    (state) => state.filters.delivery_predefinedDateRange,
  );
  const individualDateRange = useSelector(
    (state) => state.filters.delivery_individualDateRange,
  );

  const handleDateRangeChange = useCallback(
    (value) => {
      const numberOfDays = dateUtils.getNumberOfDays(value[0], value[1]);

      const isLessThan10Years = numberOfDays >= 0 && numberOfDays <= 3650;
      if (!isLessThan10Years) {
        return;
      }

      Log.info(
        'Change filter value of selected date range',
        {
          from: dateRange,
          to: value,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Filter date range', Log.FEATURE.DELIVERY_LIST);

      // dispatch(
      //   setFilterValue({
      //     page: 'deliveries',
      //     filterKey: 'dateRange',
      //     filterValue: value,
      //   }),
      // );
      dispatch(
        // deprecated Redux action -> use setFilterValue instead
        setDelivery_dateRange({
          dateRange: value,
          updateCookie: true,
        }),
      );
      // deprecated Redux action -> use setFilterValue instead
      dispatch(setDelivery_individualDateRange(true));
    },
    [dateRange, dispatch],
  );

  const handlePredefinedDateRangeChange = useCallback(
    (value) => {
      Log.info(
        'Change filter value of selected predefined date range',
        {
          from: predefinedDateRange,
          to: value,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Filter predefined date range: ' + value,
        Log.FEATURE.DELIVERY_LIST,
      );

      // dispatch(
      //   setFilterValue({
      //     page: 'deliveries',
      //     filterKey: 'dateRange',
      //     filterValue: dateUtils.getTimeframeFromDateRange(value),
      //   }),
      // );
      // TODO: dispatch action to set dateRange.predefined
      // TODO: dispatch action to set dateRange.isCustom

      dispatch(setDelivery_predefinedDateRange(value));
      dispatch(
        // deprecated Redux action -> use setFilterValue instead
        setDelivery_dateRange({
          dateRange: dateUtils.getTimeframeFromDateRange(value),
          updateCookie: true,
        }),
      );
      dispatch(setDelivery_individualDateRange(false));
    },
    [predefinedDateRange, dispatch],
  );

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      className="gap-20px"
    >
      <Grid item>
        <ClientPortalTooltip>
          <DateRangeSelect
            dateRange={dateRange}
            disabled={FeatureService.clientPortal()}
            displayArchiveModeIcon
            displayLabel
            individualDateRange={individualDateRange}
            onDateRangeChange={handleDateRangeChange}
            onPredefinedDateRangeChange={handlePredefinedDateRangeChange}
            page={FilterContext.PAGE.DELIVERY}
            predefinedDateRange={predefinedDateRange}
            archiveMode
          />
        </ClientPortalTooltip>
      </Grid>
      <DeliveryFreeTextFilter />
    </Grid>
  );
};

export const DeliveryFilterBar = withErrorBoundary(DeliveryFilterBarComponent);
