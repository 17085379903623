import React from 'react';

import { Grid } from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import { connect } from 'react-redux';
import InvoiceFreeTextFilter from './InvoiceFreeTextFilter';
import {
  setInvoice_dateRange,
  setInvoice_predefinedDateRange,
  setInvoice_individualDateRange,
} from '~/redux/filtersSlice';
import DateRangeSelect from '../../baseComponents/inputs/date/DateRangeSelect';
import { dateUtils } from '~/utils/dateUtils';
import Log from '~/utils/Log';
import ClientPortalTooltip from '../../salesPackages/clientPortal/ClientPortalTooltip';
import FeatureService from '~/services/feature.service';

const mapStateToProps = (state) => ({
  selectedStatus: state.filters.invoice_selectedStatus,
  dateRange: state.filters.invoice_dateRange,
  predefinedDateRange: state.filters.invoice_predefinedDateRange,
  individualDateRange: state.filters.invoice_individualDateRange,
});
const mapDispatchToProps = () => ({
  setInvoice_dateRange,
  setInvoice_predefinedDateRange,
  setInvoice_individualDateRange,
});

class InvoiceFilterBar extends React.Component {
  handleDateRangeChange = (value) => {
    const numberOfDays = dateUtils.getNumberOfDays(value[0], value[1]);
    if (numberOfDays >= 0 && numberOfDays <= 3650) {
      Log.info(
        'Change filter value of selected date range',
        { from: this.props.dateRange, to: value },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Filter date range', Log.FEATURE.INVOICE_LIST);

      this.props.setInvoice_dateRange({ dateRange: value, updateCookie: true });
      this.props.setInvoice_individualDateRange(true);
    }
  };
  handlePredefinedDateRangeChange = (value) => {
    Log.info(
      'Change filter value of selected predefined date range',
      { from: this.props.predefinedDateRange, to: value },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Filter predefined date range: ' + value,
      Log.FEATURE.INVOICE_LIST,
    );

    this.props.setInvoice_predefinedDateRange(value);
    this.props.setInvoice_dateRange({
      dateRange: dateUtils.getTimeframeFromDateRange(value),
      updateCookie: true,
    });
    this.props.setInvoice_individualDateRange(false);
  };

  render() {
    return (
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        className="gap-20px"
      >
        <Grid item>
          <ClientPortalTooltip>
            <DateRangeSelect
              predefinedDateRange={this.props.predefinedDateRange}
              individualDateRange={this.props.individualDateRange}
              onPredefinedDateRangeChange={this.handlePredefinedDateRangeChange}
              dateRange={this.props.dateRange}
              onDateRangeChange={this.handleDateRangeChange}
              displayLabel
              disabled={FeatureService.clientPortal()}
            />
          </ClientPortalTooltip>
        </Grid>
        <InvoiceFreeTextFilter />
      </Grid>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(InvoiceFilterBar),
  null,
);
