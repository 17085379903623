import React from 'react';

import { Check as CheckIcon } from '@mui/icons-material';

import { connect } from 'react-redux';
import { Chip } from '@mui/material';
import UnitUtils from '~/utils/unitUtils';
import Log from '~/utils/Log';

const mapStateToProps = (state) => ({});

class DashboardFilterUnitChips extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayedUnits: [],
    };

    this.PAGINATION_SIZE = 5;
  }

  componentDidMount() {
    this.initDisplayedItems();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.selectableUnits) !==
      JSON.stringify(prevProps.selectableUnits)
    ) {
      this.initDisplayedItems();
    }
  }

  initDisplayedItems() {
    const selectedUnitIndex = this.props.selectableUnits.findIndex(
      (selectableUnit) => selectableUnit.unit === this.props.selectedUnit,
    );

    if (selectedUnitIndex === -1) {
      Log.error(
        'Failed to find selected unit in list of selectable units for pagination.',
      );
    }

    this.setState({
      displayedUnits: this.props.selectableUnits.slice(
        0,
        Math.max(this.PAGINATION_SIZE, selectedUnitIndex + 1),
      ),
    });
  }

  onPaginationClick = (event) => {
    event.currentTarget.blur();

    Log.info('Load the next paginated set of selectable units.', {
      cursor: this.state.displayedUnits.length,
    });
    Log.productAnalyticsEvent(
      'Load paginated selectable units',
      Log.FEATURE.DASHBOARD,
    );

    this.setState({
      displayedUnits: this.props.selectableUnits.slice(
        0,
        this.state.displayedUnits.length + this.PAGINATION_SIZE,
      ),
    });
  };

  render() {
    return (
      <div className="flex-s-c gap-10px flex-wrap">
        <Chip
          label="Alle Einheiten"
          onClick={() => this.props.onUnitChange(null)}
          icon={
            this.props.selectedUnit ? null : (
              <CheckIcon className="text-white" />
            )
          }
          className={
            this.props.selectedUnit
              ? 'text-grey800 bg-white'
              : 'bg-primary500 text-white'
          }
          sx={{
            cursor: this.props.selectedUnit ? 'pointer' : 'auto',
            border: '#8D94A8 solid 1px',
            '&:hover': {
              backgroundColor: this.props.selectedUnit
                ? '#E4E6ED !important'
                : null,
            },
          }}
        />
        {this.state.displayedUnits.map((selectableUnit) => {
          const label = this.props.isArchiveMode
            ? selectableUnit.name
            : UnitUtils.getDescriptiveUnit(selectableUnit.unit) +
              ' (' +
              selectableUnit.frequency +
              ')';

          return (
            <Chip
              key={label}
              label={label}
              onClick={() => this.props.onUnitChange(selectableUnit.unit)}
              icon={
                selectableUnit.unit === this.props.selectedUnit ? (
                  <CheckIcon className="text-white" />
                ) : null
              }
              className={
                selectableUnit.unit === this.props.selectedUnit
                  ? 'bg-primary500 text-white'
                  : 'text-grey800 bg-white'
              }
              sx={{
                cursor:
                  selectableUnit.unit === this.props.selectedUnit
                    ? 'auto'
                    : 'pointer',
                border: '#8D94A8 solid 1px',
                '&:hover': {
                  backgroundColor:
                    selectableUnit.unit === this.props.selectedUnit
                      ? null
                      : '#E4E6ED !important',
                },
              }}
            />
          );
        })}
        {this.state.displayedUnits.length <
        this.props.selectableUnits.length ? (
          <Chip
            key="more"
            label={
              '+' +
              (this.props.selectableUnits.length -
                this.state.displayedUnits.length) +
              ' weitere Einheiten'
            }
            onClick={this.onPaginationClick}
            sx={{
              cursor: 'pointer',
              backgroundColor: 'white',
              color: '#173C88',
              border: '#8D94A8 solid 1px',
              '&:hover': {
                backgroundColor: '#E4E6ED',
              },
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default connect(mapStateToProps)(DashboardFilterUnitChips);
