import axios from '~/utils/api-client';
import Config from '~/Config';
import store from '~/redux/store';
import { LOADING_STATE } from '~/constants/LoadingState';
import {
  replaceVehicle,
  replaceVehicles,
  setVehiclesLoading,
} from '~/redux/vehiclesSlice';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';
import Vehicle from '~/models/masterdata/Vehicle';
import CacheService from './cache.service';
import { es6ClassFactory as ES6ClassFactory } from '~/utils/ES6ClassFactory';

const API_URL = Config.apiUrl + '/vehicle';

class VehicleService {
  constructor() {
    this.vehiclesLoading = LOADING_STATE.NOT_LOADED;
    this.vehicles = [];
  }

  async getAllVehicles(include_users = false, return_company_account = true) {
    return axios
      .get(API_URL + '/my', {
        params: { include_users, return_company_account },
      })
      .then((response) => {
        if (response.status !== 200) {
          return [];
        }

        return response.data.items.map((vehicle) => new Vehicle(vehicle));
      });
  }

  getVehicleById = async (vehicleId) => {
    let vehicle = store
      .getState()
      .vehicles?.vehicles?.find((vehicle) => vehicle.id === vehicleId);
    // For more information about why vehicles are cached locally in VehicleService, check the comments in SiteService.getSiteById.
    if (
      !vehicle &&
      store.getState().vehicles?.vehiclesLoading === LOADING_STATE.NOT_LOADED
    ) {
      vehicle = this.vehicles.find((vehicle) => vehicle.id === vehicleId);
    }

    if (vehicle) {
      return ES6ClassFactory.convertToES6Class([vehicle], new Vehicle())[0];
    }

    if (
      store.getState().vehicles?.vehiclesLoading === LOADING_STATE.SUCCEEDED ||
      store.getState().vehicles?.vehiclesLoading === LOADING_STATE.FAILED
    ) {
      return null;
    }

    if (
      this.vehiclesLoading === LOADING_STATE.SUCCEEDED ||
      this.vehiclesLoading === LOADING_STATE.FAILED
    ) {
      return null;
    }

    store.dispatch(setVehiclesLoading(LOADING_STATE.LOADING));
    this.vehiclesLoading = LOADING_STATE.LOADING;

    const [vehicles, error] = await promiseHandler(this.getAllVehicles());

    if (error) {
      store.dispatch(setVehiclesLoading(LOADING_STATE.FAILED));
      this.vehiclesLoading = LOADING_STATE.FAILED;
      throw error;
    }

    store.dispatch(replaceVehicles(vehicles));
    this.vehiclesLoading = LOADING_STATE.SUCCEEDED;
    this.vehicles = vehicles;

    return vehicles.find((vehicle) => vehicle.id === vehicleId) ?? null;
  };
  getVehicle = async (vehicleId, ignoreCache) => {
    const url = API_URL + '/' + vehicleId;

    if (!ignoreCache) {
      const [cachedValue, error] = CacheService.getCached(url);
      if (cachedValue) {
        return cachedValue;
      }

      if (error) {
        throw error;
      }
    }

    return axios
      .get(url)
      .then((response) => {
        if (response.status !== 200) {
          Log.warn('GET /vehicle did not return 200', {
            status: response.status,
          });
        }

        const vehicle = new Vehicle(response.data, true);
        CacheService.setCached(url, vehicle);
        return vehicle;
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  };

  async createNewVehicle(body) {
    return axios.post(API_URL, body).then((response) => {
      return response.data?.id;
    });
  }

  async updateVehicle(id, body) {
    return axios.put(API_URL + '/' + id, body);
  }

  loadVehicles = async () => {
    // to not load vehicles again when they are already loading or have already been loaded
    if (
      store.getState().vehicles?.vehiclesLoading !== LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshVehicles();
  };
  refreshVehicles = async () => {
    store.dispatch(setVehiclesLoading(LOADING_STATE.LOADING));

    const [vehicles, error] = await promiseHandler(this.getAllVehicles());

    if (error) {
      store.dispatch(setVehiclesLoading(LOADING_STATE.FAILED));
      Log.error('Failed to load vehicles.', error);
      Log.productAnalyticsEvent(
        'Failed to load vehicles',
        Log.FEATURE.VEHICLE,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceVehicles(vehicles));
  };
  refreshVehicle = async (vehicleId) => {
    const [vehicle, error] = await promiseHandler(
      this.getVehicle(vehicleId, true),
    );

    if (error) {
      Log.error('Failed to load vehicle.', error);
      Log.productAnalyticsEvent(
        'Failed to load vehicle',
        Log.FEATURE.VEHICLE,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceVehicle(vehicle));
  };
}

export default new VehicleService();
