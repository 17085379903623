import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATE } from '~/constants/LoadingState';

const dataSubscriptionsSlice = createSlice({
  name: 'dataSubscriptions',
  initialState: {
    dataSubscriptions: [],
    contactPoints: [],
    notificationPolicies: [],
    dataSubscriptionsLoading: LOADING_STATE.NOT_LOADED,
    contactPointsLoading: LOADING_STATE.NOT_LOADED,
    notificationPoliciesLoading: LOADING_STATE.NOT_LOADED,
  },
  reducers: {
    replaceDataSubscriptions(state, parameter) {
      const { payload } = parameter;

      state.dataSubscriptions = payload;
      state.dataSubscriptionsLoading = LOADING_STATE.SUCCEEDED;
    },
    replaceContactPoints(state, parameter) {
      const { payload } = parameter;

      state.contactPoints = payload;
      state.contactPointsLoading = LOADING_STATE.SUCCEEDED;
    },
    replaceNotificationPolicies(state, parameter) {
      const { payload } = parameter;

      state.notificationPolicies = payload;
      state.notificationPoliciesLoading = LOADING_STATE.SUCCEEDED;
    },
    setDataSubscriptionsLoading(state, parameter) {
      const { payload } = parameter;

      state.dataSubscriptionsLoading = payload;
    },
    setContactPointsLoading(state, parameter) {
      const { payload } = parameter;

      state.contactPointsLoading = payload;
    },
    setNotificationPoliciesLoading(state, parameter) {
      const { payload } = parameter;

      state.notificationPoliciesLoading = payload;
    },
  },
});

const { actions, reducer } = dataSubscriptionsSlice;
export const {
  replaceContactPoints,
  replaceDataSubscriptions,
  replaceNotificationPolicies,
  setContactPointsLoading,
  setDataSubscriptionsLoading,
  setNotificationPoliciesLoading,
} = actions;
export default reducer;
