import React, { useState } from 'react';

import {
  ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
} from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import Log from '~/utils/Log';
import ArrayUtils from '~/utils/arrayUtils';

// This is a separate accordion because the styling of the InvoiceCheck component has some special requirements
// regarding the accordion (mainly the css height).
const Accordion = styled((props) => <MuiAccordion elevation={0} {...props} />)(
  ({ theme }) => ({
    width: '100%',
    '&:before': {
      display: 'none',
    },
  }),
);
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: '8px' }}
        className="main-text"
      />
    }
    {...props}
  />
))(({ theme }) => ({
  padding: 0,
  height: 'fit-content',
  minHeight: 0,
  backgroundColor: '#E4E6ED',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '&.Mui-expanded': {
    height: 'fit-content',
    minHeight: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionSummary-expandIcon': {
    height: '24px',
  },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '0',
  paddingTop: 10,
  backgroundColor: '#E4E6ED',
}));

function HeaderAccordion(props) {
  const [expanded, setExpanded] = useState(props.expandedDefault ?? false);

  const onChange = () => {
    if (expanded) {
      Log.productAnalyticsEvent(
        'Close ' + props.productAnalyticsFeature,
        Log.FEATURE.INVOICE_CHECK,
      );
    } else {
      Log.productAnalyticsEvent(
        'Open ' + props.productAnalyticsFeature,
        Log.FEATURE.INVOICE_CHECK,
      );
    }

    setExpanded(!expanded);
  };

  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon className="text-black" />}>
        <Typography className="w-full" component={'span'}>
          <div className="flex-sb-c text-14px w-full">{props.title}</div>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className="w-full" component={'span'}>
          {props.children}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

function InvoiceCheck(props) {
  // Only display the body (= details) of the accordion if the necessary data to construct the body is given.
  // Currently, all accordions of invoice checks are formatted as lists. Hence, the construction of the list is directly moved to the InvoiceCheck component.
  const accordionDetails =
    props.checkResults && props.checkResultFormatter ? (
      <div className="flex-s-s text-14px">
        {ArrayUtils.split(props.checkResults, 2).map((checkResults, index) => (
          <ul key={index} className="w-half">
            {checkResults.map((check, index) => (
              <li key={index}>{props.checkResultFormatter(check)}</li>
            ))}
          </ul>
        ))}
      </div>
    ) : null;

  // Only display the body (= details) of the accordion if there would be any content.
  return (
    <div className="bg-grey100 rounded-5px text-14px p-10px flex-sb-c gap-30px w-full">
      {/* Should pass productAnalyticsFeature from parent component but this would require to update all calls of InvoiceCheck which would be quite some effort */}
      {accordionDetails ? (
        <HeaderAccordion
          title={props.children}
          expandedDefault={props.expandedDefault}
        >
          {accordionDetails}
        </HeaderAccordion>
      ) : null}
      {accordionDetails ? null : props.children}
    </div>
  );
}

export default withErrorBoundary(
  InvoiceCheck,
  'Prüfungsschritt konnte nicht geladen werden.',
);
