import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { withErrorBoundary } from '~/ui/atoms';

import FeatureService from '~/services/feature.service';
import UserUtils from '~/utils/userUtils';

import ConfigUtils from '~/utils/configUtils';

import { AssignSiteButton } from './AssignSiteButton';
import { BlacklistSiteButton } from './BlacklistSiteButton';
import { CreateDeliveryNoteButton } from './CreateDeliveryNoteButton';
import { DownloadPDFButton } from './DownloadPDFButton';
import { OpenDataQualityViewButton } from './OpenDataQualityViewButton';
import { OpenDeliveryNoteButton } from './OpenDeliveryNoteButton';
import { RequestSignatureButton } from './RequestSignatureButton';
import { ShareDeliveryNoteButton } from './ShareDeliveryNoteButton';
import { SignInAppButton } from './SignInAppButton';
import { ViewJSONDataButton } from './ViewJSONDataButton';
import { ImportIntoConcreteDiaryButton } from './ImportIntoConcreteDiaryButton';

const DeliveryNoteButtonsRaw = ({ deliveryNote, refreshDeliveryNote }) => {
  const { id } = useParams();

  const userFeatureFlags = useSelector(
    (state) => state.userinfo.userinfo.userFeatureFlags,
  );

  const displayConcreteDiaryImportButton = () => {
    if (
      !FeatureService.concreteDiary() ||
      FeatureService.clientPortal() ||
      FeatureService.packageBasicRestriction()
    ) {
      return false;
    }

    if (
      !UserUtils.isConcreteDiaryAllowedUser() &&
      !userFeatureFlags.concreteDiary
    ) {
      return false;
    }

    const dlnContainsConcrete = deliveryNote.articles.some((article) =>
      article.isConcrete(),
    );

    return !dlnContainsConcrete;
  };

  const can = {
    createDeliveryNote: Boolean(FeatureService.createDeliveryNote()),
    importConcrete: displayConcreteDiaryImportButton(),
    viewJSONData: UserUtils.isVestigasAccount(),
    viewDataQuality: ConfigUtils.isDevelopmentStagingEnvironment(),
  };

  return (
    <div className="flex-c-c gap-20px">
      <AssignSiteButton
        id={id}
        deliveryNote={deliveryNote}
        refreshDeliveryNote={refreshDeliveryNote}
      />
      <BlacklistSiteButton
        deliveryNote={deliveryNote}
        refreshDeliveryNote={refreshDeliveryNote}
      />
      <OpenDeliveryNoteButton id={id} />
      <SignInAppButton id={id} />
      <RequestSignatureButton id={id} userActions={deliveryNote.userActions} />
      <ShareDeliveryNoteButton
        id={id}
        deliveryNote={deliveryNote}
        refreshDeliveryNote={refreshDeliveryNote}
      />
      <DownloadPDFButton id={id} />
      {can.createDeliveryNote ? <CreateDeliveryNoteButton id={id} /> : null}
      {can.importConcrete ? (
        <ImportIntoConcreteDiaryButton deliveryNote={deliveryNote} />
      ) : null}
      {can.viewDataQuality ? <OpenDataQualityViewButton id={id} /> : null}
      {can.viewJSONData ? <ViewJSONDataButton id={id} /> : null}
    </div>
  );
};

export const DeliveryNoteButtons = withErrorBoundary(
  DeliveryNoteButtonsRaw,
  null,
);
