import { Stack, Chip } from '@mui/material';

import BasicForm from './BasicForm';

const ArchivedTableNoticeModal = ({
  isFeatureDisabled,
  isUserDisabled,
  nonApplicableFilters,
  onAccept,
  onReject,
  open,
}) => {
  const hasNonApplicableFilters = nonApplicableFilters.length > 0;

  const getBody = () => {
    if (isFeatureDisabled) {
      return (
        <div>
          Diese Funktion steht für das Archiv derzeit leider noch nicht zur
          Verfügung.
        </div>
      );
    }

    if (isUserDisabled) {
      return (
        <div>
          Dieser Zeitraum greift auf das Archiv zu. Das Archiv ist derzeit noch
          nicht verfügbar, aber wird zeitnah für dich freigeschaltet.
        </div>
      );
    }

    return (
      <>
        <div>
          {hasNonApplicableFilters
            ? 'Für diesen Zeitraum greifst du auf das Archiv zu. Es müssen folgende Filter entfernt werden:'
            : 'Für diesen Zeitraum greifst du auf das Archiv zu. Es stehen eventuell nicht alle Funktionen zur Verfügung.'}
        </div>
        {hasNonApplicableFilters && (
          <div>
            <Stack
              flexDirection="row"
              gap={2}
              className="w-500px mt-10px"
              flexWrap="wrap"
            >
              {nonApplicableFilters.map((filter, index) => (
                <Chip variant="outlined" label={`${filter}`} id={index} />
              ))}
            </Stack>
            <div className="h-1px bg-grey200 mt-10px w-full" />
          </div>
        )}
        <div className="mt-10px">
          Außerdem dauert das Laden von Lieferscheinen länger.
        </div>
        <div className="mt-10px">Willst du fortfahren?</div>
      </>
    );
  };

  return (
    <BasicForm
      title="Archiv"
      submitButtonTitle={
        isUserDisabled || isFeatureDisabled ? 'Ok' : 'Fortfahren'
      }
      open={open}
      formAbort={onReject}
      formSuccess={(event) => {
        event.preventDefault();
        event.stopPropagation();
        onAccept();
      }}
    >
      {getBody()}
    </BasicForm>
  );
};

export default ArchivedTableNoticeModal;
