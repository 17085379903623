import React from 'react';

import { Remove as RemoveIcon } from '@mui/icons-material';

import {
  ConsignmentIcon,
  ShipFromLightIcon,
  ShipFromToLightIcon,
  ShipToLightIcon,
} from '~/assets/icons';

import DeliveryNote from '~/models/deliveries/DeliveryNote';

import { LightTooltip } from '~/utils/componentUtils';

export const DeliveryCategoryIcon = ({ category, className = 'size-16' }) => {
  switch (category) {
    case DeliveryNote.PROCESS_CATEGORY.INTERNAL_DELIVERY.KEY: {
      return (
        <LightTooltip
          title={DeliveryNote.PROCESS_CATEGORY.INTERNAL_DELIVERY.STRING}
        >
          <span>
            <ShipFromToLightIcon className={className} />
          </span>
        </LightTooltip>
      );
    }

    case DeliveryNote.PROCESS_CATEGORY.SUPPLIER.KEY: {
      return (
        <LightTooltip title={DeliveryNote.PROCESS_CATEGORY.SUPPLIER.STRING}>
          <span>
            <ShipFromLightIcon className={className} />
          </span>
        </LightTooltip>
      );
    }

    case DeliveryNote.PROCESS_CATEGORY.CARRIER.KEY: {
      return (
        <LightTooltip title={DeliveryNote.PROCESS_CATEGORY.CARRIER.STRING}>
          <span>
            <ConsignmentIcon className={className} />
          </span>
        </LightTooltip>
      );
    }

    case DeliveryNote.PROCESS_CATEGORY.RECIPIENT.KEY: {
      return (
        <LightTooltip title={DeliveryNote.PROCESS_CATEGORY.RECIPIENT.STRING}>
          <span>
            <ShipToLightIcon className={className} />
          </span>
        </LightTooltip>
      );
    }

    default: {
      return (
        <LightTooltip title={DeliveryNote.PROCESS_CATEGORY.NONE.STRING}>
          <span>
            <RemoveIcon fontSize="small" />
          </span>
        </LightTooltip>
      );
    }
  }
};
