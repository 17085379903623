import { TextField } from '@mui/material';

export default function ArticleTextField(props) {
  const error = Boolean(props.error);

  return (
    <div className="rounded-4px relative bg-white">
      {props.autofillvalue?.startsWith(props.value) ? (
        <span
          className="user-select-none text-mui-placeholder-grey absolute"
          style={{
            fontSize: '16px',
            left: '10px',
            top: '5px',
          }}
        >
          {props.autofillvalue}
        </span>
      ) : null}
      <TextField
        {...props}
        value={props.value ?? ''}
        placeholder={props.autofillvalue ? null : props.placeholder}
        variant="outlined"
        fullWidth
        size="small"
        error={error}
        inputProps={{
          style: {
            padding: '5px 10px 5px 10px',
            textAlign: props.textend ? 'end' : 'start',
          },
        }}
        InputProps={error ? null : { style: { border: '0 !important' } }}
        autoComplete="off"
      />
    </div>
  );
}
