import React from 'react';

import {
  Add as AddIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import { Button, ButtonGroup } from '@mui/material';

import Log from '~/utils/Log';
import UserService from '~/services/user.service';
import SiteService from '~/services/site.service';
import ToastService from '~/services/toast.service';
import { BurgerMenu } from '../BurgerMenu';
import { Spinner } from '../Spinner';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import { promiseHandler } from '~/utils/promiseHandler';
import CSVValidator from '~/models/masterdata/CSVValidator';
import Site from '~/models/masterdata/Site';
import User from '~/models/masterdata/User';
import BrowserUtils from '~/utils/browserUtils';
import CostCenterService from '~/services/costCenter.service';

class SettingsCSVUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCSVUploading: false,
      controlledCSVValue: '', // if input value is uncontrolled, it isn't possible to select the same image two times in a row
      csvUploadMode: null,
    };

    this.inputFile = React.createRef();
  }

  handleClick = (event) => {
    switch (event) {
      case 'csv-upload-with-cost-centers': {
        Log.info('Upload CSV with cost centers');
        Log.productAnalyticsEvent(
          'Upload CSV with cost centers',
          Log.FEATURE.SETTINGS_TABLE,
        );
        this.inputFile.current.click();
        this.setState({
          csvUploadMode: 'csv-upload-with-cost-centers',
        });

        break;
      }

      case 'csv-upload': {
        Log.info('Upload CSV');
        Log.productAnalyticsEvent('Upload CSV', Log.FEATURE.SETTINGS_TABLE);
        this.inputFile.current.click();
        this.setState({
          csvUploadMode: 'csv-upload',
        });

        break;
      }

      case 'csv-rules-examples': {
        Log.info('Open CSV rules and examples');
        Log.productAnalyticsEvent(
          'Open CSV rules and examples',
          Log.FEATURE.SETTINGS_TABLE,
        );
        BrowserUtils.openNewTab(
          'https://www.notion.so/vestigas/README-26-01-2024-5cda2267e5794ed1bf62fa8e74dca27d',
        );

        break;
      }
      // No default
    }
  };
  handleCSVFileUploadSubmit = async (event) => {
    this.setState({
      isCSVUploading: true,
    });

    let csvCallback = null;

    switch (this.props.entity) {
      case 'user': {
        csvCallback = UserService.createUsersViaCSV;
        break;
      }

      case 'site': {
        if (this.state.csvUploadMode === 'csv-upload-with-cost-centers') {
          csvCallback = SiteService.createSitesAndCostCentersViaCSV;
        }

        if (this.state.csvUploadMode === 'csv-upload') {
          csvCallback = SiteService.createSitesViaCSV;
        }

        break;
      }

      default: {
        Log.error(
          null,
          new EnumValueNotFoundException(
            'Invalid entity type: ' + this.props.entity,
          ),
        );
      }
    }

    if (!csvCallback) {
      this.setState({
        isCSVUploading: false,
      });
      return;
    }

    const file = event.target.files[0];

    let allowedColumns = [];

    if (this.props.entity === 'user') {
      allowedColumns = User.CSV_ALLOWED_COLUMNS;
    }

    if (
      this.props.entity === 'site' &&
      this.state.csvUploadMode === 'csv-upload-with-cost-centers'
    ) {
      allowedColumns = Site.CSV_WITH_COST_CENTERS_ALLOWED_COLUMNS;
    }

    if (
      this.props.entity === 'site' &&
      this.state.csvUploadMode === 'csv-upload'
    ) {
      allowedColumns = Site.CSV_ALLOWED_COLUMNS;
    }

    const csvValidator = new CSVValidator(allowedColumns, file);

    const [response, error] = await promiseHandler(csvValidator.initFile());

    if (error) {
      Log.error('Failed to upload CSV.', error);
      Log.productAnalyticsEvent(
        'Failed to upload CSV',
        Log.FEATURE.SETTINGS_TABLE,
        Log.TYPE.ERROR,
      );
      ToastService.error(['CSV Datei konnte nicht hochgeladen werden.']);
      this.setState({
        isCSVUploading: false,
      });
      return;
    }

    const validationError = csvValidator.validate()[1];

    if (validationError) {
      Log.info('Invalid CSV: ' + validationError);
      Log.productAnalyticsEvent(
        'Invalid CSV: ' + validationError,
        Log.FEATURE.SETTINGS_TABLE,
      );
      ToastService.error([validationError]);
      this.setState({
        isCSVUploading: false,
      });
      return;
    }

    const [response2, error2] = await promiseHandler(csvCallback(file));

    if (error2) {
      if (error2.response.status === 409) {
        ToastService.httpError(
          [
            'Standorte und Kostenstellen konnten nicht angelegt werden, da eine Kostenstelle bereits im VESTIGAS System vorhanden ist.',
          ],
          error2.response,
        );
        Log.productAnalyticsEvent(
          'Failed to upload CSV due to duplicate cost center',
          Log.FEATURE.SETTINGS_TABLE,
          Log.TYPE.ERROR,
        );
      } else {
        ToastService.httpError(
          ['CSV Datei konnte nicht hochgeladen werden.'],
          error2.response,
        );
        Log.productAnalyticsEvent(
          'Failed to upload CSV',
          Log.FEATURE.SETTINGS_TABLE,
          Log.TYPE.ERROR,
        );
        Log.error('Failed to upload csv.', error2);
      }
    }

    this.setState({
      isCSVUploading: false,
      controlledCSVValue: '',
    });

    switch (this.props.entity) {
      case 'user': {
        UserService.refreshUsers();
        break;
      }

      case 'site': {
        SiteService.refreshSites();
        if (this.state.csvUploadMode === 'csv-upload-with-cost-centers') {
          CostCenterService.refreshCostCenters();
        }

        break;
      }
    }
  };

  getOptions() {
    const options = [];

    if (this.props.entity === 'site') {
      options.push({
        id: 'csv-upload-with-cost-centers',
        name: 'CSV Upload mit Kostenstellen',
      });
    }

    options.push(
      { id: 'csv-upload', name: 'CSV Upload' },
      { id: 'csv-rules-examples', name: 'CSV Regeln und Beispiele' },
    );

    return options;
  }

  render() {
    return (
      <>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button
            className="primary-button"
            startIcon={this.state.isCSVUploading ? null : <AddIcon />}
            onClick={this.props.openCreateForm}
            loading={Boolean(this.state.isCSVUploading)}
            disabled={this.state.isCSVUploading}
          >
            {this.state.isCSVUploading ? (
              <Spinner title="Erstellen..." />
            ) : (
              'Erstellen'
            )}
          </Button>
          <BurgerMenu
            className="primary-button"
            id="settings-table-create-burger-menu"
            title={<KeyboardArrowDownIcon />}
            options={this.getOptions()}
            menuId="settings-table-create-burger-menu"
            onClick={this.handleClick}
            disabled={this.state.isCSVUploading}
          />
        </ButtonGroup>
        <input
          ref={this.inputFile}
          value={this.state.controlledCSVValue}
          type="file"
          className="hidden"
          accept=".csv"
          id="csv-file"
          onChange={this.handleCSVFileUploadSubmit}
        />
      </>
    );
  }
}

export default SettingsCSVUpload;
