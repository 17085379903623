import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATE } from '~/constants/LoadingState';
import ArrayUtils from '~/utils/arrayUtils';

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    usersLoading: LOADING_STATE.NOT_LOADED,
  },
  reducers: {
    replaceUsers(state, { payload }) {
      state.users = ArrayUtils.sortByKey(payload, 'email');
      state.usersLoading = LOADING_STATE.SUCCEEDED;
    },
    replaceUser(state, { payload }) {
      const newUsers = ArrayUtils.removeByKey(state.users, 'id', payload.id);
      newUsers.push(payload);

      state.users = newUsers;
    },
    setUsersLoading(state, { payload }) {
      state.usersLoading = payload;
    },
  },
});

const { actions, reducer } = usersSlice;
export const { replaceUser, replaceUsers, setUsersLoading } = actions;
export default reducer;
