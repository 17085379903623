import { useDispatch, useSelector } from 'react-redux';
import { OutgoingInvoiceOutlinedIcon } from '~/assets/icons';
import { dateUtils } from '~/utils/dateUtils';
import { ROUTE } from '~/constants/Route';
import NumberTile from './NumberTile';
import ArrayUtils from '~/utils/arrayUtils';
import { withErrorBoundary } from '~/ui/atoms';
import InvoiceCheckResult from '~/models/invoices/InvoiceCheckResult';
import {
  setInvoice_dateRange,
  setInvoice_individualDateRange,
  setInvoice_predefinedDateRange,
} from '~/redux/filtersSlice';

const OutgoingInvoiceTile = (props) => {
  const dispatch = useDispatch();

  const { filteredOutgoingInvoices, outgoingInvoicesLoading } = useSelector(
    ({ invoices }) => invoices,
  );
  const selectedPredefinedDateRange = useSelector(
    ({ filters }) => filters.home_selectedPredefinedDateRange,
  );
  const selectedDateRange = useSelector(
    ({ filters }) => filters.home_selectedDateRange,
  );
  const individualDateRangeIsSelected = useSelector(
    ({ filters }) => filters.home_individualDateRange,
  );

  const filteredInvoices = filteredOutgoingInvoices.filter(
    ({ date }) =>
      Date.parse(date) >= props.timeframe.from &&
      Date.parse(date) <= props.timeframe.to,
  );

  const lastInvoiceDate = ArrayUtils.sortByKey(
    filteredOutgoingInvoices,
    'date',
    true,
  )[0]?.date;
  const formattedLastInvoiceDate = dateUtils.getFormattedDate_safe(
    lastInvoiceDate,
    dateUtils.DATE_FORMAT.DD_MM_YYYY,
  );

  const openInvoices = () => {
    dispatch(setInvoice_predefinedDateRange(selectedPredefinedDateRange));
    dispatch(
      setInvoice_dateRange({
        dateRange: selectedDateRange,
        updateCookie: true,
      }),
    );
    dispatch(setInvoice_individualDateRange(individualDateRangeIsSelected));
  };

  return (
    <NumberTile
      title={'Ausgangsrechnungen'}
      icon={<OutgoingInvoiceOutlinedIcon className="icon-medium" />}
      loading={outgoingInvoicesLoading}
      subheader={
        formattedLastInvoiceDate
          ? 'Letzter Ausgang: ' + formattedLastInvoiceDate
          : null
      }
      buttonTitle="Zu den Ausgangsrechnungen"
      buttonLink={ROUTE.OUTGOING_INVOICES.ROUTE}
      buttonCallback={openInvoices}
      firstNumberTitle="Gesamt"
      firstNumber={filteredInvoices.length}
      secondNumberTitle="Fehlerhaft"
      secondNumber={
        filteredInvoices.filter(({ status }) =>
          [
            InvoiceCheckResult.STATUS.ERROR,
            InvoiceCheckResult.STATUS.WARNING,
          ].includes(status),
        ).length
      }
    />
  );
};

export default withErrorBoundary(OutgoingInvoiceTile, null);
