import React from 'react';

import { Button, Popover } from '@mui/material';

import { Spinner } from './Spinner';
import { MissingPermissionsTooltip } from '~/utils/componentUtils';

class BasicPopover extends React.Component {
  constructor(props) {
    super(props);
  }

  getSubmitButton() {
    const submitButtonTitle = this.props.submitButtonTitle ?? 'Speichern';

    const button = (
      <Button
        variant="contained"
        color="primary"
        className={
          this.props.missingPermissionsToSubmit
            ? 'disabled-primary-button'
            : 'primary-button'
        }
        type="submit"
        disabled={
          this.props.submittingForm ||
          this.props.disableSubmitButton ||
          this.props.missingPermissionsToSubmit
        }
      >
        {this.props.submittingForm ? (
          <Spinner white title={submitButtonTitle + '...'} />
        ) : (
          submitButtonTitle
        )}
      </Button>
    );

    if (this.props.missingPermissionsToSubmit) {
      return <MissingPermissionsTooltip>{button}</MissingPermissionsTooltip>;
    }

    return button;
  }

  render() {
    return (
      <Popover
        open={this.props.open}
        anchorEl={this.props.anchorEl}
        onClose={this.props.onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <form onSubmit={this.props.formSuccess}>
          <div className="p-20px pb-80px min-w-300px">
            {' '}
            {/* Min width to prevent that footer buttons don't fit the whole row */}
            {this.props.children}
          </div>
          <div className="flex-sb-c sticky-dialog-footer pr-20px pl-20px w-full">
            <div>{this.props.customDeleteButton}</div>
            <div>
              <Button
                variant="text"
                className="mr-30px"
                onClick={this.props.onClose}
              >
                Abbrechen
              </Button>
              {this.getSubmitButton()}
            </div>
          </div>
        </form>
      </Popover>
    );
  }
}

export default BasicPopover;
