import { createSlice } from '@reduxjs/toolkit';
import ArrayUtils from '~/utils/arrayUtils';
import { LOADING_STATE } from '~/constants/LoadingState';

const articleMasterSlice = createSlice({
  name: 'articleMaster',
  initialState: {
    articles: [],
    articlesLoading: LOADING_STATE.NOT_LOADED,
    categories: [],
    categoriesLoading: LOADING_STATE.NOT_LOADED,
  },
  reducers: {
    replaceArticles(state, parameter) {
      const { payload } = parameter;

      state.articles = ArrayUtils.sortByKey(payload, 'articleId');
      state.articlesLoading = LOADING_STATE.SUCCEEDED;
    },
    replaceArticle(state, parameter) {
      const { payload } = parameter;

      const newArticles = ArrayUtils.removeByKey(
        state.articles,
        'id',
        payload.id,
      );
      newArticles.push(payload);

      state.articles = newArticles;
    },
    setArticlesLoading(state, parameter) {
      const { payload } = parameter;

      state.articlesLoading = payload;
    },
    replaceCategories(state, parameter) {
      const { payload } = parameter;

      state.categories = ArrayUtils.sortByKey(payload, 'name');
      state.categoriesLoading = LOADING_STATE.SUCCEEDED;
    },
    replaceCategory(state, parameter) {
      const { payload } = parameter;

      const newCategories = ArrayUtils.removeByKey(
        state.categories,
        'id',
        payload.id,
      );
      newCategories.push(payload);

      state.categories = newCategories;
    },
    setCategoriesLoading(state, parameter) {
      const { payload } = parameter;

      state.categoriesLoading = payload;
    },
  },
});

const { actions, reducer } = articleMasterSlice;
export const {
  replaceArticle,
  replaceArticles,
  replaceCategories,
  replaceCategory,
  setArticlesLoading,
  setCategoriesLoading,
} = actions;
export default reducer;
