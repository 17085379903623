import React from 'react';
import { withErrorBoundary } from '~/ui/atoms';
import { InputLabel } from '@mui/material';
import { dateUtils } from '~/utils/dateUtils';

class DeliveryNoteActionMetaData extends React.Component {
  render() {
    return (
      <div>
        <InputLabel className="text-13px mb-5px">{this.props.title}</InputLabel>
        <div className="rounded-3px border-grey400 p-5px text-grey400 w-full">
          <ul>
            {this.props.users.map((user) => (
              <li key={user.email}>
                {this.props.withoutNameAndEmail ? null : (
                  <span className="mr-20px">
                    {user.email}
                    {<span className="text-grey400 ml-20px">{user.name}</span>}
                  </span>
                )}
                {user.count ? (
                  <span className="mr-20px">({user.count + ' mal'})</span>
                ) : null}
                {user.date ? (
                  <span>
                    (zuletzt am{' '}
                    {dateUtils.getFormattedDate(
                      user.date,
                      dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
                    )}
                    )
                  </span>
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default withErrorBoundary(DeliveryNoteActionMetaData, null);
