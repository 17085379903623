import React from 'react';

import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  Grid,
  TextField,
} from '@mui/material';

import { connect } from 'react-redux';
import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';
import BasicForm from '~/components/BasicForm';
import cloneDeep from 'lodash/cloneDeep';
import ToastService from '~/services/toast.service';
import Select from '~/components/baseComponents/inputs/select/Select';
import ArticleMasterService from '~/services/articleMaster.service';
import Category from '~/models/articleMaster/Category';
import FunctionUtils from '~/utils/functionUtils';
import GenericMultiPicker from '~/components/baseComponents/inputs/select/GenericMultiPicker';
import Article from '~/models/articleMaster/Article';
import ImageUpload from '~/components/ImageUpload';
import { validate as uuidvalidate } from 'uuid';
import Company from '~/models/masterdata/Company';
import Site from '~/models/masterdata/Site';
import CostCenter from '~/models/masterdata/CostCenter';

const mapStateToProps = (state) => ({
  articleMaster: state.articleMaster,
  companies: state.companies,
  sites: state.sites,
  costCenters: state.costCenters,
  userinfo: state.userinfo,
});
const mapDispatchToProps = () => ({});

const NEW_OPTION_TYPE = {
  COMPANY: 'company',
  SITE: 'site',
  COST_CENTER: 'cost_center',
};

class CategoryForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      category: new Category(),
      submittingForm: false,
      deletingForm: false,
      categoryIcon: null,
      categoryIconHasBeenUpdated: false,
    };
  }

  componentDidMount() {
    this.resetForm();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.resetForm();

      if (!this.renderForCreate()) {
        this.loadCategoryIcon();
      }
    }
  }

  loadCategoryIcon = async () => {
    const [categoryIcon, error] = await promiseHandler(
      ArticleMasterService.getCategoryIcon(this.props.category.id, true),
    );

    if (error) {
      ToastService.httpError(
        ['Kategorielogo konnte nicht geladen werden.'],
        error.response,
        ToastService.TYPE.WARNING,
      );
      Log.error(
        'Failed to load category icon. category id: ' + this.props.category.id,
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to load category icon',
        Log.FEATURE.ARTICLE_MASTER,
        Log.TYPE.ERROR,
      );
      return;
    }

    this.setState({
      categoryIcon,
    });
  };
  uploadCategoryIcon = async (picture, categoryId = this.props.category.id) => {
    const url = URL.createObjectURL(picture);
    const img = new Image();
    img.src = url;

    img.addEventListener('load', async function () {
      const [_, error] = await promiseHandler(
        ArticleMasterService.updateCategoryIcon({ categoryId, picture }),
      );

      if (error) {
        ToastService.httpError(
          [
            'Kategorielogo konnte nicht aktualisiert werden.',
            error?.response?.data?.detail,
          ],
          error.response,
          ToastService.TYPE.WARNING,
        );
        Log.error('Failed to upload cateory icon.', error);
        Log.productAnalyticsEvent(
          'Failed to upload cateory icon',
          Log.FEATURE.ARTICLE_MASTER,
          Log.TYPE.ERROR,
        );
      }
    });

    this.setState({
      categoryIcon: picture,
    });
  };
  removeCategoryIcon = async () => {
    const [response, error] = await promiseHandler(
      ArticleMasterService.deleteCategoryIcon(this.props.category.id),
    );

    if (error) {
      ToastService.httpError(
        ['Kategorielogo konnte nicht entfernt werden.'],
        error.response,
      );
      Log.error('Failed to delete category icon', error);
      Log.productAnalyticsEvent(
        'Failed to delete category icon',
        Log.FEATURE.ARTICLE_MASTER,
        Log.TYPE.ERROR,
      );
      return;
    }

    this.setState({
      categoryIcon: null,
    });
  };

  async resetForm() {
    this.setState({
      category: this.props.category ?? new Category(),
      categoryIcon: null,
      categoryIconHasBeenUpdated: false,
    });
  }

  setCategoryIcon = (picture) => {
    this.setState({
      categoryIcon: picture,
      categoryIconHasBeenUpdated: true,
    });
  };
  formSuccess = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      submittingForm: true,
    });

    const body = {
      name: this.state.category.name,
      sub_categories: this.state.category.subCategories,
      parent_categories: this.state.category.parentCategories,
      linked_articles: this.state.category.linkedArticles,
      is_active: this.state.category.active,
      // Pass company account of user as default
      owner_company_accounts: this.state.category.ownerCompanyAccounts,
      owner_companies: this.state.category.ownerCompanies,
      access: this.state.category.access,
      filter_companies: this.state.category.filterCompanies,
      filter_sites: this.state.category.filterSites,
      filter_acc_refs: this.state.category.filterCostCenters,
    };

    Log.info('Submit category form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.ARTICLE_MASTER);

    if (this.renderForCreate()) {
      const [categoryId, error] = await promiseHandler(
        ArticleMasterService.createCategory(body),
      );

      if (error) {
        Log.error('Failed to create category.', error);
        ToastService.httpError(
          ['Kategorie konnte nicht angelegt werden.'],
          error.response,
        );
        Log.productAnalyticsEvent(
          'Failed to create',
          Log.FEATURE.ARTICLE_MASTER,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }

      if (this.state.categoryIcon) {
        this.uploadCategoryIcon(this.state.categoryIcon, categoryId);
      }
    } else {
      const [response, error] = await promiseHandler(
        ArticleMasterService.updateCategory(this.props.category.id, body),
      );

      if (error) {
        Log.error(
          'Failed to update category. id: ' + this.props.category.id,
          error,
        );
        ToastService.httpError(
          ['Kategorie konnte nicht aktualisiert werden.'],
          error.response,
        );
        Log.productAnalyticsEvent(
          'Failed to update',
          Log.FEATURE.ARTICLE_MASTER,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }

      if (this.state.categoryIcon) {
        this.uploadCategoryIcon(this.state.categoryIcon);
      } else {
        this.removeCategoryIcon();
      }
    }

    this.setState({
      submittingForm: false,
    });

    this.props.closeForm();
    this.resetForm();
    ArticleMasterService.refreshCategories(
      this.props.userinfo.userinfo.company.companyAccount,
      this.props.userinfo.userinfo.company.id,
    );
  };
  formAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.ARTICLE_MASTER);
    this.props.closeForm();
    this.resetForm();
  };
  formDelete = async (event) => {
    event.preventDefault();

    Log.info(
      'Delete category',
      { id: this.props.category.id },
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Delete', Log.FEATURE.ARTICLE_MASTER);

    this.setState({
      deletingForm: true,
    });

    const [response, error] = await promiseHandler(
      ArticleMasterService.deleteCategory(this.props.category.id),
    );

    if (error) {
      ToastService.httpError(
        ['Kategorie konnte nicht gelöscht werden.'],
        error.response,
      );
      Log.error('Failed to delete category.', error);
      Log.productAnalyticsEvent(
        'Failed to delete',
        Log.FEATURE.ARTICLE_MASTER,
        Log.TYPE.ERROR,
      );
      this.setState({
        deletingForm: false,
      });
      return;
    }

    this.setState({
      deletingForm: false,
    });

    this.props.closeForm();
    this.resetForm();
    await ArticleMasterService.refreshCategories(
      this.props.userinfo.userinfo.company.companyAccount,
      this.props.userinfo.userinfo.company.id,
    );
  };
  renderForCreate = () => {
    return this.props.type === 'create';
  };
  handleInputChange = (event) => {
    const newCategory = cloneDeep(this.state.category);

    switch (event.target.name) {
      case 'name': {
        newCategory.name = event.target.value;
        Log.info(
          'Change form value of name',
          { from: this.state.category.name, to: newCategory.name },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'category_change_name',
          Log.productAnalyticsEvent,
          ['Change name', Log.FEATURE.ARTICLE_MASTER],
        );
        break;
      }
    }

    this.setState({
      category: newCategory,
    });
  };
  handleCheckboxChange = (event) => {
    const newCategory = cloneDeep(this.state.category);

    switch (event.target.name) {
      case 'is_active': {
        newCategory.active = event.target.checked;
        Log.info(
          'Change form value of active',
          { from: this.state.category.active, to: newCategory.active },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change active checkbox',
          Log.FEATURE.ARTICLE_MASTER,
        );
        break;
      }

      case 'owner_company_accounts': {
        newCategory.ownerCompanyAccounts = event.target.checked
          ? [this.props.userinfo.userinfo.company.companyAccount]
          : [];
        Log.info(
          'Change form value of owner company account',
          {
            from: this.state.category.ownerCompanyAccounts,
            to: newCategory.ownerCompanyAccounts,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change owner company account checkbox',
          Log.FEATURE.ARTICLE_MASTER,
        );
        break;
      }
    }

    this.setState({
      category: newCategory,
    });
  };
  handleChangeParentCategories = (parentCategories) => {
    const newCategory = cloneDeep(this.state.category);

    newCategory.parentCategories = parentCategories.map(
      (category) => category.id,
    );

    Log.info(
      'Change form value of parent categories',
      {
        from: this.state.category.parentCategories,
        to: newCategory.parentCategories,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change parent categories',
      Log.FEATURE.ARTICLE_MASTER,
    );

    this.setState({
      category: newCategory,
    });
  };
  handleChangeSubCategories = (subCategories) => {
    const newCategory = cloneDeep(this.state.category);

    newCategory.subCategories = subCategories.map((category) => category.id);

    Log.info(
      'Change form value of sub categories',
      {
        from: this.state.category.subCategories,
        to: newCategory.subCategories,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change sub categories',
      Log.FEATURE.ARTICLE_MASTER,
    );

    this.setState({
      category: newCategory,
    });
  };
  handleChangeLinkedArticles = (linkedArticles) => {
    const newCategory = cloneDeep(this.state.category);

    newCategory.linkedArticles = linkedArticles.map((article) => article.id);

    Log.info(
      'Change form value of linked articles',
      {
        from: this.state.category.linkedArticles,
        to: newCategory.linkedArticles,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change linked articles',
      Log.FEATURE.ARTICLE_MASTER,
    );

    this.setState({
      category: newCategory,
    });
  };
  handleChangeOwnerCompanies = (ownerCompanies) => {
    const newCategory = cloneDeep(this.state.category);

    newCategory.ownerCompanies = ownerCompanies.map((company) => company.id);

    Log.info(
      'Change form value of owner companies',
      {
        from: this.state.category.ownerCompanies,
        to: newCategory.ownerCompanies,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change owner companies',
      Log.FEATURE.ARTICLE_MASTER,
    );

    this.setState({
      category: newCategory,
    });
  };
  handleChangeAccess = (event) => {
    const newCategory = cloneDeep(this.state.category);

    newCategory.access = event.target.value;

    Log.info(
      'Change form value of access',
      { from: this.state.category.access, to: newCategory.access },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change access', Log.FEATURE.ARTICLE_MASTER);

    this.setState({
      category: newCategory,
    });
  };
  handleChangeFilterCompanies = (filterCompanies) => {
    const newCategory = cloneDeep(this.state.category);

    newCategory.filterCompanies = filterCompanies.map((company) => company.id);

    Log.info(
      'Change form value of filter companies',
      {
        from: this.state.category.filterCompanies,
        to: newCategory.filterCompanies,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change filter companies',
      Log.FEATURE.ARTICLE_MASTER,
    );

    this.setState({
      category: newCategory,
    });
  };
  handleChangeFilterSites = (filterSites) => {
    const newCategory = cloneDeep(this.state.category);

    newCategory.filterSites = filterSites.map((site) => site.id);

    Log.info(
      'Change form value of filter sites',
      { from: this.state.category.filterSites, to: newCategory.filterSites },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change filter sites',
      Log.FEATURE.ARTICLE_MASTER,
    );

    this.setState({
      category: newCategory,
    });
  };
  handleChangeFilterCostCenters = (filterCostCenters) => {
    const newCategory = cloneDeep(this.state.category);

    newCategory.filterCostCenters = filterCostCenters.map(
      (costCenter) => costCenter.id,
    );

    Log.info(
      'Change form value of filter cost centers',
      {
        from: this.state.category.filterCostCenters,
        to: newCategory.filterCostCenters,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change filter cost centers',
      Log.FEATURE.ARTICLE_MASTER,
    );

    this.setState({
      category: newCategory,
    });
  };

  getUnsavedChanges() {
    if (this.renderForCreate()) {
      return [];
    }

    const unsavedChanges = Category.getDifferentValues(
      this.props.category,
      this.state.category,
    );
    if (this.state.categoryIconHasBeenUpdated) {
      unsavedChanges.push('Kategorielogo');
    }

    return unsavedChanges;
  }

  handleNewOptionCreate(option, type) {
    if (uuidvalidate(option.inputValue)) {
      switch (type) {
        case NEW_OPTION_TYPE.COMPANY: {
          return new Company({
            id: option.inputValue,
            name: option.inputValue,
          });
        }

        case NEW_OPTION_TYPE.SITE: {
          return new Site({ id: option.inputValue, name: option.inputValue });
        }

        case NEW_OPTION_TYPE.COST_CENTER: {
          return new CostCenter({
            id: option.inputValue,
            name: option.inputValue,
          });
        }
      }
    } else {
      ToastService.error(['Ungültige ID: Bitte gib eine gültige UUID ein.']);
      Log.productAnalyticsEvent(
        'Invalid UUID',
        Log.FEATURE.ARTICLE_MASTER,
        Log.TYPE.ERROR,
      );
      return null;
    }
  }

  getFilterHint() {
    if (
      this.state.category?.filterCompanies?.length > 0 ||
      this.state.category?.filterSites?.length > 0 ||
      this.state.category?.filterCostCenters?.length > 0
    ) {
      return (
        <div className="text-12px text-grey500 mb-10px">Exklusiv für...</div>
      );
    }

    if (this.state.category?.access === Article.ACCESS.PRIVATE.KEY) {
      return (
        <div className="text-12px text-grey500 mb-10px">
          Diese Kategorie hat aktuell keine Beschränkungen und wird jedem Nutzer
          der oben ausgewählten Firma / Firmenaccount angezeigt.
        </div>
      );
    }

    return (
      <div className="text-12px text-grey500 mb-10px">
        Diese Kategorie hat aktuell keine Beschränkungen und wird jedem Nutzer
        in VESTIGAS angezeigt.
      </div>
    );
  }

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        formDelete={this.renderForCreate() ? null : this.formDelete}
        title={
          'Kategorie ' +
          (this.renderForCreate() ? 'Erstellen' : this.props.category.name)
        }
        fullWidth
        submittingForm={this.state.submittingForm}
        deletingForm={this.state.deletingForm}
        unsavedChanges={this.getUnsavedChanges()}
        id={this.props.category?.id}
      >
        <Grid container direction="row">
          <Grid item xs={12} lg={8}>
            <Grid container direction="row" spacing={3} space={4}>
              <Grid item xs={12}>
                <h3 className="main-text mt-0">Kategorie</h3>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      id="name-input"
                      name="name"
                      label="Name"
                      type="text"
                      required
                      fullWidth
                      value={this.state.category?.name}
                      onChange={this.handleInputChange}
                      autoComplete="off"
                    />
                  </Grid>
                  {this.renderForCreate() ? null : (
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.category?.active}
                            onChange={this.handleCheckboxChange}
                            name="is_active"
                          />
                        }
                        label="Aktiv"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <h3 className="mt-20px main-text">
                  Verknüpfte Kategorien und Artikel
                </h3>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <GenericMultiPicker
                      textfieldLabel="Oberkategorien"
                      pickedItemIds={
                        this.state.category?.parentCategories ?? []
                      }
                      allItems={this.props.articleMaster.categories.filter(
                        (category) => category.id !== this.props.category?.id,
                      )}
                      callbackPickedItems={this.handleChangeParentCategories}
                      loading={this.props.articleMaster.categoriesLoading}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <GenericMultiPicker
                      textfieldLabel="Unterkategorien"
                      pickedItemIds={this.state.category?.subCategories ?? []}
                      allItems={this.props.articleMaster.categories.filter(
                        (category) => category.id !== this.props.category?.id,
                      )}
                      callbackPickedItems={this.handleChangeSubCategories}
                      loading={this.props.articleMaster.categoriesLoading}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <GenericMultiPicker
                      textfieldLabel="Enthaltene Artikel"
                      pickedItemIds={this.state.category?.linkedArticles ?? []}
                      allItems={this.props.articleMaster.articles.map(
                        (article) => {
                          return {
                            ...article,
                            nameComponent:
                              article.articleId + ' ' + article.name,
                          };
                        },
                      )}
                      callbackPickedItems={this.handleChangeLinkedArticles}
                      loading={this.props.articleMaster.articlesLoading}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} className="flex-c-c flexdir-column mt-50px">
            <InputLabel id="demo-multiple-name-label" className="text-13px">
              Kategorielogo
            </InputLabel>
            <ImageUpload
              image={this.state.categoryIcon}
              setImage={this.setCategoryIcon}
              onDelete={() => this.setCategoryIcon(null)}
              uploadText="Fügen hier das Kategorielogo hinzu."
            />
          </Grid>
          <Grid item xs={12}>
            <h3 className="mt-20px main-text">Zugeordnet zu</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8} className="flex-s-c gap-20px">
                <div className="w-300px">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          this.state.category?.ownerCompanyAccounts?.length > 0
                        }
                        onChange={this.handleCheckboxChange}
                        name="owner_company_accounts"
                      />
                    }
                    label="Firmen-Account"
                  />
                </div>
                <GenericMultiPicker
                  textfieldLabel="Zu welchen Firmen soll die Kategorie zugeordnet sein?"
                  pickedItemIds={this.state.category?.ownerCompanies ?? []}
                  allItems={this.props.companies.companies}
                  callbackPickedItems={this.handleChangeOwnerCompanies}
                  loading={this.props.companies.companiesLoading}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">Berechtigung</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <Select
                  value={this.state.category?.access}
                  fullWidth
                  onChange={this.handleChangeAccess}
                  size="small"
                  options={Article.getAccessEnum()}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">
              Diese Kategorie soll exklusiv sein?
            </h3>
            {this.getFilterHint()}
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <GenericMultiPicker
                  textfieldLabel="Firmen"
                  pickedItemIds={this.state.category?.filterCompanies ?? []}
                  allItems={this.props.companies.companies}
                  callbackPickedItems={this.handleChangeFilterCompanies}
                  loading={this.props.companies.companiesLoading}
                  onNewOptionCreate={(option) =>
                    this.handleNewOptionCreate(option, NEW_OPTION_TYPE.COMPANY)
                  }
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <GenericMultiPicker
                  textfieldLabel="Standorte"
                  pickedItemIds={this.state.category?.filterSites ?? []}
                  allItems={this.props.sites.sites}
                  callbackPickedItems={this.handleChangeFilterSites}
                  loading={this.props.sites.sitesLoading}
                  onNewOptionCreate={(option) =>
                    this.handleNewOptionCreate(option, NEW_OPTION_TYPE.SITE)
                  }
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <GenericMultiPicker
                  textfieldLabel="Kostenstellen"
                  pickedItemIds={this.state.category?.filterCostCenters ?? []}
                  allItems={this.props.costCenters.costCenters}
                  callbackPickedItems={this.handleChangeFilterCostCenters}
                  loading={this.props.costCenters.costCentersLoading}
                  onNewOptionCreate={(option) =>
                    this.handleNewOptionCreate(
                      option,
                      NEW_OPTION_TYPE.COST_CENTER,
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BasicForm>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(CategoryForm);
