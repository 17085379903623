import PermissionGrant from './PermissionGrant';
import ArrayUtils from '~/utils/arrayUtils';

export default class UserGroup {
  constructor(userGroup) {
    this.id = userGroup?.id ?? null;
    this.name = userGroup?.name ?? null;

    this.parentUserGroups = userGroup?.parent_user_groups ?? [];
    this.childUserGroups = userGroup?.member_user_groups ?? [];

    this.organisationalGroups = userGroup?.org_units ?? [];
    this.organisationalGroupPaths = userGroup?.org_unit_paths ?? [];
    this.users = userGroup?.users ?? [];
    this.userGroupPaths = userGroup?.user_group_paths ?? [];

    this.permissionGrantsOn =
      userGroup?.permissions_on?.map(
        (permissionGrant) =>
          new PermissionGrant({
            ...permissionGrant,
            subject_type: PermissionGrant.SUBJECT_TYPE.USER_GROUP.KEY,
            subject_id: this.id,
          }),
      ) ?? [];
    this.permissionGrantsFrom =
      userGroup?.permissions_from?.map(
        (permissionGrant) =>
          new PermissionGrant({
            ...permissionGrant,
            target_type: PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY,
            target_id: this.id,
          }),
      ) ?? [];
  }

  static getDifferentValues(userGroupA, userGroupB) {
    const differentValues = [];

    if (userGroupA?.id !== userGroupB?.id) {
      differentValues.push('ID');
    }

    if (userGroupA?.name !== userGroupB?.name) {
      differentValues.push('Name');
    }

    const differentParentUserGroups = ArrayUtils.getDifference(
      userGroupA?.parentUserGroups,
      userGroupB?.parentUserGroups,
    );
    if (
      differentParentUserGroups[0].length > 0 ||
      differentParentUserGroups[1].length > 0
    ) {
      differentValues.push('Benutzer-Gruppen (Ist Teil von...)');
    }

    const differentChildUserGroups = ArrayUtils.getDifference(
      userGroupA?.childUserGroups,
      userGroupB?.childUserGroups,
    );
    if (
      differentChildUserGroups[0].length > 0 ||
      differentChildUserGroups[1].length > 0
    ) {
      differentValues.push('Benutzer-Gruppen');
    }

    const differentUsers = ArrayUtils.getDifference(
      userGroupA?.users,
      userGroupB?.users,
    );
    if (differentUsers[0].length > 0 || differentUsers[1].length > 0) {
      differentValues.push('Benutzer');
    }

    const differentOrganisationalGroups = ArrayUtils.getDifference(
      userGroupA?.organisationalGroups,
      userGroupB?.organisationalGroups,
    );
    if (
      differentOrganisationalGroups[0].length > 0 ||
      differentOrganisationalGroups[1].length > 0
    ) {
      differentValues.push('Organisations-Gruppen');
    }

    return differentValues;
  }
}
