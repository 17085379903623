export function promiseHandler(promise) {
  try {
    return promise.then((data) => [data, null]).catch((error) => [null, error]);
  } catch (error) {
    return [null, error];
  }
}

/**
 * Attempts to execute the given promise and returns its result or error.
 * Behaves like the safeAssignment operator proposal.
 *
 * @param {function} promise - A function that returns a promise.
 * @return {array} An array where the first element is the error (if any) and the second element is the result of the promise.
 */
export const handlePromise = async (promise) => {
  let result = null;
  let error = null;

  try {
    result = await promise();
  } catch (error_) {
    error = error_;
  }

  return [error, result];
};
