import cloneDeep from 'lodash/cloneDeep';

import FilterProps from '~/models/filters/FilterProps';
import FilterContext from '~/models/filters/FilterContext';

import DeliveriesService from '~/services/deliveries.service';

import { LOADING_STATE } from '~/constants/LoadingState';

import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

/**
 * Load possible values (suggestions) for a filter (filter row) within a filter group from the backend.
 * @param {string} filterRowId: id of the filter row
 * @param {string} searchValue: free text search value
 * @param {number} offset: offset of the suggestions that have been loaded already
 * @param {boolean} ignoreCache
 * @returns
 */
export const loadDlnFilterSuggestions = async (
  filterRowId,
  searchValue,
  offset,
  ignoreCache,
  state,
  setState,
  props,
  getAppliedFilters,
) => {
  const newSelectableFilters = cloneDeep(state.selectableFilters);
  const index = newSelectableFilters.findIndex(({ id }) => id === filterRowId);

  const appliedFiltersAreCached =
    JSON.stringify(state.cachedSuggestions[filterRowId]?.appliedFilters) ===
    JSON.stringify(getAppliedFilters(filterRowId));
  const searchValueIsCached =
    state.cachedSuggestions[filterRowId]?.searchValue === searchValue;

  if (
    !ignoreCache &&
    appliedFiltersAreCached &&
    searchValueIsCached &&
    state.cachedSuggestions[filterRowId]?.suggestions?.length > offset
  ) {
    const suggestions = state.cachedSuggestions[filterRowId].suggestions;

    newSelectableFilters[index].allOptions = suggestions;
    newSelectableFilters[index].filteredOptions = suggestions;

    setState({
      selectableFilters: newSelectableFilters,
    });

    return;
  }

  if (offset === 0) {
    newSelectableFilters[index].loading = LOADING_STATE.LOADING;
  } else {
    newSelectableFilters[index].paginationLoading = LOADING_STATE.LOADING;
  }

  setState({
    selectableFilters: newSelectableFilters,
  });

  const [suggestions, error] = await promiseHandler(
    DeliveriesService.loadDlnFilterSuggestions({
      dateRange: props.dateRange,
      entity: FilterProps.getBackendFilterFromFilterGroupFilter(
        filterRowId,
        FilterContext.PAGE.DELIVERY,
      ),
      filterGroups: {
        processStates: props.selectedProcessState,
        acceptStates: [],
        settledStatus: [],
        toSiteRecipients: props.selectedToSiteRecipient,
        toSiteSuppliers: props.selectedToSiteSupplier,
        articles: props.selectedArticle,
        suppliers: props.selectedSupplier,
        recipients: props.selectedRecipient,
        permittedToSites: props.selectedPermittedToSites,
        permittedCostCenters: props.selectedPermittedCostCenters,
        selectedSites: props.selectedSites,
        selectedCostCenters: props.selectedCostCenters,
      },
      offset,
      searchValue,
    }),
  );

  if (suggestions) {
    if (offset === 0) {
      newSelectableFilters[index].loading = LOADING_STATE.SUCCEEDED;
      newSelectableFilters[index].allOptions = suggestions;
      newSelectableFilters[index].filteredOptions = suggestions;
    } else {
      newSelectableFilters[index].paginationLoading = LOADING_STATE.SUCCEEDED;
      newSelectableFilters[index].allOptions = [
        ...newSelectableFilters[index].allOptions,
        ...suggestions,
      ];
      newSelectableFilters[index].filteredOptions = [
        ...newSelectableFilters[index].filteredOptions,
        ...suggestions,
      ];
    }

    const newCachedSuggestions = cloneDeep(state.cachedSuggestions);
    newCachedSuggestions[filterRowId] ||= {};
    newCachedSuggestions[filterRowId].suggestions =
      newSelectableFilters[index].allOptions;
    newCachedSuggestions[filterRowId].offset = offset;
    newCachedSuggestions[filterRowId].searchValue = searchValue;
    newCachedSuggestions[filterRowId].appliedFilters =
      getAppliedFilters(filterRowId);
    setState({
      cachedSuggestions: newCachedSuggestions,
    });
  } else if (error) {
    Log.error('Failed to load suggestions for filter row.', error);
    newSelectableFilters[index].loading = LOADING_STATE.FAILED;
  }

  setState({
    selectableFilters: newSelectableFilters,
  });
};
