import { useSelector } from 'react-redux';

import OrganisationalGroup from '~/models/masterdata/OrganisationalGroup';

import OrganisationalGroupService from '~/services/organisationalGroup.service';

import Path from './Path';

export const OrganisationalGroupPaths = ({
  id,
  onOpenOrganisationalGroup,
  organisationalGroupPaths,
}) => {
  const { organisationalGroupsLoading } = useSelector(
    (state) => state.organisationalGroups,
  );

  if (!organisationalGroupPaths?.length) {
    return null;
  }

  return (
    <div>
      <div className="bold">Teil der Organisations-Gruppen:</div>
      <div className="flexdir-column gap-10px mt-10px flex">
        {organisationalGroupPaths.map((organisationalGroupPath, index) => {
          const pathId = `organisational-group-path-${id}-${index}-${JSON.stringify(
            organisationalGroupPath,
          )}`;

          return (
            <Path
              key={pathId}
              id={pathId}
              path={organisationalGroupPath}
              onOpenEntity={onOpenOrganisationalGroup}
              model={OrganisationalGroup}
              getEntityById={
                OrganisationalGroupService.getOrganisationalGroupById
              }
              loading={organisationalGroupsLoading}
            />
          );
        })}
      </div>
    </div>
  );
};
