import React from 'react';
import { connect } from 'react-redux';
import UserService from '~/services/user.service';
import { withErrorBoundary } from '~/ui/atoms';
import { Spinner } from '../Spinner';
import DeliveryTile from './DeliveryTile';
import ChartTile from './ChartTile';
import DateRangeSelect from '../baseComponents/inputs/date/DateRangeSelect';
import {
  setHome_selectedPredefinedDateRange,
  setHome_selectedDateRange,
  setHome_individualDateRange,
} from '~/redux/filtersSlice';
import {
  setFilteredDeliveryNotes,
  setArchiveDeliveryNotesCount,
  setArchiveDeliveryNotesCountLoading,
} from '~/redux/homeSlice';
import Log from '~/utils/Log';
import { dateUtils } from '~/utils/dateUtils';
import { ROUTE } from '~/constants/Route';
import UserTile from './UserTile';
import User from '~/models/masterdata/User';
import SupportTile from './SupportTile';
import IncomingInvoiceTile from './IncomingInvoiceTile';
import OutgoingInvoiceTile from './OutgoingInvoiceTile';
import LocalStorageService from '~/services/localStorage.service';
import { LOADING_STATE } from '~/constants/LoadingState';
import FeatureService from '~/services/feature.service';
import ClientPortalTooltip from '../salesPackages/clientPortal/ClientPortalTooltip';
import SalesTile from './SalesTile';
import { setPageTitle } from '~/redux/menuSlice';
import UserUtils from '~/utils/userUtils';
import InvoicesListTile from './InvoicesListTile';
import { promiseHandler } from '~/utils/promiseHandler';
import DeliveriesService from '~/services/deliveries.service';
import PromiseUtils from '~/utils/promiseUtils';
import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';
import FilterContext from '~/models/filters/FilterContext';
import { HomeTestIds } from '~/constants/test-ids';

const mapStateToProps = (state) => ({
  userinfo: state.userinfo,
  selectedDateRange: state.filters.home_selectedDateRange,
  selectedPredefinedDateRange: state.filters.home_selectedPredefinedDateRange,
  individualDateRange: state.filters.home_individualDateRange,
  selectedSites: state.filters.selectedSites,
  selectedCostCenters: state.filters.selectedCostCenters,
  invoices: state.invoices,
  deliveryNotes: state.deliveryNotes,
  sites: state.sites,
  // subscribe to companyAccount state so that update of clientPortal feature flag leads to rerender
  companyAccount: state.companyAccount,
  oldestFilteredDlnDate: state.filters.oldestFilteredDlnDate,
  home: state.home,
});
const mapDispatchToProps = () => ({
  setHome_selectedDateRange,
  setHome_selectedPredefinedDateRange,
  setHome_individualDateRange,
  setPageTitle,
  setFilteredDeliveryNotes,
  setArchiveDeliveryNotesCount,
  setArchiveDeliveryNotesCountLoading,
});

class Home extends React.Component {
  constructor(props) {
    super(props);

    // If the requests are already stale, the response is thrown away.
    this.filterId = 0;
  }

  componentDidMount() {
    this.setInvoiceTileCookies();
    this.loadDeliveryNoteData();

    this.props.setPageTitle('Home');
    document.title = 'VESTIGAS - Home';
  }

  componentDidUpdate(prevProps) {
    this.setInvoiceTileCookies();

    if (
      JSON.stringify(this.props.selectedDateRange) !==
        JSON.stringify(prevProps.selectedDateRange) ||
      JSON.stringify(this.props.selectedSites) !==
        JSON.stringify(prevProps.selectedSites) ||
      JSON.stringify(this.props.selectedCostCenters) !==
        JSON.stringify(prevProps.selectedCostCenters) ||
      JSON.stringify(this.props.oldestFilteredDlnDate) !==
        JSON.stringify(prevProps.oldestFilteredDlnDate) ||
      // Listening to userinfo.company is necessary because based on the user company, we load data directly
      // from the backend (i.e. archive) or we wait for the init of the dlns.
      this.props.userinfo.userinfo.company?.id !==
        prevProps.userinfo.userinfo.company?.id ||
      (this.props.deliveryNotes.filteredDeliveryNotesVersion !==
        prevProps.deliveryNotes.filteredDeliveryNotesVersion &&
        !this.isArchiveMode())
    ) {
      this.loadDeliveryNoteData();
    }
  }

  isArchiveMode() {
    // If not all parameters are given (e.g. dlns haven't been fetched via bulk load),
    // we want to instantly load the data from the backend so that the user doesn't have to wait.
    // However, if the user is not allowed to access the archive,
    // we are not loading the data from the backend (i.e. archive).
    const defaultIsArchiveMode = UserUtils.isArchiveModeAllowedUser();

    return DeliveriesService.isArchiveMode(
      this.props.selectedDateRange,
      defaultIsArchiveMode,
    );
  }

  setInvoiceTileCookies() {
    if (
      this.props.invoices.incomingInvoices.length > 0 &&
      !LocalStorageService.getBooleanFromLocalStorage(
        LocalStorageService.DISPLAY_HOME_INCOMING_INVOICE_TILE,
      )
    ) {
      LocalStorageService.setLocalStorage(
        LocalStorageService.DISPLAY_HOME_INCOMING_INVOICE_TILE,
        LocalStorageService.TRUE,
      );
    }

    if (
      this.props.invoices.outgoingInvoices.length > 0 &&
      !LocalStorageService.getBooleanFromLocalStorage(
        LocalStorageService.DISPLAY_HOME_OUTGOING_INVOICE_TILE,
      )
    ) {
      LocalStorageService.setLocalStorage(
        LocalStorageService.DISPLAY_HOME_OUTGOING_INVOICE_TILE,
        LocalStorageService.TRUE,
      );
    }
  }

  // Loading dlns for DeliveryTile and data for ChartTile is inconsistent.
  // dlns for DeliveryTile are loaded in Home.js while data for ChartTile is loaded in ChartTile.js.
  // This is because filtering the data was also done like this before the webapp-scaling feature.
  // In the future, this should be made consistent. Consider that unit from ChartTile needs to be taken as a parameter.
  // TODO mgottelt refactor in the future
  loadDeliveryNoteData() {
    if (this.isArchiveMode()) {
      // When one of the filters has changed, the filterId is updated so that outdated requests can be detected.
      this.filterId++;
      this.loadArchiveDeliveryNoteData(this.filterId);
    } else {
      this.filterDeliveryNotes();
    }
  }

  async loadArchiveDeliveryNoteData(filterId) {
    if (
      JSON.stringify(this.getAppliedFilters()) ===
      JSON.stringify(this.props.home.archiveDeliveryNotesCountAppliedFilters)
    ) {
      return;
    }

    this.props.setArchiveDeliveryNotesCountLoading(LOADING_STATE.LOADING);

    const archiveDeliveryNotesCountPromise = DeliveriesService.countDlns(
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      this.props.selectedSites,
      this.props.selectedCostCenters,
      this.props.selectedDateRange,
      null,
      this.props.selectedFromSite,
    );
    const archiveDeclinedDeliveryNotesCountPromise =
      DeliveriesService.countDlns(
        [],
        [AcceptStateCalculator.ACCEPT_STATE.DECLINED],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        this.props.selectedSites,
        this.props.selectedCostCenters,
        this.props.selectedDateRange,
        null,
        this.props.selectedFromSite,
      );

    const [results, error] = await promiseHandler(
      PromiseUtils.allResolved([
        archiveDeliveryNotesCountPromise,
        archiveDeclinedDeliveryNotesCountPromise,
      ]),
    );

    // If the filterId has been updated in the meantime, the request is outdated and the response is thrown away.
    if (this.filterId !== filterId) {
      return;
    }

    if (error) {
      Log.error('Error while loading archive delivery notes count', error);
      this.props.setArchiveDeliveryNotesCountLoading(LOADING_STATE.FAILED);
      return;
    }

    this.props.setArchiveDeliveryNotesCount({
      archiveDeliveryNotesCount: results[0].value,
      archiveDeclinedDeliveryNotesCount: results[1].value,
      appliedFilters: this.getAppliedFilters(),
    });
  }

  filterDeliveryNotes() {
    const { from, to } = dateUtils.extractTimeframe(
      this.props.selectedDateRange,
    );

    const filteredDeliveryNotes =
      this.props.deliveryNotes.filteredDeliveryNotes.filter(
        ({ dlnDate }) =>
          Date.parse(dlnDate) >= from && Date.parse(dlnDate) <= to,
      );

    this.props.setFilteredDeliveryNotes(filteredDeliveryNotes);
  }

  getAppliedFilters() {
    return {
      selectedSites: this.props.selectedSites,
      selectedCostCenters: this.props.selectedCostCenters,
      selectedDateRange: this.props.selectedDateRange,
    };
  }

  handleDateRangeChange = (value) => {
    const numberOfDays = dateUtils.getNumberOfDays(value[0], value[1]);

    if (!(numberOfDays >= 0 && numberOfDays <= 3650)) {
      return;
    }

    Log.info(
      'Change filter value of selected date range',
      { from: this.props.selectedDateRange, to: value },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Filter date range', Log.FEATURE.HOME);

    this.props.setHome_selectedDateRange(value);
    this.props.setHome_individualDateRange(true);
  };
  handlePredefinedDateRangeChange = (value) => {
    Log.info(
      'Change filter value of selected predefined date range',
      { from: this.props.selectedPredefinedDateRange, to: value },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Filter predefined date range: ' + value,
      Log.FEATURE.HOME,
    );

    this.props.setHome_selectedPredefinedDateRange(value);
    this.props.setHome_selectedDateRange(
      dateUtils.getTimeframeFromDateRange(value),
    );
    this.props.setHome_individualDateRange(false);
  };

  // div widths are calculated from widths of tiles:
  // 750px = 2 grids + 1 spacing = 360px * 2 + 30px
  tiles() {
    const timeframe = dateUtils.extractTimeframe(this.props.selectedDateRange);

    if (window.innerWidth <= 1500) {
      return (
        <div
          className="flex-s-c gap-30px mb-2rem flex-wrap"
          style={{ maxWidth: '750px' }}
        >
          {UserService.userIsAuthorizedForPage(
            this.props.userinfo.userPermissions,
            ROUTE.DELIVERIES.ROUTE,
          ) ? (
            <DeliveryTile isArchiveMode={this.isArchiveMode()} />
          ) : null}
          {UserService.userIsAuthorizedForPage(
            this.props.userinfo.userPermissions,
            ROUTE.INCOMING_INVOICES.ROUTE,
          ) &&
          (this.props.invoices.incomingInvoices.length > 0 ||
            LocalStorageService.getLocalStorage(
              LocalStorageService.DISPLAY_HOME_INCOMING_INVOICE_TILE,
            )) &&
          !FeatureService.clientPortal() &&
          !FeatureService.moduleInvoiceRestriction() ? (
            <IncomingInvoiceTile timeframe={timeframe} />
          ) : null}
          {UserService.userIsAuthorizedForPage(
            this.props.userinfo.userPermissions,
            ROUTE.DASHBOARD.ROUTE,
          ) ? (
            <ChartTile isArchiveMode={this.isArchiveMode()} />
          ) : null}
          {UserUtils.isDelayedSignatureHomeScreenAllowedUser(
            this.props.userinfo.userinfo.id,
            ROUTE.DELIVERIES.ROUTE,
          ) ? (
            <InvoicesListTile
              type="missing_signatures"
              timeframe={timeframe}
              history={this.props.history}
            />
          ) : null}
          {UserUtils.isDelayedSignatureHomeScreenAllowedUser(
            this.props.userinfo.userinfo.id,
            ROUTE.DELIVERIES.ROUTE,
          ) ? (
            <InvoicesListTile
              type="delayed_signed"
              timeframe={timeframe}
              history={this.props.history}
            />
          ) : null}
          <UserTile />
          {FeatureService.clientPortal() ? <SalesTile /> : null}
          <SupportTile />
          {UserService.userIsAuthorizedForPage(
            this.props.userinfo.userPermissions,
            ROUTE.OUTGOING_INVOICES.ROUTE,
          ) &&
          (this.props.invoices.outgoingInvoices.length > 0 ||
            LocalStorageService.getLocalStorage(
              LocalStorageService.DISPLAY_HOME_OUTGOING_INVOICE_TILE,
            )) &&
          !FeatureService.clientPortal() &&
          !FeatureService.moduleInvoiceRestriction() ? (
            <OutgoingInvoiceTile timeframe={timeframe} />
          ) : null}
        </div>
      );
    }

    if (window.innerWidth <= 1830) {
      return (
        <div
          className="flex-s-c gap-30px mb-2rem flex-wrap"
          style={{ maxWidth: '1140px' }}
        >
          {UserService.userIsAuthorizedForPage(
            this.props.userinfo.userPermissions,
            ROUTE.DELIVERIES.ROUTE,
          ) ? (
            <DeliveryTile isArchiveMode={this.isArchiveMode()} />
          ) : null}
          {UserService.userIsAuthorizedForPage(
            this.props.userinfo.userPermissions,
            ROUTE.DASHBOARD.ROUTE,
          ) ? (
            <ChartTile isArchiveMode={this.isArchiveMode()} />
          ) : null}
          {UserService.userIsAuthorizedForPage(
            this.props.userinfo.userPermissions,
            ROUTE.INCOMING_INVOICES.ROUTE,
          ) &&
          (this.props.invoices.incomingInvoices.length > 0 ||
            LocalStorageService.getBooleanFromLocalStorage(
              LocalStorageService.DISPLAY_HOME_INCOMING_INVOICE_TILE,
            )) &&
          !FeatureService.clientPortal() &&
          !FeatureService.moduleInvoiceRestriction() ? (
            <IncomingInvoiceTile timeframe={timeframe} />
          ) : null}
          {UserUtils.isDelayedSignatureHomeScreenAllowedUser(
            this.props.userinfo.userinfo.id,
            ROUTE.DELIVERIES.ROUTE,
          ) ? (
            <InvoicesListTile
              type="missing_signatures"
              timeframe={timeframe}
              history={this.props.history}
            />
          ) : null}
          <UserTile />
          {UserUtils.isDelayedSignatureHomeScreenAllowedUser(
            this.props.userinfo.userinfo.id,
            ROUTE.DELIVERIES.ROUTE,
          ) ? (
            <InvoicesListTile
              type="delayed_signed"
              timeframe={timeframe}
              history={this.props.history}
            />
          ) : null}
          <SupportTile />
          {UserService.userIsAuthorizedForPage(
            this.props.userinfo.userPermissions,
            ROUTE.OUTGOING_INVOICES.ROUTE,
          ) &&
          (this.props.invoices.outgoingInvoices.length > 0 ||
            LocalStorageService.getBooleanFromLocalStorage(
              LocalStorageService.DISPLAY_HOME_OUTGOING_INVOICE_TILE,
            )) &&
          !FeatureService.clientPortal() &&
          !FeatureService.moduleInvoiceRestriction() ? (
            <OutgoingInvoiceTile timeframe={timeframe} />
          ) : null}
          {FeatureService.clientPortal() ? <SalesTile /> : null}
        </div>
      );
    }

    return (
      <div
        className="flex-s-c gap-30px mb-2rem flex-wrap"
        style={{ maxWidth: '1530px' }}
      >
        {UserService.userIsAuthorizedForPage(
          this.props.userinfo.userPermissions,
          ROUTE.DELIVERIES.ROUTE,
        ) ? (
          <DeliveryTile isArchiveMode={this.isArchiveMode()} />
        ) : null}
        {UserService.userIsAuthorizedForPage(
          this.props.userinfo.userPermissions,
          ROUTE.INCOMING_INVOICES.ROUTE,
        ) &&
        (this.props.invoices.incomingInvoices.length > 0 ||
          LocalStorageService.getBooleanFromLocalStorage(
            LocalStorageService.DISPLAY_HOME_INCOMING_INVOICE_TILE,
          )) &&
        !FeatureService.clientPortal() &&
        !FeatureService.moduleInvoiceRestriction() ? (
          <IncomingInvoiceTile timeframe={timeframe} />
        ) : null}
        {UserService.userIsAuthorizedForPage(
          this.props.userinfo.userPermissions,
          ROUTE.DASHBOARD.ROUTE,
        ) ? (
          <ChartTile isArchiveMode={this.isArchiveMode()} />
        ) : null}
        {UserUtils.isDelayedSignatureHomeScreenAllowedUser(
          this.props.userinfo.userinfo.id,
          ROUTE.DELIVERIES.ROUTE,
        ) ? (
          <InvoicesListTile
            type="missing_signatures"
            timeframe={timeframe}
            history={this.props.history}
          />
        ) : null}
        {UserUtils.isDelayedSignatureHomeScreenAllowedUser(
          this.props.userinfo.userinfo.id,
          ROUTE.DELIVERIES.ROUTE,
        ) ? (
          <InvoicesListTile
            type="delayed_signed"
            timeframe={timeframe}
            history={this.props.history}
          />
        ) : null}
        <UserTile />
        {FeatureService.clientPortal() ? <SalesTile /> : null}
        <SupportTile />
        {UserService.userIsAuthorizedForPage(
          this.props.userinfo.userPermissions,
          ROUTE.OUTGOING_INVOICES.ROUTE,
        ) &&
        (this.props.invoices.outgoingInvoices.length > 0 ||
          LocalStorageService.getBooleanFromLocalStorage(
            LocalStorageService.DISPLAY_HOME_OUTGOING_INVOICE_TILE,
          )) &&
        !FeatureService.clientPortal() &&
        !FeatureService.moduleInvoiceRestriction() ? (
          <OutgoingInvoiceTile timeframe={timeframe} />
        ) : null}
      </div>
    );
  }

  render() {
    if (this.props.userinfo.userPermissionsLoading === LOADING_STATE.LOADING) {
      return <Spinner title="Berechtigungen werden geladen..." />;
    }

    return (
      <div className="text-grey800 pl-10px pr-10px text-center">
        <div className="h-250px w-full">
          <div className="bg-primary100 rounded-bottom-10px h-full w-full" />
        </div>
        <div className="mt--220px pb-50px">
          {this.props.userinfo.userinfo.firstname ||
          this.props.userinfo.userinfo.lastname ? (
            <div
              className="text-4xl text-white"
              data-testid={HomeTestIds.WELCOME_MESSAGE}
            >
              WILLKOMMEN ZURÜCK,{' '}
              {User.formatName(
                this.props.userinfo.userinfo.firstname,
                this.props.userinfo.userinfo.lastname,
              )}
              !
            </div>
          ) : (
            <div className="text-36px text-white">WILLKOMMEN ZURÜCK!</div>
          )}
        </div>
        <div className="pl-1rem pr-1rem inline-block">
          <div className="mb-20px flex items-center justify-between gap-4">
            <span className="text-32px text-white">Neue Ereignisse</span>
            <div className="flex-c-c gap-20px">
              <ClientPortalTooltip>
                <DateRangeSelect
                  predefinedDateRange={this.props.selectedPredefinedDateRange}
                  individualDateRange={this.props.individualDateRange}
                  onPredefinedDateRangeChange={
                    this.handlePredefinedDateRangeChange
                  }
                  dateRange={this.props.selectedDateRange}
                  onDateRangeChange={this.handleDateRangeChange}
                  disabled={FeatureService.clientPortal()}
                  archiveMode
                  suppressArchiveModal
                  page={FilterContext.PAGE.HOME}
                />
              </ClientPortalTooltip>
            </div>
          </div>
          {this.tiles()}
        </div>
      </div>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(Home),
  'Startseite konnte nicht geladen werden.',
);
