import {
  PhoneOutlined as PhoneOutlinedIcon,
  EmailOutlined as EmailOutlinedIcon,
  PersonOutlined as PersonOutlinedIcon,
} from '@mui/icons-material';

export const CONTACT_CHANNEL_NAME = {
  email: 'E-Mail-Kontakt',
  personal: 'Persönlicher Kontakt',
  phone: 'Telefonischer Kontakt',
};

export const CONTACT_CHANNEL_ICON = {
  email: <EmailOutlinedIcon />,
  personal: <PersonOutlinedIcon />,
  phone: <PhoneOutlinedIcon />,
};
