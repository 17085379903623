import { bindActionCreators } from 'redux';

import {
  setFilterValue,
  // -----------------------------------------------------------------------
  setDelivery_dateRange,
  setDelivery_selectedAcceptState,
  setDelivery_selectedArticle,
  setDelivery_selectedArticleNumber,
  setDelivery_selectedCostCenter,
  setDelivery_selectedCustomFields,
  setDelivery_selectedFromSite,
  setDelivery_selectedNumber,
  setDelivery_selectedPermittedCostCenters,
  setDelivery_selectedPermittedToSites,
  setDelivery_selectedProcessState,
  setDelivery_selectedRecipient,
  setDelivery_selectedSettledStatus,
  setDelivery_selectedSupplier,
  setDelivery_selectedToSiteRecipient,
  setDelivery_selectedToSiteSupplier,
  setDeliveryList_filterModel,
} from '~/redux/filtersSlice';
import { setPageTitle } from '~/redux/menuSlice';

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFilterValue,
      // -----------------------------------------------------------------------
      setDelivery_dateRange,
      setDelivery_selectedAcceptState,
      setDelivery_selectedArticle,
      setDelivery_selectedArticleNumber,
      setDelivery_selectedCostCenter,
      setDelivery_selectedCustomFields,
      setDelivery_selectedFromSite,
      setDelivery_selectedNumber,
      setDelivery_selectedPermittedCostCenters,
      setDelivery_selectedPermittedToSites,
      setDelivery_selectedProcessState,
      setDelivery_selectedRecipient,
      setDelivery_selectedSettledStatus,
      setDelivery_selectedSupplier,
      setDelivery_selectedToSiteRecipient,
      setDelivery_selectedToSiteSupplier,
      setDeliveryList_filterModel,
      setPageTitle,
    },
    dispatch,
  );
