import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

export default class BilledItem {
  constructor(billedItem) {
    this.path = billedItem?.path ?? [];
    this.totalBilledQuotient = billedItem?.total_billed_quot
      ? Number(billedItem?.total_billed_quot)
      : 0;
    this.billingDetails =
      billedItem?.billing_details?.map((billing_detail) => {
        return {
          amount: {
            value: billing_detail?.billed_amount ?? null,
            unit: billing_detail?.billed_unit ?? null,
          },
          invoiceId: billing_detail?.invoice_uuid ?? null,
        };
      }) ?? [];
    this.invoiceIds = ArrayUtils.removeDuplicates(
      this.billingDetails
        .filter((billingDetail) => billingDetail.invoiceId)
        .map((billingDetail) => billingDetail.invoiceId),
    );
    this.settledStatus = this.getSettledStatus();
  }

  getSettledStatus() {
    if (!this.totalBilledQuotient) {
      return BilledItem.SETTLED_STATUS.NOT_SETTLED.KEY;
    }

    if (this.totalBilledQuotient === 1) {
      return BilledItem.SETTLED_STATUS.FULLY_SETTLED.KEY;
    }

    return BilledItem.SETTLED_STATUS.PARTLY_SETTLED.KEY;
  }

  static calculateCombinedSettledStatus(settledStatusList) {
    if (
      settledStatusList.every(
        (settledStatus) =>
          settledStatus === BilledItem.SETTLED_STATUS.NOT_SETTLED.KEY,
      )
    ) {
      return BilledItem.SETTLED_STATUS.NOT_SETTLED.KEY;
    }

    if (
      settledStatusList.every(
        (settledStatus) =>
          settledStatus === BilledItem.SETTLED_STATUS.FULLY_SETTLED.KEY,
      )
    ) {
      return BilledItem.SETTLED_STATUS.FULLY_SETTLED.KEY;
    }

    return BilledItem.SETTLED_STATUS.PARTLY_SETTLED.KEY;
  }

  static getSettledStatusDescription(key) {
    const settledStatus = Object.keys(BilledItem.SETTLED_STATUS).find(
      (x) => BilledItem.SETTLED_STATUS[x].KEY === key,
    );

    if (!settledStatus) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid settled status key: ' + key),
      );
      return null;
    }

    return BilledItem.SETTLED_STATUS[settledStatus].DESCRIPTION;
  }

  static getSettledStatusOptions() {
    return [
      BilledItem.SETTLED_STATUS.NOT_SETTLED.DESCRIPTION,
      BilledItem.SETTLED_STATUS.PARTLY_SETTLED.DESCRIPTION,
      BilledItem.SETTLED_STATUS.FULLY_SETTLED.DESCRIPTION,
    ];
  }

  static SETTLED_STATUS = {
    NOT_SETTLED: {
      KEY: 'not-settled',
      DESCRIPTION: 'Nicht abgerechnet',
    },
    PARTLY_SETTLED: {
      KEY: 'partly-settled',
      DESCRIPTION: 'Teilweise abgerechnet',
    },
    FULLY_SETTLED: {
      KEY: 'fully-settled',
      DESCRIPTION: 'Komplett abgerechnet',
    },
  };
}
