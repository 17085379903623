import { useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';

import {
  AccessTimeOutlined as AccessTimeOutlinedIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  MailOutlineOutlined as MailOutlineOutlinedIcon,
  PhoneOutlined as PhoneOutlinedIcon,
} from '@mui/icons-material';

import { EmailPlusIcon } from '~/assets/icons';

import Log from '~/utils/Log';
import DataExchangeService from '~/services/dataExchange.service';
import { promiseHandler } from '~/utils/promiseHandler';
import { dateUtils } from '~/utils/dateUtils';

import ToastService from '~/services/toast.service';

import { LOADING_STATE } from '~/constants/LoadingState';

import DataExchangeCompany from '~/models/dataExchanges/DataExchangeCompany';
import Config from '~/Config';

import { SUPPLIERS_STATUS_MAP } from '../../SupplierCard/constants';
import { generateMailToLink } from '../../SupplierCard/utils';

export const useContactPerson = ({
  contactLinkState,
  setSelectedPerson,
  supplier,
  updateContactSaveLink,
}) => {
  const [showModal, setShowModal] = useState(false);

  const exchange = supplier.dataExchange;

  const generateNewContactLink = async () => {
    Log.info('Generate new contact link', Log.BREADCRUMB.USER_ACTION.KEY);
    Log.productAnalyticsEvent(
      'Generate new contact link',
      Log.FEATURE.KANBAN_SUPPLIER_OVERVIEW,
    );

    updateContactSaveLink((previous) => ({
      ...previous,
      loadingState: LOADING_STATE.LOADING,
    }));

    const [contactLinkResponse, error] = await promiseHandler(
      DataExchangeService.createEmailWithRegistrationLink(exchange?.id),
    );

    if (error) {
      Log.error('Failed to create a contact link for data exchange', error);
      Log.productAnalyticsEvent(
        'Failed to create a contact link for data exchange',
        Log.FEATURE.KANBAN_SUPPLIER_OVERVIEW,
        Log.TYPE.ERROR,
      );

      ToastService.error([
        'Unable to create a new contact link for this supplier',
      ]);

      updateContactSaveLink((previous) => ({
        ...previous,
        loadingState: LOADING_STATE.NOT_LOADED,
      }));

      return;
    }

    updateContactSaveLink({
      data: contactLinkResponse,
      step: 'created',
      loadingState: LOADING_STATE.NOT_LOADED,
    });
    const emailLink = generateMailToLink({
      subject: `Vestigas - der digitale Lieferschein: Telefonat ${dateUtils.getFormattedDate(new Date(), dateUtils.DATE_FORMAT.DD_MM_YYYY)}`,
      body: `Sehr geehrte Frau xxxx,
Sehr geehrter Herr xxxx,

wie in unserem o.g. Telefonat besprochen und von Ihnen gewünscht, erhalten Sie nachfolgend den Link für Ihre Registrierung bei Vestigas.

Dieser Link ist für die Dauer von 96 Stunden gültig und kann nur einmal verwendet werden.

Bei Fragen melden Sie sich gerne jederzeit bei mir.

Link: ${Config.contactPageUrl}/?link=${contactLinkResponse.link}`,
    });

    window.location.href = emailLink;
  };

  const isInActiveColumn =
    supplier?.statusDeliveryNote === DataExchangeCompany.STATUS.ACTIVATED.KEY ||
    supplier?.statusDeliveryNote === DataExchangeCompany.STATUS.LIVE.KEY ||
    supplier?.statusDeliveryNote === DataExchangeCompany.STATUS.LIVE.KEY ||
    Boolean(exchange?.contactedPerson?.id);

  const statusContentMap = {
    'initial-step': (
      <>
        {!isInActiveColumn && (
          <>
            <Box marginTop={1}>
              <Typography className="semibold">
                Ansprechperson {supplier?.name}
              </Typography>
              <Typography>
                Füge diesem Lieferanten Ansprechpartner und verantwortliche
                Personen hinzu.
              </Typography>
            </Box>
            <Box marginTop={2}>
              <Button
                color="primary"
                variant="outlined"
                loading={
                  contactLinkState.loadingState === LOADING_STATE.LOADING
                }
                disabled={
                  contactLinkState.loadingState === LOADING_STATE.LOADING
                }
                onClick={(e) => {
                  e.stopPropagation();
                  generateNewContactLink();
                }}
              >
                <EmailPlusIcon />
                {contactLinkState.loadingState === LOADING_STATE.LOADING
                  ? 'Anmeldelink wird erstellt...'
                  : 'E-Mail mit Anmeldelink erstellen'}
              </Button>
            </Box>
          </>
        )}
      </>
    ),

    created: (
      <>
        <Box marginTop={1}>
          <Typography className="semibold">
            Ansprechperson {supplier?.name}
          </Typography>
        </Box>
        <Box
          marginTop={1}
          className="banner created"
          display="flex"
          alignItems="center"
          backgroundColor
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            {SUPPLIERS_STATUS_MAP.created.icon}
          </Box>
          <Typography>{SUPPLIERS_STATUS_MAP.created.message}</Typography>
        </Box>
        <Box marginTop={2}>
          <Box marginBottom={1}>
            <FormLabel className="default-form-label">Anmeldelink AP</FormLabel>
          </Box>
          <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item xs={9}>
              <TextField
                fullWidth
                variant="outlined"
                color="primary"
                disabled
                value={`${Config.contactPageUrl}/?link=${contactLinkState?.data?.link}`}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(
                          `${Config.contactPageUrl}/?link=${contactLinkState?.data?.link}`,
                        );
                        ToastService.info([
                          'Link wurde in die Zwischenablage kopiert',
                        ]);
                      }}
                      size="large"
                    >
                      <FileCopyOutlinedIcon
                        className="copy-svg"
                        htmlColor="#2453B2"
                      />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3} fullWidth>
              <Button
                color="primary"
                variant="outlined"
                fullWidth
                onClick={() => setShowModal(true)}
              >
                <EmailPlusIcon />
                Neue Mail
              </Button>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={9}>
              <Box
                bgcolor="#F2F4F7"
                display="flex"
                alignItems="center"
                flexDirection="row"
                padding={1}
                borderRadius="8px"
                marginTop={1}
              >
                <Box marginRight={1} display="flex" alignItems="center">
                  <AccessTimeOutlinedIcon />
                </Box>
                <Typography variant="body2">
                  Noch {contactLinkState?.data?.still_valid_for} Stunden gültig
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
    ),

    expired: (
      <>
        {!isInActiveColumn && (
          <>
            <Box marginTop={1}>
              <Typography className="semibold">
                Ansprechperson {supplier?.name}
              </Typography>
            </Box>
            <Box
              marginTop={1}
              className="banner expired"
              display="flex"
              alignItems="center"
              backgroundColor
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                {SUPPLIERS_STATUS_MAP.expired.icon}
              </Box>
              <Typography>{SUPPLIERS_STATUS_MAP.expired.message}</Typography>
            </Box>
            <Box marginTop={2}>
              <Button
                color="primary"
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  generateNewContactLink();
                }}
              >
                <EmailPlusIcon />
                Neue Mail mit Anmeldelink
              </Button>
            </Box>
          </>
        )}
      </>
    ),

    filled: (
      <>
        <Box marginTop={1}>
          <Typography className="semibold">
            Ansprechperson {supplier?.name}
          </Typography>
        </Box>
        <Box
          marginTop={1}
          className="banner filled"
          display="flex"
          alignItems="center"
          backgroundColor
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            {SUPPLIERS_STATUS_MAP.filled.icon}
          </Box>
          <Typography>{SUPPLIERS_STATUS_MAP.filled.message}</Typography>
        </Box>
        {Boolean(exchange.contactedPerson) && (
          <Box marginTop={2} className="user-contact-banner">
            <Avatar>
              <Typography variant="body2">
                {exchange?.contactedPerson?.first_name?.charAt(0)}
                {exchange?.contactedPerson?.last_name?.charAt(0)}
              </Typography>
            </Avatar>
            <Box>
              <Box display="flex" flexDirection="row" marginBottom={0.5}>
                <Typography variant="body2">
                  <b>
                    {exchange?.contactedPerson?.first_name}{' '}
                    {exchange?.contactedPerson?.last_name}
                  </b>
                  {exchange?.contactedPerson?.position && (
                    <>
                      <span className="spacer">&middot;</span>{' '}
                      {supplier.contactedPerson.position}
                    </>
                  )}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                {Boolean(exchange?.contactedPerson?.email) && (
                  <Typography variant="body2">
                    <MailOutlineOutlinedIcon fontSize="10px" />{' '}
                    {exchange?.contactedPerson?.email}
                  </Typography>
                )}
                {Boolean(exchange?.contactedPerson?.phone_number) && (
                  <>
                    <span className="spacer">&middot;</span>
                    <Typography variant="body2">
                      <PhoneOutlinedIcon fontSize="10px" />
                      {exchange.contactedPerson?.phone_number}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </>
    ),
  };

  const removeResponsiblePerson = async () => {
    const [, error] = await promiseHandler(
      DataExchangeService.removeResponsiblePerson({
        senderId: supplier?.dataExchange?.senderId,
        receiverId: supplier?.dataExchange?.receiverId,
      }),
    );

    if (error) {
      Log.info('Failed to update responsible person', error);
      Log.productAnalyticsEvent(
        'Failed to update repsonsible person',
        Log.FEATURE.KANBAN_SUPPLIER_OVERVIEW,
        Log.TYPE.ERROR,
      );
      ToastService.error(
        ['Unable to update resposible person, please try again'],
        error,
      );
      return;
    }

    setSelectedPerson('');
  };

  const updateResponsiblePerson = async (value) => {
    const [, error] = await promiseHandler(
      DataExchangeService.setResponsiblePerson({
        personId: value,
        exchangeId: exchange?.id,
      }),
    );

    if (error) {
      Log.info('Failed to update responsible person', error);
      Log.productAnalyticsEvent(
        'Failed to update repsonsible person',
        Log.FEATURE.KANBAN_SUPPLIER_OVERVIEW,
        Log.TYPE.ERROR,
      );
      ToastService.error(
        ['Unable to update resposible person, please try again'],
        error,
      );
      return;
    }

    setSelectedPerson(value);
  };

  return {
    removeResponsiblePerson,
    setShowModal,
    showModal,
    statusContentMap,
    updateResponsiblePerson,
  };
};
