import LocalStorageService from '~/services/localStorage.service';

import { dateUtils } from '~/utils/dateUtils';

/**
 * Determines the default date range for delivery notes.
 * Sets the to-date in the delivery list to the latest date of all delivery notes,
 * unless a filter was set already today.
 *
 * @param {Array} deliveryNotes - An array of delivery note objects.
 * @return {Object|undefined} An object containing the default date range and a flag to update the cookie, or undefined if no valid date range can be determined.
 */
export const getDefaultDateRange = (deliveryNotes) => {
  const cookie = LocalStorageService.getLocalStorage(
    LocalStorageService.FILTER_DELIVERY_DATE_RANGE,
  );

  // If cookie has been set manually by user today, don't set a default value.
  if (dateUtils.isToday(cookie?.creationDate)) {
    return;
  }

  const dlnDates = deliveryNotes.map(({ dlnDate }) => new Date(dlnDate));
  const filteredDlnDates = dlnDates.filter((dlnDate) =>
    dateUtils.isValidDate(dlnDate),
  );

  // If no valid dln date has been set, Math.max would not return a useful value.
  if (filteredDlnDates.length === 0) {
    return;
  }

  const maxDate = new Date(Math.max(...filteredDlnDates));

  // don't set default to date to earlier than today.
  if (maxDate < new Date()) {
    return;
  }

  const dateRange = dateUtils.getDateRangeFromCookies(
    LocalStorageService.FILTER_DELIVERY_DATE_RANGE,
    new Date().setMonth(new Date().getMonth() - 6),
    maxDate,
  );

  return {
    dateRange,
    updateCookie: false,
  };
};
