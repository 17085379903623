import CustomField from '~/models/customData/CustomField';

import ArrayUtils from '~/utils/arrayUtils';

/**
 * Returns the options for a filter value select for a custom field.
 *
 * @param {Object} data - The data containing the custom field options.
 * @param {Object} customField - The custom field object to determine the level of storage.
 * @return {Array} The distinct values of the custom field based on its level.
 */
export const getCustomFieldOptions = (data, customField) => {
  if (!customField || !data) {
    return [];
  }

  /*
   * Custom fields are stored in the delivery note data differently,
   * depending on if they are article level or delivery note level:
   * - Delivery Note level: stored as a _string_
   * - Article level: stored as an _array_ because they have can multiple values per delivery note.
   */
  return customField.level === CustomField.LEVEL.ARTICLE.KEY
    ? // Ensure for each row that the custom field is a string.
      ArrayUtils.getDistinctValuesFromArrayByKey_safe(data, customField.key)
    : // Ensure for each row that the custom field is an array.
      ArrayUtils.getDistinctValuesByKey_safe(data, customField.key);
};
