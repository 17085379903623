import React from 'react';

import { Grid, TextField } from '@mui/material';

import { connect } from 'react-redux';
import DataSubscription from '~/models/dataSubscriptions/DataSubscription';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';
import ToastService from '~/services/toast.service';
import DataSubscriptionService from '~/services/dataSubscription.service';
import BasicForm from '~/components/BasicForm';

import cloneDeep from 'lodash/cloneDeep';
import GenericMultiPicker from '~/components/baseComponents/inputs/select/GenericMultiPicker';
import DataSubscriptionFilterClauses from './DataSubscriptionFilterClauses';
import DataSubscriptionFilterClause from '~/models/dataSubscriptions/DataSubscriptionFilterClause';
import DataSubscriptionDateFilterClause from '~/models/dataSubscriptions/DataSubscriptionDateFilterClause';
import FunctionUtils from '~/utils/functionUtils';

const mapStateToProps = (state) => ({
  dataSubscriptions: state.dataSubscriptions,
});
const mapDispatchToProps = () => ({});

class DataSubscriptionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSubscription: new DataSubscription(),
      submittingForm: false,
      deletingForm: false,
    };
  }

  componentDidMount() {
    this.resetForm();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.dataSubscription) !==
      JSON.stringify(this.props.dataSubscription)
    ) {
      this.resetForm();
    }
  }

  resetForm() {
    this.setState({
      dataSubscription: this.props.dataSubscription ?? new DataSubscription(),
    });
  }

  formSuccess = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      submittingForm: true,
    });

    const body = {
      name: this.state.dataSubscription.name,
      trigger: this.state.dataSubscription.trigger,
      filter_union: this.state.dataSubscription.filterUnion,
      filter_clauses: this.state.dataSubscription.filterClauses.map(
        (filterClause) =>
          DataSubscriptionFilterClause.getBackendFormat(filterClause),
      ),
      date_filter_clauses: this.state.dataSubscription.dateFilterClauses.map(
        (dateFilterClause) =>
          DataSubscriptionDateFilterClause.getBackendFormat(dateFilterClause),
      ),
      subscription_events: this.state.dataSubscription.subscriptionEvents,
    };

    Log.info(
      'Submit data subscription form',
      body,
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.DATA_SUBSCRIPTION);

    if (this.renderForCreate()) {
      const [dataSubscriptionId, error] = await promiseHandler(
        DataSubscriptionService.createDataSubscription(body),
      );

      if (error) {
        Log.error('Failed to create data subscription.', error);
        ToastService.httpError(
          ['Regel konnte nicht angelegt werden.'],
          error.response,
        );
        Log.productAnalyticsEvent(
          'Failed to create',
          Log.FEATURE.DATA_SUBSCRIPTION,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }
    } else {
      const [response, error] = await promiseHandler(
        DataSubscriptionService.updateDataSubscription(
          this.props.dataSubscription.id,
          body,
        ),
      );

      if (error) {
        Log.error(
          'Failed to update data subscription. id: ' +
            this.props.dataSubscription.id,
          error,
        );
        ToastService.httpError(
          ['Regel konnte nicht aktualisiert werden.'],
          error.response,
        );
        Log.productAnalyticsEvent(
          'Failed to update',
          Log.FEATURE.DATA_SUBSCRIPTION,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }
    }

    this.setState({
      submittingForm: false,
    });

    this.props.closeForm();
    this.resetForm();
    DataSubscriptionService.refreshDataSubscriptions();
  };
  formAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.DATA_SUBSCRIPTION);
    this.props.closeForm();
    this.resetForm();
  };
  formDelete = async (event) => {
    event.preventDefault();

    Log.info(
      'Delete data subscription',
      { id: this.props.dataSubscription.id },
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Delete', Log.FEATURE.DATA_SUBSCRIPTION);

    this.setState({
      deletingForm: true,
    });

    const [response, error] = await promiseHandler(
      DataSubscriptionService.deleteDataSubscription(
        this.props.dataSubscription.id,
      ),
    );

    if (error) {
      ToastService.httpError(
        ['Regel konnte nicht gelöscht werden.'],
        error.response,
      );
      Log.error('Failed to delete data subscription.', error);
      Log.productAnalyticsEvent(
        'Failed to delete',
        Log.FEATURE.DATA_SUBSCRIPTION,
        Log.TYPE.ERROR,
      );
      this.setState({
        deletingForm: false,
      });
      return;
    }

    this.setState({
      deletingForm: false,
    });

    this.props.closeForm();
    this.resetForm();
    await DataSubscriptionService.refreshDataSubscriptions();
  };
  renderForCreate = () => {
    return this.props.type === 'create';
  };
  handleInputChange = (event) => {
    const newDataSubscription = cloneDeep(this.state.dataSubscription);

    switch (event.target.name) {
      case 'name': {
        newDataSubscription.name = event.target.value;
        Log.info(
          'Change form value of name',
          {
            from: this.state.dataSubscription.name,
            to: newDataSubscription.name,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'data_subscription_change_name',
          Log.productAnalyticsEvent,
          ['Change name', Log.FEATURE.DATA_SUBSCRIPTION],
        );
        break;
      }
    }

    this.setState({
      dataSubscription: newDataSubscription,
    });
  };
  handleChangeSubscriptionEvents = (subscriptionEvents) => {
    const newDataSubscription = cloneDeep(this.state.dataSubscription);

    newDataSubscription.subscriptionEvents = subscriptionEvents.map(
      (subscriptionEvent) => subscriptionEvent.id,
    );

    Log.info(
      'Change form value of subscription events',
      {
        from: this.state.dataSubscription.subscriptionEvents,
        to: newDataSubscription.subscriptionEvents,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change subscription events',
      Log.FEATURE.DATA_SUBSCRIPTION,
    );

    this.setState({
      dataSubscription: newDataSubscription,
    });
  };
  handleChangeFilterClauses = (filterClauses) => {
    const newDataSubscription = cloneDeep(this.state.dataSubscription);

    newDataSubscription.filterClauses = filterClauses;

    Log.info(
      'Change form value of filter clauses',
      {
        from: this.state.dataSubscription.filterClauses,
        to: newDataSubscription.filterClauses,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change filter clauses',
      Log.FEATURE.DATA_SUBSCRIPTION,
    );

    this.setState({
      dataSubscription: newDataSubscription,
    });
  };
  handleDeleteFilterClause = (index) => {
    const newDataSubscription = cloneDeep(this.state.dataSubscription);

    newDataSubscription.filterClauses.splice(index, 1);

    Log.info(
      'Delete filter clause',
      {
        from: this.state.dataSubscription.filterClauses,
        to: newDataSubscription.filterClauses,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Delete filter clause',
      Log.FEATURE.DATA_SUBSCRIPTION,
    );

    this.setState({
      dataSubscription: newDataSubscription,
    });
  };
  handleChangeFilterUnion = (filterUnion) => {
    const newDataSubscription = cloneDeep(this.state.dataSubscription);

    newDataSubscription.filterUnion = filterUnion;

    Log.info(
      'Change form value of filter union',
      {
        from: this.state.dataSubscription.filterUnion,
        to: newDataSubscription.filterUnion,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change filter union',
      Log.FEATURE.DATA_SUBSCRIPTION,
    );

    this.setState({
      dataSubscription: newDataSubscription,
    });
  };
  handleChangeDateFilterClauses = (dateFilterClauses) => {
    const newDataSubscription = cloneDeep(this.state.dataSubscription);

    newDataSubscription.dateFilterClauses = dateFilterClauses;

    Log.info(
      'Change form value of date filter clauses',
      {
        from: this.state.dataSubscription.dateFilterClauses,
        to: newDataSubscription.dateFilterClauses,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change date filter clauses',
      Log.FEATURE.DATA_SUBSCRIPTION,
    );

    this.setState({
      dataSubscription: newDataSubscription,
    });
  };
  handleDeleteDateFilterClause = (index) => {
    const newDataSubscription = cloneDeep(this.state.dataSubscription);

    newDataSubscription.dateFilterClauses.splice(index, 1);

    Log.info(
      'Delete date filter clause',
      {
        from: this.state.dataSubscription.dateFilterClauses,
        to: newDataSubscription.dateFilterClauses,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Delete date filter clause',
      Log.FEATURE.DATA_SUBSCRIPTION,
    );

    this.setState({
      dataSubscription: newDataSubscription,
    });
  };

  getUnsavedChanges() {
    if (this.renderForCreate()) {
      return [];
    }

    return DataSubscription.getDifferentValues(
      this.props.dataSubscription,
      this.state.dataSubscription,
    );
  }

  getSubscriptionEvents() {
    return Object.keys(DataSubscription.SUBSCRIPTION_EVENT).map((key) => {
      return {
        id: DataSubscription.SUBSCRIPTION_EVENT[key].KEY,
        name: DataSubscription.SUBSCRIPTION_EVENT[key].STRING,
      };
    });
  }

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        formDelete={this.renderForCreate() ? null : this.formDelete}
        title={
          'Regel ' +
          (this.renderForCreate()
            ? 'Erstellen'
            : this.props.dataSubscription.name)
        }
        fullWidth
        submittingForm={this.state.submittingForm}
        deletingForm={this.state.deletingForm}
        unsavedChanges={this.getUnsavedChanges()}
        disableSubmitButton={
          !(this.state.dataSubscription?.subscriptionEvents?.length > 0)
        }
        id={this.props.dataSubscription?.id}
      >
        <Grid container direction="row" spacing={3} space={4}>
          <Grid item xs={12} lg={12}>
            <h3 className="main-text mt-0">Regel</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="name-input"
                  name="name"
                  label="Name"
                  type="text"
                  disabled={!this.renderForCreate()}
                  required
                  fullWidth
                  value={this.state.dataSubscription?.name}
                  onChange={this.handleInputChange}
                  autoFocus={this.renderForCreate()}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">Änderung des Prozessstatus</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <GenericMultiPicker
                  textfieldLabel="Events*"
                  pickedItemIds={
                    this.state.dataSubscription?.subscriptionEvents ?? []
                  }
                  allItems={this.getSubscriptionEvents()}
                  callbackPickedItems={this.handleChangeSubscriptionEvents}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">Filterklauseln</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <DataSubscriptionFilterClauses
                  filterClauses={this.state.dataSubscription?.filterClauses}
                  onChange={this.handleChangeFilterClauses}
                  onDelete={this.handleDeleteFilterClause}
                  filterUnion={this.state.dataSubscription?.filterUnion}
                  onChangeFilterUnion={this.handleChangeFilterUnion}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Date filter clauses only make sense when the time based triggers are available */}
        {/* <Grid item xs={12} lg={12}>
          <h3 className="mt-20px main-text">Filterklauseln (Zeitraum)</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <DataSubscriptionDateFilterClauses
                dateFilterClauses={this.state.dataSubscription?.dateFilterClauses}
                onChange={this.handleChangeDateFilterClauses}
                onDelete={this.handleDeleteDateFilterClause}
              />
            </Grid>
          </Grid>
        </Grid> */}
      </BasicForm>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps(),
)(DataSubscriptionForm);
