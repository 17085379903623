import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATE } from '~/constants/LoadingState';

const homeSlice = createSlice({
  name: 'home',
  initialState: {
    filteredDeliveryNotes: [],
    filteredDeliveryNotesVersion: 0,
    archiveDeliveryNotesCount: 0,
    archiveDeclinedDeliveryNotesCount: 0,
    archiveDeliveryNotesCountLoading: LOADING_STATE.NOT_LOADED,
    archiveDeliveryNotesCountAppliedFilters: {},
    archiveAnalyticsData: [],
    archiveAnalyticsDataVersion: 0,
    archiveAnalyticsDataLoading: LOADING_STATE.NOT_LOADED,
    archiveAnalyticsDataAppliedFilters: {},
  },
  reducers: {
    setFilteredDeliveryNotes(state, parameter) {
      const { payload } = parameter;
      state.filteredDeliveryNotes = payload;
      state.filteredDeliveryNotesVersion += 1;
    },
    setArchiveDeliveryNotesCount(state, parameter) {
      const { payload } = parameter;
      state.archiveDeliveryNotesCount = payload.archiveDeliveryNotesCount;
      state.archiveDeclinedDeliveryNotesCount =
        payload.archiveDeclinedDeliveryNotesCount;
      state.archiveDeliveryNotesCountLoading = LOADING_STATE.SUCCEEDED;
      state.archiveDeliveryNotesCountAppliedFilters = payload.appliedFilters;
    },
    setArchiveDeliveryNotesCountLoading(state, parameter) {
      const { payload } = parameter;
      state.archiveDeliveryNotesCountLoading = payload;
    },
    setArchiveAnalyticsData(state, parameter) {
      const { payload } = parameter;
      state.archiveAnalyticsData = payload.archiveAnalyticsData;
      state.archiveAnalyticsDataVersion += 1;
      state.archiveAnalyticsDataLoading = LOADING_STATE.SUCCEEDED;
      state.archiveAnalyticsDataAppliedFilters = payload.appliedFilters;
    },
    setArchiveAnalyticsDataLoading(state, parameter) {
      const { payload } = parameter;
      state.archiveAnalyticsDataLoading = payload;
    },
  },
});

const { actions, reducer } = homeSlice;
export const {
  setArchiveAnalyticsData,
  setArchiveAnalyticsDataLoading,
  setArchiveDeliveryNotesCount,
  setArchiveDeliveryNotesCountLoading,
  setFilteredDeliveryNotes,
} = actions;
export default reducer;
