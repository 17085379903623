import { useDispatch } from 'react-redux';

import DeliveriesService from '~/services/deliveries.service';

import { LOADING_STATE } from '~/constants/LoadingState';

import { replaceDeliveryNoteTemplates } from '~/redux/deliveryNotesSlice';

import Log from '~/utils/Log';

/**
 * Custom hook for loading delivery note templates.
 *
 * This hook provides functionality to load delivery note templates either from
 * the window.redux_state (if available and successfully loaded) or by fetching
 * from the backend using DeliveriesService.
 *
 * @returns {Object} An object containing the loadDeliveryNoteTemplates function.
 */
export const useLoadDeliveryNoteTemplates = () => {
  const dispatch = useDispatch();

  /**
   * Loads delivery note templates.
   *
   * If templates are available in window.redux_state and successfully loaded,
   * it dispatches the replaceDeliveryNoteTemplates action with the loaded data.
   * Otherwise, it calls DeliveriesService.refreshTemplates() to fetch the data.
   */
  async function loadDeliveryNoteTemplates() {
    if (
      /**
       * Opening an item in a new browser tab using BrowserUtils.openMenuItemInNewTab or BrowserUtils.openNewTab
       * passes the whole Redux state to the new tab as window.redux_state.
       * If that is the case, we don't need to load the data again from the backend,
       * but push the data from the window object into Redux state.
       */
      window.redux_state &&
      window.redux_state.deliveryNotes.deliveryNoteTemplatesLoading ===
        LOADING_STATE.SUCCEEDED
    ) {
      dispatch(
        replaceDeliveryNoteTemplates(
          window.redux_state.deliveryNotes.deliveryNoteTemplates,
        ),
      );

      Log.info('Delivery notes templates state passed via window.redux_state.');

      return;
    }

    DeliveriesService.refreshTemplates();
  }

  return {
    loadDeliveryNoteTemplates,
  };
};
