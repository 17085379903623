import FilterGroupFilter from '~/models/filters/FilterGroupFilter';

import { toPascalCase } from '~/utils/string';

/**
 * Returns an object with the applied filters based on the given filter and props.
 */
export const getAppliedFilters = (filter, props) => {
  if (!props) {
    return [];
  }

  const filters = [
    'ACCEPT_STATE',
    'ARTICLE_NUMBER',
    'ARTICLE',
    'COST_CENTER',
    'CUSTOM_FIELDS',
    'FROM_SITE',
    'PERMITTED_COST_CENTERS',
    'PERMITTED_TO_SITES',
    'PROCESS_STATE',
    'RECIPIENT',
    'SETTLED_STATUS',
    'SUPPLIER',
    'TO_SITE_RECIPIENT',
    'TO_SITE_SUPPLIER_TRADE_CONTACT',
    'TO_SITE_SUPPLIER',
  ];

  return filters.reduce((accumulator, key) => {
    const filterKey = FilterGroupFilter.FILTER[`SELECTED_${key}`];
    const selectedFilterKey = props[`selected${toPascalCase(key)}`];

    accumulator[filterKey] = filter === filterKey ? null : selectedFilterKey;

    return accumulator;
  }, {});
};
