import axios from '~/utils/api-client';
import Config from '~/Config';
import CustomField from '~/models/customData/CustomField';
import CacheService from './cache.service';
import Log from '~/utils/Log';
import CustomFieldOption from '~/models/customData/CustomFieldOption';
import store from '~/redux/store';
import { LOADING_STATE } from '~/constants/LoadingState';
import { es6ClassFactory as ES6ClassFactory } from '~/utils/ES6ClassFactory';
import { promiseHandler } from '~/utils/promiseHandler';
import {
  replaceCustomFields,
  setCustomFieldsLoading,
  addCustomFieldsBulk,
  addCustomFieldsBulkNotLoadableIds,
} from '~/redux/customFieldsSlice';
import UserUtils from '~/utils/userUtils';
import AuthService from './auth.service';

const API_URL = Config.apiUrl + '/custom_field';

class CustomFieldService {
  constructor() {
    this.customFieldsLoading = LOADING_STATE.NOT_LOADED;
    this.customFields = [];
  }

  async getAllCustomFields() {
    return axios.get(API_URL + '/all').then((response) => {
      if (response.status !== 200) {
        return [];
      }

      return response.data.items.map((item) => new CustomField(item));
    });
  }

  async createCustomField(body) {
    return axios.post(API_URL, body).then((response) => {
      return response.data?.id;
    });
  }

  async updateCustomField(id, body) {
    return axios.put(API_URL + '/' + id, body);
  }

  async deleteCustomField(id) {
    return axios.delete(API_URL + '/' + id);
  }

  // search for custom field in store. if not found, load all custom fields from backend
  // return empty custom field if no custom field could be found
  async getCustomFieldById(customFieldId) {
    let customField = store
      .getState()
      .customFields?.customFields?.find(
        (customField) => customField.id === customFieldId,
      );
    // For more information about why custom fields are cached locally in CustomFieldService, check the comments in SiteService.getSiteById.
    if (
      !customField &&
      store.getState().customFields?.customFieldsLoading ===
        LOADING_STATE.NOT_LOADED
    ) {
      customField = this.customFields.find(
        (customField) => customField.id === customFieldId,
      );
    }

    if (customField) {
      return ES6ClassFactory.convertToES6Class(
        [customField],
        new CustomField(),
      )[0];
    }

    if (
      store.getState().customFields?.customFieldsLoading ===
        LOADING_STATE.SUCCEEDED ||
      store.getState().customFields?.customFieldsLoading ===
        LOADING_STATE.FAILED
    ) {
      return null;
    }

    if (
      this.customFieldsLoading === LOADING_STATE.SUCCEEDED ||
      this.customFieldsLoading === LOADING_STATE.FAILED
    ) {
      return null;
    }

    store.dispatch(setCustomFieldsLoading(LOADING_STATE.LOADING));
    this.customFieldsLoading = LOADING_STATE.LOADING;

    const [customFields, error] = await promiseHandler(
      this.getAllCustomFields(),
    );

    if (error) {
      store.dispatch(setCustomFieldsLoading(LOADING_STATE.FAILED));
      this.customFieldsLoading = LOADING_STATE.FAILED;
      throw error;
    }

    store.dispatch(replaceCustomFields(customFields));
    this.customFieldsLoading = LOADING_STATE.SUCCEEDED;
    this.customFields = customFields;

    return (
      customFields.find((customField) => customField.id === customFieldId) ??
      null
    );
  }

  // search for custom field in cache. if not found, load custom field from backend
  getCustomField = async (customFieldId, ignoreCache) => {
    const url = API_URL + '/' + customFieldId;

    if (!ignoreCache) {
      const [cachedValue, error] = CacheService.getCached(url);
      if (cachedValue) {
        return cachedValue;
      }

      if (error) {
        throw error;
      }
    }

    return axios
      .get(url)
      .then((response) => {
        if (response?.status !== 200) {
          Log.warn('GET /custom_field did not return 200', {
            status: response?.status,
          });
        }

        const customField = new CustomField(response?.data);
        CacheService.setCached(url, customField);
        return customField;
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  };
  // Custom fields bulk is stored in redux state. This is different from sites, cost centers and users bulk.
  // It is needed because custom fields could already be accessed before dlns have been loaded
  // (e.g. in dln and article meta data).
  loadCustomFieldsBulk = async (customFieldIds) => {
    const filteredCustomFields = customFieldIds.filter((customFieldId) => {
      const customField = store
        .getState()
        .customFields?.customFieldsBulk?.find(
          (customField) => customField.id === customFieldId,
        );
      const customFieldsBulkNotLoadableId = store
        .getState()
        .customFields?.customFieldsBulkNotLoadableIds?.includes(customFieldId);
      return !customField && !customFieldsBulkNotLoadableId;
    });

    // If all custom fields are already loaded, return.
    if (filteredCustomFields.length === 0) {
      return;
    }

    return axios
      .post(API_URL + '/query/bulk', {
        ids: filteredCustomFields,
        include_options: true,
      })
      .then((response) => {
        if (response.status !== 200) {
          return [];
        }

        const customFieldsBulk = response.data.items.map(
          (item) =>
            new CustomField(item.custom_field, item.custom_field_options),
        );
        const customFieldsBulkNotLoadableIds = filteredCustomFields.filter(
          (customFieldId) =>
            !customFieldsBulk.find(
              (customField) => customField.id === customFieldId,
            ),
        );

        store.dispatch(addCustomFieldsBulk(customFieldsBulk));
        store.dispatch(
          addCustomFieldsBulkNotLoadableIds(customFieldsBulkNotLoadableIds),
        );
      });
  };
  getCustomFieldFromCustomFieldsBulk = async (customFieldId) => {
    return (
      store
        .getState()
        .customFields?.customFieldsBulk?.find(
          (customField) => customField.id === customFieldId,
        ) ?? null
    );
    /* const customField = store
      .getState()
      .customFields?.customFieldsBulk?.find((customField) => customField.id === customFieldId);
    if (customField) return customField;

    const customFieldsBulkNotLoadableIds = store.getState().customFields?.customFieldsBulkNotLoadableIds;
    if (customFieldsBulkNotLoadableIds?.includes(customFieldId)) return null;

    // Loading from backend should not be necessary if all custom fields are correctly loaded via bulk earlier
    const [customField2, err] = await promiseHandler(this.getCustomField(customFieldId));

    if (err) {
      Log.error('Failed to load custom field.', err);
      Log.productAnalyticsEvent('Failed to load custom field', Log.FEATURE.CUSTOM_FIELD, Log.TYPE.ERROR);
      store.dispatch(addCustomFieldsBulkNotLoadableIds([customFieldId]));
      return null;
    }

    store.dispatch(addCustomFieldsBulk([customField2]));
    return customField2; */
  };

  async getCustomFieldOptions(customFieldId, ignoreCache) {
    const url = API_URL + '/' + customFieldId + '/option';

    if (!ignoreCache) {
      const [cachedValue, error] = CacheService.getCached(url);
      if (cachedValue) {
        return cachedValue;
      }

      if (error) {
        throw error;
      }
    }

    return axios
      .get(url)
      .then((response) => {
        if (response?.status !== 200) {
          Log.warn('GET /custom-field/option did not return 200', {
            status: response?.status,
          });
        }

        const customFieldOptions = response.data.items.map(
          (item) => new CustomFieldOption(item),
        );

        CacheService.setCached(url, customFieldOptions);
        return customFieldOptions;
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  }

  async getCustomFieldOptionById(customFieldId, customFieldOptionId) {
    const url =
      API_URL + '/' + customFieldId + '/option/' + customFieldOptionId;

    const [cachedValue, error] = CacheService.getCached(url);
    if (cachedValue) {
      return cachedValue;
    }

    if (error) {
      throw error;
    }

    return axios
      .get(url)
      .then((response) => {
        if (response?.status !== 200) {
          Log.warn('GET /custom-field/option did not return 200', {
            status: response?.status,
          });
        }

        const customFieldOption = new CustomFieldOption(response.data);

        CacheService.setCached(url, customFieldOption);
        return customFieldOption;
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  }

  async createCustomFieldOption(customFieldId, body) {
    return axios
      .post(API_URL + '/' + customFieldId + '/option', body)
      .then((response) => {
        return response.data?.id;
      });
  }

  async deleteCustomFieldOption(customFieldId, customFieldOptionId) {
    return axios.delete(
      API_URL + '/' + customFieldId + '/option/' + customFieldOptionId,
    );
  }

  loadCustomFields = async () => {
    // to not load data again when they are already loading or have already been loaded
    if (
      store.getState().customFields?.customFieldsLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshCustomFields();
  };
  refreshCustomFields = async () => {
    store.dispatch(setCustomFieldsLoading(LOADING_STATE.LOADING));

    const [customFields, error] = await promiseHandler(
      this.getAllCustomFields(),
    );

    if (error) {
      store.dispatch(setCustomFieldsLoading(LOADING_STATE.FAILED));
      Log.error('Failed to load custom fields.', error);
      Log.productAnalyticsEvent(
        'Failed to load custom fields',
        Log.FEATURE.CUSTOM_FIELD,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(
      replaceCustomFields([
        ...customFields,
        ...this.getHardcodedCustomFields(),
      ]),
    );
  };

  // Currently the mappings of the custom data keys is hardcoded based on specific users.
  // Hence, the mappings must be loaded into the redux state to be applied in the delivery list and dln detail view.
  getHardcodedCustomFields() {
    return (
      UserUtils.USERS_WITH_CUSTOM_FIELDS.find(
        (user) => AuthService.getUserIdFromAccessToken() === user.userId,
      )?.customFields ?? []
    );
  }
}

export default new CustomFieldService();
