import { useState, useMemo } from 'react';

import Log from '~/utils/Log';
import DataExchangeService from '~/services/dataExchange.service';
import { promiseHandler } from '~/utils/promiseHandler';

import { dateUtils, fromUTC, parseDate } from '~/utils/dateUtils';
import objectUtils from '~/utils/objectUtils';
import arrayUtils from '~/utils/arrayUtils';

import { LOADING_STATE } from '~/constants/LoadingState';

const INITIAL_FORM_VALUES = {
  id: null,
  channel: 'email',
  summary: '',
  timestamp: '',
};

export const useContactAttempt = ({
  contactAttempts,
  setContactAttempts,
  supplier,
}) => {
  const [showForm, setShowForm] = useState(false);
  const exchange = supplier.dataExchange;

  const [loadingState, setLoadingState] = useState(LOADING_STATE.NOT_LOADED);

  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);

  // Prevent the user from selecting a date in the future.
  const maxDate = fromUTC(new Date()).toISOString().slice(0, 16);

  const handleFormOnChange = (event) => {
    const { name, value } = event.target;

    let adjustedValue = value;

    if (name === 'timestamp' && value > maxDate) {
      adjustedValue = maxDate;
    }

    setFormValues((previous) => ({
      ...previous,
      [name]: adjustedValue,
    }));
  };

  const disableButton = useMemo(() => {
    return (
      !formValues.timestamp ||
      !formValues.channel ||
      formValues.summary?.length <= 1
    );
  }, [formValues]);

  const handleSubmit = async () => {
    setLoadingState(LOADING_STATE.LOADING);

    const isEdit = Boolean(formValues?.id);
    const logMessage = isEdit
      ? 'Editing contact attempt'
      : 'Creating a new contact attempt';
    const errorMessage = isEdit
      ? 'Unable to edit contact attempt'
      : 'Unable to create new contact attempt';

    const body = {
      ...formValues,
      timestamp: parseDate(formValues.timestamp).toISOString(),
      data_exchange_id: exchange.id,
    };

    Log.info(logMessage, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.info(logMessage, Log.FEATURE.KANBAN_SUPPLIER_OVERVIEW);

    const [response, error] = await promiseHandler(
      DataExchangeService.createContactAttempt(objectUtils.removeNulls(body)),
    );

    if (error) {
      Log.info(errorMessage, error);
      Log.info(errorMessage, Log.FEATURE.KANBAN_SUPPLIER_OVERVIEW);

      setLoadingState(LOADING_STATE.NOT_LOADED);
      return;
    }

    setLoadingState(LOADING_STATE.NOT_LOADED);

    const updatedContactAttempts = isEdit
      ? arrayUtils.updateByKey(contactAttempts, 'id', formValues.id, formValues)
      : [
          {
            ...formValues,
            id: response?.id,
          },
          ...contactAttempts,
        ];

    setContactAttempts(updatedContactAttempts);

    setFormValues(INITIAL_FORM_VALUES);
    setShowForm(false);
  };

  // We now allow the user to create a contact attempt without a contacted person.
  const showNoContactedPersonErrorMessage = false;
  const showContactAttempts =
    !showNoContactedPersonErrorMessage &&
    contactAttempts?.length > 0 &&
    !showForm;
  const showNewContactButton = !showForm && !showNoContactedPersonErrorMessage;

  const showNewForm = () => {
    setFormValues({
      ...INITIAL_FORM_VALUES,
      // Initialize timestamp when form is shown to user.
      timestamp: dateUtils.getFormattedDate(
        new Date(),
        dateUtils.DATE_FORMAT.DATE_TIME_INPUT_FORMAT,
      ),
    });
    setShowForm(true);
  };

  return {
    disableButton,
    formValues,
    handleFormOnChange,
    handleSubmit,
    loadingState,
    maxDate,
    setFormValues,
    setShowForm,
    showContactAttempts,
    showForm,
    showNewContactButton,
    showNoContactedPersonErrorMessage,
    showNewForm,
  };
};
