import clsx from 'clsx';
import { CircularProgress } from '@mui/material';

export const Spinner = ({ alignLeft, size, title, white }) => (
  <div
    className={clsx('h-full', {
      'text-white': white,
      'flex w-full items-center justify-center': !alignLeft,
    })}
  >
    <CircularProgress
      size={size ?? 20}
      style={{ color: white ? 'white' : 'currentColor' }}
    />
    {title ? <div className="ml-2">{title}</div> : null}
  </div>
);
