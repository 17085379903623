import { useEffect, useState } from 'react';

import {
  ArrowRight as ArrowRightIcon,
  LocalShippingRounded as LocalShippingRoundedIcon,
  WarehouseRounded as WarehouseRoundedIcon,
} from '@mui/icons-material';

import { LightTooltip } from '~/utils/componentUtils';
import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';
import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';

import DeliveryNote from '~/models/deliveries/DeliveryNote';
import { withErrorBoundary } from '~/ui/atoms';

import {
  AttentionIconLight,
  DeclinedIconLight,
  DoneIconLight,
} from '~/assets/icons';

import ValueGroup from '~/models/deliveries/ValueGroup';

function DeliveryNoteLogos(props) {
  const [supplierLogo, setSupplierLogo] = useState(null);
  const [carrierLogo, setCarrierLogo] = useState(null);
  const [recipientLogo, setRecipientLogo] = useState(null);

  useEffect(() => {
    loadSupplierLogo();
  }, [props.supplier]);

  useEffect(() => {
    loadCarrierLogo();
  }, [props.carrier]);

  useEffect(() => {
    loadRecipientLogo();
  }, [props.recipient]);

  async function loadSupplierLogo() {
    if (!ValueGroup.getCurrentValue(props.supplier?.id)) {
      return;
    }

    const [supplierLogo, error] = await promiseHandler(
      props.supplier?.getLogo(),
    );

    if (error) {
      Log.error('Failed to load company logo of supplier', error);
      Log.productAnalyticsEvent(
        'Failed to load company logo',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      return;
    }

    setSupplierLogo(supplierLogo);
  }

  async function loadCarrierLogo() {
    if (!ValueGroup.getCurrentValue(props.carrier?.id)) {
      return;
    }

    const [carrierLogo, error] = await promiseHandler(props.carrier?.getLogo());

    if (error) {
      Log.error('Failed to load company logo of carrier', error);
      Log.productAnalyticsEvent(
        'Failed to load company logo',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      return;
    }

    setCarrierLogo(carrierLogo);
  }

  async function loadRecipientLogo() {
    if (!ValueGroup.getCurrentValue(props.recipient?.id)) {
      return;
    }

    const [recipientLogo, error] = await promiseHandler(
      props.recipient?.getLogo(),
    );

    if (error) {
      Log.error('Failed to load company logo of recipient', error);
      Log.productAnalyticsEvent(
        'Failed to load company logo',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      return;
    }

    setRecipientLogo(recipientLogo);
  }

  const displayCarrier =
    ValueGroup.getCurrentValue(props.supplier?.id) !==
      ValueGroup.getCurrentValue(props.carrier?.id) ||
    props.acceptState?.carrier === AcceptStateCalculator.ACCEPT_STATE.DECLINED;

  const logo = (
    role,
    companyName,
    companyLogo,
    acceptState,
    onBehalfAcceptState,
    fallback,
  ) => {
    let title = role + (companyName ? ': ' + companyName : '');
    let acceptStateIcon = null;

    if (
      acceptState === AcceptStateCalculator.ACCEPT_STATE.DECLINED ||
      onBehalfAcceptState === AcceptStateCalculator.ACCEPT_STATE.DECLINED
    ) {
      acceptStateIcon = (
        <DeclinedIconLight className="t--4px r--4px circle box-shadow h-20px w-20px absolute" />
      );
      title += ' (Lieferung abgelehnt)';
    } else if (
      acceptState === AcceptStateCalculator.ACCEPT_STATE.APPROVED ||
      onBehalfAcceptState === AcceptStateCalculator.ACCEPT_STATE.APPROVED
    ) {
      acceptStateIcon = (
        <DoneIconLight className="t--4px r--4px circle box-shadow h-20px w-20px absolute" />
      );
      title += ' (Lieferung bestätigt)';
    } else {
      // Case: Status of party is open.
      // If dln is delivered, display attention icon (signature needed) for both supplier and recipient.
      if (
        role !== DeliveryNote.PROCESS_ROLE.CARRIER.STRING &&
        props.processState === DeliveryNote.PROCESS_STATE.DELIVERED.STRING
      ) {
        acceptStateIcon = (
          <AttentionIconLight className="t--4px r--4px circle box-shadow h-20px w-20px absolute" />
        );
        title += ' (fehlende Signatur)';
      }
    }

    return (
      <LightTooltip title={title}>
        <div className="relative flex size-12 items-center justify-center rounded-lg bg-white">
          {companyLogo?.size > 0 ? (
            <img
              className="delivery-note-logo"
              src={URL.createObjectURL(companyLogo)}
            />
          ) : (
            fallback
          )}
          {acceptStateIcon}
        </div>
      </LightTooltip>
    );
  };

  return (
    <div className="flex-e-c">
      {logo(
        DeliveryNote.PROCESS_ROLE.SUPPLIER.STRING,
        ValueGroup.getCurrentValue(props.supplier?.name),
        supplierLogo,
        props.acceptStateSupplier,
        props.acceptStateOnBehalfSupplier,
        <WarehouseRoundedIcon />,
      )}
      <ArrowRightIcon fontSize="large" />
      {displayCarrier ? (
        <>
          {logo(
            DeliveryNote.PROCESS_ROLE.CARRIER.STRING,
            ValueGroup.getCurrentValue(props.carrier?.name),
            carrierLogo,
            props.acceptStateCarrier,
            props.acceptStateOnBehalfCarrier,
            <LocalShippingRoundedIcon />,
          )}
          <ArrowRightIcon fontSize="large" />
        </>
      ) : null}
      {logo(
        DeliveryNote.PROCESS_ROLE.RECIPIENT.STRING,
        ValueGroup.getCurrentValue(props.recipient?.name),
        recipientLogo,
        props.acceptStateRecipient,
        props.acceptStateOnBehalfRecipient,
        <WarehouseRoundedIcon />,
      )}
    </div>
  );
}

export default withErrorBoundary(DeliveryNoteLogos, null);
