import React from 'react';

import { Button } from '@mui/material';

import axios from '~/utils/api-client';
import Config from '~/Config';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  companyAccount: state.companyAccount,
  userinfo: state.userinfo,
});

class Demo extends React.Component {
  constructor(props) {
    super(props);
  }

  async resetDemo(id) {
    const API_URL = Config.apiUrl + '/admin';

    return axios.get(API_URL + '/reset_demo');
  }

  async resetDemo2(id) {
    const API_URL = Config.apiUrl + '/admin';

    return axios.get(API_URL + '/reset_demo2');
  }

  async createMusterDln(type) {
    const API_URL = Config.apiUrl + '/admin';

    return axios.get(API_URL + '/create_demo_dln', {
      params: { dln_type: type },
    });
  }

  async createMustermannDln(type) {
    const API_URL = Config.apiUrl + '/admin';

    return axios.get(API_URL + '/create_mustermann_dln', {
      params: { dln_type: type },
    });
  }

  async createDevDln(type) {
    const API_URL = Config.apiUrl + '/admin';

    return axios.get(API_URL + '/dev/create_demo_dln', {
      params: { dln_type: type },
    });
  }

  async createCustomerDemoDln(type) {
    const API_URL = Config.apiUrl + '/admin';

    return axios.get(API_URL + '/create_customer_demo_dln', {
      params: { dln_type: type },
    });
  }

  throwTypeError = (event) => {
    return event.undefinedValue.undefinedValue;
  };

  returnMatchingDemo() {
    const demoCompanyAccounts = [
      '9b004d37-5da7-4065-a9d6-76fed9716d76',
      'a85be708-d8b3-42d8-8e4b-1ffedb4f5f0e',
      '7c045c36-9e31-494d-849d-4eb873fca674',
    ];
    const musterDemoCompanyAccounts = [
      '2c6b34b9-09df-4ae3-901b-1a4ba7c1dcdb',
      '781dc85d-7cbb-4ade-a762-d2274793989d',
      '01a60cb0-75fc-4c2e-a465-2cc1701ec279',
    ];
    const devCompanyAccounts = [
      'b506cfa1-4e87-42ef-9e38-6a01d8194a1e',
      '6360729a-f2b6-4431-8b81-6764f1aa5fff',
      'ecdcb1f5-40bf-4ac3-9cc7-05171ecf40bf',
    ];
    const devCompanyAccountsDevDB = [
      'fc9a9155-0058-4ef2-a6fd-3fd9ffca90cb',
      'd695f86a-e20c-447d-b373-390e72248f8c',
      'a767719d-453c-404e-b9cd-ac849b9a113b',
    ];
    const devCompanyAccountsProductionDB = [
      'f0110e4a-505e-4d0b-ba8d-c1500df12eaf',
      '0141a50c-da1c-46b1-82fa-62454d560a8f',
      'a6ecab6d-c55c-457d-a096-4da3785b6fc0',
    ];
    const unauthorizedUsers = ['06e29deb-6fd2-4651-9d54-5f61d90a9f1d'];

    const mustermannCompanyAccounts = [
      '948ed3a9-b375-4623-bc00-63f6fc09999c',
      'e90991cc-97e4-4b6d-962b-d12ef1a9c335',
      'b04cb975-8287-4c36-af38-9c3e46d82d50',
    ];

    const userIsAuthorized =
      this.props.userinfo.userinfo?.id &&
      !unauthorizedUsers.includes(this.props.userinfo.userinfo?.id);

    if (!userIsAuthorized) {
      return '';
    }

    if (
      demoCompanyAccounts.includes(this.props.companyAccount.companyAccount.id)
    ) {
      return (
        <div>
          <Button className="primary-button" onClick={this.resetDemo}>
            {' '}
            Demo zurücksetzen{' '}
          </Button>
        </div>
      );
    }

    if (
      musterDemoCompanyAccounts.includes(
        this.props.companyAccount.companyAccount.id,
      )
    ) {
      return (
        <div>
          Lieferung erstellen:
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createMusterDln('schüttgut_kies')}
            >
              {' '}
              Kies
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createMusterDln('schüttgut_splitt')}
            >
              {' '}
              Splitt
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createMusterDln('stückgut')}
            >
              {' '}
              Stückgut 1
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createMusterDln('stückgut2')}
            >
              {' '}
              Stückgut 2
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createMusterDln('beton')}
            >
              {' '}
              Beton
            </Button>
          </div>
          <br></br>
          <div>
            <Button className="primary-button" onClick={this.resetDemo2}>
              {' '}
              Demo zurücksetzen{' '}
            </Button>
          </div>
        </div>
      );
    }

    if (
      devCompanyAccounts.includes(
        this.props.companyAccount.companyAccount.id,
      ) ||
      devCompanyAccountsDevDB.includes(
        this.props.companyAccount.companyAccount.id,
      ) ||
      devCompanyAccountsProductionDB.includes(
        this.props.companyAccount.companyAccount.id,
      )
    ) {
      return (
        <div>
          Lieferung erstellen:
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createDevDln('schüttgut_kies')}
            >
              {' '}
              Kies
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createDevDln('schüttgut_splitt')}
            >
              {' '}
              Splitt
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createDevDln('stückgut')}
            >
              {' '}
              Stückgut 1
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createDevDln('stückgut2')}
            >
              {' '}
              Stückgut 2
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createDevDln('beton')}
            >
              {' '}
              Beton
            </Button>
          </div>
          <br></br>
        </div>
      );
    }

    if (
      mustermannCompanyAccounts.includes(
        this.props.companyAccount.companyAccount.id,
      )
    ) {
      return (
        <div>
          Lieferung erstellen:
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createMustermannDln('schüttgut_kies')}
            >
              {' '}
              Kies
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createMustermannDln('schüttgut_splitt')}
            >
              {' '}
              Splitt
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createMustermannDln('stückgut')}
            >
              {' '}
              Stückgut 1
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createMustermannDln('stückgut2')}
            >
              {' '}
              Stückgut 2
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createMustermannDln('beton')}
            >
              {' '}
              Beton
            </Button>
          </div>
          <br></br>
        </div>
      );
    }

    if (
      this.props.companyAccount?.companyAccount?.featureFlags?.create_demo_dlns
    ) {
      return (
        <div>
          Lieferung erstellen:
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createCustomerDemoDln('schüttgut_kies')}
            >
              {' '}
              Kies
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createCustomerDemoDln('schüttgut_splitt')}
            >
              {' '}
              Splitt
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createCustomerDemoDln('stückgut')}
            >
              {' '}
              Stückgut 1
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createCustomerDemoDln('stückgut2')}
            >
              {' '}
              Stückgut 2
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => this.createCustomerDemoDln('beton')}
            >
              {' '}
              Beton
            </Button>
          </div>
          <br></br>
        </div>
      );
    }

    return '';
  }

  returnDebugButtons() {
    const authorizedUsers = ['830acde4-ef26-4411-a9b1-150957cb3a83']; // polier-dev

    if (authorizedUsers.includes(this.props.userinfo.userinfo?.id)) {
      return (
        <div>
          Fehler werfen
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={this.throwTypeError}
            >
              {' '}
              Throw TypeError
            </Button>
          </div>
        </div>
      );
    }

    return '';
  }

  render() {
    return (
      <div>
        {this.returnMatchingDemo()}
        {this.returnDebugButtons()}
      </div>
    );
  }
}

export default connect(mapStateToProps)(Demo);
