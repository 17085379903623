import Tile from './Tile';
import LinkButton from '../LinkButton';
import { withErrorBoundary } from '~/ui/atoms';
import clsx from 'clsx';

const NumberTile = ({
  buttonCallback,
  buttonLink,
  buttonTitle,
  firstNumber,
  firstNumberTitle,
  icon,
  loading,
  secondNumber,
  secondNumberTitle,
  subheader,
  title,
}) => (
  <Tile
    title={title}
    icon={icon}
    loading={loading}
    buttonTitle={buttonTitle}
    buttonLink={buttonLink}
  >
    <div className="flex-sb-c flexdir-column h-full">
      <div className="text-grey400">{subheader}</div>
      <div className="w-full">
        <div className="flex-sb-c mb-30px">
          <div className="bold h-120px w-150px rounded-5px flex-c-c bg-secondary1 text-center">
            <div>
              <div className="text-46px">{firstNumber}</div>
              <div className="text-grey600">{firstNumberTitle}</div>
            </div>
          </div>
          <div
            className={clsx([
              'bold h-120px w-150px rounded-5px flex-c-c text-center',
              {
                'bg-errorFFD0D1': secondNumber > 0,
                'bg-grey100': !(secondNumber > 0),
              },
            ])}
          >
            <div>
              <div
                className={clsx([
                  'text-46px',
                  {
                    'text-grey600': secondNumber > 0,
                  },
                ])}
              >
                {secondNumber}
              </div>
              <div className="text-grey600">{secondNumberTitle}</div>
            </div>
          </div>
        </div>
        <LinkButton
          title={buttonTitle}
          link={buttonLink}
          callback={buttonCallback}
          className="w-full"
        />
      </div>
    </div>
  </Tile>
);

export default withErrorBoundary(NumberTile, null);
