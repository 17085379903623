import React from 'react';
import { connect } from 'react-redux';

import { Button } from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import { LightTooltipWide } from '~/utils/componentUtils';

import { UserSignatureIcon } from '~/assets/icons';
import UserAction from '~/models/userActions/UserAction';
import DeliveryNoteActionMetaData from '../../deliveries/deliveryNoteActions/DeliveryNoteActionMetaData';
import Log from '~/utils/Log';

const mapStateToProps = (state) => ({
  userinfo: state.userinfo,
});

class InvoiceColumnUnsignedDeliveryNoteIds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      requestDeliveryNoteSignatureFormOpenInvoiceId: null,
    };
  }

  onRequestSignatures = (e, id, unsignedDeliveryNoteIds) => {
    e.stopPropagation();

    Log.info('Open request signature form');
    Log.productAnalyticsEvent(
      'Open request signature form',
      Log.FEATURE.NOTIFICATIONS,
    );

    this.props.onRequestSignatures(id, unsignedDeliveryNoteIds);
  };

  render() {
    const unsignedDeliveryNoteIds = this.props.params.value.split(';');

    // Catch weird design of .split() function as it returns an array with an empty string as only item.
    // -> https://stackoverflow.com/questions/5164883/the-confusion-about-the-split-function-of-javascript-with-an-empty-string
    if (unsignedDeliveryNoteIds[0] === '') {
      return null;
    }

    const userActionsList = [];
    const signatureRequestForDeliveryNoteIds = [];

    for (const deliveryNoteId of unsignedDeliveryNoteIds) {
      if (!this.props.userinfo.userActions[deliveryNoteId]) {
        continue;
      }

      userActionsList.push(this.props.userinfo.userActions[deliveryNoteId]);

      // If there has been a signature requested for the delivery note, push it to the array.
      if (
        this.props.userinfo.userActions[deliveryNoteId].requestSignatures
          .length > 0 &&
        !signatureRequestForDeliveryNoteIds.includes(deliveryNoteId)
      ) {
        signatureRequestForDeliveryNoteIds.push(deliveryNoteId);
      }
    }

    const requestedUsers = UserAction.getRequestedUsers(userActionsList);
    const signaturesForAllDeliveryNotesHaveBeenRequested =
      signatureRequestForDeliveryNoteIds.length ===
      unsignedDeliveryNoteIds.length;

    console.log(
      'signaturesForAllDeliveryNotesHaveBeenRequested =>',
      signaturesForAllDeliveryNotesHaveBeenRequested,
    );

    return (
      <LightTooltipWide
        title={
          signaturesForAllDeliveryNotesHaveBeenRequested ? (
            <DeliveryNoteActionMetaData
              title="Signaturen bereits angefordert von:"
              users={requestedUsers}
            />
          ) : (
            'Signaturen für die nicht signierten Lieferungen von einem anderen Benutzer anfordern.'
          )
        }
      >
        <Button
          variant="outlined"
          color={!signaturesForAllDeliveryNotesHaveBeenRequested && 'primary'}
          onClick={(event) =>
            this.onRequestSignatures(
              event,
              this.props.params.id,
              unsignedDeliveryNoteIds,
            )
          }
          className="h-30px"
          startIcon={
            <UserSignatureIcon
              className={
                'icon-small ml-8px ' +
                (signaturesForAllDeliveryNotesHaveBeenRequested
                  ? 'text-grey400'
                  : '')
              }
            />
          }
        />
      </LightTooltipWide>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(InvoiceColumnUnsignedDeliveryNoteIds),
  null,
);
