import * as React from 'react';
import { TextField } from '@mui/material';
import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import moment from 'moment';
import deLocale from 'date-fns/locale/de';

export default class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  setDate(params) {
    if (!this.props.value) {
      return params;
    }

    const date = moment(this.props.value);
    const day = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'][date.day()];

    params.inputProps.value = `${day} ${date.format('DD.MM.YYYY')}`;

    return params;
  }

  render() {
    const inputProps = this.props.textFieldProps || {};

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
        <MuiDatePicker
          value={this.props.value}
          onChange={this.props.onChange}
          open={this.state.open}
          onOpen={this.handleOpen}
          onClose={this.handleClose}
          disabled={this.props.disabled}
          maxDate={this.props.maxDate ? new Date(this.props.maxDate) : null}
          minDate={this.props.minDate ? new Date(this.props.minDate) : null}
          size="small"
          inputFormat="__/__/____"
          renderInput={(params) => (
            <TextField
              {...this.setDate(params)}
              {...inputProps}
              size="small"
              onClick={this.handleOpen}
              onKeyDown={(e) => e.preventDefault(e)} // to not allow the user to make any manual inputs
            />
          )}
        />
      </LocalizationProvider>
    );
  }
}
