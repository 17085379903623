class ImageUtils {
  getCroppedImg = (imageFile, pixelCrop, fileName) => {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const context = canvas.getContext('2d');

    const image = new Image();
    const promise = new Promise((resolve, reject) => {
      image.addEventListener('load', function () {
        context.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height,
        );
        resolve();
      });

      image.src = URL.createObjectURL(imageFile);
    }).then(function () {
      return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          blob.name = fileName;
          resolve(blob);
        }, 'image/jpeg');
      });
    });
    return promise;
  };
  reduceImageResolution = (file, reductionPercentage, callback) => {
    const reader = new FileReader();

    reader.addEventListener('load', function (e) {
      const img = new Image();
      img.src = e.target.result;

      img.addEventListener('load', function () {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        // Calculate the new dimensions based on the reduction percentage
        const newWidth = img.width * (reductionPercentage / 100);
        const newHeight = img.height * (reductionPercentage / 100);

        // Set the canvas dimensions to the new size
        canvas.width = newWidth;
        canvas.height = newHeight;

        // Draw the image onto the canvas with the new dimensions
        context.drawImage(img, 0, 0, newWidth, newHeight);

        // Convert the canvas content back to a Blob (File)
        canvas.toBlob(function (blob) {
          const reducedImage = new File([blob], file.name, {
            type: file.type,
            lastModified: Date.now(),
          });

          // Pass the reduced image File to the callback function
          callback(reducedImage);
        }, file.type);
      });
    });

    // Read the input File as a data URL
    reader.readAsDataURL(file);
  };
  getImageResolution = (file, callback) => {
    const img = new Image();

    img.addEventListener('load', function () {
      // Get the width and height of the image
      const width = this.width;
      const height = this.height;

      // Invoke the callback with the resolution
      callback({ width, height });
    });

    // Set the source of the image element to the file's data URL
    img.src = URL.createObjectURL(file);
  };
}

export default new ImageUtils();
