export const LOADING_STATE = {
  NOT_LOADED: 'not-loaded',
  LOADING: 'loading',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  OUTDATED: 'outdated',
};

export function getCombinedLoadingState(loadingStates) {
  if (loadingStates.includes(LOADING_STATE.FAILED)) {
    return LOADING_STATE.FAILED;
  }

  if (loadingStates.includes(LOADING_STATE.OUTDATED)) {
    return LOADING_STATE.OUTDATED;
  }

  if (loadingStates.includes(LOADING_STATE.LOADING)) {
    return LOADING_STATE.LOADING;
  }

  if (loadingStates.includes(LOADING_STATE.NOT_LOADED)) {
    return LOADING_STATE.NOT_LOADED;
  }

  return LOADING_STATE.SUCCEEDED;
}
