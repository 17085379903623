import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';
import BilledItem from '~/models/billingState/BilledItem';

import DeliveryNote from '~/models/deliveries/DeliveryNote';

import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';
import { getCustomFieldOptions } from './getCustomFieldOptions';

/**
 * Factory function to create option mappings.
 * Option mappings get all distinct values for a filter from the delivery notes data.
 */
const createOptionMapping = (data, selectedProcessState) => {
  const getDistinctValuesByKey = (key) =>
    ArrayUtils.getDistinctValuesByKey(data, key);
  const getDistinctValuesFromArrayByKey = (key) =>
    ArrayUtils.getDistinctValuesFromArrayByKey(data, key);

  const keys = [
    [
      'ACCEPT_STATE',
      () =>
        AcceptStateCalculator.getAcceptStateOptionsBasedOnProcessStates(
          selectedProcessState,
        ),
    ],
    ['ARTICLE_NUMBERS', getDistinctValuesFromArrayByKey],
    ['ARTICLES', getDistinctValuesFromArrayByKey],
    ['COST_CENTERS', getDistinctValuesFromArrayByKey],
    ['FROM_SITE', getDistinctValuesByKey],
    ['NUMBER', getDistinctValuesByKey],
    ['PERMITTED_COST_CENTER_NAMES', getDistinctValuesFromArrayByKey],
    ['PERMITTED_TO_SITE_NAMES', getDistinctValuesFromArrayByKey],
    ['PROCESS_STATE', () => DeliveryNote.getProcessStateOptions()],
    ['RECIPIENT', getDistinctValuesByKey],
    ['SETTLED_STATUS', () => BilledItem.getSettledStatusOptions()],
    ['SUPPLIER', getDistinctValuesByKey],
    ['TO_SITE_RECIPIENT', getDistinctValuesByKey],
    ['TO_SITE_SUPPLIER', getDistinctValuesByKey],
  ];

  return keys.reduce((accumulator, [key, function_]) => {
    const property = DeliveryNote.PROPERTY[key];

    accumulator[property.STRING] = () =>
      typeof function_ === 'function' ? function_(property.KEY) : function_();

    // Worst fix ever for VGS-6623. Because of how the filters are implemented, it tries to call the getter for a filter with the GERMAN LABEL (!) 'Kostenstellen', while the label should be 'Kostenstelle'. This will go away with a proper refactoring of the filters, but for now we can only fix it with such a hack.
    if (property.STRING === 'Kostenstellen') {
      accumulator.Kostenstelle = () =>
        typeof function_ === 'function' ? function_(property.KEY) : function_();
    }

    return accumulator;
  }, {});
};

/**
 * Retrieves _all_ available options for a delivery notes filter (before filtering).
 */
export const getAllOptions = (
  data,
  selectedProcessState,
  filterName,
  customField,
) => {
  if (!data || data.length === 0) {
    return [];
  }

  if (customField) {
    // Make sure to return strings. Broken data like the number 0 will break the rendering of the Select otherwise.
    return getCustomFieldOptions(data, customField)
      .filter((v) => v != null)
      .map(String);
  }

  const optionMappings = createOptionMapping(data, selectedProcessState);

  const filterOptions = optionMappings[filterName];
  if (filterOptions) {
    // Call the filter function to get the distinct values for the filter.
    // Make sure to return strings. Broken data like the number 0 will break the rendering of the Select otherwise.
    return filterOptions()
      .filter((v) => v != null)
      .map(String);
  }

  Log.error(
    'Failed to find autocomplete option for selectable filter (getAllOptions). name: ' +
      filterName +
      ' | custom field key: ' +
      customField?.key,
  );

  return [];
};
