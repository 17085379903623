import { useSelector } from 'react-redux';
import { Grid, Stack } from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import { SiteIdentifiersTableComponent } from './SiteIdentifiersTableComponent';
import { useSiteIdentifiers } from './useSiteIdentifiers';

export const SiteIdentifiersTable = withErrorBoundary(({ siteId }) => {
  const sites = useSelector((state) => state.sites);

  const {
    blacklistedSites,
    handleBlacklistSites,
    handleDeleteMappings,
    handleSelect,
    handleWhitelistSites,
    isInProgress,
    selected,
    whitelistedSites,
  } = useSiteIdentifiers(siteId, sites);

  return (
    <Stack direction="column" gap={4} className="w-full p-2.5">
      <Grid item xs={12}>
        <SiteIdentifiersTableComponent
          blacklistStatus="whitelisted"
          isInProgress={isInProgress}
          isLoading={sites.sitesLoading}
          onBlacklistSite={handleBlacklistSites}
          onDeleteMappings={handleDeleteMappings}
          onSelectSite={handleSelect}
          selectedSites={selected.whitelistedSites}
          siteId={siteId}
          sites={whitelistedSites}
        />
      </Grid>
      <Grid item xs={12}>
        <SiteIdentifiersTableComponent
          blacklistStatus="blacklisted"
          isInProgress={isInProgress}
          isLoading={sites.sitesLoading}
          onBlacklistSite={handleWhitelistSites}
          onDeleteMappings={handleDeleteMappings}
          onSelectSite={handleSelect}
          selectedSites={selected.blacklistedSites}
          siteId={siteId}
          sites={blacklistedSites}
        />
      </Grid>
    </Stack>
  );
}, 'Standortbezeichnungen konnten nicht geladen werden.');
