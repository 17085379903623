import { dateUtils } from '~/utils/dateUtils';
import UnitUtils from '~/utils/unitUtils';

const deliveryNoteMetadata = [
  { NAME: 'LFS-Nr.', PATH: ['number'] },
  {
    NAME: 'LFS-Datum',
    PATH: ['dlnDate'],
    FORMATTER: (value) =>
      dateUtils.getFormattedDate_safe(value, dateUtils.DATE_FORMAT.DD_MM_YYYY),
  },
  {
    NAME: 'Lieferort (Lieferantenbezeichnung)',
    PATH: ['toSiteSupplier', 'name'],
  },
  {
    NAME: 'Adresse des Lieferorts (Straße)',
    PATH: ['toSiteSupplier', 'address', 'streetName'],
  },
  {
    NAME: 'Adresse des Lieferorts (Hausnummer)',
    PATH: ['toSiteSupplier', 'address', 'buildingNumber'],
  },
  {
    NAME: 'Adresse des Lieferorts (PLZ)',
    PATH: ['toSiteSupplier', 'address', 'postCode'],
  },
  {
    NAME: 'Adresse des Lieferorts (Ort)',
    PATH: ['toSiteSupplier', 'address', 'city'],
  },
  {
    NAME: 'Ansprechperson des Lieferorts',
    PATH: ['toSiteSupplier', 'tradeContact', 'personName'],
  },
  {
    NAME: 'Ansprechperson des Lieferorts (Telefonat)',
    PATH: ['toSiteSupplier', 'tradeContact', 'universalCommunication', 'phone'],
  },
  {
    NAME: 'Ansprechperson des Lieferorts (E-Mail)',
    PATH: ['toSiteSupplier', 'tradeContact', 'universalCommunication', 'email'],
  },
  {
    NAME: 'Bestätigter Lieferort',
    PATH: ['toSiteRecipient', 'name'],
    IGNORE_INITIAL_VALUE: true,
  },
  {
    NAME: 'Kostenstellen',
    PATH: ['costCenters'],
    IGNORE_INITIAL_VALUE: true,
    FORMATTER: (value) => value.filter(Boolean).join(', '),
  },
  { NAME: 'Bestellreferenz Auftragnehmer', PATH: ['sellerOrderReferences'] },
  { NAME: 'Bestellreferenz Auftraggeber', PATH: ['buyerOrderReferences'] },
  { NAME: 'Plan', PATH: ['constructionPlans'] },
  { NAME: 'Bauteil', PATH: ['constructionComponents'] },
  { NAME: 'Beladeort', PATH: ['fromSite', 'name'] },
  {
    NAME: 'Adresse des Beladeorts (Straße)',
    PATH: ['fromSite', 'address', 'streetName'],
  },
  {
    NAME: 'Adresse des Beladeorts (Hausnummer)',
    PATH: ['fromSite', 'address', 'buildingNumber'],
  },
  {
    NAME: 'Adresse des Beladeorts (PLZ)',
    PATH: ['fromSite', 'address', 'postCode'],
  },
  {
    NAME: 'Adresse des Beladeorts (Ort)',
    PATH: ['fromSite', 'address', 'city'],
  },
  {
    NAME: 'Ansprechperson des Beladeorts',
    PATH: ['fromSite', 'tradeContact', 'personName'],
  },
  {
    NAME: 'Ansprechperson des Beladeorts (Telefonat)',
    PATH: ['fromSite', 'tradeContact', 'universalCommunication', 'phone'],
  },
  {
    NAME: 'Ansprechperson des Beladeorts (E-Mail)',
    PATH: ['fromSite', 'tradeContact', 'universalCommunication', 'email'],
  },
  { NAME: 'Zufuhrart', PATH: ['movementMeans'] },
  { NAME: 'Lieferungsart', PATH: ['deliveryType'] },
  { NAME: 'Anzahl Paletten', PATH: ['palletAmount'] },
  {
    NAME: 'Kommentare',
    PATH: ['comments'],
    FORMATTER: (value) => value.join('\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0'),
  },
  {
    NAME: 'Wartezeit',
    PATH: ['waitingTime'],
    FORMATTER: (value) => value + ' min',
  },
  {
    NAME: 'Regiezeit',
    PATH: ['regieTime'],
    FORMATTER: (value) => value + ' min',
  },
  { NAME: 'Kunden-Nr.', PATH: ['buyerId'] },
  { NAME: 'Kfz-Kennzeichen', PATH: ['carrierLicensePlate'] },
  { NAME: 'Fahrzeug-Nr.', PATH: ['carrierVehicleNumber'] },
  { NAME: 'Projekt', PATH: ['project'] },
  {
    NAME: 'Versandnummern',
    PATH: ['shippingMarks'],
    FORMATTER: (shippingMarks) =>
      shippingMarks.map(({ value }) => value).join(', '),
  },
];

const deliveryNoteParties = [
  { NAME: 'Name des Lieferanten', PATH: ['supplier', 'name'] },
  {
    NAME: 'Adresse des Lieferanten (Straße)',
    PATH: ['supplier', 'address', 'streetName'],
  },
  {
    NAME: 'Adresse des Lieferanten (Hausnummer)',
    PATH: ['supplier', 'address', 'buildingNumber'],
  },
  {
    NAME: 'Adresse des Lieferanten (PLZ)',
    PATH: ['supplier', 'address', 'postCode'],
  },
  {
    NAME: 'Adresse des Lieferanten (Ort)',
    PATH: ['supplier', 'address', 'city'],
  },
  {
    NAME: 'Kontaktdaten des Lieferanten (Telefon)',
    PATH: ['supplier', 'universalCommunication', 'phone'],
  },
  {
    NAME: 'Kontaktdaten des Lieferanten (E-Mail)',
    PATH: ['supplier', 'universalCommunication', 'email'],
  },
  { NAME: 'Name des Spediteurs', PATH: ['carrier', 'name'] },
  {
    NAME: 'Adresse des Spediteurs (Straße)',
    PATH: ['carrier', 'address', 'streetName'],
  },
  {
    NAME: 'Adresse des Spediteurs (Hausnummer)',
    PATH: ['carrier', 'address', 'buildingNumber'],
  },
  {
    NAME: 'Adresse des Spediteurs (PLZ)',
    PATH: ['carrier', 'address', 'postCode'],
  },
  {
    NAME: 'Adresse des Spediteurs (Ort)',
    PATH: ['carrier', 'address', 'city'],
  },
  {
    NAME: 'Kontaktdaten des Spediteurs (Telefon)',
    PATH: ['carrier', 'universalCommunication', 'phone'],
  },
  {
    NAME: 'Kontaktdaten des Spediteurs (E-Mail)',
    PATH: ['carrier', 'universalCommunication', 'email'],
  },
  { NAME: 'Name des Abnehmers', PATH: ['recipient', 'name'] },
  {
    NAME: 'Adresse des Abnehmers (Straße)',
    PATH: ['recipient', 'address', 'streetName'],
  },
  {
    NAME: 'Adresse des Abnehmers (Hausnummer)',
    PATH: ['recipient', 'address', 'buildingNumber'],
  },
  {
    NAME: 'Adresse des Abnehmers (PLZ)',
    PATH: ['recipient', 'address', 'postCode'],
  },
  {
    NAME: 'Adresse des Abnehmers (Ort)',
    PATH: ['recipient', 'address', 'city'],
  },
  {
    NAME: 'Kontaktdaten des Abnehmers (Telefon)',
    PATH: ['recipient', 'universalCommunication', 'phone'],
  },
  {
    NAME: 'Kontaktdaten des Abnehmers (E-Mail)',
    PATH: ['recipient', 'universalCommunication', 'email'],
  },
];

const deliveryNoteContractParties = [
  { NAME: 'Name des Auftragnehmers', PATH: ['seller', 'name'] },
  {
    NAME: 'Adresse des Auftragnehmers (Straße)',
    PATH: ['seller', 'address', 'streetName'],
  },
  {
    NAME: 'Adresse des Auftragnehmers (Hausnummer)',
    PATH: ['seller', 'address', 'buildingNumber'],
  },
  {
    NAME: 'Adresse des Auftragnehmers (PLZ)',
    PATH: ['seller', 'address', 'postCode'],
  },
  {
    NAME: 'Adresse des Auftragnehmers (Ort)',
    PATH: ['seller', 'address', 'city'],
  },
  {
    NAME: 'Kontaktdaten des Auftragnehmers (Telefon)',
    PATH: ['seller', 'universalCommunication', 'phone'],
  },
  {
    NAME: 'Kontaktdaten des Auftragnehmers (E-Mail)',
    PATH: ['seller', 'universalCommunication', 'email'],
  },
  { NAME: 'Name des Auftraggebers', PATH: ['buyer', 'name'] },
  {
    NAME: 'Adresse des Auftraggebers (Straße)',
    PATH: ['buyer', 'address', 'streetName'],
  },
  {
    NAME: 'Adresse des Auftraggebers (Hausnummer)',
    PATH: ['buyer', 'address', 'buildingNumber'],
  },
  {
    NAME: 'Adresse des Auftraggebers (PLZ)',
    PATH: ['buyer', 'address', 'postCode'],
  },
  {
    NAME: 'Adresse des Auftraggebers (Ort)',
    PATH: ['buyer', 'address', 'city'],
  },
  {
    NAME: 'Kontaktdaten des Auftraggebers (Telefon)',
    PATH: ['buyer', 'universalCommunication', 'phone'],
  },
  {
    NAME: 'Kontaktdaten des Auftraggebers (E-Mail)',
    PATH: ['buyer', 'universalCommunication', 'email'],
  },
];

const deliveryNoteTimes = [
  {
    NAME: 'Beginn Beladung (Geplant)',
    PATH: ['execution', 'beginLoadingPlanned'],
    FORMATTER: (value) =>
      dateUtils.getFormattedDate_safe(
        value,
        dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
      ),
  },
  {
    NAME: 'Ende Beladung (Geplant)',
    PATH: ['execution', 'endLoadingPlanned'],
    FORMATTER: (value) =>
      dateUtils.getFormattedDate_safe(
        value,
        dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
      ),
  },
  {
    NAME: 'Abfahrt (Geplant)',
    PATH: ['execution', 'departurePlanned'],
    FORMATTER: (value) =>
      dateUtils.getFormattedDate_safe(
        value,
        dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
      ),
  },
  {
    NAME: 'Ankunft (Geplant)',
    PATH: ['execution', 'arrivedPlanned'],
    FORMATTER: (value) =>
      dateUtils.getFormattedDate_safe(
        value,
        dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
      ),
  },
  {
    NAME: 'Beginn Entladung (Geplant)',
    PATH: ['execution', 'beginUnloadingPlanned'],
    FORMATTER: (value) =>
      dateUtils.getFormattedDate_safe(
        value,
        dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
      ),
  },
  {
    NAME: 'Ende Entladung (Geplant)',
    PATH: ['execution', 'endUnloadingPlanned'],
    FORMATTER: (value) =>
      dateUtils.getFormattedDate_safe(
        value,
        dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
      ),
  },
  {
    NAME: 'Beginn Lieferung (Geplant)',
    PATH: ['execution', 'beginExecutionPlanned'],
    FORMATTER: (value) =>
      dateUtils.getFormattedDate_safe(
        value,
        dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
      ),
  },
  {
    NAME: 'Ende Lieferung (Geplant)',
    PATH: ['execution', 'endExecutionPlanned'],
    FORMATTER: (value) =>
      dateUtils.getFormattedDate_safe(
        value,
        dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
      ),
  },
  {
    NAME: 'Beginn Beladung',
    PATH: ['execution', 'beginLoading'],
    FORMATTER: (value) =>
      dateUtils.getFormattedDate_safe(
        value,
        dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
      ),
  },
  {
    NAME: 'Ende Beladung',
    PATH: ['execution', 'endLoading'],
    FORMATTER: (value) =>
      dateUtils.getFormattedDate_safe(
        value,
        dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
      ),
  },
  {
    NAME: 'Abfahrt',
    PATH: ['execution', 'departure'],
    FORMATTER: (value) =>
      dateUtils.getFormattedDate_safe(
        value,
        dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
      ),
  },
  {
    NAME: 'Ankunft',
    PATH: ['execution', 'arrived'],
    FORMATTER: (value) =>
      dateUtils.getFormattedDate_safe(
        value,
        dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
      ),
  },
  {
    NAME: 'Beginn Entladung',
    PATH: ['execution', 'beginUnloading'],
    FORMATTER: (value) =>
      dateUtils.getFormattedDate_safe(
        value,
        dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
      ),
  },
  {
    NAME: 'Ende Entladung',
    PATH: ['execution', 'endUnloading'],
    FORMATTER: (value) =>
      dateUtils.getFormattedDate_safe(
        value,
        dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
      ),
  },
  {
    NAME: 'Beginn Lieferung',
    PATH: ['execution', 'beginExecution'],
    FORMATTER: (value) =>
      dateUtils.getFormattedDate_safe(
        value,
        dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
      ),
  },
  {
    NAME: 'Ende Lieferung',
    PATH: ['execution', 'endExecution'],
    FORMATTER: (value) =>
      dateUtils.getFormattedDate_safe(
        value,
        dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
      ),
  },
];

const articleMetadata = [
  { NAME: 'Artikel-Nr.', PATH: ['articles', '*', 'number'] },
  { NAME: 'Artikel - Name', PATH: ['articles', '*', 'type'] },
  {
    NAME: 'Artikel - Menge',
    PATH: ['articles', '*', 'amount', 'value'],
    FORMATTER: UnitUtils.formatDe_safe,
  },
  {
    NAME: 'Artikel - Menge (Einheit)',
    PATH: ['articles', '*', 'amount', 'unit'],
    FORMATTER: UnitUtils.getDescriptiveUnit,
  },
  {
    NAME: 'Artikel - Gewicht',
    PATH: ['articles', '*', 'weight', 'value'],
    FORMATTER: UnitUtils.formatDe_safe,
  },
  {
    NAME: 'Artikel - Gewicht (Einheit)',
    PATH: ['articles', '*', 'weight', 'unit'],
    FORMATTER: UnitUtils.getDescriptiveUnit,
  },
  { NAME: 'Artikel - EAN', PATH: ['articles', '*', 'ean'] },
  { NAME: 'Artikel - Beschreibung', PATH: ['articles', '*', 'description'] },
  { NAME: 'Artikel - Kommentar', PATH: ['articles', '*', 'comment'] },
  {
    NAME: 'Artikel - Versandnummern',
    PATH: ['articles', '*', 'shippingMarks'],
    FORMATTER: (shippingMarks) =>
      shippingMarks.map((shippingMark) => shippingMark.value).join(', '),
  },
];

const articleManufacturer = [
  {
    NAME: 'Artikel - Name des Herstellers',
    PATH: ['articles', '*', 'manufacturer', 'name'],
  },
  {
    NAME: 'Artikel - Adresse des Herstellers (Straße)',
    PATH: ['articles', '*', 'manufacturer', 'address', 'streetName'],
  },
  {
    NAME: 'Artikel - Adresse des Herstellers (Hausnummer)',
    PATH: ['articles', '*', 'manufacturer', 'address', 'buildingNumber'],
  },
  {
    NAME: 'Artikel - Adresse des Herstellers (PLZ)',
    PATH: ['articles', '*', 'manufacturer', 'address', 'postCode'],
  },
  {
    NAME: 'Artikel - Adresse des Herstellers (Ort)',
    PATH: ['articles', '*', 'manufacturer', 'address', 'city'],
  },
];

const articleWeighing = [
  {
    NAME: 'Artikel - Bruttogewicht',
    PATH: ['articles', '*', 'weighingInformation', 'gross', 'weight', 'value'],
    FORMATTER: UnitUtils.formatDe_safe,
  },
  {
    NAME: 'Artikel - Bruttogewicht (Einheit)',
    PATH: ['articles', '*', 'weighingInformation', 'gross', 'weight', 'unit'],
    FORMATTER: UnitUtils.getDescriptiveUnit,
  },
  {
    NAME: 'Artikel - Bruttogewicht (Waage)',
    PATH: ['articles', '*', 'weighingInformation', 'gross', 'scaleId'],
  },
  {
    NAME: 'Artikel - Taragewicht',
    PATH: ['articles', '*', 'weighingInformation', 'tare', 'weight', 'value'],
    FORMATTER: UnitUtils.formatDe_safe,
  },
  {
    NAME: 'Artikel - Taragewicht (Einheit)',
    PATH: ['articles', '*', 'weighingInformation', 'tare', 'weight', 'unit'],
    FORMATTER: UnitUtils.getDescriptiveUnit,
  },
  {
    NAME: 'Artikel - Taragewicht (Waage)',
    PATH: ['articles', '*', 'weighingInformation', 'tare', 'scaleId'],
  },
  {
    NAME: 'Artikel - Wäger',
    PATH: ['articles', '*', 'weighingInformation', 'person', 'name'],
  },
];

const articleConcrete = [
  {
    NAME: 'Artikel - Expositionsklasse',
    PATH: ['articles', '*', 'concrete', 'exposureClass'],
  },
  {
    NAME: 'Artikel - Feuchtigkeitsklasse',
    PATH: ['articles', '*', 'concrete', 'moistureClass'],
  },
  {
    NAME: 'Artikel - Festigkeitsklasse',
    PATH: ['articles', '*', 'concrete', 'strengthClass'],
  },
  {
    NAME: 'Artikel - Konsistenzklasse',
    PATH: ['articles', '*', 'concrete', 'consistency'],
  },
  {
    NAME: 'Artikel - Verwendung (Beton)',
    PATH: ['articles', '*', 'concrete', 'usage'],
  },
  {
    NAME: 'Artikel - Klasse des Chloridgehaltes',
    PATH: ['articles', '*', 'concrete', 'chlorideClass'],
  },
  {
    NAME: 'Artikel - Größtkorn',
    PATH: ['articles', '*', 'concrete', 'largestGrain'],
  },
  {
    NAME: 'Artikel - Festigkeitsentwicklung',
    PATH: ['articles', '*', 'concrete', 'strengthDevelopment'],
  },
  {
    NAME: 'Artikel - W/Z Verhältnis',
    PATH: ['articles', '*', 'concrete', 'waterCementRatio'],
    FORMATTER: UnitUtils.formatDe_safe,
  },
  {
    NAME: 'Artikel - Sollmenge',
    PATH: ['articles', '*', 'delivery', 'requested', 'value'],
    FORMATTER: UnitUtils.formatDe_safe,
  },
  {
    NAME: 'Artikel - Sollmenge (Einheit)',
    PATH: ['articles', '*', 'delivery', 'requested', 'unit'],
    FORMATTER: UnitUtils.getDescriptiveUnit,
  },
  {
    NAME: 'Artikel - Istmenge',
    PATH: ['articles', '*', 'delivery', 'actual', 'value'],
    FORMATTER: UnitUtils.formatDe_safe,
  },
  {
    NAME: 'Artikel - Istmenge (Einheit)',
    PATH: ['articles', '*', 'delivery', 'actual', 'unit'],
    FORMATTER: UnitUtils.getDescriptiveUnit,
  },
  {
    NAME: 'Artikel - Restmenge',
    PATH: ['articles', '*', 'delivery', 'remaining', 'value'],
    FORMATTER: UnitUtils.formatDe_safe,
  },
  {
    NAME: 'Artikel - Restmenge (Einheit)',
    PATH: ['articles', '*', 'delivery', 'remaining', 'unit'],
    FORMATTER: UnitUtils.getDescriptiveUnit,
  },
];

const articleMaterialComponents = [
  {
    NAME: 'Artikel - Inhaltsstoff Name',
    PATH: ['articles', '*', 'concrete', 'components', '*', 'name'],
  },
  {
    NAME: 'Artikel - Inhaltsstoff Menge',
    PATH: ['articles', '*', 'concrete', 'components', '*', 'volume', 'value'],
    FORMATTER: UnitUtils.formatDe_safe,
  },
  {
    NAME: 'Artikel - Inhaltsstoff Menge (Einheit)',
    PATH: ['articles', '*', 'concrete', 'components', '*', 'volume', 'unit'],
    FORMATTER: UnitUtils.getDescriptiveUnit,
  },
  {
    NAME: 'Artikel - Inhaltsstoff Angefragte Menge',
    PATH: [
      'articles',
      '*',
      'concrete',
      'components',
      '*',
      'volumeRequested',
      'value',
    ],
    FORMATTER: UnitUtils.formatDe_safe,
  },
  {
    NAME: 'Artikel - Inhaltsstoff Angefragte Menge (Einheit)',
    PATH: [
      'articles',
      '*',
      'concrete',
      'components',
      '*',
      'volumeRequested',
      'unit',
    ],
    FORMATTER: UnitUtils.getDescriptiveUnit,
  },
  {
    NAME: 'Artikel - Inhaltsstoff Verbleibende Menge',
    PATH: [
      'articles',
      '*',
      'concrete',
      'components',
      '*',
      'remainingConcrete',
      'value',
    ],
    FORMATTER: UnitUtils.formatDe_safe,
  },
  {
    NAME: 'Artikel - Inhaltsstoff Verbleibende Menge (Einheit)',
    PATH: [
      'articles',
      '*',
      'concrete',
      'components',
      '*',
      'remainingConcrete',
      'unit',
    ],
    FORMATTER: UnitUtils.getDescriptiveUnit,
  },
  {
    NAME: 'Artikel - Inhaltsstoff Hersteller',
    PATH: [
      'articles',
      '*',
      'concrete',
      'components',
      '*',
      'manufacturer',
      'name',
    ],
  },
  {
    NAME: 'Artikel - Inhaltsstoff Kommentar',
    PATH: ['articles', '*', 'concrete', 'components', '*', 'comment'],
  },
  {
    NAME: 'Artikel - Inhaltsstoff Grund',
    PATH: ['articles', '*', 'concrete', 'components', '*', 'reason'],
  },
  {
    NAME: 'Artikel - Inhaltsstoff Ergebnis',
    PATH: ['articles', '*', 'concrete', 'components', '*', 'result'],
  },
];

const articleMaterialAddedLater = [
  {
    NAME: 'Artikel - Nachträgl. Inhaltsstoff Name',
    PATH: ['articles', '*', 'concrete', 'posteriorAdded', '*', 'name'],
  },
  {
    NAME: 'Artikel - Nachträgl. Inhaltsstoff Menge',
    PATH: [
      'articles',
      '*',
      'concrete',
      'posteriorAdded',
      '*',
      'volume',
      'value',
    ],
    FORMATTER: UnitUtils.formatDe_safe,
  },
  {
    NAME: 'Artikel - Nachträgl. Inhaltsstoff Menge (Einheit)',
    PATH: [
      'articles',
      '*',
      'concrete',
      'posteriorAdded',
      '*',
      'volume',
      'unit',
    ],
    FORMATTER: UnitUtils.getDescriptiveUnit,
  },
  {
    NAME: 'Artikel - Nachträgl. Inhaltsstoff Angefragte Menge',
    PATH: [
      'articles',
      '*',
      'concrete',
      'posteriorAdded',
      '*',
      'volumeRequested',
      'value',
    ],
    FORMATTER: UnitUtils.formatDe_safe,
  },
  {
    NAME: 'Artikel - Nachträgl. Inhaltsstoff Angefragte Menge (Einheit)',
    PATH: [
      'articles',
      '*',
      'concrete',
      'posteriorAdded',
      '*',
      'volumeRequested',
      'unit',
    ],
    FORMATTER: UnitUtils.getDescriptiveUnit,
  },
  {
    NAME: 'Artikel - Nachträgl. Inhaltsstoff Verbleibende Menge',
    PATH: [
      'articles',
      '*',
      'concrete',
      'posteriorAdded',
      '*',
      'remainingConcrete',
      'value',
    ],
    FORMATTER: UnitUtils.formatDe_safe,
  },
  {
    NAME: 'Artikel - Nachträgl. Inhaltsstoff Verbleibende Menge (Einheit)',
    PATH: [
      'articles',
      '*',
      'concrete',
      'posteriorAdded',
      '*',
      'remainingConcrete',
      'unit',
    ],
    FORMATTER: UnitUtils.getDescriptiveUnit,
  },
  {
    NAME: 'Artikel - Nachträgl. Inhaltsstoff Hersteller',
    PATH: [
      'articles',
      '*',
      'concrete',
      'posteriorAdded',
      '*',
      'manufacturer',
      'name',
    ],
  },
  {
    NAME: 'Artikel - Nachträgl. Inhaltsstoff Kommentar',
    PATH: ['articles', '*', 'concrete', 'posteriorAdded', '*', 'comment'],
  },
  {
    NAME: 'Artikel - Nachträgl. Inhaltsstoff Grund',
    PATH: ['articles', '*', 'concrete', 'posteriorAdded', '*', 'reason'],
  },
  {
    NAME: 'Artikel - Nachträgl. Inhaltsstoff Ergebnis',
    PATH: ['articles', '*', 'concrete', 'posteriorAdded', '*', 'result'],
  },
];

/**
 * Paths included when displaying relevant changes to the user in the list of changes in the DLN history:
 * - delivery note
 *   - metadata
 *   - parties
 *   - contract parties
 *   - times
 * - article
 *   - metadata
 *   - manufacturer
 *   - weighing
 *   - concrete
 *   - material components
 *   - material addedlater
 */
export const CHANGES_LIST_INCLUDED_PATHS = [
  ...deliveryNoteMetadata,
  ...deliveryNoteParties,
  ...deliveryNoteContractParties,
  ...deliveryNoteTimes,
  ...articleMetadata,
  ...articleManufacturer,
  ...articleWeighing,
  ...articleConcrete,
  ...articleMaterialComponents,
  ...articleMaterialAddedLater,
];
