import React from 'react';
import { connect } from 'react-redux';
import { withErrorBoundary } from '~/ui/atoms';
import MapperService from '~/services/mapper.service';
import { SettingsTable } from '../../SettingsTable';
import LocalStorageService from '~/services/localStorage.service';
import InvoicesService from '~/services/invoices.service';

const columns = [
  {
    field: 'sellerAssignedId',
    headerName: 'Artikelnummer',
    width: 150,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'name',
    headerName: 'Artikelname',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'reason',
    headerName: 'Grund',
    width: 300,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'sellerCompanies',
    headerName: 'Auftragnehmer',
    width: 400,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'buyerCompanies',
    headerName: 'Auftraggeber',
    width: 400,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 400,
    sortable: true,
    resizableText: true,
  },
];

const mapStateToProps = (state) => ({
  invoices: state.invoices,
  companies: state.companies,
  dataExchanges: state.dataExchanges,
});

class InvoiceCheckIgnoredArticleTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadData();
    }
  }

  loadData() {
    this.setState({
      rows: this.props.invoices.invoiceCheckIgnoredArticles.map(
        (invoiceCheckIgnoredArticle) => {
          const row = {
            id: invoiceCheckIgnoredArticle.id,
            sellerAssignedId: invoiceCheckIgnoredArticle.sellerAssignedId,
            name: invoiceCheckIgnoredArticle.name,
            reason: invoiceCheckIgnoredArticle.reason,
            sellerCompanies: invoiceCheckIgnoredArticle.sellerCompanies
              .map(
                (sellerCompany) =>
                  this.props.dataExchanges.dataExchangeCompanies.find(
                    (company) => company.id === sellerCompany,
                  )?.name ?? '',
              )
              .join(', '),
            buyerCompanies: invoiceCheckIgnoredArticle.buyerCompanies
              .map(
                (buyerCompany) =>
                  this.props.companies.companies.find(
                    (company) => company.id === buyerCompany,
                  )?.name ?? '',
              )
              .join(', '),
          };

          return MapperService.addSearchString(row, [
            'id',
            'sellerAssignedId',
            'name',
            'reason',
            'sellerCompanies',
            'buyerCompanies',
          ]);
        },
      ),
    });
  }

  render() {
    return (
      <SettingsTable
        entity="invoiceCheckIgnoredArticle"
        title="Ignorierte Artikel"
        rows={this.state.rows}
        columns={columns}
        items={this.props.invoices.invoiceCheckIgnoredArticles}
        sortBy="sellerAssignedId"
        loading={this.props.invoices.invoiceCheckIgnoredArticlesLoading}
        refreshData={InvoicesService.refreshInvoiceCheckIgnoredArticles}
        localStorageKey={
          LocalStorageService.INVOICE_CHECK_IGNORED_ARTICLE_TABLE
        }
      />
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(InvoiceCheckIgnoredArticleTable),
  'Ignorierte Artikel konnten nicht geladen werden.',
);
