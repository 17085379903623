import React from 'react';
import { withErrorBoundary } from '~/ui/atoms';
import InvoiceCheck from '../InvoiceCheck';
import ArrayUtils from '~/utils/arrayUtils';
import InvoiceCheckCategory from './InvoiceCheckCategory';
import { DeliveryNoteLinks } from '~/utils/componentUtils';
import InvoiceCheckResult from '~/models/invoices/InvoiceCheckResult';
import { pluralizeWord } from '~/utils/pluralize';

class ArticleExistsCheck extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groupedErrorArticles: [],
      groupedSuccessArticles: [],
    };
  }

  componentDidMount() {
    this.loadGroupedArticles();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.category.errorChecks.length !==
        prevProps.category.errorChecks.length ||
      this.props.category.successChecks.length !==
        prevProps.category.successChecks.length
    ) {
      this.loadGroupedArticles();
    }
  }

  loadGroupedArticles() {
    this.setState({
      groupedErrorArticles: this.getGroupedArticles(
        this.props.category.errorChecks,
      ),
      groupedSuccessArticles: this.getGroupedArticles(
        this.props.category.successChecks,
      ),
    });
  }

  getGroupedArticles(checkResults) {
    const articles = ArrayUtils.removeDuplicates(
      checkResults.map((checkResult) => checkResult.articleName),
    );

    return articles.map((article) => {
      return {
        article,
        checkResults: checkResults.filter(
          (checkResult) => checkResult.articleName === article,
        ),
      };
    });
  }

  render() {
    const errorInvoiceChecks = (
      <>
        {this.state.groupedErrorArticles.map((groupedArticle, index) => (
          <InvoiceCheck
            key={index}
            checkResults={groupedArticle.checkResults}
            checkResultFormatter={(checkResult) => (
              <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />
            )}
          >
            <div className="w-full">
              Der Artikel{' '}
              <span className="text-error500">
                "{groupedArticle.article ?? ''}"
              </span>{' '}
              ist in der Rechnung referenziert, konnte aber in{' '}
              {pluralizeWord(
                InvoiceCheckResult.getDeliveryNoteNumbers(
                  groupedArticle.checkResults,
                ).length,
                'Lieferung',
                true,
              )}{' '}
              nicht gefunden werden.
            </div>
          </InvoiceCheck>
        ))}
      </>
    );

    const successInvoiceChecks = (
      <>
        {this.state.groupedSuccessArticles.map((groupedArticle, index) => (
          <InvoiceCheck
            key={index}
            checkResults={groupedArticle.checkResults}
            checkResultFormatter={(checkResult) => (
              <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />
            )}
          >
            <div className="w-full">
              Der Artikel "{groupedArticle.article}" ist in der Rechnung
              referenziert und konnte in{' '}
              {pluralizeWord(
                InvoiceCheckResult.getDeliveryNoteNumbers(
                  groupedArticle.checkResults,
                ).length,
                'Lieferung',
                true,
              )}{' '}
              gefunden werden.
            </div>
          </InvoiceCheck>
        ))}
      </>
    );

    return (
      <InvoiceCheckCategory
        category={this.props.category}
        errorInvoiceChecks={errorInvoiceChecks}
        successInvoiceChecks={successInvoiceChecks}
      />
    );
  }
}

export default withErrorBoundary(
  ArticleExistsCheck,
  'Prüfschritt "Artikel vorhanden" konnte nicht geladen werden.',
);
