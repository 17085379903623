import ArrayUtils from '~/utils/arrayUtils';

export default class InvoiceCheckIgnoredArticle {
  constructor(invoiceCheckIgnoredArticle) {
    this.id = invoiceCheckIgnoredArticle?.id ?? '';
    this.sellerAssignedId =
      invoiceCheckIgnoredArticle?.seller_assigned_id ?? '';
    this.name = invoiceCheckIgnoredArticle?.name ?? '';
    this.reason = invoiceCheckIgnoredArticle?.reason ?? '';
    this.sellerCompanies = invoiceCheckIgnoredArticle?.seller_companies ?? [];
    this.buyerCompanies = invoiceCheckIgnoredArticle?.buyer_companies ?? [];
  }

  static getDifferentValues(
    invoiceCheckIgnoredArticleA,
    invoiceCheckIgnoredArticleB,
  ) {
    const differentValues = [];

    if (invoiceCheckIgnoredArticleA?.id !== invoiceCheckIgnoredArticleB?.id) {
      differentValues.push('ID');
    }

    if (
      invoiceCheckIgnoredArticleA?.sellerAssignedId !==
      invoiceCheckIgnoredArticleB?.sellerAssignedId
    ) {
      differentValues.push('Artikelnummer');
    }

    if (
      invoiceCheckIgnoredArticleA?.name !== invoiceCheckIgnoredArticleB?.name
    ) {
      differentValues.push('Artikelname');
    }

    if (
      invoiceCheckIgnoredArticleA?.reason !==
      invoiceCheckIgnoredArticleB?.reason
    ) {
      differentValues.push('Grund');
    }

    const differentSellerCompanies = ArrayUtils.getDifference(
      invoiceCheckIgnoredArticleA?.sellerCompanies,
      invoiceCheckIgnoredArticleB?.sellerCompanies,
    );
    if (
      differentSellerCompanies[0].length > 0 ||
      differentSellerCompanies[1].length > 0
    ) {
      differentValues.push('Auftragnehmer');
    }

    const differentBuyerCompanies = ArrayUtils.getDifference(
      invoiceCheckIgnoredArticleA?.buyerCompanies,
      invoiceCheckIgnoredArticleB?.buyerCompanies,
    );
    if (
      differentBuyerCompanies[0].length > 0 ||
      differentBuyerCompanies[1].length > 0
    ) {
      differentValues.push('Auftraggeber');
    }

    return differentValues;
  }
}
