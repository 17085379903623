import React from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from '@mui/material';
import VehicleService from '~/services/vehicle.service';
import Select from '~/components/baseComponents/inputs/select/Select';
import cloneDeep from 'lodash/cloneDeep';
import ToastService from '~/services/toast.service';
import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';
import Vehicle from '~/models/masterdata/Vehicle';
import BasicForm from '~/components/BasicForm';
import { OrganisationalGroupPaths } from '../paths/OrganisationalGroupPaths';
import ComplexPaginatedEntityMultiPicker from '~/components/baseComponents/inputs/select/ComplexPaginatedEntityMultiPicker';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import OrganisationalGroupService from '~/services/organisationalGroup.service';
import MasterDataService from '~/services/masterData.service';
import { LOADING_STATE } from '~/constants/LoadingState';
import PermissionGrantEntityTable from '../permissionGrant/PermissionGrantEntityTable';
import UserUtils from '~/utils/userUtils';
import FunctionUtils from '~/utils/functionUtils';
import ObjectUtils from '~/utils/objectUtils';

const mapStateToProps = (state) => ({
  companies: state.companies,
  userinfo: state.userinfo,
});
const mapDispatchToProps = () => ({});

class VehicleForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      vehicle: this.getDefaultVehicle(),
      vehicleLoading: LOADING_STATE.NOT_LOADED,
      submittingForm: false,
    };
  }

  componentDidMount() {
    this.resetForm(true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.vehicle) !== JSON.stringify(this.props.vehicle)
    ) {
      this.resetForm(
        ObjectUtils.JSONstringifyDiffIgnoringProperty(
          this.props.vehicle,
          prevProps.vehicle,
          'permissionGrantsFrom',
        ),
      );
    }

    if (
      JSON.stringify(this.props.companies.companies) !==
      JSON.stringify(prevProps.companies.companies)
    ) {
      this.resetDefaultValues();
    }
  }

  resetForm(resetGeneralVehicleInformation) {
    if (!resetGeneralVehicleInformation) {
      // Only reset the granted permissions because this is the only thing that has been changed.
      const newVehicle = cloneDeep(this.state.vehicle);
      newVehicle.permissionGrantsFrom = this.props.vehicle
        ? this.props.vehicle.permissionGrantsFrom
        : this.getDefaultVehicle().permissionGrantsFrom;

      this.setState({
        vehicle: newVehicle,
      });

      return;
    }

    this.setState({
      vehicle: this.props.vehicle ?? this.getDefaultVehicle(),
    });

    if (this.props.vehicle && !this.props.vehicle.additionalDataInitiated) {
      this.refreshVehicle();
    }
  }

  resetDefaultValues() {
    const newVehicle = cloneDeep(this.state.vehicle);

    newVehicle.company ||= this.props.userinfo.userinfo.company?.id;

    this.setState({
      vehicle: newVehicle,
    });
  }

  formSuccess = async (event) => {
    event.preventDefault();

    if (!this.state.vehicle.licensePlate.isValid()) {
      ToastService.error([
        ToastService.MESSAGE.VEHICLE_INVALID_INPUT,
        'Bitte überprüfe deine Eingabe und versuche es erneut.',
      ]);
      Log.productAnalyticsEvent(
        'Invalid license plate',
        Log.FEATURE.VEHICLE,
        Log.TYPE.FAILED_VALIDATION,
      );
      return;
    }

    this.setState({
      submittingForm: true,
    });

    const body = {
      license_plate: this.state.vehicle.licensePlate.getConcatenatedName(),
      company_id: this.state.vehicle.company,
      is_active: this.state.vehicle.active,
    };

    if (this.renderForCreate()) {
      body.org_units = this.state.vehicle.organisationalGroups;
    }

    if (
      MasterDataService.propertiesAreMissing(
        body,
        ['company_id'],
        Log.FEATURE.VEHICLE,
      )
    ) {
      this.setState({
        submittingForm: false,
      });
      return;
    }

    Log.info('Submit vehicle form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.VEHICLE);

    if (this.renderForCreate()) {
      const [data, error] = await promiseHandler(
        VehicleService.createNewVehicle(body),
      );

      if (error) {
        if (error.response?.status === 409) {
          ToastService.httpError(
            [
              ToastService.MESSAGE.VEHICLE_CREATION_FAILED_DUPLICATE,
              'Bitte gib ein anderes Kennzeichen ein und versuche es erneut.',
            ],
            error.response,
          );
          Log.productAnalyticsEvent(
            'Failed to create due to duplicate license plate',
            Log.FEATURE.VEHICLE,
            Log.TYPE.ERROR,
          );
        } else {
          ToastService.httpError(
            [ToastService.MESSAGE.VEHICLE_CREATION_FAILED],
            error.response,
          );
          Log.productAnalyticsEvent(
            'Failed to create',
            Log.FEATURE.VEHICLE,
            Log.TYPE.ERROR,
          );
          Log.error('Failed to create vehicle.', error);
        }

        this.setState({
          submittingForm: false,
        });
        return;
      }
    } else {
      const [data, error] = await promiseHandler(
        VehicleService.updateVehicle(this.state.vehicle.id, body),
      );

      if (error) {
        if (error.response?.status === 409) {
          ToastService.httpError(
            [
              ToastService.MESSAGE.VEHICLE_UPDATE_FAILED_DUPLICATE,
              'Bitte gib ein anderes Kennzeichen ein und versuche es erneut.',
            ],
            error.response,
          );
          Log.productAnalyticsEvent(
            'Failed to update due to duplicate license plate',
            Log.FEATURE.VEHICLE,
            Log.TYPE.ERROR,
          );
        } else {
          ToastService.httpError(
            [ToastService.MESSAGE.VEHICLE_UPDATE_FAILED],
            error.response,
          );
          Log.productAnalyticsEvent(
            'Failed to update',
            Log.FEATURE.VEHICLE,
            Log.TYPE.ERROR,
          );
        }

        Log.error(
          'Failed to update vehicle. id: ' + this.state.vehicle.id,
          error,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }

      const [response2, error2] = await promiseHandler(
        OrganisationalGroupService.updateParentOrganisationalGroups(
          this.props.vehicle.id,
          PermissionGrant.ENTITY_TYPE.VEHICLE.KEY,
          this.props.vehicle.organisationalGroups,
          this.state.vehicle.organisationalGroups,
        ),
      );

      if (error2) {
        ToastService.httpError(
          ['Organisations-Gruppen konnten nicht geändert werden.'],
          error2.response,
        );
        Log.productAnalyticsEvent(
          'Failed to update organisational groups',
          Log.FEATURE.VEHICLE,
          Log.TYPE.ERROR,
        );
      }
    }

    this.setState({
      submittingForm: false,
    });

    this.props.closeForm();
    this.resetForm(true);
    VehicleService.refreshVehicles();
  };
  formAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.VEHICLE);
    this.props.closeForm();
    this.resetForm(true);
  };

  getDefaultVehicle() {
    const vehicle = new Vehicle();

    vehicle.company = this.props.userinfo.userinfo.company?.id;

    return vehicle;
  }

  renderForCreate = () => {
    return this.props.type === 'create';
  };
  handleChangeCompany = (event) => {
    const newVehicle = cloneDeep(this.state.vehicle);

    newVehicle.company = event.target.value;

    Log.info(
      'Change form value of company',
      { from: this.state.vehicle.company, to: newVehicle.company },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change company', Log.FEATURE.VEHICLE);

    this.setState({
      vehicle: newVehicle,
    });
  };
  handleInputChange = (event) => {
    const newVehicle = cloneDeep(this.state.vehicle);

    switch (event.target.name) {
      case 'license_plate_city': {
        newVehicle.licensePlate.city = event.target.value;
        Log.info(
          'Change form value of city',
          {
            from: this.state.vehicle.licensePlate.city,
            to: newVehicle.licensePlate.city,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'vehicle_change_city',
          Log.productAnalyticsEvent,
          ['Change license plate city', Log.FEATURE.VEHICLE],
        );
        break;
      }

      case 'license_plate_letters': {
        newVehicle.licensePlate.letters = event.target.value;
        Log.info(
          'Change form value of letters',
          {
            from: this.state.vehicle.licensePlate.letters,
            to: newVehicle.licensePlate.letters,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'vehicle_change_letters',
          Log.productAnalyticsEvent,
          ['Change license plate letters', Log.FEATURE.VEHICLE],
        );
        break;
      }

      case 'license_plate_numbers': {
        newVehicle.licensePlate.numbers = event.target.value;
        Log.info(
          'Change form value of numbers',
          {
            from: this.state.vehicle.licensePlate.numbers,
            to: newVehicle.licensePlate.numbers,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'vehicle_change_numbers',
          Log.productAnalyticsEvent,
          ['Change license plate numbers', Log.FEATURE.VEHICLE],
        );
        break;
      }
    }

    this.setState({
      vehicle: newVehicle,
    });
  };
  handleChangeOrganisationalGroups = (organisationalGroups) => {
    const newVehicle = cloneDeep(this.state.vehicle);

    newVehicle.organisationalGroups = organisationalGroups.map(
      (organisationalGroup) => organisationalGroup.id,
    );

    Log.info(
      'Change form value of organisational groups',
      {
        from: this.state.vehicle.organisationalGroups,
        to: newVehicle.organisationalGroups,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change organisational groups',
      Log.FEATURE.VEHICLE,
    );

    this.setState({
      vehicle: newVehicle,
    });
  };
  refreshVehicle = async () => {
    this.setState({
      vehicleLoading: LOADING_STATE.LOADING,
    });

    const [_, error] = await promiseHandler(
      VehicleService.refreshVehicle(this.props.vehicle.id),
    );

    if (error) {
      this.setState({
        vehicleLoading: LOADING_STATE.FAILED,
      });
      return;
    }

    this.setState({
      vehicleLoading: LOADING_STATE.SUCCEEDED,
    });
  };

  getPaths() {
    if (this.renderForCreate()) {
      return null;
    }

    return (
      <OrganisationalGroupPaths
        id={this.props.vehicle.id}
        organisationalGroupPaths={this.props.vehicle.organisationalGroupPaths}
        onOpenOrganisationalGroup={(organisationalGroup) =>
          this.props.onOpenOrganisationalGroup(
            organisationalGroup,
            this.getUnsavedChanges(),
          )
        }
      />
    );
  }

  handleActiveCheckboxChange = (event) => {
    const newVehicle = cloneDeep(this.state.vehicle);

    newVehicle.active = event.target.checked;

    Log.info(
      'Change form value of active',
      { from: this.state.vehicle.active, to: newVehicle.active },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change active checkbox', Log.FEATURE.VEHICLE);

    this.setState({
      vehicle: newVehicle,
    });
  };

  getUnsavedChanges() {
    if (this.renderForCreate()) {
      return [];
    }

    return Vehicle.getDifferentValues(this.props.vehicle, this.state.vehicle);
  }

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        title={
          'Fahrzeug ' +
          (this.renderForCreate()
            ? 'Erstellen'
            : this.props.vehicle.licensePlate.name)
        }
        fullWidth
        submittingForm={this.state.submittingForm}
        id={this.props.vehicle?.id}
        unsavedChanges={this.getUnsavedChanges()}
        missingPermissionsToSubmit={
          this.renderForCreate()
            ? !UserUtils.isVehicleCreateAllowedUser()
            : !UserUtils.isVehicleWriteAllowedUser()
        }
      >
        {this.props.vehicle?.organisationalGroupPaths?.length > 0 ? (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <div className="mb-20px">{this.getPaths()}</div>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        <Grid container spacing={2} className="max-w-500px">
          <Grid item xs={3}>
            <TextField
              id="license_plate_city-input"
              name="license_plate_city"
              label="Stadt"
              type="text"
              fullWidth
              required
              value={this.state.vehicle.licensePlate.city}
              placeholder="z.B. M"
              onChange={this.handleInputChange}
              autoFocus
              error={
                this.state.vehicle.licensePlate.city &&
                !this.state.vehicle.licensePlate.cityIsValid()
              }
              helperText={
                !this.state.vehicle.licensePlate.city ||
                this.state.vehicle.licensePlate.cityIsValid()
                  ? null
                  : 'Ungültige Eingabe'
              }
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="license_plate_letters-input"
              name="license_plate_letters"
              label="Buchstaben"
              type="text"
              fullWidth
              required
              value={this.state.vehicle.licensePlate.letters}
              placeholder="z.B. VG"
              onChange={this.handleInputChange}
              autoFocus
              error={
                this.state.vehicle.licensePlate.letters &&
                !this.state.vehicle.licensePlate.lettersIsValid()
              }
              helperText={
                !this.state.vehicle.licensePlate.letters ||
                this.state.vehicle.licensePlate.lettersIsValid()
                  ? null
                  : 'Ungültige Eingabe'
              }
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="license_plate_numbers-input"
              name="license_plate_numbers"
              label="Nummern"
              type="text"
              fullWidth
              required
              value={this.state.vehicle.licensePlate.numbers}
              placeholder="z.B. 123"
              onChange={this.handleInputChange}
              autoFocus
              error={
                this.state.vehicle.licensePlate.numbers &&
                !this.state.vehicle.licensePlate.numbersIsValid()
              }
              helperText={
                !this.state.vehicle.licensePlate.numbers ||
                this.state.vehicle.licensePlate.numbersIsValid()
                  ? null
                  : 'Ungültige Eingabe'
              }
              autoComplete="off"
            />
          </Grid>

          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.vehicle?.active}
                  name="is_active"
                />
              }
              onChange={this.handleActiveCheckboxChange}
              label="Aktiv"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className="mt-20px">
          <InputLabel id="demo-multiple-name-label" className="text-13px">
            Firma
          </InputLabel>
          <Select
            value={this.state.vehicle.company}
            fullWidth
            className="max-w-400px"
            onChange={this.handleChangeCompany}
            size="small"
            options={this.props.companies.companies}
            loading={this.props.companies.companiesLoading}
            errorText="Firmen konnten nicht geladen werden."
            sortOptions
            sortOptionsByKey="name"
          />
        </Grid>
        <Grid item xs={12}>
          <h3 className="mt-20px main-text">Ist Teil von...</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <div className="min-w-600px">
                <ComplexPaginatedEntityMultiPicker
                  entityType={
                    PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY
                  }
                  pickedIds={this.state.vehicle.organisationalGroups}
                  callbackPickedItems={this.handleChangeOrganisationalGroups}
                  onChipClick={(organisationalGroup) =>
                    this.props.onOpenOrganisationalGroup(
                      organisationalGroup,
                      this.getUnsavedChanges(),
                    )
                  }
                  onUpdatedItemsChange={
                    this.props.onUpdatedOrganisationalGroupsChange
                  }
                />
              </div>
            </Grid>
          </Grid>
        </Grid>

        {this.renderForCreate() ? null : (
          <Grid item xs={12}>
            <div className="h-20px" /* Hacky workaround to add spacing */ />
            <PermissionGrantEntityTable
              title="Wer ist auf dieses Fahrzeug berechtigt?"
              permissionGrantsFrom={this.state.vehicle.permissionGrantsFrom}
              defaultEntities={[this.props.vehicle.id]}
              defaultEntityType={PermissionGrant.ENTITY_TYPE.VEHICLE.KEY}
              fixedPicker={PermissionGrant.TYPE.ENTITY}
              refreshData={this.refreshVehicle}
              loading={this.state.vehicleLoading}
            />
          </Grid>
        )}
      </BasicForm>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(VehicleForm);
