export const FEATURE_FLAG = {
  ACCESS_ARCHIVE: 'access_archive',
  CLIENT_PORTAL: 'client_portal',
  CONCRETE_DIARY: 'concrete_diary',
  CREATE_DELIVERY_NOTE: 'create_delivery_note',
  CREATE_DEMO_DLNS: 'create_demo_dlns',
  CUSTOMER_SUBSCRIPTION: 'customer_subscription',
  DEACTIVATED_COMPANY_ACCOUNT: 'deactivated_company_account',
  DISABLE_INVOICES: 'disable_invoices',
  MODULE_INVOICE_RESTRICTION: 'module_invoice_restriction',
  NEW_SUPPLIERS_KANBAN: 'suppliers_kanban',
  NO_PRIVACY_STATEMENT: 'no_privacy_statement',
  PACKAGE_BASIC_RESTRICTION: 'package_basic_restriction',
  PRODUCT_ANALYTICS_TOOLS: 'product_analytics_tools',
  SENTRY_GOOGLE_ANALYTICS_DISABLED: 'sentry_google_analytics_disabled',
  SERVICE_NOTES: 'service_notes',
  USER_IN_DLN_HISTORY: 'user_in_dln_history',
};

export const FEATURE_CONFIG = {
  LOAD_DLNS_AMOUNT: 'load_dlns_amount',
};
