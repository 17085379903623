import React from 'react';

import { Grid, TextField } from '@mui/material';

import { connect } from 'react-redux';
import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';
import BasicForm from '~/components/BasicForm';
import cloneDeep from 'lodash/cloneDeep';
import ToastService from '~/services/toast.service';
import InvoiceCheckIgnoredArticle from '~/models/invoices/InvoiceCheckIgnoredArticle';
import InvoicesService from '~/services/invoices.service';
import FunctionUtils from '~/utils/functionUtils';
import ArrayUtils from '~/utils/arrayUtils';
import { LOADING_STATE } from '~/constants/LoadingState';
import GenericMultiPicker from '~/components/baseComponents/inputs/select/GenericMultiPicker';

const mapStateToProps = (state) => ({
  invoices: state.invoices,
  companies: state.companies,
  dataExchanges: state.dataExchanges,
});
const mapDispatchToProps = () => ({});

class InvoiceCheckIgnoredArticleForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceCheckIgnoredArticle: new InvoiceCheckIgnoredArticle(),
      submittingForm: false,
      deletingForm: false,
      sortedSellerCompanies: [],
      filteredSellerCompanies: [],
      sortedBuyerCompanies: [],
    };
  }

  componentDidMount() {
    this.resetForm();
    this.initSortedSellerCompanies();
    this.initSortedBuyerCompanies();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.invoiceCheckIgnoredArticle) !==
      JSON.stringify(this.props.invoiceCheckIgnoredArticle)
    ) {
      this.resetForm();
    }

    if (
      this.props.dataExchanges.dataExchangeCompaniesLoading ===
        LOADING_STATE.SUCCEEDED &&
      prevProps.dataExchanges.dataExchangeCompaniesLoading !==
        LOADING_STATE.SUCCEEDED
    ) {
      this.initSortedSellerCompanies();
    }

    if (
      JSON.stringify(this.state.sortedSellerCompanies) !==
        JSON.stringify(prevState.sortedSellerCompanies) ||
      (this.state.invoiceCheckIgnoredArticle.sellerCompanies.length === 0 &&
        prevState.invoiceCheckIgnoredArticle.sellerCompanies.length === 1) ||
      (this.state.invoiceCheckIgnoredArticle.sellerCompanies.length === 1 &&
        prevState.invoiceCheckIgnoredArticle.sellerCompanies.length === 0)
    ) {
      this.initFilteredSellerCompanies();
    }

    if (
      this.props.companies.companiesLoading === LOADING_STATE.SUCCEEDED &&
      prevProps.companies.companiesLoading !== LOADING_STATE.SUCCEEDED
    ) {
      this.initSortedBuyerCompanies();
    }
  }

  async resetForm() {
    this.setState({
      invoiceCheckIgnoredArticle:
        this.props.invoiceCheckIgnoredArticle ??
        new InvoiceCheckIgnoredArticle(),
    });
  }

  initSortedSellerCompanies() {
    const sortedSellerCompanies = ArrayUtils.sortByKey(
      cloneDeep(this.props.dataExchanges.dataExchangeCompanies),
      'name',
    );

    this.setState({
      sortedSellerCompanies,
    });
  }

  initFilteredSellerCompanies() {
    // Don't disable any seller companies based on the same company account if none is selected yet.
    if (this.state.invoiceCheckIgnoredArticle.sellerCompanies.length === 0) {
      this.setState({
        filteredSellerCompanies: this.state.sortedSellerCompanies,
      });
      return;
    }

    const selectedSellerCompanyAccountId =
      this.props.dataExchanges.dataExchangeCompanies.find(
        (dataExchangeCompany) =>
          dataExchangeCompany.id ===
          this.state.invoiceCheckIgnoredArticle.sellerCompanies[0],
      )?.companyAccountId;

    const filteredSellerCompanies = this.state.sortedSellerCompanies.map(
      (sellerCompany) => {
        return {
          ...sellerCompany,
          disabled:
            sellerCompany.companyAccountId !== selectedSellerCompanyAccountId,
        };
      },
    );

    this.setState({
      filteredSellerCompanies,
    });
  }

  initSortedBuyerCompanies() {
    const sortedBuyerCompanies = ArrayUtils.sortByKey(
      cloneDeep(this.props.companies.companies),
      'name',
    );

    this.setState({
      sortedBuyerCompanies,
    });
  }

  formSuccess = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      submittingForm: true,
    });

    const body = {
      seller_assigned_id:
        this.state.invoiceCheckIgnoredArticle.sellerAssignedId,
      name: this.state.invoiceCheckIgnoredArticle.name,
      reason: this.state.invoiceCheckIgnoredArticle.reason,
      seller_companies: this.state.invoiceCheckIgnoredArticle.sellerCompanies,
      buyer_companies: this.state.invoiceCheckIgnoredArticle.buyerCompanies,
    };

    Log.info(
      'Submit invoice check ignored article form',
      body,
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent(
      'Submit ignored article form',
      Log.FEATURE.INVOICE_CHECK,
    );

    if (this.renderForCreate()) {
      const [invoiceCheckIgnoredArticleId, error] = await promiseHandler(
        InvoicesService.createInvoiceCheckIgnoredArticle(body),
      );

      if (error) {
        Log.error('Failed to create invoice check ignored article.', error);
        ToastService.httpError(
          ['Ingorierter Artikel konnte nicht angelegt werden.'],
          error.response,
        );
        Log.productAnalyticsEvent(
          'Failed to create ignored article',
          Log.FEATURE.INVOICE_CHECK,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }
    } else {
      const [response, error] = await promiseHandler(
        InvoicesService.updateInvoiceCheckIgnoredArticle(
          this.props.invoiceCheckIgnoredArticle.id,
          body,
        ),
      );

      if (error) {
        Log.error(
          'Failed to update invoice check ignored article. id: ' +
            this.props.invoiceCheckIgnoredArticle.id,
          error,
        );
        ToastService.httpError(
          ['Ingorierter Artikel konnte nicht aktualisiert werden.'],
          error.response,
        );
        Log.productAnalyticsEvent(
          'Failed to update ignored article',
          Log.FEATURE.INVOICE_CHECK,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }
    }

    this.setState({
      submittingForm: false,
    });

    this.props.closeForm();
    this.resetForm();
    InvoicesService.refreshInvoiceCheckIgnoredArticles();
  };
  formAbort = () => {
    Log.productAnalyticsEvent(
      'Abort ignored article form',
      Log.FEATURE.INVOICE_CHECK,
    );
    this.props.closeForm();
    this.resetForm();
  };
  formDelete = async (event) => {
    event.preventDefault();

    Log.info(
      'Delete ignored article',
      { id: this.props.invoiceCheckIgnoredArticle.id },
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent(
      'Delete ignored article',
      Log.FEATURE.INVOICE_CHECK,
    );

    this.setState({
      deletingForm: true,
    });

    const [response, error] = await promiseHandler(
      InvoicesService.deleteInvoiceCheckIgnoredArticle(
        this.props.invoiceCheckIgnoredArticle.id,
      ),
    );

    if (error) {
      ToastService.httpError(
        ['Ingorierter Artikel konnte nicht gelöscht werden.'],
        error.response,
      );
      Log.error('Failed to delete ignored article.', error);
      Log.productAnalyticsEvent(
        'Failed to delete ignored article',
        Log.FEATURE.INVOICE_CHECK,
        Log.TYPE.ERROR,
      );
      this.setState({
        deletingForm: false,
      });
      return;
    }

    this.setState({
      deletingForm: false,
    });

    this.props.closeForm();
    this.resetForm();
    await InvoicesService.refreshInvoiceCheckIgnoredArticles();
  };
  renderForCreate = () => {
    return this.props.type === 'create';
  };
  handleInputChange = (event) => {
    const newInvoiceCheckIgnoredArticle = cloneDeep(
      this.state.invoiceCheckIgnoredArticle,
    );

    switch (event.target.name) {
      case 'sellerAssignedId': {
        newInvoiceCheckIgnoredArticle.sellerAssignedId = event.target.value;
        Log.info(
          'Change form value of seller assigned id',
          {
            from: this.state.invoiceCheckIgnoredArticle.sellerAssignedId,
            to: newInvoiceCheckIgnoredArticle.sellerAssignedId,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'invoice_check_ignored_article_change_seller_assigned_id',
          Log.productAnalyticsEvent,
          ['Change seller assigned id', Log.FEATURE.INVOICE_CHECK],
        );
        break;
      }

      case 'name': {
        newInvoiceCheckIgnoredArticle.name = event.target.value;
        Log.info(
          'Change form value of name',
          {
            from: this.state.invoiceCheckIgnoredArticle.name,
            to: newInvoiceCheckIgnoredArticle.name,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'invoice_check_ignored_article_change_name',
          Log.productAnalyticsEvent,
          ['Change name', Log.FEATURE.INVOICE_CHECK],
        );
        break;
      }

      case 'reason': {
        newInvoiceCheckIgnoredArticle.reason = event.target.value;
        Log.info(
          'Change form value of reason',
          {
            from: this.state.invoiceCheckIgnoredArticle.reason,
            to: newInvoiceCheckIgnoredArticle.reason,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'invoice_check_ignored_article_change_reason',
          Log.productAnalyticsEvent,
          ['Change reason', Log.FEATURE.INVOICE_CHECK],
        );
        break;
      }
    }

    this.setState({
      invoiceCheckIgnoredArticle: newInvoiceCheckIgnoredArticle,
    });
  };
  handleChangeSellerCompanies = (sellerCompanies) => {
    const newInvoiceCheckIgnoredArticle = cloneDeep(
      this.state.invoiceCheckIgnoredArticle,
    );

    newInvoiceCheckIgnoredArticle.sellerCompanies = sellerCompanies.map(
      (company) => company.id,
    );

    Log.info(
      'Change form value of seller companies',
      {
        from: this.state.invoiceCheckIgnoredArticle.sellerCompanies,
        to: newInvoiceCheckIgnoredArticle.sellerCompanies,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change seller companies',
      Log.FEATURE.INVOICE_CHECK,
    );

    this.setState({
      invoiceCheckIgnoredArticle: newInvoiceCheckIgnoredArticle,
    });
  };
  handleChangeBuyerCompanies = (buyerCompanies) => {
    const newInvoiceCheckIgnoredArticle = cloneDeep(
      this.state.invoiceCheckIgnoredArticle,
    );

    newInvoiceCheckIgnoredArticle.buyerCompanies = buyerCompanies.map(
      (company) => company.id,
    );

    Log.info(
      'Change form value of buyer companies',
      {
        from: this.state.invoiceCheckIgnoredArticle.buyerCompanies,
        to: newInvoiceCheckIgnoredArticle.buyerCompanies,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change buyer companies',
      Log.FEATURE.INVOICE_CHECK,
    );

    this.setState({
      invoiceCheckIgnoredArticle: newInvoiceCheckIgnoredArticle,
    });
  };

  getUnsavedChanges() {
    if (this.renderForCreate()) {
      return [];
    }

    return InvoiceCheckIgnoredArticle.getDifferentValues(
      this.props.invoiceCheckIgnoredArticle,
      this.state.invoiceCheckIgnoredArticle,
    );
  }

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        formDelete={this.renderForCreate() ? null : this.formDelete}
        title={
          'Ignorierter Artikel ' +
          (this.renderForCreate()
            ? 'Erstellen'
            : this.props.invoiceCheckIgnoredArticle.name)
        }
        fullWidth
        submittingForm={this.state.submittingForm}
        deletingForm={this.state.deletingForm}
        unsavedChanges={this.getUnsavedChanges()}
        id={this.props.invoiceCheckIgnoredArticle?.id}
      >
        <Grid container direction="row" spacing={3} space={4}>
          <Grid item xs={12} lg={12}>
            <h3 className="main-text mt-0">Ignorierter Artikel</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="sellerAssignedId-input"
                  name="sellerAssignedId"
                  label="Artikelnummer"
                  type="text"
                  required
                  fullWidth
                  value={
                    this.state.invoiceCheckIgnoredArticle?.sellerAssignedId
                  }
                  onChange={this.handleInputChange}
                  autoFocus
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="name-input"
                  name="name"
                  label="Artikelname"
                  type="text"
                  required
                  fullWidth
                  value={this.state.invoiceCheckIgnoredArticle?.name}
                  onChange={this.handleInputChange}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <TextField
                  id="reason-input"
                  name="reason"
                  label="Grund"
                  type="text"
                  variant="outlined"
                  required
                  fullWidth
                  value={this.state.invoiceCheckIgnoredArticle?.reason}
                  onChange={this.handleInputChange}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">Auftragnehmer</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <GenericMultiPicker
                  textfieldLabel="Auftragnehmer"
                  pickedItemIds={
                    this.state.invoiceCheckIgnoredArticle?.sellerCompanies ?? []
                  }
                  allItems={this.state.filteredSellerCompanies}
                  callbackPickedItems={this.handleChangeSellerCompanies}
                  loading={
                    this.props.dataExchanges.dataExchangeCompaniesLoading
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">Auftraggeber</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <GenericMultiPicker
                  textfieldLabel="Auftraggeber"
                  pickedItemIds={
                    this.state.invoiceCheckIgnoredArticle?.buyerCompanies ?? []
                  }
                  allItems={this.state.sortedBuyerCompanies}
                  callbackPickedItems={this.handleChangeBuyerCompanies}
                  loading={this.props.companies.companiesLoading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BasicForm>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps(),
)(InvoiceCheckIgnoredArticleForm);
