import { Check as CheckIcon } from '@mui/icons-material';

import { Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material';
import { Spinner } from '~/components/Spinner';
import { LOADING_STATE } from '~/constants/LoadingState';
import { LightTooltip, LightTooltipWide } from '~/utils/componentUtils';
import ArrayUtils from '~/utils/arrayUtils';

export default function Select(props) {
  const renderValue = () => {
    if (props.loading === LOADING_STATE.LOADING) {
      return <Spinner title="Laden..." />;
    }

    if (!props.value) {
      return ArrayUtils.EMPTY_DROPDOWN_OPTION;
    }

    return (
      props.options.find((option) => option.id === props.value)?.name ?? ''
    );
  };

  const getValue = () => {
    if (!props.value) {
      return 'None';
    } // A random String has to be chosen as fallback value. Otherwise renderValue() wouldn't be executed.

    return props.value;
  };

  const getOptions = () => {
    let options = [...props.options];

    if (props.sortOptionsByKey) {
      options = ArrayUtils.sortByKey(options, props.sortOptionsByKey);
    } else if (props.sortOptions) {
      options = options.sort();
    }

    if (props.withEmptyOption) {
      options.unshift({ id: null, name: ArrayUtils.EMPTY_DROPDOWN_OPTION });
    }

    return options;
  };

  const getMenuItem = (item) => {
    const content = (
      <div className="flex-sb-c w-full">
        <div className={getValue() === item.id ? 'text-primary500' : null}>
          {item.nameComponent ?? item.name}
        </div>
        {getValue() === item.id ? (
          <CheckIcon className="text-primary500" fontSize="small" />
        ) : null}
      </div>
    );

    if (item.description) {
      return (
        <MenuItem key={item.id} value={item.id} disabled={item.disabled}>
          <LightTooltip title={item.description}>{content}</LightTooltip>
        </MenuItem>
      );
    }

    return (
      <MenuItem key={item.id} value={item.id} disabled={item.disabled}>
        {content}
      </MenuItem>
    );
  };

  let select = (
    <MuiSelect
      {...props}
      value={getValue()}
      MenuProps={{ autoFocus: false }}
      renderValue={renderValue}
      disabled={
        props.loading === LOADING_STATE.LOADING ||
        props.loading === LOADING_STATE.FAILED ||
        props.disabled
      }
    >
      {getOptions().map((item) => getMenuItem(item))}
    </MuiSelect>
  );

  if (props.loading !== LOADING_STATE.LOADING && props.disabled) {
    select = (
      <LightTooltipWide title={renderValue()}>{select}</LightTooltipWide>
    );
  }

  return (
    <div className={'relative ' + (props.fullWidth ? 'w-full' : null)}>
      {select}
      {props.loading === LOADING_STATE.FAILED ? (
        <FormHelperText className="text-mui-error-red absolute">
          {props.errorText ?? 'Daten konnten nicht geladen werden.'}
        </FormHelperText>
      ) : null}
    </div>
  );
}
