import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import BasicModal from '~/components/BasicModal';

const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiPaper-root': {
      width: '35rem',
      borderRadius: '0.5rem',
    },

    '& .MuiFormControl-root': {
      '& .MuiFormLabel-root': {
        marginBottom: '0.5rem',
        color: '#5D647A',
      },
    },

    '& .MuiGrid-container': {
      marginBottom: '1rem',
    },

    '& .MuiInputBase-root': {
      borderRadius: '0.5rem',
      height: '3rem',

      '& fieldset': {
        borderColor: '#8D94A8',
      },
    },

    '& .address-container': {
      marginTop: '2rem',

      '& h6': {
        marginBottom: '1rem',
        fontSize: '1rem',
        fontWeight: 600,
      },
    },

    '& .suppliers_modal': {
      marginTop: '2rem',
      gap: '1rem',

      '& .MuiButtonBase-root': {
        height: '3.1875rem',
        borderRadius: '0.5rem',
        textTransform: 'unset',
        fontWeight: 600,
        fontSize: '1rem',
      },
    },
  },
}));

export const NewSupplierModal = ({ onClose, open = true }) => {
  const classes = useStyles();

  return (
    <BasicModal
      open={open}
      title="Neuer Lieferant"
      closeModal={onClose}
      className={classes.container}
    >
      <FormControl component="fieldset" fullWidth>
        <FormLabel>Firma</FormLabel>
        <TextField fullWidth variant="outlined" placeholder="Name der Firma" />
      </FormControl>

      <div className="address-container">
        <Typography variant="h6">Firmenadresse</Typography>

        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
          marginBottom={1}
        >
          <Grid item xs={8}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel>Straße</FormLabel>
              <TextField variant="outlined" placeholder="z.B. Hauptstraße" />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl component="fieldset">
              <FormLabel>Hausnummer</FormLabel>
              <TextField variant="outlined" placeholder="z.B. 17" />
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
          marginBottom={1}
        >
          <Grid item xs={6}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel>PLZ</FormLabel>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="z.B. 81539"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel>Ort</FormLabel>
              <TextField
                variant="outlined"
                placeholder="z.B. München"
                fullWidth
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item xs={6} fullWidth>
            <FormControl component="fieldset" fullWidth>
              <FormLabel>Land</FormLabel>
              <Select fullWidth variant="outlined" />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel>Verantwortliche Person</FormLabel>
              <Select variant="outlined" fullWidth />
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          className="suppliers_modal"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button onClick={onClose} color="primary">
            Abbrechen
          </Button>
          <Button color="primary" variant="contained">
            Absenden
          </Button>
        </Grid>
      </div>
    </BasicModal>
  );
};
