import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  replaceFilteredOutgoingInvoices,
  replaceOutgoingInvoices,
} from '~/redux/invoicesSlice';

import Invoice from '~/models/invoices/Invoice';

import { es6ClassFactory as ES6ClassFactory } from '~/utils/ES6ClassFactory';

/**
 * Custom hook for initializing and filtering outgoing invoices.
 *
 * This hook manages the state of outgoing invoices, applying filters based on selected sites and cost centers,
 * and updating invoice data based on referenced delivery notes.
 *
 * @returns {void}
 */
export const useInitOutgoingInvoices = () => {
  const dispatch = useDispatch();

  const {
    filteredDeliveryNotesVersion,
    filteredDeliveryNotesVersionUpdateByBulkLoad,
  } = useSelector((state) => state.deliveryNotes);
  const { selectedCostCenters, selectedSites } = useSelector(
    (state) => state.filters,
  );
  const { outgoingInvoices, outgoingInvoicesFromBackendVersion } = useSelector(
    (state) => state.invoices,
  );

  /**
   * Initializes and filters outgoing invoices based on current state and filters.
   *
   * This function performs the following tasks:
   * 1. Converts outgoing invoices to ES6 class instances.
   * 2. Updates invoice data based on referenced delivery notes.
   * 3. Applies filters based on selected sites and cost centers.
   * 4. Dispatches actions to update the Redux store with new and filtered invoices.
   */
  const initOutgoingInvoices = () => {
    const newOutgoingInvoices = ES6ClassFactory.convertToES6Class(
      outgoingInvoices,
      new Invoice(),
    );

    const noFilterApplied =
      selectedSites.length === 0 && selectedCostCenters.length === 0;

    for (const newOutgoingInvoice of newOutgoingInvoices) {
      // Update the invoice data based on the referenced delivery notes in the following cases:
      // ### For the toSite and status:
      // - If the init of the invoices has been triggered by the bulk load of the delivery notes
      //   (e.g. filteredDeliveryNotesVersionUpdateByBulkLoad)
      //   and the referenced delivery notes of the invoice haven't been loaded yet
      // ### For the containsFilteredDeliveryNote (to determine if invoice should be filtered out):
      // - If the init of the invoices has been triggered by the filter change
      //   (e.g. !filteredDeliveryNotesVersionUpdateByBulkLoad)
      // - If the init of the invoices has been triggered by the bulk load of the delivery notes
      //   (e.g. filteredDeliveryNotesVersionUpdateByBulkLoad)
      //   and the referenced delivery notes of the invoice haven't been loaded yet
      const updateToSiteAndStatus =
        filteredDeliveryNotesVersionUpdateByBulkLoad &&
        !newOutgoingInvoice.referencedDeliveryNotesLoaded;

      const updateContainsFilteredDeliveryNote =
        !filteredDeliveryNotesVersionUpdateByBulkLoad ||
        (filteredDeliveryNotesVersionUpdateByBulkLoad &&
          !newOutgoingInvoice.referencedDeliveryNotesLoaded);

      if (noFilterApplied) {
        // If there is no filter applied, we want to display all invoices anyway.
        // Therefore, we don't need to check if the invoice should be filtered out.
        newOutgoingInvoice.containsFilteredDeliveryNote = true;
        newOutgoingInvoice.initWithReferencedDeliveryNotes(
          updateToSiteAndStatus,
          false,
        );
      } else {
        // If there is a filter applied, we want to display only the invoices that are not filtered out.
        newOutgoingInvoice.initWithReferencedDeliveryNotes(
          updateToSiteAndStatus,
          updateContainsFilteredDeliveryNote,
        );
      }
    }

    dispatch(replaceOutgoingInvoices(newOutgoingInvoices));
    dispatch(
      replaceFilteredOutgoingInvoices(
        newOutgoingInvoices.filter((invoice) => {
          // If a DLN is referenced but filtered out, the invoice should be filtered out as well.
          if (
            invoice.referencedDeliveryNotes.length > 0 &&
            !invoice.containsFilteredDeliveryNote
          ) {
            return false;
          }

          // If there is a filter applied, invoices without referenced delivery notes should be filtered out (e.g. create invoice).
          if (
            (selectedSites.length > 0 || selectedCostCenters.length > 0) &&
            invoice.referencedDeliveryNotes.length === 0
          ) {
            return false;
          }

          return true;
        }),
      ),
    );
  };

  useEffect(() => {
    initOutgoingInvoices();
  }, [filteredDeliveryNotesVersion, outgoingInvoicesFromBackendVersion]);
};
