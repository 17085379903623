import React from 'react';
import BasicModal from '../BasicModal';
import Log from '~/utils/Log';

class AttachmentImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleClick = () => {
    Log.productAnalyticsEvent(
      'Open image attachment',
      Log.FEATURE.OTHER_FEATURE,
    );
    this.setState({
      open: true,
    });
  };
  handleClose = () => {
    Log.productAnalyticsEvent(
      'Close image attachment',
      Log.FEATURE.OTHER_FEATURE,
    );
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <>
        <img
          className="fit-contain h-full w-full cursor-pointer"
          src={this.props.url}
          key={this.props.url}
          onClick={this.handleClick}
        />
        <BasicModal
          title="Anhang"
          open={this.state.open}
          closeModal={this.handleClose}
        >
          <img className="max-h-70vh max-w-70vw" src={this.props.url} />
        </BasicModal>
      </>
    );
  }
}

export default AttachmentImage;
