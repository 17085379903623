export default class AcceptStateCalculator {
  static calculateOverallAcceptStateFromParties(
    acceptStateSupplier,
    acceptStateCarrier,
    acceptStateRecipient,
    acceptStateOnBehalfSupplier,
    acceptStateOnBehalfCarrier,
    acceptStateOnBehalfRecipient,
  ) {
    if (
      acceptStateSupplier === AcceptStateCalculator.ACCEPT_STATE.DECLINED ||
      acceptStateCarrier === AcceptStateCalculator.ACCEPT_STATE.DECLINED ||
      acceptStateRecipient === AcceptStateCalculator.ACCEPT_STATE.DECLINED ||
      acceptStateOnBehalfSupplier ===
        AcceptStateCalculator.ACCEPT_STATE.DECLINED ||
      acceptStateOnBehalfCarrier ===
        AcceptStateCalculator.ACCEPT_STATE.DECLINED ||
      acceptStateOnBehalfRecipient ===
        AcceptStateCalculator.ACCEPT_STATE.DECLINED
    ) {
      return AcceptStateCalculator.ACCEPT_STATE.DECLINED;
    }

    if (
      acceptStateRecipient === AcceptStateCalculator.ACCEPT_STATE.APPROVED ||
      acceptStateOnBehalfRecipient ===
        AcceptStateCalculator.ACCEPT_STATE.APPROVED
    ) {
      return AcceptStateCalculator.ACCEPT_STATE.APPROVED;
    }

    return AcceptStateCalculator.ACCEPT_STATE.OPEN;
  }

  static calculateOverallAcceptStateFromArticles(articleAcceptStates) {
    if (articleAcceptStates.length === 0) {
      return AcceptStateCalculator.ACCEPT_STATE.OPEN;
    }

    if (
      articleAcceptStates.includes(AcceptStateCalculator.ACCEPT_STATE.DECLINED)
    ) {
      return AcceptStateCalculator.ACCEPT_STATE.DECLINED;
    }

    if (
      articleAcceptStates.every(
        (acceptState) =>
          acceptState === AcceptStateCalculator.ACCEPT_STATE.APPROVED,
      )
    ) {
      return AcceptStateCalculator.ACCEPT_STATE.APPROVED;
    }

    return AcceptStateCalculator.ACCEPT_STATE.OPEN;
  }

  // DeliveryNote.PROCESS_STATE must be hardcoded here because the import of DeliveryNote.js throws
  // Uncaught ReferenceError: Cannot access '__WEBPACK_DEFAULT_EXPORT__' before initialization
  static getAcceptStateOptionsBasedOnProcessStates(processStates) {
    if (processStates.length === 0) {
      return [
        AcceptStateCalculator.ACCEPT_STATE.APPROVED,
        AcceptStateCalculator.ACCEPT_STATE.DECLINED,
        AcceptStateCalculator.ACCEPT_STATE.OPEN,
      ];
    }

    const acceptStateOptions = [];

    if (processStates.includes('Geliefert')) {
      acceptStateOptions.push(AcceptStateCalculator.ACCEPT_STATE.APPROVED);
    }

    if (
      processStates.includes('In Transport') ||
      processStates.includes('Übergabe') ||
      processStates.includes('Storniert') ||
      processStates.includes('Geliefert')
    ) {
      acceptStateOptions.push(AcceptStateCalculator.ACCEPT_STATE.DECLINED);
    }

    acceptStateOptions.push(AcceptStateCalculator.ACCEPT_STATE.OPEN);

    return acceptStateOptions;
  }

  static getBackendAcceptStateKey(acceptState) {
    switch (acceptState) {
      case AcceptStateCalculator.ACCEPT_STATE.APPROVED: {
        return AcceptStateCalculator.BACKEND_ACCEPT_STATE_KEY.APPROVED;
      }

      case AcceptStateCalculator.ACCEPT_STATE.DECLINED: {
        return AcceptStateCalculator.BACKEND_ACCEPT_STATE_KEY.DECLINED;
      }

      case AcceptStateCalculator.ACCEPT_STATE.OPEN: {
        return AcceptStateCalculator.BACKEND_ACCEPT_STATE_KEY.OPEN;
      }
    }
  }

  static getAcceptStateDesc(acceptState) {
    switch (acceptState) {
      case AcceptStateCalculator.ACCEPT_STATE.APPROVED: {
        return 'Zeige Lieferungen an, welche vom Abnehmer angenommen wurden.';
      }

      case AcceptStateCalculator.ACCEPT_STATE.DECLINED: {
        return 'Zeige Lieferungen an, welche von mindestens einer Partei abgelehnt wurden.';
      }

      case AcceptStateCalculator.ACCEPT_STATE.OPEN: {
        return 'Zeige Lieferungen an, bei denen die Signatur des Abnehmers oder des Lieferanten noch aussteht.';
      }
    }
  }

  static getAcceptStates() {
    return [
      AcceptStateCalculator.ACCEPT_STATE.APPROVED,
      AcceptStateCalculator.ACCEPT_STATE.DECLINED,
      AcceptStateCalculator.ACCEPT_STATE.OPEN,
    ];
  }

  static ACCEPT_STATE = {
    APPROVED: 'Angenommen',
    // PARTLY_APPROVED: 'Teilweise angenommen',
    DECLINED: 'Abgelehnt',
    OPEN: 'Offen',
  };
  static BACKEND_ACCEPT_STATE_KEY = {
    APPROVED: 'accepted',
    DECLINED: 'rejected',
    OPEN: 'open',
  };
}
