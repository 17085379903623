import { useQuery } from '@tanstack/react-query';

import CompanyService from '~/services/company.service';

export const useQueryCompanyLogo = (companyId, options = {}) =>
  useQuery({
    queryKey: ['companyLogo', { companyId }],
    queryFn: () => CompanyService.getCompanyLogo(companyId),
    select: (logoData) =>
      logoData?.size > 0 ? URL.createObjectURL(logoData) : null,
    ...options,
  });
