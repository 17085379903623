import { useHistory } from 'react-router-dom';

import { CreateDeliveryOutlinedIcon } from '~/assets/icons';

import { ROUTE } from '~/constants/Route';
import { promiseHandler } from '~/utils/promiseHandler';
import DeliveriesService from '~/services/deliveries.service';
import ToastService from '~/services/toast.service';

import IconButton from '~/components/IconButton';

import Log from '~/utils/Log';

export const CreateDeliveryNoteButton = ({ id }) => {
  const history = useHistory();

  const handleCreateDeliveryNote = async () => {
    Log.productAnalyticsEvent(
      'Import delivery note',
      Log.FEATURE.CREATE_DELIVERY_NOTE,
    );

    const [dln, error] = await promiseHandler(
      DeliveriesService.getDeliveryNoteById(id),
    );

    if (error) {
      Log.error(`Failed to load delivery note. id: ${id}`);
      ToastService.warning([
        'Lieferung konnte nicht als Vorlage verwendet werden wegen eines internen Fehlers.',
      ]);
      Log.productAnalyticsEvent(
        'Failed to import delivery note',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
    }

    DeliveriesService.setDeliveryNoteForCreationForm(dln);
    history.push({
      pathname: ROUTE.CREATE_DELIVERY_NOTE.ROUTE,
      state: {
        passDeliveryNote: true,
      },
    });
  };

  return (
    <IconButton
      onClick={handleCreateDeliveryNote}
      tooltipTitle="Lieferung als Vorlage für neue Lieferung verwenden."
      size="large"
    >
      <CreateDeliveryOutlinedIcon className="text-primary500 icon-small" />
    </IconButton>
  );
};
