import React from 'react';

import { Chip, FormHelperText, Autocomplete, TextField } from '@mui/material';

import { connect } from 'react-redux';
import { LOADING_STATE } from '~/constants/LoadingState';
import Log from '~/utils/Log';
import { Spinner } from '~/components/Spinner';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import ArrayUtils from '~/utils/arrayUtils';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import UserUtils from '~/utils/userUtils';
import { SiteNameAddressPair } from '~/components/SiteNameAddressPair';
import MapperService from '~/services/mapper.service';

const mapStateToProps = (state) => ({
  users: state.users,
  sites: state.sites,
  costCenters: state.costCenters,
  vehicles: state.vehicles,
  companies: state.companies,
  organisationalGroups: state.organisationalGroups,
  userGroups: state.userGroups,
});

class PaginatedEntityMultiPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textfieldLabel: '',
      fieldName: 'name',
      allItems: [],
      pickedItems: [],
      missingPermissionsText: '',
      allItemsLoading: LOADING_STATE.NOT_LOADED,
      /* loadPage: null,
            getItem: null,
            getItems: null,
            page: 0,
            nameFilter: '',
            pageSize: 100,
            allPagesLoaded: false */
    };

    // this.requestId = 0;
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.entityType !== prevProps.entityType ||
      // We need to update the component if the allItems have been loaded. E.g. when we have a user multi picker and the users have been loaded, we need to write the loaded users into this.state.allItems.
      (this.props.entityType === PermissionGrant.ENTITY_TYPE.USER.KEY &&
        this.props.users.usersLoading === LOADING_STATE.SUCCEEDED &&
        prevProps.users.usersLoading !== LOADING_STATE.SUCCEEDED) ||
      (this.props.entityType === PermissionGrant.ENTITY_TYPE.SITE.KEY &&
        this.props.sites.sitesLoading === LOADING_STATE.SUCCEEDED &&
        prevProps.sites.sitesLoading !== LOADING_STATE.SUCCEEDED) ||
      (this.props.entityType === PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY &&
        this.props.costCenters.costCentersLoading === LOADING_STATE.SUCCEEDED &&
        prevProps.costCenters.costCentersLoading !== LOADING_STATE.SUCCEEDED) ||
      (this.props.entityType === PermissionGrant.ENTITY_TYPE.VEHICLE.KEY &&
        this.props.vehicles.vehiclesLoading === LOADING_STATE.SUCCEEDED &&
        prevProps.vehicles.vehiclesLoading !== LOADING_STATE.SUCCEEDED) ||
      (this.props.entityType === PermissionGrant.ENTITY_TYPE.COMPANY.KEY &&
        this.props.companies.companiesLoading === LOADING_STATE.SUCCEEDED &&
        prevProps.companies.companiesLoading !== LOADING_STATE.SUCCEEDED) ||
      (this.props.entityType ===
        PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY &&
        this.props.organisationalGroups.organisationalGroupsLoading ===
          LOADING_STATE.SUCCEEDED &&
        prevProps.organisationalGroups.organisationalGroupsLoading !==
          LOADING_STATE.SUCCEEDED) ||
      (this.props.entityType === PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY &&
        this.props.userGroups.userGroupsLoading === LOADING_STATE.SUCCEEDED &&
        prevProps.userGroups.userGroupsLoading !== LOADING_STATE.SUCCEEDED)
    ) {
      this.init();
    }

    if (
      JSON.stringify(this.props.pickedIds) !==
        JSON.stringify(prevProps.pickedIds) ||
      JSON.stringify(this.state.allItems) !== JSON.stringify(prevState.allItems)
    ) {
      this.initPickedItems();
    }

    if (
      (this.props.entityType === PermissionGrant.ENTITY_TYPE.USER.KEY &&
        this.props.users.usersLoading !== prevProps.users.usersLoading) ||
      (this.props.entityType === PermissionGrant.ENTITY_TYPE.SITE.KEY &&
        this.props.sites.sitesLoading !== prevProps.sites.sitesLoading) ||
      (this.props.entityType === PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY &&
        this.props.costCenters.costCentersLoading !==
          prevProps.costCenters.costCentersLoading) ||
      (this.props.entityType === PermissionGrant.ENTITY_TYPE.VEHICLE.KEY &&
        this.props.vehicles.vehiclesLoading !==
          prevProps.vehicles.vehiclesLoading) ||
      (this.props.entityType === PermissionGrant.ENTITY_TYPE.COMPANY.KEY &&
        this.props.companies.companiesLoading !==
          prevProps.companies.companiesLoading) ||
      (this.props.entityType ===
        PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY &&
        this.props.organisationalGroups.organisationalGroupsLoading !==
          prevProps.organisationalGroups.organisationalGroupsLoading) ||
      (this.props.entityType === PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY &&
        this.props.userGroups.userGroupsLoading !==
          prevProps.userGroups.userGroupsLoading)
    ) {
      // Update the loading state and text inside the options based on the loading state of the master data from redux.
      this.initAllItemsLoading();
    }

    if (
      this.props.entityType === PermissionGrant.ENTITY_TYPE.SITE.KEY &&
      ((this.props.costCenters.costCentersLoading === LOADING_STATE.SUCCEEDED &&
        prevProps.costCenters.costCentersLoading !== LOADING_STATE.SUCCEEDED) ||
        this.props.displayPermissionGrantOfCostCentersWithSites !==
          prevProps.displayPermissionGrantOfCostCentersWithSites)
    ) {
      // If the items are from type "site" and either the cost centers have changed or the flag whether to combine them,
      // we need to refresh how the items will be displayed in the options and chips.
      this.setState({
        allItems: this.getFormattedSites(this.state.allItems),
      });
    }

    /* if(
            this.state.loadPage !== prevState.loadPage ||
            this.state.nameFilter !== prevState.nameFilter
        ) {
            this.loadFirstItemPage();
        }

        if(
            JSON.stringify(this.props.pickedIds) !== JSON.stringify(prevProps.pickedIds) ||
            this.state.getItem !== prevState.getItem ||
            this.state.getItems !== prevState.getItems
        ) {
            this.initPickedItems();
        } */
  }

  init() {
    let textfieldLabel;
    let fieldName;
    let allItems;
    let missingPermissionsText = '';
    let allItemsLoading = LOADING_STATE.NOT_LOADED;
    /* let loadPage;
        let getItem;
        let getItems;
        let pageSize; */

    switch (this.props.entityType) {
      case PermissionGrant.ENTITY_TYPE.USER.KEY: {
        textfieldLabel = 'Benutzer';
        fieldName = 'email';
        allItems = this.props.users.users.filter((user) => user.active);
        allItemsLoading = this.props.users.usersLoading;
        if (!UserUtils.isUserReadAllowedUser()) {
          missingPermissionsText =
            'Für die Auswahl von Benutzern fehlt Ihnen die Berechtigung, um diese einzusehen.';
        }

        // loadPage = (page, name, isActive) => UserService.loadUserPage(page, name, isActive);
        // getItem = (userId) => UserService.getUser(userId);
        // getItems = (userIds) => UserService.getUsers(userIds);
        // pageSize = UserService.USERS_PAGE_SIZE;
        break;
      }

      case PermissionGrant.ENTITY_TYPE.SITE.KEY: {
        textfieldLabel = 'Standorte';
        fieldName = 'name';
        allItems = this.props.sites.sites.filter((site) => site.active);
        allItemsLoading = this.props.sites.sitesLoading;

        if (!UserUtils.isSiteReadAllowedUser()) {
          missingPermissionsText =
            'Für die Auswahl von Standorten fehlt Ihnen die Berechtigung, um diese einzusehen.';
        }

        // loadPage = (page, name, isActive) => SiteService.loadSitePage(page, name, isActive);
        // getItem = (siteId) => SiteService.getSiteById(siteId);
        // getItems = (siteIds) => SiteService.getSites(siteIds);
        // pageSize = SiteService.SITES_PAGE_SIZE;
        break;
      }

      case PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY: {
        textfieldLabel = 'Kostenstellen';
        fieldName = 'name';
        allItems = this.props.costCenters.costCenters.filter(
          (costCenter) => costCenter.active,
        );
        allItemsLoading = this.props.costCenters.costCentersLoading;
        if (!UserUtils.isCostCenterReadAllowedUser()) {
          missingPermissionsText =
            'Für die Auswahl von Kostenstellen fehlt Ihnen die Berechtigung, um diese einzusehen.';
        }

        // loadPage = (page, name, isActive) => CostCenterService.loadCostCenterPage(page, name, isActive);
        // getItem = (costCenterId) => CostCenterService.getCostCenterById(costCenterId);
        // getItems = (costCenterIds) => CostCenterService.getCostCenters(costCenterIds);
        // pageSize = CostCenterService.COST_CENTERS_PAGE_SIZE;
        break;
      }

      case PermissionGrant.ENTITY_TYPE.VEHICLE.KEY: {
        textfieldLabel = 'Fahrzeuge';
        fieldName = 'licensePlateName';
        allItems = this.props.vehicles.vehicles.map((vehicle) => {
          return { ...vehicle, licensePlateName: vehicle.licensePlate.name };
        });
        allItemsLoading = this.props.vehicles.vehiclesLoading;
        if (!UserUtils.isVehicleReadAllowedUser()) {
          missingPermissionsText =
            'Für die Auswahl von Fahrzeugen fehlt Ihnen die Berechtigung, um diese einzusehen.';
        }

        // loadPage = (page, licensePlate, isActive) => VehicleService.loadVehiclePage(page, licensePlate, isActive);
        // getItem = (vehicleId) => VehicleService.getVehicle(vehicleId);
        // pageSize = VehicleService.VEHICLES_PAGE_SIZE;
        break;
      }

      case PermissionGrant.ENTITY_TYPE.COMPANY.KEY: {
        textfieldLabel = 'Firmen';
        fieldName = 'name';
        allItems = this.props.companies.companies;
        allItemsLoading = this.props.companies.companiesLoading;
        if (!UserUtils.isCompanyReadAllowedUser()) {
          missingPermissionsText =
            'Für die Auswahl von Firmen fehlt Ihnen die Berechtigung, um diese einzusehen.';
        }

        // loadPage = (page, name) => CompanyService.loadCompanyPage(page, name);
        // getItem = (companyId) => CompanyService.getCompany(companyId);
        // getItems = (companyIds) => CompanyService.getCompanies(companyIds);
        // pageSize = CompanyService.COMPANIES_PAGE_SIZE;
        break;
      }

      case PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY: {
        textfieldLabel = 'Organisations-Gruppen';
        fieldName = 'name';
        allItems = this.props.organisationalGroups.organisationalGroups;
        allItemsLoading =
          this.props.organisationalGroups.organisationalGroupsLoading;
        if (!UserUtils.isOrganisationalGroupReadAllowedUser()) {
          missingPermissionsText =
            'Für die Auswahl von Organisations-Gruppen fehlt Ihnen die Berechtigung, um diese einzusehen.';
        }

        // loadPage = (page, name) => OrganisationalGroupService.loadOrganisationalGroupPage(page, name);
        // getItem = (organisationalGroupId) => OrganisationalGroupService.getOrganisationalGroup(organisationalGroupId);
        // getItems = (organisationalGroupIds) => OrganisationalGroupService.getOrganisationalGroups(organisationalGroupIds);
        // pageSize = OrganisationalGroupService.ORGANISATIONAL_GROUPS_PAGE_SIZE;
        break;
      }

      case PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY: {
        textfieldLabel = 'Benutzer-Gruppen';
        fieldName = 'name';
        allItems = this.props.userGroups.userGroups;
        allItemsLoading = this.props.userGroups.userGroupsLoading;
        if (!UserUtils.isUserGroupReadAllowedUser()) {
          missingPermissionsText =
            'Für die Auswahl von Benutzer-Gruppen fehlt Ihnen die Berechtigung, um diese einzusehen.';
        }

        // loadPage = (page, name) => UserGroupService.loadUserGroupPage(page, name);
        // getItem = (userGroupId) => UserGroupService.getUserGroup(userGroupId);
        // getItems = (userGroupIds) => UserGroupService.getUserGroups(userGroupIds);
        // pageSize = UserGroupService.USER_GROUPS_PAGE_SIZE;
        break;
      }

      default: {
        Log.error(
          null,
          new EnumValueNotFoundException(
            'Invalid entity type: ' + this.props.entityType,
          ),
        );
        return;
      }
    }

    // If the user wants to combine sites and cost centers, we need to format how the options and chips of the sites will be displayed.
    if (this.props.entityType === PermissionGrant.ENTITY_TYPE.SITE.KEY) {
      allItems = this.getFormattedSites(allItems);
    }

    this.setState({
      /* pickedItems: this.props.pickedIds.map(pickedId => this.getDefaultPickedItem(pickedId, fieldName)),
            page: 0,
            allPagesLoaded: false, */
      textfieldLabel,
      fieldName,
      allItems,
      missingPermissionsText,
      allItemsLoading,
      /* loadPage: loadPage,
            getItem: getItem,
            getItems: getItems,
            pageSize: pageSize */
    });
  }

  initAllItemsLoading() {
    let allItemsLoading = LOADING_STATE.NOT_LOADED;

    switch (this.props.entityType) {
      case PermissionGrant.ENTITY_TYPE.USER.KEY: {
        allItemsLoading = this.props.users.usersLoading;
        break;
      }

      case PermissionGrant.ENTITY_TYPE.SITE.KEY: {
        allItemsLoading = this.props.sites.sitesLoading;
        break;
      }

      case PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY: {
        allItemsLoading = this.props.costCenters.costCentersLoading;
        break;
      }

      case PermissionGrant.ENTITY_TYPE.VEHICLE.KEY: {
        allItemsLoading = this.props.vehicles.vehiclesLoading;
        break;
      }

      case PermissionGrant.ENTITY_TYPE.COMPANY.KEY: {
        allItemsLoading = this.props.companies.companiesLoading;
        break;
      }

      case PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY: {
        allItemsLoading =
          this.props.organisationalGroups.organisationalGroupsLoading;
        break;
      }

      case PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY: {
        allItemsLoading = this.props.userGroups.userGroupsLoading;
        break;
      }

      default: {
        Log.error(
          null,
          new EnumValueNotFoundException(
            'Invalid entity type: ' + this.props.entityType,
          ),
        );
        return;
      }
    }

    this.setState({
      allItemsLoading,
    });
  }

  initPickedItems() {
    const pickedItems = [];

    for (const pickedId of this.props.pickedIds) {
      const pickedItem = this.state.allItems.find(
        (item) => item.id === pickedId,
      );
      if (pickedItem) {
        pickedItems.push(pickedItem);
      }
    }

    this.setState({
      pickedItems,
    });
  }

  // If the user wants to combine sites and cost centers, we need to format how the options and chips of the sites will be displayed.
  getFormattedSites(sites) {
    if (!this.props.displayPermissionGrantOfCostCentersWithSites) {
      return sites.map((site) => {
        const costCenters =
          this.props.costCenters.costCenters.filter(
            (costCenter) =>
              costCenter.active && site.costCenters.includes(costCenter.id),
          ) ?? [];
        const costCenterNames = costCenters
          .map((costCenter) => costCenter.name)
          .join(', ');

        const newSite = {
          ...site,
          labelComponent: null,
          optionComponent: (
            <SiteNameAddressPair site={site} costCenters={costCenterNames} />
          ),
        };

        return MapperService.addSearchStringWithValues(newSite, [
          site.name,
          site.address.getConcatenatedAddress(),
          costCenterNames,
        ]);
      });
    }

    return sites.map((site) => {
      const costCenters =
        this.props.costCenters.costCenters.filter(
          (costCenter) =>
            costCenter.active && site.costCenters.includes(costCenter.id),
        ) ?? [];
      const costCenterNames = costCenters
        .map((costCenter) => costCenter.name)
        .join(', ');

      const newSite = {
        ...site,
        labelComponent:
          costCenters.length > 0
            ? site.name + ' (' + costCenterNames + ')'
            : site.name,
        optionComponent: (
          <SiteNameAddressPair
            site={site}
            costCenters={costCenterNames}
            permissionGrantOnCostCenters
          />
        ),
      };

      return MapperService.addSearchStringWithValues(newSite, [
        site.name,
        site.address.getConcatenatedAddress(),
        costCenterNames,
      ]);
    });
  }

  /* async loadFirstItemPage() {
        this.setState({
            allItems: [],
            allItemsLoading: LOADING_STATE.LOADING
        });

        this.requestId = this.requestId + 1;
        const requestId = this.requestId;

        const [allItems, err] = await promiseHandler(this.state.loadPage(0, this.state.nameFilter, true));

        if(requestId !== this.requestId) return;

        if(err) {
            this.setState({
                allItemsLoading: LOADING_STATE.FAILED
            });
            return;
        }

        this.setState({
            allItems: allItems,
            allItemsLoading: LOADING_STATE.SUCCEEDED,
            page: 1,
            allPagesLoaded: allItems.length < this.state.pageSize
        });
    }

    initPickedItems() {
        if(this.state.getItem) {
            this.initPickedItemsSeparately();
        } else if(this.state.getItems) {
            this.initPickedItemsBulk();
        }
    }

    async initPickedItemsBulk() {
        const [pickedItems, err] = await promiseHandler(this.getItems(this.props.pickedIds));

        if(err) {
            Log.error('Failed to load entities. entityIds: ' + this.props.pickedIds);
            return;
        }

        this.setState({
            pickedItems: pickedItems
        });
    }

    async initPickedItemsSeparately() {
        const promises = this.props.pickedIds.map(entityId => this.getItem(entityId));
        const results = await Promise.allSettled(promises); // Maybe use PromiseUtils.allResolved(promises);

        let pickedItems = [];
        results.forEach((result, i) => {
            if(result.status === PROMISE_STATUS.REJECTED) {
                Log.error('Failed to load entity. entity type: ' + this.props.entityType + ' | id: ' + this.props.pickedIds[i]);
                pickedItems.push(this.getDefaultPickedItem(this.props.pickedIds[i], this.state.fieldName));
                return;
            }

            pickedItems.push(result.value);
        });

        this.setState({
            pickedItems: pickedItems
        });
    }

    async loadNextItemPage() {
        if(this.state.allPagesLoaded || this.state.allItemsLoading === LOADING_STATE.LOADING) return;

        this.setState({
            allItemsLoading: LOADING_STATE.LOADING
        });

        this.requestId = this.requestId + 1;
        const requestId = this.requestId;

        const [allItems, err] = await promiseHandler(this.state.loadPage(this.state.page, this.state.nameFilter, true));

        if(requestId !== this.requestId) return;

        if(err) {
            this.setState({
                allItemsLoading: LOADING_STATE.FAILED
            });
            return;
        }

        this.setState({
            allItems: [...this.state.allItems, ...allItems],
            allItemsLoading: LOADING_STATE.SUCCEEDED,
            page: this.state.page + 1,
            allPagesLoaded: allItems.length < this.state.pageSize
        });
    }

    handleChangeNameFilter = (event, value) => {
        FunctionUtils.delayFunction('set_multiselect_search', this.setNameFilter, [value], 1000);
    }

    setNameFilter = (value) => {
        this.setState({
            nameFilter: value
        });
    } */

  getAllItems() {
    let allItems = [...this.state.allItems];

    // This manual sorting must be removed when the real pagination is implemented as the order will be determined by the backend!
    allItems = ArrayUtils.sortByKey(allItems, this.state.fieldName);

    if (this.state.allItemsLoading === LOADING_STATE.LOADING) {
      allItems.push({ id: 'loading-spinner', name: 'Laden...' });
    }

    return allItems;
  }

  /* async getItem(entityId) {
        const item = this.state.allItems.find(item => item.id === entityId);

        if(item) return item;

        return this.state.getItem(entityId)
    }

    async getItems(entityIds) {
        let items = [];
        let openEntityIds = [];

        entityIds.forEach(entityId => {
            const item = this.state.allItems.find(item => item.id === entityId);

            if(item) {
                items.push(item);
            } else {
                openEntityIds.push(entityId);
            }
        });

        const [response, err] = await promiseHandler(this.state.getItems(openEntityIds));

        if(err) return Promise.reject(err);

        items.push(...response);

        return ArrayUtils.sortByKeyValues(items, entityIds, 'id');
    } */

  getNoOptionsText() {
    if (this.state.allItemsLoading === LOADING_STATE.LOADING) {
      return <Spinner />;
    }

    if (this.state.allItemsLoading === LOADING_STATE.FAILED) {
      return 'Optionen konnten nicht geladen werden.';
    }

    if (this.state.missingPermissionsText) {
      Log.productAnalyticsEvent(
        'Missing permissions to read entities',
        Log.FEATURE.PERMISSIONS,
        Log.TYPE.ERROR,
      );
      return this.state.missingPermissionsText;
    }

    return 'Keine Optionen';
  }

  /* getDefaultPickedItem(pickedId, fieldName) {
        return {id: pickedId, [fieldName]: '...'}
    } */

  /* onChipClick = (entityId) => {
        if(this.props.onChipClick) this.props.onChipClick(entityId);
    } */

  render() {
    // Don't render tags if chips can't be clicked because otherwise org group form is laggy in case of STRABAG org group 111aaefc-c7a0-41e2-bffd-9d71a2509a53
    /* const renderTags = this.props.onChipClick ? {
            renderTags: (params, getTagProps) => params.map((option, index) =>
                <div className="m-3px" onClick={() => this.onChipClick(option)}>
                    <Chip key={option.id} label={option[this.state.fieldName]} {...getTagProps({ index })} sx={this.props.onChipClick ? {cursor: "pointer"} : null}/>
                </div>
            )
        } : {}; */

    return (
      <div className="relative w-full">
        <Autocomplete
          disabled={this.props.disabled}
          fullWidth={this.props.fullWidth}
          className={this.props.className}
          multiple
          options={this.getAllItems()}
          getOptionLabel={(option) => option[this.state.fieldName]}
          value={this.state.pickedItems}
          noOptionsText={this.getNoOptionsText()}
          autoComplete="off"
          onChange={(event, value) => this.props.callbackPickedItems(value)}
          // onInputChange={this.handleChangeNameFilter}
          filterOptions={(options, { inputValue }) =>
            options.filter((option) => {
              if (option.id === 'loading-spinner') {
                return true;
              }

              const searchString =
                option.searchString ?? option[this.state.fieldName];
              return searchString
                .toLowerCase()
                .includes(inputValue.toLowerCase());
            })
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionDisabled={(option, value) => option.id === 'loading-spinner'}
          renderTags={(params, getTagProps) =>
            params.map((option, index) => (
              <div className="m-3px">
                <Chip
                  key={option.id}
                  label={option.labelComponent ?? option[this.state.fieldName]}
                  {...getTagProps({ index })}
                />
              </div>
            ))
          }
          renderOption={(props, option) => {
            if (option.id === 'loading-spinner') {
              return (
                <li>
                  <Spinner />
                </li>
              );
            }

            if (option.optionComponent) {
              return <li {...props}>{option.optionComponent}</li>;
            }

            return <li {...props}>{option[this.state.fieldName]}</li>;
          }}
          renderInput={(params) => {
            params.inputProps.autoComplete = 'new-password'; // prevent autocomplete by browser

            return (
              <TextField
                {...params}
                variant="standard"
                label={
                  this.props.textfieldLabel ??
                  this.state.textfieldLabel ??
                  'Suche'
                }
                placeholder={
                  this.props.disabled ||
                  this.state.pickedItems.length === this.state.allItems.length
                    ? ''
                    : 'Weitere'
                }
              />
            );
          }}
          /* ListboxProps={{
                        onScroll: (event: React.SyntheticEvent) => {
                            const listboxNode = event.currentTarget;
                            if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                this.loadNextItemPage();
                            }
                        }
                    }} */
        />
        {this.props.error ? (
          <FormHelperText className="text-mui-error-red absolute">
            {this.props.error}
          </FormHelperText>
        ) : null}
      </div>
    );
  }
}

export default connect(mapStateToProps)(PaginatedEntityMultiPicker);
