import { useState } from 'react';

import { SignatureIcon } from '~/assets/icons';

import IconButton from '~/components/IconButton';

import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

import PushService from '~/services/push.service';
import ToastService from '~/services/toast.service';

export const SignInAppButton = ({ id }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSignDeliveryNote = async () => {
    Log.info('Request push notification to sign delivery note');
    Log.productAnalyticsEvent('Sign delivery note', Log.FEATURE.NOTIFICATIONS);

    setIsProcessing(true);

    const [, error] = await promiseHandler(
      PushService.requestSignature([id], []),
    );

    if (error) {
      Log.error(
        'Failed to send push to request delivery note signature.',
        error,
      );
      ToastService.httpError(
        [
          'Die Lieferung konnte nicht in der mobilen App signiert werden wegen eines internen Fehlers.',
        ],
        error.response,
      );
      Log.productAnalyticsEvent(
        'Failed to sign delivery note',
        Log.FEATURE.NOTIFICATIONS,
        Log.TYPE.ERROR,
      );
      return;
    }

    setIsProcessing(false);

    ToastService.success([
      'Die Lieferung kann nun in der mobilen App signiert werden.',
      'Bitte überprüfe, ob du eine Benachrichtigung auf deinem Handy erhalten hast.',
    ]);
  };

  return (
    <IconButton
      onClick={handleSignDeliveryNote}
      isLoading={isProcessing}
      tooltipTitle="Lieferung in mobiler App signieren."
      size="large"
    >
      <SignatureIcon className="text-primary500 icon-small" />
    </IconButton>
  );
};
