import InvoiceCheckResult from './InvoiceCheckResult';
import ArrayUtils from '~/utils/arrayUtils';

export default class InvoiceCheckCategory {
  constructor(category, checkResults) {
    this.key = category.KEY;

    this.errorChecks = checkResults.filter(
      (checkResult) =>
        category.CHECKS.includes(checkResult.name) &&
        checkResult.status === InvoiceCheckResult.STATUS.ERROR,
    );
    this.successChecks = checkResults.filter(
      (checkResult) =>
        category.CHECKS.includes(checkResult.name) &&
        checkResult.status === InvoiceCheckResult.STATUS.SUCCESS,
    );
    this.manualChecks = checkResults.filter(
      (checkResult) =>
        category.CHECKS.includes(checkResult.name) &&
        checkResult.status === InvoiceCheckResult.STATUS.NA,
    );
    this.delayedSuccessChecks = []; // Delayed Success checks are set async when the dlns have been loaded.

    this.name =
      this.errorChecks.length > 0 ? category.ERROR_NAME : category.SUCCESS_NAME;
    this.status = this.getStatus();

    this.sortCheckResults(category.CHECKS);
  }

  getStatus() {
    // In the case of the AMOUNT_APPROVED_CHECK, it is already enough for the category to be successful if no articles have been declined and thus no errors have been thrown.
    if (
      this.key === InvoiceCheckCategory.CATEGORIES.AMOUNT_APPROVED_CHECK.KEY &&
      this.errorChecks.length === 0
    ) {
      return InvoiceCheckResult.STATUS.SUCCESS;
    }

    // In case of the signature check, we want to display a yellow warning if there are unsigned delivery notes.
    if (
      this.key === InvoiceCheckCategory.CATEGORIES.SIGNATURE_CHECK.KEY &&
      this.errorChecks.length > 0
    ) {
      return InvoiceCheckResult.STATUS.WARNING;
    }

    if (this.errorChecks.length > 0) {
      return InvoiceCheckResult.STATUS.ERROR;
    }

    if (this.successChecks.length > 0) {
      return InvoiceCheckResult.STATUS.SUCCESS;
    }

    return InvoiceCheckResult.STATUS.MANUAL;
  }

  // Sort the check results
  // - first by the check name according to the order in the enum InvoiceCheckCategory.CATEGORIES
  // - second by the article name
  // - third by the delivery note number (more specifically: by the first delivery note from the list of assigned delivery notes)
  sortCheckResults(sortedChecks) {
    for (const checkResult of this.errorChecks)
      checkResult.dlnNumber = checkResult.deliveryNotes[0]?.number;
    this.errorChecks = ArrayUtils.sortByKey(this.errorChecks, 'dlnNumber');
    this.errorChecks = ArrayUtils.sortByKey(this.errorChecks, 'articleName');
    this.errorChecks = ArrayUtils.sortByKeyValues(
      this.errorChecks,
      sortedChecks,
      'name',
    );
    for (const checkResult of this.errorChecks) delete checkResult.dlnNumber;

    for (const checkResult of this.successChecks)
      checkResult.dlnNumber = checkResult.deliveryNotes[0]?.number;
    this.successChecks = ArrayUtils.sortByKey(this.successChecks, 'dlnNumber');
    this.successChecks = ArrayUtils.sortByKey(
      this.successChecks,
      'articleName',
    );
    this.successChecks = ArrayUtils.sortByKeyValues(
      this.successChecks,
      sortedChecks,
      'name',
    );
    for (const checkResult of this.successChecks) delete checkResult.dlnNumber;

    for (const checkResult of this.manualChecks)
      checkResult.dlnNumber = checkResult.deliveryNotes[0]?.number;
    this.manualChecks = ArrayUtils.sortByKey(this.manualChecks, 'dlnNumber');
    this.manualChecks = ArrayUtils.sortByKey(this.manualChecks, 'articleName');
    this.manualChecks = ArrayUtils.sortByKeyValues(
      this.manualChecks,
      sortedChecks,
      'name',
    );
    for (const checkResult of this.manualChecks) delete checkResult.dlnNumber;
  }

  // TODO: function should not be static not static
  static getUnsignedDeliveryNoteIds(errorChecks) {
    const unsignedDeliveryNoteIds = [];

    for (const checkResult of errorChecks.filter(
      (checkResult) => checkResult.name === 'DeliveryNoteAuthorized',
    )) {
      for (const deliveryNote of checkResult.deliveryNotes) {
        if (!deliveryNote.id) {
          continue;
        }

        unsignedDeliveryNoteIds.push(deliveryNote.id);
      }
    }

    return unsignedDeliveryNoteIds;
  }

  static CATEGORIES = {
    FORMAL_CHECK: {
      KEY: 'formal-check',
      SUCCESS_NAME: 'Formale Prüfung',
      ERROR_NAME: 'Formale Prüfung',
      CHECKS: [
        // according to §14 UStG (Abs. 4)
        'SenderNameExists',
        'SenderAddressExists',
        'ReceiverNameExists',
        'ReceiverAddressExists',
        'TaxIDExists',
        'InvoiceDateExists',
        'InvoiceIDExists',
        'DeliveryDateExists',
        'TotalPriceExists',
        // calculation inside of invoice
        'TaxAmountExists',
        'TaxRateExists',
        'TaxRateCheck',
        'TotalTaxAmountCalculation',
        'TotalGrossPriceCalculation',
        'TotalNetPriceCalculation',
        // cross-check with VESTIGAS data
        // "MatchingRecipientData", // not provided by backend because checking master data is not necessary
        // "MatchingSenderData", // not provided by backend because checking master data is not necessary
        'UniqueInvoiceID',
        // checking if items have all data
        // "ItemIdentificationExists", // Check is unnecessary since we don’t parse invoices with missing data
        // "ItemCountExists", // Check is unnecessary since we don’t parse invoices with missing data
        // "ItemUnitTypeExists", // Check is unnecessary since we don’t parse invoices with missing data
        // "ItemTaxRateExists", // Check is unnecessary since we don’t parse invoices with missing data
        'MaterialNetPriceCalculation',
        'MaterialTaxRateCheck',
      ],
    },
    DLN_CHECK: {
      KEY: 'dln-check',
      SUCCESS_NAME: 'Lieferscheinprüfung',
      ERROR_NAME: 'Lieferscheinprüfung',
      CHECKS: [
        'DeliveryNoteExists',
        'DeliveryNoteNotAlreadySettled',
        'DeliveryNoteReferenceExists',
        'DeliveryNoteItemAlreadyBilled',
      ],
    },
    SIGNATURE_CHECK: {
      KEY: 'signature-check',
      SUCCESS_NAME: 'Signaturen',
      ERROR_NAME: 'Signaturen',
      CHECKS: ['DeliveryNoteAuthorized'],
    },
    ARTICLE_EXISTS_CHECK: {
      KEY: 'item-exists-check',
      SUCCESS_NAME: 'Artikel vorhanden',
      ERROR_NAME: 'Artikel fehlen',
      CHECKS: ['DeliveryNoteMaterialMatches'],
    },
    AMOUNT_CHECK: {
      KEY: 'amount-check',
      SUCCESS_NAME: 'Mengenabgleich',
      ERROR_NAME: 'Mengenabgleich',
      CHECKS: ['DeliveryNoteQuantityMatches'],
    },
    AMOUNT_APPROVED_CHECK: {
      KEY: 'amount-approved-check',
      SUCCESS_NAME: 'Keine Reklamationen',
      ERROR_NAME: 'Reklamationen',
      CHECKS: ['AcceptedPartialDeliveryQuantityMatches'],
    },
    PRICE_CHECK: {
      KEY: 'price-check',
      SUCCESS_NAME: 'Preisprüfung',
      ERROR_NAME: 'Preisprüfung',
      CHECKS: [],
    },
  };
  // order matters because in this order, the delivery notes from the articles are assigned to the categories in case of multiple matches
  static CHART_CATEGORIES = {
    ALREADY_SETTLED: {
      NAME: 'Bereits abgerechnet',
      CHECKS: ['DeliveryNoteNotAlreadySettled'],
    },
    NOT_APPROVED_DECLINED: {
      NAME: 'Unbestätigter oder abgelehnter Artikel',
      CHECKS: ['AcceptedPartialDeliveryQuantityMatches'],
    },
    NOT_APPROVED_WRONG_ARTICLE: {
      NAME: 'Unbestätigt, fehlerhafter Artikel',
      CHECKS: ['DeliveryNoteMaterialMatches', 'DeliveryNoteQuantityMatches'],
    },
    NOT_APPROVED_NOT_SIGNED: {
      NAME: 'Unbestätigt, nicht signiert',
      CHECKS: ['DeliveryNoteAuthorized', 'DeliveryNoteChangesAuthorized'],
    },
    NOT_APPROVED_NOT_EXISTING: {
      NAME: 'Unbestätigt, liegt nicht vor',
      CHECKS: ['DeliveryNoteExists'],
    },
    APPROVED: {
      NAME: 'Bestätigt',
      CHECKS: [],
    },
  };
}
