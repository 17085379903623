export default class CustomData {
  constructor(additionalPartyData) {
    this.setData(additionalPartyData);
  }

  setData(additionalPartyData) {
    if (!additionalPartyData) {
      return;
    }

    for (const entry of Object.entries(additionalPartyData)) {
      const key = entry[0];
      const value = entry[1];

      this[key] = value;
    }
  }

  getKeys() {
    return Object.keys(this);
  }

  getValues() {
    return Object.values(this);
  }

  getEntries() {
    return Object.entries(this);
  }

  getLength() {
    return Object.keys(this).length;
  }
}
