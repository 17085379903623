import moment from 'moment';
import LocalStorageService from '~/services/localStorage.service';
import Log from './Log';
import InvalidDateException from '~/errors/InvalidDateException';

export const startOfDay = (date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);

  return newDate?.getTime();
};

export const endOfDay = (date) => {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);

  return newDate?.getTime();
};

export const parseDate = (date) => {
  return new Date(date);
};

/**
 * Converts a given date from UTC to the user's local time.
 *
 * Offsets UTC time to the user's local time.
 * Important when reading dates from the server, as the server sends UTC time!
 *
 * @param {string | number | Date} date - The date to convert from UTC.
 * @returns {Date | null} - The equivalent date in the user's local time, or null if the input date is undefined or null.
 */
export const fromUTC = (date) => {
  if (!date) {
    return null;
  }

  let parsedDate;

  // Parse the input date
  if (typeof date === 'string' || typeof date === 'number') {
    parsedDate = new Date(date);
  } else if (date instanceof Date) {
    parsedDate = new Date(date.getTime());
  } else {
    return null;
  }

  // Check if the parsed date is valid
  if (Number.isNaN(parsedDate.getTime())) {
    return null;
  }

  // Calculate the timezone offset based on the specific date - important for handling daylight saving time!
  const timezoneOffset = parsedDate.getTimezoneOffset() * 60_000;

  // Convert UTC to local time by subtracting the offset
  const localDate = new Date(parsedDate.getTime() - timezoneOffset);

  // If the date is a string without time information, set the time to midnight local time
  if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
    localDate.setHours(0, 0, 0, 0);
  }

  return localDate;
};

/**
 * Converts a given date string into UTC format.
 *
 * Offsets timezone from user's local time into UTC time
 *
 * @param {string | number | Date} date - The date to convert to UTC.
 * @returns {Date | null} - The equivalent date in UTC time, or null if the input date is undefined or null.
 */
export const toUTC = (date) => {
  if (!date) {
    return null;
  }

  let parsedDate;

  // Parse the input date
  if (typeof date === 'string' || typeof date === 'number') {
    parsedDate = new Date(date);
  } else if (date instanceof Date) {
    parsedDate = new Date(date.getTime());
  } else {
    return null;
  }

  // Check if the parsed date is valid
  if (Number.isNaN(parsedDate.getTime())) {
    return null;
  }

  // If the date is a string without time information, set the time to midnight UTC
  if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
    parsedDate.setUTCHours(0, 0, 0, 0);
  }

  return parsedDate;
};

class DateUtils {
  constructor() {
    this.MONTH_NAMES = [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ];

    this.PREDEFINED_DATE_RANGE_OPTIONS = {
      DAY: {
        ID: 'day',
        STRING: 'Heute',
      },
      YESTERDAY: {
        ID: 'yesterday',
        STRING: 'Gestern',
      },
      WEEK: {
        ID: 'week',
        STRING: 'Diese Woche',
      },
      LAST_WEEK: {
        ID: 'last-week',
        STRING: 'Letzte Woche',
      },
      MONTH: {
        ID: 'month',
        STRING: 'Dieser Monat',
      },
      LAST_MONTH: {
        ID: 'last-month',
        STRING: 'Letzter Monat',
      },
      YEAR: {
        ID: 'year',
        STRING: 'Dieses Jahr',
      },
      _7DAYS: {
        ID: '7-days',
        STRING: 'Letzte 7 Tage',
      },
      _30DAYS: {
        ID: '30-days',
        STRING: 'Letzte 30 Tage',
      },
      _365DAYS: {
        ID: '365-days',
        STRING: 'Letzte 365 Tage',
      },
    };

    this.DATE_FORMAT = {
      DD_MM_YYYY__HH_mm_ss: 'DD.MM.YYYY HH:mm:ss',
      DD_MM_YYYY__HH_mm: 'DD.MM.YYYY HH:mm',
      DD_MM_YYYY: 'DD.MM.YYYY',
      YYYYMMDDHHMMSS: 'YYYYMMDDHHmmss',
      YYYYMMDD_HHMMSS: 'YYYYMMDD_HHmmss',
      YYYYMMDD: 'YYYYMMDD',
      DD_MM: 'DD.MM',
      HH_mm_ss: 'HH:mm:ss',
      YYYY_MM_DD: 'YYYY.MM.DD',
      YYYY_MM_DD_ISO: 'YYYY-MM-DD',
      YYYY_MM_DD__HH_mm_ss_SSSSSS: 'YYYY-MM-DD HH:mm:ss.SSSSSS',
      HH: 'HH',
      mm: 'mm',
      DATE_TIME_INPUT_FORMAT: 'YYYY-MM-DD[T]HH:mm',
    };
  }

  getFormattedDate = (date, format) => {
    const value = moment(date);

    if (!value.isValid()) {
      throw new InvalidDateException();
    }

    return value.format(format);
  };
  getFormattedDate_safe = (date, outputFormat, inputFormat) => {
    if (date === null || date === undefined) {
      return null;
    }

    const value = moment(date, inputFormat);

    if (!value.isValid()) {
      return null;
    }

    return value.format(outputFormat);
  };
  getFormattedDateWithoutMidnight_safe = (date, outputFormat, inputFormat) => {
    if (date == null) {
      return null;
    }

    const value = moment(date, inputFormat);

    if (!value.isValid()) {
      return null;
    }

    const formattedValue = value.format(outputFormat);

    if (
      formattedValue.split(' ')[1] === '00:00' ||
      formattedValue.split(' ')[1] === '00:00:00'
    ) {
      return formattedValue.split(' ')[0];
    }

    return formattedValue;
  };

  getMonthStringFromDate(date) {
    return `${this.MONTH_NAMES[date.getMonth()]} ${date.getFullYear()}`;
  }

  getNumberOfDays(from, to) {
    return Math.round((to - from) / (1000 * 60 * 60 * 24)) + 1;
  }

  formatMillisecondsAsString(t) {
    const cd = 24 * 60 * 60 * 1000;
    const ch = 60 * 60 * 1000;
    let d = Math.floor(t / cd);
    let h = Math.floor((t - d * cd) / ch);
    let m = Math.round((t - d * cd - h * ch) / 60_000);
    const pad = (n) => (n < 10 ? '0' + n : n);

    if (m === 60) {
      h++;
      m = 0;
    }

    if (h === 24) {
      d++;
      h = 0;
    }

    return [d, pad(h), pad(m)].join(':');
  }

  isValidDate(date) {
    return date instanceof Date && !isNaN(date);
  }

  /**
   * Converts the selected date range into a timeframe object for data filtering.
   *
   * @param {array} selectedDateRange - An array representing the selected date range.
   * @return {object} The timeframe object with 'from' and 'to' properties.
   */
  extractTimeframe(selectedDateRange) {
    if (!selectedDateRange?.[0] || !selectedDateRange[1]) {
      Log.error(
        'Failed to extract timeframe from date range. date range: ' +
          JSON.stringify(selectedDateRange),
      );

      const from = new Date();
      from.setFullYear(from.getFullYear() - 100); // subtract 100 years per default
      const to = new Date();

      return {
        from,
        to,
      };
    }

    const timeframe = {
      from: startOfDay(selectedDateRange[0]),
      to: endOfDay(selectedDateRange[1]),
    };

    return timeframe;
  }

  isToday(date) {
    return (
      new Date(date).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
    );
  }

  onTheSameDay(day1, day2) {
    return (
      new Date(day1).setHours(0, 0, 0, 0) ===
      new Date(day2).setHours(0, 0, 0, 0)
    );
  }

  // Special function is needed because the to date of the date range must be set to the current day if the cookie is "old".
  // Otherwise, the to date would stay the same and the user wouldn't see his latest deliveries.
  // Thus, if the creation date of the cookie is not from today, take today as to date.
  getDateRangeFromCookies(cookieName, defaultStart, defaultEnd) {
    const cookie = LocalStorageService.getObjectFromLocalStorage(cookieName);

    if (!cookie) {
      return [defaultStart, defaultEnd];
    }

    const dateRange = cookie.data.split(LocalStorageService.SEPARATOR);
    const creationDate = cookie.creationDate;

    // if not from today, set the to date to today so that user sees his latest delivery notes
    if (!this.isToday(creationDate)) {
      return [dateRange[0], defaultEnd];
    }

    return dateRange;
  }

  setDateRangeAsCookie(cookieName, dateRange) {
    LocalStorageService.setMetaDataLocalStorage(
      cookieName,
      dateRange.join(LocalStorageService.SEPARATOR),
    );
  }

  getTimeframeFromDateRange(predefinedDateRange) {
    let timeframe = null;

    if (predefinedDateRange === this.PREDEFINED_DATE_RANGE_OPTIONS.DAY.ID) {
      timeframe = [new Date(), new Date()];
    }

    if (
      predefinedDateRange === this.PREDEFINED_DATE_RANGE_OPTIONS.YESTERDAY.ID
    ) {
      const from = new Date();
      from.setDate(from.getDate() - 1);
      const to = new Date();
      to.setDate(to.getDate() - 1);
      timeframe = [from, to];
    }

    if (predefinedDateRange === this.PREDEFINED_DATE_RANGE_OPTIONS.WEEK.ID) {
      const from = new Date();
      const day = from.getDay();
      const diff = from.getDate() - day + (day === 0 ? -6 : 1);

      timeframe = [new Date(from.setDate(diff)), new Date()];
    }

    if (
      predefinedDateRange === this.PREDEFINED_DATE_RANGE_OPTIONS.LAST_WEEK.ID
    ) {
      let from = new Date();
      const day = from.getDay();
      const diff = from.getDate() - day + (day === 0 ? -6 : 1);
      from = new Date(from.setDate(diff));
      from.setDate(from.getDate() - 7);
      const to = new Date(from.getTime());
      to.setDate(from.getDate() + 6);
      timeframe = [from, to];
    }

    if (predefinedDateRange === this.PREDEFINED_DATE_RANGE_OPTIONS.MONTH.ID) {
      const from = new Date();
      from.setDate(1);
      timeframe = [from, new Date()];
    }

    if (
      predefinedDateRange === this.PREDEFINED_DATE_RANGE_OPTIONS.LAST_MONTH.ID
    ) {
      const from = new Date();
      from.setDate(1);
      from.setMonth(from.getMonth() - 1);
      const to = new Date();
      to.setDate(0);
      timeframe = [from, to];
    }

    if (predefinedDateRange === this.PREDEFINED_DATE_RANGE_OPTIONS.YEAR.ID) {
      const from = new Date();
      from.setDate(1);
      from.setMonth(0);
      timeframe = [from, new Date()];
    }

    if (predefinedDateRange === this.PREDEFINED_DATE_RANGE_OPTIONS._7DAYS.ID) {
      const from = new Date();
      from.setDate(from.getDate() - 7);
      timeframe = [from, new Date()];
    }

    if (predefinedDateRange === this.PREDEFINED_DATE_RANGE_OPTIONS._30DAYS.ID) {
      const from = new Date();
      from.setDate(from.getDate() - 30);
      timeframe = [from, new Date()];
    }

    if (
      predefinedDateRange === this.PREDEFINED_DATE_RANGE_OPTIONS._365DAYS.ID
    ) {
      const from = new Date();
      from.setDate(from.getDate() - 365);
      timeframe = [from, new Date()];
    }

    if (!timeframe) {
      Log.error('Invalid predefined date range: ' + predefinedDateRange);
      return null;
    }

    // Extended logs to debug bug with incorrect filters.
    // console.log('dateUtils.extractTimeframe() -> 1st log');
    // console.log(cloneDeep(timeframe));
    // Reset the timeframes to midnight so that they can be compared in the cleanDateRange function in the DateRangeSelect component.
    timeframe[0] = new Date(timeframe[0].setHours(0, 0, 0, 0)).toString();
    timeframe[1] = new Date(timeframe[1].setHours(0, 0, 0, 0)).toString();

    // Extended logs to debug bug with incorrect filters.
    // console.log('dateUtils.extractTimeframe() -> 2nd log');
    // console.log(cloneDeep(timeframe));
    return timeframe;
  }

  // take a predefined dateRange and the date of the oldest dlnNote & determine if
  // date of the dln is older than the dateRange. It should always return a boolean
  isDlnDateOlderThanDateRange(predefinedDateRange, dlnDate) {
    const [from] = this.getTimeframeFromDateRange(predefinedDateRange);
    return this.isDateOlderThanDate(from, dlnDate);
  }

  isDateOlderThanDate(dateA, dateB) {
    return moment(dateA).isBefore(moment(dateB));
  }

  sortDeliveryNotesByOldestDate(deliveryNotes) {
    return deliveryNotes
      .filter((d) => d.dlnDate.length > 0)
      .sort((a, b) => new Date(b.dlnDate) - new Date(a.dlnDate));
  }

  getDifferenceInHours(date1, date2) {
    const diffInMilliseconds =
      new Date(date2).getTime() - new Date(date1).getTime();
    // Convert the time difference from milliseconds to hours
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

    return diffInHours;
  }
}

export const dateUtils = new DateUtils();
