import ToastService from '~/services/toast.service';
import UserService from '~/services/user.service';

import { promiseHandler } from '~/utils/promiseHandler';
import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';

/**
 * Updates the list of filter groups by replacing the selected filter group provided updated filter group object.
 *
 * @param {Array} filterGroups - The array of filter groups to update.
 * @param {string} selectedFilterGroupId - The ID of the selected filter group.
 * @param {object} updatedFilterGroupObject - The updated filter group object.
 * @param {function} setDelivery_filterGroups - Callback function to update the filter groups in the Redux store.
 */
export const updateFilterGroup = async (
  filterGroups,
  selectedFilterGroupId,
  updatedFilterGroupObject,
  setDelivery_filterGroups,
) => {
  const newFilterGroups = ArrayUtils.updateByKey(
    filterGroups,
    'id',
    selectedFilterGroupId,
    updatedFilterGroupObject,
  );

  const [, error] = await promiseHandler(
    UserService.updateDeliveryFilterGroups(newFilterGroups),
  );

  if (error) {
    Log.error('Failed to update filter group.', error);
    ToastService.error(['Filter konnte nicht geändert werden.']);
    throw error; // Re-throw error to be caught by calling function
  }

  setDelivery_filterGroups(newFilterGroups);
};
