/**
 * Check if a string represents an integer.
 *
 * @param {string} string - The string to check.
 * @return {boolean} True if the string represents an integer, false otherwise.
 */
export const isInteger = (string) => {
  /**
   * Regular expression to match strings that represent integers.
   * The regular expression matches strings that start with a digit
   * other than zero, followed by zero or more digits.
   * @type {RegExp}
   */
  const integerRegex = /^[1-9]\d*$/;

  // Check if the string matches the integer regex.
  return integerRegex.test(string);
};

/**
 * Calculate the precision of a number.
 *
 * @param {number} a - The number to calculate the precision for.
 * @return {number} The precision of the number as the number of decimal places.
 */
export const precision = (a) => {
  if (!isFinite(a)) {
    return 0;
  }

  let e = 1;
  let p = 0;

  while (Math.round(a * e) / e !== a) {
    e *= 10;
    p++;
  }

  return p;
};
