import React from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';

import { Grid } from '@mui/material';

import { SentryRoute } from '~/services/thirdParty.service';

import SettingsMenu from './SettingsMenu';
import ProfileSettings from './profile/ProfileSettings';
import SupportSettings from './support/SupportSettings';
import Demo from './Demo';
import { UnauthorizedPage } from '../menu/UnauthorizedPage';
import { ROUTE } from '~/constants/Route';
import { SiteTable } from './masterData/site/SiteTable';
import CostCenterTable from './masterData/costCenter/CostCenterTable';
import CompanyTable from './masterData/company/CompanyTable';
import UsersTable from './masterData/users/UsersTable';
import CustomerSubscriptionTable from './admin/customerSubscription/CustomerSubscriptionTable';
import { withErrorBoundary } from '~/ui/atoms';
import VehicleTable from './masterData/vehicle/VehicleTable';
import SiteService from '~/services/site.service';
import { setUsersLoading } from '~/redux/usersSlice';
import DataProtection from './dataProtection/DataProtection';
import PdfSettingsService from '~/services/pdfsettings.service';
import PdfSettings from './admin/pdf/PdfSettings';
import Impressum from './Impressum';
import CostCenterService from '~/services/costCenter.service';
import VehicleService from '~/services/vehicle.service';
import { setPageTitle } from '~/redux/menuSlice';
import CustomerSubscriptionService from '~/services/customerSubscription.service';
import UserService from '~/services/user.service';
import OrganisationalGroupService from '~/services/organisationalGroup.service';
import OrganisationalGroupTable from './masterData/organisationalGroup/OrganisationalGroupTable';
import UserGroupService from '~/services/userGroup.service';
import UserGroupTable from './masterData/userGroup/UserGroupTable';
import CompanyService from '~/services/company.service';
import MasterData from './masterData/MasterData';
import CustomFieldTable from './admin/customField/CustomFieldTable';
import SignatureFieldService from '~/services/signatureField.service';
import SignatureFieldTable from './admin/signatureField/SignatureFieldTable';
import ArticleMasterService from '~/services/articleMaster.service';
import ArticleTable from './admin/articleMaster/ArticleTable';
import CategoryTable from './admin/articleMaster/CategoryTable';
import InvoicesService from '~/services/invoices.service';
import InvoiceCheckIgnoredArticleTable from './admin/invoiceCheckIgnoredArticle/InvoiceCheckIgnoredArticleTable';
import DataSubscriptionService from '~/services/dataSubscription.service';
import DataSubscriptions from './admin/dataSubscriptions/DataSubscriptions';
import UserUtils from '~/utils/userUtils';

const mapStateToProps = (state) => ({
  userinfo: state.userinfo,
  companies: state.companies.companies,
  users: state.users,
});
const mapDispatchToProps = () => ({
  setUsersLoading,
  setPageTitle,
});

class Settings extends React.Component {
  componentDidMount() {
    UserService.loadUsers();
    SiteService.loadSites();
    CostCenterService.loadCostCenters();
    VehicleService.loadVehicles();
    CompanyService.loadCompanies();
    OrganisationalGroupService.loadOrganisationalGroups();
    UserGroupService.loadUserGroups();
    CustomerSubscriptionService.loadCustomerSubscriptions();

    if (UserUtils.isVestigasAccount()) {
      // Don't need to load custom fields as they are already loaded in AppInitializer because they are needed to correctly display delivery notes.
      SignatureFieldService.loadSignatureFields();
      // Only load articles and categories of company account of logged-in user because only those are editable in the settings area.
      ArticleMasterService.loadArticles(
        this.props.userinfo.userinfo.company?.companyAccount,
        this.props.userinfo.userinfo.company?.id,
      );
      ArticleMasterService.loadCategories(
        this.props.userinfo.userinfo.company?.companyAccount,
        this.props.userinfo.userinfo.company?.id,
      );
      // Other features
      PdfSettingsService.loadPdfTemplates();
      InvoicesService.loadInvoiceCheckIgnoredArticles();
    }

    if (UserUtils.isVestigasSupportAccount()) {
      // Data subscriptions validate if the request comes from a support account.
      DataSubscriptionService.loadContactPoints();
      DataSubscriptionService.loadDataSubscriptions();
      DataSubscriptionService.loadNotificationPolicies();
    }

    this.props.setPageTitle('Einstellungen');
    document.title = 'VESTIGAS - Einstellungen';
  }

  render() {
    return (
      <div className="main-padding">
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} lg={3}>
            <SettingsMenu />
          </Grid>
          <Grid item xs={12} lg={9}>
            {UserService.userIsAuthorizedForPage(
              this.props.userinfo.userPermissions,
              this.props.location.pathname,
            ) ? (
              <Switch>
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS.ROUTE}
                  component={ProfileSettings}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_PROFILE.ROUTE}
                  component={ProfileSettings}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_SUPPORT.ROUTE}
                  component={SupportSettings}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_COMPANY.ROUTE}
                  component={CompanyTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_MASTERDATA.ROUTE}
                  component={MasterData}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_USER.ROUTE}
                  component={UsersTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_SITE.ROUTE}
                  component={SiteTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_COST_CENTER.ROUTE}
                  component={CostCenterTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_VEHICLE.ROUTE}
                  component={VehicleTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_ORGANISATIONAL_GROUP.ROUTE}
                  component={OrganisationalGroupTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_USER_GROUP.ROUTE}
                  component={UserGroupTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_DATA_SUBSCRIPTIONS.ROUTE}
                  component={DataSubscriptions}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_CUSTOM_FIELDS.ROUTE}
                  component={CustomFieldTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_SIGNATURE_FIELDS.ROUTE}
                  component={SignatureFieldTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_ARTICLES.ROUTE}
                  component={ArticleTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_CATEGORIES.ROUTE}
                  component={CategoryTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_CUSTOMER_SUBSCRIPTIONS.ROUTE}
                  component={CustomerSubscriptionTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_PDF.ROUTE}
                  component={PdfSettings}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_INVOICE_CHECK_IGNORED_ARTICLES.ROUTE}
                  component={InvoiceCheckIgnoredArticleTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_DEMO.ROUTE}
                  component={Demo}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_DATA_PROTECTION.ROUTE}
                  component={DataProtection}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_IMPRESSUM.ROUTE}
                  component={Impressum}
                />
              </Switch>
            ) : (
              <UnauthorizedPage />
            )}
          </Grid>
        </Grid>
        <div
          className="min-h-2rem"
          /* This is a hacky workaround to get the padding bottom of 2rem. It is applied as child container to all divs with main-padding */
          /* A better solution would be to make the parent container min-h-fit-content so that the padding of main-padding is applied. */
          /* However, min-h-fit-content seems to not work with h-fill or generally with flexbox and flex-1. */
        />
      </div>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(Settings),
  'Einstellungen konnten nicht geladen werden.',
);
