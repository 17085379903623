import React from 'react';
import { withErrorBoundary } from '~/ui/atoms';
import ValueGroup from '~/models/deliveries/ValueGroup';
import Article from '~/models/articles/Article';

function DeliveryNoteArticleSummary(props) {
  // TODO: debug error in d28d1bbc-a17e-4b0f-9c33-7f3aa8cb034c
  console.log(
    ValueGroup.getCurrentValue(
      props.deliveryNote?.totalWeightDeliveredArticles,
    ),
  );
  console.log(
    ValueGroup.getCurrentValue(props.deliveryNote?.totalWeightReturnedArticles),
  );

  const getTotalWeight = () => {
    return props.deliveryDirection ===
      Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY
      ? ValueGroup.getCurrentValue(
          props.deliveryNote?.totalWeightDeliveredArticles,
        )
      : ValueGroup.getCurrentValue(
          props.deliveryNote?.totalWeightReturnedArticles,
        );
  };

  const getArticleCount = () => {
    return props.deliveryDirection ===
      Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY
      ? props.deliveryNote?.deliveredArticles?.length
      : props.deliveryNote?.returnedArticles?.length;
  };

  return (
    <div className="flex-sb-e p-20px pb-10px">
      <div>
        <div className="bold">
          {props.deliveryDirection ===
          Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY
            ? 'Auslieferung'
            : 'Retoure'}
        </div>
        <div>
          Positionen: <span className="bold">{getArticleCount() ?? '-'}</span>
        </div>
      </div>
      <div>
        <div className="text-end">
          Gesamtgewicht: <span className="bold">{getTotalWeight() ?? '-'}</span>
        </div>
      </div>
    </div>
  );
}

export default withErrorBoundary(
  DeliveryNoteArticleSummary,
  'Gewicht und Anzahl der Artikel konnten nicht geladen werden.',
);
