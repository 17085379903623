import clsx from 'clsx';

import {
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { Spinner } from '~/components/Spinner';

import { useSupplierDetailModal } from './useSupplierDetailModal';

export const SupplierDetailModal = ({
  buyerCompany,
  isCreatingNewDataExchange,
  onClose,
  onSupplierChange,
  supplier,
}) => {
  const { activeRoute, classes, handleClose, logo, routes, setActiveRoute } =
    useSupplierDetailModal({
      buyerCompany,
      onClose,
      onSupplierChange,
      supplier,
    });

  return (
    <Dialog open onClose={handleClose} className={classes.container}>
      {isCreatingNewDataExchange ? (
        <div className={classes.loadingContainer}>
          <Spinner />
        </div>
      ) : (
        <Grid container direction="row" className="supplier_detail_container">
          <Grid container item component="nav" spacing={2}>
            {Object.entries(routes).map(([routeKey, route]) => (
              <button
                key={routeKey}
                className={clsx('nav-item flex-s-c', {
                  active: routeKey === activeRoute,
                })}
                onMouseDown={() => setActiveRoute(routeKey)}
              >
                {activeRoute === routeKey ? route.filledIcon : route.icon}
                <Typography>{route.title}</Typography>
              </button>
            ))}
            <Box className="logo-container">
              {logo && <img src={logo} alt="" />}
            </Box>
          </Grid>
          <Divider flexItem />
          <Grid item className="nav-content">
            <IconButton
              className="close-button"
              onClick={handleClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
            {routes[activeRoute]?.content}
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};
