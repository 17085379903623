import { useDispatch } from 'react-redux';

import UserService from '~/services/user.service';

import { saveProfilePicture } from '~/redux/userinfoSlice';

import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';

/**
 * Custom hook for loading the user's profile picture.
 *
 * This hook provides functionality to fetch the user's profile picture
 * from the backend using UserService and update the Redux store with
 * the retrieved data.
 *
 * @returns {Object} An object containing the loadProfilePicture function.
 */
export const useLoadProfilePicture = () => {
  const dispatch = useDispatch();

  /**
   * Loads the user's profile picture.
   *
   * This function attempts to fetch the profile picture using UserService.
   * If successful, it dispatches the saveProfilePicture action with the
   * retrieved data. If an error occurs, it logs the error and records
   * an analytics event.
   */
  async function loadProfilePicture() {
    const [response, error] = await promiseHandler(
      UserService.getProfilePicture(),
    );

    if (error) {
      Log.error('Failed to load profile picture.', error);
      Log.productAnalyticsEvent(
        'Failed to load user profile picture',
        Log.FEATURE.OTHER_FEATURE,
        Log.TYPE.ERROR,
      );

      return;
    }

    dispatch(saveProfilePicture(response));
  }

  return {
    loadProfilePicture,
  };
};
