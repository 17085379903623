import React, { useState, useEffect } from 'react';

import { InputLabel, MenuItem, Select, Button } from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import Log from '~/utils/Log';
import PermissionGrant, {
  ENTITY_TYPES,
  SUBJECT_TYPES,
} from '~/models/masterdata/PermissionGrant';
import Permissions from '~/models/masterdata/Permissions';
import PermissionGrantMultiPicker from '../../permissionGrant/PermissionGrantMultiPicker';
import { LightTooltip } from '~/utils/componentUtils';
import PermissionForm from '../../permissionGrant/PermissionForm';
import ToastService from '~/services/toast.service';
import PermissionGrantService from '~/services/permissionGrant.service';
import PromiseUtils from '~/utils/promiseUtils';
import { promiseHandler } from '~/utils/promiseHandler';
import UserService from '~/services/user.service';
import ArrayUtils from '~/utils/arrayUtils';
import SiteService from '~/services/site.service';

export const UpdatePermissionsWizardPermissionGrantPicker = withErrorBoundary(
  (props) => {
    const [pickedSubjectType, setPickedSubjectType] = useState(
      PermissionGrant.SUBJECT_TYPE.USER.KEY,
    );
    const [pickedSubjects, setPickedSubjects] = useState([]);
    const [pickedEntityType, setPickedEntityType] = useState(
      PermissionGrant.ENTITY_TYPE.SITE.KEY,
    );
    const [pickedEntities, setPickedEntities] = useState([]);
    const [permissionFormOpen, setPermissionFormOpen] = useState(false);
    const [permissions, setPermissions] = useState(new Permissions());

    useEffect(() => {
      initRole();
    }, [pickedSubjects]);

    // Automatically select a role as default based on the roles that are already assigned to the users whose permissions should be updated.
    const initRole = async () => {
      if (permissions.permissionGranted()) {
        // Don't overwrite if the user has already specified a set of permissions.
        return;
      }

      if (!pickedSubjects[0]) {
        // No need to search for the most frequent role if no subject is selected yet.
        return;
      }

      const [subject, error] = await promiseHandler(
        UserService.getUser(pickedSubjects[0], true),
      );

      const roles = subject.permissionGrantsOn.map((permissionGrant) =>
        permissionGrant.getDefaultRoleName(),
      );
      // Ignore Mitarbeiter as we assume that users don't want to assign Mitarbeiter role in the daily usage.
      const filteredRoles = roles.filter(
        (role) => role !== Permissions.DEFAULT_ROLE.EMPLOYEE.NAME,
      );

      const mostFrequentRole = ArrayUtils.getMostFrequentValue(filteredRoles);

      // Ignore Individuell as we assume that users don't want to assign individual permissions in the daily usage.
      if (mostFrequentRole === Permissions.INDIVIDUAL_ROLE) {
        return;
      }

      const newPermissions = new Permissions();
      newPermissions.initWithDefaultRole(mostFrequentRole);

      setPermissions(newPermissions);
    };

    const handleChangeSubjectType = (event) => {
      Log.info(
        'Change form value of subject type',
        { from: pickedSubjectType, to: event.target.value },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change subject type in update permissions wizard',
        Log.FEATURE.WIZARD,
      );

      setPickedSubjectType(event.target.value);
      setPickedSubjects([]);
    };

    const handleChangeSubjects = (event) => {
      const newPickedSubjects = event.map((item) => item.id);

      Log.info(
        'Change form value of subjects',
        { from: pickedSubjects, to: newPickedSubjects },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change subjects in update permissions wizard',
        Log.FEATURE.WIZARD,
      );

      setPickedSubjects(newPickedSubjects);

      // We want to prefill the user list in the deletion step with the user from the grating step.
      // Currently, commented out though because this seems to be a bit misleading.
      // this.props.setPickedSubjects(ArrayUtils.removeDuplicates([...this.props.pickedSubjects, ...newPickedSubjects]));
    };

    const handleChangeEntityType = (event) => {
      Log.info(
        'Change form value of entity type',
        { from: pickedEntityType, to: event.target.value },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Change entity type', Log.FEATURE.WIZARD);

      setPickedEntityType(event.target.value);
      setPickedEntities([]);
    };

    const handleChangeEntities = (event) => {
      const newPickedEntities = event.map((item) => item.id);

      Log.info(
        'Change form value of entities',
        { from: pickedEntities, to: newPickedEntities },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Change entities', Log.FEATURE.WIZARD);

      setPickedEntities(newPickedEntities);
    };

    const handleChangeRole = (event) => {
      Log.info(
        'Change form value of role',
        {
          from: permissions.getDefaultRoleName(),
          to: event.target.value,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change role (' + event.target.value + ') in update permissions wizard',
        Log.FEATURE.WIZARD,
      );

      const newPermissions = new Permissions();

      if (event.target.value === Permissions.INDIVIDUAL_ROLE) {
        setPermissionFormOpen(true);
        return;
      }

      newPermissions.initWithDefaultRole(event.target.value);

      setPermissions(newPermissions);
    };

    const openPermissionForm = () => {
      Log.productAnalyticsEvent(
        'Open permission form in update permissions wizard',
        Log.FEATURE.WIZARD,
      );
      setPermissionFormOpen(true);
    };

    const permissionFormSuccess = (newPermissions) => {
      setPermissionFormOpen(false);
      setPermissions(newPermissions);
    };

    const permissionFormAbort = () => {
      setPermissionFormOpen(false);
    };

    const submit = async (grantPermissionsOnCostCenters) => {
      const promises = [];

      if (!permissions.permissionGranted()) {
        return;
      }

      for (const subject of pickedSubjects) {
        for (const entity of pickedEntities) {
          const body = {
            permissions: permissions.getBackendPermissions(),
          };

          Log.info(
            'Submit permission grant form',
            body,
            Log.BREADCRUMB.FORM_SUBMIT.KEY,
          );

          const [isDuplicate, error] = await promiseHandler(
            PermissionGrantService.isDuplicatePermissionGrant(
              PermissionGrant.TYPE.SUBJECT,
              pickedSubjectType,
              subject,
              pickedEntityType,
              entity,
              permissions,
            ),
          );

          if (error) {
            Log.error('Failed to detect duplicate permission grant.', error);
            Log.productAnalyticsEvent(
              'Failed to detect duplicate permission grant',
              Log.FEATURE.PERMISSIONS,
              Log.TYPE.ERROR,
            );
          }

          if (isDuplicate) {
            continue;
          }

          const promise = PermissionGrantService.createNewPermissionGrant(
            pickedSubjectType,
            subject,
            pickedEntityType,
            entity,
            body,
          );

          promises.push(promise);
        }
      }

      if (
        grantPermissionsOnCostCenters &&
        pickedEntityType === PermissionGrant.ENTITY_TYPE.SITE.KEY
      ) {
        for (const pickedEntity of pickedEntities) {
          const [site, error] = await promiseHandler(
            SiteService.getSiteById(pickedEntity),
          );

          if (error) {
            Log.error('Failed to load cost center. id: ' + pickedEntity);
            ToastService.error([
              'Berechtigungen konnten nicht auf alle Kostenstellen vollständig vergeben werden.',
            ]);
            continue;
          }

          for (const subject of pickedSubjects) {
            for (const costCenterId of site.costCenters) {
              const body = {
                permissions: permissions.getBackendPermissions(),
              };

              Log.info(
                'Submit permission grant form',
                body,
                Log.BREADCRUMB.FORM_SUBMIT.KEY,
              );

              const [isDuplicate, error] = await promiseHandler(
                PermissionGrantService.isDuplicatePermissionGrant(
                  PermissionGrant.TYPE.SUBJECT,
                  pickedSubjectType,
                  subject,
                  PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY,
                  costCenterId,
                  permissions,
                ),
              );

              if (error) {
                Log.error(
                  'Failed to detect duplicate permission grant.',
                  error,
                );
                Log.productAnalyticsEvent(
                  'Failed to detect duplicate permission grant',
                  Log.FEATURE.PERMISSIONS,
                  Log.TYPE.ERROR,
                );
              }

              if (isDuplicate) {
                continue;
              }

              const promise = PermissionGrantService.createNewPermissionGrant(
                pickedSubjectType,
                subject,
                PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY,
                costCenterId,
                body,
              );

              promises.push(promise);
            }
          }
        }
      }

      return PromiseUtils.allResolved(promises);
    };

    return (
      <>
        <div className="flex-s-c gap-20px mt-20px">
          <div>
            <InputLabel className="text-13px">Berechtigung für</InputLabel>
            <Select
              value={pickedSubjectType}
              onChange={handleChangeSubjectType}
              className="w-300px"
              size="small"
              // If changing permissions for user groups should be possible in the wizard, the whole logic becomes a bit more complex.
              // Thus, we disable it for the moment. It should be possible in the future though.
              disabled
            >
              {Object.entries(SUBJECT_TYPES).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </div>
          <PermissionGrantMultiPicker
            type="permission_for"
            subjectType={pickedSubjectType}
            pickedIds={pickedSubjects}
            callbackPickedItems={handleChangeSubjects}
            fullWidth
          />
        </div>
        <div className="flex-s-c gap-20px mt-20px">
          <div>
            <InputLabel className="text-13px">Berechtigung auf</InputLabel>
            <Select
              value={pickedEntityType}
              key={0}
              onChange={handleChangeEntityType}
              className="w-300px"
              size="small"
            >
              {Object.entries(ENTITY_TYPES).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </div>
          <PermissionGrantMultiPicker
            type="permission_to"
            entityType={pickedEntityType}
            pickedIds={pickedEntities}
            callbackPickedItems={handleChangeEntities}
            fullWidth
            // Needed for the enhanced label
            subjectType={pickedSubjectType}
            subjects={pickedSubjects}
            displayPermissionGrantOfCostCentersWithSites={
              props.grantPermissionsOnCostCenters
            }
          />
        </div>
        <div className="flex-s-e gap-20px mt-20px">
          <div>
            <InputLabel className="text-13px">Berechtigung als</InputLabel>
            <Select
              value={permissions.getDefaultRoleName() ?? 'None'} // A random String has to be chosen as fallback value because otherwise renderValue wouldn't catch the case if(!this.state.permissions.permissionGranted())
              key={0}
              onChange={handleChangeRole}
              className="w-300px"
              size="small"
              renderValue={(id) => {
                if (!permissions.permissionGranted()) {
                  return (
                    <span className="text-mui-not-selected-grey">
                      Bitte Berechtigungen vergeben
                    </span>
                  );
                }

                return Permissions.getPickableRoles().find(
                  (option) => option.id === id,
                ).name;
              }}
            >
              {Permissions.getPickableRoles().map((role) => (
                <MenuItem value={role.id} key={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="mb-2px">
            <LightTooltip title="Detaillierte Berechtigungen einsehen">
              <Button
                variant="outlined"
                color="primary"
                onClick={openPermissionForm}
              >
                Details
              </Button>
            </LightTooltip>
          </div>
        </div>
        <PermissionForm
          open={permissionFormOpen}
          formSuccess={permissionFormSuccess}
          formAbort={permissionFormAbort}
          permissions={permissions}
        />
        <br />
        <br />
        <br />
        <br />
      </>
    );
  },
  'Daten konnten nicht geladen werden.',
);
