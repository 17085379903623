import 'chart.js/auto';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { connect } from 'react-redux';

import DashboardService from '~/services/dashboard.service';

import { saveArticleColorMapping } from '~/redux/userinfoSlice';

import UnitUtils from '~/utils/unitUtils';
import ColorUtils from '~/utils/colorUtils';

const mapStateToProps = (state) => ({
  articleColorMapping: state.userinfo.articleColorMapping,
  articleColorMappingVersion: state.userinfo.articleColorMappingVersion,
});
const mapDispatchToProps = () => ({
  saveArticleColorMapping,
});

class DashboardPieChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: null,
    };

    this.options = {
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label(tooltipItem, data) {
            const label = data.labels[tooltipItem.index];
            const value = data.datasets[0].data[tooltipItem.index];
            const formattedValue = UnitUtils.roundAndFormatDe_safe(value) ?? '';

            return [label, formattedValue].join(': ');
          },
        },
      },
      plugins: {
        legend: {
          align: 'start',
          display: false,
          labels: {
            filter(legendItem, data) {
              const rank = data.labels.indexOf(legendItem.text);

              return rank < 5;
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    this.init();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.isArchiveMode !== this.props.isArchiveMode) {
      return true;
    }

    const isNewColorMappingVersion =
      nextProps.articleColorMappingVersion !==
      this.props.articleColorMappingVersion;

    if (isNewColorMappingVersion) {
      return true;
    }

    const isNewData =
      JSON.stringify(nextState.chartData) !==
      JSON.stringify(this.state.chartData);

    if (isNewData) {
      return true;
    }

    const isNewArchiveVersion =
      nextProps.archiveAnalyticsDataVersion !==
      this.props.archiveAnalyticsDataVersion;

    if (nextProps.isArchiveMode) {
      return isNewArchiveVersion;
    }

    const isNewDataVersion = nextProps.dataVersion !== this.props.dataVersion;

    return isNewDataVersion;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let newChartData = cloneDeep(this.state.chartData);
    let chartDataUpdated = false;

    // When the data has changed, we need to update the chart.
    // When the data hasn't changed but the archive mode has, we need to update the chart as well.
    // This case can happen when the user switches between modes while data is already cached.
    if (
      this.props.dataVersion !== prevProps.dataVersion ||
      !this.props.isArchiveMode
    ) {
      newChartData = DashboardService.createPieChartDatasets(this.props.data);
      chartDataUpdated = true;
    } else if (
      // The case for archive data is analogous as described above.
      this.props.archiveAnalyticsDataVersion !==
        prevProps.archiveAnalyticsDataVersion ||
      this.props.isArchiveMode
    ) {
      newChartData = DashboardService.createPieChartDatasetsFromAnalyticsData(
        this.props.archiveAnalyticsData,
      );
      chartDataUpdated = true;
    }

    if (
      chartDataUpdated ||
      this.props.articleColorMappingVersion !==
        prevProps.articleColorMappingVersion
    ) {
      this.assignColors(newChartData);
    }

    this.setState({
      chartData: newChartData,
    });

    if (this.props.onChartDataChange) {
      this.props.onChartDataChange(newChartData);
    }
  }

  init() {
    let newChartData = [];

    newChartData = this.props.isArchiveMode
      ? DashboardService.createPieChartDatasetsFromAnalyticsData(
          this.props.archiveAnalyticsData,
        )
      : DashboardService.createPieChartDatasets(this.props.data);

    this.assignColors(newChartData);

    this.setState({
      chartData: newChartData,
    });
  }

  assignColors(chartData) {
    const newArticleColorMapping = [...this.props.articleColorMapping];

    for (const [index, article] of chartData.labels.entries()) {
      let color = newArticleColorMapping.find(
        (mapping) => mapping.article === article,
      )?.color;

      if (!color) {
        color = ColorUtils.getRandomDashboardColor();

        newArticleColorMapping.push({
          article,
          color,
        });
      }

      chartData.datasets[0].backgroundColor[index] = color;
    }

    if (newArticleColorMapping.length > this.props.articleColorMapping.length) {
      this.props.saveArticleColorMapping({
        mapping: newArticleColorMapping,
        saveToBackend: true,
      });
    }
  }

  render() {
    if (!this.state.chartData) {
      return null;
    }

    if (this.props.displayLegend) {
      this.options.legend.display = true;
    }

    return (
      <Doughnut data={cloneDeep(this.state.chartData)} options={this.options} />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps(),
)(DashboardPieChart);
