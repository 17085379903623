import React from 'react';

import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Grid,
  TextField,
} from '@mui/material';

import { connect } from 'react-redux';
import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';

import BasicForm from '~/components/BasicForm';
import cloneDeep from 'lodash/cloneDeep';
import ToastService from '~/services/toast.service';

import FunctionUtils from '~/utils/functionUtils';
import Select from '~/components/baseComponents/inputs/select/Select';

import SignatureField from '~/models/signatureField/SignatureField';
import SignatureFieldService from '~/services/signatureField.service';
import SearchableSelect from '~/components/baseComponents/inputs/select/SearchableSelect';
import { LOADING_STATE } from '~/constants/LoadingState';
import { SiteNameAddressPair } from '~/components/SiteNameAddressPair';
import MapperService from '~/services/mapper.service';
import ArrayUtils from '~/utils/arrayUtils';
import ObjectUtils from '~/utils/objectUtils';
import CustomField from '~/models/customData/CustomField';
import DatePicker from '~/components/baseComponents/inputs/date/DatePicker';
import { dateUtils } from '~/utils/dateUtils';

import CustomFieldService from '~/services/customField.service';
import Article from '~/models/articles/Article';

const mapStateToProps = (state) => ({
  customFields: state.customFields,
  companies: state.companies,
  sites: state.sites,
  costCenters: state.costCenters,
});
const mapDispatchToProps = () => ({});

class SignatureFieldForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      signatureField: new SignatureField(),
      submittingForm: false,
      deletingForm: false,
      sortedCompanies: [],
      sortedSites: [],
      sortedCostCenters: [],
      customFieldOptions: [],
    };
  }

  componentDidMount() {
    this.resetForm();
    this.initCompanies();
    this.initSites();
    this.initCostCenters();
    this.initCustomFieldOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.signatureField) !==
      JSON.stringify(this.props.signatureField)
    ) {
      this.resetForm();
    }

    if (
      this.props.companies.companiesLoading === LOADING_STATE.SUCCEEDED &&
      prevProps.companies.companiesLoading !== LOADING_STATE.SUCCEEDED
    ) {
      this.initCompanies();
    }

    if (
      this.props.sites.sitesLoading === LOADING_STATE.SUCCEEDED &&
      prevProps.sites.sitesLoading !== LOADING_STATE.SUCCEEDED
    ) {
      this.initSites();
    }

    if (
      this.props.costCenters.costCentersLoading === LOADING_STATE.SUCCEEDED &&
      prevProps.costCenters.costCentersLoading !== LOADING_STATE.SUCCEEDED
    ) {
      this.initCostCenters();
    }

    // Actually, we could also include customFieldOptionsLoading, signatureField?.pathKey and signatureField?.defaultMode into determining whether to reload the custom field options
    // but this would make the logic more complex and error-prone.
    if (
      this.state.signatureField?.customFieldId !==
      prevState.signatureField?.customFieldId
    ) {
      this.initCustomFieldOptions();
    }

    if (
      this.state.signatureField?.defaultMode !==
        prevState.signatureField?.defaultMode ||
      this.state.signatureField?.pathKey !==
        prevState.signatureField?.pathKey ||
      this.state.signatureField?.customFieldId !==
        prevState.signatureField?.customFieldId
    ) {
      this.resetDefaultValue();
    }
  }

  async resetForm() {
    this.setState({
      signatureField: this.props.signatureField ?? new SignatureField(),
    });
  }

  initCompanies() {
    const companies = this.props.companies.companies.map((company) => {
      const newCompany = {
        id: company.id,
        name: company.name,
      };

      return MapperService.addSearchStringWithValues(newCompany, [
        company.name,
      ]);
    });

    const sortedCompanies = ArrayUtils.sortByKey(companies, 'name');

    this.setState({
      sortedCompanies,
    });
  }

  initSites() {
    const sites = this.props.sites.sites.map((site) => {
      const newSite = {
        id: site.id,
        active: site.active,
        name: site.name,
        nameComponent: <SiteNameAddressPair site={site} />,
      };

      return MapperService.addSearchStringWithValues(newSite, [
        site.name,
        site.address.getConcatenatedAddress(),
      ]);
    });

    const sortedSites = ArrayUtils.sortByKey(sites, 'name').filter(
      (site) => site.active,
    );

    this.setState({
      sortedSites,
    });
  }

  initCostCenters() {
    const costCenters = this.props.costCenters.costCenters.map((costCenter) => {
      const newCostCenter = {
        id: costCenter.id,
        active: costCenter.active,
        name: costCenter.name,
      };

      return MapperService.addSearchStringWithValues(newCostCenter, [
        costCenter.name,
      ]);
    });

    const sortedCostCenters = ArrayUtils.sortByKey(costCenters, 'name').filter(
      (costCenter) => costCenter.active,
    );

    this.setState({
      sortedCostCenters,
    });
  }

  async initCustomFieldOptions() {
    let customFieldId = null;

    if (this.props.signatureField?.customFieldId) {
      customFieldId = this.props.signatureField?.customFieldId;
    }

    if (this.state.signatureField?.customFieldId) {
      customFieldId = this.state.signatureField?.customFieldId;
    }

    if (!customFieldId) {
      return;
    }

    this.setState({
      customFieldOptionsLoading: LOADING_STATE.LOADING,
    });

    const [customFieldOptions, error] = await promiseHandler(
      CustomFieldService.getCustomFieldOptions(customFieldId, true),
    );

    if (error) {
      Log.error(
        'Failed to load custom field options. custom field id: ' +
          customFieldId,
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to load custom field options',
        Log.FEATURE.CUSTOM_FIELD,
        Log.TYPE.ERROR,
      );
      this.setState({
        customFieldOptionsLoading: LOADING_STATE.FAILED,
      });
      return;
    }

    this.setState({
      customFieldOptions: customFieldOptions.map((customFieldOption) => {
        return {
          id: customFieldOption.id,
          name: customFieldOption.displayName,
        };
      }),
      customFieldOptionsLoading: LOADING_STATE.SUCCEEDED,
    });
  }

  resetDefaultValue() {
    const newSignatureField = cloneDeep(this.state.signatureField);

    if (
      this.state.signatureField?.defaultMode !==
      SignatureField.DEFAULT_MODE.FIXED.KEY
    ) {
      newSignatureField.defaultValue = null;
      this.setState({
        signatureField: newSignatureField,
      });
      return;
    }

    // If the original path or custom field is selected, the original default value should be set again.
    // This logic is necessary because otherwise the default value would always be reset and never be displayed with the original one.
    if (
      // The original path has been selected AND
      this.props.signatureField?.pathKey ===
        this.state.signatureField?.pathKey &&
      // The path is a global custom field and the original global custom field has been selected OR
      ((this.props.signatureField?.pathKey ===
        SignatureField.PATH.CUSTOM_FIELD_GLOBAL.KEY &&
        this.props.signatureField?.customFieldId ===
          this.state.signatureField?.customFieldId) ||
        // The path is a local custom field and the original local custom field has been selected OR
        (this.props.signatureField?.pathKey ===
          SignatureField.PATH.CUSTOM_FIELD_LOCAL.KEY &&
          this.props.signatureField?.customFieldId ===
            this.state.signatureField?.customFieldId) ||
        // The path is not a custom field
        (this.props.signatureField?.pathKey !==
          SignatureField.PATH.CUSTOM_FIELD_GLOBAL.KEY &&
          this.props.signatureField?.pathKey !==
            SignatureField.PATH.CUSTOM_FIELD_LOCAL.KEY))
    ) {
      newSignatureField.defaultValue = this.props.signatureField?.defaultValue;
      this.setState({
        signatureField: newSignatureField,
      });
      return;
    }

    const type = Object.values(SignatureField.PATH).find(
      (value) => value.KEY === this.state.signatureField?.pathKey,
    )?.TYPE;
    const customFieldType = this.props.customFields.customFields.find(
      (customField) =>
        customField.id === this.state.signatureField?.customFieldId,
    )?.type;

    if (
      type === SignatureField.TYPE.CUSTOM_FIELD.KEY &&
      customFieldType === CustomField.TYPE.STRING.KEY
    ) {
      newSignatureField.defaultValue = '';
    }

    if (
      type === SignatureField.TYPE.CUSTOM_FIELD.KEY &&
      customFieldType === CustomField.TYPE.DOUBLE.KEY
    ) {
      newSignatureField.defaultValue = 0;
    }

    if (
      type === SignatureField.TYPE.DATETIME.KEY ||
      (type === SignatureField.TYPE.CUSTOM_FIELD.KEY &&
        customFieldType === CustomField.TYPE.DATETIME.KEY)
    ) {
      newSignatureField.defaultValue = new Date();
    }

    if (
      type === SignatureField.TYPE.CUSTOM_FIELD.KEY &&
      customFieldType === CustomField.TYPE.BOOLEAN.KEY
    ) {
      newSignatureField.defaultValue = true;
    }

    if (
      type === SignatureField.TYPE.CUSTOM_FIELD.KEY &&
      customFieldType === CustomField.TYPE.ENUMERATION.KEY
    ) {
      newSignatureField.defaultValue = '';
    }

    if (
      type === SignatureField.TYPE.LEGAL_ORGANIZATION.KEY ||
      (type === SignatureField.TYPE.CUSTOM_FIELD.KEY &&
        customFieldType === CustomField.TYPE.LEGAL_ORGANIZATION.KEY)
    ) {
      newSignatureField.defaultValue = {};
    }

    if (
      type === SignatureField.TYPE.CUSTOM_FIELD.KEY &&
      customFieldType === CustomField.TYPE.VALUE_MEASURE.KEY
    ) {
      newSignatureField.defaultValue = { value: 0, measure: null };
    }

    if (
      type === SignatureField.TYPE.CUSTOM_FIELD.KEY &&
      customFieldType === CustomField.TYPE.DICT.KEY
    ) {
      newSignatureField.defaultValue = null;
    }

    this.setState({
      signatureField: newSignatureField,
    });
  }

  formSuccess = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      submittingForm: true,
    });

    const body = {
      name: this.state.signatureField.name,
      display_name: this.state.signatureField.displayName,
      path: SignatureField.getPathFromKeyAndCustomFields(
        this.state.signatureField.pathKey,
        this.state.signatureField.customFieldId,
        this.props.customFields.customFields,
      ),
      required: this.state.signatureField.required,
      role: this.state.signatureField.role,
      company_id: this.state.signatureField.companyId,
      site_id: this.state.signatureField.siteId,
      accounting_reference_id: this.state.signatureField.costCenterId,
      default_mode: this.state.signatureField.defaultMode,
      default_value: this.state.signatureField.defaultValue ?? '',
    };

    Log.info(
      'Submit signature field form',
      body,
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.SIGNATURE_FIELD);

    if (this.renderForCreate()) {
      const [signatureFieldId, error] = await promiseHandler(
        SignatureFieldService.createSignatureField(body),
      );

      if (error) {
        Log.error('Failed to create signature field.', error);
        ToastService.httpError(
          ['Signaturfeld konnte nicht angelegt werden.'],
          error.response,
        );
        Log.productAnalyticsEvent(
          'Failed to create',
          Log.FEATURE.SIGNATURE_FIELD,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }
    } else {
      const [response, error] = await promiseHandler(
        SignatureFieldService.updateSignatureField(
          this.props.signatureField.id,
          body,
        ),
      );

      if (error) {
        Log.error(
          'Failed to update signature field. id: ' +
            this.props.signatureField.id,
          error,
        );
        ToastService.httpError(
          ['Signaturfeld konnte nicht aktualisiert werden.'],
          error.response,
        );
        Log.productAnalyticsEvent(
          'Failed to update',
          Log.FEATURE.SIGNATURE_FIELD,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }
    }

    this.setState({
      submittingForm: false,
    });

    this.props.closeForm();
    this.resetForm();
    SignatureFieldService.refreshSignatureFields();
  };
  formAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.SIGNATURE_FIELD);
    this.props.closeForm();
    this.resetForm();
  };
  formDelete = async (event) => {
    event.preventDefault();

    Log.info(
      'Delete signature field',
      { id: this.props.signatureField.id },
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Delete', Log.FEATURE.SIGNATURE_FIELD);

    this.setState({
      deletingForm: true,
    });

    const [response, error] = await promiseHandler(
      SignatureFieldService.deleteSignatureField(this.props.signatureField.id),
    );

    if (error) {
      ToastService.httpError(
        ['Signaturfeld konnte nicht gelöscht werden.'],
        error.response,
      );
      Log.error('Failed to delete signature field.', error);
      Log.productAnalyticsEvent(
        'Failed to delete',
        Log.FEATURE.SIGNATURE_FIELD,
        Log.TYPE.ERROR,
      );
      this.setState({
        deletingForm: false,
      });
      return;
    }

    this.setState({
      deletingForm: false,
    });

    this.props.closeForm();
    this.resetForm();
    await SignatureFieldService.refreshSignatureFields();
  };
  renderForCreate = () => {
    return this.props.type === 'create';
  };
  handleInputChange = (event) => {
    const newSignatureField = cloneDeep(this.state.signatureField);

    switch (event.target.name) {
      case 'name': {
        newSignatureField.name = event.target.value;
        Log.info(
          'Change form value of name',
          { from: this.state.signatureField.name, to: newSignatureField.name },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'signature_field_change_name',
          Log.productAnalyticsEvent,
          ['Change name', Log.FEATURE.SIGNATURE_FIELD],
        );
        break;
      }

      case 'displayName': {
        newSignatureField.displayName = event.target.value;
        Log.info(
          'Change form value of display name',
          {
            from: this.state.signatureField.displayName,
            to: newSignatureField.displayName,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'signature_field_change_display_name',
          Log.productAnalyticsEvent,
          ['Change display name', Log.FEATURE.SIGNATURE_FIELD],
        );
        break;
      }
    }

    this.setState({
      signatureField: newSignatureField,
    });
  };
  handleChangeSignatureFieldRole = (event) => {
    const newSignatureField = cloneDeep(this.state.signatureField);

    newSignatureField.role = event.target.value;

    Log.info(
      'Change form value of role',
      { from: this.state.signatureField.role, to: newSignatureField.role },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change type', Log.FEATURE.SIGNATURE_FIELD);

    this.setState({
      signatureField: newSignatureField,
    });
  };
  handleCheckboxChange = (event) => {
    const newSignatureField = cloneDeep(this.state.signatureField);

    newSignatureField.required = event.target.checked;

    Log.info(
      'Change form value of required',
      {
        from: this.state.signatureField.required,
        to: newSignatureField.required,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change required checkbox',
      Log.FEATURE.SIGNATURE_FIELD,
    );

    this.setState({
      signatureField: newSignatureField,
    });
  };
  handleChangePathKey = (event) => {
    const newSignatureField = cloneDeep(this.state.signatureField);

    newSignatureField.pathKey = event.target.value;

    const changePathKey = this.setDefaultCustomField(newSignatureField);

    if (!changePathKey) {
      return;
    }

    Log.info(
      'Change form value of path key',
      {
        from: this.state.signatureField.pathKey,
        to: newSignatureField.pathKey,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change path key', Log.FEATURE.SIGNATURE_FIELD);

    this.setState({
      signatureField: newSignatureField,
    });
  };

  // Changes newSignatureField by object reference.
  // Returns false if the path key should not be changed because there are no custom fields selectable.
  setDefaultCustomField(newSignatureField) {
    if (
      newSignatureField.pathKey !==
        SignatureField.PATH.CUSTOM_FIELD_GLOBAL.KEY &&
      newSignatureField.pathKey !== SignatureField.PATH.CUSTOM_FIELD_LOCAL.KEY
    ) {
      return true;
    }

    const customFields = this.props.customFields.customFields.filter(
      (customField) => {
        if (
          newSignatureField.pathKey ===
          SignatureField.PATH.CUSTOM_FIELD_GLOBAL.KEY
        ) {
          return customField.level === CustomField.LEVEL.DELIVERY_NOTE.KEY;
        }

        if (
          newSignatureField.pathKey ===
          SignatureField.PATH.CUSTOM_FIELD_LOCAL.KEY
        ) {
          return customField.level === CustomField.LEVEL.ARTICLE.KEY;
        }

        return false;
      },
    );

    const customField = customFields.find(
      (customField) =>
        customField.id === this.state.signatureField.customFieldId,
    );

    // Set default custom field
    if (!customField) {
      const defaultCustomField = customFields[0];

      if (!defaultCustomField) {
        ToastService.info(['Bitte lege zunächst ein flexibles Feld an.']);
        return false;
      }

      newSignatureField.customFieldId = defaultCustomField.id;
    }

    return true;
  }

  handleChangeCustomFieldId = (event) => {
    const newSignatureField = cloneDeep(this.state.signatureField);

    newSignatureField.customFieldId = event.target.value;

    Log.info(
      'Change form value of custom field id',
      {
        from: this.state.signatureField.customFieldId,
        to: newSignatureField.customFieldId,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change custom field id',
      Log.FEATURE.SIGNATURE_FIELD,
    );

    this.setState({
      signatureField: newSignatureField,
    });
  };
  handleChangeCompanyId = (event) => {
    const newSignatureField = cloneDeep(this.state.signatureField);

    newSignatureField.companyId = event.target.value;

    Log.info(
      'Change form value of company id',
      {
        from: this.state.signatureField.companyId,
        to: newSignatureField.companyId,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change company id', Log.FEATURE.SIGNATURE_FIELD);

    this.setState({
      signatureField: newSignatureField,
    });
  };
  handleChangeSiteId = (event) => {
    const newSignatureField = cloneDeep(this.state.signatureField);

    newSignatureField.siteId = event.target.value;

    Log.info(
      'Change form value of site id',
      { from: this.state.signatureField.siteId, to: newSignatureField.siteId },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change site id', Log.FEATURE.SIGNATURE_FIELD);

    this.setState({
      signatureField: newSignatureField,
    });
  };
  handleChangeCostCenterId = (event) => {
    const newSignatureField = cloneDeep(this.state.signatureField);

    newSignatureField.costCenterId = event.target.value;

    Log.info(
      'Change form value of cost center id',
      {
        from: this.state.signatureField.costCenterId,
        to: newSignatureField.costCenterId,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change cost center id',
      Log.FEATURE.SIGNATURE_FIELD,
    );

    this.setState({
      signatureField: newSignatureField,
    });
  };
  handleChangeDefaultMode = (event) => {
    const newSignatureField = cloneDeep(this.state.signatureField);

    newSignatureField.defaultMode = event.target.value;

    Log.info(
      'Change form value of default mode',
      {
        from: this.state.signatureField.defaultMode,
        to: newSignatureField.defaultMode,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change default mode',
      Log.FEATURE.SIGNATURE_FIELD,
    );

    this.setState({
      signatureField: newSignatureField,
    });
  };
  handleChangeDefaultValueString = (event) => {
    const newSignatureField = cloneDeep(this.state.signatureField);

    newSignatureField.defaultValue = event.target.value;

    Log.info(
      'Change form value of default value',
      {
        from: this.state.signatureField.defaultValue,
        to: newSignatureField.defaultValue,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change default value',
      Log.FEATURE.SIGNATURE_FIELD,
    );

    this.setState({
      signatureField: newSignatureField,
    });
  };
  handleChangeDefaultValueDouble = (event) => {
    const newSignatureField = cloneDeep(this.state.signatureField);

    newSignatureField.defaultValue = Number(event.target.value);

    Log.info(
      'Change form value of default value',
      {
        from: this.state.signatureField.defaultValue,
        to: newSignatureField.defaultValue,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change default value',
      Log.FEATURE.SIGNATURE_FIELD,
    );

    this.setState({
      signatureField: newSignatureField,
    });
  };
  handleChangeDefaultValueDate = (event) => {
    const newSignatureField = cloneDeep(this.state.signatureField);
    newSignatureField.defaultValue = new Date(newSignatureField.defaultValue);

    if (newSignatureField.defaultValue) {
      newSignatureField.defaultValue.setFullYear(event.getFullYear());
      newSignatureField.defaultValue.setMonth(event.getMonth());
      newSignatureField.defaultValue.setDate(event.getDate());
    } else {
      newSignatureField.defaultValue = event;
    }

    Log.info(
      'Change form value of default value',
      {
        from: this.state.signatureField.defaultValue,
        to: newSignatureField.defaultValue,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change default value',
      Log.FEATURE.SIGNATURE_FIELD,
    );

    this.setState({
      signatureField: newSignatureField,
    });
  };
  handleChangeDefaultValueHours = (event) => {
    if (
      !/\d$/.test(event.target.value) &&
      !/[0-5]\d$/.test(event.target.value)
    ) {
      return;
    }

    const newSignatureField = cloneDeep(this.state.signatureField);
    newSignatureField.defaultValue = new Date(newSignatureField.defaultValue);

    newSignatureField.defaultValue.setHours(event.target.value);

    Log.info(
      'Change form value of default value',
      {
        from: this.state.signatureField.defaultValue,
        to: newSignatureField.defaultValue,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change default value',
      Log.FEATURE.SIGNATURE_FIELD,
    );

    this.setState({
      signatureField: newSignatureField,
    });
  };
  handleChangeDefaultValueMinutes = (event) => {
    if (
      !/\d$/.test(event.target.value) &&
      !/[0-5]\d$/.test(event.target.value)
    ) {
      return;
    }

    const newSignatureField = cloneDeep(this.state.signatureField);
    newSignatureField.defaultValue = new Date(newSignatureField.defaultValue);

    newSignatureField.defaultValue.setMinutes(event.target.value);

    Log.info(
      'Change form value of default value',
      {
        from: this.state.signatureField.defaultValue,
        to: newSignatureField.defaultValue,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change default value',
      Log.FEATURE.SIGNATURE_FIELD,
    );

    this.setState({
      signatureField: newSignatureField,
    });
  };
  handleChangeDefaultValueBoolean = (event) => {
    const newSignatureField = cloneDeep(this.state.signatureField);

    newSignatureField.defaultValue = event.target.value === 'defaultValue_yes';

    Log.info(
      'Change form value of default value',
      {
        from: this.state.signatureField.defaultValue,
        to: newSignatureField.defaultValue,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change default value',
      Log.FEATURE.SIGNATURE_FIELD,
    );

    this.setState({
      signatureField: newSignatureField,
    });
  };
  handleChangeDefaultValueEnumeration = (event) => {
    const newSignatureField = cloneDeep(this.state.signatureField);

    newSignatureField.defaultValue = event.target.value;

    Log.info(
      'Change form value of default value',
      {
        from: this.state.signatureField.defaultValue,
        to: newSignatureField.defaultValue,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change default value',
      Log.FEATURE.SIGNATURE_FIELD,
    );

    this.setState({
      signatureField: newSignatureField,
    });
  };
  handleChangeDefaultValueLegalOrganization = (event) => {
    const newSignatureField = cloneDeep(this.state.signatureField);

    const company = this.props.companies.companies.find(
      (company) => company.id === event.target.value,
    );

    if (company) {
      newSignatureField.defaultValue =
        company.getBackendFormat().legal_organization;
    } else {
      newSignatureField.defaultValue = null;
    }

    Log.info(
      'Change form value of default value',
      {
        from: this.state.signatureField.defaultValue,
        to: newSignatureField.defaultValue,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change default value',
      Log.FEATURE.SIGNATURE_FIELD,
    );

    this.setState({
      signatureField: newSignatureField,
    });
  };
  handleChangeDefaultValueValue = (event) => {
    const newSignatureField = cloneDeep(this.state.signatureField);

    // In the SignatureField model, defaultValue is initialized with null. Thus, after initialization it must be set to the default object.
    newSignatureField.defaultValue ||= { value: 0, measure: null };

    newSignatureField.defaultValue.value = Number(event.target.value);

    Log.info(
      'Change form value of default value',
      {
        from: this.state.signatureField.defaultValue,
        to: newSignatureField.defaultValue,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change default value',
      Log.FEATURE.SIGNATURE_FIELD,
    );

    this.setState({
      signatureField: newSignatureField,
    });
  };
  handleChangeDefaultValueMeasure = (event) => {
    const newSignatureField = cloneDeep(this.state.signatureField);

    // In the SignatureField model, defaultValue is initialized with null. Thus, after initialization it must be set to the default object.
    newSignatureField.defaultValue ||= { value: 0, measure: null };

    newSignatureField.defaultValue.measure = event.target.value;

    Log.info(
      'Change form value of default value',
      {
        from: this.state.signatureField.defaultValue,
        to: newSignatureField.defaultValue,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change default value',
      Log.FEATURE.SIGNATURE_FIELD,
    );

    this.setState({
      signatureField: newSignatureField,
    });
  };

  getUnsavedChanges() {
    if (this.renderForCreate()) {
      return [];
    }

    return SignatureField.getDifferentValues(
      this.props.signatureField,
      this.state.signatureField,
    );
  }

  getPathOptions() {
    return ObjectUtils.entries(SignatureField.PATH).map((entry) => {
      return {
        id: entry.value.KEY,
        name: entry.value.STRING,
        disabled: entry.value.DISABLED,
      };
    });
  }

  getFixedDefaultValueInput() {
    const type = Object.values(SignatureField.PATH).find(
      (value) => value.KEY === this.state.signatureField?.pathKey,
    )?.TYPE;
    const customField = this.props.customFields.customFields.find(
      (customField) =>
        customField.id === this.state.signatureField?.customFieldId,
    );

    if (
      type === SignatureField.TYPE.CUSTOM_FIELD.KEY &&
      customField?.type === CustomField.TYPE.STRING.KEY
    ) {
      return (
        <TextField
          id="defaultValue-input"
          name="name"
          placeholder="Bitte Default String eingeben"
          type="text"
          fullWidth
          value={this.state.signatureField?.defaultValue}
          onChange={this.handleChangeDefaultValueString}
          autoComplete="off"
        />
      );
    }

    if (
      type === SignatureField.TYPE.CUSTOM_FIELD.KEY &&
      customField?.type === CustomField.TYPE.DOUBLE.KEY
    ) {
      return (
        <TextField
          id="defaultValue-input"
          name="name"
          placeholder="Bitte Default Double eingeben"
          type="number"
          fullWidth
          value={this.state.signatureField?.defaultValue}
          onChange={this.handleChangeDefaultValueDouble}
          autoComplete="off"
        />
      );
    }

    if (
      type === SignatureField.TYPE.DATETIME.KEY ||
      (type === SignatureField.TYPE.CUSTOM_FIELD.KEY &&
        customField?.type === CustomField.TYPE.DATETIME.KEY)
    ) {
      return (
        <div className="flex-s-c gap-10px">
          <DatePicker
            value={this.state.signatureField?.defaultValue}
            onChange={this.handleChangeDefaultValueDate}
          />
          <div className="flex-s-c gap-5px">
            <TextField
              value={dateUtils.getFormattedDate_safe(
                this.state.signatureField?.defaultValue,
                dateUtils.DATE_FORMAT.HH,
              )}
              onChange={this.handleChangeDefaultValueHours}
              placeholder="00"
              autoComplete="off"
              className="w-46px"
            />
            :
            <TextField
              value={dateUtils.getFormattedDate_safe(
                this.state.signatureField?.defaultValue,
                dateUtils.DATE_FORMAT.mm,
              )}
              onChange={this.handleChangeDefaultValueMinutes}
              placeholder="00"
              autoComplete="off"
              className="w-46px"
            />
          </div>
        </div>
      );
    }

    if (
      type === SignatureField.TYPE.CUSTOM_FIELD.KEY &&
      customField?.type === CustomField.TYPE.BOOLEAN.KEY
    ) {
      return (
        <RadioGroup
          onChange={this.handleChangeDefaultValueBoolean}
          value={
            this.state.signatureField?.defaultValue
              ? 'defaultValue_yes'
              : 'defaultValue_no'
          }
          row
        >
          <FormControlLabel
            value="defaultValue_yes"
            control={<Radio />}
            label="Ja"
          />
          <FormControlLabel
            value="defaultValue_no"
            control={<Radio />}
            label="Nein"
            className="mr-50px"
          />
        </RadioGroup>
      );
    }

    if (
      type === SignatureField.TYPE.CUSTOM_FIELD.KEY &&
      customField?.type === CustomField.TYPE.ENUMERATION.KEY
    ) {
      return (
        <Select
          value={this.state.signatureField?.defaultValue}
          fullWidth
          onChange={this.handleChangeDefaultValueEnumeration}
          size="small"
          options={this.state.customFieldOptions}
          loading={this.state.customFieldOptionsLoading}
          errorText="Auswahl konnte nicht geladen werden."
        />
      );
    }

    if (
      type === SignatureField.TYPE.LEGAL_ORGANIZATION.KEY ||
      (type === SignatureField.TYPE.CUSTOM_FIELD.KEY &&
        customField?.type === CustomField.TYPE.LEGAL_ORGANIZATION.KEY)
    ) {
      return (
        <Select
          value={this.state.signatureField?.defaultValue?.id}
          fullWidth
          onChange={this.handleChangeDefaultValueLegalOrganization}
          size="small"
          options={this.props.companies.companies}
          loading={this.props.companies.companiesLoading}
          errorText="Firmen konnten nicht geladen werden."
          sortOptions
          sortOptionsByKey="name"
        />
      );
    }

    if (
      type === SignatureField.TYPE.CUSTOM_FIELD.KEY &&
      customField?.type === CustomField.TYPE.VALUE_MEASURE.KEY
    ) {
      const allowedUnits = Object.values(Article.UNIT)
        .map((value) => {
          return {
            id: value.STANDARDISED,
            name: value.ABBREVIATED_STRING ?? value.ABBREVIATED,
          };
        })
        .filter((article) => customField?.allowedUnits?.includes(article.id));

      return (
        <div className="flex-s-c gap-10px">
          <TextField
            id="defaultValue-input"
            name="name"
            placeholder="Bitte Default Double eingeben"
            type="number"
            fullWidth
            value={this.state.signatureField?.defaultValue?.value}
            onChange={this.handleChangeDefaultValueValue}
            autoComplete="off"
          />
          <Select
            value={this.state.signatureField?.defaultValue?.measure}
            fullWidth
            onChange={this.handleChangeDefaultValueMeasure}
            size="small"
            options={allowedUnits}
            withEmptyOption
          />
        </div>
      );
    }

    if (
      type === SignatureField.TYPE.CUSTOM_FIELD.KEY &&
      customField?.type === CustomField.TYPE.DICT.KEY
    ) {
      return (
        <div className="flex-s-c h-full">
          Default Werte werden für den Typ "Dict" derzeit noch nicht
          unterstützt.
        </div>
      );
    }
  }

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        formDelete={this.renderForCreate() ? null : this.formDelete}
        title={
          'Signaturfeld ' +
          (this.renderForCreate()
            ? 'Erstellen'
            : this.props.signatureField.name)
        }
        fullWidth
        submittingForm={this.state.submittingForm}
        deletingForm={this.state.deletingForm}
        unsavedChanges={this.getUnsavedChanges()}
        id={this.props.signatureField?.id}
      >
        <Grid container direction="row" spacing={3} space={4}>
          <Grid item xs={12} lg={12}>
            <h3 className="main-text mt-0">Signaturfeld</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="name-input"
                  name="name"
                  label="Name"
                  type="text"
                  disabled={!this.renderForCreate()}
                  required
                  fullWidth
                  value={this.state.signatureField?.name}
                  onChange={this.handleInputChange}
                  autoFocus={this.renderForCreate()}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="display-name-input"
                  name="displayName"
                  label="Anzeige-Name"
                  type="text"
                  required
                  fullWidth
                  value={this.state.signatureField?.displayName}
                  onChange={this.handleInputChange}
                  autoFocus={!this.renderForCreate()}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <InputLabel className="text-13px">Rolle</InputLabel>
                <Select
                  value={this.state.signatureField?.role}
                  fullWidth
                  onChange={this.handleChangeSignatureFieldRole}
                  size="small"
                  options={SignatureField.getSignatureFieldRoles()}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <FormControlLabel
                  className="mt-17px"
                  label="Verpflichtend"
                  control={
                    <Checkbox
                      checked={this.state.signatureField?.required}
                      onChange={this.handleCheckboxChange}
                      name="required"
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">Zugriff auf</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <InputLabel className="text-13px">Zugriff auf</InputLabel>
                <Select
                  value={this.state.signatureField?.pathKey}
                  fullWidth
                  onChange={this.handleChangePathKey}
                  size="small"
                  options={this.getPathOptions()}
                />
              </Grid>
              {this.state.signatureField?.pathKey ===
                SignatureField.PATH.CUSTOM_FIELD_GLOBAL.KEY ||
              this.state.signatureField?.pathKey ===
                SignatureField.PATH.CUSTOM_FIELD_LOCAL.KEY ? (
                <Grid item xs={6} lg={4}>
                  <InputLabel className="text-13px">Flexibles Feld</InputLabel>
                  <Select
                    value={this.state.signatureField?.customFieldId}
                    fullWidth
                    onChange={this.handleChangeCustomFieldId}
                    size="small"
                    options={this.props.customFields.customFields.filter(
                      (customField) => {
                        if (
                          this.state.signatureField?.pathKey ===
                          SignatureField.PATH.CUSTOM_FIELD_GLOBAL.KEY
                        ) {
                          return (
                            customField.level ===
                            CustomField.LEVEL.DELIVERY_NOTE.KEY
                          );
                        }

                        if (
                          this.state.signatureField?.pathKey ===
                          SignatureField.PATH.CUSTOM_FIELD_LOCAL.KEY
                        ) {
                          return (
                            customField.level === CustomField.LEVEL.ARTICLE.KEY
                          );
                        }

                        return false;
                      },
                    )}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">Default Wert</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <Select
                  value={this.state.signatureField?.defaultMode}
                  fullWidth
                  onChange={this.handleChangeDefaultMode}
                  size="small"
                  options={SignatureField.getDefaultModes()}
                />
              </Grid>
              {this.state.signatureField?.defaultMode ===
              SignatureField.DEFAULT_MODE.FIXED.KEY ? (
                <Grid item xs={6} lg={4}>
                  {this.getFixedDefaultValueInput()}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">Zuordnung zu</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <InputLabel className="text-13px">Firma</InputLabel>
                <SearchableSelect
                  value={this.state.signatureField?.companyId}
                  fullWidth
                  onChange={this.handleChangeCompanyId}
                  size="small"
                  options={this.state.sortedCompanies}
                  loading={this.props.companies.companiesLoading}
                  withEmptyOption
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <InputLabel className="text-13px">Standort</InputLabel>
                <SearchableSelect
                  value={this.state.signatureField?.siteId}
                  fullWidth
                  onChange={this.handleChangeSiteId}
                  size="small"
                  options={this.state.sortedSites}
                  loading={this.props.sites.sitesLoading}
                  withEmptyOption
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <InputLabel className="text-13px">Kostenstelle</InputLabel>
                <SearchableSelect
                  value={this.state.signatureField?.costCenterId}
                  fullWidth
                  onChange={this.handleChangeCostCenterId}
                  size="small"
                  options={this.state.sortedCostCenters}
                  loading={this.props.costCenters.costCentersLoading}
                  withEmptyOption
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BasicForm>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps(),
)(SignatureFieldForm);
