import { useState, useEffect, useCallback } from 'react';

import SiteService from '~/services/site.service';
import ToastService from '~/services/toast.service';

import Log from '~/utils/Log';
import PromiseUtils from '~/utils/promiseUtils';

export const useSiteIdentifiers = (siteId, sites) => {
  const [blacklistedSites, setBlacklistedSites] = useState([]);
  const [whitelistedSites, setWhitelistedSites] = useState([]);
  const [isInProgress, setIsInProgress] = useState(false);
  const [selected, setSelected] = useState({
    blacklistedSites: [],
    whitelistedSites: [],
  });

  const loadData = useCallback(() => {
    const site = sites?.sites?.find(({ id }) => id === siteId);

    if (!site?.references) {
      return;
    }

    const rowsData = site.references.map((siteReference) => ({
      ...siteReference,
      id: siteReference.identifierId,
    }));

    setBlacklistedSites(rowsData.filter(({ isBlacklisted }) => isBlacklisted));
    setWhitelistedSites(rowsData.filter(({ isBlacklisted }) => !isBlacklisted));
  }, [siteId, sites]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSelect = (event, listType) => {
    Log.info(
      'Change selection value of selected site references',
      { from: selected, to: event },
      Log.BREADCRUMB.SELECTION_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      '(De)select site reference',
      Log.FEATURE.SITE_MAPPING,
    );

    setSelected((previous) => ({
      ...previous,
      [listType]: event,
    }));
  };

  const performActionOnSites = async (sitesType, action) => {
    setIsInProgress(true);

    const promises = selected[sitesType].map((identifierId) =>
      action(identifierId),
    );

    const actionVerb =
      sitesType === 'whitelistedSites'
        ? 'von der Zuordnung ausgeschlossen'
        : 'zugeordnet';
    try {
      await PromiseUtils.allResolved(promises);
      SiteService.refreshSites();
      SiteService.refreshAssignedSiteReferences();

      const message =
        promises.length > 1
          ? `Standortbezeichnungen ${actionVerb}`
          : `Standortbezeichnung ${actionVerb}`;

      ToastService.success([message]);
    } catch {
      const errorMessage =
        promises.length > 1
          ? `Standortbezeichnungen konnten nicht ${actionVerb} werden`
          : `Standortbezeichnung konnte nicht ${actionVerb} werden`;

      ToastService.error([errorMessage]);
    } finally {
      setIsInProgress(false);
    }
  };

  const handleBlacklistSites = () => {
    performActionOnSites('whitelistedSites', (identifierId) =>
      SiteService.blacklistSiteIdentifier(siteId, identifierId, true),
    );
  };

  const handleWhitelistSites = () => {
    performActionOnSites('blacklistedSites', (identifierId) =>
      SiteService.blacklistSiteIdentifier(siteId, identifierId, false),
    );
  };

  const handleDeleteMappings = async (sitesType) => {
    if (!['blacklistedSites', 'whitelistedSites'].includes(sitesType)) {
      console.error(`"${sitesType}" is not a valid sites type`);
      return;
    }

    setIsInProgress(true);

    Log.info(
      'Delete site references',
      {
        selected: selected[sitesType],
        from: sitesType,
      },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent(
      'Delete site references',
      Log.FEATURE.SITE_MAPPING,
    );

    const promises = selected[sitesType].map((id) =>
      SiteService.deleteSiteReference(siteId, id)
        .then(() => {
          SiteService.refreshSites();
          SiteService.refreshAssignedSiteReferences();
        })
        .catch((error) => {
          ToastService.httpError(
            [ToastService.MESSAGE.SITE_REFERENCE_DELETION_FAILED],
            error.response,
          );
          Log.productAnalyticsEvent(
            'Failed to delete site references',
            Log.FEATURE.SITE,
            Log.TYPE.ERROR,
          );
        }),
    );

    try {
      await PromiseUtils.allResolved(promises);
      ToastService.success(['Standortbezeichnungen wurden gelöscht.']);
    } catch (error) {
      ToastService.error([
        'Standortbezeichnungen konnten nicht vollständig gelöscht werden.',
      ]);
      Log.error('Failed to delete site references.', error);
      Log.productAnalyticsEvent(
        'Failed to delete site references',
        Log.FEATURE.SITE_MAPPING,
        Log.TYPE.ERROR,
      );
    } finally {
      setIsInProgress(false);
    }
  };

  return {
    blacklistedSites,
    handleBlacklistSites,
    handleDeleteMappings,
    handleSelect,
    handleWhitelistSites,
    isInProgress,
    selected,
    whitelistedSites,
  };
};
