export default class DataExchange {
  constructor(dataExchange) {
    this.id = dataExchange?.id ?? '';
    this.activeCustomerIds = dataExchange?.active_customer_ids || '';
    this.contactAttempts = dataExchange?.contact_attempts ?? [];
    this.contactedPerson = dataExchange?.contacted_person ?? null;
    this.contactingLink = dataExchange?.contacting_link ?? null;
    this.exchangeDeliveryNotes = dataExchange?.exchange_lfs ?? false;
    this.exchangeInvoices = dataExchange?.exchange_invoices ?? false;
    this.exchangeOrders = dataExchange?.exchange_orders ?? false;
    this.furtherInformation = dataExchange?.further_information ?? '';
    this.isExchangeViaPaper = dataExchange?.is_exchange_via_paper ?? false;
    this.knownIssues = dataExchange?.known_issues ?? '';
    this.lastContactAttempt = dataExchange?.contact_attempts?.[0] ?? null;
    this.lastContactAttemptTimestamp =
      dataExchange?.contact_attempts?.[0]?.timestamp ?? null;
    this.receiverId = dataExchange?.receiver_id ?? '';
    this.responsiblePerson = dataExchange?.responsible_person ?? null;
    this.senderId = dataExchange?.sender_id ?? '';
  }
}
