import packageJson from '../package.json';

// we use a js variable to store all config information. this leads to better editor autocomplete when using them without
// having to know the precise env variables there loaded from
const Config = {
  apiUrl: process.env.REACT_APP_ENV_API_URL,
  contactPageUrl: process.env.REACT_APP_ENV_CONTACT_PAGE_URL,
  environment: process.env.REACT_APP_ENV,
  muiLicenseKey: process.env.REACT_APP_MUI_LICENSE_KEY,
  redirectUrl: process.env.REACT_APP_ENV_REDIRECT_URL,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  sgwUrl: process.env.REACT_APP_ENV_SGW_URL,
  version: packageJson.version,
};

const ENV_MAP = {
  beta: 'BETA',
  dev: 'DEV',
  development: 'DEV',
  production: 'PROD',
  staging: 'STAGING',
  test: 'TEST',
};

const extractSubdomain = (url) => {
  const regex = /app\.([^.]+)\.vestigas/;
  const match = url.match(regex);

  const environment = match?.[1] ? match[1]?.toLowerCase() : 'production';

  return ENV_MAP[environment || 'production'];
};

export const environmentInfo = `${extractSubdomain(Config.apiUrl)} API`;

export default Config;
