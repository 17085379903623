import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { KeyboardArrowRight as KeyboardArrowRightIcon } from '@mui/icons-material';
import { MenuItem, Select, Button } from '@mui/material';

import Tile from './Tile';

import { PieChartOutlinedIcon } from '~/assets/icons';
import { ROUTE } from '~/constants/Route';
import DashboardPieChart from '../dashboard/DashboardPieChart';
import DashboardService from '~/services/dashboard.service';
import UnitUtils from '~/utils/unitUtils';

import {
  setDashboard_individualDateRange,
  setDashboard_selectedDateRange,
  setHome_selectedUnit,
  setDashboard_selectedUnit,
  setDashboard_selectedPredefinedDateRange,
} from '~/redux/filtersSlice';
import Log from '~/utils/Log';
import { withErrorBoundary } from '~/ui/atoms';
import ArrayUtils from '~/utils/arrayUtils';
import unitUtils from '~/utils/unitUtils';
import { LOADING_STATE } from '~/constants/LoadingState';
import { promiseHandler } from '~/utils/promiseHandler';
import DeliveriesService from '~/services/deliveries.service';
import {
  setArchiveAnalyticsData,
  setArchiveAnalyticsDataLoading,
} from '~/redux/homeSlice';
import FilterNew from '~/models/filters/FilterNew';

// To track whether the open HTTP requests are loading data for the latest filter.
// If the requests are already stale, the response is thrown away.
// This is a workaround to prevent the chart from being re-rendered when the filter is changed.
// However, we should find a better solution than a global variable.
let chartTileFilterId = 0;

const ChartTile = ({ isArchiveMode }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { deliveryNotesLoading } = useSelector(
    ({ deliveryNotes }) => deliveryNotes,
  );
  const selectedUnit = useSelector(({ filters }) => filters.home_selectedUnit);
  const selectedPredefinedDateRange = useSelector(
    ({ filters }) => filters.home_selectedPredefinedDateRange,
  );
  const selectedDateRange = useSelector(
    ({ filters }) => filters.home_selectedDateRange,
  );
  const selectedSites = useSelector(({ filters }) => filters.selectedSites);
  const selectedCostCenters = useSelector(
    ({ filters }) => filters.selectedCostCenters,
  );
  const individualDateRangeIsSelected = useSelector(
    ({ filters }) => filters.home_individualDateRange,
  );
  const {
    archiveAnalyticsData,
    archiveAnalyticsDataAppliedFilters,
    archiveAnalyticsDataLoading,
    archiveAnalyticsDataVersion,
    filteredDeliveryNotes,
    filteredDeliveryNotesVersion,
  } = useSelector(({ home }) => home);

  const [allData, setAllData] = useState([]);
  const [selectableUnits, setSelectableUnits] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataVersion, setFilteredDataVersion] = useState(0);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (isArchiveMode) {
      return;
    }

    initData();
  }, [filteredDeliveryNotesVersion, isArchiveMode]);

  useEffect(() => {
    if (!isArchiveMode) {
      return;
    }

    // When one of the filters has changed, the filterId is updated so that outdated requests can be detected.
    chartTileFilterId++;
    loadArchiveAnalyticsData(chartTileFilterId);
  }, [
    JSON.stringify(selectedSites),
    JSON.stringify(selectedCostCenters),
    JSON.stringify(selectedDateRange),
    // Potential bug: Listening for the change of selectedDateRange and isArchiveMode
    // might lead to redundant requests.
    isArchiveMode,
    selectedUnit,
  ]);

  const initData = () => {
    const allData = DashboardService.transformDeliveryNotes(
      filteredDeliveryNotes,
    );
    const newSelectableUnits = DashboardService.getUnitFrequencies(allData);

    let newSelectedUnit = selectedUnit;

    const validUnitSelected = newSelectableUnits
      .map(({ unit }) => unit)
      .includes(newSelectedUnit);

    // Check if the selectable units contain the currently selected unit. If not, automatically update the selected unit.
    if (!validUnitSelected) {
      newSelectedUnit = newSelectableUnits[0]?.unit ?? null;
      dispatch(setHome_selectedUnit(newSelectedUnit));
    }

    const filteredData = DashboardService.filterData(
      allData,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      null,
      newSelectedUnit,
      null, // Already filtered in a parent component.
      null,
    );

    setAllData(DashboardService.transformDeliveryNotes(filteredDeliveryNotes));
    setSelectableUnits(
      ArrayUtils.sortByKey(newSelectableUnits, 'frequency', true).map(
        ({ frequency, unit }) => ({
          id: unit,
          name: `${unitUtils.getDescriptiveUnit(unit)} (${frequency})`,
        }),
      ),
    );
    setFilteredData(filteredData);
    setFilteredDataVersion(filteredDataVersion + 1);
  };

  const loadArchiveAnalyticsData = async (oldChartTileFilterId) => {
    if (
      JSON.stringify(getAppliedFilters()) ===
      JSON.stringify(archiveAnalyticsDataAppliedFilters)
    ) {
      return;
    }

    if (!selectedUnit) {
      dispatch(setHome_selectedUnit(getSelectableUnits()[0]?.id));
      return;
    }

    dispatch(setArchiveAnalyticsDataLoading(LOADING_STATE.LOADING));

    const [response, error] = await promiseHandler(
      DeliveriesService.getAnalyticsData(
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        selectedSites,
        selectedCostCenters,
        selectedUnit,
        selectedDateRange,
        null,
      ),
    );

    // If the filterId has been updated in the meantime, the request is outdated and the response is thrown away.
    if (chartTileFilterId !== oldChartTileFilterId) {
      return;
    }

    if (error) {
      Log.error('Error while loading archive analytics data', error);
      dispatch(setArchiveAnalyticsDataLoading(LOADING_STATE.FAILED));
      return;
    }

    dispatch(
      setArchiveAnalyticsData({
        archiveAnalyticsData: response,
        appliedFilters: getAppliedFilters(),
      }),
    );
  };

  const getAppliedFilters = () => ({
    selectedSites,
    selectedCostCenters,
    selectedDateRange,
    selectedUnit,
  });

  const handleUnitChange = (event) => {
    Log.info(
      'Change filter value of unit',
      { from: selectedUnit, to: event.target.value },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Filter unit', Log.FEATURE.HOME);

    dispatch(setHome_selectedUnit(event.target.value));

    if (isArchiveMode) {
      return;
    }

    const filteredData = DashboardService.filterData(
      allData,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      null,
      event.target.value,
      null, // Already filtered in a parent component.
      null,
    );

    setFilteredData(filteredData);
    setFilteredDataVersion(filteredDataVersion + 1);
  };

  const openDashboard = () => {
    Log.productAnalyticsEvent('Open dashboard', Log.FEATURE.HOME);

    dispatch(setDashboard_selectedUnit(selectedUnit));
    dispatch(
      setDashboard_selectedPredefinedDateRange(selectedPredefinedDateRange),
    );
    dispatch(setDashboard_selectedDateRange(selectedDateRange));
    dispatch(setDashboard_individualDateRange(individualDateRangeIsSelected));

    if (isArchiveMode) {
      FilterNew.removeNonApplicableBackendFiltersForDashboardPage();
    }

    history.push({ pathname: ROUTE.DASHBOARD.ROUTE });
  };

  const legendItem = (name, amount, color) => {
    return (
      <div className="flex-s-c gap-10px pt-5px pb-5px" key={name}>
        <div className="h-8px w-20px" style={{ backgroundColor: color }} />
        <div className="text-grey600 text-12px max-w-200px line-h-12px wrap text-start">
          <div>{name}</div>
          <div className="mt-3px">{amount} {UnitUtils.getAbbreviatedUnit(selectedUnit)}</div>
        </div>
      </div>
    );
  };

  const getLoading = () =>
    isArchiveMode ? archiveAnalyticsDataLoading : deliveryNotesLoading;

  const getLegendData = () => {
    const legendData = [];

    if (!chartData) {
      return legendData;
    }

    for (let index = 0; index < 4; index++) {
      if (!chartData.labels[index]) {
        break;
      }

      legendData.push({
        name: chartData.labels[index],
        amount:
          UnitUtils.roundAndFormatDe_safe(chartData.datasets[0].data[index]) ??
          '',
        color: chartData.datasets[0].backgroundColor[index],
      });
    }

    return legendData;
  };

  const getSelectableUnits = () => {
    if (!isArchiveMode) {
      return selectableUnits;
    }

    return DashboardService.getMajorSelectableUnitsForArchiveMode();
  };

  const getUnitFilter = () => {
    const selectableUnits = getSelectableUnits();

    if (selectableUnits.length === 0) {
      return null;
    }

    return (
      <Select
        id="unit-select"
        value={selectedUnit ?? ''}
        onChange={handleUnitChange}
        size="small"
      >
        {selectableUnits.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const noLiveData =
    !isArchiveMode &&
    (filteredData.length === 0 || filteredDeliveryNotes.length === 0);
  const noArchivedData = isArchiveMode && archiveAnalyticsData.length === 0;

  if (noLiveData || noArchivedData) {
    return (
      <Tile
        title="Artikel"
        icon={<PieChartOutlinedIcon className="icon-medium" />}
        width={2}
        loading={getLoading()}
      >
        <div className="flex-sb-c gap-50px h-full">
          <div className="text-grey400 bold ml-250px">
            Keine Lieferungen für den
            <br />
            angegebenen Filter vorhanden.
          </div>
          <div className="flex-sb-e flexdir-column w-170px h-270px mt--35px">
            {getUnitFilter()}
            <Button
              className="primary-button mt-auto"
              onClick={openDashboard}
              endIcon={<KeyboardArrowRightIcon />}
            >
              Zur Übersicht
            </Button>
          </div>
        </div>
      </Tile>
    );
  }

  return (
    <Tile
      title={'Artikel'}
      icon={<PieChartOutlinedIcon className="icon-medium" />}
      width={2}
      loading={getLoading()}
    >
      <div className="flex-sb-c gap-50px h-full">
        <div>
          {getLegendData().map(({ amount, color, name }) =>
            legendItem(name, amount, color),
          )}
        </div>
        <div className="w-200px flex-c-c h-full">
          <DashboardPieChart
            data={filteredData}
            dataVersion={filteredDataVersion}
            isArchiveMode={isArchiveMode}
            archiveAnalyticsData={archiveAnalyticsData}
            archiveAnalyticsDataVersion={archiveAnalyticsDataVersion}
            onChartDataChange={setChartData}
          />
        </div>
        <div className="flex-sb-e flexdir-column w-170px h-270px mt--35px">
          {getUnitFilter()}
          <Button
            className="primary-button"
            onClick={openDashboard}
            endIcon={<KeyboardArrowRightIcon />}
          >
            Zur Übersicht
          </Button>
        </div>
      </div>
    </Tile>
  );
};

export default withErrorBoundary(ChartTile, null);
