import React from 'react';

import { Grid } from '@mui/material';

import Invoice from '~/models/invoices/Invoice';
import { withErrorBoundary } from '~/ui/atoms';
import InvoiceMetaDataItem from './InvoiceMetaDataItem';
import { dateUtils } from '~/utils/dateUtils';

function InvoiceMetaData(props) {
  const categoryValues = [];

  if (props.invoice?.direction === Invoice.DIRECTION.INCOMING) {
    categoryValues.push({
      category: Invoice.PROPERTY.SELLER.STRING,
      value:
        props.invoice?.seller?.name +
        '\n' +
        props.invoice?.seller?.address?.getConcatenatedAddress(),
      xs: 4,
      lg: 6,
    });
  }

  if (props.invoice?.direction === Invoice.DIRECTION.OUTGOING) {
    categoryValues.push({
      category: Invoice.PROPERTY.BUYER.STRING,
      value:
        props.invoice?.buyer?.name +
        '\n' +
        props.invoice?.buyer?.address?.getConcatenatedAddress(),
      xs: 4,
      lg: 6,
    });
  }

  if (props.invoice?.direction === Invoice.DIRECTION.NA) {
    categoryValues.push(
      {
        category: Invoice.PROPERTY.SELLER.STRING,
        value:
          props.invoice?.seller?.name +
          '\n' +
          props.invoice?.seller?.address?.getConcatenatedAddress(),
        xs: 2,
        lg: 3,
      },
      {
        category: Invoice.PROPERTY.BUYER.STRING,
        value:
          props.invoice?.buyer?.name +
          '\n' +
          props.invoice?.buyer?.address?.getConcatenatedAddress(),
        xs: 2,
        lg: 3,
      },
    );
  }

  categoryValues.push(
    {
      category: 'Lieferort',
      value: props.toSite ? props.toSite : '-',
      xs: 3,
      lg: 3,
    },
    {
      category: 'Bestellnummer',
      value: '-',
      xs: 3,
      lg: 2,
    },
    {
      category: 'Datum',
      value:
        dateUtils.getFormattedDate_safe(
          props.invoice?.date,
          dateUtils.DATE_FORMAT.DD_MM_YYYY,
        ) ?? '-',
      xs: 2,
      lg: 1,
    },
  );

  return (
    <Grid container spacing={2}>
      {categoryValues.map((dataSet, index) => (
        <Grid key={index} item xs={dataSet.xs} lg={dataSet.lg}>
          <InvoiceMetaDataItem
            key={index}
            category={dataSet.category}
            value={dataSet.value}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default withErrorBoundary(
  InvoiceMetaData,
  'Rechnungsdaten konten nicht geladen werden.',
);
