import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Divider } from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import Log from '~/utils/Log';

import { ROUTE } from '~/constants/Route';

import { TAB } from '~/constants/Tab';

import UserUtils from '~/utils/userUtils';
import SiteService from '~/services/site.service';

import { DeliveryChanges } from '../DeliveryChanges';
import { DeliveryFilterBar } from '../DeliveryFilterBar';
import { DeliveryFilterGroups } from '../DeliveryFilterGroups';
import { DeliveryList } from '../DeliveryList';

import { mapStateToProps } from './mapStateToProps';
import { mapDispatchToProps } from './mapDispatchToProps';
import { getDefaultDateRange, handleFilterChange } from './utils';

import { DeliveryNoteActions } from './DeliveryNoteActions';
import { fixLegacyFilterModels } from '~/utils/datagridUtils';

const DeliveryOverviewComponent = (props) => {
  useEffect(() => {
    setDefaultDateRange(props.deliveryNotes.deliveryNotes);
    props.setPageTitle('Alle Lieferungen');
    document.title = 'VESTIGAS - Alle Lieferungen';
  }, []);

  useEffect(() => {
    setDefaultDateRange(props.deliveryNotes.deliveryNotes);
  }, [props.deliveryNotes.deliveryNotesVersion]);

  const setDefaultDateRange = (deliveryNotes) => {
    const dateRange = getDefaultDateRange(deliveryNotes);
    if (dateRange) {
      props.setDelivery_dateRange(dateRange);
    }
  };

  const handleFilterChangeRefactorMe = (type, customField, filterValue) => {
    handleFilterChange(type, customField, filterValue, props);
  };

  const getCalculatedFilterModel = () => {
    const isUnassignedDLNsTab =
      props.selectedTab === TAB.DELIVERY.UNASSIGNED_DELIVERY_NOTES.INDEX;
    const isPermittedSiteAllowedUser =
      props.userFeatureFlags.accessPermittedSites ||
      UserUtils.isPermittedSiteAllowedUser();

    const nextFilterModel =
      isUnassignedDLNsTab && isPermittedSiteAllowedUser
        ? SiteService.getUnassignedDeliveriesFilters()
        : props.filterModel;

    return fixLegacyFilterModels(nextFilterModel);
  };

  const handleClickCreateDln = () => {
    Log.productAnalyticsEvent('Open form', Log.FEATURE.CREATE_DELIVERY_NOTE);
    props.history.push({
      pathname: ROUTE.CREATE_DELIVERY_NOTE.ROUTE,
    });
  };

  const handleClickTemplate = (templateId) => {
    Log.productAnalyticsEvent(
      'Import template',
      Log.FEATURE.CREATE_DELIVERY_NOTE,
    );
    const template = props.deliveryNotes.deliveryNoteTemplates.find(
      ({ id }) => id === templateId,
    );
    if (template) {
      return props.history.push({
        pathname: ROUTE.CREATE_DELIVERY_NOTE.ROUTE,
        state: {
          template,
        },
      });
    }

    Log.error(`Failed to find template by id. id: ${templateId}`);
    props.history.push({
      pathname: ROUTE.CREATE_DELIVERY_NOTE.ROUTE,
    });
  };

  const handleClickCreateServiceNote = () => {
    Log.productAnalyticsEvent('Open form', Log.FEATURE.CREATE_DELIVERY_NOTE);
    props.history.push({
      pathname: ROUTE.CREATE_SERVICE_NOTE.ROUTE,
    });
  };

  const handleClickServiceNoteTemplate = (templateId) => {
    Log.productAnalyticsEvent(
      'Import template',
      Log.FEATURE.CREATE_DELIVERY_NOTE,
    );
    const template = props.deliveryNotes.deliveryNoteTemplates.find(
      ({ id }) => id === templateId,
    );
    if (template) {
      return props.history.push({
        pathname: ROUTE.CREATE_SERVICE_NOTE.ROUTE,
        state: {
          template,
        },
      });
    }

    Log.error(`Failed to find template by id. id: ${templateId}`);
    props.history.push({
      pathname: ROUTE.CREATE_SERVICE_NOTE.ROUTE,
    });
  };

  return (
    <div className="main-padding flexdir-column flex h-full">
      <DeliveryFilterGroups
        onChangeValue={handleFilterChangeRefactorMe}
        data={props.deliveryNotes.filteredDeliveryRows}
        dataVersion={props.deliveryNotes.filteredDeliveryNotesVersion}
        calculatedFilterModel={getCalculatedFilterModel()}
      />
      <Divider sx={{ my: 2 }} />
      <div className="flex-sb-c w-full">
        <DeliveryFilterBar />
        <DeliveryNoteActions
          handleClickCreateDln={handleClickCreateDln}
          handleClickCreateServiceNote={handleClickCreateServiceNote}
          handleClickTemplate={handleClickTemplate}
          handleClickServiceNoteTemplate={handleClickServiceNoteTemplate}
        />
      </div>
      {props.selectedTab === TAB.DELIVERY.CHANGES.INDEX ? (
        <DeliveryChanges history={props.history} />
      ) : (
        <DeliveryList
          history={props.history}
          calculatedFilterModel={getCalculatedFilterModel()}
          userinfo={props.userinfo}
        />
      )}
      <div className="min-h-2rem" />
    </div>
  );
};

export const DeliveryOverview = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(DeliveryOverviewComponent),
  'Lieferungen konnten nicht geladen werden.',
);
