import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

import { EndAdornmentClear } from './EndAdornmentClear';

import FunctionUtils from '~/utils/functionUtils';
import Log from '~/utils/Log';

export default class FreeTextSearch extends React.Component {
  constructor(props) {
    super(props);

    // this.state.bufferedValue is the value that the user sees in the TextField. It is updated in realtime by each keystroke.
    // this.props.value is the value that is passed with a delay of 1 second to the parent component.
    this.state = {
      bufferedValue: '',
    };
  }

  componentDidMount() {
    this.setState({
      bufferedValue: this.props.value,
    });
  }

  componentDidUpdate(prevProps) {
    // If value is controlled from outside (e.g. reset filters), then bufferedValue must also be updated.
    if (this.props.value !== prevProps.value) {
      this.setState({
        bufferedValue: this.props.value,
      });
    }
  }

  onChange = (event) => {
    Log.info(
      'Change value of free text search',
      { from: this.state.bufferedValue, to: event.target.value },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );

    this.setState({
      bufferedValue: event.target.value,
    });

    if (this.props.withDelay) {
      FunctionUtils.delayFunction(
        this.props.id,
        () => this.forwardChangeToProps(event.target.value),
        [event.target.value],
        1000,
      );
    } else {
      this.forwardChangeToProps(event.target.value);
    }
  };
  forwardChangeToProps = (value) => {
    Log.productAnalyticsEvent(
      'Update free text search',
      this.props.productAnalyticsFeature,
    );
    this.props.onChange(value);
  };
  onClear = () => {
    Log.info(
      'Change value of free text search',
      { from: this.state.bufferedValue, to: '' },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Remove free text search',
      this.props.productAnalyticsFeature,
    );

    this.props.onChange('');
  };

  render() {
    return (
      <TextField
        label="Suche..."
        value={this.state.bufferedValue}
        onChange={this.onChange}
        autoComplete="off"
        size="small"
        className="w-full bg-white"
        disabled={this.props.disabled}
        autoFocus={this.props.autoFocus}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: this.props.value ? (
            <EndAdornmentClear onClick={this.onClear} />
          ) : null,
        }}
      />
    );
  }
}
