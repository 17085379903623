import Address from './Address';
import CompanyService from '~/services/company.service';
import ValueGroup from '../deliveries/ValueGroup';
import UniversalCommunication from './UniversalCommunication';
import PermissionGrant from './PermissionGrant';
import ArrayUtils from '~/utils/arrayUtils';
import TradeContact from './TradeContact';

export default class Company {
  constructor(legalOrganization, tradeContact, additionalDataInitiated) {
    this.id = legalOrganization?.id ?? '';
    this.name = legalOrganization?.name ?? '';
    this.companyAccount = legalOrganization?.company_account_id ?? '';
    this.address = new Address(legalOrganization?.address);
    this.universalCommunication = new UniversalCommunication(
      legalOrganization?.universal_communication,
    );
    this.tradeContact = new TradeContact(tradeContact);

    this.organisationalGroups = legalOrganization?.org_units ?? [];
    this.organisationalGroupPaths = legalOrganization?.org_unit_paths ?? [];

    this.permissionGrantsFrom =
      legalOrganization?.permissions_from?.map(
        (permissionGrant) =>
          new PermissionGrant({
            ...permissionGrant,
            target_type: PermissionGrant.ENTITY_TYPE.COMPANY.KEY,
            target_id: this.id,
          }),
      ) ?? [];

    // This variable is used to track whether additional data such as permissions and paths are already initiated.
    // In the future, this data will not be provided anymore by the bulk endpoint and must be loaded separately when the user opens the respective master data form.
    this.additionalDataInitiated = additionalDataInitiated;

    // Workaround: Prevent the paths to be loaded when the GET /all endpoint is called as the paths are currently still provided and cause visual bugs.
    if (!this.additionalDataInitiated) {
      this.organisationalGroupPaths = [];
    }

    this.fixParserErrors();
  }

  async getLogo() {
    const id = ValueGroup.getCurrentValue(this.id);
    return CompanyService.getCompanyLogoById(id);
  }

  fixParserErrors() {
    this.name = this.name.includes('Undifinied') ? '' : this.name;
  }

  getBackendFormat() {
    const id = this.id ? this.id : null;
    const companyAccount = this.companyAccount ? this.companyAccount : null;

    return {
      id,
      legal_organization: {
        id,
        company_account_id: companyAccount,
        name: this.name,
        address: this.address.getBackendFormat(),
      },
    };
  }

  static getDifferentValues(companyA, companyB) {
    const differentValues = [];

    if (companyA?.id !== companyB?.id) {
      differentValues.push('ID');
    }

    if (companyA?.name !== companyB?.name) {
      differentValues.push('Name');
    }

    if (
      Address.getDifferentValues(companyA?.address, companyB?.address).length >
      0
    ) {
      differentValues.push('Adresse');
    }

    // Difference check for universal communication is not needed currently as it can't be specified in company form.

    const differentOrganisationalGroups = ArrayUtils.getDifference(
      companyA?.organisationalGroups,
      companyB?.organisationalGroups,
    );
    if (
      differentOrganisationalGroups[0].length > 0 ||
      differentOrganisationalGroups[1].length > 0
    ) {
      differentValues.push('Organisations-Gruppen');
    }

    return differentValues;
  }
}
