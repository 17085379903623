export default function UserEmailNamePair(props) {
  return (
    <span>
      {props.user.email}
      {props.user.getName() ? (
        <span className="text-grey400 ml-20px">{props.user.getName()}</span>
      ) : null}
    </span>
  );
}
