import { createRoot } from 'react-dom/client';

import { Provider as ReduxProvider } from 'react-redux';

import store from '~/redux/store';

import { App } from './App';

const root = createRoot(document.querySelector('#root'));
root.render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>,
);
