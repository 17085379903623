import { Box, Button, Card } from '@mui/material';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { useCopyToClipboard } from '@uidotdev/usehooks';
import {
  allExpanded,
  collapseAllNested,
  defaultStyles,
  JsonView,
} from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { useSelector, useDispatch } from 'react-redux';

import { closeJsonModal } from '~/redux/devToolsSlice';

import BasicModal from './BasicModal';

export const JsonModal = ({ showAllExpanded = true }) => {
  const devTools = useSelector((state) => state.devTools);
  const dispatch = useDispatch();

  const [copiedText, copyToClipboard] = useCopyToClipboard();

  const content = JSON.stringify(devTools.jsonModal.json);

  return (
    <BasicModal
      title={
        <div className="flex w-full gap-4">
          <span>JSON</span>
          <Button
            onClick={() => copyToClipboard(content)}
            startIcon={<ContentCopyIcon />}
          >
            JSON kopieren
          </Button>
        </div>
      }
      open={devTools.jsonModal.open}
      closeModal={() => dispatch(closeJsonModal())}
    >
      <Card variant="outlined">
        <Box p={1}>
          <JsonView
            data={devTools.jsonModal.json ?? {}}
            shouldExpandNode={showAllExpanded ? allExpanded : collapseAllNested}
            style={defaultStyles}
            clickToExpandNode={true}
          />
        </Box>
      </Card>
    </BasicModal>
  );
};
