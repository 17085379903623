import { LightTooltip } from '~/utils/componentUtils';
import { EditedIconLight } from '~/assets/icons';
import ValueGroup from '~/models/deliveries/ValueGroup';
import { dateUtils } from '~/utils/dateUtils';
import ObjectUtils from '~/utils/objectUtils';
import Log from '~/utils/Log';

function EditingHistory(props) {
  let callback = props.callback;
  callback ||= (value) => value; // if no callback is set, the values should not be processed

  const fallback = props.fallback ?? '-';

  const value = ValueGroup.isValueGroup(props.value)
    ? props.value.current.value
    : props.value;
  const formattedValue = callback(value);
  const displayValue =
    formattedValue == null || formattedValue === '' ? fallback : formattedValue;

  if (!ValueGroup.isValueGroup(props.value)) {
    return <div className="word-break">{displayValue}</div>;
  }

  const onMouseEnter = () => {
    Log.productAnalyticsEvent(
      'Open changes tooltip',
      Log.FEATURE.DELIVERY_CHANGES,
    );
  };

  return (
    <div className="flex items-center justify-start gap-2">
      <div className="word-break">{displayValue}</div>
      {props.value.history.length > 0 ? (
        <div className="flex min-w-8 items-center justify-start">
          <LightTooltip
            onMouseEnter={onMouseEnter}
            title={
              <div>
                <div className="bold">Ursprünglich</div>
                <div>
                  {ObjectUtils.isEmptyValue(callback(props.value.initial.value))
                    ? '-'
                    : callback(props.value.initial.value)}
                </div>
                {props.value.history.map((item, index) => {
                  return (
                    <div key={index}>
                      <br />
                      <div className="bold">
                        {item.company} am{' '}
                        {dateUtils.getFormattedDate_safe(
                          item.datetime,
                          dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
                        ) ?? ''}
                      </div>
                      <div>
                        {ObjectUtils.isEmptyValue(callback(item.value))
                          ? '-'
                          : callback(item.value)}
                      </div>
                    </div>
                  );
                })}
              </div>
            }
          >
            <span>
              <EditedIconLight className="size-6" />
            </span>
          </LightTooltip>
        </div>
      ) : null}
    </div>
  );
}

export default EditingHistory;
