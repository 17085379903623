import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';

export const SiteSection = ({
  formSite,
  handleCheckboxChange,
  handleInputChange,
  renderForCreate,
}) => (
  <>
    <Grid item xs={12} lg={12}>
      <h3 className="main-text mt-0">Standort</h3>
      <Grid container spacing={2}>
        <Grid item xs={8} lg={8}>
          <TextField
            id="name-input"
            name="name"
            label="Name"
            type="text"
            required
            fullWidth
            value={formSite?.name}
            onChange={handleInputChange}
            autoFocus
            autoComplete="off"
          />
        </Grid>
        {!renderForCreate() && (
          <Grid item xs={4} lg={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formSite?.active}
                  onChange={handleCheckboxChange}
                  name="is_active"
                />
              }
              label="Aktiv"
            />
          </Grid>
        )}
      </Grid>
    </Grid>
    <Grid item xs={12} lg={12}>
      <h3 className="mt-20px main-text">Details</h3>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={4}>
          <TextField
            id="street_name-input"
            name="street_name"
            label="Straße"
            type="text"
            fullWidth
            value={formSite?.address?.streetName}
            onChange={handleInputChange}
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <TextField
            id="building_number-input"
            name="building_number"
            label="Hausnummer"
            type="text"
            fullWidth
            value={formSite?.address?.buildingNumber}
            onChange={handleInputChange}
            autoComplete="off"
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} lg={12}>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={4}>
          <TextField
            id="post_code-input"
            name="post_code"
            label="PLZ"
            type="text"
            fullWidth
            value={formSite?.address?.postCode}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <TextField
            id="city-input"
            name="city"
            label="Ort"
            type="text"
            fullWidth
            value={formSite?.address?.city}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} lg={12}>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={4}>
          <TextField
            id="latitude-input"
            name="latitude"
            label="Breitengrad"
            type="text"
            step="any"
            fullWidth
            value={formSite?.coords?.latitude}
            onChange={handleInputChange}
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <TextField
            id="longitude-input"
            name="longitude"
            label="Längengrad"
            type="text"
            step="any"
            fullWidth
            value={formSite?.coords?.longitude}
            onChange={handleInputChange}
            autoComplete="off"
          />
        </Grid>
      </Grid>
    </Grid>
  </>
);
