import Tile from './Tile';
import { withErrorBoundary } from '~/ui/atoms';
import { useSelector } from 'react-redux';
import User from '~/models/masterdata/User';

const UserTile = () => {
  const { userinfo, userinfoLoading } = useSelector(({ userinfo }) => userinfo);

  const rows = [
    {
      title: 'Name',
      value: User.formatName(userinfo.firstname, userinfo.lastname),
    },
    {
      title: 'Position',
      value: userinfo.position ?? '-',
    },
    {
      title: 'Firma',
      value: userinfo.company?.name ?? '-',
    },
  ];

  return (
    <Tile title="Persönliche Daten" loading={userinfoLoading}>
      <div className="pt-65px flex-s-s gap-20px flexdir-column">
        {rows.map(({ title, value }) => (
          <div key={title} className="flex-s-s">
            <div className="text-grey400 min-w-80px text-start">{title}</div>
            <div className="bold text-start">{value}</div>
          </div>
        ))}
      </div>
    </Tile>
  );
};

export default withErrorBoundary(UserTile, null);
