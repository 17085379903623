import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';

/**
 * Checks if a query matches a value in a row.
 *
 * @param {string} query - The query to match, e.g. 'Large Corp Inc.'.
 * @param {string} selectField - The field to search in the row: 'all' or a specific field.
 * @param {Object} row - The row to search in.
 * @param {string} searchString - The concatenated values of the row.
 * @return {boolean} Returns true if the query matches the value in the row, otherwise false.
 */

export const queryMatches = (query, selectField, row, searchString) => {
  if (!query) {
    return false;
  }

  if (!selectField) {
    return false;
  }

  if (selectField === 'all') {
    return searchString.includes(query);
  }

  if (row[selectField] === undefined) {
    Log.error(
      'Failed to find select field of free text filter in rows of delivery list table.',
    );
    return false;
  }

  return String(row[selectField]).toLowerCase().includes(query);
};

/**
 * Checks if a given date is within a specified date range.
 *
 * @param {Object} selectedTimeframe - The date range to check against.
 * @param {string} selectedTimeframe.from - The start date of the range.
 * @param {string} selectedTimeframe.to - The end date of the range.
 * @param {string} date - The date to check.
 * @return {boolean} Returns true if the given date is within the specified date range, otherwise false.
 */
export const dateRangeMatches = (selectedTimeframe, date) => {
  if (!selectedTimeframe) {
    // If no timeframe is provided, we call it a match, b/c we don't want to filter out items if no filter is set.
    return true;
  }

  const rowDate = Date.parse(date);

  return rowDate >= selectedTimeframe.from && rowDate <= selectedTimeframe.to;
};

/**
 * Checks if the one of the selected values match the row value (which is not an array).
 *
 * @param {Array} selectedValues - The array of selected values to compare.
 * @param {any} rowValue - The value of the row to check against the selected values.
 * @return {boolean} Returns true if the selected values match the row value, otherwise false.
 */
const valueMatches = (selectedValues, rowValue) => {
  if (selectedValues.length === 0) {
    return false;
  }

  if (!rowValue && selectedValues.includes(ArrayUtils.EMPTY_DROPDOWN_OPTION)) {
    return false;
  }

  return selectedValues.includes(rowValue);
};

/**
 * Checks if one of the selected values match one of the row values (which is an array).
 *
 * @param {Array} selectedValues - The array of selected values to compare.
 * @param {Array} rowValues - The array of row values to compare against selected values.
 * @return {boolean} Returns true if there is an overlap, otherwise false.
 */
const arraysOverlap = (selectedValues, rowValues) => {
  if (selectedValues.length === 0) {
    return false;
  }

  if (
    rowValues.length === 0 &&
    selectedValues.includes(ArrayUtils.EMPTY_DROPDOWN_OPTION)
  ) {
    return false;
  }

  return ArrayUtils.getOverlappingValues(selectedValues, rowValues).length > 0;
};

/**
 * Checks if one of the selected values match the row value.
 * Handles both single row values and array row values.
 *
 * @param {Array} selectedValues - The array of selected values to compare.
 * @param {any} rowValue - The value of the row to check against the selected values.
 * @return {boolean} Returns true if the selected values match the row value, otherwise false.
 */
export const selectionMatches = (selectedValues, rowValue) =>
  Array.isArray(rowValue)
    ? arraysOverlap(selectedValues, rowValue)
    : valueMatches(selectedValues, rowValue);

/**
 * Checks if all the selected custom fields match the corresponding values in the given row.
 *
 * @param {Array} selectedCustomFields - An array of custom field objects containing filterValue and key properties.
 * @param {Object} row - The row object containing the values to compare against the selected custom fields.
 * @return {boolean} Returns true if all the selected custom fields match the corresponding values in the given row, otherwise false.
 */
export const customFieldsMatch = (selectedCustomFields, row) => {
  if (selectedCustomFields.length === 0) {
    return false;
  }

  return selectedCustomFields.every((customField) => {
    const { filterValue, key } = customField;
    const rowValue = row[key];

    if (filterValue.length === 0) {
      return true;
    }

    const isStringOrNumberMatch = filterValue.includes(rowValue);
    const isArrayMatch =
      Array.isArray(rowValue) &&
      ArrayUtils.getOverlappingValues(filterValue, rowValue).length > 0;
    const isBooleanMatch =
      typeof rowValue === 'boolean' &&
      ((rowValue && filterValue.includes('Ja')) ||
        (!rowValue && filterValue.includes('Nein'))); // FIXME: checking against a translated value is bound to break.
    const isEmptyDropdownOption =
      !rowValue && filterValue.includes(ArrayUtils.EMPTY_DROPDOWN_OPTION);

    return (
      isStringOrNumberMatch ||
      isArrayMatch ||
      isBooleanMatch ||
      isEmptyDropdownOption
    );
  });
};

/**
 * Checks if any of the provided elements (which can be arrays or strings) contain any values.
 *
 * @param {Array} values - The array of elements to check.
 * @return {boolean} Returns true if any of the elements contain values, otherwise false.
 */
export const containsAnyValues = (values) =>
  values.some((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }

    return (value != null && String(value)?.trim()?.length > 0) || false;
  });
