import React from 'react';

import { Delete as DeleteIcon } from '@mui/icons-material';

import { Button, FormControlLabel, Switch } from '@mui/material';
import { LightTooltipWide } from '~/utils/componentUtils';

import { withErrorBoundary } from '~/ui/atoms';
import Log from '~/utils/Log';
import { connect } from 'react-redux';
import { FEATURE_FLAG } from '~/constants/FeatureFlag';
import ThirdPartyService from '~/services/thirdParty.service';

const mapStateToProps = (state) => ({
  companyAccount: state.companyAccount,
});

class DataProtection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productAnalyticsConsent:
        !ThirdPartyService.productAnalyticsToolsOptedOut(),
    };
  }

  handleSwitchChange = (event) => {
    if (this.state.productAnalyticsConsent) {
      Log.productAnalyticsEvent(
        'Decline product analytics consent (opt-out)',
        Log.FEATURE.DATA_PROTECTION,
      );
      ThirdPartyService.optOutProductAnalyticsTools();
    } else {
      Log.productAnalyticsEvent(
        'Grant product analytics consent (opt-in)',
        Log.FEATURE.DATA_PROTECTION,
      );
      ThirdPartyService.optInProductAnalyticsTools();
    }

    window.location.reload();
  };
  handleClick = () => {
    Log.productAnalyticsEvent(
      'Reset analytics consent',
      Log.FEATURE.DATA_PROTECTION,
    );
    ThirdPartyService.optInProductAnalyticsTools();
    window.location.reload();
  };

  render() {
    return (
      <div className="ml-2rem mr-2rem">
        <div className="main-header">Datenschutz</div>
        <div className="box-shadow-blue rounded-5px p-20px pt-10px bg-white">
          <h3 className="main-text">Datenschutzbestimmungen</h3>
          <span>
            Die Datenschutzbestimmungen von VESTIGAS findest du auf unserer{' '}
            <a href="https://vestigas.com/datenschutz">Website</a>.
          </span>
          <h3 className="mt-3rem main-text">Cookie-Einstellungen</h3>
          <h4>Diese Website verwendet Cookies</h4>
          <span>
            Wir verwenden Cookies und ähnliche Technologien auf unserer Website,
            um Zugriffe zu analysieren. Die Datenverarbeitung kann erst in Folge
            gesetzter Cookies stattfinden. Wir teilen diese Daten mit Dritten,
            die wir im Folgenden benennen.
            <br />
            <br />
            Die Datenverarbeitung kann mit deiner Einwilligung oder auf Basis
            eines berechtigten Interesses erfolgen, dem du widersprechen kannst.
            Du hast das Recht, nicht einzuwilligen und deine Einwilligung zu
            einem späteren Zeitpunkt unter "Einstellungen - Datenschutz" zu
            ändern oder zu widerrufen. Weitere Informationen zur Verwendung
            deiner Daten findest du in unseren{' '}
            <a href="https://vestigas.com/datenschutz">
              Datenschutzbestimmungen
            </a>
            .
            <br />
            <br />
            Wir legen höchsten Wert auf den Schutz deiner Daten und handeln
            folglich <b>konform entsprechend DSGVO</b>. Es werden{' '}
            <b>keine personenbezogenen Daten</b> gesammelt. Die gesammelten
            Daten <b>können nicht mit dir in Verbindung gebracht werden</b>.
          </span>
          <h4>Welche Cookies, bzw. Services, verwendet diese Website?</h4>
          <div>
            Im Folgenden findest du eine Übersicht über alle Cookies, bzw.
            Services, die von dieser Website genutzt werden. Du kannst
            detaillierte Informationen zu jedem Service einsehen und einzeln
            zustimmen oder von deinem Widerspruchsrecht Gebrauch machen.
          </div>
          <div className="mt-1rem">
            <FormControlLabel
              disabled
              control={<Switch defaultChecked />}
              label="Essenziell"
            />
            <div className="border-grey p-1rem w-75pct">
              Essenzielle Cookies sind für die grundlegende Funktionalität der
              Website erforderlich. Sie enthalten nur technisch notwendige
              Cookies. Diesen Cookies kann nicht widersprochen werden.
            </div>
          </div>
          {this.props.companyAccount.companyAccount?.featureFlags?.[
            FEATURE_FLAG.PRODUCT_ANALYTICS_TOOLS
          ] ? (
            <>
              <div className="mt-1rem">
                <FormControlLabel
                  control={<Switch />}
                  label="Analyse"
                  checked={this.state.productAnalyticsConsent}
                  onChange={this.handleSwitchChange}
                  name="product_analytics_consent"
                />
                <div className="border-grey p-1rem w-75pct">
                  Analyse-Services werden zur Erstellung von Nutzungsprofilen
                  zur bedarfsgerechten Gestaltung der Software benötigt. Die
                  Daten ermöglichen es uns essenzielle Funktionen der Anwendung
                  auf die Bedürfnisse der Benutzer hin auszurichten.
                  <ul>
                    <li>
                      <b>Amplitude Inc.</b>
                      <br />
                      Aggregierte Analyse des Nutzungsverhaltens innerhalb der
                      Software, um zu verstehen, welche und wie Funktionen
                      Benutzer verwenden, um diese auf die Bedürfnisse der
                      Benutzer hin auszurichten.
                    </li>
                  </ul>
                </div>
              </div>
            </>
          ) : null}
          <div className="mt-1rem">
            <LightTooltipWide title="Cookie-Einstellungen zurücksetzen und Banner öffnen.">
              <Button
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={this.handleClick}
              >
                Cookie-Einstellungen zurücksetzen
              </Button>
            </LightTooltipWide>
          </div>
        </div>
      </div>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(DataProtection),
  'Datenschutzbestimmungen konnten nicht geladen werden.',
);
