import { useState } from 'react';

import { Button, Menu, MenuItem } from '@mui/material';

import { Spinner } from '../Spinner';

import { ICON } from './constants';

export const GridToolbarCustomComponent = ({ icon, menuItems, title }) => {
  const [anchorElement, setAnchorElement] = useState(null);

  const handleClick = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <Button
        className="datagrid-toolbar-button"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        startIcon={ICON[icon] ?? null}
      >
        {title}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {menuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.name}
            selected={menuItem.selected}
            disabled={menuItem.isLoading}
            onClick={menuItem.onClick ?? handleClose}
            className="flex items-center gap-2"
          >
            {menuItem.isLoading ? <Spinner /> : null}
            {menuItem.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
