import memoize from 'lodash/memoize';
import MapperService from '~/services/mapper.service';
import ArrayUtils from '~/utils/arrayUtils';
import { SiteNameAddressPair } from '~/components/SiteNameAddressPair';

// Memoize the filtering of cost centers for each site
const getFilteredCostCenters = memoize((siteCostCenters, activeCostCenters) => {
  const siteCostCentersSet = new Set(siteCostCenters);
  return activeCostCenters.filter(({ id }) => siteCostCentersSet.has(id));
});

export const initSelectableCostCentersAndSites = ({
  activeCostCenters,
  activeSites,
  setSortedCostCenters,
  setSortedSites,
}) => {
  const mappedSites = activeSites.map((site) => {
    const filteredCostCenters = getFilteredCostCenters(
      site.costCenters,
      activeCostCenters,
    );

    const filteredCostCenterNames = filteredCostCenters
      .map(({ name }) => name)
      .join(', ');

    const newSite = {
      id: site.id,
      name: site.name,
      costCenters: filteredCostCenters,
      nameComponent: (
        <SiteNameAddressPair
          site={site}
          costCenters={filteredCostCenterNames}
        />
      ),
    };

    return MapperService.addSearchStringWithValues(newSite, [
      site.name,
      site.address.getConcatenatedAddress(),
      filteredCostCenterNames,
    ]);
  });

  // Sort the sites and cost centers after mapping
  const sortedSites = ArrayUtils.sortByKey(mappedSites, 'name');
  const sortedCostCenters = ArrayUtils.sortByKey(activeCostCenters, 'name');

  // Update the sorted lists
  setSortedSites(sortedSites);
  setSortedCostCenters(sortedCostCenters);
};
