import React from 'react';
import DlnCheck from './DlnCheck';
import SignatureCheck from './SignatureCheck';
import ArticleExistsCheck from './ArticleExistsCheck';
import AmountApprovedCheck from './AmountApprovedCheck';
import AmountCheck from './AmountCheck';
import PriceCheck from './PriceCheck';
import FormalCheck from './FormalCheck';
import { withErrorBoundary } from '~/ui/atoms';
import ArrayUtils from '~/utils/arrayUtils';

class InvoiceCheckCategories extends React.Component {
  getCategoryCheckClassName(category) {
    let className = 'bg-white rounded-5px box-shadow-blue w-full ';

    className += this.props.activeInvoiceCheckCategories.includes(category.name)
      ? 'fade-in-1500ms p-20px mb-20px'
      : 'fade-out-0ms max-h-0';

    return className;
  }

  render() {
    const categoryComponentPairs = [
      {
        category: this.props.invoice.checkCategory.formalCheck,
        categoryName: this.props.invoice.checkCategory.formalCheck.name,
        component: (
          <FormalCheck
            category={this.props.invoice.checkCategory.formalCheck}
          />
        ),
      },
      {
        category: this.props.invoice.checkCategory.dlnCheck,
        categoryName: this.props.invoice.checkCategory.dlnCheck.name,
        component: (
          <DlnCheck category={this.props.invoice.checkCategory.dlnCheck} />
        ),
      },
      {
        category: this.props.invoice.checkCategory.signatureCheck,
        categoryName: this.props.invoice.checkCategory.signatureCheck.name,
        component: (
          <SignatureCheck
            category={this.props.invoice.checkCategory.signatureCheck}
          />
        ),
      },
      {
        category: this.props.invoice.checkCategory.articleExistsCheck,
        categoryName: this.props.invoice.checkCategory.articleExistsCheck.name,
        component: (
          <ArticleExistsCheck
            category={this.props.invoice.checkCategory.articleExistsCheck}
          />
        ),
      },
      {
        category: this.props.invoice.checkCategory.amountCheck,
        categoryName: this.props.invoice.checkCategory.amountCheck.name,
        component: (
          <AmountCheck
            category={this.props.invoice.checkCategory.amountCheck}
          />
        ),
      },
      {
        category: this.props.invoice.checkCategory.amountApprovedCheck,
        categoryName: this.props.invoice.checkCategory.amountApprovedCheck.name,
        component: (
          <AmountApprovedCheck
            category={this.props.invoice.checkCategory.amountApprovedCheck}
          />
        ),
      },
      {
        category: this.props.invoice.checkCategory.priceCheck,
        categoryName: this.props.invoice.checkCategory.priceCheck.name,
        component: (
          <PriceCheck category={this.props.invoice.checkCategory.priceCheck} />
        ),
      },
    ];

    // The categories should be sorted by the order of the user clicks so that always the latest one is on top.
    const sortedCategoryComponentPairs = ArrayUtils.sortByKeyValues(
      categoryComponentPairs,
      this.props.activeInvoiceCheckCategories,
      'categoryName',
    );

    // The inactive categories also have to be added to the components so that the fade animation works correctly.
    for (const categoryComponentPair of categoryComponentPairs) {
      if (
        !this.props.activeInvoiceCheckCategories.includes(
          categoryComponentPair.category.name,
        )
      ) {
        sortedCategoryComponentPairs.push(categoryComponentPair);
      }
    }

    return (
      <div>
        {sortedCategoryComponentPairs.map((categoryComponentPair, index) => (
          <div
            className={this.getCategoryCheckClassName(
              categoryComponentPair.category,
            )}
            key={categoryComponentPair.category.name}
          >
            {categoryComponentPair.component}
          </div>
        ))}
      </div>
    );
  }
}

export default withErrorBoundary(InvoiceCheckCategories, null);
