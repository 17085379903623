import { createSlice } from '@reduxjs/toolkit';
import ArrayUtils from '~/utils/arrayUtils';
import { LOADING_STATE } from '~/constants/LoadingState';

const organisationalGroupsSlice = createSlice({
  name: 'organisationalGroups',
  initialState: {
    organisationalGroups: [],
    organisationalGroupsLoading: LOADING_STATE.NOT_LOADED,
    organisationalGroupRowPages: [],
    organisationalGroupRowPagesVersion: 0,
    organisationalGroupRowCount: 0,
    organisationalGroupRowCountLoading: LOADING_STATE.NOT_LOADED,
  },
  reducers: {
    replaceOrganisationalGroups(state, parameter) {
      const { payload } = parameter;

      state.organisationalGroups = ArrayUtils.sortByKey(payload, 'name');
      state.organisationalGroupsLoading = LOADING_STATE.SUCCEEDED;
    },
    replaceOrganisationalGroup(state, parameter) {
      const { payload } = parameter;

      const newOrganisationalGroups = ArrayUtils.removeByKey(
        state.organisationalGroups,
        'id',
        payload.id,
      );
      newOrganisationalGroups.push(payload);

      state.organisationalGroups = newOrganisationalGroups;
    },
    setOrganisationalGroupsLoading(state, parameter) {
      const { payload } = parameter;

      state.organisationalGroupsLoading = payload;
    },
  },
});

const { actions, reducer } = organisationalGroupsSlice;
export const {
  replaceOrganisationalGroup,
  replaceOrganisationalGroups,
  setOrganisationalGroupsLoading,
} = actions;
export default reducer;
