import React from 'react';
import { withErrorBoundary } from '~/ui/atoms';
import InvoiceCheck from '../InvoiceCheck';
import InvoiceCheckCategory from './InvoiceCheckCategory';
import { DeliveryNoteLinks } from '~/utils/componentUtils';
import { pluralizeString, pluralizeWord } from '~/utils/pluralize';

class SignatureCheck extends React.Component {
  render() {
    const errorInvoiceChecks = (
      <InvoiceCheck
        expandedDefault
        checkResults={this.props.category.errorChecks}
        checkResultFormatter={(checkResult) => (
          <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />
        )}
      >
        <div className="w-full">
          Die{' '}
          {pluralizeWord(this.props.category.errorChecks.length, 'folgende')}{' '}
          {pluralizeString(
            this.props.category.errorChecks.length,
            'Lieferung wurde',
            this.props.category.errorChecks.length > 1,
          )}{' '}
          nicht von einem Mitarbeiter des Rechnungsempfängers signiert.
        </div>
      </InvoiceCheck>
    );

    const delayedSuccessInvoiceChecks = (
      <InvoiceCheck
        expandedDefault
        checkResults={this.props.category.delayedSuccessChecks}
        checkResultFormatter={(checkResult) => (
          <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />
        )}
      >
        <div className="w-full">
          Die{' '}
          {pluralizeWord(
            this.props.category.delayedSuccessChecks.length,
            'folgende',
          )}{' '}
          {pluralizeString(
            this.props.category.delayedSuccessChecks.length,
            'Lieferung wurde',
            this.props.category.delayedSuccessChecks.length > 1,
          )}{' '}
          nachträglich von einem Mitarbeiter des Rechnungsempfängers signiert.
        </div>
      </InvoiceCheck>
    );

    const successInvoiceChecks = (
      <InvoiceCheck
        expandedDefault
        checkResults={this.props.category.successChecks}
        checkResultFormatter={(checkResult) => (
          <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />
        )}
      >
        <div className="w-full">
          Die{' '}
          {pluralizeWord(this.props.category.successChecks.length, 'folgende')}{' '}
          {pluralizeString(
            this.props.category.successChecks.length,
            'Lieferung wurde',
            this.props.category.successChecks.length > 1,
          )}{' '}
          von einem Mitarbeiter des Rechnungsempfängers signiert.
        </div>
      </InvoiceCheck>
    );

    return (
      <InvoiceCheckCategory
        category={this.props.category}
        errorInvoiceChecks={errorInvoiceChecks}
        delayedSuccessInvoiceChecks={delayedSuccessInvoiceChecks}
        successInvoiceChecks={successInvoiceChecks}
      />
    );
  }
}

export default withErrorBoundary(
  SignatureCheck,
  'Prüfschritt "Signaturen" konnte nicht geladen werden.',
);
