import React from 'react';

import {
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { Step, StepLabel, Stepper, Button } from '@mui/material';

import StepWizard from 'react-step-wizard';
import { withErrorBoundary } from '~/ui/atoms';
import BasicModal from './BasicModal';
import { Spinner } from './Spinner';
import Log from '~/utils/Log';
import BasicForm from './BasicForm';
import { promiseHandler } from '~/utils/promiseHandler';

class Wizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: null,
      currentStep: 0,
      SW: null,
      abortFormOpen: false,
    };
  }

  setInstance = (SW) => {
    this.setState({
      SW,
    });
  };
  previousStep = () => {
    Log.productAnalyticsEvent('Go to previous step', Log.FEATURE.WIZARD);

    this.state.SW?.previousStep();

    this.setState({
      currentStep: this.state.currentStep - 1,
    });
  };
  nextStep = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.props.steps[this.state.currentStep].preventNextStep?.()) {
      return;
    }

    if (this.nextStepIsSubmit()) {
      const [successfulSubmit, error] = await promiseHandler(
        this.props.wizardSuccess(),
      );

      if (successfulSubmit) {
        this.setState({
          currentStep: 0,
        });
      }

      return;
    }

    Log.productAnalyticsEvent('Go to next step', Log.FEATURE.WIZARD);

    this.state.SW?.nextStep();

    this.setState({
      currentStep: this.state.currentStep + 1,
    });
  };
  closeWizard = () => {
    if (this.props.unsavedChanges?.length > 0) {
      this.setState({
        abortFormOpen: true,
      });
      return;
    }

    this.setState({
      currentStep: 0,
    });

    this.props.closeWizard();
  };
  closeAbortForm = () => {
    this.setState({
      abortFormOpen: false,
    });
  };
  abortFormSuccess = (e) => {
    e.stopPropagation();
    e.preventDefault();

    Log.productAnalyticsEvent('Submit abort form', Log.FEATURE.WIZARD);

    this.setState({
      currentStep: 0,
    });

    this.props.closeWizard();
    this.closeAbortForm();
  };
  abortFormAbort = () => {
    Log.productAnalyticsEvent('Abort abort form', Log.FEATURE.WIZARD);
    this.closeAbortForm();
  };

  nextStepIsSubmit() {
    return this.state.currentStep === this.props.steps.length - 1;
  }

  render() {
    return (
      <BasicModal
        open={this.props.open}
        closeModal={this.closeWizard}
        title={this.props.title}
        fullWidth={this.props.fullWidth}
      >
        <form onSubmit={this.nextStep}>
          <div className="pb-80px">
            <Stepper activeStep={this.state.currentStep} className="pb-30px">
              {this.props.steps.map((step) => (
                <Step key={step.title}>
                  <StepLabel>{step.title}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className="z-index-0 relative">
              <StepWizard instance={this.setInstance}>
                {/* Using "hidden" is a hacky workaround to prevent that the different steps influence each other's height. */}
                {this.props.steps.map((step, index) => (
                  <div
                    key={step.title}
                    className={index === this.state.currentStep ? '' : 'hidden'}
                  >
                    {step.component}
                  </div>
                ))}
              </StepWizard>
            </div>
          </div>
          <div
            className="flex-sb-c sticky-dialog-footer z-index-1"
            style={{ width: `calc(100% - 48px)` }}
          >
            <Button
              variant="text"
              className="mr-30px"
              onClick={this.closeWizard}
            >
              Abbrechen
            </Button>
            <BasicForm
              open={this.state.abortFormOpen}
              formSuccess={this.abortFormSuccess}
              formAbort={this.abortFormAbort}
              title="Ungespeicherte Änderungen"
              submitButtonTitle="Fortfahren"
            >
              Ungespeicherte Änderungen gehen verloren. Willst du wirklich
              fortfahren?
            </BasicForm>
            <div>
              <Button
                variant="text"
                className="mr-30px"
                onClick={this.previousStep}
                disabled={
                  this.state.currentStep === 0 || this.props.submittingWizard
                }
                startIcon={<NavigateBeforeIcon />}
              >
                Zurück
              </Button>
              <Button
                variant={this.nextStepIsSubmit() ? 'contained' : undefined}
                color={this.nextStepIsSubmit() ? 'primary' : undefined}
                startIcon={
                  this.nextStepIsSubmit() && !this.props.submittingWizard ? (
                    <SaveIcon />
                  ) : undefined
                }
                endIcon={
                  this.nextStepIsSubmit() ||
                  this.props.submittingWizard ? undefined : (
                    <NavigateNextIcon />
                  )
                }
                className="primary-button"
                type="submit"
                disabled={
                  this.props.steps[this.state.currentStep].isNextStepDisabled ||
                  this.props.submittingWizard
                }
              >
                {!this.props.submittingWizard && !this.nextStepIsSubmit()
                  ? 'Weiter'
                  : null}
                {!this.props.submittingWizard && this.nextStepIsSubmit()
                  ? 'Speichern'
                  : null}
                {this.props.submittingWizard ? (
                  <Spinner white title="Speichern ..." />
                ) : null}
              </Button>
            </div>
          </div>
        </form>
      </BasicModal>
    );
  }
}

export default withErrorBoundary(Wizard, null);
