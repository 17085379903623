import React from 'react';

import { FormatIndentIncrease as FormatIndentIncreaseIcon } from '@mui/icons-material';
import {
  FormHelperText,
  InputLabel,
  IconButton,
  TextField,
} from '@mui/material';

import beautify from 'json-beautify';

import ObjectUtils from '~/utils/objectUtils';
import Log from '~/utils/Log';

class JsonInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      jsonString: '',
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.value) !== JSON.stringify(prevProps.value) &&
      // Prevent that the JSON is formatted every time this.props.value is updated by the JsonInput itself
      // because then the cursor would always jump to the bottom of the Textfield when the jsonString is valid.
      (!ObjectUtils.isValidJson(this.state.jsonString) ||
        JSON.stringify(this.props.value) !==
          JSON.stringify(JSON.parse(this.state.jsonString)))
    ) {
      this.init();
    }
  }

  init() {
    this.setState({
      jsonString: beautify(this.props.value, null, 2, 0),
    });
  }

  handleChangeJsonString = (event) => {
    this.setState({
      jsonString: event.target.value,
    });

    if (ObjectUtils.isValidJson(event.target.value)) {
      this.props.onChange(JSON.parse(event.target.value));
    }
  };
  formatJson = () => {
    if (!ObjectUtils.isValidJson(this.state.jsonString)) {
      return;
    }

    Log.info('Format json', null);
    Log.productAnalyticsEvent('Format json', Log.FEATURE.OTHER_FEATURE);

    this.setState({
      jsonString: beautify(JSON.parse(this.state.jsonString), null, 2, 0),
    });
  };

  render() {
    return (
      <div className="flex-s-e flexdir-column gap-5px">
        <div className={'relative ' + (this.props.fullWidth ? 'w-full' : null)}>
          {this.props.title ? (
            <InputLabel className="text-13px">{this.props.title}</InputLabel>
          ) : null}
          <TextField
            value={this.state.jsonString}
            onChange={this.handleChangeJsonString}
            placeholder={this.props.placeholder ?? 'Bitte JSON eingeben'}
            autoComplete="off"
            multiline
            rows={this.props.rows}
            fullWidth={this.props.fullWidth}
            variant="outlined"
          />
          {this.state.jsonString &&
          !ObjectUtils.isValidJson(this.state.jsonString) ? (
            <FormHelperText className="text-mui-error-red absolute">
              Ungültiges JSON Format.
            </FormHelperText>
          ) : null}
        </div>
        <IconButton onClick={this.formatJson} size="medium">
          <FormatIndentIncreaseIcon fontSize="small" />
        </IconButton>
      </div>
    );
  }
}

export default JsonInput;
