/**
 * Generates a mailto link with subject and body parameters.
 *
 * @param {object} mailtoLinkOptions - Options for generating the mailto link.
 * @param {string} mailtoLinkOptions.body - The body of the email.
 * @param {string} [mailtoLinkOptions.emailAddress=''] - The email address to send to.
 * @param {string} mailtoLinkOptions.subject - The subject of the email.
 * @return {string} The generated mailto link.
 */
export const generateMailToLink = ({
  body = '',
  emailAddress = '',
  subject = '',
}) => {
  const mailto = ['mailto:', encodeURIComponent(emailAddress)].join('');

  const params = [];
  if (subject) {
    params.push(['subject', encodeURIComponent(subject)].join('='));
  }

  if (body) {
    params.push(['body', encodeURIComponent(body)].join('='));
  }

  if (params.length > 0) {
    return [mailto, params.join('&')].join('?');
  }

  return mailto;
};
