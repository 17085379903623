import { Box, Chip, Typography } from '@mui/material';

export const CustomerNumber = ({ contacts }) => (
  <Box flexDirection="column">
    <Typography variant="h6" className="content-heading">
      Kundennummern
    </Typography>
    <Box
      marginTop={2}
      display="flex"
      flexWrap="wrap"
      flexDirection="row"
      alignItems="flex-start"
      justifyContent="flex-start"
      sx={{
        gap: 8,
      }}
    >
      {contacts.length > 0 ? (
        <>
          {contacts?.map((value) => (
            <Chip key={value} size="medium" label={value} variant="outlined" />
          ))}
        </>
      ) : (
        <Box
          marginTop={22}
          alignItems="center"
          justifyContent="center"
          display="flex"
          height="100%"
          width="100%"
        >
          <Typography color="textSecondary">
            Für diesen Lieferanten sind keine Kundennummern hinterlegt
          </Typography>
        </Box>
      )}
    </Box>
  </Box>
);
