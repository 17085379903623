import React from 'react';
import { connect } from 'react-redux';
import { withErrorBoundary } from '~/ui/atoms';
import BasicTable from '../BasicTable';
import cloneDeep from 'lodash/cloneDeep';
import { LOADING_STATE } from '~/constants/LoadingState';
import LocalStorageService from '~/services/localStorage.service';
import { setPageTitle } from '~/redux/menuSlice';
import DeliveryNote from '~/models/deliveries/DeliveryNote';
import UnitUtils from '~/utils/unitUtils';
import DateRangeSelect from '../baseComponents/inputs/date/DateRangeSelect';
import { dateUtils } from '~/utils/dateUtils';
import Log from '~/utils/Log';
import {
  setUserMetrics_dateRange,
  setUserMetrics_predefinedDateRange,
  setUserMetrics_individualDateRange,
  setUserMetrics_sortModel,
} from '~/redux/filtersSlice';
import UserMetricsDetailPanel from './UserMetricsDetailPanel';
import InvoiceCheckResult from '~/models/invoices/InvoiceCheckResult';
import BasicModal from '../BasicModal';
import FilterContext from '~/models/filters/FilterContext';

const mapStateToProps = (state) => ({
  deliveryNotes: state.deliveryNotes,
  invoices: state.invoices,
  sites: state.sites,
  dateRange: state.filters.userMetrics_dateRange,
  predefinedDateRange: state.filters.userMetrics_predefinedDateRange,
  individualDateRange: state.filters.userMetrics_individualDateRange,
  sortModel: state.filters.userMetrics_sortModel,
});
const mapDispatchToProps = () => ({
  setPageTitle,
  setUserMetrics_dateRange,
  setUserMetrics_predefinedDateRange,
  setUserMetrics_individualDateRange,
  setUserMetrics_sortModel,
});

class UserMetrics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      filteredDeliveryNotes: [],
      filteredDeliveryRows: [],
      invoiceParsedDates: {},
      expandedRows: [],
      open: false,
      selectedRow: null,
    };

    this.DETAIL_PANEL_HEIGHT = 600;
  }

  componentDidMount() {
    this.filterDeliveryNotes();

    this.props.setPageTitle('Benutzerstatistiken');
    document.title = 'VESTIGAS - Benutzerstatistiken';
  }

  // if one of the filters has changed, then update/filter the data
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.state.filteredDeliveryNotes) !==
        JSON.stringify(prevState.filteredDeliveryNotes) ||
      this.props.invoices.incomingInvoicesVersion !==
        prevProps.invoices.incomingInvoicesVersion
    ) {
      this.loadData();
    }

    if (
      this.props.deliveryNotes.filteredDeliveryNotesVersion !==
        prevProps.deliveryNotes.filteredDeliveryNotesVersion ||
      JSON.stringify(this.props.dateRange) !==
        JSON.stringify(prevProps.dateRange)
    ) {
      this.filterDeliveryNotes();
    }
  }

  filterDeliveryNotes() {
    const timeframe = dateUtils.extractTimeframe(this.props.dateRange);

    const filteredDeliveryNotes =
      this.props.deliveryNotes.filteredDeliveryNotes.filter(
        (deliveryNote) =>
          Date.parse(deliveryNote.dlnDate) >= timeframe.from &&
          Date.parse(deliveryNote.dlnDate) <= timeframe.to,
      );

    const filteredDeliveryRows =
      this.props.deliveryNotes.filteredDeliveryRows.filter(
        (deliveryRow) =>
          Date.parse(deliveryRow.dlnDate) >= timeframe.from &&
          Date.parse(deliveryRow.dlnDate) <= timeframe.to,
      );

    this.setState({
      filteredDeliveryNotes,
      filteredDeliveryRows,
    });
  }

  loadData() {
    let newSites = cloneDeep(
      this.props.sites.sites.filter((site) => site.active),
    );

    const assignedDlnsCount = {};
    const signedDlnsCount = {};
    const signedDlns = {};
    // Track the delivery notes that have been signed but after the invoice check was executed.
    const delayedSignedDlnsCount = {};
    const delayedSignedDlns = {};

    // Temp variable to track the delivery notes that have been signed but after the invoice check.
    const invoiceUnsignedDeliveryNotes = {};

    // The invoice parsed dates are used to compare the delay between the signature of the delivery note and when the invoice check was executed.
    const invoiceParsedDates = {};

    for (const invoice of this.props.invoices.incomingInvoices) {
      const checkResults = invoice.checkResults.filter(
        (checkResult) =>
          checkResult.name === 'DeliveryNoteAuthorized' &&
          checkResult.status === InvoiceCheckResult.STATUS.ERROR,
      );
      for (const checkResult of checkResults) {
        for (const deliveryNote of checkResult.deliveryNotes) {
          // TODO: Matching of delivery notes should be via ID.
          //  However, due to a bug in the backend oly the numbers of the dlns are provided for DeliveryNoteAuthorized errors.
          //  Thus, as a workaround match via the number.
          invoiceUnsignedDeliveryNotes[deliveryNote.number] = true;
          invoiceParsedDates[deliveryNote.number] = invoice.parsedDate;
        }
      }
    }

    for (const deliveryNote of this.state.filteredDeliveryNotes) {
      // Determine dlns that are assigned to a site based on toSiteRecipient
      if (deliveryNote.toSiteRecipient.id) {
        if (assignedDlnsCount[deliveryNote.toSiteRecipient.id] === undefined) {
          assignedDlnsCount[deliveryNote.toSiteRecipient.id] = 0;
          signedDlnsCount[deliveryNote.toSiteRecipient.id] = 0;
          signedDlns[deliveryNote.toSiteRecipient.id] = [];
          delayedSignedDlnsCount[deliveryNote.toSiteRecipient.id] = 0;
          delayedSignedDlns[deliveryNote.toSiteRecipient.id] = [];
        }

        assignedDlnsCount[deliveryNote.toSiteRecipient.id]++;
        if (
          deliveryNote.processState ===
          DeliveryNote.PROCESS_STATE.DELIVERED.STRING
        ) {
          signedDlnsCount[deliveryNote.toSiteRecipient.id]++;
          signedDlns[deliveryNote.toSiteRecipient.id].push(deliveryNote.id);

          // If a delivery note has been signed but also is thrown as "unsigned" in the invoice check,
          // we want to track it as "delayed signed".
          if (invoiceUnsignedDeliveryNotes[deliveryNote.number]) {
            delayedSignedDlnsCount[deliveryNote.toSiteRecipient.id]++;
            delayedSignedDlns[deliveryNote.toSiteRecipient.id].push(
              deliveryNote.id,
            );
          }
        }
      }

      // Determine dlns that are assigned to a site based on the permittedToSites.
      // This also covers cost centers implicitly because dlns are assigned automatically to sites which belong to cost centers in the permission system.
      for (const permittedToSite of deliveryNote.permittedToSites) {
        if (!permittedToSite.id) {
          continue;
        }

        if (permittedToSite.id === deliveryNote.toSiteRecipient.id) {
          continue;
        } // prevent duplicates

        if (assignedDlnsCount[permittedToSite.id] === undefined) {
          assignedDlnsCount[permittedToSite.id] = 0;
          signedDlnsCount[permittedToSite.id] = 0;
          signedDlns[permittedToSite.id] = [];
          delayedSignedDlnsCount[permittedToSite.id] = 0;
          delayedSignedDlns[permittedToSite.id] = [];
        }

        assignedDlnsCount[permittedToSite.id]++;
        if (
          deliveryNote.processState ===
          DeliveryNote.PROCESS_STATE.DELIVERED.STRING
        ) {
          signedDlnsCount[permittedToSite.id]++;
          signedDlns[permittedToSite.id].push(deliveryNote.id);

          if (invoiceUnsignedDeliveryNotes[deliveryNote.number]) {
            delayedSignedDlnsCount[permittedToSite.id]++;
            delayedSignedDlns[permittedToSite.id].push(deliveryNote.id);
          }
        }
      }
    }

    newSites = newSites.map((site) => {
      return {
        ...site,
        assignedDlnsCount: assignedDlnsCount[site.id] ?? 0,
        signedDlnsCount: signedDlnsCount[site.id] ?? 0,
        signedAssignedRatio:
          signedDlnsCount[site.id] && assignedDlnsCount[site.id]
            ? signedDlnsCount[site.id] / assignedDlnsCount[site.id]
            : 0,
        signedDlns: signedDlns[site.id] ?? [],
        delayedSignedDlnsCount: delayedSignedDlnsCount[site.id] ?? 0,
        delayedSignedDlns: delayedSignedDlns[site.id] ?? [],
        delayedSignedSignedRatio:
          delayedSignedDlnsCount[site.id] && signedDlnsCount[site.id]
            ? delayedSignedDlnsCount[site.id] / signedDlnsCount[site.id]
            : 0,
      };
    });

    this.setState({
      rows: newSites,
      invoiceParsedDates,
    });
  }

  getColumns() {
    return [
      {
        field: 'name',
        headerName: 'Standort',
        width: 400,
        sortable: true,
        resizableText: true,
      },
      {
        field: 'signedDlnsCount',
        headerName: 'Signierte Lieferungen',
        width: 150,
        sortable: true,
        type: 'number',
        resizableText: true,
      },
      {
        field: 'assignedDlnsCount',
        headerName: 'Zugeordnete Lieferungen',
        width: 150,
        sortable: true,
        type: 'number',
        resizableText: true,
      },
      {
        field: 'signedAssignedRatio',
        headerName: 'Anteil Signiert / Zugeordnet',
        width: 150,
        sortable: true,
        type: 'number',
        resizableText: true,
        renderCell: (params) =>
          UnitUtils.formatDeMoneyAmount_safe(params.value),
      },
      {
        field: 'delayedSignedDlnsCount',
        headerName: 'Nachträglich Signierte Lieferungen',
        width: 150,
        sortable: true,
        type: 'number',
        resizableText: true,
      },
      {
        field: 'delayedSignedSignedRatio',
        headerName: 'Anteil Nachträglich Signiert /Signiert',
        width: 150,
        sortable: true,
        type: 'number',
        resizableText: true,
        renderCell: (params) =>
          UnitUtils.formatDeMoneyAmount_safe(params.value),
      },
    ];
  }

  handleDateRangeChange = (value) => {
    const numberOfDays = dateUtils.getNumberOfDays(value[0], value[1]);
    if (numberOfDays >= 0 && numberOfDays <= 3650) {
      Log.info(
        'Change filter value of selected date range',
        { from: this.props.dateRange, to: value },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Filter date range', Log.FEATURE.USER_METRICS);

      this.props.setUserMetrics_dateRange(value);
      this.props.setUserMetrics_individualDateRange(true);
    }
  };
  handlePredefinedDateRangeChange = (value) => {
    Log.info(
      'Change filter value of selected predefined date range',
      { from: this.props.predefinedDateRange, to: value },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Filter predefined date range: ' + value,
      Log.FEATURE.USER_METRICS,
    );

    this.props.setUserMetrics_predefinedDateRange(value);
    this.props.setUserMetrics_dateRange(
      dateUtils.getTimeframeFromDateRange(value),
    );
    this.props.setUserMetrics_individualDateRange(false);
  };
  onSortModelChange = (event) => {
    Log.productAnalyticsEvent('Sort', Log.FEATURE.USER_METRICS);
    this.props.setUserMetrics_sortModel(event);
  };

  /* handleDetailPanelExpandedRowIdsChange = (event) => {
        Log.productAnalyticsEvent('Expand site', Log.FEATURE.USER_METRICS);

        this.setState({
            expandedRows: event
        });
    } */

  handleOpen = (rowData) => {
    Log.productAnalyticsEvent('Expand site', Log.FEATURE.USER_METRICS);

    this.setState({
      open: true,
      selectedRow: rowData.row,
    });
  };
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  getLoadingState() {
    if (
      this.props.deliveryNotes.deliveryNotesLoading === LOADING_STATE.LOADING
    ) {
      return LOADING_STATE.LOADING;
    }

    if (this.props.sites.sitesLoading === LOADING_STATE.LOADING) {
      return LOADING_STATE.LOADING;
    }

    if (this.props.invoices.incomingInvoicesLoading === LOADING_STATE.LOADING) {
      return LOADING_STATE.LOADING;
    }

    return null;
  }

  render() {
    return (
      <div className="main-padding flexdir-column flex h-full">
        <div className="flex-s-c w-full">
          <DateRangeSelect
            predefinedDateRange={this.props.predefinedDateRange}
            individualDateRange={this.props.individualDateRange}
            onPredefinedDateRangeChange={this.handlePredefinedDateRangeChange}
            dateRange={this.props.dateRange}
            onDateRangeChange={this.handleDateRangeChange}
            displayLabel
            archiveMode
            page={FilterContext.PAGE.USER_METRICS}
          />
        </div>
        <div className="min-h-500px mt-30px rounded-5px box-shadow-blue flex-1 bg-white">
          <BasicTable
            rows={this.state.rows}
            columns={this.getColumns()}
            loading={this.getLoadingState()}
            localStorageKey={LocalStorageService.USER_METRICS}
            onSortModelChange={this.onSortModelChange}
            sortModel={this.props.sortModel}
            onRowClick={this.handleOpen}
            excelData={this.state.rows}
            excelColumns={this.getColumns()}
            // detailPanelExpandedRowIds={this.state.expandedRows}
            // onDetailPanelExpandedRowIdsChange={this.handleDetailPanelExpandedRowIdsChange}
            // getDetailPanelContent={rowData => <UserMetricsDetailPanel site={rowData.row} />}
            // getDetailPanelHeight={() => this.DETAIL_PANEL_HEIGHT}
            disableRowSelectionOnClick
          />
        </div>
        <BasicModal
          title={this.state.selectedRow?.name}
          open={this.state.open}
          closeModal={this.handleClose}
          fullWidth
        >
          <UserMetricsDetailPanel
            site={this.state.selectedRow}
            filteredDeliveryRows={this.state.filteredDeliveryRows}
            invoiceParsedDates={this.state.invoiceParsedDates}
          />
        </BasicModal>
        <div
          className="min-h-2rem"
          /* This is a hacky workaround to get the padding bottom of 2rem. It is applied as child container to all divs with main-padding */
          /* A better solution would be to make the parent container min-h-fit-content so that the padding of main-padding is applied. */
          /* However, min-h-fit-content seems to not work with h-fill or generally with flexbox and flex-1. */
        />
      </div>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(UserMetrics),
  'Benutzerstatistiken konnten nicht geladen werden.',
);
