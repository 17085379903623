import UserGroup from '~/models/masterdata/UserGroup';
import UserGroupService from '~/services/userGroup.service';
import Path from './Path';
import { useSelector } from 'react-redux';

export default function UserGroupPaths(props) {
  const { userGroupsLoading } = useSelector((state) => state.userGroups);

  if (props.userGroupPaths.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="bold">Teil der Benutzer-Gruppen:</div>
      <div className="flexdir-column gap-10px mt-10px flex">
        {props.userGroupPaths.map((userGroupPath, index) => (
          <Path
            // id and key are needed so that paths are rendered correctly when navigating or refreshing data.
            id={
              'user-group-path-' +
              props.id +
              '-' +
              index +
              '-' +
              JSON.stringify(userGroupPath)
            }
            key={
              'user-group-path-' +
              props.id +
              '-' +
              index +
              '-' +
              JSON.stringify(userGroupPath)
            }
            path={userGroupPath}
            onOpenEntity={props.onOpenUserGroup}
            model={UserGroup}
            getEntityById={UserGroupService.getUserGroupById}
            loading={userGroupsLoading}
          />
        ))}
      </div>
    </div>
  );
}
